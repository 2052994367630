import { schema } from 'normalizr';

const User = new schema.Entity(
  'users',
  {},
  {
    idAttribute: (user) => user.nhanVienId,
  }
);

export default User;
