import React from 'react';
import { Tabs } from '@blueprintjs/core';
import classNames from 'classnames';

import './index.scss';

const CPCTab = ({
  id = 'tabs',
  children,
  selected,
  animate = true,
  renderActiveTabPanelOnly = true,
  theme,
  className,
  ...props
}) => {
  return (
    <Tabs
      className={classNames('cpc-tabs', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
      })}
      id={id}
      animate={animate}
      renderActiveTabPanelOnly={renderActiveTabPanelOnly}
      selectedTabId={selected}
      {...props}
    >
      {children}
    </Tabs>
  );
};

export default CPCTab;
