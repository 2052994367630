import classNames from 'classnames';
import './index.scss';

const CPCFlex = ({
  wrap,
  align, // center | flex-start | flex-end
  justify, // center | space-between | flex-start | flex-end
  gap, // 5 | 10 | 15 | 20
  direction, // row | column
  className,
  style,
  children,
  onClick = () => {},
}) => {
  return (
    <div
      className={classNames('cpc-flex', {
        [`${className}`]: className,
        [`wrap-${wrap}`]: wrap,
        [`align-${align}`]: align,
        [`justify-${justify}`]: justify,
        [`gap-${gap}`]: gap,
        [`direction-${direction}`]: direction,
      })}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default CPCFlex;
