import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Button extends Component {
  handleConfirm = (e) => {
    e.preventDefault();
    this.props.clickButton && this.props.clickButton(e);
  };

  render() {
    return (
      <button
        type={this.props.type}
        onClick={this.handleConfirm}
        disabled={this.props.disabled}
        className={`button-action ${this.props.className}`}
        style={this.props.style}
      >
        <div
          className={classnames('button-inside', {
            'button-inside-reverse': this.props.insideReverse,
          })}
        >
          {this.props.icon && !this.props.loading && (
            <span className={`icon ${this.props.icon}`}></span>
          )}
          {this.props.loading && (
            <span className={`icon icon-loading-arrow1601 loading-rotate`}></span>
          )}
          <span className="title">{this.props.title}</span>
        </div>
      </button>
    );
  }

  static defaultProps = {
    title: 'Gửi',
    className: '',
    icon: '',
    disabled: false,
    type: 'button',
    insideReverse: false,
    style: {},
    loading: false,
  };

  static propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    insideReverse: PropTypes.bool,
    clickButton: PropTypes.func,
    style: PropTypes.object,
    loading: PropTypes.bool,
  };
}
