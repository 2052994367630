import {
  GET_FEEDBACK_LIST,
  GET_FEEDBACK_DETAIL,
  UPDATE_FEEDBACK_HANDLE,
  GET_FEEDBACK_LIST_STATISTIC,
  GET_FEEDBACK_STATISTIC_DETAIL,
  GET_FEEDBACK_PHIENBAN,
  GET_FEEDBACK_CHATLUONG,
  GET_FEEDBACK_TRANGTHAI,
} from '../constants/ActionTypes';

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  statistic: {},
  statisticDetail: {},
  tongSoLuotDanhGia: 0,
  detail: {},
  status: {},
  phienBan: [],
  chatLuong: [],
  trangThai: [],
};

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACK_LIST:
      return {
        ...state,
        list: {
          page: action.payload.data.result.page,
          total: action.payload.data.result.total,
          items:
            action.payload.data && action.payload.data.result && action.payload.data.result.items
              ? action.payload.data.result.items
              : [],
        },
      };
    case GET_FEEDBACK_DETAIL:
      return {
        ...state,
        detail: action.payload.data.result,
      };
    case UPDATE_FEEDBACK_HANDLE:
      return {
        ...state,
        status: action.payload.data.result,
      };
    case GET_FEEDBACK_LIST_STATISTIC:
      return {
        ...state,
        statistic:
          action.payload.data && action.payload.data.result ? action.payload.data.result : {},
      };
    case GET_FEEDBACK_STATISTIC_DETAIL:
      return {
        ...state,
        statisticDetail:
          action.payload.data && action.payload.data.result ? action.payload.data.result : {},
      };
    case GET_FEEDBACK_PHIENBAN:
      return {
        ...state,
        phienBan:
          action.payload.data && action.payload.data.result && action.payload.data.result.items
            ? action.payload.data.result.items
            : [],
      };
    case GET_FEEDBACK_CHATLUONG:
      return {
        ...state,
        chatLuong:
          action.payload.data && action.payload.data.result && action.payload.data.result.items
            ? action.payload.data.result.items
            : [],
      };
    case GET_FEEDBACK_TRANGTHAI:
      return {
        ...state,
        trangThai:
          action.payload.data && action.payload.data.result && action.payload.data.result.items
            ? action.payload.data.result.items
            : [],
      };
    default:
      return state;
  }
}
