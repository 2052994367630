export default {
  'system.app': 'App',
  'system.coloivuilongthulai': 'Có lỗi xảy ra, vui lòng thử lại',
  'system.capnhatthanhcong': 'Cập nhật thành công',
  'system.capnhatkhongthanhcong': 'Cập nhật không thành công',
  'system.xemtatca': 'Xem tất cả',
  'system.huy': 'Hủy',
  'system.chon': 'Chọn',
  'system.da-chon': 'Đã chọn',
  'system.khong': 'Không',
  'system.xoa': 'Xóa',
  'system.tatthongbao': 'Tắt thông báo',
  'system.khongcodulieu': 'Không có dữ liệu trong hệ thống',
  'system.dongy': 'Đồng ý',
  'system.chiase': 'Chia sẻ',
};
