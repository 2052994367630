import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { bindActionCreators } from 'redux';

import { FE_URL, DOFFICE_URL } from '@/constants/Api';
import variables from '@/constants/variables';
import logoImg from '@/assets/images/logo.png';
import { sidebar } from './constants';
import Setting from './setting';
import useSidebar from './useSidebar';
import BirthdayModal from './birthday-modal';
import useOpen from '@/hooks/useOpen';
import * as Actions from '../../../actions';
import { AppToaster } from '@/components/atoms/toaster';
import SidebarItem from './item';
import './index.scss';

function Sidebar(props) {
  const TONG_CONG_TY_ID = '06914739-d280-e711-80cd-40167e7b9c6a';
  const { history, actions = {}, auth = {}, birthdayUserList } = props;
  const [loading, setLoading] = useState(null);
  const [counterBirthDay, setCounterBirthDay] = useState(0);

  useSidebar({ actions, auth });
  const { open, handleClose, handleOpen } = useOpen();
  const handleSaveConfig = (data) => {
    return actions
      .configUpdate(data)
      .then((res) => {
        if (res.error) {
          AppToaster.show({
            message: variables.MESSAGE_STATUS.TOATS_MESSAGE_ERROR,
            intent: Intent.WARNING,
          });
        }
        AppToaster.show({
          message: variables.MESSAGE_STATUS.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        });
      })
      .then(actions.getUserConfig)
      .catch((err) => {
        AppToaster.show({
          message: variables.MESSAGE_STATUS.TOATS_MESSAGE_ERROR,
          intent: Intent.WARNING,
        });
      });
  };

  const onItem = (value) => {
    actions.setSidebar(value);
    if (value?.keyName === 'SINH_NHAT') {
      handleOpen();
      return;
    } else if (value?.keyName === 'CLOUD') {
      setLoading({
        ...loading,
        CLOUD: true,
      });
      actions.getConversationCloud().then((res) => {
        setLoading({
          ...loading,
          CLOUD: false,
        });
        if (res?.payload?.data?.result) {
          if (history?.location?.pathname === '/') {
            actions.setTopConversation(res.payload.data.result);
          } else {
            actions.createTopChat(res.payload.data.result);
            history.push('/');
          }
        }
      });
      return;
    } else if (value?.keyName === 'EOFFICE') {
      const url = `${FE_URL}?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
      window.open(url, 'eoffice');
      return;
    } else if (value?.keyName === 'DOFFICE') {
      window.open(DOFFICE_URL, 'doffice');
      return;
    } else if (value?.url) {
      return history.push(value.url);
    }
  };

  useEffect(() => {
    if (birthdayUserList) {
      if (birthdayUserList?.length !== 0) {
        if (auth?.config?.loaiThongBaoSinhNhat === variables.BIRTHDAY_CONFIG_TYPE.TAT_THONG_BA0) {
          return setCounterBirthDay(0);
        } else if (
          auth?.config?.loaiThongBaoSinhNhat === variables.BIRTHDAY_CONFIG_TYPE.DANH_SACH
        ) {
          return setCounterBirthDay(
            birthdayUserList?.filter((birthday) =>
              auth?.config?.dsNguoiNhanThongBaoSinhNhat?.some(
                (user) => user?.nhanVienId === birthday?.nhanVienId
              )
            )?.length || 0
          );
        } else if (auth?.config?.loaiThongBaoSinhNhat === variables.BIRTHDAY_CONFIG_TYPE.DON_VI) {
          const donViChinh =
            auth &&
            auth.mainUnitId &&
            props?.contact?.units?.find((e) => e?.donViId === auth.mainUnitId)
              ? props?.contact?.units?.find((e) => e?.donViId === auth.mainUnitId)
              : null;
          return setCounterBirthDay(
            birthdayUserList?.filter(
              (birthday) =>
                birthday?.donViId === donViChinh?.donViId ||
                (donViChinh?.donViId === birthday?.donViChaId &&
                  donViChinh?.donViId !== TONG_CONG_TY_ID) ||
                (donViChinh?.donViCha === birthday?.donViChaId &&
                  donViChinh?.donViCha !== TONG_CONG_TY_ID) ||
                (donViChinh?.donViCha === birthday?.donViId &&
                  donViChinh?.donViCha !== TONG_CONG_TY_ID)
            )?.length || 0
          );
        } else {
          return setCounterBirthDay(birthdayUserList?.length);
        }
      } else {
        setCounterBirthDay(0);
      }
    }
  }, [auth?.config?.loaiThongBaoSinhNhat, birthdayUserList]);

  useEffect(() => {
    actions.getFullUnits().then(async () => {
      await actions.contactFetchDepartmentsByUnit(auth.mainUnitId);
    });
  }, []);

  return (
    <section className="cpc-sidebar">
      <div>
        <div className="nav-left-item no-hover" onClick={() => history.push('/')}>
          <img className="logo" src={logoImg} alt="logo chat cpc" />
        </div>
        {sidebar
          .filter((e) => !e?.isBottom)
          .map((item, index) => (
            <SidebarItem
              key={index}
              data={item}
              pathname={history?.location?.pathname}
              counterBirthDay={counterBirthDay}
              loading={loading}
              onChange={(item) => onItem(item)}
            />
          ))}
      </div>
      <div className="sidebar-footer">
        {sidebar
          .filter((e) => e?.isBottom)
          .map((item, index) => (
            <SidebarItem
              key={index}
              data={item}
              pathname={history?.location?.pathname}
              counterBirthDay={counterBirthDay}
              loading={loading}
              onChange={(item) => onItem(item)}
            />
          ))}
        <Setting
          user={props.auth.user}
          actions={props.actions}
          history={history}
          auth={props.auth}
          units={props.contact.units}
          handleSaveConfig={handleSaveConfig}
        />
        <BirthdayModal
          isOpen={open}
          history={history}
          handleClose={handleClose}
          handleOpen={handleOpen}
          receivers={props.chat?.receivers}
        />
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
  contact: {
    ...state.contact,
    viewUnit: state.entities.units[state.contact.viewUnit]
      ? {
          ...state.entities.units[state.contact.viewUnit],
          dsPhongBan: state.entities.units[state.contact.viewUnit].dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
        }
      : {},
    units: (() => {
      let { units } = state.contact;

      if (
        state.entities.units[state.auth.mainUnitId] &&
        units.indexOf(state.auth.mainUnitId) === -1
      ) {
        units.push(state.auth.mainUnitId);
      }

      return units.map((id) => {
        const unit = state.entities.units[id];
        if (!unit.dsPhongBan) {
          unit.dsPhongBan = [];
        }

        const getUnit = (data) => {
          if (!data || !_.isArray(data.dsDonViCon)) {
            return [];
          }

          return data.dsDonViCon.map((id) => {
            const unit = state.entities.units[id];
            return {
              ...unit,
              dsDonViCon: getUnit(unit),
              dsPhongBan: unit.dsPhongBan
                ? unit.dsPhongBan.map((id) => {
                    const department = state.entities.departments[id];
                    return {
                      ...department,
                      dsNhanVien: department.dsNhanVien
                        .map((id) => state.entities.users[id])
                        .filter((user) => {
                          if (state.contact.filterOnline) {
                            return user.trucTuyen === 1;
                          }

                          return true;
                        }),
                    };
                  })
                : [],
            };
          });
        };

        return {
          ...unit,
          dsPhongBan: unit.dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
          dsDonViCon: getUnit(unit),
        };
      });
    })(),
    favourites: state.contact.favourites.map((id) => {
      const favourite = state.entities.favourites[id];
      if (!favourite.dsNhanVien) {
        favourite.dsNhanVien = [];
      }
      return {
        ...favourite,
        users: favourite.dsNhanVien.map((item) => state.entities.users[item.nhanVienId]),
      };
    }),
    showProfile: state.contact.showProfile ? state.entities.users[state.contact.showProfile] : null,
    searchUnit: {
      ...state.contact.searchUnit,
      items: state.contact.searchUnit.items
        .map((id) => state.entities.users[id])
        .filter((user) => {
          if (state.contact.filterOnline) {
            return user.trucTuyen === 1;
          }

          return true;
        }),
    },
    searchAll: {
      ...state.contact.searchAll,
      items: state.contact.searchAll.items
        .map((id) => state.entities.users[id])
        .filter((user) => {
          if (state.contact.filterOnline) {
            return user.trucTuyen === 1;
          }

          return true;
        }),
    },
    searchFavourite: {
      ...state.contact.searchFavourite,
      items: state.contact.searchFavourite.items
        .map((id) => state.entities.users[id])
        .filter((user) => {
          if (state.contact.filterOnline) {
            return user.trucTuyen === 1;
          }

          return true;
        }),
    },
  },
  birthdayUserList: state.chat?.birthdayUserList || [],
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
