import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../components/layout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Section as ContactSection } from '../../components/contact';
import { ChatBox } from '../../components/chat';
import * as Actions from '../../actions';
import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { IMAGE_URL } from '../../constants/Api';
import { SignalRHub } from '../../middleware/signalr';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/ListSurvey.scss';
import classnames from 'classnames';

class SendSurveyPage extends Component {
  receivers = {};

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };

  state = {
    buttonbackFixTop: false,
  };

  constructor(props) {
    super(props);

    this.surveyId = this.props.match.params.id;
    this.signalRHub = SignalRHub(this.props.auth, this.props.actions);
    this.handleScroll = this.handleScroll.bind(this);
  }

  refHandlers = {
    chatbox: (ref: ChatBox) => (this.chatbox = ref),
  };

  componentDidMount() {
    this.props.actions.chatClearReceiver(this.props.chat.receivers);
    this.props.actions.getSurveyItem(this.surveyId);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillMount() {
    this.signalRHub &&
      this.signalRHub
        .start()
        .done(() => {
          this.props.actions.chatSetConnectionId(this.signalRHub.connection.id);
          if (process.env.NODE_ENV !== 'production') {
            console.log('Now connected, connection ID=' + this.signalRHub.connection.id);
          }

          this.props.actions.commonReconnectSignalR(false);
          this.props.actions.setReconnectingStatus(false);
        })
        .fail(() => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Could not connect');
          }
        });
  }

  componentWillUnmount() {
    this.signalRHub && this.signalRHub.stop();
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset;
    if (scrollTop > 70) {
      this.setState({
        buttonbackFixTop: true,
      });
    } else {
      this.setState({
        buttonbackFixTop: false,
      });
    }
  }

  componentDidUpdate(prePros) {
    if (prePros.chat.receivers.length < this.props.chat.receivers.length) {
      this.scroll && this.scroll.scrollToBottom();
    }
  }

  handleSetPermission = (user, e) => {
    let value = [parseInt(e.target.value, 10)];

    if (value[0] === 3) {
      value = [1, 2];
    }

    this.receivers[user.nhanVienId] = value;
  };

  handleSendMessage = async (message, attachments = []) => {
    const cleanMessage = sanitizeHtml(message, {
      allowedTags: ['b', 'i', 'em', 'strong', 'ins', 'u', 'br', 'p', 'span'],
      allowedAttributes: {
        span: ['style'],
      },
      transformTags: {
        ins: 'u',
      },
    });

    let defaultSelect = {};
    let receivers = [];
    let surveyId = this.surveyId;
    this.props.chat.receivers.map((item) => (defaultSelect[item.nhanVienId] = [1]));
    let tempReceivers = Object.assign({}, defaultSelect, this.receivers);

    for (const prop in tempReceivers) {
      receivers.push({
        nhan_vien_id: prop,
        quyen: tempReceivers[prop],
      });
    }

    await this.props.actions
      .surveySend(surveyId, {
        phieu_khao_sat_id: surveyId,
        noi_dung_tin_nhan: cleanMessage,
        ds_nguoi_nhan: receivers,
      })
      .then((res) => {
        if (res.error) {
          throw new Error('Có lỗi xảy ra, vui lòng thử lại.');
        }
      });

    return new Promise((resolve, reject) => {
      if (!this.props.chat.receivers || !this.props.chat.receivers.length) {
        return reject(new Error(''));
      }

      if (this.signalRHub.connection.state !== 1) {
        return reject(new Error('Mất kết nối, vui lòng thử lại.'));
      }

      this.props.actions.chatSignalRPreSendMessage();
      const send = (conversationId) => {
        const messageModel = {
          noi_dung: cleanMessage,
          hoi_thoai_id: conversationId,
          phieu_khao_sat: [
            {
              phieu_khao_sat_id: surveyId,
              tieu_de: this.props.survey.item.tieuDe,
            },
          ],
          file_dinh_kem: [],
          tuy_chon: {
            sms: 0,
            email: 0,
          },
        };
        this.signalRHub
          .invoke('SendMessage', [messageModel])
          .done((res) => {
            return this.props.history.push('/');
          })
          .fail(() => {
            reject(new Error('Có lỗi xảy ra, vui lòng thử lại.'));
          });
      };

      if (this.props.conversationId) {
        send(this.props.conversationId);
      } else {
        this.props.actions.chatCreateConversation(this.props.chat.receivers).then((res) => {
          if (res.error) {
            return reject(res.error);
          }

          if (
            !res.payload ||
            !res.payload.data ||
            !res.payload.data.result ||
            !res.payload.data.result.hoiThoaiId
          ) {
            return reject(new Error('Có lỗi xảy ra, vui lòng thử lại.'));
          }

          this.props.actions.chatSetConversation(res.payload.data.result.hoiThoaiId);

          send(res.payload.data.result.hoiThoaiId);
        });
      }
    });
  };

  goBack = () => {
    return this.props.history.goBack();
  };

  handleRemoveUser = (user) => {
    this.props.actions.chatRemoveReceiver(user.nhanVienId);
  };

  render() {
    return (
      <div>
        <Header
          actions={this.props.actions}
          auth={this.props.auth}
          onClickSearch={this.toggleSearchMessage}
          onOpenEoffice={this.handleOpenEoffice}
          onOpenConfig={this.handleToggleConfig}
        />
        <div className="container-fluid">
          <button
            type="button"
            className={classnames('pt-button cpc-button-back', {
              'button-back-fixed': this.state.buttonbackFixTop,
            })}
            onClick={this.goBack}
          >
            <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
            <span className="button-content">Quay lại</span>
          </button>
          <div className="cpc-survey-container">
            <div className="survey-container">
              <section className="section-block-heading">
                <h2 className="heading-icon-20 main-heading-page">
                  <span className="heading-text">Gửi phiếu khảo sát</span>
                </h2>
              </section>
              <section className="section-block-send-page survey-chat-main ">
                <div className="row survey-chat-shadow">
                  <div className="col-xs-4 survey-left-content">
                    <ContactSection
                      actions={this.props.actions}
                      auth={this.props.auth}
                      contact={this.props.contact}
                      receivers={this.props.chat.receivers}
                      viewUnit={this.props.contact.viewUnit}
                      isSurvey
                      units={this.props.contact.units}
                    />
                  </div>
                  <div className="col-xs-8 survey-right-content">
                    <div className="survey-selector-container">
                      <h3 className="main-heading-block heading-16">Danh sách người nhận</h3>
                      <Scrollbars style={{ height: 500 }} ref={(e) => (this.scroll = e)}>
                        <div className="survey-selector-list">
                          {this.props.chat.receivers.map((item, k) => (
                            <div key={k} className="selector-item">
                              <div className="selector-block">
                                <div className="pt-select select-right">
                                  <select
                                    className="cpc-select-element"
                                    onChange={this.handleSetPermission.bind(this, item)}
                                  >
                                    <option value="1">Bỏ phiếu</option>
                                    <option value="2">Xem thống kê</option>
                                    <option value="3">Bỏ phiếu và Xem thống kê</option>
                                  </select>
                                </div>
                                <div className="user-info">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage: `url(${IMAGE_URL}${item.anhDaiDien})`,
                                    }}
                                  ></span>
                                  <div className="user-info">
                                    <h4 className="username">{item.tenNhanVien}</h4>
                                    <p className="intro">
                                      {item.phongBan ? item.phongBan.maPhongBan : ''}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  className="btn-delete"
                                  type="button"
                                  onClick={this.handleRemoveUser.bind(this, item)}
                                >
                                  <span className="icon-remove icon-action" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Scrollbars>
                    </div>
                    <ChatBox
                      ref={this.refHandlers.chatbox}
                      config={this.props.auth.config}
                      actions={this.props.actions}
                      receivers={this.props.chat.receivers}
                      survey={this.props.survey.item}
                      favourites={[]}
                      isSurvey
                      onSendMessage={this.handleSendMessage}
                      isSubmiting={this.props.delivered}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
  contact: {
    ...state.contact,
    viewUnit: state.entities.units[state.contact.viewUnit]
      ? {
          ...state.entities.units[state.contact.viewUnit],
          dsPhongBan: state.entities.units[state.contact.viewUnit].dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
        }
      : {},
    units: (() => {
      let { units } = state.contact;

      if (
        state.entities.units[state.auth.mainUnitId] &&
        units.indexOf(state.auth.mainUnitId) === -1
      ) {
        units.push(state.auth.mainUnitId);
      }

      return units.map((id) => {
        const unit = state.entities.units[id];
        if (!unit.dsPhongBan) {
          unit.dsPhongBan = [];
        }

        const getUnit = (data) => {
          if (!data || !_.isArray(data.dsDonViCon)) {
            return [];
          }

          return data.dsDonViCon.map((id) => {
            const unit = state.entities.units[id];
            return {
              ...unit,
              dsDonViCon: getUnit(unit),
              dsPhongBan: unit.dsPhongBan
                ? unit.dsPhongBan.map((id) => {
                    const department = state.entities.departments[id];
                    return {
                      ...department,
                      dsNhanVien: department.dsNhanVien
                        .map((id) => state.entities.users[id])
                        .filter((user) => {
                          if (state.contact.filterOnline) {
                            return user.trucTuyen === 1;
                          }

                          return true;
                        }),
                    };
                  })
                : [],
            };
          });
        };

        return {
          ...unit,
          dsPhongBan: unit.dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
          dsDonViCon: getUnit(unit),
        };
      });
    })(),
    favourites: state.contact.favourites.map((id) => {
      const favourite = state.entities.favourites[id];
      if (!favourite.dsNhanVien) {
        favourite.dsNhanVien = [];
      }
      return {
        ...favourite,
        users: favourite.dsNhanVien.map((item) => state.entities.users[item.nhanVienId]),
      };
    }),
    showProfile: state.contact.showProfile ? state.entities.users[state.contact.showProfile] : null,
    searchUnit: {
      ...state.contact.searchUnit,
      items: state.contact.searchUnit.items
        .map((id) => state.entities.users[id])
        .filter((user) => {
          if (state.contact.filterOnline) {
            return user.trucTuyen === 1;
          }

          return true;
        }),
    },
    searchAll: {
      ...state.contact.searchAll,
      items: state.contact.searchAll.items
        .map((id) => state.entities.users[id])
        .filter((user) => {
          if (state.contact.filterOnline) {
            return user.trucTuyen === 1;
          }

          return true;
        }),
    },
    searchFavourite: {
      ...state.contact.searchFavourite,
      items: state.contact.searchFavourite.items
        .map((id) => state.entities.users[id])
        .filter((user) => {
          if (state.contact.filterOnline) {
            return user.trucTuyen === 1;
          }

          return true;
        }),
    },
  },
  chat: {
    ...state.chat,
    receivers: state.chat?.receivers?.map((id) => {
      return state.entities.users[id];
    }),
  },

  survey: {
    ...state.surveys,
    item: state.entities.surveys[state.surveys.phieuKhaoSatId],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendSurveyPage);
