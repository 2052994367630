import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import classNames from 'classnames';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';
import CPCIcon from '../icon';

registerLocale('vi', vi);

function CPCDatePicker(props) {
  const { className, theme, hasClose = false, ...rest } = props;
  return (
    <div
      className={classNames('cpc-date', {
        [`theme-${theme}`]: theme,
        [`${className}`]: className,
      })}
    >
      <ReactDatePicker
        autoComplete="off"
        className={classNames('cpc-date-picker')}
        locale="vi"
        showMonthDropdown
        showYearDropdown
        {...rest}
      />
      {props?.selected && hasClose ? (
        <CPCIcon
          icon="xmark"
          size={8}
          className="close"
          onClick={() => (props?.onChange ? props?.onChange(null) : undefined)}
        />
      ) : (
        ''
      )}
      <div className="cpc-icon-over">
        <CPCIcon icon="calendar-v2" size={12} />
      </div>
    </div>
  );
}

export default CPCDatePicker;
