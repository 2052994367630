import React from 'react';
import TreeSelect, { TreeNode } from 'rc-tree-select';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import 'rc-tree-select/assets/index.css';

class SelectTree extends React.Component {
  constructor(props) {
    super(props);

    this.filterTreeNode = this.filterTreeNode.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  filterTreeNode = (input, child) => {
    let res = input.toLowerCase();
    let titleProps = child.props.title.toLowerCase();
    return String(titleProps).indexOf(res) === 0;
  };

  handleSelect(data) {
    return data.map((item) => (
      <TreeNode value={item.donViId} title={item.tenVietTat} key={item.donViId}>
        {item.dsDonViCon && item.dsDonViCon.length > 0 && this.handleSelect(item.dsDonViCon)}
      </TreeNode>
    ));
  }

  render() {
    let data = this.props.data || [];

    if (this.props.except) {
      data = data.filter(
        (item) => item.donViId !== this.props.except || item.donViCha === this.props.except
      );
    }

    return (
      <TreeSelect
        className={`dropdown-tree dropdown-tree-filter ${this.props.className}`}
        dropdownStyle={{ height: 470, minWidth: 100, overflow: 'auto' }}
        value={this.props.value || 'all'}
        treeDefaultExpandAll={false}
        treeNodeFilterProp="title"
        filterTreeNode={this.filterTreeNode}
        showSearch
        onChange={this.props.handleChange}
        notFoundContent="Không có kết quả"
        disabled={this.props.isLoading}
      >
        {this.props.except
          ? [
              <TreeNode value="all" title={this.props.firstText} key="all" />,
              ...this.handleSelect(data),
            ]
          : this.handleSelect(data)}
      </TreeSelect>
    );
  }
}

SelectTree.propTypes = {
  data: PropTypes.array.isRequired,
  exceptId: PropTypes.any,
  firstText: PropTypes.string,
};

SelectTree.defaultProps = {
  firstText: 'Chọn đơn vị',
};

export default SelectTree;
