import * as types from '@/constants/ActionTypes';
import variables from '@/constants/variables';
import last from 'lodash/last';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  messages: [],
  receivers: [],
  receiversSurvey: [],
  searchMessages: {
    items: [],
  },
  suggestUsers: {
    items: [],
  },
  news: 0,
  lastMessageId: null,
  conversationId: null,
  connectionId: null,
  loadReceiverUsers: false,
  delivered: false,
  idle: false,
  reconnectSignalR: false,
  importantMessageList: {},
  customText: '',
  birthdayUserList: [],
  deg: 0,
  fileIdFromEoffice: '',
  fileDinhKemFromEoffice: {},
  idMessageForward: '',
  removeIdFileFromQuoteMessage: [],
  removeMessageFromQuoteMessage: [],
  removeSurveyFromQuoteMessage: '',
  emoticonUpdate: [],
  closeLimitConfirm: false,
  conversations: [],
  conversationsIsLoading: false,
  isGroup: null,
  loadMoreBeforeMessages: {
    hasMore: false,
    loading: false,
  },
  currentConversation: {},
  newMessages: [],
  isReceiveNewMessage: false,
  loadMoreMessages: {
    hasMore: false,
    loading: false,
  },
  typeConversation: 'ALL',
  isScrollBottom: true,
  topPinedMessage: [],
  addTopChat: null,
  searchMessage: null,
  isLoadingSearchMessage: false,
  limitMessageCheck: {
    isBefore: false,
    isAfter: false,
  },
  messageSeen: null,
  messageReceived: null,
  isMessageLatest: false,
  isMessageNewest: false,
  isEditorLoading: false,
  allUsersConversation: [],
  isReconnectingStatus: false,
  isReconnect: false,
  audioPlayId: null,
  mentionedIds: [],
  conversationPinTopList: [],
  isLoadingConversationPinTop: false,
  contentEditor: null,
  isActivateAllTab: false,
};

const addMessageToGroup = (message, messages, push = true, lastMessages) => {
  let group = messages.find((g) => g.date === message.ngayTaoGroup);
  if (!group) {
    group = {
      date: message.ngayTaoGroup,
      items: [],
    };
    push ? messages.push(group) : messages.unshift(group);
  }
  push ? group.items.push(message.tinNhanId) : group.items.unshift(message.tinNhanId);

  if (lastMessages && lastMessages.date === group.date) {
    lastMessages.items.push(message);
    const orderList = lastMessages.items
      .sort(function (a, b) {
        return new Date(a.ngayTao) - new Date(b.ngayTao);
      })
      .map((item) => item.tinNhanId);

    group.items = [...group.items.filter((id) => orderList.indexOf(id) === -1), ...orderList];
  }

  return [...messages];
};

export default function chat(state = initialState, action) {
  let messages,
    message,
    hoiThoai,
    hoiThoaiId,
    cloneConversations,
    cloneConversationPinTopList,
    cloneCurrentConversation,
    newMessages,
    indexNhanVienHoiThoai,
    userId,
    tinNhanId = 0,
    dsTinNhanTheoId = [],
    dsNhanVienHoiThoai = [],
    hasConversationInPinTop = false,
    tempHoiThoai = {},
    temp,
    index,
    index2,
    indexNhanVienHoiThoai2,
    indexBiggerDate = -1;
  switch (action.type) {
    case types.AUTH_DISCARD_TOKEN:
      return {
        messages: [],
        receivers: [],
        searchMessages: {
          items: [],
        },
        suggestUsers: {
          items: [],
        },
        conversationId: null,
      };

    case types.CHAT_RESET:
      return {
        ...state,
        messages: [],
        searchMessages: {
          items: [],
        },
      };

    case types.CHAT_ADD_RECEIVER:
      if (state.receivers.find((userId) => userId === action.userId)) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        receivers: [...state.receivers, action.userId],
        conversationId: null,
      };

    case types.CHAT_ADD_RECEIVER_SURVEY:
      if (state.receiversSurvey.find((userId) => userId === action.userId)) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        receiversSurvey: [...state.receiversSurvey, action.userId],
      };

    case types.CHAT_ADD_MULTIPLE_RECEIVERS:
      let receivers = [];
      for (const user of action.users) {
        if (state.receivers.indexOf(user.nhanVienId) >= 0) {
          continue;
        }
        receivers.push(user.nhanVienId);
      }
      return {
        ...state,
        receivers: [...state.receivers, ...receivers],
        conversationId: null,
      };

    case types.CHAT_ADD_RECEIVERS_SURVEY:
      let receiversSurvey = [];
      for (const user of action.users) {
        if (state.receiversSurvey.indexOf(user.nhanVienId) >= 0) {
          continue;
        }
        receiversSurvey.push(user.nhanVienId);
      }
      return {
        ...state,
        receiversSurvey: [...state.receiversSurvey, ...receiversSurvey],
      };

    case types.CHAT_REMOVE_RECEIVER:
      return {
        ...state,
        receivers: state.receivers.filter((userId) => userId !== action.userId),
        conversationId: null,
      };

    case types.CHAT_REMOVE_RECEIVER_SURVEY:
      return {
        ...state,
        receiversSurvey: state.receiversSurvey.filter((userId) => userId !== action.userId),
        conversationId: null,
      };

    case types.CHAT_REMOVE_MULTIPLE_RECEIVERS:
      return {
        ...state,
        receivers: state.receivers.filter(
          (userId) => !action.users.find((user) => user.nhanVienId === userId)
        ),
        conversationId: null,
      };

    case types.CHAT_REMOVE_RECEIVERS_SURVEY:
      return {
        ...state,
        receiversSurvey: state.receiversSurvey.filter(
          (userId) => !action.users.find((user) => user.nhanVienId === userId)
        ),
        conversationId: null,
      };

    case types.CHAT_CLEAR_RECEIVER:
      return {
        ...state,
        receivers: [],
        conversationId: null,
      };

    case types.CHAT_SET_CONVERSATION:
      return {
        ...state,
        conversationId: action.conversationId,
      };

    case types.CHAT_SET_CONNECTION:
      return {
        ...state,
        connectionId: action.connectionId,
      };

    case types.CHAT_FETCH_MESSAGES:
      messages = state.messages;
      for (const messageId of action.payload.data.result.result.items.reverse()) {
        message = action.payload.data.entities.messages[messageId];
        messages = addMessageToGroup(message, messages);
      }
      return {
        ...state,
        messages,
        lastMessageId: last(messages) ? last(last(messages).items) : null,
      };

    case types.CHAT_FETCH_MORE_MESSAGES:
      messages = state.messages;
      for (const messageId of action.payload.data.result.result.items) {
        message = action.payload.data.entities.messages[messageId];
        messages = addMessageToGroup(message, messages, false);
      }
      return {
        ...state,
        messages,
      };

    case types.CHAT_SIGNALR_NEW_MESSAGE:
      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      tinNhanId = action?.payload?.data?.result;
      dsTinNhanTheoId = action?.payload?.data?.entities?.messages;
      index = cloneConversations.findIndex(
        (item) => item.hoiThoaiId === dsTinNhanTheoId?.[tinNhanId].hoiThoaiId
      );
      index2 = state.conversationPinTopList.findIndex(
        (item) => item.hoiThoaiId === dsTinNhanTheoId?.[tinNhanId].hoiThoaiId
      );
      if (index !== -1 || index2 !== -1) {
        // Nếu hội thoại có trong ds hội thoại hiện tại (thường và hội thoại ghim)
        hoiThoai = dsTinNhanTheoId?.[tinNhanId]?.hoiThoai;
        if (index !== -1) {
          // Hội thoại thường
          if (cloneConversations[index]?.hoiThoaiId !== state?.currentConversation?.hoiThoaiId) {
            // Nếu khác hội thoại đang focus
            cloneConversations[index].tongTinNhan =
              (cloneConversations[index]?.tongTinNhan || 0) + 1;
          }

          cloneConversations = [
            {
              ...cloneConversations[index],
              lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
              tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
            },
            ...cloneConversations,
          ];
          cloneConversations.splice(index + 1, 1);
        }

        if (index2 !== -1) {
          // Hội thoại ghim
          tempHoiThoai = {
            ...cloneConversationPinTopList[index2],
            // Nếu khác hội thoại đang focus
            ...(cloneConversationPinTopList[index2]?.hoiThoaiId !==
            state?.currentConversation?.hoiThoaiId
              ? { tongTinNhan: (cloneConversationPinTopList[index2]?.tongTinNhan || 0) + 1 }
              : {}),
            lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
            tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
          };
          cloneConversationPinTopList.splice(index2, 1, tempHoiThoai);
        }
      } else {
        // Nếu hội thoại không có trong ds hội thoại hiện tại (thường và hội thoại ghim)
        // Kiểm tra thuộc hội thoại thường hay hội thoại ghim để xử lý
        hoiThoai = dsTinNhanTheoId?.[tinNhanId]?.hoiThoai;
        hasConversationInPinTop = hoiThoai.nhanVienHoiThoai?.some(
          (s) => s?.nhanVien === action.payload?.auth?.user && s?.ngayGhim
        );
        if (
          action.payload?.typeConversation === 'ALL' ||
          action.payload?.typeConversation === hoiThoai?.isGroup
        ) {
          if (hasConversationInPinTop) {
            // Hội thoại thuộc ghim top
            // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
            indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
              return (
                new Date(item.lanHoatDongCuoi).getTime() <
                new Date(hoiThoai?.lanHoatDongCuoi).getTime()
              );
            });
            tempHoiThoai = {
              ...hoiThoai,
              nhanVienHoiThoai: hoiThoai.nhanVienHoiThoai.map((nVHoiThoai) => ({
                ...nVHoiThoai,
                nhanVien: action.payload?.data?.entities?.conversations[nVHoiThoai.nhanVien],
              })),
              lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
              tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
            };
            if (indexBiggerDate !== -1) {
              cloneConversationPinTopList.splice(indexBiggerDate, 0, tempHoiThoai);
            } else {
              cloneConversationPinTopList = [tempHoiThoai, ...cloneConversationPinTopList];
            }
          } else {
            cloneConversations = [
              {
                ...hoiThoai,
                nhanVienHoiThoai: hoiThoai.nhanVienHoiThoai.map((nVHoiThoai) => ({
                  ...nVHoiThoai,
                  nhanVien: action.payload?.data?.entities?.conversations[nVHoiThoai.nhanVien],
                })),
                lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
                tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
              },
              ...cloneConversations,
            ];
          }
        }
      }

      return {
        ...state,
        ...(state.currentConversation?.hoiThoaiId === dsTinNhanTheoId?.[tinNhanId].hoiThoaiId
          ? {
              newMessages: state.loadMoreBeforeMessages?.hasMore
                ? state.newMessages
                : [...(state?.newMessages || []), dsTinNhanTheoId?.[tinNhanId]],
              isReceiveNewMessage: true,
            }
          : {}),
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
      };

    case types.CHAT_SIGNALR_SEND_MESSAGE:
      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      tinNhanId = action?.payload?.data?.result?.entity;
      dsTinNhanTheoId = action?.payload?.data?.entities?.messages;
      index = cloneConversations.findIndex(
        (item) => item.hoiThoaiId === dsTinNhanTheoId?.[tinNhanId].hoiThoaiId
      );
      index2 = state.conversationPinTopList.findIndex(
        (item) => item.hoiThoaiId === dsTinNhanTheoId?.[tinNhanId].hoiThoaiId
      );
      if (index !== -1 || index2 !== -1) {
        // Nếu hội thoại có trong ds hội thoại hiện tại (thường và hội thoại ghim)
        hoiThoai = dsTinNhanTheoId?.[tinNhanId]?.hoiThoai;
        if (index !== -1) {
          // Hội thoại thường
          cloneConversations = [
            {
              ...cloneConversations[index],
              lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
              tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
            },
            ...cloneConversations,
          ];
          cloneConversations.splice(index + 1, 1);
        }

        if (index2 !== -1) {
          // Hội thoại ghim
          tempHoiThoai = {
            ...cloneConversationPinTopList[index2],
            lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
            tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
          };
          cloneConversationPinTopList.splice(index2, 1, tempHoiThoai);
        }
      } else {
        // Nếu hội thoại không có trong ds hội thoại hiện tại (thường và hội thoại ghim)
        // Kiểm tra thuộc hội thoại thường hay hội thoại ghim để xử lý
        hoiThoai = dsTinNhanTheoId?.[tinNhanId]?.hoiThoai;
        hasConversationInPinTop = hoiThoai.nhanVienHoiThoai?.some(
          (s) => s?.nhanVien === action.payload?.auth?.user && s?.ngayGhim
        );
        if (
          action.payload?.typeConversation === 'ALL' ||
          action.payload?.typeConversation === hoiThoai?.isGroup
        ) {
          if (hasConversationInPinTop) {
            // Hội thoại thuộc ghim top

            // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
            indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
              return (
                new Date(item.lanHoatDongCuoi).getTime() <
                new Date(hoiThoai?.lanHoatDongCuoi).getTime()
              );
            });
            tempHoiThoai = {
              ...hoiThoai,
              nhanVienHoiThoai: hoiThoai.nhanVienHoiThoai.map((nVHoiThoai) => ({
                ...nVHoiThoai,
                nhanVien: action.payload?.data?.entities?.conversations[nVHoiThoai.nhanVien],
              })),
              lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
              tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
            };
            if (indexBiggerDate !== -1) {
              cloneConversationPinTopList.splice(indexBiggerDate, 0, tempHoiThoai);
            } else {
              cloneConversationPinTopList = [tempHoiThoai, ...cloneConversationPinTopList];
            }
          } else {
            cloneConversations = [
              {
                ...hoiThoai,
                nhanVienHoiThoai: hoiThoai.nhanVienHoiThoai.map((nVHoiThoai) => ({
                  ...nVHoiThoai,
                  nhanVien: action.payload?.data?.entities?.conversations[nVHoiThoai.nhanVien],
                })),
                lanHoatDongCuoi: hoiThoai?.lanHoatDongCuoi,
                tinNhanCuoi: dsTinNhanTheoId?.[tinNhanId],
              },
              ...cloneConversations,
            ];
          }
        }
      }

      return {
        ...state,
        ...(state.currentConversation?.hoiThoaiId &&
        state.currentConversation?.hoiThoaiId === dsTinNhanTheoId?.[tinNhanId]?.hoiThoaiId
          ? {
              newMessages: state.loadMoreBeforeMessages?.hasMore
                ? [...action?.payload?.messages?.reverse(), dsTinNhanTheoId?.[tinNhanId]]
                : [...(state?.newMessages || []), dsTinNhanTheoId?.[tinNhanId]],
              isReceiveNewMessage: true,
              loadMoreMessages: {
                hasMore: state.loadMoreBeforeMessages?.hasMore
                  ? action?.payload?.messages?.length === variables.LOAD_MORE.MESSAGE_LIMIT
                  : state.loadMoreMessages?.hasMore || false,
                loading: false,
              },
            }
          : {}),
        loadMoreBeforeMessages: {
          hasMore: false,
          loading: false,
        },
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
      };

    case types.SET_RECEIVE_NEW_MESSAGE:
      return {
        ...state,
        isReceiveNewMessage: false,
      };

    case types.CHAT_READ_ALL_MESSAGES:
      return {
        ...state,
        news: 0,
      };

    case types.CHAT_SET_IDLE_STATUS:
      return Object.assign({}, state, { idle: action.status });

    case types.CHAT_SIGNALR_PRE_SEND_MESSAGE:
      return {
        ...state,
        delivered: false,
      };

    case types.CHAT_SIGNALR_MESSAGE_DELETED:
      messages = state.messages
        .map((group) => ({
          ...group,
          items:
            group.items && group.items.length
              ? group.items.filter((messageId) => messageId !== action.messageId)
              : [],
        }))
        .filter((group) => group.items.length);

      return {
        ...state,
        messages,
        searchMessages: {
          ...state.searchMessages,
          items:
            state.searchMessages.items && state.searchMessages.items.length
              ? state.searchMessages.items.filter((messageId) => messageId !== action.messageId)
              : [],
        },
        lastMessageId: last(messages) ? last(last(messages).items) : null,
      };

    case types.CHAT_REMOVE_MESSAGE:
      messages = state.messages
        .map((group) => ({
          ...group,
          items:
            group.items && group.items.length
              ? group.items.filter(
                  (messageId) => messageId !== action.meta.previousAction.messageId
                )
              : [],
        }))
        .filter((group) => group.items.length);
      return {
        ...state,
        messages,
        searchMessages: {
          ...state.searchMessages,
          total: state.searchMessages.total ? state.searchMessages.total - 1 : 0,
          items:
            state.searchMessages.items && state.searchMessages.items.length
              ? state.searchMessages.items.filter(
                  (messageId) => messageId !== action.meta.previousAction.messageId
                )
              : [],
        },
        lastMessageId: last(messages) ? last(last(messages).items) : null,
      };

    case types.CHAT_SIGNALR_MESSAGES_DELETED:
      newMessages = state?.newMessages;
      for (const o of action.messages) {
        newMessages = newMessages.filter((item) => item?.tinNhanId !== o);
      }

      index = (state.conversations || []).findIndex(
        (item) => item.hoiThoaiId === state.currentConversation?.hoiThoaiId
      );

      if (index === -1) {
        return {
          ...state,
          newMessages,
        };
      }

      cloneConversations = [...state.conversations];

      cloneConversations[index].tinNhanCuoi = newMessages[newMessages.length - 1] || null;

      return {
        ...state,
        newMessages,
        conversations: cloneConversations,
        conversationPinTopList: state.conversationPinTopList?.map((conversation) =>
          action.messages.some((s) => s === conversation.tinNhanCuoi?.tinNhanId)
            ? { ...conversation, tinNhanCuoi: null }
            : conversation
        ),
      };

    case types.CHAT_REMOVE_MULTIPLE_MESSAGE:
      messages = state.messages
        .map((group) => ({
          ...group,
          items:
            group.items && group.items.length
              ? group.items.filter(
                  (messageId) =>
                    !action.meta.previousAction.messages.find(
                      (message) => message.tinNhanId === messageId
                    )
                )
              : [],
        }))
        .filter((group) => group.items.length);
      return {
        ...state,
        messages,
        searchMessages: {
          ...state.searchMessages,
          items:
            state.searchMessages.items && state.searchMessages.items.length
              ? state.searchMessages.items.filter(
                  (messageId) =>
                    !action.meta.previousAction.messages.find(
                      (message) => message.tinNhanId === messageId
                    )
                )
              : [],
        },
        lastMessageId: last(messages) ? last(last(messages).items) : null,
      };

    case types.CHAT_SEARCH_MESSAGES:
      let searchMessages = state.searchMessages;
      if (action.meta.previousAction.page === 1) {
        searchMessages = action.payload.data.result.result;
        if (!searchMessages.items) {
          searchMessages.items = [];
        }
      } else {
        searchMessages.page = action.meta.previousAction.page;
        if (
          action.payload.data.result.result.items &&
          action.payload.data.result.result.items.length
        ) {
          searchMessages.items = [
            ...searchMessages.items,
            ...action.payload.data.result.result.items,
          ];
        }
      }
      return {
        ...state,
        searchMessages: searchMessages,
      };

    case types.CHAT_SEARCH_CLEAR:
      return {
        ...state,
        searchMessages: {
          items: [],
        },
      };

    case types.CHAT_SEARCH_SUGGEST_CLEAR:
      return {
        ...state,
        suggestUsers: {
          items: [],
        },
      };

    case types.CHAT_SEARCH_SUGGEST_USER:
      let suggestUsers = state.suggestUsers;
      if (action.meta.previousAction.page === 1) {
        suggestUsers = action.payload.data.result.result;
        if (!suggestUsers.items) {
          suggestUsers.items = [];
        }
      } else {
        suggestUsers.page = action.meta.previousAction.page;
        if (
          action.payload.data.result.result.items &&
          action.payload.data.result.result.items.length
        ) {
          suggestUsers.items = [...suggestUsers.items, ...action.payload.data.result.result.items];
        }
      }
      return {
        ...state,
        suggestUsers: suggestUsers,
      };

    case types.PRE_CHAT_GET_RECEIVER_CONVERSATION:
      return {
        ...state,
        loadReceiverUsers: isNaN(action.loading) ? true : action.loading,
      };

    case types.CHAT_GET_RECEIVER_CONVERSATION:
      return {
        ...state,
        loadReceiverUsers: false,
      };

    case types.CHAT_GET_NEW_MESSAGES:
      messages = state.messages;
      let tinNhanMoi = [];
      for (const messageId of action.payload.data.result.result.items) {
        message = action.payload.data.entities.messages[messageId];
        tinNhanMoi = [...tinNhanMoi, message];
        messages = addMessageToGroup(message, messages);
      }

      return {
        ...state,
        messages,
        newMessages: [...(state?.newMessages || []), ...tinNhanMoi],
        lastMessageId: last(messages) ? last(last(messages).items) : null,
      };

    case types.COMMON_RECONNECT_SIGNALR:
      return {
        ...state,
        reconnectSignalR: action.data,
      };
    case types.ADD_IMPORTANT_LIST:
      if (action.payload.data.result) {
        let { id } = action.meta.previousAction.message;
        index = state?.newMessages.findIndex((item) => item.tinNhanId === id);
        if (index === -1) {
          return {
            ...state,
          };
        }

        newMessages = [...(state?.newMessages || [])];
        newMessages[index].danhDau = !newMessages[index].danhDau;
        return {
          ...state,
          newMessages,
        };
      }
      return state;
    case types.CREAT_CUSTOM_TEXT_CHAT:
      return {
        ...state,
        customText: action.data,
      };
    case types.GET_BIRTHDAY_USER_LIST:
      return {
        ...state,
        birthdayUserList: action.payload.data.result,
      };
    case types.HANDLE_ROTATE_IMG:
      return {
        ...state,
        deg: state.deg + 90,
      };
    case types.RESET_DEG_IMG:
      return {
        ...state,
        deg: 0,
      };
    case types.SET_FILE_ID_FROM_EOFFICE:
      return {
        ...state,
        fileIdFromEoffice: action.payload.data,
      };
    case types.SET_INFO_GET_FILE_DINH_KEM_FROM_EOFFICE:
      return {
        ...state,
        fileDinhKemFromEoffice: {
          id: action.payload.data.id,
          type: action.payload.data.type,
        },
      };
    case types.SET_ID_MESSAGE_FORWARD:
      return {
        ...state,
        idMessageForward: action.payload.data,
      };
    case types.REMOVE_ID_FILE_FROM_QUOTE_MESSAGE:
      if (action.payload.data) {
        return {
          ...state,
          removeIdFileFromQuoteMessage: [
            ...state.removeIdFileFromQuoteMessage,
            action.payload.data,
          ],
        };
      } else {
        return {
          ...state,
          removeIdFileFromQuoteMessage: [],
        };
      }
    case types.REMOVE_MESSAGE_FROM_QUOTE_MESSAGE:
      if (action.payload.data) {
        return {
          ...state,
          removeMessageFromQuoteMessage: [
            ...state.removeMessageFromQuoteMessage,
            action.payload.data,
          ],
        };
      } else {
        return {
          ...state,
          removeMessageFromQuoteMessage: [],
        };
      }
    case types.REMOVE_SURVEY_FROM_QUOTE_MESSAGE:
      if (action.payload.data) {
        return {
          ...state,
          removeSurveyFromQuoteMessage: action.payload.data,
        };
      } else {
        return {
          ...state,
          removeSurveyFromQuoteMessage: [],
        };
      }
    case types.CHAT_SIGNALR_UPDATE_MESSAGE:
      const payload = action.payload;
      newMessages = [...(state?.newMessages || [])];
      index = newMessages.findIndex((item) => item.tinNhanId === payload?.tinNhanId);
      if (index !== -1) {
        newMessages[index].dsThongKeEmoticon = payload?.dsThongKeEmoticon;
        return {
          ...state,
          newMessages: newMessages,
        };
      }

      return state;

    case types.CHAT_CLOSE_LIMIT_CONFIRM:
      return {
        ...state,
        closeLimitConfirm: action.payload || false,
      };
    case types.API_REQUEST_GET_CONVERSATION_LOADING:
      return {
        ...state,
        conversationsIsLoading: action?.payload,
      };
    case types.API_REQUEST_GET_CONVERSATION:
      const hoithoaiId = action?.meta?.previousAction?.payload?.request?.params?.hoithoaiId;
      temp = action?.payload?.data?.result?.items || [];
      cloneConversations = hoithoaiId ? [...(state?.conversations || []), ...temp] : temp;

      return {
        ...state,
        conversationsIsLoading: false,
        conversations: cloneConversations,
      };
    case types.SAVE_CURRENT_CONVERSATION_DEFAULT:
      hoiThoai = state.addTopChat;
      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      if (hoiThoai) {
        // Nếu có hội thoại addTop
        cloneCurrentConversation = hoiThoai;
        // Kiểm tra thuộc hội thoại thường hay hội thoại ghim để xử lý
        hasConversationInPinTop = hoiThoai.nhanVienHoiThoai?.some(
          (s) => s?.nhanVien?.nhanVienId === action.payload?.auth?.user && s?.ngayGhim
        );
        if (hasConversationInPinTop) {
          index2 = state?.conversationPinTopList?.findIndex(
            (f) => f.hoiThoaiId === hoiThoai.hoiThoaiId
          );
          // Hội thoại thuộc ghim top
          if (index2 === -1) {
            // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
            indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
              return (
                new Date(item.lanHoatDongCuoi).getTime() <
                new Date(hoiThoai.lanHoatDongCuoi).getTime()
              );
            });
            if (indexBiggerDate !== -1) {
              cloneConversationPinTopList.splice(indexBiggerDate, 0, hoiThoai);
            } else {
              cloneConversationPinTopList = [hoiThoai, ...cloneConversationPinTopList];
            }
          }
        } else {
          cloneConversations = cloneConversations?.filter(
            (f) => f.hoiThoaiId !== hoiThoai.hoiThoaiId
          );
          cloneConversations = [hoiThoai, ...cloneConversations];
        }
      } else {
        // Nếu không có hội thoại addTop
        if (cloneConversationPinTopList?.length > 0 ?? false) {
          cloneCurrentConversation = cloneConversationPinTopList[0];
        } else {
          cloneCurrentConversation = cloneConversations[0];
        }
      }

      return {
        ...state,
        currentConversation: cloneCurrentConversation,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
        addTopChat: null,
      };
    case types.SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.data,
      };

    case types.API_REQUEST_GET_MESSAGES:
      hoiThoaiId = get(action, 'meta.previousAction.payload.hoiThoaiId');
      temp = get(action, 'payload.data.result.items', []);

      if (hoiThoaiId === state.currentConversation?.hoiThoaiId) {
        return {
          ...state,
          newMessages: [...temp.reverse(), ...(state?.newMessages || [])],
          loadMoreMessages: {
            hasMore: temp.length === variables.LOAD_MORE.MESSAGE_LIMIT,
          },
        };
      }

      return {
        ...state,
      };

    case types.API_REQUEST_GET_AFTER_MESSAGES:
      hoiThoaiId = get(action, 'meta.previousAction.payload.hoiThoaiId');
      temp = get(action, 'payload.data.result.items', []);

      if (hoiThoaiId === state.currentConversation?.hoiThoaiId) {
        return {
          ...state,
          newMessages: [...(state?.newMessages || []), ...temp],
          loadMoreBeforeMessages: {
            ...state.loadMoreBeforeMessages,
            hasMore: temp.length === variables.LOAD_MORE.MESSAGE_LIMIT,
          },
        };
      }

      return {
        ...state,
      };

    case types.SET_IS_SCROLL_BOTTOM:
      return {
        ...state,
        isScrollBottom: action.data,
      };

    case types.REMOVE_MESSAGE_OF_CONVERSATION:
      return {
        ...state,
        newMessages: [],
      };

    case types.SET_LOADING_MESSAGE:
      return {
        ...state,
        loadMoreMessages: { ...state.loadMoreMessages, loading: action.loading },
      };

    case types.SET_LOADING_AFTER_MESSAGE:
      return {
        ...state,
        loadMoreBeforeMessages: { ...state.loadMoreBeforeMessages, loading: action.loading },
      };

    case types.CREATE_NEW_CONVERSATION:
      hoiThoai = action?.conversation;
      if (hoiThoai) {
        cloneConversations = [...(state.conversations || [])];
        cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
        index = state?.conversations?.findIndex((f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId);
        index2 = state?.conversationPinTopList?.findIndex(
          (f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId
        );
        if (index !== -1 || index2 !== -1) {
          if (index !== -1) {
            cloneConversations = [hoiThoai, ...cloneConversations];
            cloneConversations.splice(index + 1, 1);
          }
        } else {
          // Nếu hội thoại không có trong ds hội thoại hiện tại (thường và hội thoại ghim)
          // Kiểm tra thuộc hội thoại thường hay hội thoại ghim để xử lý
          hasConversationInPinTop = hoiThoai.nhanVienHoiThoai?.some(
            (s) => s?.nhanVien?.nhanVienId === action.auth?.user && s?.ngayGhim
          );
          if (hasConversationInPinTop) {
            // Hội thoại thuộc ghim top
            // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
            indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
              return (
                new Date(item.lanHoatDongCuoi).getTime() <
                new Date(hoiThoai?.lanHoatDongCuoi).getTime()
              );
            });
            if (indexBiggerDate !== -1) {
              cloneConversationPinTopList.splice(indexBiggerDate, 0, hoiThoai);
            } else {
              cloneConversationPinTopList = [hoiThoai, ...cloneConversationPinTopList];
            }
          } else {
            cloneConversations = [hoiThoai, ...cloneConversations];
          }
        }

        return {
          ...state,
          ...(action?.isMe
            ? {
                currentConversation: hoiThoai,
              }
            : {}),
          conversations: cloneConversations,
          conversationPinTopList: cloneConversationPinTopList,
        };
      } else {
        return state;
      }
    case types.REMOVE_UNREAD_MESSAGE:
      cloneConversations = [...(state.conversations || [])];
      index = cloneConversations.findIndex((item) => item.hoiThoaiId === action?.data?.hoiThoaiId);
      if (index !== -1) {
        indexNhanVienHoiThoai = cloneConversations[index].nhanVienHoiThoai?.findIndex(
          (item) => item?.nhanVien?.nhanVienId === action?.auth?.user
        );
        if (indexNhanVienHoiThoai !== -1) {
          cloneConversations[index].nhanVienHoiThoai[indexNhanVienHoiThoai].soTinDaDoc =
            cloneConversations[index]?.tongTinNhan;
        }
      }

      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      index2 = cloneConversationPinTopList.findIndex(
        (item) => item.hoiThoaiId === action?.data?.hoiThoaiId
      );
      if (index2 !== -1) {
        indexNhanVienHoiThoai2 = cloneConversationPinTopList[index2].nhanVienHoiThoai?.findIndex(
          (item) => item?.nhanVien?.nhanVienId === action?.auth?.user
        );
        if (indexNhanVienHoiThoai2 !== -1) {
          cloneConversationPinTopList[index2].nhanVienHoiThoai[indexNhanVienHoiThoai2].soTinDaDoc =
            cloneConversationPinTopList[index2]?.tongTinNhan;
        }
      }

      return {
        ...state,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
      };
    case types.UPDATE_CONVERSATION:
      hoiThoai = action?.payload;
      index = state.conversations.findIndex((o) => o.hoiThoaiId === hoiThoai?.hoiThoaiId);
      index2 = state.conversationPinTopList.findIndex((o) => o.hoiThoaiId === hoiThoai?.hoiThoaiId);
      cloneConversations = [...state.conversations];
      cloneConversationPinTopList = [...state.conversationPinTopList];
      if (index !== -1) {
        cloneConversations[index] = {
          ...cloneConversations[index],
          tenHoiThoai: hoiThoai?.tenHoiThoai,
          anhDaiDien: hoiThoai?.anhDaiDien,
        };
      }

      if (index2 !== -1) {
        cloneConversationPinTopList[index2] = {
          ...cloneConversationPinTopList[index2],
          tenHoiThoai: hoiThoai?.tenHoiThoai,
          anhDaiDien: hoiThoai?.anhDaiDien,
        };
      }

      return {
        ...state,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
        currentConversation:
          state?.currentConversation?.hoiThoaiId === hoiThoai?.hoiThoaiId
            ? {
                ...state?.currentConversation,
                tenHoiThoai: hoiThoai?.tenHoiThoai,
                anhDaiDien: hoiThoai?.anhDaiDien,
              }
            : state?.currentConversation,
      };
    case types.LEAVE_CONVERSATION:
      cloneConversations = state?.conversations?.filter(
        (elm) => elm?.hoiThoaiId !== action?.payload?.hoiThoaiId
      );

      return {
        ...state,
        conversations: cloneConversations,
        currentConversation: cloneConversations?.[0],
        newMessages: [],
        conversationPinTopList: state.conversationPinTopList?.filter(
          (elm) => elm?.hoiThoaiId !== action?.payload?.hoiThoaiId
        ),
      };
    case types.UPDATE_CONVERSATION_INFO:
      return {
        ...state,
        conversations: state?.conversations?.map((elm) => {
          if (elm?.hoiThoaiId === action?.payload?.hoiThoaiId) {
            return {
              ...elm,
              ...action?.payload?.data,
            };
          }

          return elm;
        }),
        currentConversation:
          action?.payload?.hoiThoaiId === state?.currentConversation?.hoiThoaiId
            ? {
                ...state?.currentConversation,
                ...action?.payload?.data,
              }
            : state?.currentConversation,
        conversationPinTopList: state.conversationPinTopList.map((elm) => {
          if (elm?.hoiThoaiId === action?.payload?.hoiThoaiId) {
            return {
              ...elm,
              ...action?.payload?.data,
            };
          }

          return elm;
        }),
      };
    case types.CONVERSATION_DETAIL:
      // SET data topPinedMessage from
      // SET data allUsersConversation
      return {
        ...state,
        allUsersConversation: action?.payload?.data?.result?.nhanVienHoiThoai || [],
        topPinedMessage: action?.payload?.data?.result?.tinNhanGhim || [],
      };
    case types.DELETE_USER_CONVERATION:
      const newConversationList = state?.conversations
        ?.filter(
          (e) =>
            e?.hoiThoaiId !== action.payload?.hoiThoai.hoiThoaiId ||
            (e?.hoiThoaiId === action.payload?.hoiThoai.hoiThoaiId && !action.payload?.isDeletedMe)
        )
        ?.map((conversation) =>
          conversation?.hoiThoaiId === action.payload?.hoiThoai.hoiThoaiId
            ? action.payload?.hoiThoai
            : conversation
        );
      cloneCurrentConversation =
        state?.currentConversation?.hoiThoaiId === action.payload?.hoiThoai.hoiThoaiId
          ? action.payload?.hoiThoai
          : state?.currentConversation;

      let newAllUsersConversation = state?.allUsersConversation;
      if (state?.currentConversation?.hoiThoaiId === action.payload?.hoiThoai.hoiThoaiId) {
        newAllUsersConversation = newAllUsersConversation?.filter(
          (user) => user?.nhanVien?.nhanVienId !== action.payload?.nhanVienDeleteId
        );
      }

      return {
        ...state,
        conversations: newConversationList,
        currentConversation: !action.payload?.isDeletedMe
          ? cloneCurrentConversation
          : newConversationList[0],
        allUsersConversation: newAllUsersConversation,
        conversationPinTopList: state.conversationPinTopList
          .filter(
            (e) =>
              e?.hoiThoaiId !== action?.payload?.hoiThoai.hoiThoaiId ||
              (e?.hoiThoaiId === action?.payload?.hoiThoai.hoiThoaiId &&
                !action?.payload?.isDeletedMe)
          )
          ?.map((conversation) =>
            conversation?.hoiThoaiId === action?.payload?.hoiThoai.hoiThoaiId
              ? action?.payload.hoiThoai
              : conversation
          ),
      };
    case types.RESET_TOP_PIN_MESSAGES:
      return {
        ...state,
        topPinedMessage: [],
      };
    case types.ADD_USERS_CONVERSATION:
      hoiThoai = action.payload?.data?.result;
      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      index = cloneConversations?.findIndex((f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId);
      index2 = cloneConversationPinTopList?.findIndex((f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId);
      if (index !== -1) {
        cloneConversations = cloneConversations.map((elm) =>
          elm.hoiThoaiId === hoiThoai.hoiThoaiId ? hoiThoai : elm
        );
      }

      if (index2 !== -1) {
        cloneConversationPinTopList = cloneConversationPinTopList.map((elm) =>
          elm.hoiThoaiId === hoiThoai.hoiThoaiId ? hoiThoai : elm
        );
      }

      return {
        ...state,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
        currentConversation:
          state?.currentConversation?.hoiThoaiId === hoiThoai?.hoiThoaiId
            ? hoiThoai
            : state?.currentConversation,
      };
    case types.SIGNALR_ADD_USERS:
      return {
        ...state,
        allUsersConversation: [...state.allUsersConversation, ...action?.payload],
      };
    case types.RECALL_MESSAGE:
      return {
        ...state,
        topPinedMessage: [],
      };

    case types.CLEAN_CONTEXT_CONVERSATION:
      return {
        ...state,
        currentConversation: {},
        conversations: [],
        conversationPinTopList: [],
        newMessages: [],
        isReceiveNewMessage: false,
        loadMoreMessages: {
          hasMore: true,
          loading: false,
        },
        loadMoreBeforeMessages: {
          hasMore: true,
          loading: false,
        },
        typeConversation: 'ALL',
        isScrollBottom: true,
        topPinedMessage: [],
        searchMessage: null,
        isLoadingSearchMessage: null,
      };

    case types.UPDATE_MESSAGE:
      message = action.payload;
      newMessages = [...(state?.newMessages || [])];
      index = newMessages.findIndex((item) => item.tinNhanId === message?.tinNhanId);
      if (index !== -1) {
        newMessages[index] = message;
      }

      return {
        ...state,
        newMessages: newMessages,
        conversations: state.conversations?.map((conversation) =>
          conversation?.hoiThoaiId === message?.hoiThoaiId
            ? {
                ...conversation,
                tinNhanCuoi:
                  conversation?.tinNhanCuoi?.tinNhanId === message?.tinNhanId
                    ? { ...conversation?.tinNhanCuoi, noiDung: message?.noiDung }
                    : conversation?.tinNhanCuoi,
              }
            : conversation
        ),
        currentConversation:
          state.currentConversation?.hoiThoaiId === message?.hoiThoaiId
            ? {
                ...state.currentConversation,
                tinNhanCuoi: {
                  ...state.currentConversation?.tinNhanCuoi,
                  noiDung: message?.noiDung,
                },
              }
            : state.currentConversation,
        conversationPinTopList: state.conversationPinTopList?.map((conversation) =>
          conversation?.hoiThoaiId === action?.payload?.hoiThoaiId
            ? {
                ...conversation,
                tinNhanCuoi:
                  conversation?.tinNhanCuoi?.tinNhanId === action?.payload?.tinNhanId
                    ? { ...conversation?.tinNhanCuoi, noiDung: action?.payload?.noiDung }
                    : conversation?.tinNhanCuoi,
              }
            : conversation
        ),
      };

    case types.DELETE_CONVERSATION_GENERAL:
      let indexCurrent = 0;
      const indexDelete = state?.conversations?.findIndex(
        (elm) => elm?.hoiThoaiId === action?.payload
      );
      if (indexDelete > 0 && indexDelete - 1 <= state?.conversations?.length) {
        indexCurrent = indexDelete - 1;
      }
      const newData = state?.conversations?.filter((elm) => elm?.hoiThoaiId !== action?.payload);
      return {
        ...state,
        currentConversation:
          state?.currentConversation?.hoiThoaiId === action?.payload
            ? newData?.[indexCurrent]
            : state?.currentConversation,
        conversations: newData,
        newMessages:
          state?.currentConversation?.hoiThoaiId === action?.payload ? [] : state?.newMessages,
        messages: state?.currentConversation?.hoiThoaiId === action?.payload ? [] : state?.messages,
        conversationPinTopList: state.conversationPinTopList?.filter(
          (f) => f.hoiThoaiId !== action?.payload
        ),
      };
    case types.SAVE_ADD_TOP_CHAT:
      return {
        ...state,
        addTopChat: action?.payload?.data?.result || null,
      };
    case types.SAVE_CURRENT_CONVERSATION_CHAT:
      if (
        state?.typeConversation === 'ALL' ||
        state?.typeConversation === action.payload?.hoiThoai?.isGroup
      ) {
        return {
          ...state,
          currentConversation: action.payload?.hoiThoai,
        };
      }

      return state;
    case types.HANDLE_NOTIFICATION_OF_CONVERSATION:
      hoiThoaiId = action.payload.hoiThoaiId;
      if (!hoiThoaiId) {
        return state;
      }

      userId = action.payload.userId;
      index = state.conversations.findIndex((o) => o.hoiThoaiId === hoiThoaiId);
      index2 = state.conversationPinTopList.findIndex((o) => o.hoiThoaiId === hoiThoaiId);
      cloneConversations = [...state.conversations];
      cloneConversationPinTopList = [...state.conversationPinTopList];
      if (index !== -1) {
        indexNhanVienHoiThoai = cloneConversations[index].nhanVienHoiThoai?.findIndex(
          (item) => item?.nhanVien?.nhanVienId === userId
        );
        if (indexNhanVienHoiThoai !== -1) {
          cloneConversations[index].nhanVienHoiThoai[indexNhanVienHoiThoai].tatThongBao =
            !cloneConversations[index].nhanVienHoiThoai[indexNhanVienHoiThoai].tatThongBao;
        }
      }

      if (index2 !== -1) {
        indexNhanVienHoiThoai2 = cloneConversationPinTopList[index2].nhanVienHoiThoai?.findIndex(
          (item) => item?.nhanVien?.nhanVienId === userId
        );
        if (indexNhanVienHoiThoai2 !== -1) {
          cloneConversationPinTopList[index2].nhanVienHoiThoai[indexNhanVienHoiThoai2].tatThongBao =
            !cloneConversationPinTopList[index2].nhanVienHoiThoai[indexNhanVienHoiThoai2]
              .tatThongBao;
        }
      }

      return {
        ...state,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
      };

    case types.IMPORTANT_CONVERSATION:
      hoiThoai = action.payload?.hoiThoai;
      if (!hoiThoai?.hoiThoaiId) {
        return state;
      }

      index = state.conversations.findIndex((o) => o.hoiThoaiId === hoiThoai.hoiThoaiId);
      index2 = state.conversationPinTopList.findIndex((f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId);
      cloneConversations = [...state.conversations];
      cloneConversationPinTopList = [...state.conversationPinTopList];
      if (index !== -1) {
        indexNhanVienHoiThoai = cloneConversations[index].nhanVienHoiThoai?.findIndex(
          (item) => item?.nhanVien?.nhanVienId === action.payload?.currentUserId
        );
        if (indexNhanVienHoiThoai !== -1) {
          cloneConversations[index].nhanVienHoiThoai[indexNhanVienHoiThoai].quanTrong =
            action.payload?.isQuanTrong;
          dsNhanVienHoiThoai = cloneConversations[index].nhanVienHoiThoai;
        }
      }

      if (index2 !== -1) {
        indexNhanVienHoiThoai2 = cloneConversationPinTopList[index2].nhanVienHoiThoai?.findIndex(
          (item) => item?.nhanVien?.nhanVienId === action.payload?.currentUserId
        );
        if (indexNhanVienHoiThoai2 !== -1) {
          cloneConversationPinTopList[index2].nhanVienHoiThoai[indexNhanVienHoiThoai2].quanTrong =
            action.payload?.isQuanTrong;
          dsNhanVienHoiThoai = cloneConversationPinTopList[index2].nhanVienHoiThoai;
        }
      }

      return {
        ...state,
        conversations: cloneConversations,
        currentConversation:
          state.currentConversation?.hoiThoaiId === hoiThoai.hoiThoaiId
            ? {
                ...state.currentConversation,
                nhanVienHoiThoai: dsNhanVienHoiThoai,
              }
            : state.currentConversation,
        conversationPinTopList: cloneConversationPinTopList,
      };

    case types.SET_TYPE_CONVERSATION:
      return {
        ...state,
        typeConversation: action.payload.typeConversation,
      };

    case types.SET_SEARCH_MESSAGE:
      return {
        ...state,
        searchMessage: action?.payload?.message || null,
      };

    case types.GET_MESSAGES_FORM_SEARCH_MESSAGE:
      const mes1 = get(action, 'payload.res[0].data.result.items', []);
      const mes2 = get(action, 'payload.res[1].data.result.items', []);
      temp = get(action, 'payload.res[2].data.result', {});

      return {
        ...state,
        isLoadingSearchMessage: false,
        newMessages:
          temp.hoiThoaiId === state.currentConversation?.hoiThoaiId
            ? [...mes1.reverse(), temp, ...mes2]
            : [],
        loadMoreBeforeMessages: {
          ...state.loadMoreBeforeMessages,
          hasMore: mes2?.length === variables.LOAD_MORE.MESSAGE_LIMIT,
        },
      };

    case types.SET_LOADING_SEARCH_MESSAGE:
      return {
        ...state,
        isLoadingSearchMessage: action.payload.loading,
      };

    case types.REFRESH_CONTEXT_MESSAGE:
      return {
        ...state,
        loadMoreBeforeMessages: {
          hasMore: false,
          loading: false,
        },
      };

    case types.UPDATE_ROLE_CONVERSATION:
      return {
        ...state,
        conversations: state?.conversations?.map((hoiThoai) => {
          if (hoiThoai?.hoiThoaiId === action.payload?.hoiThoaiId) {
            return {
              ...hoiThoai,
              nhanVienHoiThoai: hoiThoai?.nhanVienHoiThoai?.map((user) =>
                user?.nhanVien?.nhanVienId === action.payload?.nhanVienId
                  ? {
                      ...user,
                      vaiTro: action.payload?.vaiTro || 0,
                      nhanVien: {
                        ...user?.nhanVien,
                        vaiTro: action.payload?.vaiTro || 0,
                      },
                    }
                  : user
              ),
            };
          }

          return hoiThoai;
        }),
        currentConversation:
          state?.currentConversation?.hoiThoaiId === action.payload?.hoiThoaiId
            ? {
                ...state?.currentConversation,
                nhanVienHoiThoai: state?.currentConversation?.nhanVienHoiThoai?.map((user) =>
                  user?.nhanVien?.nhanVienId === action.payload?.nhanVienId
                    ? {
                        ...user,
                        vaiTro: action.payload?.vaiTro || 0,
                        nhanVien: {
                          ...user?.nhanVien,
                          vaiTro: action.payload?.vaiTro || 0,
                        },
                      }
                    : user
                ),
              }
            : state?.currentConversation,
        allUsersConversation: state?.allUsersConversation?.map((user) =>
          user?.nhanVien?.nhanVienId === action.payload?.nhanVienId
            ? {
                ...user,
                vaiTro: action.payload?.vaiTro || 0,
                nhanVien: {
                  ...user?.nhanVien,
                  vaiTro: action.payload?.vaiTro || 0,
                },
              }
            : user
        ),
      };

    case types.SET_CONVERSATION_PIN_TOP:
      const isSameConversation =
        action.payload?.hoiThoaiId === state?.currentConversation?.hoiThoaiId;
      let newPinedMessage = [];
      if (action.payload?.type === 'NEW') {
        const pinTopNotCurrentId = state?.topPinedMessage?.filter(
          (item) => item?.tinNhanId !== action?.payload?.tinNhanGhim?.tinNhanId
        );
        newPinedMessage = [action.payload?.tinNhanGhim, ...pinTopNotCurrentId];
      } else if (action.payload?.type === 'DELETE' || action.payload?.type === 'UNPIN') {
        newPinedMessage = state?.topPinedMessage?.filter(
          (item) => item?.tinNhanGhimId !== action?.payload?.tinNhanId
        );
      }

      return {
        ...state,
        topPinedMessage: isSameConversation ? newPinedMessage : state?.topPinedMessage,
      };

    case types.ACTIVE_CONVERSATION:
      hoiThoai = action.payload?.conversation;
      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      index = state?.conversations?.findIndex((f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId);
      index2 = state?.conversationPinTopList?.findIndex(
        (f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId
      );
      hasConversationInPinTop = hoiThoai.nhanVienHoiThoai?.some(
        (s) => s?.nhanVien?.nhanVienId === action.payload?.nhanVienId && s?.ngayGhim
      );
      if (hasConversationInPinTop) {
        // Là hội thoại ghim top
        // Xóa trong ds hội thoại thường nếu có
        if (index !== -1) {
          cloneConversations.splice(index + 1, 1);
        }

        if (index2 !== -1) {
          cloneConversationPinTopList.splice(index2, 1, hoiThoai);
        } else {
          // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
          indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
            return (
              new Date(item.lanHoatDongCuoi).getTime() <
              new Date(hoiThoai?.lanHoatDongCuoi).getTime()
            );
          });
          if (indexBiggerDate !== -1) {
            cloneConversationPinTopList.splice(indexBiggerDate, 0, hoiThoai);
          } else {
            cloneConversationPinTopList = [hoiThoai, ...cloneConversationPinTopList];
          }
        }
      } else {
        cloneConversations = [hoiThoai, ...cloneConversations];
        if (index !== -1) {
          cloneConversations.splice(index + 1, 1);
        }

        if (index2 !== -1) {
          cloneConversationPinTopList.splice(index2 + 1, 1);
        }
      }

      return {
        ...state,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
        currentConversation: hoiThoai,
      };
    case types.SET_TOP_CONVERSATION:
      hoiThoai = action.payload?.hoiThoai;
      if (state.currentConversation?.hoiThoaiId === hoiThoai?.hoiThoaiId || !hoiThoai) {
        return state;
      }

      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      index = state?.conversations?.findIndex((f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId);
      index2 = state?.conversationPinTopList?.findIndex(
        (f) => f.hoiThoaiId === hoiThoai?.hoiThoaiId
      );
      if (index !== -1 || index2 !== -1) {
        if (index !== -1) {
          cloneConversations = [hoiThoai, ...cloneConversations];
          cloneConversations.splice(index + 1, 1);
        }

        if (index2 !== -1) {
          cloneConversationPinTopList.splice(index2, 1, hoiThoai);
        }
      } else {
        // Nếu hội thoại không có trong ds hội thoại hiện tại (thường và hội thoại ghim)
        // Kiểm tra thuộc hội thoại thường hay hội thoại ghim để xử lý
        hasConversationInPinTop = hoiThoai.nhanVienHoiThoai?.some(
          (s) => s?.nhanVien?.nhanVienId === action.payload.auth?.user && s?.ngayGhim
        );
        if (hasConversationInPinTop) {
          // Hội thoại thuộc ghim top
          // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
          indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
            return (
              new Date(item.lanHoatDongCuoi).getTime() <
              new Date(hoiThoai?.lanHoatDongCuoi).getTime()
            );
          });
          if (indexBiggerDate !== -1) {
            cloneConversationPinTopList.splice(indexBiggerDate, 0, hoiThoai);
          } else {
            cloneConversationPinTopList = [hoiThoai, ...cloneConversationPinTopList];
          }
        } else {
          cloneConversations = [hoiThoai, ...cloneConversations];
        }
      }

      return {
        ...state,
        currentConversation: hoiThoai,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
      };
    case types.SET_CONVERSATION_MESSAGE_SEEN:
      return {
        ...state,
        messageSeen: action?.payload,
      };
    case types.SET_CONVERSATION_MESSAGE_RECEIVED:
      return {
        ...state,
        messageReceived: action?.payload,
      };
    case types.SET_LAST_MESSAGE:
      return {
        ...state,
        isMessageLatest: action?.payload,
      };
    case types.SET_NEW_MESSAGE:
      return {
        ...state,
        isMessageNewest: action?.payload,
      };
    case types.IS_EDITOR_LOADING:
      return {
        ...state,
        isEditorLoading: action?.payload,
      };
    case types.SET_TOTAL_MESSAGE:
      return {
        ...state,
        conversations: state?.conversations?.map((hoiThoai) =>
          hoiThoai?.hoiThoaiId === action?.payload?.hoiThoaiId
            ? { ...hoiThoai, tongTinNhan: hoiThoai?.tongTinNhan + action?.payload?.soTinChuaDoc }
            : hoiThoai
        ),
      };
    case types.SET_CONVERSATION_APPROVED_USER:
      hoiThoai = action?.payload?.hoiThoai;
      cloneConversations = [...(state.conversations || [])];
      cloneConversationPinTopList = [...(state.conversationPinTopList || [])];
      index = cloneConversations.findIndex((item) => item.hoiThoaiId === hoiThoai.hoiThoaiId);
      index2 = cloneConversationPinTopList.findIndex(
        (item) => item.hoiThoaiId === hoiThoai.hoiThoaiId
      );
      if (index !== -1) {
        cloneConversations[index] = hoiThoai;
      }

      if (index2 !== -1) {
        cloneConversationPinTopList[index] = hoiThoai;
      }

      return {
        ...state,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
        currentConversation:
          state?.currentConversation?.hoiThoaiId === hoiThoai.hoiThoaiId
            ? hoiThoai
            : state?.currentConversation,
      };
    case types.RECONNECTING_STATUS:
      return {
        ...state,
        isReconnectingStatus: action?.payload,
      };
    case types.SET_IS_RECONNECT:
      return {
        ...state,
        isReconnect: action?.payload,
      };
    case types.API_GET_CONVERSATIONS_RECONNECTED:
      cloneCurrentConversation = state?.currentConversation;
      cloneConversations = action.payload?.conversations;
      cloneConversationPinTopList = action.payload?.conversationPinTopList;
      index = action.payload?.conversations?.findIndex(
        (e) => e?.hoiThoaiId === state.currentConversation?.hoiThoaiId
      );
      index2 = action.payload?.conversationPinTopList?.findIndex(
        (e) => e?.hoiThoaiId === state.currentConversation?.hoiThoaiId
      );
      if (index !== -1 || index2 !== -1) {
        if (index2 !== -1) {
          cloneCurrentConversation = cloneConversationPinTopList[index2];
        } else {
          cloneCurrentConversation = cloneConversations[index];
        }
      } else {
        hasConversationInPinTop = cloneCurrentConversation.nhanVienHoiThoai?.some(
          (s) => s?.nhanVien?.nhanVienId === action.payload?.auth?.user && s?.ngayGhim
        );
        if (hasConversationInPinTop) {
          // Hội thoại thuộc ghim top
          // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
          indexBiggerDate = cloneConversationPinTopList.findIndex((item) => {
            return (
              new Date(item.lanHoatDongCuoi).getTime() <
              new Date(cloneCurrentConversation?.lanHoatDongCuoi).getTime()
            );
          });
          if (indexBiggerDate !== -1) {
            cloneConversationPinTopList.splice(indexBiggerDate, 0, cloneCurrentConversation);
          } else {
            cloneConversationPinTopList = [
              cloneCurrentConversation,
              ...cloneConversationPinTopList,
            ];
          }
        } else {
          cloneConversations = [...cloneConversations, cloneCurrentConversation];
        }
      }

      return {
        ...state,
        currentConversation: cloneCurrentConversation,
        conversations: cloneConversations,
        conversationPinTopList: cloneConversationPinTopList,
      };
    case types.SET_AUDIO_PLAY_ID:
      return {
        ...state,
        audioPlayId: action?.payload || null,
      };
    case types.SET_MENTIONED_IDS:
      return {
        ...state,
        mentionedIds: action?.payload || [],
      };
    case types.UPDATE_CONVERSATION_PIN_TOP:
      hoiThoai = action.payload.hoiThoai;
      cloneConversations = [...state.conversations];
      cloneConversationPinTopList = [...state.conversationPinTopList];
      if (action?.payload?.isGhim) {
        cloneConversations = cloneConversations.filter(
          (f) => f?.hoiThoaiId !== hoiThoai?.hoiThoaiId
        );
        // Kiểm tra index thời gian hoạt động cuối của hội thoại ghim. Sau đó, chèn và sắp xếp đúng thứ tự
        indexBiggerDate = state.conversationPinTopList.findIndex((item) => {
          return (
            new Date(item.lanHoatDongCuoi).getTime() < new Date(hoiThoai?.lanHoatDongCuoi).getTime()
          );
        });
        if (indexBiggerDate !== -1) {
          cloneConversationPinTopList.splice(indexBiggerDate, 0, hoiThoai);
        } else {
          cloneConversationPinTopList = [hoiThoai, ...cloneConversationPinTopList];
        }
      } else {
        cloneConversationPinTopList = cloneConversationPinTopList.filter(
          (f) => f?.hoiThoaiId !== hoiThoai?.hoiThoaiId
        );
        // Kiểm tra index thời gian hoạt động cuối của hội thoại. Sau đó, chèn và sắp xếp đúng thứ tự
        indexBiggerDate = state.conversations.findIndex((item) => {
          return (
            new Date(item.lanHoatDongCuoi).getTime() < new Date(hoiThoai?.lanHoatDongCuoi).getTime()
          );
        });
        if (indexBiggerDate !== -1) {
          cloneConversations.splice(indexBiggerDate, 0, hoiThoai);
        } else {
          cloneConversations = [hoiThoai, ...cloneConversations];
        }
      }

      return {
        ...state,
        conversations: uniqBy(cloneConversations, 'hoiThoaiId'),
        conversationPinTopList: uniqBy(cloneConversationPinTopList, 'hoiThoaiId'),
      };
    case types.API_REQUEST_GET_CONVERSATION_PIN_TOP:
      return {
        ...state,
        conversationPinTopList: action?.payload?.data?.result?.items || [],
      };
    case types.API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP:
      return {
        ...state,
        isLoadingConversationPinTop: action?.payload,
      };
    case types.API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP_RESET:
      return {
        ...state,
        conversationPinTopList: [],
      };
    case types.SET_CONTENT_EDITOR:
      return {
        ...state,
        contentEditor: action?.payload || null,
      };
    case types.SET_ACTIVATE_ALL_TAB:
      return {
        ...state,
        isActivateAllTab: action?.payload,
      };
    default:
      return state;
  }
}
