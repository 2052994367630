export const TINNHAN_SMS = 'TINNHAN_SMS';
export const TINNHAN_SMS_365 = 'TINNHAN_SMS_365';
export const REACT_EMOTICON = 'REACT_EMOTICON';
export const CHAT_BETA = 'CHAT_BETA';

export default {
  TINNHAN_SMS,
  TINNHAN_SMS_365,
  REACT_EMOTICON,
  CHAT_BETA,
};
