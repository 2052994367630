import { SET_DIALOG_CREATE_WORK } from '@/constants/ActionTypes';

const initialState = {
  dialogCreateWork: null,
};

export default function work(state = initialState, action) {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_DIALOG_CREATE_WORK:
      return {
        ...state,
        dialogCreateWork: payload,
      };
    default:
      return state;
  }
}
