import { CircleSpinner } from 'react-spinners-kit';
import './index.scss';

function LoadingLoadMore({ size, color }) {
  return (
    <div className="loading-load-more">
      <CircleSpinner
        size={size || 14}
        className={`cpc-button-loading`}
        color={color || '#959595'}
      />
    </div>
  );
}

export default LoadingLoadMore;
