import { Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import './index.scss';

const CPCPopover = ({
  content,
  parentContent = null,
  usePortal = false, // View over element
  position = Position.TOP_LEFT,
  popoverClassName,
  children,
  onClose = () => {},
  ...props
}) => {
  return (
    <Popover2
      {...props}
      popoverClassName={classNames('cpc-popover', popoverClassName)}
      usePortal={usePortal}
      content={content}
      position={position}
      onClose={onClose}
    >
      {children}
    </Popover2>
  );
};

export default CPCPopover;
