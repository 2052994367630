import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { DateRangeInput } from '@blueprintjs/datetime';
import { Select } from '../common';
import { checkQuyenChucVu } from '../../helpers/helper';
import classnames from 'classnames';

const getPreviousMonth = (currentDate) => {
  currentDate.setMonth(currentDate.getMonth() - 1);
  return currentDate;
};

const getNextDay = (currentDate) => {
  currentDate.setDate(currentDate.getDate());
  return currentDate;
};

class ListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleFilter: false,
      filter: {
        fromDate: getPreviousMonth(new Date()),
        toDate: getNextDay(new Date()),
        donViId: 'All',
        textSearch: '',
        chatLuongId: 0,
        phienBanId: 0,
        nguoiGui: '',
      },
    };
  }

  componentDidMount() {
    this.endInput.addEventListener('keydown', (e) => {
      e.preventDefault();
    });
    this.startInput.addEventListener('keydown', (e) => {
      e.preventDefault();
    });
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          ...this.state.filter,
          fromDate: props.filter.fromDate ? new Date(props.filter.fromDate) : undefined,
          toDate: props.filter.toDate ? new Date(props.filter.toDate) : undefined,
          chatLuongId: props.filter.chatLuongId ? props.filter.chatLuongId : 0,
          phienBanId: props.filter.phienBanId ? props.filter.phienBanId : 0,
        },
      });
    }
  }

  getDanhSachChatLuong = (chatLuongArray) => {
    if (chatLuongArray.length === 0) {
      return [];
    }

    if (chatLuongArray.findIndex((i) => i.chatLuongId === 0) !== -1) {
      return chatLuongArray;
    }

    chatLuongArray.unshift({
      chatLuongId: 0,
      tenChatLuong: 'Tất cả',
    });

    return chatLuongArray;
  };

  getDanhSachPhienBan = (phienBanArray) => {
    if (phienBanArray.length === 0) {
      return [];
    }

    if (phienBanArray.findIndex((i) => i.phienBanId === 0) !== -1) {
      return phienBanArray;
    }

    phienBanArray.unshift({
      phienBanId: 0,
      phienBan: 'Tất cả',
    });

    return phienBanArray;
  };

  clearFilter = (e) => {
    e.preventDefault();
    this.setState(
      {
        filter: {
          fromDate: getPreviousMonth(new Date()),
          toDate: getNextDay(new Date()),
          donViId: 'All',
          textSearch: '',
          chatLuongId: 0,
          phienBanId: 0,
          nguoiGui: '',
        },
      },
      () => {
        this.props.handleFilter && this.props.handleFilter(this.state.filter);
      }
    );
  };

  handleDateChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          fromDate: e[0],
          toDate: e[1],
        },
      },
      () => {
        this.props.handleFilter && this.props.handleFilter(this.state.filter);
      }
    );
  };

  handleSelectChange = (e, name) => {
    if (!name) {
      let value = e.target.value;
      let name = e.target.name;
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [name]: value,
          },
        },
        () => {
          this.props.handleFilter && this.props.handleFilter(this.state.filter);
        }
      );
      return;
    }

    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: e,
        },
      },
      () => {
        this.props.handleFilter && this.props.handleFilter(this.state.filter);
      }
    );
  };

  handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    });
  };

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    });
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.handleFilter && this.props.handleFilter(this.state.filter);
  };

  toggleFilter = () => {
    this.setState({
      isToggleFilter: !this.state.isToggleFilter,
    });
  };

  render() {
    return (
      <div className="filter-wrapper">
        <form onSubmit={this.handleSubmit}>
          <div className="row-filter">
            <div className="col-filter">
              <label htmlFor="">Ngày gửi</label>
              <div className="filter-control filter-control-date">
                <DateRangeInput
                  value={[this.state.filter.fromDate, this.state.filter.toDate]}
                  startInputProps={{
                    inputRef: (el) => (this.startInput = el),
                  }}
                  endInputProps={{
                    inputRef: (el) => (this.endInput = el),
                  }}
                  onChange={this.handleDateChange}
                  popoverProps={{
                    inline: false,
                    tetherOptions: {
                      constraints: [
                        {
                          attachment: 'together',
                          to: 'window',
                          pin: true,
                        },
                      ],
                    },
                  }}
                  allowSingleDayRange={true}
                  shortcuts={false}
                  format="DD/MM/YYYY"
                />
              </div>
            </div>
            {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
              <div className="col-filter">
                <label htmlFor="">Đơn vị</label>
                <div className="filter-control filter-control-select">
                  <Select
                    data={this.props.unit || []}
                    model={['donViId', 'tenVietTat', 'dsDonViCon']}
                    value={this.state.filter.donViId || 'All'}
                    onChange={(e) => this.handleSelectChange(e, 'donViId')}
                    tree={true}
                  />
                </div>
              </div>
            )}
            <div className="col-filter col-filter-glow">
              <div className="filter-control filter-control-search filter-control-search-button">
                <input
                  name="textSearch"
                  className="input"
                  type="text"
                  onChange={this.handleInputChange}
                  placeholder={`Tìm kiếm nội dung`}
                  value={this.state.filter.textSearch ? this.state.filter.textSearch : ''}
                  autoFocus={true}
                  maxLength={255}
                />
                {this.state.filter.textSearch && (
                  <i
                    className="icon icon-close-cicle-line filter-close-icon"
                    onClick={this.removeTextSearch.bind(this, 'textSearch')}
                  ></i>
                )}
                <Button type="submit">
                  <span className="icon icon-search"></span>
                </Button>
              </div>
            </div>
            <div className="col-filter">
              <div className="filter-control filter-control-reset">
                <Button onClick={this.clearFilter}>
                  <span className="icon icon-loading-arrow1601"></span>
                </Button>
              </div>
            </div>
            <div className="col-filter">
              <div className="filter-control filter-control-reset filter-control-toggle">
                <Button
                  className={classnames('rotate', {
                    'rotate-toggle': this.state.isToggleFilter,
                  })}
                  onClick={this.toggleFilter}
                >
                  <span className="icon icon-table-paging-right-upto1601"></span>
                </Button>
              </div>
            </div>
          </div>
          {this.state.isToggleFilter && (
            <div className="row-filter">
              <div className="col-filter">
                <label htmlFor="">Chất lượng</label>
                <div className="filter-control filter-control-select filter-control-fix-width">
                  <Select
                    data={this.getDanhSachChatLuong(this.props.chatLuong) || []}
                    model={['chatLuongId', 'tenChatLuong']}
                    value={this.state.filter.chatLuongId || 0}
                    name="chatLuongId"
                    onChange={this.handleSelectChange}
                  />
                </div>
              </div>
              <div className="col-filter">
                <label htmlFor="">Phiên bản</label>
                <div className="filter-control filter-control-select filter-control-fix-width">
                  <Select
                    data={this.getDanhSachPhienBan(this.props.phienBan) || []}
                    model={['phienBanId', 'phienBan']}
                    value={this.state.filter.phienBanId || 0}
                    name="phienBanId"
                    onChange={this.handleSelectChange}
                  />
                </div>
              </div>
              {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
                <div className="col-filter">
                  <label htmlFor="">Người gửi</label>
                  <div className="filter-control filter-control-fix-width2">
                    <input
                      name="nguoiGui"
                      className="input"
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder={`Nhập người gửi`}
                      value={this.state.filter.nguoiGui ? this.state.filter.nguoiGui : ''}
                      autoFocus={true}
                      maxLength={255}
                    />
                    {this.state.filter.nguoiGui && (
                      <i
                        className="icon icon-close-cicle-line filter-close-icon"
                        onClick={this.removeTextSearch.bind(this, 'nguoiGui')}
                      ></i>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    );
  }
}

ListFilter.propTypes = {
  handleFilter: PropTypes.func,
};

export default ListFilter;
