import React from 'react';
import logo from '@/assets/images/logo.png';
import logoText from '@/assets/images/cpc-logo.png';
import './index.scss';

function Brand(props) {
  const { hideText, center } = props;
  return (
    <div className={`brand-wrapper ${center ? 'justify-center' : ''}`}>
      {hideText ? (
        <img className="logo" src={logo} alt="EVN CPC" />
      ) : (
        <img className="logo" src={logoText} alt="EVN CPC" />
      )}
    </div>
  );
}

export default Brand;
