import { SwishSpinner } from 'react-spinners-kit';

function FullLoading() {
  return (
    <div className="div-center">
      <SwishSpinner />
    </div>
  );
}

export default FullLoading;
