import { useMemo } from 'react';
import classNames from 'classnames';
import CPCIcon from '@/components/atoms/icon';
import { UPLOAD_API_URL } from '@/constants/Api';
import { checkFileType, getFileSize } from '@/helpers/helper';
import IconWrapper from '@/components/molecules/icon-wrapper';
import CPCImage from '@/components/atoms/image';
import CPCFile from '@/components/atoms/file';
import { variables } from '@/constants/variables';
import './index.scss';

const CPCFileThumbnail = ({
  data,
  auth,
  className,
  hasRemove = true,
  theme,
  onRemove = () => {},
}) => {
  const renderItem = useMemo(() => {
    const token = auth?.token?.accessToken;
    if (checkFileType(data?.extension) === 'IMAGE') {
      const thumbWidth = data?.kieuFile === 'image/gif' ? '' : '&w=250';
      const file = `${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${data?.id}?fileName=${data?.name}&BearerToken=${token}`;
      const thumbnailSrc = `${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${data?.id}?fileName=${data?.name}${thumbWidth}&BearerToken=${token}`;
      return <CPCImage src={file} thumbnailSrc={thumbnailSrc} alt={data?.name} />;
    } else if (
      checkFileType(data?.extension) === 'DOCUMENT' ||
      checkFileType(data?.extension) === 'VIDEO' ||
      checkFileType(data?.extension) === 'COMPRESSED'
    ) {
      return <CPCFile name={data?.name} kind={data?.extension} size={getFileSize(data?.size)} />;
    }

    return '';
  }, [data, auth, checkFileType]);

  return (
    <div
      className={classNames('cpc-file-thumbnail', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
      })}
    >
      {renderItem}
      {hasRemove && (
        <div className="close">
          <IconWrapper color="rgba(8, 28, 54, 0.8)" type="circle" size="s-1">
            <CPCIcon icon="xmark" onClick={() => onRemove(data)} size={9} color="white" />
          </IconWrapper>
        </div>
      )}
    </div>
  );
};

export default CPCFileThumbnail;
