import React, { Component } from 'react';
import FileDownload from 'react-file-download';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import { Position, Intent, Toaster, Dialog, Button } from '@blueprintjs/core';
import { UPLOAD_API_URL } from '../../constants/Api';
import ImageAttachment from './ImageAttachment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import { checkExtension } from '../../helpers/helper';
import { variables } from '@/constants/variables';
import { get } from 'lodash';

class MessageItemAttachment extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    fileDownloading: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
  };

  static defaultProps = {
    fileDownloading: {},
  };

  state = {
    images: {},
    openDialog: false,
    isOpenPopupNoFile: false,
    imagePreview: '',
    customWidthHeight: {
      width: '500px',
      height: '500px',
    },
  };

  refHandlers = {
    toaster: (ref) => (this.toaster = ref),
  };

  handleImageEndload(i, boolean, e) {
    this.setState({
      images: {
        ...this.state.images,
        [i]: boolean,
      },
    });
  }

  async handleImageClick(file, fileSystemDeleted, e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    let { accessToken } = this.props.auth.token;
    const { clientHeight, clientWidth } = this[file.fileId];
    let width = 750;
    if (clientWidth / clientHeight > 1) {
      width = window.innerWidth * 0.7;
    }
    let url = `${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${file.fileId}?w=${width}&fileName=${file.fileName}&BearerToken=${accessToken}`;
    if (fileSystemDeleted) {
      return this.setState({
        isOpenPopupNoFile: true,
      });
    }
    this.setState({
      openDialog: !this.state.openDialog,
      imagePreview: url,
    });
  }

  togleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  handleDownload(file) {
    this.props.onDownload && this.props.onDownload(file);
  }

  handleSaveDocument(file, fileSystemDeleted, e) {
    e.preventDefault();
    let url = e.currentTarget.href;
    if (!url) {
      return;
    }
    if (fileSystemDeleted) {
      return this.props.onSaveDocument && this.props.onSaveDocument(file);
    }
    this.props.onSaveDocument && this.props.onSaveDocument(file);
  }

  checkFile = (e, fileSystemDeleted) => {
    if (e) {
      e.preventDefault();
    }
    let url = get(e, 'currentTarget.href');
    if (!url) {
      return;
    }
    if (fileSystemDeleted) {
      return this.setState({
        isOpenPopupNoFile: true,
      });
    }
    window.open(url, '_blank');
  };

  createTitle = (content) => {
    return (
      <div className="d-flex">
        {content}
        <a onClick={this.props.actions.handleRotateImg} className="ml-auto mr20">
          <span className="icon-refresh"></span>
        </a>
      </div>
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.openDialog && this.state.openDialog) {
      this.props.actions.resetDeg();
    }
  };

  initDimensionImg = (w, h) => {
    this.setState({
      customWidthHeight: {
        height: h + 'px',
        width: w + 'px',
      },
    });
  };

  checkFileSystemDeleted = (fileName = '', e) => {
    if (!fileName) {
      return false;
    }

    if (fileName.endsWith('system_deleted')) {
      return true;
    } else {
      return false;
    }
  };

  removeSystemDeleteName = (fileName) => {
    if (fileName && fileName.endsWith('_system_deleted')) {
      return fileName.replace('_system_deleted', '');
    }

    return fileName;
  };

  taiFile = async (file) => {
    if (!file) {
      return this.toaster.show({
        message: 'Không tải được file.',
        intent: Intent.DANGER,
      });
    }
    await this.props.actions.downloadFileChat(file.fileId).then((res) => {
      if (res.error) {
        this.toaster.show({
          message: 'Không tải được file.',
          intent: Intent.DANGER,
        });
        return;
      }

      if (res && res.payload && res.payload.data) {
        FileDownload(res.payload.data, file.tenFile);
      }
    });
  };

  render() {
    let { accessToken } = this.props.auth.token;
    return this.props.files.length ? (
      <div className="cpc-message-attachment">
        <Dialog
          className="image-preview"
          iconName="inbox"
          isOpen={this.state.openDialog}
          onClose={this.togleDialog.bind(this)}
          title={this.createTitle('Preview')}
        >
          <div
            className="pt-dialog-body transition-width-height position-relative overflow-hidden"
            style={this.state.customWidthHeight}
          >
            <div className="positon-absolute absolute-center">
              <ImageAttachment
                togleDialog={this.togleDialog}
                imagePreview={this.state.imagePreview}
                initDimensionImg={this.initDimensionImg}
                deg={this.props.deg}
              />
            </div>
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.isOpenPopupNoFile}
          onClose={(e) => this.setState({ isOpenPopupNoFile: false })}
          className="popup-cpc-confirm"
          title=""
        >
          <div className="pt-dialog-body">
            <span className="icon icon-interactive"></span>
            <p className="message-cofirm">Tin nhắn này đã thực hiện áp dụng chính sách xóa file</p>
          </div>
          <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions">
              <Button
                iconName="pt-icon-tick"
                intent={Intent.NORMAL}
                onClick={(e) => this.setState({ isOpenPopupNoFile: false })}
                text="Đóng"
                className="btn-confirm-popup"
              />
            </div>
          </div>
        </Dialog>

        {this.props.files.map((file, i) => {
          let isSystemDeletedFile = file?.tenFile && file.tenFile.endsWith('system_deleted');
          return (
            <div key={file.fileId} className="cpc-message-attachment-item">
              <div className="cpc-thumb pull-left">
                <a
                  href={`${UPLOAD_API_URL}/api/${
                    file.tenFile?.startsWith('eofficeFile') ? 'file' : 'filechat'
                  }/get/${file.fileId}?app=eoffice&fileName=${
                    file.fileName
                  }&BearerToken=${accessToken}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => this.checkFile(e, isSystemDeletedFile)}
                >
                  {file.kieuFile === 'application/pdf' || checkExtension(['pdf'], file.fileName) ? (
                    <img alt="" src={process.env.PUBLIC_URL + '/images/pdf.png'} />
                  ) : file.kieuFile ===
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    file.kieuFile === 'application/msword' ||
                    checkExtension(['doc', 'docx'], file.fileName) ? (
                    <img alt="" src={process.env.PUBLIC_URL + '/images/doc.png'} />
                  ) : file.kieuFile ===
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    file.kieuFile === 'application/vnd.ms-excel' ||
                    checkExtension(['xls', 'xlsx'], file.fileName) ? (
                    <img alt="" src={process.env.PUBLIC_URL + '/images/xls.png'} />
                  ) : file.kieuFile.indexOf('image') >= 0 ? (
                    <div>
                      {!this.state.images[i] && (
                        <img
                          style={{ maxHeight: '52px' }}
                          className="cpc-image cpc-image-v2"
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            (this.state.images[i] === false
                              ? '/images/not-found-image.svg'
                              : '/images/loading.gif')
                          }
                          onClick={(e) => this.handleImageClick(file, isSystemDeletedFile, e)}
                        />
                      )}
                      {
                        <img
                          className={`cpc-image cpc-image-v2 ${
                            this.state.images[i] ? 'show' : 'hide'
                          }`}
                          alt=""
                          onLoad={this.handleImageEndload.bind(this, i, true)}
                          onError={this.handleImageEndload.bind(this, i, false)}
                          onClick={(e) => this.handleImageClick(file, isSystemDeletedFile, e)}
                          src={`${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${file.fileId}?w=250&fileName=${file.fileName}&BearerToken=${accessToken}`}
                          ref={(element) => (this[file.fileId] = element)}
                        />
                      }
                    </div>
                  ) : (
                    <img alt="" src={process.env.PUBLIC_URL + '/images/file.png'} />
                  )}
                </a>
              </div>
              {file.kieuFile.indexOf('image') >= 0 ? null : (
                <div className="pull-left">
                  <div className="cpc-filename">
                    <a
                      onClick={(e) => this.checkFile(e, isSystemDeletedFile)}
                      href={`${UPLOAD_API_URL}/api/${
                        file.tenFile?.startsWith('eofficeFile') ? 'file' : 'filechat'
                      }/get/${file.fileId}?app=eoffice&fileName=${
                        file.fileName
                      }&BearerToken=${accessToken}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.tenFile?.startsWith('eofficeFile')
                        ? this.removeSystemDeleteName(file.tenFile.replace('eofficeFile-', ''))
                        : this.removeSystemDeleteName(file.tenFile)}
                    </a>
                  </div>
                  <div className="cpc-filesize">
                    <a
                      onClick={(e) => this.checkFile(e, isSystemDeletedFile)}
                      href={`${UPLOAD_API_URL}/api/${
                        file.tenFile?.startsWith('eofficeFile') ? 'file' : 'filechat'
                      }/get/${file.fileId}?app=eoffice&fileName=${
                        file.fileName
                      }&BearerToken=${accessToken}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {bytes(file.kichThuoc, { unitSeparator: ' ' })}
                    </a>
                  </div>
                </div>
              )}
              {
                // Tải file hình ảnh
                file.kieuFile.indexOf('image') !== -1 && (
                  <a
                    className="cpc-actions"
                    onClick={() => this.taiFile(file)}
                    rel="noopener noreferrer"
                    data-toggle="tooltip"
                    title="Tải file"
                  >
                    <span className="cpc-icon icon-save"></span>
                  </a>
                )
              }
              {file.kieuFile.indexOf('image') === -1 &&
                !this.props.fileDownloading[file.fileId] &&
                !this.props.isInEditor && (
                  <a
                    className="cpc-actions"
                    href={`${UPLOAD_API_URL}/api/${
                      file.tenFile?.startsWith('eofficeFile') ? 'file' : 'filechat'
                    }/get/${file.fileId}?app=eoffice&fileName=${
                      file.fileName
                    }&BearerToken=${accessToken}`}
                    onClick={(e) => this.checkFile(e, isSystemDeletedFile)}
                    download={file.tenFile}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-toggle="tooltip"
                    title="Tải file"
                  >
                    <span className="cpc-icon icon-save"></span>
                  </a>
                )}
              {file.kieuFile.indexOf('image') === -1 &&
                !this.props.isForwardMessage &&
                !this.props.isInEditor && (
                  <a
                    className="cpc-actions"
                    href={`${UPLOAD_API_URL}/api/${
                      file.tenFile?.startsWith('eofficeFile') ? 'file' : 'filechat'
                    }/get/${file.fileId}?app=eoffice&fileName=${
                      file.fileName
                    }&BearerToken=${accessToken}`}
                    onClick={this.handleSaveDocument.bind(this, file, isSystemDeletedFile)}
                    data-toggle="tooltip"
                    title="Lưu tủ tài liệu"
                  >
                    <span className="cpc-icon icon-save-document"></span>
                  </a>
                )}
              {this.props.fileDownloading[file.fileId] && (
                <span
                  className="cpc-actions icon-close-cicle-line"
                  style={{ cursor: 'progress' }}
                />
              )}
              <div className="clearfix" />
            </div>
          );
        })}

        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  deg: state.chat.deg,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageItemAttachment);
