import CPCAvatar from '@/components/atoms/avatar';
import CPCButton from '@/components/atoms/button';
import CPCIcon from '@/components/atoms/icon';
import Heading from '@/components/layout/heading';
import FullLoading from '@/components/molecules/full-loading';
import LayoutMain from '@/components/organisms/layout-main';
import { variables } from '@/constants/variables';
import {
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  Divider,
  Intent,
  Position,
  Radio,
  RadioGroup,
  Toaster,
} from '@blueprintjs/core';
import bytes from 'bytes';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import { UPLOAD_API_URL } from '../../constants/Api';
import NotFoundQuestion from './NotFoundQuestion';
import StatisticalContainer from './StatisticalContainer';
import './SurveyItemPage.scss';
import './assets/AddSurvey.scss';

const ONE_ANSWER = 2;
const MUTIL_ANSWER = 1;

class SurveyItemPage extends Component {
  surveyId;

  constructor(props, context) {
    super(props, context);
    this.state = {
      isSubmit: false,
      isOpenPopup: false,
      answers: {},
      isHideFooter: false,
      buttonbackFixTop: false,
      errorPage: false,
      isLoading: true,
      openDialog: false,
      imagePreview: '',
      tieuDe: '',
    };

    this.surveyId = this.props.match.params.id;
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  componentDidMount() {
    this.props.actions.surveyCheckPermission(this.surveyId).then((res) => {
      const role = res?.payload?.data?.result?.dsQuyen?.indexOf(2) >= 0;
      this.setState({ role: role });
    });

    this.props.actions.getSurveyItem(this.surveyId).then((res) => {
      this.setState({ isLoading: false });
      if (res.payload && res.payload.status === 403) {
        return this.props.history.push('/phieu-khao-sat/cau-hoi-khong-duoc-tra-loi');
      }

      if (res.error) {
        this.setState({ errorPage: true });
      }
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  handleMutilAnswer(questionId, answerId, gioiHanDapAn, e) {
    let state = this.state;
    const { checked } = e.target;

    if (checked) {
      if (
        state.answers[questionId] &&
        state.answers[questionId].dsDapAn &&
        state.answers[questionId].dsDapAn.length + 1 <= (gioiHanDapAn !== 0 ? gioiHanDapAn : 1000)
      ) {
        state.answers[questionId].dsDapAn.push(answerId);
      } else if (!state.answers[questionId]) {
        state.answers[questionId] = {
          ...state.answers[questionId],
          dsDapAn: [answerId],
        };
      } else {
        return this.props.actions.commonAddToasterMessage({
          message: `Anh/chị chỉ được chọn tối đa ${gioiHanDapAn} câu trả lời đối với câu hỏi này`,
          intent: Intent.WARNING,
        });
      }
    } else if (state.answers[questionId] && state.answers[questionId].dsDapAn) {
      state.answers[questionId].dsDapAn.splice(
        state.answers[questionId].dsDapAn.indexOf(answerId),
        1
      );
    }

    this.setState(state);
  }

  handleOneAnswer(questionId, answerId) {
    let state = this.state;

    state.answers[questionId] = {
      ...state.answers[questionId],
      dsDapAn: [answerId],
    };

    this.setState({ state });
  }

  validateForm() {
    const data = this.props.survey.item;

    data.dsCauHoi.map((item) => {
      if (
        !this.state.answers[item.cauHoiId] ||
        !this.state.answers[item.cauHoiId].dsDapAn ||
        !this.state.answers[item.cauHoiId].dsDapAn.length
      ) {
        throw new Error('Vui lòng chọn đáp án ở các câu hỏi.');
      }

      if (
        this.state.answers &&
        this.state.answers[item.cauHoiId] &&
        this.state.answers[item.cauHoiId].dsDapAn.indexOf('dapAnKhac') > -1
      ) {
        if (
          !this.state.answers[item.cauHoiId].dapAnKhac ||
          this.state.answers[item.cauHoiId].dapAnKhac === ''
        ) {
          throw new Error('Vui lòng nhập câu trả lời đáp án khác.');
        }
      }

      return true;
    });
  }

  handleSubmitForm() {
    try {
      this.setState({ isSubmit: true });

      this.validateForm();
      let cauHoi = [];
      let data = { ...this.state.answers };
      for (const q in this.state.answers) {
        let dap_an_khac = data[q].dsDapAn.indexOf('dapAnKhac') > -1 ? data[q].dapAnKhac : '';
        let ds_dap_an = data[q].dsDapAn.filter((item) => item !== 'dapAnKhac');
        cauHoi.push({
          cau_hoi_id: q,
          dap_an_khac: dap_an_khac,
          ds_dap_an: ds_dap_an,
        });
      }

      this.props.actions
        .surveyAnswer(this.surveyId, cauHoi)
        .then((res) => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Trả lời không thành công.',
              intent: Intent.WARNING,
            });
          }
          this.setState({ isOpenPopup: true });
        })
        .then((res) => {
          this.setState({ isSubmit: false });
        });
    } catch (e) {
      this.setState({ isSubmit: false });
      return this.props.actions.commonAddToasterMessage({
        message: e.message,
        intent: Intent.WARNING,
      });
    }
  }

  handleConfirm = () => {
    return this.props.history.push('/');
  };

  handleClickClosePopup = () => {
    this.setState({ isOpenPopup: false });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    let hasScrollbar =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (hasScrollbar > 10) {
      this.setState({
        isHideFooter: true,
      });
    }

    //fixed button back
    let scrollTop = window.pageYOffset;
    if (scrollTop > 70) {
      this.setState({
        buttonbackFixTop: true,
      });
    } else {
      this.setState({
        buttonbackFixTop: false,
      });
    }
  }

  handleFlow(id) {
    this.props.history.push(`/phieu-khao-sat/thong-ke/${id}`);
  }

  handleTextMutiAnswer(questionId, answerId, gioiHanDapAn, e) {
    let state = this.state;
    const { value } = e.target;

    if (value.trim() && value.trim().length > 0) {
      if (
        state.answers[questionId] &&
        state.answers[questionId].dsDapAn &&
        state.answers[questionId].dsDapAn.length + 1 <= (gioiHanDapAn !== 0 ? gioiHanDapAn : 1000)
      ) {
        if (
          state.answers[questionId] &&
          state.answers[questionId].dsDapAn &&
          state.answers[questionId].dsDapAn.indexOf(answerId) === -1
        ) {
          state.answers[questionId].dsDapAn.push(answerId);
        }
        state.answers[questionId].dapAnKhac = value;
      } else if (!state.answers[questionId]) {
        state.answers[questionId] = {
          ...state.answers[questionId],
          dsDapAn: [answerId],
          dapAnKhac: value,
        };
      } else if (
        state.answers[questionId] &&
        state.answers[questionId].dsDapAn &&
        state.answers[questionId].dsDapAn.indexOf(answerId) === -1
      ) {
        state.answers[questionId].dapAnKhac = value;
        this.props.actions.commonAddToasterMessage({
          message: `Anh/chị chỉ được chọn tối đa ${gioiHanDapAn} câu trả lời đối với câu hỏi này`,
          intent: Intent.WARNING,
        });
      } else {
        state.answers[questionId].dapAnKhac = value;
      }
    } else if (state.answers[questionId] && state.answers[questionId].dsDapAn) {
      if (
        state.answers[questionId] &&
        state.answers[questionId].dsDapAn &&
        state.answers[questionId].dsDapAn.indexOf(answerId) > -1
      ) {
        state.answers[questionId].dsDapAn.splice(
          state.answers[questionId].dsDapAn.indexOf(answerId),
          1
        );
        state.answers[questionId].dapAnKhac = '';
      }
    }

    this.setState(state);
  }

  handleTextOneAnswer(questionId, answerId, e) {
    let state = this.state;
    const { value } = e.target;

    if (value.trim() && value.trim().length > 0) {
      state.answers[questionId] = {
        ...state.answers[questionId],
        dsDapAn: [answerId],
        dapAnKhac: value,
      };
    } else {
      if (
        state.answers[questionId] &&
        state.answers[questionId].dsDapAn &&
        state.answers[questionId].dsDapAn.indexOf(answerId) > -1
      ) {
        state.answers[questionId] = {
          ...state.answers[questionId],
          dsDapAn: [],
          dapAnKhac: '',
        };
      } else {
        state.answers[questionId] = {
          ...state.answers[questionId],
          dapAnKhac: '',
        };
      }
    }

    this.setState({ state });
  }

  handleImageClick(file, e) {
    e.preventDefault();
    let { accessToken } = this.props.auth.token;
    this.setState({
      openDialog: !this.state.openDialog,
      imagePreview: `${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${
        file.fileId
      }?w=${'750'}&fileName=${file.tenFile}&BearerToken=${accessToken}`,
    });
  }

  togleDialog() {
    this.setState({ openDialog: !this.state.openDialog });
  }

  handleGoBack() {
    return this.props.history.push('/');
  }

  render() {
    const data = this.props.survey.item || {};
    if (this.state.errorPage) {
      return (
        <NotFoundQuestion
          message="Anh/chị không được tham gia phiếu khảo sát."
          history={this.props.history}
        />
      );
    }

    if (data.daHoanThanh) {
      return <Redirect to={`/phieu-khao-sat/chi-tiet/${this.surveyId}`} />;
    }
    const { accessToken } = this.props.auth.token;
    return (
      <div>
        <LayoutMain {...this.props} history={this.props.history}>
          <section
            className="wrapper-survey wrapper-survey-new"
            onScroll={this.handleScroll}
            id="survey-content"
          >
            <Heading
              title={
                data?.tieuDe?.startsWith('[PĐT]') ? data?.tieuDe.replace('[PĐT]', '') : data?.tieuDe
              }
              isGoback={true}
              handleGoBack={this.handleGoBack}
            />
            {this.state.isLoading && <FullLoading />}
            {!this.state.isLoading && (
              <div
                className={classnames('cpc-survey-body', {
                  'header-fixed': this.state.buttonbackFixTop,
                })}
              >
                <Scrollbars className="survey-content">
                  <div style={{ padding: '0 20px 20px' }}>
                    <section className="survey-description box">
                      <h4 className="survey-title">Mô tả</h4>
                      <p className="survey-desc">{data.moTa}</p>
                    </section>
                    <div className="border-line" />
                    <div className="questions">
                      {data.dsCauHoi &&
                        data.dsCauHoi.map((item, k) => (
                          <section className="question" key={k}>
                            <div className="list">
                              <h3 className="title">{`${item.stt}. ${item.noiDung}`}</h3>
                              {item.loaiCauHoi === MUTIL_ANSWER && item.gioiHanDapAn > 0 && (
                                <p className="notif-question">
                                  Anh/chị chỉ chọn được tối đa {item.gioiHanDapAn} đáp án
                                </p>
                              )}
                              <div className="list-answer">
                                {item.loaiCauHoi === MUTIL_ANSWER &&
                                  item.dsDapAn.map((answer, k) => (
                                    <div className="item-checked" key={k}>
                                      <Checkbox
                                        label={`${item.stt}.${answer.stt}. ${answer.noiDung}`}
                                        checked={
                                          this.state.answers[item.cauHoiId] &&
                                          this.state.answers[item.cauHoiId].dsDapAn &&
                                          this.state.answers[item.cauHoiId].dsDapAn.filter(
                                            (item) => item === answer.dapAnId
                                          ).length === 1
                                            ? true
                                            : false
                                        }
                                        onChange={this.handleMutilAnswer.bind(
                                          this,
                                          item.cauHoiId,
                                          answer.dapAnId,
                                          item.gioiHanDapAn
                                        )}
                                        disabled={data.daQuaHan}
                                      />
                                    </div>
                                  ))}
                                {item.loaiCauHoi === ONE_ANSWER && (
                                  <RadioGroup
                                    name={item.cauHoiId}
                                    selectedValue={
                                      this.state.answers[item.cauHoiId] &&
                                      this.state.answers[item.cauHoiId].dsDapAn
                                    }
                                    className="list-answer"
                                  >
                                    {item.dsDapAn.map((answer, k) => (
                                      <div className="item-checked" key={k}>
                                        <Radio
                                          name={item.cauHoiId}
                                          label={`${item.stt}.${answer.stt}. ${answer.noiDung}`}
                                          checked={
                                            this.state.answers[item.cauHoiId] &&
                                            this.state.answers[item.cauHoiId].dsDapAn &&
                                            this.state.answers[item.cauHoiId].dsDapAn.filter(
                                              (item) => item === answer.dapAnId
                                            ).length === 1
                                              ? true
                                              : false
                                          }
                                          onChange={this.handleOneAnswer.bind(
                                            this,
                                            item.cauHoiId,
                                            answer.dapAnId
                                          )}
                                          disabled={data.daQuaHan}
                                        />
                                      </div>
                                    ))}
                                  </RadioGroup>
                                )}
                                {item.themKhac && (
                                  <div className="item-checked">
                                    {item.loaiCauHoi === MUTIL_ANSWER ? (
                                      <div>
                                        <Checkbox
                                          label="Khác:"
                                          checked={
                                            this.state.answers[item.cauHoiId] &&
                                            this.state.answers[item.cauHoiId].dsDapAn &&
                                            this.state.answers[item.cauHoiId].dsDapAn.filter(
                                              (item) => item === 'dapAnKhac'
                                            ).length === 1
                                              ? true
                                              : false
                                          }
                                          onChange={this.handleMutilAnswer.bind(
                                            this,
                                            item.cauHoiId,
                                            'dapAnKhac',
                                            item.gioiHanDapAn
                                          )}
                                          disabled={data.daQuaHan}
                                        />
                                        <div className="input-focus-border-animate">
                                          <input
                                            type="text"
                                            className="input-other"
                                            onChange={this.handleTextMutiAnswer.bind(
                                              this,
                                              item.cauHoiId,
                                              'dapAnKhac',
                                              item.gioiHanDapAn
                                            )}
                                            disabled={data.daQuaHan}
                                          />
                                          <div className="line-bottom"></div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <Radio
                                          label="Khác:"
                                          checked={
                                            this.state.answers[item.cauHoiId] &&
                                            this.state.answers[item.cauHoiId].dsDapAn &&
                                            this.state.answers[item.cauHoiId].dsDapAn.filter(
                                              (item) => item === 'dapAnKhac'
                                            ).length === 1
                                              ? true
                                              : false
                                          }
                                          onChange={this.handleOneAnswer.bind(
                                            this,
                                            item.cauHoiId,
                                            'dapAnKhac'
                                          )}
                                          disabled={data.daQuaHan}
                                        />
                                        <div className="input-focus-border-animate">
                                          <input
                                            type="text"
                                            className="input-other"
                                            onChange={this.handleTextOneAnswer.bind(
                                              this,
                                              item.cauHoiId,
                                              'dapAnKhac'
                                            )}
                                            disabled={data.daQuaHan}
                                          />
                                          <div className="line-bottom"></div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            {item.dsFileDinhKem && item.dsFileDinhKem.length > 0 && (
                              <StatisticalContainer title="File đính kèm">
                                {item.dsFileDinhKem.map((file, i) => (
                                  <div className="attached-file" key={i}>
                                    <div className="cpc-file">
                                      <span className="cpc-icon">
                                        {file.kieuFile === 'application/pdf' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/pdf.png'}
                                          />
                                        ) : file.kieuFile ===
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                          file.kieuFile === 'application/msword' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/doc.png'}
                                          />
                                        ) : file.kieuFile ===
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                          file.kieuFile === 'application/vnd.ms-excel' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/xls.png'}
                                          />
                                        ) : file.kieuFile === 'application/zip' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/zip.png'}
                                          />
                                        ) : file.kieuFile === 'audio/mp3' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/mp3.png'}
                                          />
                                        ) : file.kieuFile === 'video/mp4' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/video.png'}
                                          />
                                        ) : file.kieuFile.indexOf('image') >= 0 ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/png.png'}
                                          />
                                        ) : file.kieuFile ===
                                            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                                          file.kieuFile === 'application/vnd.ms-powerpoint' ? (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/ppt.png'}
                                          />
                                        ) : (
                                          <img
                                            className="icon-image"
                                            alt=""
                                            src={process.env.PUBLIC_URL + '/images/file.png'}
                                          />
                                        )}
                                      </span>
                                      {file.kieuFile.indexOf('image') >= 0 ? (
                                        <a
                                          onClick={this.handleImageClick.bind(this, file)}
                                          target={'_blank'}
                                          style={{ width: 220 }}
                                        >
                                          <span className="cpc-filename">{file.tenFile}</span>
                                        </a>
                                      ) : (
                                        <a
                                          href={`${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${file.fileId}?fileName=${file.tenFile}&BearerToken=${accessToken}`}
                                          download
                                          target={'_blank'}
                                          style={{ width: 220 }}
                                        >
                                          <span className="cpc-filename">{file.tenFile}</span>
                                        </a>
                                      )}
                                      <span className="cpc-filesize">
                                        {bytes(file.kichThuoc, { unitSeparator: ' ' })}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </StatisticalContainer>
                            )}
                          </section>
                        ))}
                    </div>
                    <div className="border-line" />
                  </div>
                </Scrollbars>
                <div className="footer-action-block">
                  {data.nguoiTao && (
                    <div className="box-left-custom">
                      <p className="label-datepicker">Người tạo: </p>
                      {data.nguoiTao && (
                        <div className="user-info">
                          <CPCAvatar src={data.nguoiTao.anhDaiDien} />
                          <div className="content">
                            <h4 className="username">{data.nguoiTao.tenNhanVien}</h4>
                            <p className="intro">
                              {data.nguoiTao.phongBan ? data.nguoiTao.phongBan.maPhongBan : ''}
                            </p>
                          </div>
                        </div>
                      )}
                      <Divider />
                      <div className="time-expire">
                        <CPCIcon icon="calendar-v2" size={16} />
                        <span className="expire">
                          Ngày hết hạn:
                          <span
                            className={classnames('date', {
                              'blue-content': !data.daQuaHan,
                              'text-danger': data.daQuaHan,
                            })}
                          >
                            {`${dayjs(data.ngayHetHan).format('H:mm - DD/MM/YYYY')}`}
                            {data.daQuaHan ? ' (Hết hạn)' : null}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="box-right">
                    {/* <CPCButton className="btn-cancel" text="Hủy" onClick={this.handleGoBack} /> */}
                    {this.state.role && data.phieuKhaoSatId && (
                      <CPCButton
                        className="cpc-button__theme5 green large mr-10"
                        onClick={this.handleFlow.bind(this, data.phieuKhaoSatId)}
                        text="Xem thống kê"
                      />
                    )}

                    {!data.daQuaHan && (
                      <CPCButton
                        disabled={data.daQuaHan}
                        type="submit"
                        className="btn-save"
                        text="Gửi"
                        loading={this.state.isSubmit}
                        onClick={this.handleSubmitForm}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </section>
        </LayoutMain>
        <Dialog
          className="image-preview"
          iconName="inbox"
          isOpen={this.state.openDialog}
          onClose={this.togleDialog.bind(this)}
          title="Preview"
        >
          <div className="pt-dialog-body">
            <img
              style={{ maxWidth: '100%', maxHeigh: '100%' }}
              alt=""
              onClick={this.togleDialog.bind(this)}
              src={this.state.imagePreview}
            />
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickClosePopup}
          className="popup-cpc-confirm w-400"
          title="Thông báo"
        >
          <DialogBody>
            <p className="message-cofirm">Đã hoàn thành phiếu khảo sát</p>
          </DialogBody>
          <DialogFooter>
            <div className="footer-right">
              <CPCButton
                intent={Intent.PRIMARY}
                onClick={this.handleConfirm}
                text="Đóng"
                className="cpc-info"
              />
            </div>
          </DialogFooter>
        </Dialog>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },

  survey: {
    ...state.surveys,
    item: state.entities.surveys[state.surveys.phieuKhaoSatId],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyItemPage);
