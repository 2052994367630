import CPCAvatar from '@/components/atoms/avatar';
import { getLinkAvatar } from '@/helpers/image';
import { connect } from 'react-redux';
import variables from '@/constants/variables';
import cloudImage from '@/assets/images/cloud.svg';
import './index.scss';

const AvatarConversation = ({
  auth,
  actions,
  history,
  anhDaiDien,
  tongSoNhanVien,
  nhanVienHoiThoai,
  isGroup,
}) => {
  const { accessToken } = auth.token;

  const renderAvatar = () => {
    const members = nhanVienHoiThoai?.filter(
      (o) => o?.nhanVien?.nhanVienId !== auth?.user?.nhanVienId
    );

    if (isGroup === variables.CONVERSATION_TYPE.CLOUD) {
      return <img className="cloud-avatar" src={cloudImage} />;
    }

    if (isGroup === variables.CONVERSATION_TYPE.PERSONAL) {
      if (tongSoNhanVien === 1) {
        return (
          <CPCAvatar
            actions={actions}
            history={history}
            src={nhanVienHoiThoai?.[0]?.nhanVien?.anhDaiDien}
            size="md"
            trucTuyen={nhanVienHoiThoai?.[0]?.nhanVien?.trucTuyen}
            userInfo={nhanVienHoiThoai?.[0]?.nhanVien}
          />
        );
      } else {
        return (
          <CPCAvatar
            actions={actions}
            history={history}
            src={members?.[0]?.nhanVien?.anhDaiDien}
            size="md"
            trucTuyen={members?.[0]?.nhanVien?.trucTuyen}
            userInfo={members?.[0]?.nhanVien}
          />
        );
      }
    } else if (isGroup === variables.CONVERSATION_TYPE.GROUP) {
      if (anhDaiDien) {
        const url = getLinkAvatar({ anhDaiDien, accessToken });
        return <CPCAvatar actions={actions} history={history} src={url} size="md" />;
      } else if (tongSoNhanVien === 1) {
        return (
          <div className="avatar-conversation__group">
            <CPCAvatar
              actions={actions}
              history={history}
              size="sm-2-medium"
              className={`avatar-4-1`}
              src={nhanVienHoiThoai[0]?.nhanVien?.anhDaiDien}
              userInfo={nhanVienHoiThoai[0]?.nhanVien}
            />

            <div className="avatar-4-2-2">0</div>
          </div>
        );
      } else if (tongSoNhanVien === 2) {
        return (
          <div className="avatar-conversation__group">
            <CPCAvatar
              actions={actions}
              history={history}
              size="sm-2-medium"
              className={`avatar-4-1`}
              src={nhanVienHoiThoai[0]?.nhanVien?.anhDaiDien}
              userInfo={nhanVienHoiThoai[0]?.nhanVien}
            />

            <CPCAvatar
              actions={actions}
              history={history}
              size="sm-2-medium"
              className={`avatar-4-2`}
              src={nhanVienHoiThoai[1]?.nhanVien?.anhDaiDien}
              userInfo={nhanVienHoiThoai[1]?.nhanVien}
            />
          </div>
        );
      } else if (tongSoNhanVien === 3) {
        return (
          <div className="avatar-conversation__group">
            {(nhanVienHoiThoai || []).slice(0, 3).map((item, index) => (
              <CPCAvatar
                actions={actions}
                history={history}
                key={index}
                size="sm-1"
                className={`avatar-${index}`}
                src={item?.nhanVien?.anhDaiDien}
                userInfo={item?.nhanVien}
              />
            ))}
          </div>
        );
      } else if (tongSoNhanVien === 4) {
        return (
          <div className="avatar-conversation__group">
            {(nhanVienHoiThoai || []).slice(0, 4).map((item, index) => (
              <CPCAvatar
                actions={actions}
                history={history}
                key={index}
                size="sm-2"
                className={`avatar-4-${index}`}
                src={item?.nhanVien?.anhDaiDien}
                userInfo={item?.nhanVien}
              />
            ))}
          </div>
        );
      }
    }

    return (
      <div className="avatar-conversation__group">
        {(nhanVienHoiThoai || []).slice(0, 3).map((item, index) => (
          <CPCAvatar
            actions={actions}
            history={history}
            size="sm-2"
            key={index}
            className={`avatar-4-${index}`}
            src={item?.nhanVien?.anhDaiDien}
            userInfo={item?.nhanVien}
          />
        ))}
        <div className="avatar-4-3">
          {tongSoNhanVien > 99 ? '99+' : Math.max(tongSoNhanVien, 0)}
        </div>
      </div>
    );
  };

  return <div className="avatar-conversation">{renderAvatar()}</div>;
};

const mapStateToProps = (state) => {
  return {
    auth: { ...state.auth, user: state.entities.users[state.auth.user] },
  };
};

export default connect(mapStateToProps, null)(AvatarConversation);
