import { TextArea } from '@blueprintjs/core';
import classNames from 'classnames';
import './index.scss';

const CPCTextArea = ({ placeholder, className, value, theme, shape, ...textAreaProps }) => {
  return (
    <TextArea
      {...textAreaProps}
      className={classNames('cpc-textarea', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
        [`shape-${shape}`]: shape,
      })}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default CPCTextArea;
