// save contents message

import { testJSON } from './json';

const NAME = 'conversation_content';
const MAX_CONVERSATION = 10;

// conversation_content = [{ conversationId: '', content: '' }, ...]

export const getMessageContents = (name = NAME) => {
  if (localStorage) {
    const contents = localStorage.getItem(name);
    if (contents) {
      return testJSON(contents) ? JSON.parse(contents) : [];
    }
  }

  return [];
};

export const setMessageContents = (conversationId, content, name = NAME) => {
  if (localStorage) {
    const contents = getMessageContents(name);

    const index = contents.findIndex((item) => item.conversationId === conversationId);
    if (index !== -1) {
      contents.splice(index, 1);
    }

    contents.unshift({ conversationId, content });

    if (contents.length > MAX_CONVERSATION) {
      contents.pop();
    }

    localStorage.setItem(name, JSON.stringify(contents));
  }
};

export const getContentCurrentConversation = (conversationId, name = NAME) => {
  if (localStorage) {
    const contents = getMessageContents(name);
    if (contents) {
      const item = contents.find((item) => item.conversationId === conversationId);
      return item?.content || '';
    }
  }

  return '';
};
