import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Position, Toaster, Intent } from '@blueprintjs/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { ImpulseSpinner } from 'react-spinners-kit';
import { jwtDecode } from 'jwt-decode';

import favicon from '@/assets/images/favicon.svg';
import faviconDot from '@/assets/images/favicon-dot.svg';
import CPCDialog from '@/components/atoms/dialog';
import CPCButtonV2 from '@/components/atoms/button-v2';
import CPCFlex from '@/components/atoms/flex';
import { RenderRoutes } from '../routes';
import 'flexboxgrid/dist/flexboxgrid.css';
import 'normalize.css/normalize.css';
import 'react-photo-view/dist/react-photo-view.css';
import './pages/assets/app.scss';
import * as Actions from '../actions/';
import { CHAT_FE_TITLE, FE_URL } from '@/constants/Api';
import { setupDataListener } from '@/middleware/firebase/firebase-functions';

const noLimitTime = 0;
const RECONNECT = true;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toaster: null,
      dialog: null,
      isOpen: true,
      isLoading: false,
      isConfirmMultipleTab: false,
    };

    this.iframeDialogRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  static propTypes = {
    route: PropTypes.object.isRequired,
    toaster: PropTypes.object,
  };

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  handleFavicon = (favicon) => {
    const link =
      document.querySelector('link[rel="shortcut icon"]') || document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = favicon;
    document.head.appendChild(link);
  };

  onReload = () => {
    window.location.reload();
  };

  onRedirectLogin = () => {
    this.props.actions.setIsLoginHasBeenChanged(false);
    this.props.actions.authLogout();
    return this.props.history.push('/login');
  };

  handleVisibilityChange = () => {
    if (!document.hidden) {
      this.props.actions.setPageVisibility(true);
    } else {
      this.props.actions.setPageVisibility(false);
    }
  };

  handleBeforeUnload = () => {
    // Trước khi đóng trình duyệt
    // Nếu xóa trình duyệt đang kích hoạt thì xóa set active-tab-key = null
    const tabCurrent = sessionStorage.getItem('backup-tab-key');
    const tabVisibility = localStorage.getItem('active-tab-key');
    if (tabVisibility && tabCurrent === tabVisibility) {
      localStorage.setItem('active-tab-key', '000000000');
    }
  };

  // Add an event listener for 'message' events on the window
  handleMessageWindow = (event) => {
    // You can add more security checks here, like verifying event.origin
    if (event.data !== 'close-popup') {
      // If the data is not what we expect, don't do anything
      return;
    }
    if (event.data === 'close-popup') {
      setTimeout(() => {
        this.props.actions.setDialogCreateWork(false, null);
      }, 1000);
    }
  };

  componentDidMount() {
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    // Mặc định lưu local tab active
    sessionStorage.setItem('backup-tab-key', moment().format());
    localStorage.setItem('active-tab-key', moment().format());

    // Xử lý tạo việc  từ CHAT
    window.addEventListener('message', this.handleMessageWindow);
  }

  componentDidUpdate(prevProps) {
    // Xử lý firebase
    if (
      prevProps.auth?.token?.accessToken &&
      this.props.auth?.user?.username &&
      this.props.auth?.user?.username !== prevProps.auth?.user?.username
    ) {
      const username = this.props.auth.user.username?.includes('.')
        ? this.props.auth.user.username.replace('.', '_')
        : this.props.auth.user.username;
      setupDataListener(
        `/system_infos/account_updated/${username}/version`,
        (versionKeyFirebase) => {
          const tokenDecoded = jwtDecode(this.props.auth?.token?.accessToken);
          const versionKey = tokenDecoded?.version;
          if (
            versionKeyFirebase &&
            (!versionKey || (versionKey && versionKey !== versionKeyFirebase))
          ) {
            this.props.actions.authLogout();
          }
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    document.title = CHAT_FE_TITLE;

    const hasIndex = nextProps.unreadList?.findIndex((f) => f.value !== 0);
    if (nextProps?.totalNewMessages || hasIndex !== -1) {
      this.handleFavicon(faviconDot);
    } else {
      this.handleFavicon(favicon);
    }

    if (nextProps.toaster) {
      this.setState({ toaster: nextProps.toaster });
    }

    if (nextProps.dialog !== this.props.dialog) {
      this.setState({ dialog: nextProps.dialog });
      if (nextProps.dialog) {
        this.toaster.clear();
      }
    }

    if (nextProps?.isPageVisibility) {
      // Kiểm tra tab đang xem có phải là tab đang được active không?
      // Nếu k đúng thì open confirm
      const tabCurrent = sessionStorage.getItem('backup-tab-key');
      const tabVisibility = localStorage.getItem('active-tab-key');
      if (tabVisibility && tabVisibility !== '000000000' && tabCurrent !== tabVisibility) {
        this.setState({ isConfirmMultipleTab: true });
      } else {
        // Ngược lại set lại local tab active cho tab đang đứng
        sessionStorage.setItem('backup-tab-key', moment().format());
        localStorage.setItem('active-tab-key', moment().format());
        this.setState({ isConfirmMultipleTab: false });
      }
    }
  }

  componentWillUpdate = async (nextProps, nextState) => {
    if (nextProps.toaster !== null && nextProps.toaster !== this.props.toaster) {
      if (nextState.toaster && nextState.toaster.limitTime) {
        await this.toaster.clear();
      } else {
        await this.toaster.clear();
        await this.toaster.show({
          message: nextState.toaster.message,
          intent: nextState.toaster.intent,
          autoFocus: true,
          timeout: nextState.toaster.noLimitTime === 0 ? nextState.toaster.noLimitTime : 5000,
        });
      }

      this.props.actions.commonRemoveToasterMessage();
      this.setState({ toaster: null });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    window.removeEventListener('message', this.handleMessageWindow);
  }

  onSubmit() {
    this.setState({ isLoading: true }, () => {
      // Khi bấm kết nối lại
      this.props.actions.setReconnectingStatus(false);
      this.props.actions.commonRemoveDialog();
      this.props.actions.commonReconnectSignalR(RECONNECT);
      this.setState({ dialog: null, isLoading: false });
    });
  }

  onClose() {
    this.props.actions.setReconnectingStatus(false);
    this.props.actions.commonRemoveDialog();
    this.props.actions.commonAddToasterMessage({
      message: 'Anh/Chị đã bị mất kết nối. Vui lòng F5 để kết nối lại!',
      intent: Intent.WARNING,
      noLimitTime,
    });
    return this.setState({ dialog: null });
  }

  renderDialog = () => {
    const isConfirmMultipleTab = this.state.isConfirmMultipleTab;
    const { dialogCreateWork, auth, isLoginHasBeenChanged } = this.props;
    if (isLoginHasBeenChanged) {
      // Thông tin tài khoản đã thay đổi, cần đăng nhập lại
      return (
        <CPCDialog
          isCloseButtonShown={false}
          className={'custom-dialog-connect'}
          theme={1}
          isOpen={isLoginHasBeenChanged}
          title={'Thông báo'}
          onClose={() => {}}
        >
          <p className="pv-20 ph-10">
            Thông tin tài khoản đã được cập nhật, vui lòng đăng nhập lại
          </p>
          <CPCFlex align={'center'} justify={'center'} className={'p-15'}>
            <CPCButtonV2
              className={'ml-20'}
              text={'Về trang đăng nhập'}
              color="blue"
              shape="round-sm"
              size="sm"
              min="90"
              onClick={this.onRedirectLogin}
            />
          </CPCFlex>
        </CPCDialog>
      );
    } else if (isConfirmMultipleTab) {
      // Chỉ được kích hoạt 1 tab trên 1 browser
      return (
        <CPCDialog
          isCloseButtonShown={false}
          className={'custom-dialog-connect'}
          theme={1}
          isOpen={isConfirmMultipleTab}
          title={`Bạn đang mở ${CHAT_FE_TITLE} trên một Tab khác`}
          onClose={() => {}}
        >
          <p className="pv-20 ph-10">Nhấn kích hoạt để sử dụng trên Tab này</p>
          <CPCFlex align={'center'} justify={'center'} className={'p-15'}>
            <CPCButtonV2
              className={'ml-20'}
              text={'Kích hoạt'}
              color="blue"
              shape="round-sm"
              size="sm"
              min="90"
              onClick={this.onReload}
            />
          </CPCFlex>
        </CPCDialog>
      );
    } else if (dialogCreateWork?.isOpen && dialogCreateWork?.id) {
      // Hiển thị giao diện tạo việc từ CHAT qua EOFFICE
      return (
        <CPCDialog
          className={'max-900'}
          title="Tạo mới công việc"
          theme={1}
          isOpen={dialogCreateWork?.isOpen}
          onClose={() => this.props.actions.setDialogCreateWork(false, null)}
        >
          <div className="pt-25 pb-15 pv-20">
            <div className="iframe-dialog-wrapper">
              <iframe
                className="iframe-dialog"
                src={`${FE_URL}/tao-cong-viec?accessToken=${auth.token?.accessToken}&tokenType=${auth.token?.tokenType}&expiresIn=${auth.token?.expiresIn}&refreshToken=${auth.token?.refreshToken}&type=${dialogCreateWork?.type}&id=${dialogCreateWork?.id}`}
                width={860}
                height={
                  this.iframeDialogRef?.current?.offsetHeight
                    ? this.iframeDialogRef?.current?.offsetHeight - 220
                    : 600
                }
              />
            </div>
          </div>
        </CPCDialog>
      );
    } else if (this.state.dialog) {
      // Thông báo mất kết nối, kết nối lại
      return (
        <CPCDialog
          isCloseButtonShown={false}
          className={'custom-dialog-connect'}
          theme={1}
          isOpen={this.state.dialog}
          title="Anh/ Chị đã bị mất kết nối. Anh/ Chị có muốn kết nối lại?"
          onClose={() => {}}
        >
          <CPCFlex align={'center'} justify={'center'} className={'p-15'}>
            <CPCButtonV2
              text={'Không'}
              color="light-gray"
              shape="round-sm"
              size="sm"
              min="90"
              disabled={this.state.isLoading}
              onClick={this.onClose}
            />
            <CPCButtonV2
              className={'ml-20'}
              text={'Đồng ý'}
              color="blue"
              shape="round-sm"
              size="sm"
              min="90"
              loading={this.state.isLoading}
              disabled={this.state.isLoading}
              onClick={this.onSubmit}
            />
          </CPCFlex>
        </CPCDialog>
      );
    }
  };

  render() {
    const isConfirmMultipleTab = this.state.isConfirmMultipleTab;
    const isReconnectingStatus = this.props.isReconnectingStatus;
    return (
      <div ref={this.iframeDialogRef}>
        {/* Hiển thị trạng thái đang connect signalr */}
        {isReconnectingStatus ? (
          <div className="reconnecting-status d-flex align-center gap-10">
            Đang kết nối lại{' '}
            <ImpulseSpinner size={20} frontColor={'#ffffff'} backColor={'#686769'} />
          </div>
        ) : (
          ''
        )}
        {this.renderDialog()}
        {/* Render router */}
        {!isConfirmMultipleTab ? <RenderRoutes routes={this.props.route.routes} /> : ''}
        <Toaster
          className="message-popup-delete"
          position={Position.TOP_RIGHT}
          ref={this.refHandlers.toaster}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
  toaster: state.surveys.toaster,
  dialog: state.surveys.dialog,
  totalNewMessages: state.conversation.totalNewMessages,
  isPageVisibility: state.configuration?.isPageVisibility,
  isReconnectingStatus: state.chat?.isReconnectingStatus,
  dialogCreateWork: state.work?.dialogCreateWork,
  isLoginHasBeenChanged: state.other?.isLoginHasBeenChanged,
  unreadList: state.conversation.unreadList,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
