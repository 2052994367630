import { schema } from 'normalizr';
import User from './User';

const Department = new schema.Entity(
  'departments',
  {
    dsNhanVien: [User],
  },
  {
    idAttribute: (department) => department.phongBanId,
  }
);

export default Department;
