import { schema } from 'normalizr';

const Conversation = new schema.Entity(
  'conversations',
  {},
  {
    idAttribute: (user) => user.nhanVienId,
  }
);

export default Conversation;
