import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cln from 'classnames';
import { Emojione } from 'react-emoji-render';

class EmoticonItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  changeEmoticon = async (data) => {
    this.props && this.props.changeEmoticon(data);
  };

  render() {
    const { emoticonName, isActive } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="emoticon-slider--item">
        <button
          disabled={isLoading}
          className={cln('emoticon-slider--item--image', {
            dot: isActive,
          })}
          onClick={() => this.changeEmoticon(this.props)}
        >
          {/* <Emojione text={emoticonName} /> */}
          {emoticonName && <Emojione>{emoticonName}</Emojione>}
        </button>
      </div>
    );
  }
}

EmoticonItem.defaultProps = {
  emoticonName: null,
  changeEmoticon: () => {},
  isActive: false,
};

EmoticonItem.propTypes = {
  emoticonName: PropTypes.string,
  changeEmoticon: PropTypes.func,
  isActive: PropTypes.bool,
};

export default EmoticonItem;
