import * as types from '@/constants/ActionTypes';

export const setMentionedIds = (data) => ({
  type: types.SET_MENTIONED_IDS,
  payload: data,
});

export const setContentEditor = (value) => ({
  type: types.SET_CONTENT_EDITOR,
  payload: value,
});
