import { useEffect, useState } from 'react';
import moment from 'moment';

import Survey from '@/components/molecules/messages/survey';
import { variables } from '@/constants/variables';
import CPCFlex from '@/components/atoms/flex';
import { urlify, getContent, stringMentionSetEditor } from '@/helpers/chat';
import ForwardFile from './file';

const ForwardItem = ({ dataSource, auth, onRemove = () => {} }) => {
  const [mainOrganization, setMainOrganization] = useState(null);

  useEffect(() => {
    setMainOrganization(dataSource?.nguoiGui?.dsChucDanh?.find((f) => f?.phongBanChinh));
  }, [dataSource]);

  return (
    <CPCFlex align={'center'} justify={'space-between'} gap={10} className={'forward-item'}>
      <CPCFlex align={'flex-start'} gap={10}>
        <span className="icon-forward-v2 color-text-time font-size-13 color-orange"></span>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: stringMentionSetEditor(urlify(getContent(dataSource?.noiDung))),
            }}
            className="forward-item-content"
          />
          {dataSource?.fileDinhKem && dataSource?.fileDinhKem?.length !== 0 ? (
            <div className="ph-10">
              <CPCFlex align={'center'} wrap={'wrap'} gap={10}>
                {dataSource?.fileDinhKem?.map((elm, index) => (
                  <ForwardFile
                    key={index}
                    dataSource={elm}
                    auth={auth}
                    onRemove={(file) => onRemove('FILE', file, dataSource)}
                  />
                ))}
              </CPCFlex>
            </div>
          ) : (
            ''
          )}
          {dataSource?.phieuKhaoSat && dataSource?.phieuKhaoSat?.length !== 0 ? (
            <CPCFlex align={'center'} wrap={'wrap'} gap={10}>
              {dataSource?.phieuKhaoSat &&
                dataSource?.phieuKhaoSat?.length !== 0 &&
                dataSource?.phieuKhaoSat?.map((survey, index) => (
                  <Survey key={index} history={history} dataSource={survey} />
                ))}
            </CPCFlex>
          ) : (
            ''
          )}
          <CPCFlex align={'center'} gap={10}>
            <span className="text-13-600">{dataSource?.nguoiGui?.tenNhanVien}</span>
            <span className="text-13-400">
              {mainOrganization?.phongBan?.maPhongBan} - {mainOrganization?.donVi?.tenVietTat},
            </span>
            <span className="text-13-400">
              {moment(dataSource?.ngayTao).isValid()
                ? moment(dataSource?.ngayTao).format(variables.DATE.FORMAT)
                : ''}
            </span>
          </CPCFlex>
        </div>
      </CPCFlex>
      <span
        className="icon-xmark cursor-pointer close"
        onClick={() => onRemove('MESSAGE', dataSource)}
      ></span>
    </CPCFlex>
  );
};

export default ForwardItem;
