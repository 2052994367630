import system from './en/system';
import conversation from './en/conversation';
import notification from './en/notification';

const en = {
  translation: {
    ...system,
    ...conversation,
    ...notification,
  },
};

export default en;
