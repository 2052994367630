import React, { Component } from 'react';
import { get } from 'lodash';
import { Intent } from '@blueprintjs/core';
import { decamelizeKeys } from 'humps';
import bytes from 'bytes';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import LayoutMain from '@/components/organisms/layout-main';
import Heading from '@/components/layout/heading';
import { Select, Button } from '@/components/common';
import * as Actions from '../../../actions';
import './../assets/common.scss';
import { variables } from '../../../constants/variables';
import { FE_URL, LIMIT_SIZE_FILE_5MB } from '../../../constants/Api';
import { documentAllowType, documentAllowExtension } from '../../../constants/fileType';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

const EMPTY_FILE = {
  tenFile: '',
  kieuFile: '',
  file: undefined,
};
class FeedbackSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingData: false,
      isUploadFile: false,
      isSubmit: false,
      isLoadingButton: false,
      dsChatLuong: [
        {
          name: 'Tốt',
          icon: 'tot.svg',
          key: variables.FEEDBACK_QUALITY.GOOD,
        },
        {
          name: 'Bình thường',
          icon: 'binh-thuong.svg',
          key: variables.FEEDBACK_QUALITY.NORMAL,
        },
        {
          name: 'Chưa tốt',
          icon: 'chua-tot.svg',
          key: variables.FEEDBACK_QUALITY.NOTGOOD,
        },
      ],
      formData: {
        chatLuong: 0,
        phienBanId: '',
        noiDung: '',
        dsCauTraLoi: [],
        fileDinhKem: [],
      },
      questionsGroup: [],
      dsPhienBan: [],
      fileDinhKemDuocChon: [EMPTY_FILE],
    };
  }

  handleGoBack = () => {
    return this.props.history.push('/hop-thu-gop-y');
  };

  handleOpenEoffice = () => {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  };

  handleChange = ({ target: { name, value } = {} }) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleChangeChatLuong = (key) => {
    const { questionsGroup } = this.state;
    const cauTraLoiMacDinh = (get(questionsGroup, `[${key}].dsCauHoi`, []) || []).map(
      (question) => {
        const dapAnMacDinh = get(question, 'dsDapAn[0].dapAnId');
        const dsDapAn = dapAnMacDinh ? [dapAnMacDinh] : [];
        return {
          cauHoiId: question.cauHoiId,
          dsDapAn,
        };
      }
    );
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        chatLuong: key,
        dsCauTraLoi: cauTraLoiMacDinh,
      },
    }));
  };

  handleChangeCauTraLoi = ({ target: { value } = {} }, cauHoiId) => {
    this.setState((prevState) => {
      const dsCauTraLoi = prevState.formData.dsCauTraLoi.map((answer) => {
        if (answer.cauHoiId === cauHoiId) {
          return {
            ...answer,
            dsDapAn: [value],
          };
        }
        return answer;
      });
      return {
        formData: {
          ...prevState.formData,
          dsCauTraLoi,
        },
      };
    });
  };

  handleAddFileInput = () => {
    this.setState((prevState) => ({
      fileDinhKemDuocChon: [...prevState.fileDinhKemDuocChon, EMPTY_FILE],
    }));
  };

  handleFileSelect = (evt, index) => {
    const { files } = evt.target;
    const originalFile = files[0];
    if (originalFile) {
      if (!documentAllowType.includes(originalFile.type)) {
        return this.props.actions.commonAddToasterMessage({
          message: variables.VALIDATE.FILE_TYPE_ALLOW(documentAllowExtension),
          intent: Intent.WARNING,
        });
      }
      if (originalFile.size > LIMIT_SIZE_FILE_5MB * 1048576) {
        return this.props.actions.commonAddToasterMessage({
          message: variables.VALIDATE.FILE_SIZE_ALLOW(LIMIT_SIZE_FILE_5MB),
          intent: Intent.WARNING,
        });
      }
      this.setState((prevState) => ({
        fileDinhKemDuocChon: prevState.fileDinhKemDuocChon.map((file, fileIndex) => {
          if (fileIndex === index) {
            return {
              ...file,
              tenFile: originalFile.name,
              kichThuoc: originalFile.size,
              file: originalFile,
            };
          }
          return file;
        }),
      }));
    }
  };

  handleRemoveFile = (index) => {
    this.setState((prevState) => {
      let { fileDinhKemDuocChon } = prevState;
      if (fileDinhKemDuocChon.length === 1) {
        fileDinhKemDuocChon = [EMPTY_FILE];
      } else {
        fileDinhKemDuocChon = fileDinhKemDuocChon.filter((file, fileIndex) => fileIndex !== index);
      }
      return {
        fileDinhKemDuocChon,
      };
    });
  };

  handleSubmit = async () => {
    this.setState({ isSubmit: true, isLoadingButton: true });
    const { fileDinhKemDuocChon } = this.state;
    const filesUpload = fileDinhKemDuocChon.filter((file) => !!file.file).map((file) => file.file);
    if (filesUpload?.length > 0) {
      await this.setState({ isUploadFile: true });
      await this.props.actions.uploadFile(filesUpload).then((res) => {
        const isSucess = get(res, 'payload.status') === 200;
        if (isSucess) {
          const filesInfo = get(res, 'payload.data.result', []);
          const fileDinhKem = filesInfo.map((file) => ({
            fileId: file.id,
            tenFile: file.originalName,
            url: file.path,
            kieuFile: file.extension,
            kichThuoc: file.length,
          }));
          this.setState(
            (prevState) => ({
              formData: {
                ...prevState.formData,
                fileDinhKem,
              },
            }),
            () => {
              this.setState({ isUploadFile: false });
              const { formData, dsChatLuong } = this.state;
              let data = {
                ...formData,
                noiDung: _.trim(formData.noiDung),
                chatLuongId: get(dsChatLuong, `[${formData.chatLuong}].id`),
              };
              data.fileDinhKem = data.fileDinhKem.filter((file) => !!file.fileId);
              delete data.chatLuong;
              if (data.phienBanId === '-1') {
                data.phienBanId = '';
              }
              data.dsCauTraLoi = data.dsCauTraLoi.map((cauTraLoi) => ({
                ...cauTraLoi,
                dsDapAn: cauTraLoi.dsDapAn.filter((dapAn) => dapAn !== '-1'),
              }));
              data = decamelizeKeys(data);
              this.props.actions.guiGopY(data).then(
                (res) => {
                  const isSucess = get(res, 'payload.status') === 201;
                  if (isSucess) {
                    this.props.actions.commonAddToasterMessage({
                      message: variables.ACTION_STATUS.GOP_Y_SUCCESS,
                      intent: Intent.SUCCESS,
                    });
                    return this.props.history.push('/hop-thu-gop-y/thong-ke');
                  }
                },
                () => {
                  this.setState({ isSubmit: false, isLoadingButton: false });
                }
              );
            }
          );
        }
      });
    } else {
      this.setState({ isUploadFile: false });
      const { formData, dsChatLuong } = this.state;
      let data = {
        ...formData,
        noiDung: _.trim(formData.noiDung),
        chatLuongId: get(dsChatLuong, `[${formData.chatLuong}].id`),
      };
      data.fileDinhKem = data.fileDinhKem.filter((file) => !!file.fileId);
      delete data.chatLuong;
      if (data.phienBanId === '-1') {
        data.phienBanId = '';
      }
      data.dsCauTraLoi = data.dsCauTraLoi.map((cauTraLoi) => ({
        ...cauTraLoi,
        dsDapAn: cauTraLoi.dsDapAn.filter((dapAn) => dapAn !== '-1'),
      }));
      data = decamelizeKeys(data);
      this.props.actions.guiGopY(data).then(
        (res) => {
          const isSucess = get(res, 'payload.status') === 201;
          if (isSucess) {
            this.props.actions.commonAddToasterMessage({
              message: variables.ACTION_STATUS.GOP_Y_SUCCESS,
              intent: Intent.SUCCESS,
            });
            return this.props.history.push('/hop-thu-gop-y/thong-ke');
          }
        },
        () => {
          this.setState({ isSubmit: false, isLoadingButton: false });
        }
      );
    }
  };

  getPhienBan = () => {
    this.props.actions.feedbackGetPhienBan().then((res) => {
      const dsPhienBanRes = get(res, 'payload.data.result.items', []);
      const dsPhienBan = dsPhienBanRes.map((phienBan) => ({
        name: phienBan.phienBan,
        value: phienBan.phienBanId,
      }));
      dsPhienBan.unshift({
        name: 'Chọn',
        value: '-1',
      });
      this.setState((prevState) => ({
        dsPhienBan,
        formData: {
          ...prevState.formData,
          phienBanId: get(dsPhienBan, '[0]', {}).value || '',
        },
      }));
    });
  };

  getCauHinhGopY = () => {
    this.props.actions.getCauHinhGopY().then((res) => {
      const questionsGroup = get(res, 'payload.data.result.dsChatLuong', []);
      // Thêm option '- Chọn' cho tất cả câu hỏi
      // Giá trị mặc định là '-1'
      const questionsGroupsWithDefaultAnswer = questionsGroup.map((group) => {
        if (get(group, 'dsCauHoi', []).length) {
          const dsCauHoi = group.dsCauHoi.map((question) => {
            let dsDapAn = get(question, 'dsDapAn', []);
            dsDapAn.unshift({ noiDungDapAn: 'Chọn', dapAnId: '-1' });
            return { ...question, dsDapAn };
          });
          return { ...group, dsCauHoi };
        }
        return group;
      });
      const cauTraLoiMacDinh = (get(questionsGroup, '[0].dsCauHoi', []) || []).map((question) => {
        const dapAnMacDinh = get(question, 'dsDapAn[0].dapAnId');
        const dsDapAn = dapAnMacDinh ? [dapAnMacDinh] : [];
        return {
          cauHoiId: question.cauHoiId,
          dsDapAn,
        };
      });
      this.setChatLuongId(questionsGroup);
      this.setState((prevState) => ({
        questionsGroup: questionsGroupsWithDefaultAnswer,
        formData: {
          ...prevState.formData,
          dsCauTraLoi: cauTraLoiMacDinh,
        },
      }));
    });
  };

  setChatLuongId = (chatLuong) => {
    this.setState((prevState) => {
      const dsChatLuong = prevState.dsChatLuong.map((item) => {
        for (const i of chatLuong) {
          if (i.tenChatLuong === item.name) {
            return {
              ...item,
              id: i.chatLuongId,
            };
          }
          continue;
        }
        return item;
      });
      return { dsChatLuong };
    });
  };

  async componentDidMount() {
    await this.setState({ isFetchingData: true });
    Promise.all([this.getPhienBan(), this.getCauHinhGopY()]).then(
      this.setState({ isFetchingData: false })
    );
  }

  render() {
    const {
      isFetchingData,
      isUploadFile,
      isSubmit,
      dsChatLuong,
      dsPhienBan,
      formData,
      questionsGroup,
      fileDinhKemDuocChon,
      isLoadingButton,
    } = this.state;
    const questions = get(questionsGroup, `[${formData.chatLuong}].dsCauHoi`, []) || [];
    const checkFileIsEmpty = fileDinhKemDuocChon.some((item) => !item.file);

    return (
      <LayoutMain {...this.props.props} history={this.props.history}>
        <Scrollbars className="section" style={{ height: '100vh' }}>
          <Heading title="Thêm mới góp ý" isGoback={true} handleGoBack={this.handleGoBack} />
          <div className="cpc-container pb-65">
            <div className="cpc-content">
              <p className="cpc-content__label mb14">
                Anh/chị vui lòng đánh giá chất lượng eOffice dưới đây
              </p>
              <div className="cpc-content__body">
                <ul className="quality-list">
                  {dsChatLuong.map((item, key) => {
                    return (
                      <li key={key}>
                        <div
                          className={`smile-box ${formData.chatLuong === key ? 'active' : ''}`}
                          onClick={() => this.handleChangeChatLuong(key)}
                        >
                          <span
                            className="icon"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/images/${item.icon})`,
                            }}
                          />
                          <h4>{item.name}</h4>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xs-6">
                <div className="cpc-content">
                  <label className="cpc-content__label">1. Phiên bản đang sử dụng</label>
                  <Select
                    classNameWrapper="select-box__theme3"
                    name="phienBanId"
                    data={dsPhienBan}
                    onChange={this.handleChange}
                    value={formData.phienBanId}
                  />
                </div>
              </div>
              {questions.map((question, index) => {
                if (get(question, 'dsDapAn', []).length) {
                  return (
                    <div className="col-lg-6 col-xs-6">
                      <div className="cpc-content">
                        <label className="cpc-content__label">
                          {index + 2}. {question.cauHoi}
                        </label>
                        <Select
                          classNameWrapper="select-box__theme3"
                          name="dsCauTraLoi"
                          data={question.dsDapAn.map((answer) => ({
                            name: answer.noiDungDapAn,
                            value: answer.dapAnId,
                          }))}
                          onChange={(evt) => this.handleChangeCauTraLoi(evt, question.cauHoiId)}
                          value={get(formData, `dsCauTraLoi[${index}].dsDapAn[0]`)}
                        />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="row">
              <div className="col-lg-12 col-xs-12 flex">
                <div className="cpc-content left">
                  <label className="cpc-content__label">
                    Nội dung góp ý
                    <span>
                      <i style={{ color: '#7589A3', fontWeight: '400' }}>
                        {' '}
                        (đề xuất ý tưởng, bổ sung, hiệu chỉnh)
                      </i>
                    </span>
                  </label>
                  <textarea
                    className="cpc-content__body"
                    name="noiDung"
                    rows="4"
                    placeholder="Nhập nội dung"
                    style={{ resize: 'vertical', minHeight: '40px', borderRadius: '8px' }}
                    onChange={this.handleChange}
                    value={formData.noiDung}
                    maxLength={500}
                  />
                </div>
                <div className="cpc-content right">
                  <label className="cpc-content__label">Đính kèm file</label>
                  {fileDinhKemDuocChon.map((file, index) => (
                    <div
                      className="input-choose-file"
                      key={index}
                      style={{
                        marginBottom: index === fileDinhKemDuocChon.length - 1 ? 0 : '10px',
                      }}
                    >
                      <input
                        type="text"
                        placeholder={
                          file.file
                            ? `${file.tenFile} - ${bytes(file.kichThuoc)}`
                            : 'Chọn file đính kèm'
                        }
                      />
                      <input
                        type="file"
                        onChange={(evt) => this.handleFileSelect(evt, index)}
                        disabled={isUploadFile}
                      />
                      <button type="button">Chọn file</button>
                      {(file.file || fileDinhKemDuocChon.length > 1) && (
                        <span
                          className="icon-xmark"
                          style={{
                            position: 'absolute',
                            top: '14px',
                            right: '80px',
                            cursor: 'pointer',
                            zIndex: 10,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleRemoveFile(index);
                          }}
                        />
                      )}
                    </div>
                  ))}
                  <Button
                    className="mt10"
                    title="Thêm file đính kèm"
                    icon="icon-plus-v2"
                    disabled={isSubmit || isUploadFile || checkFileIsEmpty}
                    clickButton={this.handleAddFileInput}
                  />
                </div>
              </div>
            </div>
            <div className="cpc-form-footer">
              <Button
                loading={isLoadingButton}
                className="cpc-form-footer__button"
                title="Gửi"
                disabled={isFetchingData || isSubmit || isUploadFile}
                clickButton={this.handleSubmit}
                insideReverse
                style={{ minWidth: '85px', textTransform: 'uppercase' }}
              />
            </div>
          </div>
        </Scrollbars>
      </LayoutMain>
    );
  }
}

FeedbackSend.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackSend);
