import CPCIcon from '@/components/atoms/icon';
import CPCPopover from '@/components/atoms/popover';
import useOpen from '@/hooks/useOpen';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import './index.scss';

function Emoji(props) {
  const { handleChooseEmo, portalClassName } = props;

  const { open, handleToggle } = useOpen();

  const onEmojiClick = (e) => {
    if (e) {
      handleChooseEmo(e?.native);
    }
  };

  return (
    <CPCPopover
      isOpen={open}
      usePortal={true}
      interactionKind={'click'}
      portalClassName={portalClassName}
      content={
        <Picker
          emojiSize={20}
          perLine={8}
          data={data}
          onEmojiSelect={onEmojiClick}
          onClickOutside={(e) => {
            e.preventDefault();
            if (e.target.className !== 'cpc-icon icon-face') {
              handleToggle();
            }
          }}
        />
      }
    >
      <CPCIcon icon="face" size={16} color="#7589A3" onClick={handleToggle} />
    </CPCPopover>
  );
}

export default Emoji;
