import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { ClassicSpinner } from 'react-spinners-kit';

import { variables } from '@/constants/variables';
import CPCFlex from '@/components/atoms/flex';
import CPCIcon from '@/components/atoms/icon';
import COLORS from '@/constants/colors';
import CPCDialog from '@/components/atoms/dialog';
import CPCButtonV2 from '@/components/atoms/button-v2';
import { surveyCheckPermission, commonAddToasterMessage } from '@/actions/index';
import './index.scss';

const Survey = ({ actions, history, className, dataSource }) => {
  const { t } = useTranslation();
  const isSurvey = !dataSource?.tieuDe?.startsWith('[PĐT]');
  const title = isSurvey ? 'Phiếu khảo sát' : 'Phiếu điểm tâm';
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleSurveyDetail = () => {
    setIsLoading(true);
    actions
      .surveyCheckPermission(dataSource?.phieuKhaoSatId)
      .then((res) => {
        setIsLoading(false);
        if (res.error) {
          return actions.commonAddToasterMessage({
            message: t('notification.survey-permission-error'),
            intent: Intent.WARNING,
          });
        }

        const result = res.payload?.data?.result;
        if (result && result?.dsQuyen) {
          if (result.dsQuyen?.indexOf(variables.SURVEY_PERMISSION.ANSWER) >= 0) {
            if (result?.daHoanThanh) {
              return history.push(`/phieu-khao-sat/chi-tiet/${dataSource?.phieuKhaoSatId}`);
            }

            return history.push(`/phieu-khao-sat/tra-loi/${dataSource?.phieuKhaoSatId}`);
          } else if (result.dsQuyen?.indexOf(variables.SURVEY_PERMISSION.FOLLOW) >= 0) {
            return history.push(`/phieu-khao-sat/thong-ke/${dataSource?.phieuKhaoSatId}`);
          }
        }

        setIsOpenDialog(true);
        return;
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CPCFlex
        align="center"
        gap={10}
        className={classNames('messages-item-survey', {
          [`${className}`]: className,
          'cursor-default': isLoading,
          'cursor-pointer': !isLoading,
        })}
        onClick={isLoading ? null : handleSurveyDetail}
      >
        {isLoading ? (
          <ClassicSpinner color={isSurvey ? COLORS.blue : COLORS.orange} size={20} />
        ) : (
          <div style={{ minWidth: '28px' }}>
            {isSurvey ? (
              <CPCIcon icon="do-survey" color={COLORS.blue} size="30px" />
            ) : (
              <CPCIcon icon="coffee" color={COLORS.orange} size="30px" />
            )}
          </div>
        )}
        <div>
          <p
            className={classNames('text-13-500 m-0', {
              'color-blue': isSurvey,
              'color-orange': !isSurvey,
            })}
          >
            {title}
          </p>
          <span className="text-12-400 white-space-normal word-break-break-word">
            {dataSource?.tieuDe.replace('[PĐT]', '')}
          </span>
        </div>
      </CPCFlex>
      <CPCDialog className={'max-400'} theme={1} isOpen={isOpenDialog}>
        <div className="pt-25 pb-15 pv-20">
          <h4 className="text-16-700 m-0 mb-10 text-center">Thông báo!</h4>
          <p className="text-13-400 text-center mb-25">
            {'Anh/chị không được tương tác phiếu khảo sát này'}
          </p>
          <CPCFlex align="center" justify={'center'}>
            <CPCButtonV2
              text={'Đóng'}
              color="light-gray-v3"
              size="sm-s"
              shape="round-6"
              className={'width-180'}
              classNameText="text-14-600"
              onClick={() => setIsOpenDialog(false)}
            />
          </CPCFlex>
        </div>
      </CPCDialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ surveyCheckPermission, commonAddToasterMessage }, dispatch),
});

export default connect(null, mapDispatchToProps)(Survey);
