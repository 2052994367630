import React, { Component } from 'react';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { UPLOAD_API_URL } from '../../constants/Api';

class DownloadAllButton extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
  };

  dowloadAllFiles = () => {
    if (get(this.props, 'files', []).length < 1 || !this.props.accessToken) {
      return;
    }

    this.props.files.forEach((file) => {
      fetch(
        `${UPLOAD_API_URL}/api/${
          get(file, 'tenFile')?.startsWith('eofficeFile') ? 'file' : 'filechat'
        }/get/${get(file, 'fileId')}?app=eoffice&fileName=${get(file, 'fileName')}&BearerToken=${
          this.props.accessToken
        }`
      )
        .then((res) => res.blob())
        .then((blob) => {
          FileSaver.saveAs(blob, get(file, 'tenFile'));
        });
    });
  };

  render() {
    return (
      <span
        style={{
          background: '#7ed321',
          borderRadius: 3,
          color: '#fff',
          cursor: 'pointer',
          display: 'inline-block',
          marginTop: 8,
          padding: '5px 15px',
          fontSize: 13,
          verticalAlign: 'center',
        }}
        onClick={this.dowloadAllFiles}
      >
        <span className="cpc-icon icon-multiple-download" /> Tải tất cả file
      </span>
    );
  }
}

export default DownloadAllButton;
