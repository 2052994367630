import CPCIcon from '@/components/atoms/icon';
import COLORS from '@/constants/colors';
import useOpen from '@/hooks/useOpen';
import { Divider, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import CPCAvatar from '../../atoms/avatar';
import PopupAccount from './popup/popup-account';
import PopupLogout from './popup/popup-logout';
import './setting.scss';
import PopupSetting from './popup/popup-setting';

function Setting(props) {
  const { user = {}, actions, history } = props;
  const { open, handleClose, handleToggle } = useOpen();
  const propLogoutPopup = useOpen();
  const propAccountPopup = useOpen();
  const propConfigPopup = useOpen();

  return (
    <div className="cpc-setting">
      <Popover2
        enforceFocus={false}
        content={
          <Menu className={'message-context-menu'}>
            <MenuItem
              icon={<CPCIcon icon="user" size={16} color={COLORS.blue} />}
              text="Tài khoản"
              onClick={propAccountPopup?.handleOpen}
            />
            <MenuItem
              icon={<CPCIcon icon="setting" size={16} color={COLORS.blue} />}
              text="Cài đặt"
              onClick={propConfigPopup?.handleOpen}
            />
            <Divider />
            <MenuItem
              icon={<CPCIcon icon="logout" size={16} color={COLORS.red} />}
              text={<span className="name color-red">Đăng xuất</span>}
              onClick={propLogoutPopup?.handleOpen}
            />
          </Menu>
        }
        interactionKind={'click'}
        isOpen={open}
        placement={'right'}
        onClose={handleClose}
      >
        <CPCAvatar src={user?.anhDaiDien} onClick={handleToggle} className="setting-avatar" />
      </Popover2>

      {propLogoutPopup?.open && <PopupLogout {...{ actions, ...propLogoutPopup }} />}
      {propAccountPopup?.open && <PopupAccount {...propAccountPopup} history={history} />}
      {propConfigPopup?.open && <PopupSetting {...{ actions, ...propConfigPopup, ...props }} />}
    </div>
  );
}

export default Setting;
