import CPCButton from '@/components/atoms/button';
import { Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';

function Remove(props) {
  const { isOpenPopup, isLoading, handleClickPopup, handleClickClosePopup, handleClickRemove } =
    props;

  return (
    <Dialog
      isOpen={isOpenPopup}
      onClose={handleClickPopup}
      className="popup-cpc-confirm w-400"
      title="Xác nhận"
    >
      <DialogBody>
        <p>
          Kết quả bỏ phiếu và thống kê sẽ không còn hiệu lực sau khi thực hiện xóa phiếu khảo sát.
        </p>
        <p>Anh chị có chắc chắn muốn xóa?</p>
      </DialogBody>
      <DialogFooter>
        <div className="footer-right">
          <CPCButton className="cpc-info" text="Không" onClick={handleClickClosePopup} />
          <CPCButton
            intent={Intent.PRIMARY}
            onClick={handleClickRemove}
            text="Xóa"
            className="cpc-danger"
            loading={isLoading}
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default Remove;
