import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, RadioGroup, Radio, Collapse, Dialog } from '@blueprintjs/core';
import './assets/config.scss';
import classnames from 'classnames';
import { SelectTree, CheckboxTree } from '../chat';
import { Scrollbars } from 'react-custom-scrollbars';
import { IMAGE_URL } from '../../constants/Api';
import CPCIcon from '../atoms/icon';
import classNames from 'classnames';
import { UPDATER_API } from '../../constants/Api';
import CPCFlex from '@/components/atoms/flex';

const sinhNhatRadioData = [
  {
    label: 'CBCNV trong toàn EVNCPC',
    value: '0',
  },
  {
    label: 'CBCNV trong đơn vị',
    value: '1',
  },
  {
    label: 'CBCNV trong danh sách tùy chọn',
    value: '2',
  },
  {
    label: 'Không nhận thông báo',
    value: '3',
  },
];

const SettingMenu = [
  {
    label: 'Chung',
    icon: 'setting',
    key: 'COMMON',
  },
  {
    label: 'Tải app notification',
    icon: 'application',
    key: 'APP',
  },
];

export default class Config extends Component {
  static propTypes = {
    config: PropTypes.object,
  };

  static defaultProps = {
    config: {},
  };

  state = {
    isLoading: false,
    selectedUser: this.props.config.dsNguoiNhanThongBaoSinhNhat || [],
    form: {
      suDungChatOffline: this.props.config?.suDungChatOffline,
      loaiThongBaoSinhNhat:
        this.props.config?.loaiThongBaoSinhNhat !== null &&
        this.props.config.loaiThongBaoSinhNhat !== undefined
          ? this.props.config.loaiThongBaoSinhNhat.toString()
          : '1',
      dsNguoiNhanThongBaoSinhNhat: this.props.config.dsNguoiNhanThongBaoSinhNhat || [],
    },
    unitId: this.props.auth.mainUnitId,
    departmentData: [],
    isCheckAllDepartment: false,
    menuActive: 'COMMON',
  };

  componentDidMount() {
    this.fetchDepartmentsByUnit();
  }

  handleInputChange = (e) => {
    const { value, name, checked, type } = e.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  handleChangeMenuActive = (key) => {
    this.setState({
      menuActive: key,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let data = {
      ...this.state.form,
      dsNguoiNhanThongBaoSinhNhat: this.state.form.dsNguoiNhanThongBaoSinhNhat.map((user) => ({
        nhanVienId: user.nhanVienId,
        tenNhanVien: user.tenNhanVien,
      })),
    };
    this.props.onSubmit &&
      this.props.onSubmit(data).then(() => {
        this.setState({ isLoading: false });
      });
  };

  handleChangeSelect = (id, name, value) => {
    let result = id;
    if (!id) {
      result = value?.triggerValue;
    }

    this.setState(
      {
        unitId: result,
      },
      () => this.fetchDepartmentsByUnit()
    );
  };

  fetchDepartmentsByUnit = async () => {
    await this.setState({ isLoading: true });
    const res = await this.props.actions.contactFetchDepartmentsByUnitNoEntity(this.state.unitId);
    if (
      res &&
      res.payload &&
      res.payload.data &&
      res.payload.data.result &&
      res.payload.data.result.items
    ) {
      this.setState({
        departmentData: res.payload.data.result.items,
        isLoading: false,
      });
    }
  };

  handleCheckboxTreeChange = (userArr) => {
    let newUserArr = userArr.map((user) => {
      if (user.dsChucDanh) {
        let pbChinh = user.dsChucDanh.find((item) => item.phongBanChinh);
        return {
          nhanVienId: user.nhanVienId,
          tenNhanVien: user.tenNhanVien,
          tenVietTatDonVi: pbChinh.donVi.tenVietTat,
          maPhongBan: pbChinh.phongBan.maPhongBan,
          anhDaiDien: user.anhDaiDien,
        };
      } else {
        return user;
      }
    });
    this.setState({
      selectedUser: newUserArr,
      form: {
        ...this.state.form,
        dsNguoiNhanThongBaoSinhNhat: userArr,
      },
    });
  };

  handleRemoveUserChecked = (user) => {
    this.checkboxTree.onChange({ userArr: [user], checked: false });
  };

  handleCheckAllDepartment = (auto = false) => {
    this.setState(
      {
        isCheckAllDepartment: !this.state.isCheckAllDepartment,
      },
      () => {
        !auto && this.toggleAllDepartment(this.state.isCheckAllDepartment);
      }
    );
  };

  getAllChild = (phongBan) => {
    const { departmentData } = this.state;
    let idDepartmentArr = [];
    let userArr = phongBan.dsNhanVien || [];

    for (let i = 0; i < departmentData.length; i++) {
      const item = departmentData[i];
      if (item.phongBanCha === phongBan.phongBanId) {
        idDepartmentArr.push(item.phongBanId);
        let idArrChild = this.getAllChild(item);
        userArr = [...userArr, ...idArrChild.user];
        idDepartmentArr = [...idDepartmentArr, ...idArrChild.department];
      }
    }
    return {
      user: userArr,
      department: idDepartmentArr,
    };
  };

  toggleAllDepartment = (checked) => {
    const data = this.state.departmentData;
    let userArr = [];
    let idDepartmentArr = [];
    data.forEach((item) => {
      const { user, department } = this.getAllChild(item);
      userArr.push(...user);
      idDepartmentArr.push(item.phongBanId, ...department);
    });
    this.checkboxTree.onChange({ userArr, idDepartmentArr, checked });
  };

  render() {
    return (
      <div className="cpc-save-document">
        <div className="save-document-form-container">
          <ul className="main-form-menu">
            {SettingMenu?.map((item) => (
              <li
                key={item.key}
                className={classNames({ active: this.state.menuActive === item.key })}
                onClick={() => this.handleChangeMenuActive(item.key)}
              >
                <CPCIcon icon={item.icon} size={16} color="#7589A3" />
                <span>{item.label}</span>
              </li>
            ))}
          </ul>
          <form className="cpc-save-document-form" onSubmit={this.handleSubmit}>
            <div className="main-form-content">
              {this.state.menuActive === 'COMMON' && (
                <div className="cpc-form-group">
                  <label className="label-content">Gửi tin nhắn</label>
                  <Checkbox
                    label="Ctrl + Enter"
                    onChange={this.handleInputChange}
                    name="phimTatWebSend"
                    defaultChecked={
                      this.props.config.phimTatWebSend &&
                      this.props.config.phimTatWebSend === 'true'
                    }
                  />
                </div>
              )}
              {this.state.menuActive === 'COMMON' && (
                <>
                  <div className="cpc-form-group">
                    <label className="label-content">Thông báo sinh nhật</label>
                    <RadioGroup
                      name="loaiThongBaoSinhNhat"
                      onChange={this.handleInputChange}
                      selectedValue={this.state.form.loaiThongBaoSinhNhat}
                    >
                      {sinhNhatRadioData.map((item, index) => {
                        if (index !== 2) {
                          return (
                            <Radio
                              key={index}
                              label={item.label}
                              value={item.value}
                              className="mb15"
                            />
                          );
                        } else {
                          return [
                            <Radio
                              key={index}
                              label={item.label}
                              value={item.value}
                              className={classnames({
                                mb15: this.state.form.loaiThongBaoSinhNhat !== '2',
                              })}
                            />,
                            <Collapse
                              className="cpc-department-contact-list"
                              isOpen={this.state.form.loaiThongBaoSinhNhat === '2'}
                            >
                              <div className="rc-select-custom--config">
                                <Checkbox
                                  checked={this.state.isCheckAllDepartment}
                                  onChange={() => this.handleCheckAllDepartment()}
                                  disabled={this.state.isLoading}
                                  className="rc-select-custom__checkbox"
                                />
                                <SelectTree
                                  className="rc-select-custom__tree"
                                  data={this.props.units}
                                  handleChange={this.handleChangeSelect}
                                  value={this.state.unitId}
                                  isLoading={this.state.isLoading}
                                />
                                <span
                                  title="Danh sách thông báo sinh nhật"
                                  onClick={() => this.setState({ isOpenListUser: true })}
                                  className="icon-document noti-birthday"
                                />
                                {this.state.form.dsNguoiNhanThongBaoSinhNhat &&
                                  this.state.form.dsNguoiNhanThongBaoSinhNhat.length > 0 && (
                                    <span
                                      onClick={() => this.setState({ isOpenListUser: true })}
                                      className="rc-select-custom__dot"
                                    ></span>
                                  )}
                              </div>
                              <CheckboxTree
                                ref={(el) => (this.checkboxTree = el)}
                                isLoading={this.state.isLoading}
                                userChecked={this.state.form.dsNguoiNhanThongBaoSinhNhat}
                                onChange={this.handleCheckboxTreeChange}
                                data={this.state.departmentData}
                                isCheckAllDepartment={this.state.isCheckAllDepartment}
                                handleCheckAllDepartment={this.handleCheckAllDepartment}
                              />
                            </Collapse>,
                          ];
                        }
                      })}
                    </RadioGroup>
                  </div>
                  <div className="cpc-form-group mb-0">
                    <Button
                      type="submit"
                      className="pt-button btn-save"
                      loading={this.state.isLoading}
                    >
                      Lưu cài đặt
                    </Button>
                  </div>
                </>
              )}
              {this.state.menuActive === 'APP' && (
                <div className="application-get">
                  <span className="title">Tải app notification</span>
                  <p className="description">
                    Chọn hệ điều hành mà anh chị đang sử dụng để tải về ứng dụng thông báo.
                  </p>
                  <div className="app-action">
                    {/* ${UPDATER_API}/download/latest/osx_64 */}
                    <a
                      className="app-action-item"
                      target="_blank"
                      href={
                        'https://drive.google.com/file/d/1GVQQRps0YxLItmHv9gzOvTe1v0zXjEcJ/view?usp=sharing'
                      }
                    >
                      <CPCIcon icon="apple" size={24} color="#fff" />
                      <div className="app-action-description">
                        <span className="text-download">Tải ứng dụng</span>
                        <span className="text-notification">THÔNG BÁO</span>
                      </div>
                    </a>
                    <a
                      className="app-action-item"
                      // href={`${UPDATER_API}/download/latest/windows_32`}
                      target="_blank"
                      href={
                        'https://drive.google.com/file/d/16lqYgafyBADMF1ewu9jlkKmYUgsul6Qs/view?usp=sharing'
                      }
                    >
                      <CPCIcon icon="windows" size={24} color="#fff" />
                      <div className="app-action-description">
                        <span className="text-download">Tải ứng dụng</span>
                        <span className="text-notification">THÔNG BÁO</span>
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <Dialog
          className="cpc-modify-favourite-dialog"
          isOpen={this.state.isOpenListUser}
          onClose={() => this.setState({ isOpenListUser: false })}
          title={`Danh sách thông báo sinh nhật (${
            this.state.form.dsNguoiNhanThongBaoSinhNhat
              ? this.state.form.dsNguoiNhanThongBaoSinhNhat.length
              : 0
          })`}
        >
          <div className="pt-dialog-body">
            <Scrollbars
              style={{ width: '100%', height: 250 }}
              renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
            >
              <div className="cpc-list-container">
                {this.state.selectedUser &&
                  this.state.selectedUser.length > 0 &&
                  this.state.selectedUser.map((user, i) => (
                    <CPCFlex
                      key={i}
                      align={'center'}
                      justify={'space-between'}
                      gap={10}
                      className={'cpc-item'}
                      style={{ alignItems: 'center' }}
                    >
                      <div className="d-flex middle-xs">
                        <div className="border-gray rounded mr20">
                          <div
                            style={{ backgroundImage: `url(${IMAGE_URL + user.anhDaiDien})` }}
                            className="rounded w-50px h-50px bg-cover"
                          ></div>
                        </div>
                        <div>
                          <p className="cpc-text">{user.tenNhanVien}</p>
                          <p>
                            {user.maPhongBan} - {user.tenVietTatDonVi}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => this.handleRemoveUserChecked(user)}
                        style={{ margin: 0, padding: 5 }}
                      >
                        <span className="icon-xmark color-text-time font-size-12" />
                      </button>
                    </CPCFlex>
                  ))}
              </div>
            </Scrollbars>
          </div>
        </Dialog>
      </div>
    );
  }
}
