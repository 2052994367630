import { useState, useEffect, useCallback } from 'react';
import differenceBy from 'lodash/differenceBy';
import unionBy from 'lodash/unionBy';
import { filter as filterLodash, size } from 'lodash';
import { Intent } from '@blueprintjs/core';
import { decamelizeKeys, camelizeKeys } from 'humps';

import {
  urlify,
  getContent,
  getForward,
  securityMessage,
  stringMentionSetEditor,
  removeLastBlankLine,
} from '@/helpers/chat';
import { compareArrays, arrayToCompare, checkScroll } from '@/helpers/helper';

const useShare = ({
  actions,
  auth,
  hoiThoaiId,
  actionProps,
  limitMember,
  dataFiles,
  conversationLatestContact,
  allUsersConversation,
  signalRHub,
  onClose,
  onReset,
  fileList,
  searchParamKhaoSat,
}) => {
  const tab = {
    hoiThoai: 'CONVERSATION',
    donVi: 'ORGANIZATION',
    donViKhac: 'ORGANIZATION_OTHER',
  };
  const actionType = {
    KHAO_SAT: 'SURVEY',
    CHUYEN_TIEP: 'FORWARD',
  };
  const [dataTemp, setDataTemp] = useState([]);
  const [dataDisable, setDataDisable] = useState([]);
  const [files, setFiles] = useState([]);
  const [tabActive, setTabActive] = useState(tab.hoiThoai);
  const [filter, setFilter] = useState(null);
  const [dataSearch, setDataSearch] = useState(null);
  const [dataSearchConversation, setDataSearchConversation] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState([]);
  const [dataChecked, setDataChecked] = useState([]);
  const [dataForm, setDataForm] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [isLoadingTree, setIsLoadingTree] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [forwardMessage, setForwardMessage] = useState([]);

  const [isForwardDisplay, setIsForwardDisplay] = useState(false);

  const onRemoveForward = (type, value, message) => {
    if (type === 'MESSAGE') {
      setForwardMessage(forwardMessage?.filter((f) => f?.tinNhanId !== value?.tinNhanId));
    } else {
      setForwardMessage(
        forwardMessage?.map((item) =>
          item?.tinNhanId === message?.tinNhanId
            ? {
                ...item,
                fileDinhKem: item?.fileDinhKem?.filter((f) => f.fileId !== value?.fileId),
              }
            : item
        )
      );
    }
  };

  const getConversationLatestSearch = (page = 1) => {
    if (page > 1) {
      setIsLoadingPagination(true);
    } else {
      setIsLoadingTree(true);
    }

    const text = filter?.textSearch?.trim() || undefined;
    setIsSearch(true);
    actions
      .getSearchConversationList({
        searchText: text,
        page,
        limit: 10,
      })
      .then((res) => {
        setDataSearchConversation((prev) => ({
          ...prev,
          ...res?.payload?.data?.result,
          items:
            page === 1
              ? res?.payload?.data?.result?.items
              : [...prev?.items, ...res?.payload?.data?.result?.items],
        }));
        setIsLoadingPagination(false);
        setIsLoadingTree(false);
      });
    return;
  };

  const getConversationLatest = (page = 1) => {
    if (page > 1) {
      setIsLoadingPagination(true);
    } else {
      setIsLoadingTree(true);
    }
    const text = filter?.textSearch?.trim() || undefined;
    actions
      .getConversationLatestContactList({
        name: text,
        page,
        limit: 10,
      })
      .then(() => {
        setIsLoadingPagination(false);
        setIsLoadingTree(false);
      });
  };

  const onClear = useCallback(() => {
    setIsSearch(false);
    setDataSearch(null);
    if (tabActive === tab.donViKhac) {
      setFilter((prev) => ({
        ...prev,
        textSearch: '',
      }));
    } else {
      setFilter(null);
    }
    if (tabActive === tab.hoiThoai) {
      setDataSearchConversation(null);
      getConversationLatest(1);
    }
  }, [tabActive]);

  const onChangeTab = (value) => {
    onClear();
    setTabActive(value);
  };

  const getUsers = (data, result = []) => {
    let newData = [...result];
    if (data?.dsNhanVien && data?.dsNhanVien?.length !== 0) {
      newData = [...newData, ...data?.dsNhanVien];
    }

    if (data?.children && data?.children?.length !== 0) {
      data?.children.forEach((element) => {
        newData = getUsers(element, newData);
      });
    }

    return newData;
  };

  const onUnitsSetData = (res) => {
    const newItems = res?.payload?.data?.result?.result?.items;
    const newEntitiesDepartment = res?.payload?.data?.entities?.departments;
    const newEntitiesUser = res?.payload?.data?.entities?.users;
    if (newItems) {
      let newUser = [];
      newItems?.forEach((id) => {
        const newDepartment = newEntitiesDepartment[id];
        newDepartment?.dsNhanVien?.forEach((idUser) => {
          newUser = [...newUser, newEntitiesUser[idUser]];
        });
      });

      return newUser;
    }
  };

  const onUnitChangeData = ({ departments, units }) => {
    let result1 = [];
    departments?.forEach((department) => {
      const childUsers = getUsers(department);
      result1 = [...result1, ...childUsers];
    });

    let result2 = [];
    units?.forEach((unit) => {
      unit?.dsPhongBan?.forEach((department) => {
        const childUsers = getUsers(department);
        result2 = [...result2, ...childUsers];
      });
      unit?.dsDonViCon?.forEach((unitChild) => {
        unitChild?.dsPhongBan?.forEach((department) => {
          const childUsers = getUsers(department);
          result2 = [...result2, ...childUsers];
        });
      });
    });

    const result = [...result1, ...result2];
    return result;
  };

  const onToggle = async (_, data) => {
    setLoading([]);
    if (data?.maDonVi) {
      if (data?.dsPhongBan?.length === 0) {
        setLoading([data?.donViId]);
        await actions.contactFetchDepartmentsByUnit(data.donViId).then(() => {
          setLoading([]);
        });
        for (const unit of data?.dsDonViCon) {
          setLoading([data?.donViId]);
          await actions.contactFetchDepartmentsByUnit(unit.donViId).then(() => {
            setLoading([]);
          });
        }
      }
    }
  };

  const onChecked = async (isOpen, checked, data) => {
    setDateError({});
    let newData = dataChecked || [];
    if (checked) {
      if (data?.nhanVienId) {
        newData = [...newData, data];
      } else {
        if (
          data?.phongBanId &&
          data?.maPhongBan &&
          ((data?.children && data?.children?.length !== 0) ||
            (data?.dsNhanVien && data?.dsNhanVien?.length !== 0))
        ) {
          const childUsers = getUsers(data);
          newData = [...newData, ...childUsers];
        }

        if (data?.donViId && data?.maDonVi) {
          if (
            (data?.dsPhongBan && data?.dsPhongBan?.length === 0) ||
            (data?.dsDonViCon && data?.dsDonViCon?.length === 0)
          ) {
            // Checked đơn vị chưa có children thì gọi api để lấy tất cả user
            await actions.contactFetchDepartmentsByUnit(data.donViId).then((res) => {
              if (res) {
                const organizationData = onUnitsSetData(res);
                newData = [...newData, ...organizationData];
              }
            });
            for (const unit of data?.dsDonViCon) {
              await actions.contactFetchDepartmentsByUnit(unit.donViId).then((res) => {
                if (res) {
                  const organizationData = onUnitsSetData(res);
                  newData = [...newData, ...organizationData];
                }
              });
            }
          } else {
            // Đã có children thì lấy tất cả user
            const organizationData = onUnitChangeData({
              departments: data?.dsPhongBan,
              units: data?.dsDonViCon,
            });
            newData = [...newData, ...organizationData];
          }
        }
      }
    } else {
      if (data?.nhanVienId) {
        if (actionProps.type === 'SURVEY') {
          const listNhanVienIdExist = newData.find(
            (nhanVien) => nhanVien?.nhanVienId === data?.nhanVienId
          );
          if (listNhanVienIdExist.hoiThoaiIdCha) {
            const listHoiThoai = newData.filter(
              (nhanVien) => nhanVien?.hoiThoaiIdCha === listNhanVienIdExist.hoiThoaiIdCha
            );
            if (size(listHoiThoai) === 1) {
              newData = newData?.filter((nhanVien) => {
                return !(
                  listNhanVienIdExist.hoiThoaiIdCha === nhanVien.hoiThoaiId ||
                  nhanVien.nhanVienId === listNhanVienIdExist?.nhanVienId
                );
              });
            }
          } else {
            newData = newData?.filter((e) => e?.nhanVienId !== data?.nhanVienId);
          }
        } else {
          newData = newData?.filter((e) => e?.nhanVienId !== data?.nhanVienId);
        }
      } else {
        if (
          (data?.children && data?.children?.length !== 0) ||
          (data?.dsNhanVien && data?.dsNhanVien?.length !== 0)
        ) {
          const childUsers = getUsers(data);
          newData = differenceBy(newData, childUsers, 'nhanVienId');
        }

        if (data?.donViId && data?.maDonVi) {
          const organizationData = onUnitChangeData({
            departments: data?.dsPhongBan,
            units: data?.dsDonViCon,
          });
          newData = differenceBy(newData, organizationData, 'nhanVienId');
        }
      }
    }

    newData = newData?.filter((user) => user?.nhanVienId !== auth?.user?.nhanVienId);
    setDataChecked(newData);
    return actions.updateIsScroll(true);
  };

  const getOrganization = (organizations) => {
    return Promise.all(
      organizations.map(async (elm) => {
        let result = [];
        if (elm?.dsPhongBan && elm?.dsPhongBan?.length === 0) {
          // Checked đơn vị chưa có children thì gọi api để lấy tất cả user
          await actions.contactFetchDepartmentsByUnit(elm.donViId).then((res) => {
            if (res) {
              const organizationData = onUnitsSetData(res);
              result = organizationData;
            }
          });
        } else {
          // Đã có children thì lấy tất cả user
          const organizationData = onUnitChangeData({
            departments: elm?.dsPhongBan,
            units: elm?.dsDonViCon,
          });
          result = organizationData;
        }
        return result;
      })
    ).then((res) => {
      let result = [];
      res?.forEach((e) => {
        result = [...result, ...e];
      });
      return result;
    });
  };

  const onCheckedAll = async (isCheck, data) => {
    const organizations = data?.filter((e) => e?.donViId && e?.maDonVi);
    const newData2 = await getOrganization(organizations);
    let result = [];
    if (data?.[0]?.phongBanId && data?.[0]?.maPhongBan) {
      data?.forEach((elm) => {
        const childUsers = getUsers(elm);
        result = [...result, ...childUsers];
      });
    } else {
      result = data;
    }

    let newData = [];

    if (isCheck) {
      result = result?.filter((user) => user?.nhanVienId !== auth?.user?.nhanVienId);
      newData = unionBy([...dataChecked, ...newData2, ...result], 'nhanVienId');
    } else {
      newData = differenceBy(dataChecked, [...newData2, ...result], 'nhanVienId');
    }

    setDataChecked(newData);
    return actions.updateIsScroll(true);
  };

  const handleSearchMapData = useCallback((value, users, page = 1) => {
    let newItem = [];
    value?.items?.forEach((id) => {
      const item = users[id];
      newItem = [...newItem, item];
    });
    setDataSearch((prev) =>
      page === 1
        ? {
            ...value,
            items: newItem,
          }
        : {
            ...value,
            items: [...prev.items, ...newItem],
          }
    );
  }, []);

  const handleSearch = useCallback(
    (page = 1) => {
      setIsSearch(true);
      setIsLoadingTree(true);
      let organizationId = auth.mainUnitId;
      const text = filter?.textSearch?.trim() || undefined;
      if (tabActive === tab.donVi) {
        actions.contactSearchByUnit(organizationId, text, page).then((res) => {
          setIsLoadingTree(false);
          const result = res?.payload?.data?.result?.result;
          if (result) {
            const users = res?.payload?.data?.entities?.users;
            handleSearchMapData(result, users, page);
          }
        });
      } else if (tabActive === tab.donViKhac) {
        if (filter?.unitId && filter?.unitId !== 'All') {
          organizationId = filter?.unitId;
          actions.contactSearchByUnit(organizationId, text, page).then((res) => {
            setIsLoadingTree(false);
            const result = res?.payload?.data?.result?.result;
            if (result) {
              const users = res?.payload?.data?.entities?.users;
              handleSearchMapData(result, users, page);
            }
          });
        } else {
          actions.contactSearchAll(organizationId, text, page).then((res) => {
            setIsLoadingTree(false);
            const result = res?.payload?.data?.result?.result;
            if (result) {
              const users = res?.payload?.data?.entities?.users;
              handleSearchMapData(result, users, page);
            }
          });
        }
      }
    },
    [auth, filter, tabActive, tab]
  );

  const onScroll = (scroll) => {
    if (
      checkScroll(scroll) &&
      dataSearch?.items &&
      dataSearch.items.length !== 0 &&
      dataSearch.items.length < dataSearch.total &&
      !isLoading
    ) {
      handleSearch(dataSearch.page + 1);
    }
  };

  const onChangeSearch = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (event?.target?.value) {
        setFilter((prev) => ({
          ...prev,
          textSearch: event?.target?.value,
        }));
        if (tabActive === tab.hoiThoai) {
          return getConversationLatestSearch();
        }

        return handleSearch();
      }
    }
  };

  const onSelectUnit = (id) => {
    setFilter((prev) => ({
      ...prev,
      unitId: id,
    }));
  };

  const onConversationScroll = (scroll) => {
    if (
      isSearch &&
      checkScroll(scroll) &&
      !isLoadingPagination &&
      dataSearchConversation &&
      dataSearchConversation?.total !== 0 &&
      dataSearchConversation?.items?.length < dataSearchConversation?.total
    ) {
      getConversationLatestSearch(dataSearchConversation?.page + 1);
    } else if (
      !isSearch &&
      checkScroll(scroll) &&
      !isLoadingPagination &&
      conversationLatestContact &&
      conversationLatestContact?.total !== 0 &&
      conversationLatestContact?.items?.length < conversationLatestContact?.total
    ) {
      getConversationLatest(conversationLatestContact?.page + 1);
    }
  };

  const onConversationChecked = async (isCheck, value) => {
    setDateError({});
    let newData = dataChecked;

    if (isCheck) {
      if (actionProps.type === 'SURVEY' && tabActive === 'CONVERSATION') {
        if (value?.hoiThoaiId) {
          await actions
            .getConversationAllUsers(value?.hoiThoaiId, { page: 1, limit: 10000, isDaDuyet: true })
            .then((res) => {
              if (res) {
                const { items } = res?.payload?.data?.result;
                // Filter the items once outside the array construction
                const filteredItems =
                  value.isGroup === 0
                    ? items.filter((user) => user?.nhanVienId !== auth?.user?.nhanVienId)
                    : items;
                // Map over the filtered items to add the hoiThoaiIdCha property
                const mappedItems = filteredItems.map((item) => ({
                  ...item,
                  hoiThoaiIdCha: value.hoiThoaiId,
                }));

                // Construct the newData array with the value and mapped items
                newData = [...newData, value, ...mappedItems];
                return;
              }
            });
        }
      } else {
        newData = [...newData, value];
      }
    } else {
      if (actionProps.type === 'SURVEY' && tabActive === 'CONVERSATION') {
        newData = newData?.filter(
          (conversation) =>
            conversation.hoiThoaiId !== value?.hoiThoaiId &&
            conversation?.hoiThoaiIdCha !== value?.hoiThoaiId
        );
      } else {
        newData = newData?.filter((conversation) => conversation.hoiThoaiId !== value?.hoiThoaiId);
      }
    }

    setDataChecked(newData);
    return actions.updateIsScroll(true);
  };

  const onUnChecked = (value) => {
    setDateError({});
    let newData = dataChecked;
    if (value?.hoiThoaiId) {
      newData = newData?.filter((conversation) => conversation.hoiThoaiId !== value?.hoiThoaiId);
    } else {
      if (actionProps.type === 'SURVEY') {
        const totalItems = size(
          newData?.filter((nhanVien) => nhanVien.hoiThoaiIdCha === value?.hoiThoaiIdCha)
        );
        newData = newData?.filter((nhanVien) => {
          return !(
            (totalItems === 1 && value.hoiThoaiIdCha === nhanVien.hoiThoaiId) ||
            nhanVien.nhanVienId === value?.nhanVienId
          );
        });
      } else {
        newData = newData?.filter((nhanVien) => nhanVien.nhanVienId !== value?.nhanVienId);
      }
    }

    return setDataChecked(newData);
  };

  const onChangePermission = (elm, value) => {
    setDataChecked((prev) =>
      prev?.map((e) =>
        (elm?.hoiThoaiId && e?.hoiThoaiId === elm?.hoiThoaiId) ||
        (elm?.nhanVienId && e?.nhanVienId === elm?.nhanVienId)
          ? { ...e, surveyPermission: value?.value }
          : e
      )
    );
  };

  const onFileRemove = (file) => {
    setFiles((prev) => prev?.filter((e) => e?.fileId !== file?.fileId));
  };

  const messageForward = (messages, content) => {
    return `[messageForwardJSON]${JSON.stringify(
      decamelizeKeys(
        messages?.map((e) => ({
          ngayTao: e?.ngayTao,
          hoiThoaiId: e?.hoiThoaiId,
          tinNhanId: e?.tinNhanId,
          nguoiGuiId: e?.nguoiGuiId,
          nguoiGui: {
            tenNhanVien: e?.nguoiGui?.tenNhanVien,
            dsChucDanh: e?.nguoiGui?.dsChucDanh?.map((cd) => ({
              phongBanChinh: cd?.phongBanChinh,
              donVi: {
                tenDonVi: cd?.donVi?.tenDonVi,
                tenVietTat: cd?.donVi?.tenVietTat,
              },
              phongBan: {
                maPhongBan: cd?.phongBan?.maPhongBan,
                tenPhongBan: cd?.phongBan?.tenPhongBan,
              },
            })),
          },
          fileDinhKem: e?.fileDinhKem,
          phieuKhaoSat: e?.phieuKhaoSat,
          noiDung: e?.noiDung,
          danhDau: e?.danhDau,
          isEvict: e?.isEvict,
        }))
      )
    )}[messageForwardJSON]${removeLastBlankLine(securityMessage(content))}`;
  };

  const validate = () => {
    const dataCommonCheck = [
      ...unionBy(dataChecked?.filter((e) => e?.nhanVienId) || [], 'nhanVienId'),
      ...unionBy(dataChecked?.filter((e) => e?.hoiThoaiId) || [], 'hoiThoaiId'),
    ];
    let newError = {};
    let check = [];
    if (!dataCommonCheck || (dataCommonCheck && dataCommonCheck?.length === 0)) {
      newError['error-check'] = 'Vui lòng chọn dữ liệu';
      check = [...check, 1];
    }

    // Dữ liệu khi chọn trên 1000 người sẽ + thêm 1 là user hiện tại để đúng với UI default 0/1200
    if (dataCommonCheck?.length + 1 > limitMember) {
      newError['error-check'] = 'Không chọn quá giới hạn';
      check = [...check, 1];
    }

    setDateError(newError);
    if (check?.length !== 0) {
      return false;
    }

    return true;
  };

  const surveyAction = () => {
    const filteredDataChecked = filterLodash(dataChecked, (item) => item.nhanVienId);
    const nhanViens = unionBy(filteredDataChecked, (item) => item.nhanVienId);
    const send = (hoiThoaiId) => {
      const messageModel = {
        noi_dung: securityMessage(removeLastBlankLine(dataForm?.description?.trim())),
        hoi_thoai_id: hoiThoaiId,
        phieu_khao_sat: [
          {
            phieu_khao_sat_id: actionProps?.data?.phieuKhaoSat?.[0]?.phieuKhaoSatId,
            tieu_de: actionProps?.data?.phieuKhaoSat?.[0]?.tieuDe,
          },
        ],
        file_dinh_kem: [],
        tuy_chon: {
          sms: 0,
          email: 0,
        },
      };
      signalRHub
        .invoke('SendMessage', [messageModel])
        .done(() => {
          const { tuNgay, denNgay, tieuDe, page } = searchParamKhaoSat;
          setTimeout(() => {
            actions.commonAddToasterMessage({
              message: 'Gửi phiếu khảo sát thành công.',
              intent: Intent.SUCCESS,
            });
            actions.getListSurveys(tuNgay, denNgay, tieuDe, page);
          }, 500);
        })
        .fail(() => {
          actions.commonAddToasterMessage({
            message: 'Có lỗi xảy ra, vui lòng thử lại.',
            intent: Intent.WARNING,
          });
        });
    };
    const data = {
      ten_hoi_thoai: null,
      anh_dai_dien: null,
      thay_doi_thong_tin: true,
      ghim_tin_nhan: true,
      phe_duyet_thanh_vien: false,
      phan_hoi_tin_nhan: true,
      check_exist: true,
    };
    actions.chatCreateConversation(nhanViens, data).then((res) => {
      if (res?.payload?.data?.result) {
        send(res?.payload?.data?.result?.hoiThoaiId);
      }
    });
    setIsLoading(true);
    actions
      .surveySend(actionProps?.data?.phieuKhaoSat?.[0]?.phieuKhaoSatId, {
        phieu_khao_sat_id: actionProps?.data?.phieuKhaoSat?.[0]?.phieuKhaoSatId,
        ds_nguoi_nhan: nhanViens.map((nhanVien) => ({
          nhan_vien_id: nhanVien.nhanVienId,
          quyen:
            nhanVien?.surveyPermission === 3
              ? [1, 2]
              : nhanVien?.surveyPermission
              ? [nhanVien?.surveyPermission]
              : [1],
        })),
      })
      .then(() => {
        setIsLoading(false);
        return onClose();
      });
  };

  const forwardAction = () => {
    const users = unionBy(dataChecked?.filter((e) => e?.nhanVienId) || [], 'nhanVienId') || [];
    const conversations = unionBy(dataChecked?.filter((e) => e?.hoiThoaiId) || [], 'hoiThoaiId');
    setIsLoading(true);
    const messageUser = () => {
      if (users?.length !== 0) {
        if (
          allUsersConversation?.length !== 0 &&
          arrayToCompare(
            users,
            allUsersConversation?.filter((e) => e?.nhanVien?.nhanVienId !== auth?.user?.nhanVienId),
            'nhanVienId'
          )
        ) {
          const message = {
            hoi_thoai_id: hoiThoaiId,
            file_dinh_kem: fileList.map((file) => ({
              file_id: file?.info?.id,
              ten_file: file?.info?.name,
              url: file?.info?.url,
              kieu_file: file?.info?.type,
              kich_thuoc: file?.info?.size,
            })),
            phieu_khao_sat: [],
            tuy_chon: {
              sms: false,
              email: false,
            },
            noi_dung: messageForward(
              forwardMessage,
              removeLastBlankLine(dataForm?.description?.trim())
            ),
          };
          signalRHub
            .invoke('SendMessage', [message])
            .done(() => {})
            .fail(() => {
              actions.commonAddToasterMessage({
                message: 'Có lỗi xảy ra, vui lòng thử lại.',
                intent: Intent.WARNING,
              });
            });
        } else {
          const requiredData = {
            nhan_vien_hoi_thoai: users?.map((elm) => ({
              nguoi_nhan_id: elm?.nhanVienId,
            })),
            nguoi_tao_id: auth?.user?.nhanVienId,
            ten_hoi_thoai: null,
            anh_dai_dien: null,
            thay_doi_thong_tin: true,
            ghim_tin_nhan: true,
            phe_duyet_thanh_vien: false,
            phan_hoi_tin_nhan: true,
            check_exist: true,
          };
          actions.createGroup(requiredData).then((res) => {
            if (res?.payload?.data?.result) {
              const message = {
                hoi_thoai_id: res?.payload?.data?.result?.hoiThoaiId,
                file_dinh_kem: fileList.map((file) => ({
                  file_id: file?.info?.id,
                  ten_file: file?.info?.name,
                  url: file?.info?.url,
                  kieu_file: file?.info?.type,
                  kich_thuoc: file?.info?.size,
                })),
                phieu_khao_sat: [],
                tuy_chon: {
                  sms: false,
                  email: false,
                },
                noi_dung: messageForward(
                  forwardMessage,
                  removeLastBlankLine(dataForm?.description?.trim())
                ),
              };
              signalRHub
                .invoke('SendMessage', [message])
                .done(() => {})
                .fail(() => {
                  actions.commonAddToasterMessage({
                    message: 'Có lỗi xảy ra, vui lòng thử lại.',
                    intent: Intent.WARNING,
                  });
                });
            }
          });
        }
      }

      return true;
    };
    const messageConversation = () => {
      conversations?.forEach((conversation) => {
        const message = {
          hoi_thoai_id: conversation?.hoiThoaiId,
          file_dinh_kem: fileList.map((file) => ({
            file_id: file?.info?.id,
            ten_file: file?.info?.name,
            url: file?.info?.url,
            kieu_file: file?.info?.type,
            kich_thuoc: file?.info?.size,
          })),
          phieu_khao_sat: [],
          tuy_chon: {
            sms: false,
            email: false,
          },
          noi_dung: messageForward(
            forwardMessage,
            removeLastBlankLine(dataForm?.description?.trim())
          ),
        };
        signalRHub
          .invoke('SendMessage', [message])
          .done(() => {})
          .fail(() => {
            actions.commonAddToasterMessage({
              message: 'Có lỗi xảy ra, vui lòng thử lại.',
              intent: Intent.WARNING,
            });
          });
      });
      return true;
    };
    Promise.all([messageUser(), messageConversation()]).then(() => {
      setIsLoading(false);
      onReset();
      return onClose();
    });
  };

  const onSubmit = () => {
    const isValidate = validate();
    if (!isValidate) {
      return;
    }

    if (actionProps.type === actionType.KHAO_SAT) {
      surveyAction();
    } else if (actionProps.type === actionType.CHUYEN_TIEP) {
      forwardAction();
    }
  };

  useEffect(() => {
    const contentEditor = removeLastBlankLine(dataForm?.description);
    const contentSurvey = actionProps?.data?.phieuKhaoSat;
    if (
      dataChecked?.length !== 0 &&
      (contentEditor ||
        (fileList && fileList?.length !== 0) ||
        (forwardMessage && forwardMessage?.length !== 0) ||
        (contentSurvey && contentSurvey?.length !== 0))
    ) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
  }, [
    dataForm?.description,
    forwardMessage?.length,
    dataChecked?.length,
    fileList?.length,
    actionProps?.data?.phieuKhaoSat?.length,
  ]);

  useEffect(() => {
    if (actionProps?.data?.noiDung) {
      setDataForm((prev) => ({
        ...prev,
        description: stringMentionSetEditor(urlify(getContent(actionProps?.data?.noiDung))),
      }));
    }
  }, [actionProps?.data?.noiDung]);

  useEffect(() => {
    if (actionProps?.data?.quoteMessages) {
      let quoteMessages = [];
      try {
        quoteMessages = getForward(actionProps?.data?.quoteMessages?.noiDung)
          ? camelizeKeys(JSON.parse(getForward(actionProps?.data?.quoteMessages?.noiDung)))
          : [];
      } catch {
        quoteMessages = [];
      }

      setForwardMessage([
        ...quoteMessages,
        camelizeKeys({
          ...actionProps?.data?.quoteMessages,
          noi_dung: securityMessage(getContent(actionProps?.data?.quoteMessages?.noiDung)),
        }),
      ]);
    }
  }, [actionProps?.data?.quoteMessages]);

  useEffect(() => {
    if (
      dataTemp?.length !== 0 &&
      conversationLatestContact?.items &&
      conversationLatestContact?.items?.length !== 0
    ) {
      const newData = conversationLatestContact?.items?.filter((e) =>
        dataTemp?.some((s) => s === e?.hoiThoaiId)
      );
      setDataChecked(newData);
    }
  }, [dataTemp, conversationLatestContact?.items]);

  useEffect(() => {
    if (
      actionProps?.type === 'SURVEY' &&
      actionProps?.data?.conversations &&
      actionProps?.data?.conversations?.length !== 0 &&
      !compareArrays(actionProps?.data?.conversations, dataTemp)
    ) {
      setDataTemp(actionProps?.data?.conversations);
    }
  }, [actionProps?.data?.conversations, dataTemp]);

  useEffect(() => {
    if (dataFiles?.length !== 0) {
      setFiles(dataFiles);
    }
  }, [dataFiles]);

  useEffect(() => {
    getConversationLatest(1);
  }, []);

  useEffect(() => {
    return () => {
      actions.removeConversationLatestContactList();
    };
  }, []);

  useEffect(() => {
    if (auth?.user?.nhanVienId) {
      setDataDisable([auth?.user?.nhanVienId]);
    }
  }, [auth?.user?.nhanVienId]);

  return {
    tab,
    tabActive,
    filter,
    loading,
    isSearch,
    dataDisable,
    dataSearch,
    dataSearchConversation,
    dataChecked,
    isLoadingTree,
    isLoadingPagination,
    isLoading,
    isSubmit,
    dataForm,
    dateError,
    files,
    forwardMessage,
    isForwardDisplay,
    setDataChecked,
    setIsForwardDisplay,
    onRemoveForward,
    setFilter,
    setIsSearch,
    onScroll,
    onChangeSearch,
    onChangeTab,
    onSelectUnit,
    onUnChecked,
    onChangePermission,
    onToggle,
    onChecked,
    onCheckedAll,
    onClear,
    onConversationScroll,
    onConversationChecked,
    setDataForm,
    onFileRemove,
    onSubmit,
  };
};

export default useShare;
