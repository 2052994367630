import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import _ from 'lodash';
import shallowCompare from 'react-addons-shallow-compare';
import { getLinkImage } from '../../helpers/image';

export default class Avatar extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
      return false;
    }

    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ backgroundImage: `url(${getLinkImage(this.props.user.anhDaiDien)})` }}
      >
        <Image user={this.props.user} actions={this.props.actions} />
      </div>
    );
  }
}
