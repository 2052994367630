import CPCIcon from '@/components/atoms/icon';
import CPCStatus from '@/components/atoms/status';
import Heading from '@/components/layout/heading';
import LayoutMain from '@/components/organisms/layout-main';
import { Collapse, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import { FileList } from '../../../components/common';
import { FeedbackHandle } from '../../../components/hopthugopy';
import { FE_URL, IMAGE_URL } from '../../../constants/Api';
import { variables } from '../../../constants/variables';
import { checkQuyenChucVu } from '../../../helpers/helper';
import './../assets/common.scss';
import FullLoading from '@/components/molecules/full-loading';

class FeedbackDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id || undefined,
      isSubmit: false,
      isLoading: true,
      isEmpty: false,
      isError: false,
      isOpen: true,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.getDetail();
    }

    this.props.actions.feedbackGetTrangThai();
  }

  getDetail = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.actions.feedbackGetDetail(this.state.id).then((res) => {
          if (res && res.error && res.error.response && res.error.response.status !== 200) {
            this.props.actions.commonAddToasterMessage({
              message: variables.MESSAGE_STATUS.MESSAGE_NOT_FOUND,
              intent: Intent.DANGER,
            });
            return this.handleGoBack();
          } else {
            let isEmpty = this.state.isEmpty;
            if (res.payload.data.result && res.payload.data.result.dsDapAn.length === 0) {
              isEmpty = true;
            }

            this.setState({ isLoading: false, isEmpty });
          }
        });
      }
    );
  };

  getStatus = (xuLyGopY) => {
    if (!xuLyGopY) {
      return;
    }

    switch (xuLyGopY.trangThai.maTrangThai) {
      case variables.TRANGTHAIXULY.CHO_TIEP_NHAN:
        return <span className="status warning">{get(xuLyGopY, 'trangThai.tenTrangThai')}</span>;
      case variables.TRANGTHAIXULY.DA_TIEP_NHAN:
        return <span className="status info">{get(xuLyGopY, 'trangThai.tenTrangThai')}</span>;
      case variables.TRANGTHAIXULY.SE_HIEU_CHINH:
        return <span className="status success">{get(xuLyGopY, 'trangThai.tenTrangThai')}</span>;
      case variables.TRANGTHAIXULY.KHONG_HIEU_CHINH:
        return <span className="status danger">{get(xuLyGopY, 'trangThai.tenTrangThai')}</span>;
      default:
        return;
    }
  };

  getNguoiGui = (tenNguoiGui, anhDaiDien) => {
    return tenNguoiGui ? (
      <div className="avatar-info small">
        <span
          className="image"
          style={{
            backgroundImage: anhDaiDien
              ? `url(${IMAGE_URL}${anhDaiDien})`
              : `url(${process.env.PUBLIC_URL}/images/default_avatar.png)`,
          }}
        ></span>
        <p className="name">{tenNguoiGui || ''}</p>
      </div>
    ) : (
      <></>
    );
  };

  handleGoBack = () => {
    return this.props.history.push('/hop-thu-gop-y');
  };

  handleOpenEoffice = () => {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  };

  trangThai = (status) => {
    if (isEmpty(status)) {
      return;
    }

    let colorClass = '';
    switch (status?.maTrangThai) {
      case variables.TRANGTHAIXULY.CHO_TIEP_NHAN:
        colorClass = 'orange';
        break;
      case variables.TRANGTHAIXULY.DA_TIEP_NHAN:
        colorClass = 'blue';
        break;
      case variables.TRANGTHAIXULY.SE_HIEU_CHINH:
        colorClass = 'orange';
        break;
      case variables.TRANGTHAIXULY.KHONG_HIEU_CHINH:
        colorClass = 'red';
        break;

      default:
        break;
    }

    return <CPCStatus title={status?.tenTrangThai} colorClass={colorClass} />;
  };

  handleCollapseQuestion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    let { detail } = this.props;
    return (
      <LayoutMain {...this.props.props} history={this.props.history}>
        <Scrollbars className="section" style={{ height: '100vh' }}>
          <Heading title="Hộp thư góp ý" isGoback={true} handleGoBack={this.handleGoBack} />
          {this.state.isLoading && <FullLoading />}
          {!this.state.isLoading && (
            <div className="cpc-container">
              <div
                className={classNames('form-detail', {
                  'hide-bottom-radius': checkQuyenChucVu(this.props.auth, ['Superadmin']),
                })}
              >
                <div className="form-detail__header border-bottom">
                  <CPCIcon icon="calendar" color="blue" size={16} />
                  <div>
                    {moment(detail.ngayGui).isValid()
                      ? moment(detail.ngayGui).format('DD/MM/YYYY HH:mm')
                      : ''}
                  </div>
                  <div>{this.trangThai(detail?.xuLyGopY?.trangThai)}</div>
                </div>
                <div className="row border-bottom-large">
                  {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
                    <div className="col-lg-12 col-xs-12">
                      <div className="form-detail-group pb15 mb15 border-bottom">
                        <label className="form-detail-group__label">Người gửi</label>
                        {this.getNguoiGui(
                          detail.tenNguoiGui,
                          detail.anhDaiDienNguoiGui,
                          detail.donVi,
                          detail.phongBan
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-lg-12 col-xs-12">
                    <div className="form-detail-group">
                      <label className="form-detail-group__label">Nội dung</label>
                      <div className="form-detail-group__value">{get(detail, 'noiDung')}</div>
                    </div>
                  </div>
                  <div className="form-detail-item ml-8">
                    <div className="form-detail-group">
                      <label className="form-detail-group__label">Chất lượng</label>
                      <div className="form-detail-group__value">
                        {get(detail.chatLuong, 'tenChatLuong')}
                      </div>
                    </div>
                  </div>
                  <div className="form-detail-item">
                    <div className="form-detail-group">
                      <label className="form-detail-group__label">Phiên bản</label>
                      <div className="form-detail-group__value">{get(detail, 'phienBan')}</div>
                    </div>
                  </div>
                  <div className="form-detail-item">
                    <div className="form-detail-group">
                      <label className="form-detail-group__label">Người xác nhận</label>
                      {this.getNguoiGui(detail?.xuLyGopY?.nguoiXuLy, detail?.xuLyGopY?.anhDaiDien)}
                    </div>
                  </div>
                  <div className="form-detail-item">
                    <div className="form-detail-group">
                      <label className="form-detail-group__label">File đính kèm</label>
                      <FileList auth={this.props.auth} data={detail.dsFile || []} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xs-12">
                    <div className="form-detail-group question">
                      <label
                        className="form-detail-group__label collapse-label"
                        onClick={this.handleCollapseQuestion}
                      >
                        DANH SÁCH CÂU HỎI VÀ ĐÁP ÁN
                        <CPCIcon
                          icon="chevron-down"
                          className={classNames('drop-icon', { open: this.state.isOpen })}
                          size={10}
                        />
                      </label>
                      <Collapse isOpen={this.state.isOpen}>
                        <div className="question-list">
                          {get(detail, 'dsDapAn')?.map((item, index) => (
                            <div className="question-item">
                              <label className="question-item__label">
                                {index + 1}. {item?.cauHoi}
                              </label>
                              <div className="question-item__value">
                                <CPCIcon icon="quote" color="blue" size={18} />
                                {item?.noiDungDapAn}
                              </div>
                            </div>
                          ))}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
              {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
                <FeedbackHandle
                  match={this.props.match}
                  data={detail.xuLyGopY || {}}
                  reloadDetail={this.getDetail}
                  trangThai={this.props.trangThai || []}
                  history={this.props.history}
                />
              )}
              {!checkQuyenChucVu(this.props.auth, ['Superadmin']) &&
                get(detail, 'xuLyGopY.noiDungXuLy') && (
                  <div className="cpc-panel">
                    <div className="panel-title">
                      <span className="icon-writing1601" />
                      <h3 className="title">Nội dung xử lý</h3>
                    </div>
                    <div className="panel-body">
                      <div className="p20">{detail.xuLyGopY.noiDungXuLy}</div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </Scrollbars>
      </LayoutMain>
    );
  }
}

FeedbackDetail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  detail: state.feedback.detail,
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
  trangThai: state.feedback.trangThai,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetail);
