import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Search from './Search';
import PanelSearch from './PanelSearch';
import Favourite from './Favourite';
import { Scrollbars } from 'react-custom-scrollbars';
import { Intent, Position, Toaster } from '@blueprintjs/core';

export default class PanelFavourite extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    contact: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loaded: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  state = {
    search: '',
    submitting: false,
    notFound: false,
  };

  componentWillMount() {
    this.handleClearSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search && this.state.search.trim() === '') {
      this.search();
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleSearch = () => {
    this.search();
  };

  handleClearSearch = () => {
    this.setState({ search: '' }, () => {
      this.search();
    });
  };

  search = async (page = 1) => {
    if (this.state.submitting) {
      return;
    }

    await this.setState({ submitting: true, notFound: false });
    if (this.state.search.trim()) {
      return this.props.actions
        .contactSearchFavourite(this.state.search.trim(), page)
        .then((res) => {
          this.setState({ submitting: false });
          if (res.error) {
            this.toaster.show({
              message: 'Có lỗi xảy ra, vui lòng thử lại',
              intent: Intent.DANGER,
            });
            return;
          }

          if (
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.items &&
            !res.payload.data.result.result.items.length
          ) {
            this.setState({ notFound: true });
          }

          this.setState({ submitting: false });
        });
    } else {
      this.setState({ submitting: false });
      return this.props.actions.contactClearSearchFavourite();
    }
  };

  showSearch = () => {
    return this.state.search.trim() !== '';
  };

  render() {
    return (
      <div>
        <PanelSearch
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          actions={this.props.actions}
          auth={this.props.auth}
          units={this.props.units}
          contact={this.props.contact}
          receivers={this.props.receivers}
        />
        <div
          className="cpc-contact-list-container"
          style={{ top: this.props.isSurvey ? '140px' : null }}
        >
          <Scrollbars
            style={{ width: '100%', height: '100%' }}
            renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
          >
            {!this.props.loaded || this.state.submitting ? (
              <div className="spinner">
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
            ) : null}
            <div
              className="cpc-search-result"
              style={{ display: this.props.loaded && this.showSearch() ? 'block' : 'none' }}
            >
              {this.showSearch() &&
              (this.state.notFound ||
                (!this.state.submitting &&
                  this.props.contact.searchFavourite.items.length === 0)) ? (
                <div className="cpc-not-found">Không tìm thấy kết quả</div>
              ) : null}
              {this.showSearch() && !this.state.notFound ? (
                <Search
                  result={this.props.contact.searchFavourite}
                  auth={this.props.auth}
                  receivers={this.props.receivers}
                  actions={this.props.actions}
                  onLoadMore={this.search}
                />
              ) : null}
            </div>
            <div style={{ display: this.props.loaded && !this.showSearch() ? 'block' : 'none' }}>
              <Favourite
                auth={this.props.auth}
                actions={this.props.actions}
                favourites={this.props.contact.favourites}
                filter={this.state.searchFavourite}
                filterOnline={this.props.contact.filterOnline}
                receivers={this.props.receivers}
              />
            </div>
          </Scrollbars>
        </div>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}
