import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { useEffect, useRef } from 'react';

import './index.scss';

const CPCScrollbar = ({
  className = '',
  children,
  autoHeightMin = 0,
  autoHeightMax = 50,
  autoHide = false,
  universal = false,
  refFunc = () => {},
  onScroll = () => {},
  onScrollFrame = () => {},
}) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    refFunc(scrollRef);
  }, [scrollRef]);

  return (
    <Scrollbars
      ref={scrollRef}
      className={classNames('cpc-scrollbar', className)}
      autoHide={autoHide}
      autoHeight
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      renderThumbVertical={(props) => <div {...props} className="cpc-scrollbar__vthumb" />}
      renderThumbHorizontal={(props) => <div {...props} className="cpc-scrollbar__hthumb" />}
      universal={universal}
      onScroll={onScroll}
      onScrollFrame={onScrollFrame}
    >
      {children}
    </Scrollbars>
  );
};

export default CPCScrollbar;
