import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import emojione from 'emojione';
import { Text } from '@blueprintjs/core';
import { uniqBy, size, get } from 'lodash';

import { TINNHAN_SMS_365 } from '../../constants/Permissions';
import Avatar from './AvatarOnline';
import './assets/contact-item.scss';
import CPCCheckbox from '../atoms/checkbox';

export default class ContactItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    showFullInfo: PropTypes.bool,
  };

  static defaultProps = {
    showFullInfo: false,
    auth: null,
  };

  constructor(props) {
    super(props);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleCheckbox = () => {
    if (!this.props.user.checked) {
      let newUsersConvert = [];
      if (this.props.receivers) {
        newUsersConvert = [
          ...this.props.receivers,
          {
            nhanVienId: this.props.user.nhanVienId,
            tenNhanVien: this.props.user.tenNhanVien,
          },
        ];
      }
      let newUsers = uniqBy(newUsersConvert, 'nhanVienId');
      if (
        size(newUsers) > 1200 &&
        get(this.props, 'auth') &&
        this.props.auth.permissions.indexOf(TINNHAN_SMS_365) === -1
      ) {
        return this.props.actions.setCloseLimitConfirm(true);
      }

      this.props.actions.chatAddReceiver(this.props.user.nhanVienId);
    } else {
      this.props.actions.chatRemoveReceiver(this.props.user.nhanVienId);
    }
  };

  unitName = () => {
    if (!this.props.user.dsChucDanh || !this.props.user.dsChucDanh.length) {
      return '';
    }

    const mainUnit = this.props.user.dsChucDanh.find((unit) => unit.phongBanChinh);
    if (!mainUnit || !mainUnit.donVi) {
      return '';
    }

    if (
      this.props.auth &&
      this.props.auth.mainUnitId &&
      mainUnit.donVi.donViId === this.props.auth.mainUnitId
    ) {
      return '';
    }

    return mainUnit.donVi.tenDonVi;
  };

  departmentName = () => {
    if (!this.props.user.dsChucDanh || !this.props.user.dsChucDanh.length) {
      return '';
    }

    const mainUnit = this.props.user.dsChucDanh.find((unit) => unit.phongBanChinh);
    if (!mainUnit || !mainUnit.phongBan) {
      return '';
    }

    return mainUnit.phongBan.tenPhongBan;
  };

  render() {
    return (
      <div className="cpc-contact-item">
        <label className="pt-control pt-checkbox">
          <input
            type="checkbox"
            checked={this.props.user.checked === true}
            onChange={this.handleCheckbox}
          />
          {/* <span className="pt-control-indicator" /> */}
        </label>
        <div className="cpc-contact-name">
          <Text className="cpc-name-container">
            <Avatar
              auth={this.props.auth}
              actions={this.props.actions}
              className="cpc-avatar-container"
              user={this.props.user}
            />
            <span className="cpc-name-content" onClick={this.handleCheckbox}>
              {this.props.user.tenNhanVien}
            </span>
          </Text>

          {this.props.user.soDienThoai ? (
            <div className="cpc-contact-info" onClick={this.handleCheckbox}>
              <div className="pull-left">
                <span className="icon-call" />
              </div>
              <div className="pull-left cpc-text">
                <Text title={this.props.user.soDienThoai}>{this.props.user.soDienThoai}</Text>
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
          {this.props.user.soDienThoaiKhac ? (
            <div className="cpc-contact-info" onClick={this.handleCheckbox}>
              <div className="pull-left">
                <span className="icon-call" />
              </div>
              <div className="pull-left cpc-text">
                <Text title={this.props.user.soDienThoaiKhac}>
                  {this.props.user.soDienThoaiKhac}
                </Text>
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
          {this.props.user.tamTrang ? (
            <div className="cpc-contact-info" onClick={this.handleCheckbox}>
              <div className="pull-left">
                <span className="icon-right-quote" />
              </div>
              <div className="pull-left cpc-text">
                <div
                  dangerouslySetInnerHTML={{ __html: emojione.toImage(this.props.user.tamTrang) }}
                />

                {/* <Text title={this.props.user.tamTrang}>{emojione.toImage(this.props.user.tamTrang)}</Text> */}
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
          {false && this.props.user.email ? (
            <div className="cpc-contact-info" onClick={this.handleCheckbox}>
              <div className="pull-left">
                <span className="icon-mail" />
              </div>
              <div className="pull-left cpc-text">
                <Text title={this.props.user.email}>{this.props.user.email}</Text>
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
          {this.props.showFullInfo && this.unitName() ? (
            <div className="cpc-contact-info" onClick={this.handleCheckbox}>
              <div className="pull-left">
                <span className="icon-building" />
              </div>
              <div className="pull-left cpc-text">
                <Text title={this.unitName()}>{this.unitName()}</Text>
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
          {this.props.showFullInfo && this.departmentName() ? (
            <div className="cpc-contact-info" onClick={this.handleCheckbox}>
              <div className="pull-left">
                <span className="icon-table" />
              </div>
              <div className="pull-left cpc-text">
                <Text title={this.departmentName()}>{this.departmentName()}</Text>
              </div>
              <div className="clearfix" />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
