import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isArray, size, get } from 'lodash';
import { Text } from '@blueprintjs/core';
import './assets/unit-item.scss';
import Department from './Department';
import { TINNHAN_SMS_365 } from '../../constants/Permissions';
import CPCCheckbox from '../atoms/checkbox';

export default class UnitItem extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    receivers: PropTypes.array,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onClick: () => {},
    exclude: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      checking: false,
      opening: false,
      isOpen: {},
      isOpenConfirmLimitUser: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  getChecked = () => {
    if (!this.props.unit.dsPhongBan || !this.props.unit.dsPhongBan.length) {
      return false;
    }

    for (const department of this.props.unit.dsPhongBan) {
      if (!department.dsNhanVien || !department.dsNhanVien.length) {
        return false;
      }

      if (department.dsNhanVien.filter((user) => user.checked !== true).length) {
        return false;
      }
    }

    return true;
  };

  handleCheckbox = async () => {
    if (!this.getChecked()) {
      this.setState({ checking: true });
      await this.props.actions.contactFetchDepartmentsByUnit(this.props.unit.donViId);
      for (const unit of this.props.unit.dsDonViCon) {
        await this.props.actions.contactFetchDepartmentsByUnit(unit.donViId);
      }
      this.setState({ checking: false });
    }

    let users = [];
    for (const department of this.props.unit.dsPhongBan) {
      const dsNhanVien = department.dsNhanVien || [];
      users = [...users, ...dsNhanVien];
    }

    for (const unit of this.props.unit.dsDonViCon) {
      for (const department of unit.dsPhongBan) {
        const dsNhanVien = department.dsNhanVien || [];
        users = [...users, ...dsNhanVien];
      }
    }

    const newUsers = [...this.props.receivers, ...users];
    if (!this.getChecked()) {
      // await this.props.actions.chatClearReceiver(this.props.receivers);
      if (
        size(newUsers) > 1200 &&
        get(this.props, 'auth') &&
        this.props.auth.permissions.indexOf(TINNHAN_SMS_365) === -1
      ) {
        return this.props.actions.setCloseLimitConfirm(true);
      }

      this.props.actions.chatAddMultipleReceivers(users);
    } else {
      this.props.actions.chatRemoveMultipleReceivers(users);
    }
  };

  handleClick = (unit) => {
    if (this.props.exclude === unit.donViId) {
      return;
    }

    this.openTree(unit);
  };

  openTree = (unit) => {
    if (
      this.state.isOpen[unit.donViId] ||
      (isArray(this.props.unit.dsPhongBan) && this.props.unit.dsPhongBan.length > 0)
    ) {
      return this.setState({
        opening: false,
        isOpen: {
          ...this.state.isOpen,
          [unit.donViId]: !this.state.isOpen[unit.donViId],
        },
      });
    }

    this.setState({ opening: true });
    this.props.onClick(unit).then((res) => {
      this.setState({
        opening: false,
        isOpen: {
          ...this.state.isOpen,
          [unit.donViId]: !this.state.isOpen[unit.donViId],
        },
      });
    });
  };

  render() {
    if (this.props.exclude === this.props.unit.donViId) {
      return null;
    }

    return (
      <div
        className={classnames('cpc-unit-item', {
          active: this.state.isOpen[this.props.unit.donViId],
        })}
      >
        <div
          className={classnames('cpc-item', {
            disabled: this.props.exclude === this.props.unit.donViId,
          })}
        >
          <div className="toggle-button">
            {this.state.opening ? (
              <img
                style={{ marginLeft: 6 }}
                alt=""
                src={process.env.PUBLIC_URL + '/images/spinner.gif'}
              />
            ) : (
              <button className="pull-left" onClick={this.openTree.bind(this, this.props.unit)}>
                {this.state.isOpen[this.props.unit.donViId] ? (
                  <span className="cpc icon-round-delete-button" />
                ) : (
                  <span className="cpc icon-rounded-add-button" />
                )}
              </button>
            )}
          </div>
          <div className="unit-block">
            {/* <button className="pull-right" onClick={this.handleClick.bind(this, this.props.unit)}>
              <span className="pt-icon pt-icon-chevron-right"/>
            </button> */}
            {this.state.checking && (
              <div className="loading">
                <img alt="" src={process.env.PUBLIC_URL + '/images/spinner.gif'} />
              </div>
            )}
            {!this.state.checking && (
              <label className="pt-control pt-checkbox">
                <input
                  type="checkbox"
                  checked={this.getChecked()}
                  onChange={this.handleCheckbox}
                  disabled={this.props.exclude === this.props.unit.donViId}
                />
                {/* <span className="pt-control-indicator" /> */}
              </label>
            )}

            <div onClick={this.handleClick.bind(this, this.props.unit)}>
              <Text className="cpc-unit-info" title={this.props.unit.tenDonVi}>
                {this.props.unit.tenDonVi}
              </Text>
            </div>
          </div>
        </div>
        {this.state.isOpen[this.props.unit.donViId] && isArray(this.props.unit.dsPhongBan) && (
          <Department
            auth={this.props.auth}
            actions={this.props.actions}
            unit={this.props.unit}
            receivers={this.props.receivers}
            filterOnline={this.props.filterOnline}
          />
        )}
        {this.state.isOpen[this.props.unit.donViId] &&
          isArray(this.props.unit.dsDonViCon) &&
          this.props.unit.dsDonViCon.map((unit, i) => (
            <UnitItem
              key={unit.donViId}
              auth={this.props.auth}
              unit={unit}
              actions={this.props.actions}
              onClick={this.props.onClick}
              receivers={this.props.receivers}
              exclude={this.props.exclude}
            />
          ))}
      </div>
    );
  }
}
