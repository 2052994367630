import {
  GET_LIST_PIN_MESSAGES,
  GET_ATTACHED_FILES_CONVERATION,
  GET_CONVERATION_USERS_SEARCH,
  GET_CONVERATION_USERS_NOT_APPROVED_SEARCH,
  GET_MESSAGES_IMPORTANT,
  CONVERSATION_DETAIL,
  DELETE_CONVERSATION_GENERAL,
  GET_ATTACHED_DOCUMENTS,
  GET_ATTACHED_IMAGES,
  SET_FILES_NEW_MESSAGE,
  SET_RELOAD_DATA,
  DELETE_USER_CONVERATION,
  SET_CONVERSATION_LATEST_CONTACT_LIST,
  REMOVE_CONVERSATION_LATEST_CONTACT_LIST,
  REMOVE_CONVERSATION_LIST,
  HANDLE_NOTIFICATION_OF_CONVERSATION,
  UPDATE_ROLE_CONVERSATION,
  API_REQUEST_CONVERSATION_LIST,
  RESET_USER_LIST,
  ACTIVE_CONVERSATION,
  SET_USER_INTERFACE_KEY,
  SAVE_CURRENT_CONVERSATION_CHAT,
  SET_SCROLL,
  GET_CONVERSATION_TOTAL_NEW_MESSAGES,
  SET_FROM_OTHER_APP_DATA,
  API_GET_GROUP_CONVERSATION_LIST,
  SET_LOADING_APPROVED,
  UPDATE_CONVERSATION,
  GET_LINKS_CONVERATION,
  SET_UNREAD_LIST,
  REMOVE_UNREAD_LIST,
  UPDATE_CONVERSATION_INFO,
  CLEAN_CONTEXT_CONVERSATION,
} from '@/constants/ActionTypes';
import { checkFileType } from '@/helpers/helper';

const initialState = {
  conversationDetail: {},
  listPin: {},
  listImportant: {},
  attachedImages: {},
  attachedDocuments: {},
  attachedFiles: {},
  conversationUsers: {},
  conversationUsersNotApproved: {},
  isReloadData: false,
  conversationLatestContact: null,
  conversationList: [],
  userInterfaceKey: null,
  isScroll: false,
  totalNewMessages: 0,
  fromOtherAppData: null,
  groupConversationList: {},
  loadingApproved: [],
  conversationLinks: null,
  unreadList: [],
};

export default function conversation(state = initialState, action) {
  const type = action?.type;
  const payload = action?.payload;
  let tinNhanId = 0;
  switch (type) {
    case GET_LIST_PIN_MESSAGES:
      return {
        ...state,
        listPin:
          payload?.data?.result?.page === 1
            ? payload?.data?.result
            : {
                ...(payload?.data?.result || []),
                items: [...(state?.listPin?.items || []), ...payload?.data?.result?.items] || [],
              },
      };
    case GET_ATTACHED_IMAGES:
      return {
        ...state,
        attachedImages: payload?.data?.result,
      };
    case GET_ATTACHED_DOCUMENTS:
      return {
        ...state,
        attachedDocuments: payload?.data?.result,
      };
    case GET_ATTACHED_FILES_CONVERATION:
      return {
        ...state,
        attachedFiles:
          payload?.data?.result?.page <= 1
            ? payload?.data?.result
            : {
                ...(payload?.data?.result || []),
                items:
                  [...(state?.attachedFiles?.items || []), ...payload?.data?.result?.items] || [],
              },
      };
    case GET_CONVERATION_USERS_SEARCH:
      return {
        ...state,
        conversationUsers:
          payload?.data?.result?.page <= 1
            ? payload?.data?.result
            : {
                ...(payload?.data?.result || []),
                items:
                  [...(state?.conversationUsers?.items || []), ...payload?.data?.result?.items] ||
                  [],
              },
      };
    case GET_CONVERATION_USERS_NOT_APPROVED_SEARCH:
      return {
        ...state,
        conversationUsersNotApproved:
          payload?.data?.result?.page <= 1
            ? payload?.data?.result
            : {
                ...(payload?.data?.result || []),
                items:
                  [
                    ...(state?.conversationUsersNotApproved?.items || []),
                    ...payload?.data?.result?.items,
                  ] || [],
              },
      };
    case GET_MESSAGES_IMPORTANT:
      return {
        ...state,
        listImportant:
          payload?.data?.result?.page <= 1
            ? payload?.data?.result
            : {
                ...(payload?.data?.result || []),
                items:
                  [...(state?.listImportant?.items || []), ...payload?.data?.result?.items] || [],
              },
      };
    case CONVERSATION_DETAIL:
      return {
        ...state,
        conversationDetail: payload?.data?.result || {},
      };
    case DELETE_CONVERSATION_GENERAL:
      return {
        ...state,
        conversationDetail:
          state?.conversationDetail?.hoiThoaiId === payload ? null : state?.conversationDetail,
      };
    case SET_FILES_NEW_MESSAGE:
      let newAttachedImages = [];
      let newTotalImages = 0;
      let newAttachedDocuments = [];
      let newTotalDocuments = 0;
      if (state?.conversationDetail?.hoiThoaiId === payload?.data?.hoiThoaiId) {
        const newImages =
          payload?.data?.fileDinhKem?.filter((e) => checkFileType(e?.kieuFile) === 'IMAGE') || [];
        const newDocuments =
          payload?.data?.fileDinhKem?.filter((e) => checkFileType(e?.kieuFile) === 'DOCUMENT') ||
          [];
        if (newImages && newImages?.length !== 0) {
          if (state?.attachedImages?.items) {
            newAttachedImages = [
              ...newImages?.map((file) => ({ ...file, tinNhanId: payload?.data?.tinNhanId })),
              ...state?.attachedImages?.items,
            ];
            newTotalImages = state?.attachedImages?.total + newImages?.length;
          } else {
            newAttachedImages = newImages;
            newTotalImages = newImages?.length;
          }
        }
        if (newDocuments && newDocuments?.length !== 0) {
          if (state?.attachedDocuments?.items) {
            newAttachedDocuments = [
              ...newDocuments?.map((file) => ({ ...file, tinNhanId: payload?.data?.tinNhanId })),
              ...state?.attachedDocuments?.items,
            ];
            newTotalDocuments = state?.attachedDocuments?.total + newDocuments?.length;
          } else {
            newAttachedDocuments = newDocuments;
            newTotalDocuments = newDocuments?.length;
          }
        }
      }

      return {
        ...state,
        attachedImages: {
          ...state?.attachedImages,
          ...(newAttachedImages?.length !== 0
            ? {
                items: newAttachedImages?.slice(0, 6),
                total: newTotalImages,
              }
            : {}),
        },
        attachedDocuments: {
          ...state?.attachedDocuments,
          ...(newAttachedDocuments?.length !== 0
            ? {
                items: newAttachedDocuments?.slice(0, 3),
                total: newTotalDocuments,
              }
            : {}),
        },
      };
    case SET_RELOAD_DATA:
      return {
        ...state,
        isReloadData: payload?.data || false,
      };
    case DELETE_USER_CONVERATION:
      return {
        ...state,
        conversationDetail:
          state?.conversationDetail.hoiThoaiId === payload?.hoiThoai.hoiThoaiId
            ? payload?.hoiThoai
            : state?.conversationDetail,
      };
    case SET_CONVERSATION_LATEST_CONTACT_LIST:
      return {
        ...state,
        conversationLatestContact:
          payload?.data?.result?.page === 1
            ? payload?.data?.result
            : {
                ...payload?.data?.result,
                items: [
                  ...state?.conversationLatestContact?.items,
                  ...payload?.data?.result?.items,
                ],
              },
      };
    case REMOVE_CONVERSATION_LATEST_CONTACT_LIST:
      return {
        ...state,
        conversationLatestContact: null,
      };
    case HANDLE_NOTIFICATION_OF_CONVERSATION:
      return {
        ...state,
        conversationDetail:
          state?.conversationDetail?.hoiThoaiId === payload?.hoiThoaiId
            ? {
                ...state?.conversationDetail,
                nhanVienHoiThoai: state?.conversationDetail?.nhanVienHoiThoai?.map((elm) =>
                  elm?.nhanVien?.nhanVienId === payload?.userId
                    ? { ...elm, tatThongBao: !elm?.tatThongBao }
                    : elm
                ),
              }
            : state?.conversationDetail,
      };
    case UPDATE_ROLE_CONVERSATION:
      return {
        ...state,
        conversationUsers:
          state?.conversationDetail?.hoiThoaiId === payload?.hoiThoaiId
            ? {
                ...state?.conversationUsers,
                items: state?.conversationUsers?.items?.map((user) => {
                  if (user?.nhanVienId === payload?.nhanVienId) {
                    return {
                      ...user,
                      vaiTro: payload?.vaiTro || 0,
                      nhanVien: {
                        ...user?.nhanVien,
                        vaiTro: payload?.vaiTro || 0,
                      },
                    };
                  }

                  return user;
                }),
              }
            : state?.conversationUsers,
        conversationUsersNotApproved:
          state?.conversationDetail?.hoiThoaiId === payload?.hoiThoaiId
            ? {
                ...state?.conversationUsersNotApproved,
                items: state?.conversationUsersNotApproved?.items?.map((user) => {
                  if (user?.nhanVienId === payload?.nhanVienId) {
                    return {
                      ...user,
                      vaiTro: payload?.vaiTro || 0,
                      nhanVien: {
                        ...user?.nhanVien,
                        vaiTro: payload?.vaiTro || 0,
                      },
                    };
                  }

                  return user;
                }),
              }
            : state?.conversationUsersNotApproved,
        conversationDetail:
          state?.conversationDetail?.hoiThoaiId === payload?.hoiThoaiId
            ? {
                ...state?.conversationDetail,
                nhanVienHoiThoai: state?.conversationDetail?.nhanVienHoiThoai?.map((user) =>
                  user?.nhanVien?.nhanVienId === payload?.nhanVienId
                    ? {
                        ...user,
                        vaiTro: payload?.vaiTro || 0,
                        nhanVien: {
                          ...user?.nhanVien,
                          vaiTro: payload?.vaiTro || 0,
                        },
                      }
                    : user
                ),
              }
            : state?.conversationDetail,
      };
    case API_REQUEST_CONVERSATION_LIST:
      return {
        ...state,
        conversationList: payload?.config?.params?.hoithoaiId
          ? [...state?.conversationList, ...payload?.data?.result?.items]
          : payload?.data?.result?.items,
      };
    case REMOVE_CONVERSATION_LIST:
      return {
        ...state,
        conversationList: [],
      };
    case RESET_USER_LIST:
      return {
        ...state,
        conversationUsers: {},
        conversationUsersNotApproved: {},
      };
    case ACTIVE_CONVERSATION:
      return {
        ...state,
        conversationDetail: payload?.conversation,
      };
    case SET_USER_INTERFACE_KEY:
      return {
        ...state,
        userInterfaceKey: payload,
      };
    case SAVE_CURRENT_CONVERSATION_CHAT:
      if (
        payload?.typeConversation === 'ALL' ||
        payload?.typeConversation === payload?.hoiThoai?.isGroup
      ) {
        return {
          ...state,
          conversationDetail: payload?.hoiThoai,
        };
      }

      return state;
    case SET_SCROLL:
      return {
        ...state,
        isScroll: payload?.data,
      };
    case GET_CONVERSATION_TOTAL_NEW_MESSAGES:
      return {
        ...state,
        totalNewMessages: payload,
      };
    case SET_FROM_OTHER_APP_DATA:
      return {
        ...state,
        fromOtherAppData: payload,
      };
    case API_GET_GROUP_CONVERSATION_LIST:
      if (payload.data.result.page !== 1) {
        const values = payload.data.result;
        const newData = [...(state.groupConversationList.items || []), ...(values.items || [])];
        return {
          ...state,
          groupConversationList: {
            ...values,
            items: newData,
          },
        };
      }
      return {
        ...state,
        groupConversationList: payload.data.result || {},
      };
    case SET_LOADING_APPROVED:
      let newLoadingApproved = [...state.loadingApproved];
      if (payload?.isRemove) {
        newLoadingApproved = newLoadingApproved?.filter((elm) => elm?.id !== payload?.id);
      } else {
        newLoadingApproved = [
          ...state?.loadingApproved,
          {
            id: payload?.id,
            isApprove: payload?.isApprove,
          },
        ];
      }

      return {
        ...state,
        loadingApproved: newLoadingApproved,
      };
    case UPDATE_CONVERSATION:
      return {
        ...state,
        conversationDetail:
          state?.conversationDetail?.hoiThoaiId === payload?.hoiThoaiId
            ? {
                ...state?.conversationDetail,
                tenHoiThoai: payload?.tenHoiThoai,
                anhDaiDien: payload?.anhDaiDien,
              }
            : state?.conversationDetail,
      };
    case GET_LINKS_CONVERATION:
      tinNhanId = action?.meta?.previousAction?.payload?.request?.params?.tinNhanId;
      const items = payload?.data?.result?.items || [];
      return {
        ...state,
        conversationLinks: {
          ...payload?.data?.result,
          items:
            tinNhanId !== null
              ? [
                  ...state?.conversationLinks?.items,
                  ...items?.map((item, index) => ({
                    ...item,
                    id: state?.conversationLinks?.items?.length + index,
                  })),
                ]
              : items?.map((item, index) => ({
                  ...item,
                  id: index,
                })),
        },
      };
    case SET_UNREAD_LIST:
      return {
        ...state,
        unreadList: [...state?.unreadList?.filter((f) => f.id !== payload.id), payload],
      };
    case REMOVE_UNREAD_LIST:
      return {
        ...state,
        unreadList: [],
      };
    case UPDATE_CONVERSATION_INFO:
      return {
        ...state,
        conversationDetail:
          state.conversationDetail.hoiThoaiId === payload.hoiThoaiId
            ? {
                ...state.conversationDetail,
                ...payload?.data,
              }
            : state.conversationDetail,
      };
    case CLEAN_CONTEXT_CONVERSATION:
      return {
        ...state,
        conversationDetail: {},
      };
    default:
      return state;
  }
}
