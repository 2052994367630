import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cln from 'classnames';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
    };
  }

  componentDidMount() {
    const { avatarSize } = this.props;
    if (avatarSize === 'NOMAL') {
      this.setState({
        style: {
          width: '30px',
          height: '30px',
          minWidth: '30px',
        },
      });
    }
  }

  render() {
    const { name, avatar, className, department, unit } = this.props;
    const { style } = this.state;
    return (
      <div className={cln('user-card', className)}>
        <span className="avatar" style={{ ...style, backgroundImage: `url(${avatar})` }} />
        <div className="info">
          <p className="name">{name}</p>
          <p className="department">
            {department ? department : ''}
            {unit ? ` - ${unit}` : ''}
          </p>
        </div>
      </div>
    );
  }
}

User.defaultProps = {
  avatarSize: 'NOMAL',
  name: '',
  avatar: null,
  className: '',
  department: null,
  unit: null,
};

User.propTypes = {
  avatarSize: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  className: PropTypes.string,
  department: PropTypes.string,
  unit: PropTypes.string,
};

export default User;
