export const feedbackList = [
  {
    classNames: 'center',
    style: [{ type: 'textAlign', value: 'center' }],
    value: 'Stt',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
  },
  {
    classNames: 'column-8',
    style: [],
    value: 'Chất lượng',
    sort: 'chatLuong',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
    dataSelect: [
      {
        name: 'Tất cả',
        value: '',
      },
      {
        name: 'Tốt',
        value: 1,
      },
    ],
  },
  {
    classNames: 'column-8',
    style: [],
    value: 'Phiên bản',
    sort: 'phienBan',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
    dataSelect: [
      {
        name: 'Tất cả',
        value: '',
      },
      {
        name: 'Mobile',
        value: 1,
      },
    ],
  },
  {
    classNames: 'column-20',
    style: [{ type: 'minWidth', value: '300px' }],
    value: 'Nội dung',
    sort: 'noiDung',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
  },
  {
    classNames: '',
    style: [],
    value: 'Ngày gửi',
    sort: 'ngayGui',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
  },
  {
    classNames: '',
    style: [],
    value: 'Người gửi',
    sort: null,
    permission: ['SUPERADMIN', 'ADMIN'],
    chucVu: ['Superadmin'],
    typeGridView: null,
    nameGridView: '',
  },
  {
    classNames: '',
    style: [],
    value: 'Trạng thái',
    sort: 'trangThai',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
    dataSelect: [
      {
        name: 'Tất cả',
        value: '',
      },
      {
        name: 'Hoàn thành',
        value: 1,
      },
    ],
  },
  {
    classNames: 'column-10',
    style: [{ type: 'minWidth', value: '180px' }],
    value: 'Người xác nhận',
    sort: 'nguoiXacNhan',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
  },
];

export const questionList = [
  {
    classNames: 'center',
    style: [
      { type: 'textAlign', value: 'center' },
      { type: 'width', value: '70px' },
    ],
    value: 'Stt',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
  },
  {
    classNames: '',
    style: [{ type: 'width', value: '50%' }],
    value: 'Câu hỏi',
    sort: null,
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: 'cauHoi',
  },
  {
    classNames: '',
    style: [],
    value: 'Đáp án',
    sort: null,
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: 'dapAn',
  },
];

export const statisticsList = [
  {
    classNames: 'center',
    style: [
      { type: 'textAlign', value: 'center' },
      { type: 'width', value: '70px' },
    ],
    value: 'Stt',
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: '',
  },
  {
    classNames: '',
    style: [{ type: 'width', value: '50%' }],
    value: 'Câu hỏi',
    sort: null,
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: 'cauHoi',
  },
  {
    classNames: 'pl20',
    style: [],
    value: 'Đáp án',
    sort: null,
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: 'dapAn',
  },
  {
    classNames: '',
    style: [
      { type: 'width', value: '80px' },
      { type: 'textAlign', value: 'center' },
    ],
    value: 'Sl',
    sort: null,
    permission: [],
    chucVu: [],
    typeGridView: null,
    nameGridView: 'sl',
  },
];
