import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { IMAGE_URL } from '../../constants/Api';
import { checkQuyenChucVu } from '../../helpers/helper';
import { variables } from '../../constants/variables';

class ListItem extends Component {
  showTrangTrai() {
    let { xuLyGopY } = this.props.item;
    if (!_.get(xuLyGopY, 'trangThai')) {
      return '';
    }
    switch (_.get(xuLyGopY.trangThai, 'maTrangThai')) {
      case variables.TRANGTHAIXULY.CHO_TIEP_NHAN:
        return <span className="status warning">{_.get(xuLyGopY.trangThai, 'tenTrangThai')}</span>;
      case variables.TRANGTHAIXULY.DA_TIEP_NHAN:
        return <span className="status info">{_.get(xuLyGopY.trangThai, 'tenTrangThai')}</span>;
      case variables.TRANGTHAIXULY.SE_HIEU_CHINH:
        return <span className="status success">{_.get(xuLyGopY.trangThai, 'tenTrangThai')}</span>;
      case variables.TRANGTHAIXULY.KHONG_HIEU_CHINH:
        return <span className="status danger">{_.get(xuLyGopY.trangThai, 'tenTrangThai')}</span>;
      default:
        return false;
    }
  }

  handleClick = () => {
    const { item } = this.props;
    this.props.history.push(`/hop-thu-gop-y/chi-tiet/${item.gopYId}`);
  };

  render() {
    const { item, number } = this.props;
    if (!item || !number) {
      return;
    }

    return (
      <tr className="pointer" onClick={this.handleClick}>
        <td className="center">{number > 9 ? number : '0' + number}</td>
        <td>
          <a onClick={this.handleClick}>{_.get(item.chatLuong, 'tenChatLuong')}</a>
        </td>
        <td>{item.phienBan}</td>
        <td>{item.noiDung}</td>
        <td>
          {moment(item.ngayGui).isValid() ? moment(item.ngayGui).format('DD/MM/YYYY HH:mm') : ''}
        </td>
        {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
          <td className="donViNguoiGui">
            <p>{item.tenNguoiGui}</p>
            {item.donVi && (
              <span>
                {_.get(item.phongBan, 'maPhongBan')
                  ? `${_.get(item.phongBan, 'maPhongBan')} - `
                  : ''}
                {item.donVi.tenVietTat}
              </span>
            )}
          </td>
        )}
        <td>{this.showTrangTrai()}</td>
        <td>
          {_.get(item.xuLyGopY, 'nguoiXuLy') && (
            <div className="avatar-info small">
              <span
                className="image"
                style={{
                  backgroundImage: _.get(item.xuLyGopY, 'anhDaiDien')
                    ? `url(${IMAGE_URL}${_.get(item.xuLyGopY, 'anhDaiDien')})`
                    : `url(${process.env.PUBLIC_URL}/images/default_avatar.png)`,
                }}
              ></span>
              <span className="name">{_.get(item.xuLyGopY, 'nguoiXuLy')}</span>
            </div>
          )}
        </td>
      </tr>
    );
  }
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
};

export default ListItem;
