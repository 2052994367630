import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';

export default class Filter extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filterOnline: this.props.contact.filterOnline || false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleFilter = (e) => {
    this.setState({ filterOnline: !this.state.filterOnline }, () => {
      this.props.actions.contactChangeStatusFilter();
    });
  };

  render() {
    const checked = this.props.contact.filterOnline;

    return (
      <label className="checkbox-online">
        <input type="checkbox" checked={checked} onChange={this.handleFilter} />
        {/* <span className="pt-control-indicator"/> */}
        Online
      </label>
    );
  }
}
