import React, { Component } from 'react';

export default class TextAreaAutoHeight extends Component {
  constructor(props) {
    super(props);

    this.textArea = null;
  }

  componentDidMount() {
    if (this.props.focus) {
      this.textArea.focus();
      const strLength = this.props.value.length;
      this.textArea.setSelectionRange(strLength, strLength);
    }
    this.resize();
  }

  resize() {
    let field = this.textArea;
    field.style.height = 'inherit';
    let computed = window.getComputedStyle(field);

    // Calculate the height
    let height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 'px';
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus && prevProps.focus !== this.props.focus) {
      this.textArea.focus();
      const strLength = this.props.value.length;
      this.textArea.setSelectionRange(strLength, strLength);
    }

    if (this.props.value !== prevProps.value) {
      this.resize();
    }
  }

  render() {
    return (
      <textarea
        ref={(el) => (this.textArea = el)}
        className={this.props.className}
        dir="auto"
        rows={1}
        name={this.props.name}
        maxLength={this.props.maxLength}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
      />
    );
  }
}
