import { camelizeKeys } from 'humps';
import * as types from '../constants/ActionTypes';
import { convertKeysToCamelCase } from '@/helpers/helper';
import { apiClients } from '@/middleware/api';
import { Intent } from '@blueprintjs/core';
import variables from '@/constants/variables';

export const setConversationsIsLoading = (value) => (dispatch, getState) => {
  return dispatch({
    type: types.API_REQUEST_GET_CONVERSATION_LOADING,
    payload: value,
  });
};

export const getConversations =
  ({ hoithoaiId = '', limit, isGroup, isQuanTrong, unread }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_GET_CONVERSATION, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: '/api/hoithoai',
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params: {
            hoithoaiId: hoithoaiId,
            limit: limit,
            isGroup: isGroup,
            isQuanTrong: isQuanTrong,
            unread: unread,
            ...(isGroup === 1 ||
            isGroup === 0 ||
            isGroup === 'ALL' ||
            (!isGroup && !isQuanTrong && !unread)
              ? {
                  ghim: false,
                }
              : {}),
          },
        },
      },
    });
  };

export const setConversationsIsLoadingPinTop = (value) => (dispatch) => {
  return dispatch({
    type: types.API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP,
    payload: value,
  });
};

export const getConversationsPinTopReset = () => (dispatch) => {
  return dispatch({
    type: types.API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP_RESET,
  });
};

export const updateConversationPinTop = (data) => (dispatch) => {
  return dispatch({
    type: types.UPDATE_CONVERSATION_PIN_TOP,
    payload: data,
  });
};

export const getConversationsPinTop =
  ({ hoithoaiId = '', isGroup, limit = 30 }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_GET_CONVERSATION_PIN_TOP,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/hoithoai/ghim',
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params: {
            hoithoaiId: hoithoaiId,
            isGroup: isGroup,
            limit: limit,
            ghim: true,
          },
        },
      },
    });
  };

export const getConversationsPinTopReconnected =
  ({ hoithoaiId = '', isGroup, limit = 30 }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: '/api/hoithoai/ghim',
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params: {
            hoithoaiId: hoithoaiId,
            isGroup: isGroup,
            limit: limit,
            ghim: true,
          },
        },
      },
    });
  };

export const getConversationsReconnected =
  ({ hoithoaiId = '', limit, isGroup, isQuanTrong, unread }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
      payload: {
        client: 'defaultV2',
        request: {
          url: '/api/hoithoai',
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params: {
            hoithoaiId: hoithoaiId,
            limit: limit,
            isGroup: isGroup,
            isQuanTrong: isQuanTrong,
            unread: unread,
            ...(isGroup === 1 ||
            isGroup === 0 ||
            isGroup === 'ALL' ||
            (!isGroup && !isQuanTrong && !unread)
              ? {
                  ghim: false,
                }
              : {}),
          },
        },
      },
    });
  };

export const updateConversationsReconnected = (data) => (dispatch, getState) => {
  return dispatch({
    type: types.API_GET_CONVERSATIONS_RECONNECTED,
    payload: {
      ...convertKeysToCamelCase(data),
      auth: getState().auth,
    },
  });
};

export const removeConversationList = () => ({
  type: types.REMOVE_CONVERSATION_LIST,
});

export const setCurrentConversation = (conversation) => ({
  type: types.SET_CURRENT_CONVERSATION,
  data: conversation,
});

export const setActivateAllTab = (isValue) => ({
  type: types.SET_ACTIVATE_ALL_TAB,
  payload: isValue,
});

export const getMessageOfConversation =
  ({ limit, hoiThoaiId = '', tinNhanId = '' }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_GET_MESSAGES, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: `/api/hoithoai/${hoiThoaiId}/tinnhan?tinnhanId=${tinNhanId}&limit=${limit}`,
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
        },
        hoiThoaiId,
      },
    });
  };

export const setLoadingMessage = (loading) => ({
  type: types.SET_LOADING_MESSAGE,
  loading,
});

export const setLoadingBeforeMessage = (loading) => ({
  type: types.SET_LOADING_AFTER_MESSAGE,
  loading,
});

export const setScrollBottom = (isScrollBottom) => ({
  type: types.SET_IS_SCROLL_BOTTOM,
  data: isScrollBottom,
});

export const updateMessage = (message) => ({
  type: types.UPDATE_MESSAGE,
  payload: message,
});

export const removeMessages = () => ({
  type: types.REMOVE_MESSAGE_OF_CONVERSATION,
});

export const cleanContextConversation = () => ({
  type: types.CLEAN_CONTEXT_CONVERSATION,
});

export const resetTopPinMessages = () => ({
  type: types.RESET_TOP_PIN_MESSAGES,
});

export const setReceiveNewMessage = () => ({
  type: types.SET_RECEIVE_NEW_MESSAGE,
});

export const resetUserList = () => ({
  type: types.RESET_USER_LIST,
});

export const handleTotalConversationHasNewsMessage = (total) => ({
  type: types.GET_CONVERSATION_TOTAL_NEW_MESSAGES,
  payload: total,
});

export const removeUnreadMessage = (data) => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: types.REMOVE_UNREAD_MESSAGE,
    data,
    auth: state?.auth,
  });
};

export const createNewConversation = (conversation) => (dispatch, getState) => {
  const state = getState();
  conversation = camelizeKeys(conversation);
  const isMe = state.auth?.user === conversation?.nguoiTaoId;
  return dispatch({
    type: types.CREATE_NEW_CONVERSATION,
    conversation,
    isMe,
    auth: state.auth?.user,
  });
};

export const activeConversation = (conversation, nhanVienId) => ({
  type: types.ACTIVE_CONVERSATION,
  payload: {
    conversation,
    nhanVienId,
  },
});

export const createPinMessage =
  ({ tinNhanId = '', hoiThoaiId = '' }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: `/api/tinnhan/ghim`,
          method: 'POST',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          data: {
            tin_nhan_id: tinNhanId,
            hoi_thoai_id: hoiThoaiId,
          },
        },
      },
    });
  };

export const updatePinMessage = (data) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/ghim`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });
};

export const unpinMessage =
  ({ hoiThoaiId = '', tinNhanGhimId = '' }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: `/api/tinnhan/ghim/${tinNhanGhimId}/hoithoai/${hoiThoaiId}`,
          method: 'PUT',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
        },
      },
    });
  };

export const deletePinMessage = (hoiThoaiId, tinNhanGhimId) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/ghim/${tinNhanGhimId}/hoithoai/${hoiThoaiId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const recallMessage =
  ({ hoiThoaiId = '', tinNhanId = '', connectionId = '' }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.RECALL_MESSAGE, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: `api/tinnhan/thuhoi`,
          method: 'PUT',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
            'api-version': '1.0',
          },
          params: {
            hoithoaiId: hoiThoaiId,
            tinnhanId: tinNhanId,
            connectionId,
          },
        },
      },
    });
  };

export const getConversationDetail = (hoiThoaiId) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.CONVERSATION_DETAIL, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const getConversationDetailV2 = (hoiThoaiId) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const putImportantConversation = (hoiThoaiId, isQuanTrong) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/danhdau?hoithoaiId=${hoiThoaiId}&isQuanTrong=${isQuanTrong}`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const setImportantConversation = (hoiThoai, currentUserId, isQuanTrong) => ({
  type: types.IMPORTANT_CONVERSATION,
  payload: {
    hoiThoai,
    currentUserId,
    isQuanTrong,
  },
});

export const resetDetailConversation = () => ({
  type: types.CONVERSATION_DETAIL,
  payload: null,
});

export const setFilesNewMessage = (data) => ({
  type: types.SET_FILES_NEW_MESSAGE,
  payload: {
    data,
  },
});

export const setReloadData = (data) => ({
  type: types.SET_RELOAD_DATA,
  payload: {
    data,
  },
});

export const setSearchMessage = (message) => ({
  type: types.SET_SEARCH_MESSAGE,
  payload: {
    message,
  },
});

export const getConversationLatestContactList =
  ({ page = 1, name, limit = 10 }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SET_CONVERSATION_LATEST_CONTACT_LIST,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/hoithoai/haylienlac',
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params: {
            page,
            name,
            limit,
          },
        },
      },
    });
  };

export const removeConversationLatestContactList = () => ({
  type: types.REMOVE_CONVERSATION_LATEST_CONTACT_LIST,
});

export const handleNotificationOfConversation = (hoiThoaiId, userId) => ({
  type: types.HANDLE_NOTIFICATION_OF_CONVERSATION,
  payload: {
    hoiThoaiId,
    userId,
  },
});

export const setTypeConversation = (typeConversation) => ({
  type: types.SET_TYPE_CONVERSATION,
  payload: {
    typeConversation,
  },
});

export const setLoadingSearchMessage = (loading) => ({
  type: types.SET_LOADING_SEARCH_MESSAGE,
  payload: {
    loading,
  },
});

export const refreshContextMessage = () => ({
  type: types.REFRESH_CONTEXT_MESSAGE,
});

export const getMessagesFromSearchMessage =
  (hoiThoaiId, tinhanId, limit) => async (dispatch, getState) => {
    dispatch({
      type: types.SET_LOADING_SEARCH_MESSAGE,
      payload: {
        loading: true,
      },
    });

    let res;
    try {
      const beforeMes = apiClients.default.client({
        method: 'get',
        url: `/api/hoithoai/${hoiThoaiId}/tinnhan?tinnhanId=${tinhanId}&limit=${limit}&isDesc=true`,
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
          'api-version': '2.0',
        },
      });

      const afterMes = apiClients.default.client({
        method: 'get',
        url: `/api/hoithoai/${hoiThoaiId}/tinnhan?tinnhanId=${tinhanId}&limit=${limit}&isDesc=false`,
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
          'api-version': '2.0',
        },
      });

      const message = apiClients.default.client({
        method: 'get',
        url: `/api/tinnhan/${tinhanId}`,
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      });

      res = await Promise.all([beforeMes, afterMes, message]);

      return dispatch({
        type: types.GET_MESSAGES_FORM_SEARCH_MESSAGE,
        payload: {
          res,
        },
      });
    } catch (err) {
      dispatch({
        type: types.COMMON_ADD_TOASTER_MESSAGE,
        data: {
          message: err?.message,
          intent: Intent.WARNING,
        },
      });

      dispatch({
        type: types.SET_LOADING_SEARCH_MESSAGE,
        payload: {
          loading: false,
        },
      });
    }
  };

export const getBeforeMessageOfConversation =
  ({ hoiThoaiId = '', tinNhanId = '' }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_GET_AFTER_MESSAGES,
        types.API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: `/api/hoithoai/${hoiThoaiId}/tinnhan?tinnhanId=${tinNhanId}&limit=${variables.LOAD_MORE.MESSAGE_LIMIT}&isDesc=false`,
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
            'api-version': '2.0',
          },
        },
        hoiThoaiId,
      },
    });
  };

export const updateRoleConversation = (hoiThoaiId, nhanVienId, vaiTro) => ({
  type: types.UPDATE_ROLE_CONVERSATION,
  payload: {
    hoiThoaiId,
    nhanVienId,
    vaiTro,
  },
});

export const setConversationPinTop = (hoiThoaiId, tinNhanGhim, type, tinNhanId = null) => ({
  type: types.SET_CONVERSATION_PIN_TOP,
  payload: {
    hoiThoaiId,
    tinNhanGhim,
    type,
    tinNhanId,
  },
});

export const getMessageSearch = (params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/timkiem`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
          'api-version': 2.0,
        },
        params,
      },
    },
  });
};

export const getGroupConversationList = (params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_GET_GROUP_CONVERSATION_LIST, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/timkiem`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params: {
          hoiThoaiMotMot: false,
          hoiThoaiNhieuNguoi: true,
          isQuanTrong: false,
          isDatTen: true,
          ...params,
        },
      },
    },
  });
};

export const getSearchConversationList = (params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/timkiem`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params: {
          ...params,
          limit: 20,
        },
      },
    },
  });
};

export const getSearchMessageList = (params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/timkiem`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params: {
          ...params,
          limit: 20,
        },
      },
    },
  });
};

export const setUserInterfaceKey = (value) => ({
  type: types.SET_USER_INTERFACE_KEY,
  payload: value,
});

export const getConversationCloud = () => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/hoithoai/cloud',
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const setTopConversation = (hoiThoai) => (dispatch, getState) =>
  dispatch({
    type: types.SET_TOP_CONVERSATION,
    payload: {
      hoiThoai,
      auth: getState().auth,
    },
  });

export const setConversationMessageSeen = (hoiThoaiId, tinNhanId, status) => ({
  type: types.SET_CONVERSATION_MESSAGE_SEEN,
  payload: {
    hoiThoaiId,
    tinNhanId,
    status,
  },
});

export const setConversationMessageReceived = (hoiThoaiId, tinNhanId, status) => ({
  type: types.SET_CONVERSATION_MESSAGE_RECEIVED,
  payload: {
    hoiThoaiId,
    tinNhanId,
    status,
  },
});

export const updateIsScroll = (status) => ({
  type: types.SET_SCROLL,
  payload: {
    data: status,
  },
});

export const setFromOtherAppData = (data) => {
  return {
    type: types.SET_FROM_OTHER_APP_DATA,
    payload: data
      ? {
          tinNhan: data?.tinNhan,
          hoiThoai: data?.hoiThoai,
        }
      : null,
  };
};

export const setTotalMessage = (value) => ({
  type: types.SET_TOTAL_MESSAGE,
  payload: value,
});

export const getConversationExist = (hoiThoaiId) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}/exist`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const setConversationApprovedUser = (value) => ({
  type: types.SET_CONVERSATION_APPROVED_USER,
  payload: value,
});

export const setLoadingApproved = (id, isApprove, isRemove = false) => ({
  type: types.SET_LOADING_APPROVED,
  payload: {
    id,
    isApprove,
    isRemove,
  },
});

export const getConversationLinks = (params) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.GET_LINKS_CONVERATION, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/link`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params,
      },
    },
  });

export const signalrAddUsers = (users) => ({
  type: types.SIGNALR_ADD_USERS,
  payload: users,
});

export const setUnreadList = (value) => ({
  type: types.SET_UNREAD_LIST,
  payload: value,
});

export const removeUnreadList = () => ({
  type: types.REMOVE_UNREAD_LIST,
});
