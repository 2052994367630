import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import variables from '@/constants/variables';
import classNames from 'classnames';

export default class Tabs extends Component {
  chooseTab = (e, key, index) => {
    e.preventDefault();
    this.props.onClick && this.props.onClick(key, index);
  };
  render() {
    const dsChatLuong = [
      {
        name: 'Tốt',
        icon: 'tot.svg',
        key: variables.CHAT_LUONG.TOT,
      },
      {
        name: 'Bình thường',
        icon: 'binh-thuong.svg',
        key: variables.CHAT_LUONG.BINH_THUONG,
      },
      {
        name: 'Chưa tốt',
        icon: 'chua-tot.svg',
        key: variables.CHAT_LUONG.CHUA_TOT,
      },
    ];
    const { className, data, active } = this.props;
    return (
      <div className="cpc-content">
        <p className="cpc-content__label">Anh/chị vui lòng đánh giá chất lượng eOffice dưới đây</p>
        <div className="cpc-content__body">
          <ul
            className={classnames('quality-list', {
              className: className !== '',
            })}
          >
            {data &&
              data.length !== 0 &&
              dsChatLuong.map((item, key) => {
                return (
                  <li key={key}>
                    <div
                      className={classNames('smile-box', {
                        active: active === data[key].chatLuongId,
                      })}
                      onClick={(e) => this.chooseTab(e, data[key].chatLuongId, key)}
                    >
                      <span
                        className="icon"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/images/${item.icon})`,
                        }}
                      />
                      <h4>{item.name}</h4>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  active: '',
  className: '',
  data: [],
  model: ['key', 'name', 'icon'],
};

Tabs.propTypes = {
  active: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  model: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};
