import React, { Component } from 'react';
import './assets/downloadpage.scss';
import axios from 'axios';
import Brand from '../../components/atoms/brand';

class Download extends Component {
  state = {
    iOs: '',
  };

  componentWillMount() {
    this.loadiOsJson().then((res) => {
      if (res.status !== 200) {
        return false;
      }

      this.setState({
        iOs: res.data.menifest,
      });
    });

    this.loadSystemInfos().then((res) => {
      this.setState({
        android_url: res?.android_url,
        android_url_beta: res?.android_url_beta,
        ios_url: res?.ios_url,
        ios_url_beta: res?.ios_url_beta,
      });
    });
  }

  loadiOsJson = () => {
    return axios.get('https://cpc-eoffice.firebaseio.com/ios/infoautoupdate.json').then((res) => {
      if (res.status !== 200) {
        return false;
      }

      return res.data.menifest;
    });
  };

  loadSystemInfos = () => {
    return axios.get('https://cpc-eoffice.firebaseio.com/system_infos.json').then((res) => {
      if (res.status !== 200) {
        return false;
      }

      return res.data;
    });
  };

  handleOpenIos = (e) => {
    e.preventDefault();

    if (this.state.iOs && this.state.iOs !== '') {
      return (window.location.href =
        'itms-services://?action=download-manifest&url=' + this.state.iOs);
    }

    this.loadiOsJson().then((link) => {
      window.location.href = 'itms-services://?action=download-manifest&url=' + link;
    });
  };

  render() {
    return (
      <div className="container-fluid download-container">
        <div className="cpc-download-page">
          <div className="center">
            <Brand hideText center />
            <h2 className="title">CPC-eOffice</h2>
          </div>
          <main className="main-container">
            <div className="btn-group">
              <a
                href={this.state?.ios_url}
                className="btn-download-app btn-dl-app-store"
                onClick={this.handleOpenIos}
              >
                Tải ứng dụng trên App Store
              </a>
              <a href={this.state?.android_url} className="btn-download-app btn-dl-google-play">
                Tải ứng dụng trên Google play
              </a>
            </div>
            {this.state?.ios_url_beta || this.state?.android_url_beta ? (
              <div className="btn-group">
                <a
                  href={this.state?.ios_url_beta}
                  className="btn-download-app btn-dl-app-store-beta"
                >
                  Tải ứng dụng IOS Beta
                </a>
                <a
                  href={this.state?.android_url_beta}
                  className="btn-download-app btn-dl-google-play-beta"
                >
                  Tải ứng dụng Android Beta
                </a>
              </div>
            ) : (
              ''
            )}
          </main>
        </div>
        <div className="cpc-footer">
          <div className="cpc-footer-version">PHIÊN BẢN CPC-eOffice 7.1 - Văn phòng điện tử</div>
          <div className="cpc-footer-copyright">&copy; 2017 TỔNG CÔNG TY ĐIỆN LỰC MIỀN TRUNG</div>
        </div>
      </div>
    );
  }
}

export default Download;
