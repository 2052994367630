export const toolbarOptions = [
  // [{ header: [1, 2, 3] }],
  ['bold', 'italic', 'underline', 'strike', { color: [] }, { background: [] }, 'clean'], // toggled buttons
  // ['blockquote', 'code-block'],

  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ direction: 'rtl' }], // text direction

  // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  // [{ align: [] }],

  // ['clean'], // remove formatting button
];

export const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'background',
  'text',
  'mention',
];
