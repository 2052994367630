import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBox from './ChatBox';
import Message from './Message';
import sanitizeHtml from 'sanitize-html';
import shallowCompare from 'react-addons-shallow-compare';
import { SignalRHub } from '../../middleware/signalr';
import './assets/section.scss';
import ifvisible from 'ifvisible.js';

export default class Section extends Component {
  static propTypes = {
    conversationId: PropTypes.string,
    auth: PropTypes.object.isRequired,
    favourites: PropTypes.array.isRequired,
    messages: PropTypes.array.isRequired,
    receivers: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    conversationId: null,
  };

  refHandlers = {
    chatbox: (ref: ChatBox) => (this.chatbox = ref),
  };

  constructor(props) {
    super(props);
    this.signalRHub = SignalRHub(this.props.auth, this.props.actions);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleChangeSms = this.handleChangeSms.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleReadAllMessage = this.handleReadAllMessage.bind(this);
    this.handleForwardMessage = this.handleForwardMessage.bind(this);
    this.handleCopyAndForwardMessage = this.handleCopyAndForwardMessage.bind(this);
    this.handleTyping = this.handleTyping.bind(this);
    this.handleOffsetHeight = this.handleOffsetHeight.bind(this);
    this.connectSignalRHub = this.connectSignalRHub.bind(this);
  }

  state = {
    smsChecked: false,
    isTyping: false,
    emailChecked: false,
    lastSent: new Date().getTime(),
    offsetHeight: 220,
    isCheckNofi: false,
    isCheckCopyAndForward: true,
    callFuncChatBox: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentWillMount() {
    this.connectSignalRHub();
  }

  connectSignalRHub() {
    this.signalRHub &&
      this.signalRHub
        .start()
        .done(() => {
          this.props.actions.chatSetConnectionId(this.signalRHub.connection.id);
          // this.props.actions.getNewMessages();
          this.props.actions.commonRemoveToasterMessage();
          if (process.env.NODE_ENV !== 'production') {
            console.log('Now connected, connection ID=' + this.signalRHub.connection.id);
          }
        })
        .fail(() => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Could not connect');
          }
        });
  }

  componentWillUnmount() {
    this.signalRHub && this.signalRHub.stop();
    ifvisible.off('focus', this.handleFocus);
  }

  componentWillReceiveProps(props) {
    if (props.reconnectSignalR !== this.props.reconnectSignalR) {
      this.setState({ isCheckNofi: true });
      this.connectSignalRHub();
    }
  }

  resetData = () => {
    this.setState({
      smsChecked: false,
      emailChecked: false,
    });
  };

  handleChangeSms = () => {
    this.setState({ smsChecked: !this.state.smsChecked });
  };

  handleChangeEmail = () => {
    this.setState({ emailChecked: !this.state.emailChecked });
  };

  handleReadAllMessage = () => {
    if (!this.signalRHub || !this.signalRHub.connection || this.signalRHub.connection.state !== 1) {
      return;
    }

    this.props.actions.chatReadAllMessages();
    this.signalRHub.invoke('ReadAllConversation', [new Date().toISOString()]);
  };

  handleForwardMessage = (id) => {
    this.props.actions.setIdMessageForward(id);
    this.props.actions.removeFromQuoteMessage({ type: 'file' });
    this.props.actions.removeFromQuoteMessage({ type: 'tinNhan' });
    this.props.actions.removeFromQuoteMessage({ type: 'phieuKhaoSat' });
    this.setState({
      isCheckCopyAndForward: false,
      callFuncChatBox: true,
    });
  };

  handleCopyAndForwardMessage = (id) => {
    this.props.actions.setIdMessageForward(id);
    this.props.actions.removeFromQuoteMessage({ type: 'file' });
    this.props.actions.removeFromQuoteMessage({ type: 'tinNhan' });
    this.props.actions.removeFromQuoteMessage({ type: 'phieuKhaoSat' });
    this.setState({
      isCheckCopyAndForward: true,
      callFuncChatBox: true,
    });
  };

  chatBoxFunctionCalled = () => {
    this.setState({
      callFuncChatBox: false,
    });
  };

  resetCheckCopyAndForward = (param) => {
    this.setState({
      isCheckCopyAndForward: param,
    });
  };

  handleSendMessage = (message, attachments = [], surveys = [], messageForwardJSON) => {
    this.setState({
      lastSent: new Date().getTime(),
    });

    return new Promise((resolve, reject) => {
      if (!this.props.receivers || !this.props.receivers.length) {
        return reject(new Error(''));
      }

      if (this.signalRHub.connection.state !== 1) {
        return reject(new Error('Mất kết nối, vui lòng thử lại.'));
      }

      this.props.actions.chatSignalRPreSendMessage();
      let cleanMessage = sanitizeHtml(message, {
        allowedTags: ['b', 'i', 'em', 'strong', 'ins', 'u', 'br', 'p', 'span'],
        allowedAttributes: {
          span: ['style'],
        },
        transformTags: {
          ins: 'u',
        },
      });

      cleanMessage = messageForwardJSON
        ? `[messageForwardJSON]${messageForwardJSON}[messageForwardJSON]${cleanMessage}`
        : cleanMessage;
      const send = (conversationId) => {
        const messageModel = {
          noi_dung: cleanMessage,
          hoi_thoai_id: conversationId,
          // If you change here, you MUST change at ChatBox::forwardMessage()
          file_dinh_kem: attachments.map((file) => ({
            file_id: file.info.id,
            ten_file: file.info.original_name,
            url: file.info.path,
            kieu_file: file.info.type,
            kich_thuoc: file.info.length,
          })),
          phieu_khao_sat: surveys.map((item) => ({
            phieu_khao_sat_id: item.phieuKhaoSatId,
            tieu_de: item.tieuDe,
          })),
          tuy_chon: {
            sms: this.state.smsChecked === true ? 1 : 0,
            email: this.state.emailChecked === true ? 1 : 0,
          },
        };
        this.signalRHub
          .invoke('SendMessage', [messageModel])
          .done((res) => {
            this.handleOffsetHeight();
            resolve();
          })
          .fail(() => {
            reject(new Error('Có lỗi xảy ra, vui lòng thử lại.'));
          });
      };

      if (this.props.conversationId) {
        send(this.props.conversationId);
      } else {
        this.props.actions.chatCreateConversation(this.props.receivers).then((res) => {
          if (res.error) {
            return reject(res.error);
          }

          if (
            !res.payload ||
            !res.payload.data ||
            !res.payload.data.result ||
            !res.payload.data.result.hoiThoaiId
          ) {
            return reject(new Error('Có lỗi xảy ra, vui lòng thử lại.'));
          }

          this.props.actions.chatSetConversation(res.payload.data.result.hoiThoaiId);

          send(res.payload.data.result.hoiThoaiId);
        });
      }
    });
  };

  handleTyping() {
    this.setState({ isTyping: true });
  }

  handleOffsetHeight() {
    this.setState({
      offsetHeight:
        document.getElementById('cpc_chat_box_section') &&
        document.getElementById('cpc_chat_box_section').offsetHeight,
    });
  }

  handleFocus = () => {
    this.connectSignalRHub();
  };

  componentDidMount() {
    this.setState({ isTyping: false });
    ifvisible.on('focus', this.handleFocus);
  }

  render() {
    return (
      <div className="cpc-chat-section">
        <div
          className="cpc-chat-message-section"
          style={{
            paddingBottom: this.state.offsetHeight,
          }}
          id="cpc_chat_message_section"
        >
          <Message
            auth={this.props.auth}
            messages={this.props.messages}
            actions={this.props.actions}
            receivers={this.props.receivers}
            isTyping={this.state.isTyping}
            onReadAll={this.handleReadAllMessage}
            onForward={this.handleForwardMessage}
            onCopyAndForward={this.handleCopyAndForwardMessage}
            onLoadReceiverUsers={this.props.onLoadReceiverUsers}
            onSaveDocument={this.props.onSaveDocument}
            news={this.props.newsMessages}
            lastSent={this.state.lastSent}
            onOffsetHeight={this.state.offsetHeight}
          />
        </div>
        <div className="cpc-chat-box-section" id="cpc_chat_box_section">
          <ChatBox
            auth={this.props.auth}
            ref={this.refHandlers.chatbox}
            config={this.props.auth.config}
            conversationId={this.props.conversationId}
            actions={this.props.actions}
            onSendMessage={this.handleSendMessage}
            onTyping={this.handleTyping}
            receivers={this.props.receivers}
            favourites={this.props.favourites}
            smsChecked={this.state.smsChecked}
            emailChecked={this.state.emailChecked}
            onChangeSms={this.handleChangeSms}
            onChangeEmail={this.handleChangeEmail}
            isSubmiting={this.props.delivered}
            onLoadReceiverUsers={this.props.onLoadReceiverUsers}
            onOffsetHeight={this.handleOffsetHeight}
            fileUpload={this.props.fileUpload}
            customText={this.props.customText}
            isCheckCopyAndForward={this.state.isCheckCopyAndForward}
            resetCheckCopyAndForward={this.resetCheckCopyAndForward}
            callFuncChatBox={this.state.callFuncChatBox}
            chatBoxFunctionCalled={this.chatBoxFunctionCalled}
            resetData={this.resetData}
            fileDinhKem={this.props.fileDinhKem}
          />
        </div>
      </div>
    );
  }
}
