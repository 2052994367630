import React, { Component } from 'react';
import DepartmentTree from './DepartmentTree';
import { Scrollbars } from 'react-custom-scrollbars';
import { uniq, pullAll, isEqual, intersection } from 'lodash';
export default class CheckboxTree extends Component {
  state = {
    userChecked: this.props.userChecked || [],
    departmentChecked: [],
  };

  getNewUserChecked = ({ userArr, userChecked, checked }) => {
    let userEntity = {};
    let idUserArr = userArr.map((item) => item.nhanVienId);
    let idUserChecked = userChecked.map((item) => item.nhanVienId);
    userArr.forEach((item) => (userEntity = { ...userEntity, [item.nhanVienId]: item }));
    userChecked.forEach((item) => (userEntity = { ...userEntity, [item.nhanVienId]: item }));
    let newUserChecked = [];
    if (checked) {
      newUserChecked = uniq([...idUserArr, ...idUserChecked]);
    } else {
      newUserChecked = idUserChecked;
      pullAll(newUserChecked, idUserArr);
    }
    return newUserChecked.map((item) => userEntity[item]);
  };

  getNewDepartmentChecked = ({ idDepartmentArr, departmentChecked, checked }) => {
    if (checked) {
      return uniq([...departmentChecked, ...idDepartmentArr]);
    } else {
      let newDepartmentChecked = [...departmentChecked];
      pullAll(newDepartmentChecked, idDepartmentArr);
      return newDepartmentChecked;
    }
  };

  onChange = ({ userArr, idDepartmentArr, checked }) => {
    this.setState(
      (prevState) => ({
        userChecked:
          userArr && userArr.length > 0
            ? this.getNewUserChecked({ userArr, userChecked: prevState.userChecked, checked })
            : prevState.userChecked,
        departmentChecked:
          idDepartmentArr && idDepartmentArr.length > 0
            ? this.getNewDepartmentChecked({
                idDepartmentArr,
                departmentChecked: prevState.departmentChecked,
                checked,
              })
            : prevState.departmentChecked,
      }),
      () => this.props.onChange(this.state.userChecked)
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.data, prevProps.data) ||
      !isEqual(this.state.departmentChecked, prevState.departmentChecked)
    ) {
      let idDepartmentArr = this.props.data.map((department) => department.phongBanId);
      let isHaveAllDepartment =
        intersection(this.state.departmentChecked, idDepartmentArr).length ===
        idDepartmentArr.length;
      if (
        (this.props.isCheckAllDepartment && !isHaveAllDepartment) ||
        (!this.props.isCheckAllDepartment && idDepartmentArr.length !== 0 && isHaveAllDepartment)
      ) {
        this.props.handleCheckAllDepartment(true);
      }
    }
  }
  render() {
    return (
      <div className="mb15 checkbox-tree">
        <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={200}
          renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
        >
          {this.props.isLoading ? (
            <div className="spinner">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          ) : null}
          {!this.props.isLoading &&
            this.props.data.map((item) =>
              !item.phongBanCha ? (
                <DepartmentTree
                  departmentChecked={this.state.departmentChecked}
                  checked={
                    this.state.departmentChecked.findIndex((id) => item.phongBanId === id) !== -1
                  }
                  userChecked={this.state.userChecked}
                  onChange={this.onChange}
                  key={item.phongBanId}
                  data={item}
                  departmentData={this.props.data}
                />
              ) : null
            )}
        </Scrollbars>
      </div>
    );
  }
}
