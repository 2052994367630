import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MessageItem from './MessageItem';
import ifvisible from 'ifvisible.js';
import { Dialog, Button, Intent, Position, Toaster } from '@blueprintjs/core';
import { FormattedDate } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/message.scss';
import { connect } from 'react-redux';
import { camelizeKeys } from 'humps';
import { get } from 'lodash';

import { variables } from '../../constants/variables';

const countMessages = (messages) => {
  let count = 0;
  for (const group of messages) {
    count += group.items.length;
  }
  return count;
};

const getLastMessage = (messages) => {
  if (!messages.length || !messages[messages.length - 1].items.length) {
    return null;
  }
  return messages[messages.length - 1].items[messages[messages.length - 1].items.length - 1];
};

class Message extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    receivers: PropTypes.array.isRequired,
    messages: PropTypes.array.isRequired,
    onReadAll: PropTypes.func,
    news: PropTypes.number,
    lastSent: PropTypes.number,
    onForward: PropTypes.func,
    onCopyAndForward: PropTypes.func,
  };

  static defaultProps = {
    news: 0,
    onReadAll: () => {},
    onForward: () => {},
    onCopyAndForward: () => {},
  };

  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleRemoveMessage = this.handleRemoveMessage.bind(this);
    this.handleRemoveAllMessage = this.handleRemoveAllMessage.bind(this);
    this.handleCloseDialogRemoveMessage = this.handleCloseDialogRemoveMessage.bind(this);
    this.handleCloseDialogRemoveAllMessage = this.handleCloseDialogRemoveAllMessage.bind(this);
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
    this.handleCheckDelete = this.handleCheckDelete.bind(this);
    this.handleDeleteMessages = this.handleDeleteMessages.bind(this);
  }

  state = {
    messageRemove: null,
    isOpenRemoveMessage: false,
    isOpenRemoveAllMessage: false,
    isDelete: false,
    loading: false,
    hasMore: countMessages(this.props.messages) >= 20,
    scrollBottom: true,
    focus: true,
    news: 0,
    listDeleteMessages: {},
  };

  refHandlers = {
    scrollbar: (ref: Scrollbars) => (this.scrollbar = ref),
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidMount() {
    this.scrollbar.scrollToBottom();
    this.props.onReadAll();
    ifvisible.on('focus', this.handleFocus);
    ifvisible.on('blur', this.handleBlur);
    ifvisible.on('wakeup', this.handleFocus);
    ifvisible.on('idle', this.handleBlur);
  }

  componentWillUnmount() {
    ifvisible.off('focus', this.handleFocus);
    ifvisible.off('blur', this.handleBlur);
    ifvisible.off('wakeup', this.handleFocus);
    ifvisible.off('idle', this.handleBlur);
  }

  componentDidUpdate(prevProps, prevState) {
    const oldTotal = countMessages(prevProps.messages);
    const newTotal = countMessages(this.props.messages);
    const lastMessage = getLastMessage(this.props.messages);

    if (
      this.props.news &&
      lastMessage &&
      lastMessage.nguoiGuiId === this.props.auth.user.nhanVienId
    ) {
      this.props.onReadAll();
    }

    if (this.state.focus) {
      if (
        this.state.scrollBottom ||
        (newTotal > oldTotal &&
          lastMessage &&
          lastMessage.nguoiGuiId === this.props.auth.user.nhanVienId)
      ) {
        this.scrollbar.scrollToBottom();
      }
    }

    // if (!this.state.focus || this.state.news) {
    //   if (newTotal > oldTotal) {
    //     if (this.state.scrollBottom) {
    //       this.scrollbar.scrollTop(this.scrollbar.getScrollTop() + 23);
    //     }

    //     this.setState({
    //       news: this.state.news + (newTotal - oldTotal),
    //       scrollBottom: true
    //     });
    //   }
    // }

    if (oldTotal !== newTotal && newTotal === 0 && this.state.hasMore) {
      this.loadMoreMessages();
    }

    if (oldTotal !== newTotal && newTotal === 20) {
      this.setState({ hasMore: true });
    }
  }

  componentWillReceiveProps(props) {
    if (props.isTyping && this.state.news > 0) {
      this.props.onReadAll();
    }

    if (props.news !== this.state.news) {
      this.setState({ news: props.news });
    }

    if (props.lastSent !== this.props.lastSent) {
      this.loadToBottom();
    }
  }

  handleRemoveMessage = (message) => {
    this.setState({
      messageRemove: message,
      isDelete: true,
      listDeleteMessages: {
        ...this.state.listDeleteMessages,
        [message.tinNhanId]: message,
      },
      // isOpenRemoveMessage: true
    });
  };

  handleCloseDialogRemoveMessage = () => {
    this.setState({
      error: '',
      isOpenRemoveMessage: false,
    });
  };

  handleSubmitRemoveMessage = (e) => {
    e.preventDefault();

    if (!this.state.messageRemove) {
      return;
    }

    this.props.actions.chatRemoveMessage(this.state.messageRemove.tinNhanId).then((res) => {
      if (res.error) {
        this.toaster.show({ message: 'Có lỗi xảy ra, vui lòng thử lại', intent: Intent.DANGER });
        return;
      }

      this.toaster.show({ message: 'Xóa tin nhắn thành công', intent: Intent.SUCCESS });
      this.setState({
        isOpenRemoveMessage: false,
        messageRemove: null,
      });
    });
  };

  handleRemoveAllMessage = (message) => {
    this.setState({
      isOpenRemoveAllMessage: true,
    });
  };
  handleCloseDialogRemoveAllMessage = () => {
    this.setState({
      error: '',
      isOpenRemoveAllMessage: false,
    });
  };
  handleSubmitRemoveAllMessage = (e) => {
    e.preventDefault();

    if (!countMessages(this.props.messages)) {
      return;
    }

    let flatMessages = [];
    for (const group of this.props.messages) {
      flatMessages = [...flatMessages, ...group.items];
    }

    this.props.actions.chatRemoveMultipleMessages(flatMessages).then((res) => {
      if (res.error) {
        this.toaster.show({ message: 'Có lỗi xảy ra, vui lòng thử lại', intent: Intent.DANGER });
        return;
      }

      this.toaster.show({ message: 'Xóa tất cả tin nhắn thành công', intent: Intent.SUCCESS });
      this.setState({
        isOpenRemoveAllMessage: false,
      });
    });
  };

  handleFocus = () => {
    if (process.env.NODE_ENV !== 'production') {
      // console.log('focus');
    }

    if (this.scrollbar && this.scrollbar.getValues().top > 0.9 && this.state.news === 0) {
      this.props.actions.chatSetIdle(false);
    }

    this.setState({ focus: true });
  };

  handleBlur = () => {
    if (process.env.NODE_ENV !== 'production') {
      // console.log('blur');
    }
    this.props.actions.chatSetIdle(true);
    this.setState({ focus: false });
  };

  handleScroll = (values) => {
    if (!this.state.focus || !this.props.messages.length || !this.props.messages[0].items.length) {
      return;
    }

    if (values.top >= 1) {
      if (!this.state.scrollBottom) {
        this.setState({ scrollBottom: true });
        this.props.onReadAll();
        this.props.actions.chatSetIdle(false);
        if (process.env.NODE_ENV !== 'production') {
          // console.log('Read all messages');
        }
      }
    } else {
      if (this.state.scrollBottom) {
        this.setState({ scrollBottom: false });
        this.props.actions.chatSetIdle(true);
      }
    }

    if (values.top <= 0 && this.state.hasMore) {
      this.loadMoreMessages();
    }
  };

  loadMoreMessages = () => {
    if (!this.state.loading) {
      this.setState({ loading: true }, () => {
        const height = this.scrollbar.getScrollHeight();
        const latestMessage =
          this.props.messages[0] && this.props.messages[0].items[0]
            ? this.props.messages[0].items[0]
            : null;
        const loadnext = latestMessage ? latestMessage.tinNhanId : null;
        this.props.actions.chatFetchMessages(loadnext).then((res) => {
          if (!loadnext) {
            this.setState({ hasMore: countMessages(this.props.messages) >= 20 });
          }

          if (
            !res.payload ||
            !res.payload.data ||
            !res.payload.data.result ||
            !res.payload.data.result.result ||
            !res.payload.data.result.result.items ||
            !res.payload.data.result.result.items.length
          ) {
            this.setState({ hasMore: false });
          }

          this.scrollbar.scrollTop(this.scrollbar.getScrollHeight() - height);

          this.setState({ loading: false });
        });
      });
    }
  };

  handleCheckDelete(e, message) {
    const { name, checked } = e.target;
    this.setState({
      listDeleteMessages: {
        ...this.state.listDeleteMessages,
        [name]: checked ? message : false,
      },
    });
  }

  handleDeleteMessages() {
    let messages = [];

    for (var message in this.state.listDeleteMessages) {
      if (this.state.listDeleteMessages[message]) {
        messages.push(this.state.listDeleteMessages[message]);
      }
    }

    if (messages.length === 0) {
      return this.setState({ isDelete: false });
    }

    this.props.actions.chatRemoveMultipleMessages(messages).then((res) => {
      if (res.error) {
        return this.toaster.show({
          message: 'Có lỗi xảy ra, vui lòng thử lại',
          intent: Intent.DANGER,
        });
      }

      this.setState({
        isDelete: false,
        listDeleteMessages: {},
      });
      this.toaster.show({ message: 'Xóa tin nhắn thành công', intent: Intent.SUCCESS });
    });
  }

  handleCancelDelete = () => {
    this.setState({
      isDelete: false,
      listDeleteMessages: {},
    });
  };

  loadToBottom = () => {
    this.scrollbar.scrollToBottom();
  };

  getFordwardMessage = (str) => {
    if (str && str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[1];
    } else {
      return '';
    }
  };

  changeEmoticonMessager = (data) => {
    try {
      this.props.actions.putEmoticons(data.tinNhanId, data).then((res) => {
        if (get(res, 'error')) {
          this.toaster.show({
            message: variables.MESSAGE_STATUS.TOATS_MESSAGE_ERROR,
            intent: Intent.WARNING,
          });
        }
      });
    } catch (error) {
      this.toaster.show({
        message: variables.MESSAGE_STATUS.TOATS_MESSAGE_ERROR,
        intent: Intent.WARNING,
      });
    }
  };

  render() {
    return (
      <div className="cpc-chat-messages">
        <Scrollbars
          style={{ width: '100%', height: '100%' }}
          ref={this.refHandlers.scrollbar}
          onScrollFrame={this.handleScroll}
          renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
        >
          <div className="cpc-chat-messages-scrollbar">
            {this.state.hasMore && (
              <div className="spinner">
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
            )}
            {this.props.messages.map((dateGroup, indexGroup) => (
              <div key={indexGroup} className="cpc-chat-date-group">
                <div className="cpc-chat-date">
                  <div className="cpc-chat-date-label">
                    <FormattedDate value={dateGroup.date} />
                  </div>
                  <div className="cpc-chat-date-line" />
                </div>
                <div className="cpc-chat-messages-list">
                  {dateGroup.items
                    .filter((v, i, arr) => arr.findIndex((t) => t.tinNhanId === v.tinNhanId) === i)
                    .map((message, index) => {
                      let messageForward = null;
                      if (message?.noiDung && this.getFordwardMessage(message.noiDung)) {
                        messageForward = JSON.parse(this.getFordwardMessage(message.noiDung));
                        messageForward = camelizeKeys(messageForward);
                      }

                      return (
                        <MessageItem
                          key={index}
                          auth={this.props.auth}
                          news={
                            (indexGroup === this.props.messages.length - 1) &
                            (dateGroup.items.length - this.state.news === i)
                              ? this.state.news
                              : 0
                          }
                          message={message}
                          forwardMessage={messageForward}
                          actions={this.props.actions}
                          receivers={this.props.receivers}
                          onForward={this.props.onForward}
                          onCopyAndForward={this.props.onCopyAndForward}
                          onRemove={this.handleRemoveMessage}
                          onRemoveAll={this.handleRemoveAllMessage}
                          onLoadReceiverUsers={this.props.onLoadReceiverUsers}
                          onCheckDelete={this.handleCheckDelete}
                          isDelete={this.state.isDelete}
                          checked={this.state.listDeleteMessages[message.tinNhanId]}
                          onSaveDocument={this.props.onSaveDocument}
                          importantMessage={
                            this.props.importantMessageList &&
                            this.props.importantMessageList[message.tinNhanId] !== undefined
                              ? this.props.importantMessageList[message.tinNhanId]
                              : undefined
                          }
                          funcChangeEmoticon={this.changeEmoticonMessager}
                        />
                      );
                    })}
                </div>
              </div>
            ))}
          </div>
        </Scrollbars>

        {this.state.news > 0 && this.scrollbar && this.scrollbar.getValues().top < 0.9 && (
          <div className="message-new">
            <span onClick={this.loadToBottom}>Bạn có {this.state.news} tin nhắn mới</span>
          </div>
        )}

        <Dialog
          className="cpc-delete-message-dialog"
          isOpen={this.state.isOpenRemoveMessage}
          onClose={this.handleCloseDialogRemoveMessage}
          title="Xóa tin nhắn"
        >
          <form onSubmit={this.handleSubmitRemoveMessage}>
            <div className="pt-dialog-body">
              <div className="pt-form-group">Bạn muốn xóa tin nhắn?</div>
              {this.state.error && (
                <div className="pt-input-helper-text error">{this.state.error}</div>
              )}
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                <Button
                  className="pt-large"
                  rightIconName="cross"
                  text="Đóng"
                  onClick={this.handleCloseDialogRemoveMessage}
                />
                <Button
                  className="pt-large"
                  rightIconName="trash"
                  type="submit"
                  intent={Intent.PRIMARY}
                  text="Xóa"
                />
              </div>
            </div>
          </form>
        </Dialog>

        <Dialog
          className="cpc-delete-message-dialog"
          isOpen={this.state.isOpenRemoveAllMessage}
          onClose={this.handleCloseDialogRemoveAllMessage}
          title="Xóa tất cả tin nhắn"
        >
          <form onSubmit={this.handleSubmitRemoveAllMessage}>
            <div className="pt-dialog-body">
              <div className="pt-form-group">Bạn muốn xóa tất cả tin nhắn?</div>
              {this.state.error && (
                <div className="pt-input-helper-text error">{this.state.error}</div>
              )}
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                <Button
                  className="pt-large"
                  rightIconName="cross"
                  text="Đóng"
                  onClick={this.handleCloseDialogRemoveAllMessage}
                />
                <Button
                  className="pt-large"
                  rightIconName="trash"
                  type="submit"
                  intent={Intent.PRIMARY}
                  text="Xóa tất cả"
                />
              </div>
            </div>
          </form>
        </Dialog>
        {this.state.isDelete && (
          <div className="cpc-delete-button">
            <button
              type="button"
              className="pt-button pt-icon-cross"
              onClick={this.handleCancelDelete}
            >
              Hủy
            </button>
            <button
              type="button"
              className="pt-button pt-icon-trash pt-intent-primary"
              onClick={this.handleDeleteMessages}
            >
              Xóa
            </button>
          </div>
        )}
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  importantMessageList: state.chat.importantMessageList,
});

export default connect(mapStateToProps)(Message);
