const COLORS = {
  text: '#222222',
  blue: '#1552dc',
  disabled: '#f2f2f2',
  hover: '#dfe2e7',
  border: '#eaeef4',
  gray: '#828282',
  gray1: '#eaeef4',
  green: '#43c300',
  red: '#E80808',
  white: '#ffffff',
  orange: '#FF951A',
  time: '#7589A3',
};

export default COLORS;
