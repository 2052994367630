import CPCDatePicker from '@/components/atoms/datepicker';
import classNames from 'classnames';
import CPCInput from '@/components/atoms/input';
import CPCSelect from '@/components/atoms/select';
import DropdownSelect from '@/components/atoms/dropdown-select';
import { FormGroup as FormGroupBL } from '@blueprintjs/core';
import { useMemo } from 'react';
import './index.scss';
import { Select } from '@/components/common';

function FormGroup(props) {
  const { inputProps, kind = 'input', ...rest } = props;

  const input = useMemo(() => {
    switch (kind) {
      case 'input':
        return <CPCInput {...inputProps} />;

      case 'date':
        return <CPCDatePicker {...inputProps} />;

      case 'select':
        return <CPCSelect {...inputProps} />;

      case 'select2':
        return <DropdownSelect {...inputProps} />;

      case 'treeSelect':
        return <Select {...inputProps} />;

      default:
        return <CPCInput {...inputProps} />;
    }
  }, [kind, inputProps]);

  return (
    <FormGroupBL
      className={classNames('cpc-form-group', {
        [`${rest.classNameGroup}`]: rest.classNameGroup,
        [`theme-${rest.themeGroup}`]: rest.themeGroup,
      })}
      {...rest}
    >
      {input}
    </FormGroupBL>
  );
}

export default FormGroup;
