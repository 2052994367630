import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateInput } from '@blueprintjs/datetime';

class InputDate extends Component {
  componentDidMount() {
    this.inputRef.addEventListener('keydown', (e) => {
      e.preventDefault();
    });
  }

  handleChangeDate = (name, e) => {
    if (!name && !e) {
      return;
    }

    this.props.onChange && this.props.onChange(name, e);
  };

  handleDeleteDate = (name) => {
    if (!name) {
      return;
    }

    this.props.onChange && this.props.onChange(name, undefined);
  };

  render() {
    return (
      <div className="dateInput-common">
        <DateInput
          formatDate={(date) => date.toLocaleString()}
          value={this.props.defaultValue || undefined}
          defaultValue={undefined}
          minDate={this.props.minDate || undefined}
          maxDate={this.props.maxDate || undefined}
          closeOnSelection={true}
          format={this.props.format}
          fill={true}
          placeholder={this.props.placeholder}
          outOfRangeMessage={this.props.placeholder}
          invalidDateMessage={this.props.placeholder}
          canClearSelection={true}
          inputProps={{
            inputRef: (el) => (this.inputRef = el),
          }}
          popoverProps={{
            inline: false,
          }}
          onChange={(e) => this.handleChangeDate(this.props.name, e)}
        />
        {this.props.defaultValue && (
          <span
            className="close icon-close-cicle-line"
            onClick={() => this.handleDeleteDate(this.props.name)}
          />
        )}
      </div>
    );
  }
}

InputDate.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
  format: 'DD/MM/YYYY',
  placeholder: 'From date',
  name: 'fromDate',
};

InputDate.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default InputDate;
