const VERSION = process.env.REACT_APP_VERSION || '7.0.0';
const LIMIT_UPLOAD_SIZE = 25;
const LIMIT_SIZE_FILE_5MB = 5;

let FILE_SERVER_URL;
let AUTH_API_URL;
let CHAT_API_URL;
let CHAT_FE_TITLE;
let UPLOAD_API_URL;
let FE_URL;
let IMAGE_URL;
let UPDATER_API;
let FE_API_URL;
let SURVEY_API_URL;
let MAINTENANCE_URL;
let API_XAC_THUC_DANG_NHAP;
let GOOGLE_DOMAIN;
let SITE_KEY;
let SECRET_KEY;
let PUSHEOFFICE_URL;
let FE_CHAT_URL;
let DOFFICE_URL;

switch (process.env.REACT_APP_BUILD_ENV) {
  case 'eOffice-LIVE':
    AUTH_API_URL = 'https://sso.eoffice.greenglobal.vn';
    CHAT_API_URL = 'https://api-chat-test1.eoffice.greenglobal.vn';
    CHAT_FE_TITLE = 'CPC-eOffice Chat';
    UPLOAD_API_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    FE_URL = 'https://test1.eoffice.greenglobal.vn';
    IMAGE_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    UPDATER_API = 'https://eoffice-update.cpc.vn';
    FE_API_URL = 'https://api-test1.eoffice.greenglobal.vn';
    SURVEY_API_URL = 'https://api-survey.eoffice.greenglobal.vn';
    MAINTENANCE_URL = 'https://cpc-eoffice.firebaseio.com/system_infos/maintenance_mode.json';
    API_XAC_THUC_DANG_NHAP = 'https://:444/service.asmx/EO_GuiTinEOfficeV1';
    PUSHEOFFICE_URL='https://api-push-test1.eoffice.greenglobal.vn';
    FILE_SERVER_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    FE_CHAT_URL = 'https://chat-test1.eoffice.greenglobal.vn';
    DOFFICE_URL = 'https://doffice.cpc.vn';
    break;
  case 'eOffice-STAGING':
    AUTH_API_URL = 'https://autheoffice-staging.cpc.vn';
    CHAT_API_URL = 'https://api-chat-staging.cpc.vn';
    UPLOAD_API_URL = 'https://fileeoffice-staging.cpc.vn';
    FE_URL = 'https://eoffice-staging.cpc.vn';
    IMAGE_URL = 'https://image-eoffice-staging.cpc.vn';
    UPDATER_API = 'https://eoffice-update.cpc.vn';
    FE_API_URL = 'https://api-eoffice-staging.cpc.vn';
    SURVEY_API_URL = 'https://api-chat-staging.cpc.vn';
    MAINTENANCE_URL = 'https://cpc-eoffice.firebaseio.com/system_infos/maintenance_mode.json';
    API_XAC_THUC_DANG_NHAP = 'https://:444/service.asmx/EO_GuiTinEOffice_stagingV1';
    PUSHEOFFICE_URL='https://api-push-test1.eoffice.greenglobal.vn';
    FILE_SERVER_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    FE_CHAT_URL = 'https://chat-test1.eoffice.greenglobal.vn';
    CHAT_FE_TITLE = 'CPC-eOffice Chat';
    DOFFICE_URL = 'https://doffice.cpc.vn';
    break;
  default:
    AUTH_API_URL = 'https://sso.eoffice.greenglobal.vn';
    CHAT_API_URL = 'https://api-chat-test1.eoffice.greenglobal.vn';
    UPLOAD_API_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    FE_URL = 'https://test1.eoffice.greenglobal.vn';
    IMAGE_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    UPDATER_API = 'https://eoffice-update.cpc.vn';
    FE_API_URL = 'https://api-test1.eoffice.greenglobal.vn';
    SURVEY_API_URL = 'https://api-survey.eoffice.greenglobal.vn';
    MAINTENANCE_URL = 'https://cpc-eoffice.firebaseio.com/system_infos/maintenance_mode.json';
    API_XAC_THUC_DANG_NHAP = '';
    PUSHEOFFICE_URL='https://api-push-test1.eoffice.greenglobal.vn';
    FILE_SERVER_URL = 'https://api-file-test1.eoffice.greenglobal.vn';
    GOOGLE_DOMAIN = 'https://www.google.com/recaptcha/api/siteverify';
    SITE_KEY = '6LcGoNsaAAAAAKqO0JUCMNqjaIlyDITe480pVgZG';
    SECRET_KEY = '6LcGoNsaAAAAAEwWdV1nI0T4raTguPmr87AWchgy';
    FE_CHAT_URL = 'https://chat-test1.eoffice.greenglobal.vn';
    CHAT_FE_TITLE = 'CPC-eOffice Chat';
    DOFFICE_URL = 'https://doffice.cpc.vn';
}

export {
  VERSION,
  LIMIT_UPLOAD_SIZE,
  AUTH_API_URL,
  CHAT_API_URL,
  UPLOAD_API_URL,
  FE_URL,
  IMAGE_URL,
  UPDATER_API,
  FE_API_URL,
  SURVEY_API_URL,
  MAINTENANCE_URL,
  LIMIT_SIZE_FILE_5MB,
  API_XAC_THUC_DANG_NHAP,
  GOOGLE_DOMAIN,
  SITE_KEY,
  SECRET_KEY,
  PUSHEOFFICE_URL,
  FE_CHAT_URL,
  CHAT_FE_TITLE,
  DOFFICE_URL
}
