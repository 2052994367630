import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Button } from '../../components/common';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { variables } from '../../constants/variables';
import { Intent } from '@blueprintjs/core';

class FeedbackHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id || undefined,
      isLoading: false,
      isDisabled: false,
      trangThaiId: _.get(this.props.data, 'trangThai.trangThaiId') || undefined,
      noiDungXuLy: _.get(this.props.data, 'noiDungXuLy') || '',
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      trangThaiId: _.get(props.data, 'trangThai.trangThaiId') || undefined,
      noiDungXuLy: _.get(props.data, 'noiDungXuLy') || '',
    });
  }

  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    if (!this.state.id) {
      return;
    }

    let params = {
      id: this.state.id,
      noi_dung_xu_ly: _.trim(this.state.noiDungXuLy),
      trang_thai_id: this.state.trangThaiId,
    };
    this.setState(
      {
        isLoading: true,
        isDisabled: true,
      },
      () => {
        this.props.actions.feedbackXuLy(params).then((res) => {
          this.setState({
            isLoading: false,
            isDisabled: false,
          });
          if (res && res.error && res.error.response && res.error.response.status !== 200) {
            return this.props.actions.commonAddToasterMessage({
              message:
                (res && res.error.response.data.message) ||
                variables.MESSAGE_STATUS.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            });
          } else {
            this.props.reloadDetail && this.props.reloadDetail();
            this.props.actions.commonAddToasterMessage({
              message: variables.MESSAGE_STATUS.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            });
            return this.goBack();
          }
        });
      }
    );
  };

  goBack = () => {
    return this.props.history.push('/hop-thu-gop-y');
  };

  render() {
    return (
      <div className="cpc-panel mb-60">
        <div className="panel-title">
          <span className="icon-writing1601" />
          <h3 className="title">XỬ LÝ GÓP Ý</h3>
        </div>
        <div className="panel-body">
          <div className="feedback-form">
            <div className="row">
              <div className="col-lg-6 col-xs-6">
                <div className="form-group-control">
                  <label>Trạng thái xử lý</label>
                  <Select
                    name="trangThaiId"
                    data={this.props.trangThai}
                    model={['trangThaiId', 'tenTrangThai']}
                    onChange={this.handleChange}
                    value={this.state.trangThaiId}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xs-12">
                <div className="form-group-control">
                  <label>Nội dung xử lý</label>
                  <textarea
                    rows="3"
                    name="noiDungXuLy"
                    placeholder="Nhập nội dung"
                    value={this.state.noiDungXuLy}
                    onChange={this.handleChange}
                    maxLength={500}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cpc-form-footer">
            <Button
              className="cpc-form-footer__button"
              title="Gửi"
              loading={this.state.isLoading}
              disabled={this.state.isDisabled}
              clickButton={this.handleSubmit}
              insideReverse={true}
              style={{ minWidth: '85px', textTransform: 'uppercase' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

FeedbackHandle.propTypes = {
  auth: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  trangThai: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackHandle);
