import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MessageItemReceiver from './MessageItemReceiver';
import MessageItemAttachment from './MessageItemAttachment';
import MessageItemContextMenu from './MessageItemContextMenu';
import emojione from 'emojione';
import { ContextMenu, Tooltip, Position } from '@blueprintjs/core';
import { FormattedDate, FormattedTime } from 'react-intl';
import MessageItemSurvey from './MessageItemSurvey';
import './assets/search-item.scss';
import { Intent, Dialog, Button } from '@blueprintjs/core';
import { Utils } from '../../helpers';
import classnames from 'classnames';
import FileDownload from 'react-file-download';
import moment from 'moment';
import _ from 'lodash';
import ForwardMessage from './ForwardMessage';

import DownloadAllButton from './DownloadAllButton';
import { getContent } from '@/helpers/chat';

const ANSWER = 1;
const FOLLOW = 2;
const READ = 3;

export default class SearchItem extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    receivers: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    onForwardMessage: PropTypes.func,
    onRemoveMessage: PropTypes.func,
    onSaveDocument: PropTypes.func,
    onCopyAndForward: PropTypes.func,
  };

  static defaultProps = {
    onForwardMessage: () => {},
    onRemoveMessage: () => {},
    onSaveDocument: () => {},
    onCopyAndForward: () => {},
  };

  constructor(props) {
    super(props);
    this.handleReply = this.handleReply.bind(this);
    this.handleReplyAll = this.handleReplyAll.bind(this);
    this.handleShowContextMenu = this.handleShowContextMenu.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.markImportantMessage = this.markImportantMessage.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);
  }

  state = {
    isContextMenuOpen: false,
    classSmsId: `sms-${this.props.message.tinNhanId}`,
  };

  departmentName = () => {
    if (this.isOwner()) {
      return;
    }

    if (!this.props.message.sender.dsChucDanh || !this.props.message.sender.dsChucDanh.length) {
      return '';
    }

    const mainUnit = this.props.message.sender.dsChucDanh.find((unit) => unit.phongBanChinh);

    if (!mainUnit || !mainUnit.phongBan) {
      return '';
    }

    let unitName = '';
    if (
      mainUnit.donVi &&
      mainUnit.donVi.donViId !== this.props.auth.mainUnitId &&
      mainUnit.donVi.tenVietTat
    ) {
      unitName = ' - ' + mainUnit.donVi.tenVietTat;
    }

    return mainUnit.phongBan.maPhongBan + unitName;
  };

  handleReply = () => {
    this.props.actions.chatClearReceiver(this.props.receivers);

    if (!this.props.message.sender) {
      return;
    }

    this.props.actions.chatAddReceiver(this.props.message.sender.nhanVienId);

    this.props.actions.chatSetConversation(null);
  };

  handleReplyAll = async () => {
    this.props.actions.chatClearReceiver(this.props.receivers);

    await this.props.actions.getReceiverUserWithConversationId(
      this.props.message.tinNhanId,
      this.props.message.hoiThoaiId,
      this.props.message.hoiThoai.tongSoNhanVien
    );

    if (!this.props.message.nhanVienHoiThoai || !this.props.message.nhanVienHoiThoai.length) {
      return;
    }

    const getUsers = (users) => {
      if (users.length === 1) {
        return users;
      }

      return users.filter(
        (user) => this.props.auth.user && this.props.auth.user.nhanVienId !== user.nhanVienId
      );
    };

    this.props.actions.chatAddMultipleReceivers(getUsers(this.props.message.nhanVienHoiThoai));

    if (!this.props.message.hoiThoaiId) {
      this.props.actions.chatSetConversation(null);
    } else {
      this.props.actions.chatSetConversation(this.props.message.hoiThoaiId);
    }
  };

  handleOpen() {
    this.setState({ onLoadReceiverUsers: true });
    this.props.actions
      .getReceiverUserWithConversationId(
        this.props.message.tinNhanId,
        this.props.message.hoiThoaiId,
        this.props.message.hoiThoai.tongSoNhanVien,
        false
      )
      .then(() => {
        this.setState({ onLoadReceiverUsers: false });
      });
  }

  handleSurveyClick = (survey) => {
    return this.props.actions.surveyCheckPermission(survey.phieuKhaoSatId).then((res) => {
      if (res.error) {
        return (
          this.toaster &&
          this.toaster.show({
            message: 'Kiểm tra phiếu khảo sát không thành công.',
            intent: Intent.WARNING,
          })
        );
      }

      const { result = {} } = res.payload.data;
      const role = result.dsQuyen;
      Utils.saveData(`RoleSurveys`, { role });
      if (result.dsQuyen && result.dsQuyen.indexOf(ANSWER) >= 0) {
        if (result.daHoanThanh) {
          return READ;
        }

        return ANSWER;
      }

      if (result.dsQuyen && result.dsQuyen.indexOf(FOLLOW) >= 0) {
        return FOLLOW;
      }

      return this.setState({ isOpenPopup: true });
    });
  };

  getContent = (str) => {
    if (str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[2];
    } else {
      return str;
    }
  };

  handleShowContextMenu = (e) => {
    e.preventDefault();

    ContextMenu.show(
      <MessageItemContextMenu
        auth={this.props.auth}
        actions={this.props.actions}
        classSmsId={this.state.classSmsId}
        receivers={this.props.receivers}
        message={this.props.message}
        disableRemoveAll={true}
        onForward={this.props.onForwardMessage}
        onCopyAndForward={this.props.onCopyAndForward}
        onRemove={this.props.onRemoveMessage}
        onReply={this.handleReply}
        onReplyAll={this.handleReplyAll}
        isImportantMessage={
          this.props.importantMessage !== undefined
            ? this.props.importantMessage
            : this.props.message.danhDau
        }
        markImportantMessage={this.markImportantMessage}
        moreWidth
        onSaveMessage={this.handleSaveMessage}
        onSaveDocument={this.props.onSaveDocument}
      />,
      { left: e.clientX, top: e.clientY },
      () => this.setState({ isContextMenuOpen: false })
    );

    this.setState({ isContextMenuOpen: true });
  };

  isOwner = () => {
    return (
      this.props.auth.user && this.props.message.nguoiGuiId === this.props.auth.user.nhanVienId
    );
  };

  urlify(text) {
    const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;
    return text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }

  markImportantMessage = () => {
    this.props.actions.markImportantMessage(
      this.props.message.tinNhanId,
      this.props.message.hoiThoaiId,
      this.props.message.danhDau
    );
  };
  handleSaveMessage(e) {
    const { forwardMessage } = this.props;
    const { message } = this.props;
    let forwardMessageContent = '';
    forwardMessage &&
      forwardMessage.forEach((msg) => {
        const text = this.getContextMessage(msg);
        forwardMessageContent += text;
      });
    const text = forwardMessageContent + this.getContextMessage(message);
    var blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    FileDownload(blob, 'tin-nhan.txt');
  }

  getContextMessage = (message) => {
    let receivers = message.nhanVienHoiThoai
      ? message.nhanVienHoiThoai.filter((i) => i.nhanVienId !== message.sender.nhanVienId)
      : null;
    let receiverText = '';
    if (receivers) {
      const maxReceiver = receivers.length <= 3 ? receivers.length : 3;
      for (var i = 0; i < maxReceiver; i++) {
        receiverText = _.trim(`${receiverText}, ${receivers[i].tenNhanVien}`, ', ');
      }
      if (receivers.length > 3) {
        receiverText = `${receiverText}...`;
      }
    }
    const content = message.noiDung.startsWith('[messageForwardJSON]')
      ? getContent(message?.noiDung)
      : message.noiDung;
    const contentClean = content.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
    const sender = message.sender || message.nguoiGui;
    const text = `FW: [From: ${sender.tenNhanVien || ''}${
      receiverText ? ` To: ${receiverText}` : ''
    } ${
      moment(message.ngayTao).isValid() ? moment(message.ngayTao).format('DD/MM/YYYY h:mm') : ''
    }] \r\n${contentClean}\r\n`;
    return text;
  };

  handleClickSaveDocument = (file) => {
    this.props.onSaveDocument && this.props.onSaveDocument([file]);
  };

  render() {
    let { forwardMessage } = this.props;
    return (
      <div className="cpc-search-message-item" onContextMenu={this.handleShowContextMenu}>
        <div className="cpc-item-header">
          <Tooltip
            className="float-left"
            content="Tin nhắn quan trọng"
            position={Position.TOP_LEFT}
          >
            <span
              onClick={this.markImportantMessage}
              className={classnames({
                'icon-star':
                  this.props.importantMessage !== undefined
                    ? this.props.importantMessage
                    : this.props.message.danhDau,
                'icon-star-no-fill': !(this.props.importantMessage !== undefined
                  ? this.props.importantMessage
                  : this.props.message.danhDau),
              })}
            ></span>
          </Tooltip>
          <div className="cpc-sender">
            <h3 onClick={this.handleReply}>{this.props.message.sender.tenNhanVien}</h3>
            <span className="cpc-department-name">{this.departmentName()}</span>
          </div>
          <div className="cpc-receivers">
            <span>To:</span>
            <MessageItemReceiver
              actions={this.props.actions}
              receivers={this.props.message.nhanVienHoiThoai}
              exclude={this.props.message.sender}
              onOpen={this.handleOpen}
              onClick={this.handleReplyAll}
              onLoadReceiverUsers={this.state.onLoadReceiverUsers}
            />
            <Tooltip content="Trả lời" position={Position.TOP}>
              <span className="cpc-actions cpc-icon icon-arrrow-1" onClick={this.handleReply} />
            </Tooltip>
            <Tooltip content="Trả lời tất cả" position={Position.TOP}>
              <span className="cpc-actions cpc-icon icon-arrow-2" onClick={this.handleReplyAll} />
            </Tooltip>
          </div>
        </div>
        {forwardMessage &&
          forwardMessage.map((message) => (
            <ForwardMessage
              key={message.tinNhanId}
              onSaveDocument={this.props.onSaveDocumen}
              actions={this.props.actions}
              forwardMessage={message}
              auth={this.props.auth}
              isOwner={this.isOwner()}
            />
          ))}
        <div
          className="cpc-item-body"
          id={this.state.classSmsId}
          dangerouslySetInnerHTML={{
            __html: emojione.unicodeToImage(
              this.urlify(this.getContent(this.props.message.noiDung))
            ),
          }}
        />
        {this.props.message.fileDinhKem && this.props.message.fileDinhKem.length ? (
          <MessageItemAttachment
            auth={this.props.auth}
            files={this.props.message.fileDinhKem}
            onSaveDocument={this.handleClickSaveDocument}
          />
        ) : null}

        {_.size(_.get(forwardMessage, '[0].fileDinhKem', [])) +
          _.size(_.get(this.props, 'message.fileDinhKem', [])) !==
          0 && (
          <DownloadAllButton
            accessToken={_.get(this.props, 'auth.token.accessToken')}
            files={[
              ..._.get(forwardMessage, '[0].fileDinhKem', []),
              ..._.get(this.props, 'message.fileDinhKem', []),
            ]}
          />
        )}

        {this.props.message.phieuKhaoSat && this.props.message.phieuKhaoSat.length ? (
          <MessageItemSurvey
            owner={this.isOwner()}
            surveys={this.props.message.phieuKhaoSat}
            onClick={this.handleSurveyClick}
            actions={this.props.actions}
          />
        ) : null}
        <div className="cpc-right-section">
          <div className="cpc-time-delivery">
            <span className="cpc-time">
              <FormattedDate value={this.props.message.ngayTao} />
              &nbsp;
              <FormattedTime value={this.props.message.ngayTao} />
            </span>
          </div>
          <div className="cpc-sms-email">
            {this.props.message.guiKemSms && <span className="cpc-sms">SMS</span>}
            {this.props.message.guiKemEmail && <span className="cpc-icon icon-mail" />}
          </div>
        </div>
        <Dialog
          isOpen={this.state.isOpenPopup}
          onClose={(e) => this.setState({ isOpenPopup: false })}
          className="popup-cpc-confirm"
          title=""
        >
          <div className="pt-dialog-body">
            <span className="icon icon-interactive"></span>
            <p className="message-cofirm">Anh chị không được tương tác phiếu khảo sát này</p>
          </div>
          <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions">
              <Button
                iconName="pt-icon-tick"
                intent={Intent.NORMAL}
                onClick={(e) => this.setState({ isOpenPopup: false })}
                text="Đóng"
                className="btn-confirm-popup"
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
