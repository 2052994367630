import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import PanelUnit from './PanelUnit';
import PanelOtherUnit from './PanelOtherUnit';
import PanelFavourite from './PanelFavourite';
import { Tab, Tabs } from '@blueprintjs/core';
import './assets/section.scss';
const EmptyComponent = class EmptyComponent extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    return <div></div>;
  }
};

export default class Section extends Component {
  state = {
    tab: 'units',
    searchFavourite: '',
    loadUnit: false,
    loadFavourite: false,
    timeClick: new Date().getTime(),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.search.trim() === '') {
        this.searchByUnit();
      }
      if (this.state.searchAll.trim() === '') {
        this.searchAll();
      }
    });
  };

  handleTabChange = (e) => {
    this.setState({
      tab: e,
    });
    if (e === 'all') {
      this.setState({
        loadUnit: true,
        timeClick: new Date().getTime(),
      });
    } else if (e === 'favourites' && !this.state.loadFavourite) {
      this.props.actions.contactFetchFavouriteGroups().then((res) => {
        this.setState({ loadFavourite: true });
      });
    }
  };

  handleClickUnit = (unit) => {
    // this.setState({ loadUnit: false });
    return this.props.actions.contactFetchDepartmentsByUnit(unit.donViId).then(() => {
      // this.setState({ loadUnit: true });
    });
  };

  render() {
    return this.props.auth.user ? (
      <Tabs className="cpc-contact-section" onChange={this.handleTabChange}>
        <Tab
          id="units"
          title="Đơn vị"
          panel={
            this.state.tab === 'units' ? (
              <PanelUnit {...this.props} onClickUnit={this.handleClickUnit} />
            ) : (
              <EmptyComponent />
            )
          }
        />
        <Tab
          id="all"
          title="Đơn vị khác"
          panel={
            this.state.tab === 'all' ? (
              <PanelOtherUnit
                {...this.props}
                loaded={this.state.loadUnit}
                onClickUnit={this.handleClickUnit}
                timeClick={this.state.timeClick}
              />
            ) : (
              <EmptyComponent />
            )
          }
          receivers={this.props.receivers}
        />
        {!this.props.isSurvey && (
          <>
            <Tab
              id="favourites"
              title="Hay liên lạc"
              panel={
                this.state.tab === 'favourites' ? (
                  <PanelFavourite {...this.props} loaded={this.state.loadFavourite} />
                ) : (
                  <EmptyComponent />
                )
              }
            />
            <Tabs.Expander />
          </>
        )}
      </Tabs>
    ) : null;
  }
}

Section.propTypes = {
  receivers: PropTypes.array,
  viewUnit: PropTypes.object,
  contact: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
