import CPCIcon from '@/components/atoms/icon';
import COLORS from '@/constants/colors';
import useOpen from '@/hooks/useOpen';
import { Popover2 } from '@blueprintjs/popover2/lib/esm';
import './actions.scss';
import React from 'react';

function Actions(props) {
  const {
    daGui,
    phieuKhaoSatId,
    handleClickEdit,
    handleClickAdd,
    handleCopyPKS,
    handleClickPopup,
  } = props;
  const { open, handleToggle, handleClose } = useOpen();
  return (
    <Popover2
      enforceFocus={false}
      content={
        <div className="section-box-action">
          <div className="item" onClick={() => handleClickEdit(phieuKhaoSatId)}>
            <CPCIcon icon="edit" size={16} color={COLORS.blue} />
            <span className="name">Sửa phiếu</span>
          </div>
          <div
            className="item"
            onClick={() => {
              handleClose();
              handleClickAdd(phieuKhaoSatId);
            }}
          >
            <CPCIcon icon="share" size={16} color={COLORS.blue} />
            <span className="name">Gửi phiếu</span>
          </div>
          <div className="item" onClick={() => handleCopyPKS(phieuKhaoSatId)}>
            <CPCIcon icon="copy" size={16} color={COLORS.blue} className="icon-copy" />
            <span className="name">Sao chép</span>
          </div>
          <div className="item" onClick={() => handleClickPopup(phieuKhaoSatId)}>
            <CPCIcon icon="delete" size={16} color={COLORS.red} />
            <span className="name delete">Xóa</span>
          </div>
        </div>
      }
      interactionKind={'click'}
      isOpen={open}
      placement={'bottom'}
      onClose={handleClose}
    >
      <CPCIcon icon="3-dot" size={5} onClick={handleToggle} color="#808080" />
    </Popover2>
  );
}

export default React.memo(Actions);
