import { useHover } from '@mantine/hooks';
import { useMemo } from 'react';
import classNames from 'classnames';
import './index.scss';

function IconWrapper(props) {
  const {
    type = 'square',
    color,
    colorHover,
    className,
    onClick = () => {},
    size,
    shadow,
    rotate,
    theme,
    ...rest
  } = props;

  const { hovered, ref } = useHover();

  const hover = useMemo(() => {
    if (colorHover && hovered) {
      return colorHover;
    }

    return color || 'inherit';
  }, [hovered, colorHover, color]);

  return (
    <div
      style={{ backgroundColor: hover }}
      className={classNames('icon-scss', {
        [`${className}`]: className,
        [`${size}`]: size,
        [`${type}`]: type,
        [`shadow-${shadow}`]: shadow,
        [`rotate-${rotate}`]: rotate,
        [`theme-${theme}`]: theme,
      })}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {props.children}
    </div>
  );
}

export default IconWrapper;
