import * as types from '../constants/ActionTypes';

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  toaster: null,
  dialog: null,
  phieuKhaoSatId: null,
  userSurvey: {
    chuaXuLy: { list: [], total: 0 },
    daHoanThanh: { list: [], total: 0 },
    theoDoi: { list: [], total: 0 },
  },
};

export default function surveys(state = initialState, action) {
  switch (action.type) {
    case types.LIST_SURVEYS:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      };

    case types.COMMON_ADD_TOASTER_MESSAGE:
      return {
        ...state,
        toaster: action.data,
      };

    case types.COMMON_REMOVE_TOASTER_MESSAGE:
      return {
        ...state,
        toaster: null,
      };

    case types.COMMON_ADD_DIALOG:
      return {
        ...state,
        dialog: true,
      };

    case types.COMMON_REMOVE_DIALOG:
      return {
        ...state,
        dialog: null,
      };

    case types.GET_SURVEY_ITEM:
      return {
        ...state,
        phieuKhaoSatId: action.payload.data.result ? action.payload.data.result.result : null,
      };

    case types.REMOVE_SURVEY_ITEM:
      return {
        ...state,
        phieuKhaoSatId: null,
      };

    case types.GET_LIST_UERS_SURVEY:
      if (action.payload.config.params.nhom_tham_gia_khao_sat === 0) {
        const listData =
          action.meta.previousAction.page === 1
            ? action.payload.data.result.result.items
            : [...state.userSurvey.chuaXuLy.list, ...action.payload.data.result.result.items];
        return {
          ...state,
          userSurvey: {
            ...state.userSurvey,
            chuaXuLy: {
              list: listData.filter((value, index, self) => self.indexOf(value) === index),
              total: action.payload.data.result.result.total,
            },
          },
        };
      }

      if (action.payload.config.params.nhom_tham_gia_khao_sat === 1) {
        const listData =
          action.meta.previousAction.page === 1
            ? action.payload.data.result.result.items
            : [...state.userSurvey.daHoanThanh.list, ...action.payload.data.result.result.items];
        return {
          ...state,
          userSurvey: {
            ...state.userSurvey,
            daHoanThanh: {
              list: listData.filter((value, index, self) => self.indexOf(value) === index),
              total: action.payload.data.result.result.total,
            },
          },
        };
      }

      if (action.payload.config.params.nhom_tham_gia_khao_sat === 2) {
        const listData =
          action.meta.previousAction.page === 1
            ? action.payload.data.result.result.items
            : [...state.userSurvey.theoDoi.list, ...action.payload.data.result.result.items];
        return {
          ...state,
          userSurvey: {
            ...state.userSurvey,
            theoDoi: {
              list: listData.filter((value, index, self) => self.indexOf(value) === index),
              total: action.payload.data.result.result.total,
            },
          },
        };
      }

      break;

    default:
      return state;
  }
}
