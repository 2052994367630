import { Tooltip2 } from '@blueprintjs/popover2';
import { PopoverPosition, Intent, PopoverInteractionKind } from '@blueprintjs/core';
import classNames from 'classnames';
import './index.scss';

const CPCTooltip = ({
  content,
  position = PopoverPosition.TOP,
  intent = Intent.none,
  isClick = false,
  className,
  theme, // white
  children,
  usePortal = true,
  ...props
}) => {
  return (
    <Tooltip2
      {...props}
      className={classNames('cpc-tooltip-wrapper', { [`${className}`]: className })}
      popoverClassName={classNames('cpc-tooltip', {
        [`theme-${theme}`]: theme,
      })}
      interactionKind={isClick ? PopoverInteractionKind.CLICK : PopoverInteractionKind.HOVER}
      position={position}
      intent={intent}
      usePortal={usePortal}
      content={content}
    >
      {children}
    </Tooltip2>
  );
};

export default CPCTooltip;
