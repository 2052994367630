import { AppToaster } from '@/components/atoms/toaster';
import { LIMIT_UPLOAD_SIZE } from '@/constants/Api';
import { uploader as customUploader } from '@/middleware/uploader';
import { Intent } from '@blueprintjs/core';
import async from 'async';
import { useCallback, useEffect, useRef, useState } from 'react';

function Upload({ actions, onSetHeightMessage }) {
  const [uploader] = useState(customUploader());
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState([]);

  const refProgress = useRef([]);

  const cleanFiles = () => {
    setUploadedFiles([]);
    onSetHeightMessage();
  };

  const handleUploadCancel = useCallback(
    (id) => {
      setUploadedFiles([...uploadedFiles.filter((item) => item.id !== id)]);
      onSetHeightMessage();
    },
    [uploadedFiles, setUploadedFiles]
  );

  const handleUploadComplete = useCallback(
    (id, name, response) => {
      if (response.error || !response.result || !response.result[0]) {
        actions.commonAddToasterMessage({
          message: `Upload file ${name} không thành công`,
          intent: Intent.WARNING,
        });
        return;
      }
      onSetHeightMessage();

      refProgress.current = refProgress.current.filter((o) => o.id !== id);

      setUploadedFiles((prev) => [
        ...prev,
        {
          id: id,
          name: name,
          info: response?.result?.[0],
        },
      ]);
    },
    [uploadedFiles, uploadingProgress, setUploadingProgress, setUploadedFiles]
  );

  const handleUploadProgress = useCallback(
    (id, name, uploadedBytes, totalBytes) => {
      const index = refProgress.current.findIndex((o) => o.id === id);
      if (index !== -1) {
        const temp = [...refProgress.current];
        temp[index] = {
          ...temp[index],
          progress: (uploadedBytes / totalBytes) * 100,
        };
        setUploadingProgress([...temp]);

        refProgress.current = [...temp];
      } else {
        const file = uploader.methods.getFile(id);
        setUploadingProgress([
          ...refProgress.current,
          {
            id,
            progress: (uploadedBytes / totalBytes) * 100,
            src: URL.createObjectURL(file),
            file: file,
          },
        ]);

        refProgress.current = [
          ...refProgress.current,
          {
            id,
            progress: (uploadedBytes / totalBytes) * 100,
            src: URL.createObjectURL(file),
            file: file,
          },
        ];
      }
    },
    [uploadedFiles, uploadingProgress, uploader, setUploadingProgress]
  );

  const handleUploadCompleteAll = useCallback(() => {
    setIsUpload(false);
    refProgress.current = [];
  }, [uploadedFiles]);

  const handleUploadSubmit = useCallback(() => {
    setIsUpload(true);
  }, [uploadedFiles]);

  const handleValidate = useCallback(
    (files) => {
      let totalSize = 0;
      async.mapSeries(
        files,
        (file, next) => {
          totalSize += file.size;
          return next();
        },
        () => {
          const ONE_MB = 1048576; // byte
          if (totalSize > LIMIT_UPLOAD_SIZE * ONE_MB) {
            throw AppToaster.show({
              message: `Dung lượng upload file vượt quá ${LIMIT_UPLOAD_SIZE}MB`,
              intent: Intent.WARNING,
            });
          }
        }
      );
    },
    [uploadedFiles]
  );

  const onEditMessage = () => {
    setUploadedFiles();
  };

  useEffect(() => {
    uploader.on('cancel', handleUploadCancel);
    uploader.on('complete', handleUploadComplete);
    uploader.on('progress', handleUploadProgress);
    uploader.on('allComplete', handleUploadCompleteAll);
    uploader.on('submit', handleUploadSubmit);
    uploader.on('validateBatch', handleValidate);

    return () => {
      uploader.off('cancel');
      uploader.off('complete');
      uploader.off('progress');
      uploader.off('allComplete');
      uploader.off('submit');
      uploader.off('validateBatch');
    };
  }, []);

  useEffect(() => {
    if (refProgress.current) {
      setUploadingProgress(refProgress.current);
    }
  }, [refProgress.current]);

  return {
    handleUploadCancel,
    handleUploadComplete,
    handleUploadProgress,
    handleUploadCompleteAll,
    handleUploadSubmit,
    handleValidate,
    uploader,
    uploadedFiles,
    isUpload,
    cleanFiles,
    setUploadedFiles,
    uploadingProgress,
  };
}

export default Upload;
