import { useMemo } from 'react';
import './index.scss';
import FileItem from './item';
import LoadingFile from './loading-file';

function Files({ uploadedFiles, handleUploadCancel, auth, uploadingProgress, uploader }) {
  const renderFiles = useMemo(() => {
    return (uploadedFiles || []).map((item, index) => (
      <FileItem key={index} item={item} handleUploadCancel={handleUploadCancel} auth={auth} />
    ));
  }, [uploadedFiles]);

  const renderUploadingProgress = useMemo(() => {
    return (uploadingProgress || []).map((item, index) => (
      <LoadingFile key={index} item={item} uploader={uploader} />
    ));
  }, [uploadingProgress]);

  return (
    <div className="files-upload">
      {renderFiles}
      {renderUploadingProgress}
    </div>
  );
}

export default Files;
