import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../contact/Avatar';
import { Link } from 'react-router-dom';
import './assets/header.scss';
import withRouter from 'react-router/withRouter';
import { Overlay, Classes } from '@blueprintjs/core';
import { Birthday } from '../chat';
import Confetti from 'react-dom-confetti';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEqual, intersection } from 'lodash';
import { saveData } from '../../helpers/helper';
import DownloadApp from '../atoms/download-app';

const TONG_CONG_TY_ID = '06914739-d280-e711-80cd-40167e7b9c6a';
const BIRTHDAY_CONFIG_TYPE = {
  TAT_CA: 0,
  DON_VI: 1,
  DANH_SACH: 2,
  TAT_THONG_BA0: 3,
};
const config = {
  spread: '91',
  startVelocity: '68',
  elementCount: '70',
  dragFriction: 0.1,
  duration: '7110',
  delay: '10',
  width: '10px',
  height: '10px',
  colors: ['#f00', '#0f0', '#00f'],
};
class Header extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleClickProfile = this.handleClickProfile.bind(this);
  }

  state = {
    isOpen: false,
    confetti: false,
    isUserBirthDay: false,
    birthdayUserList: this.props.birthdayUserList || [],
    isAnimate: false,
  };

  componentDidMount() {
    this.handleConfigBirthday();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillMount() {
    window.addEventListener('checkReloadPage', this.checkReloadPage);
    this.checkReloadPage();
  }

  checkReloadPage = () => {
    window.onbeforeunload = (e) => {
      console.log(encodeURI);
      saveData && saveData('isReloadPage', true);
    };

    window.onload = (e) => {
      console.log(e);
      saveData && saveData('isReloadPage', false);
    };
  };

  closeDialog = () => {
    this.setState({
      isOpen: false,
      confetti: false,
    });
  };

  openDialog = () => {
    this.setState({
      isOpen: true,
    });
    setTimeout(() => {
      this.setState({
        confetti: true,
      });
    }, 300);
  };

  handleClickProfile = () => {
    this.props.actions.contactShowProfile(this.props.auth.user.nhanVienId);
  };

  handleClickConfig = () => {
    this.props.onOpenConfig && this.props.onOpenConfig();
  };

  handleClickOutside = (event) => {
    if (this.birthDayRef && !this.birthDayRef.contains(event.target)) {
      this.closeDialogWithAnimate();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleConfigBirthday = () => {
    const { config } = this.props.auth;
    switch (config?.loaiThongBaoSinhNhat) {
      case BIRTHDAY_CONFIG_TYPE.DON_VI:
      case null:
        let newBirthdayUserList = [];
        let donViChinh =
          this.props.auth &&
          this.props.auth.mainUnitId &&
          this.props.units[this.props.auth.mainUnitId]
            ? this.props.units[this.props.auth.mainUnitId]
            : null;
        if (donViChinh && this.props.birthdayUserList) {
          this.props.birthdayUserList.forEach((item) => {
            if (
              item.donViId === donViChinh.donViId ||
              (item.donViChaId === donViChinh.donViId && donViChinh.donViId !== TONG_CONG_TY_ID) ||
              (item.donViChaId === donViChinh.donViCha &&
                donViChinh.donViCha !== TONG_CONG_TY_ID) ||
              (item.donViId === donViChinh.donViCha && donViChinh.donViCha !== TONG_CONG_TY_ID)
            ) {
              newBirthdayUserList.push(item);
            }
          });
        }
        this.setState({ birthdayUserList: newBirthdayUserList });
        break;
      case BIRTHDAY_CONFIG_TYPE.DANH_SACH:
        let userEntity = [];
        this.props.birthdayUserList &&
          this.props.birthdayUserList.forEach((user) => (userEntity[user.nhanVienId] = user));
        let birthdayUserIdList = this.props.birthdayUserList
          ? this.props.birthdayUserList.map((user) => user.nhanVienId)
          : [];
        let configUserIdList =
          config.dsNguoiNhanThongBaoSinhNhat && config.dsNguoiNhanThongBaoSinhNhat.length > 0
            ? config.dsNguoiNhanThongBaoSinhNhat.map((user) => user.nhanVienId)
            : [];
        let newBirthdayUserIdList = intersection(birthdayUserIdList, configUserIdList);
        this.setState({
          birthdayUserList: newBirthdayUserIdList.map((nhanVienId) => userEntity[nhanVienId]),
        });
        break;
      case BIRTHDAY_CONFIG_TYPE.TAT_THONG_BA0:
        this.setState({
          birthdayUserList: [],
        });
        break;
      default:
        this.setState({
          birthdayUserList: this.props.birthdayUserList,
        });
        break;
    }
  };

  animteBirthday = () => {
    setTimeout(() => {
      this.setState({
        isAnimate: true,
      });
    }, 150);
  };

  closeDialogWithAnimate = async () => {
    await this.setState({
      isAnimate: false,
    });
    this.closeDialog();
  };

  getBirthdayRef = (el) => (this.birthDayRef = el);

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !isEqual(this.props.auth, prevProps.auth) ||
      !isEqual(prevProps.birthdayUserList, this.props.birthdayUserList) ||
      !isEqual(prevProps.units, this.props.units)
    ) {
      this.handleConfigBirthday();
    }
    if (
      this.props.birthdayUserList &&
      this.props.birthdayUserList.length > 0 &&
      this.props.auth &&
      this.props.auth.user &&
      !this.state.isUserBirthDay &&
      !this.state.isCanShowBirthDay
    ) {
      if (
        this.props.birthdayUserList.findIndex(
          (item) => item.nhanVienId === this.props.auth.user.nhanVienId
        ) !== -1
      ) {
        setTimeout(() => {
          this.setState({
            isUserBirthDay: true,
            isCanShowBirthDay: true,
          });
        }, 2000);
      } else {
        this.setState({
          isCanShowBirthDay: true,
        });
      }
    }
    if (
      !prevState.isUserBirthDay &&
      this.state.isUserBirthDay &&
      this.props.auth &&
      this.props.auth.user
    ) {
      let openedDialog = localStorage.getItem(
        `openedDialog-${this.props.auth.user.nhanVienId}-${moment().year()}`
      );
      if (!openedDialog) {
        this.openDialog();
        localStorage.setItem(
          `openedDialog-${this.props.auth.user.nhanVienId}-${moment().year()}`,
          true
        );
      }
    }
  };

  render() {
    return (
      <nav className="pt-navbar cpc-navbar">
        <div className="pt-navbar-group pt-align-left">
          <div className="pt-navbar-heading cpc-heading">
            <h1 className="cpc-logo-full">
              <Link to="/" className="cpc-logo" />
            </h1>
          </div>
        </div>
        {this.props.auth.user && (
          <div className="pt-navbar-group pt-align-right">
            <div className="cpc-user-info">
              <Avatar
                user={this.props.auth.user}
                actions={this.props.actions}
                className="cpc-user-avatar-container"
              />
              <span className="use-name">{this.props.auth.user.tenNhanVien}</span>
              <span className="pt-icon-chevron-down" />
              <div className="cpc-menu">
                <div className="header-user-dropdown">
                  <ul className="header-user-info-content">
                    <li className="menu-item">
                      <Link
                        to="/"
                        onClick={this.handleClickProfile}
                        className="text-link usermenu-link"
                      >
                        <span className="left-icon">
                          <span className="cpc icon-profile"></span>
                        </span>
                        <span className="text-content">Thông tin cá nhân</span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link
                        to="/"
                        onClick={this.handleClickConfig}
                        className="text-link usermenu-link"
                      >
                        <span className="left-icon icon-settings-2"></span>
                        <span className="text-content">Cài đặt</span>
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link
                        to="/"
                        onClick={this.props.actions.authLogout}
                        className="text-link usermenu-link"
                      >
                        <span className="left-icon">
                          <span className="cpc icon-logout"></span>
                        </span>
                        <span className="text-content">Đăng xuất</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.props.location &&
          this.props.location.pathname.indexOf('phieu-khao-sat') !== 1 &&
          !this.props.hideSearch && (
            <button className="btn-search-toggle" onClick={this.props.onClickSearch}>
              <span className="cpc-icon icon-search" />
            </button>
          )}
        {this.props.location &&
          this.props.location.pathname.indexOf('phieu-khao-sat') !== 1 &&
          this.props.location.pathname.indexOf('hop-thu-gop-y/thong-ke') !== 1 &&
          this.props.location.pathname.indexOf('hop-thu-gop-y/danh-sach-gop-y') !== 1 &&
          this.props.location.pathname.indexOf('hop-thu-gop-y/tao-cau-hoi') !== 1 &&
          this.props.location.pathname.indexOf('hop-thu-gop-y/gui-gop-y') !== 1 &&
          this.props.location.pathname.indexOf('hop-thu-gop-y/chi-tiet/') !== 1 &&
          this.state.birthdayUserList &&
          this.state.birthdayUserList.length > 0 &&
          this.state.isCanShowBirthDay && (
            <button
              className="btn-search-toggle p5 position-relative hover-scale"
              onClick={this.openDialog}
            >
              <span className="cpc-icon icon-gift font-size-25"></span>
              <span className="badge">{this.state.birthdayUserList.length}</span>
            </button>
          )}
        <button className="button-link-eoffice" onClick={this.props.onOpenEoffice}>
          <span className="cpc-icon icon-note" />
          <span className="text">Đến Công Văn</span>
        </button>

        <DownloadApp type="ios" />
        <DownloadApp type="window" />

        <Overlay
          transitionDuration={300}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          isOpen={this.state.isOpen}
        >
          <div className="d-flex w-100 h-100 middle-xs">
            <Birthday
              getBirthdayRef={this.getBirthdayRef}
              animteBirthday={this.animteBirthday}
              birthdayUserList={this.state.birthdayUserList}
              unit={this.props.units}
              auth={this.props.auth}
              receivers={this.props.receivers}
              closeDialog={this.closeDialogWithAnimate}
              isUserBirthDay={this.state.isUserBirthDay}
              isAnimate={this.state.isAnimate}
            />
          </div>
          <div className="position-absolute w-100 h-100 overflow-hidden pointer-event-none">
            <div className="position-absolute left-0 z-index-1">
              <Confetti
                active={this.state.confetti && this.state.isUserBirthDay}
                config={{ ...config, angle: 345 }}
              />
            </div>
            <div className="position-absolute right-0 z-index-1">
              <Confetti
                active={this.state.confetti && this.state.isUserBirthDay}
                config={{ ...config, angle: 195 }}
              />
            </div>
          </div>
        </Overlay>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  birthdayUserList: state.chat.birthdayUserList,
  units: state.entities.units,
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

export default withRouter(connect(mapStateToProps)(Header));
