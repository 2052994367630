import { GET_CONFIGURATION, SET_SIDEBAR, SET_PAGE_VISIBILITY } from '@/constants/ActionTypes';

const initialState = {
  configurations: {
    limitMember: 1200,
  },
  sidebarActive: null,
  isPageVisibility: true,
};

export default function configuration(state = initialState, action) {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case GET_CONFIGURATION:
      return {
        ...state,
        configurations: {
          limitMember: payload?.data?.limitMember,
          allowLeftGroup: payload?.data?.allowLeftGroup,
          minuteAllowEvict: payload?.data?.minuteAllowEvict,
          minuteAllowEdit: payload?.data?.minuteAllowEdit,
        },
      };
    case SET_SIDEBAR:
      return {
        ...state,
        sidebarActive: payload,
      };
    case SET_PAGE_VISIBILITY:
      return {
        ...state,
        isPageVisibility: payload,
      };
    default:
      return state;
  }
}
