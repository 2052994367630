import CPCButton from '@/components/atoms/button';
import { Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';

function PopupLogout(props) {
  const { open, handleClose, actions } = props;

  return (
    <Dialog isOpen={open} onClose={handleClose} className="popup-cpc-confirm" title="Đăng xuất">
      <DialogBody>
        <p>Anh chị muốn đăng xuất?</p>
      </DialogBody>
      <DialogFooter>
        <div className="footer-right">
          <CPCButton className="cpc-info" text="Không" onClick={handleClose} />
          <CPCButton
            intent={Intent.PRIMARY}
            onClick={actions.authLogout}
            text="Đăng xuất"
            className="cpc-danger"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default PopupLogout;
