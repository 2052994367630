import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Search, Table } from './components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '@/actions';
import { Position } from '@blueprintjs/core';
import '../assets/ListSurvey.scss';
import { FE_URL } from '@/constants/Api';
import classnames from 'classnames';
import LayoutMain from '@/components/organisms/layout-main';
import CPCIcon from '@/components/atoms/icon';
import IconWrapper from '@/components/molecules/icon-wrapper';
import variables from '@/constants/variables';
import './index.scss';
import Scrollbars from 'react-custom-scrollbars';
import CPCTooltip from '@/components/atoms/tooltip';
import moment from 'moment';
import { getData, preData } from '@/helpers/helper';

class ListSurveyPage extends Component {
  constructor(props) {
    super(props);

    this.handleGoBack = this.handleGoBack.bind(this);
    this.clickPagination = this.clickPagination.bind(this);
    this.submit = this.submit.bind(this);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.toggleSearchMessage = this.toggleSearchMessage.bind(this);
    this.handleOpenEoffice = this.handleOpenEoffice.bind(this);
    this.handleToggleConfig = this.handleToggleConfig.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
  };
  prevSearch = getData('survey');
  state = {
    page: this.prevSearch?.page || 1,
    isError: false,
    isEmpty: false,
    items: [],
    isLoading: true,
    buttonbackFixTop: false,
    tuNgay: this.prevSearch?.tuNgay
      ? moment(this.prevSearch?.tuNgay)?.toDate()
      : moment().subtract(1, 'months').toDate(),
    denNgay: this.prevSearch?.tuNgay
      ? moment(this.prevSearch?.denNgay)?.toDate()
      : moment().toDate(),
    tieuDe: this.prevSearch?.tieuDe || '',
    conversationId: this.props.match.params.conversationId,
  };

  componentDidMount() {
    let state = this.state;
    this.setState({ ...state }, () => {
      this.clickPagination({ selected: state.page - 1, isLoading: true });
    });
    this.props.actions.getConfigurationInfos();
  }

  clickPagination(e) {
    let state = {
      page: e.selected + 1,
    };

    this.setState(state, () => {
      this.submit(this.state.page);
    });
  }

  handleChangeFilter(field, value) {
    let state = {
      [field]: value,
    };
    this.setState(state);
  }

  handleResetFilter() {
    this.setState({
      tieuDe: '',
      tuNgay: moment().subtract(1, 'months').toDate(),
      denNgay: moment().toDate(),
    });
  }

  submit(page = 1) {
    const { tuNgay, denNgay, tieuDe } = this.state;
    this.setState({ page, isLoading: true }, () => {
      preData('survey', this.state);
      this.props.actions
        .getListSurveys(
          moment(tuNgay)?.format(variables.DATE.YEAR_MONTH_DAY),
          moment(denNgay)?.format(variables.DATE.YEAR_MONTH_DAY),
          tieuDe,
          page
        )
        .then((res) => {
          if (res.error) {
            return this.setState({ isLoading: false, isError: false });
          }

          let isEmpty = false;
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true;
          }
          this.setState({ isLoading: false, isEmpty });
        });
    });
  }

  handleGoBack() {
    this.props.history.push('/');
  }

  handleClickAdd() {
    this.props.actions.removeSurveyItem();
    this.props.history.push('/phieu-khao-sat/them-moi');
  }

  toggleSearchMessage = (close = true) => {
    this.setState(
      {
        closeSearchMessages: close ? this.state.showSearchMessages : false,
        showSearchMessages: !this.state.showSearchMessages,
        showContact: this.state.showSearchMessages,
        showConfig: false,
      },
      () => {
        if (this.state.closeSearchMessages) {
          this.props.actions.chatClearSearch();
        }

        if (this.state.showSearchMessages && this.props.contact.showProfile) {
          this.props.actions.contactShowProfile(null);
        }
      }
    );
  };

  handleOpenEoffice() {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  }

  handleToggleConfig = () => {
    this.setState(
      {
        showCabinet: this.state.showConfig ? false : this.state.showCabinet,
        showSearchMessages: this.state.showConfig ? false : this.state.showSearchMessages,
        showConfig: !this.state.showConfig,
      },
      () => {
        if (this.state.showConfig && this.props.contact.showProfile) {
          this.props.actions.contactShowProfile(null);
        }
      }
    );
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.props.actions.cleanContextConversation();
  }

  handleScroll(event) {
    const div = document.getElementById('survey-list');
    if (div) {
      let scrollTop = div.scrollTop;
      if (scrollTop > 67) {
        this.setState({
          buttonbackFixTop: true,
        });
      } else {
        this.setState({
          buttonbackFixTop: false,
        });
      }
    }
  }

  render() {
    return (
      <LayoutMain {...this.props} history={this.props.history}>
        <div className="wrapper-survey">
          <div className="cpc-survey-container">
            <Scrollbars
              id="survey-list"
              className={classnames('survey-container section', {
                'mt-66': this.state.buttonbackFixTop,
              })}
              onScroll={this.handleScroll}
            >
              <section className={classnames('section-block-heading button-back-fixed')}>
                <h2 className="heading-icon-20 main-heading-page">
                  <span className="heading-text">Phiếu khảo sát</span>
                </h2>
                <CPCTooltip
                  className={'mt-10'}
                  theme="white"
                  position={Position.LEFT}
                  content="Thêm mới"
                >
                  <IconWrapper color="#00AA48" onClick={this.handleClickAdd} size="36px">
                    <CPCIcon icon="plus-v2" color="#fff" size={18} />
                  </IconWrapper>
                </CPCTooltip>
              </section>

              <Search
                handleChangeFilter={this.handleChangeFilter}
                handleResetFilter={this.handleResetFilter}
                onSearch={this.submit}
                state={this.state}
              />

              <section className="section-block-table">
                <Table
                  items={this.props.surveys.list.items}
                  history={this.props.history}
                  actions={this.props.actions}
                  forcePage={this.state.page - 1}
                  isLoading={this.state.isLoading}
                  pageCount={Math.ceil(
                    this.props.surveys.list.total / variables.PAGINATION.PAGINATION_LIMIT
                  )}
                  total={this.props.surveys.list.total}
                  page={this.state.page}
                  onPageClick={this.clickPagination}
                  isEmpty={this.state.isEmpty}
                  isError={this.state.isError}
                  onSubmit={this.submit}
                  contact={this.props.contact}
                  auth={this.props.auth}
                  conversationId={this.state.conversationId}
                  searchParamKhaoSat={{
                    tuNgay: moment(this.state.tuNgay)?.format(variables.DATE.YEAR_MONTH_DAY),
                    denNgay: moment(this.state.denNgay)?.format(variables.DATE.YEAR_MONTH_DAY),
                    tieuDe: this.state.tieuDe,
                    page: this.state.page,
                  }}
                />
              </section>
            </Scrollbars>
          </div>
        </div>
      </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },

  surveys: {
    ...state.surveys,
    list: {
      ...state.surveys.list,
      items: state.surveys.list.items.map((id) => state.entities.surveys[id]),
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListSurveyPage);
