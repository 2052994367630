import 'babel-polyfill';
import 'intl';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Root from './containers/Root';
import { unregister } from './registerServiceWorker';
import configureStore from './store/configureStore';
import { createBrowserHistory } from 'history';
import { routes } from './routes';
import { syncHistoryWithStore } from 'react-router-redux';
import './assets/scss/app.scss';

import './i18n';
import * as dayjs from 'dayjs';
import 'dayjs/locale/vi';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);
dayjs.locale('vi');
dayjs.updateLocale('vi', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Vài giây',
    m: '1 phút',
    mm: '%d phút',
    h: '1 giờ',
    hh: '%d giờ',
    d: 'Hôm qua',
    dd: '%d ngày',
    M: '1 tháng',
    MM: '%d tháng',
    y: '1 năm',
    yy: '%d năm',
  },
});

export const store = configureStore();
const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store);

window.name = 'eoffice-chat'; // Set tab name

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root store={store} routes={routes} history={history} />);

unregister();
