const ItemChild = ({ dataSource, counter, onClick = () => {} }) => {
  return (
    <div className="nav-left-child__item" onClick={() => onClick(dataSource)}>
      <span className={`icon-${dataSource?.icon}`}></span>
      <span>{dataSource?.name}</span>
      {dataSource?.keyName && counter?.[dataSource?.keyName] ? (
        <span className="counter-inline">{counter?.[dataSource?.keyName]}</span>
      ) : (
        ''
      )}
    </div>
  );
};

export default ItemChild;
