import { schema } from 'normalizr';

const Surveys = new schema.Entity(
  'surveys',
  {},
  {
    idAttribute: (surveys) => surveys.phieuKhaoSatId,
  }
);

export default Surveys;
