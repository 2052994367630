import { schema } from 'normalizr';

const Favourite = new schema.Entity(
  'favourites',
  {},
  {
    idAttribute: (user) => user.nhomLienLacId,
  }
);

export default Favourite;
