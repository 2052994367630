import { schema } from 'normalizr';
import Unit from './Unit';

const Units = new schema.Array(Unit);
Unit.define({
  dsDonViCon: Units,
});

const UnitArraySchema = {
  result: {
    items: Units,
  },
};

export default UnitArraySchema;
