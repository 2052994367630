import { API_REQUEST_GET_EMOTICON } from '../constants/ActionTypes';

const initialState = {
  emoticons: [],
};

export default function emoticon(state = initialState, action) {
  switch (action.type) {
    case API_REQUEST_GET_EMOTICON:
      return {
        ...state,
        emoticons: action.payload.data.result.items || [],
      };
    default:
      return state;
  }
}
