import { useState } from 'react';
import classNames from 'classnames';
import CPCImagePreview from '@/components/atoms/image-preview';
import './index.scss';

const CPCImage = ({ src, thumbnailSrc, alt, size, border = 'sm', className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={classNames('cpc-image', {
          [`${className}`]: className,
          [`size-${size}`]: size,
          [`border-${border}`]: border,
        })}
        onClick={() => setIsOpen(true)}
      >
        <div
          style={{ backgroundImage: `url("${thumbnailSrc ? thumbnailSrc : src}")` }}
          className="cpc-image__img"
        >
          <img src={thumbnailSrc ? thumbnailSrc : src} alt={alt ?? src} />
        </div>
      </div>
      {isOpen && <CPCImagePreview src={src} isOpen={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  );
};

export default CPCImage;
