import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../contact/Avatar';

export default class SearchUserSuggestionItem extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(this.props.user);
  };

  render() {
    return (
      <div className="cpc-user-suggestion-item" onClick={this.handleClick}>
        <Avatar
          user={this.props.user}
          actions={this.props.actions}
          className="cpc-user-suggestion-item-avatar"
        />
        <span className="cpc-user-name">{this.props.user.tenNhanVien}</span>
      </div>
    );
  }
}
