import { schema } from 'normalizr';
import DocumentCabinetCategory from './DocumentCabinetCategory';

const DocumentCabinetCategories = new schema.Array(DocumentCabinetCategory);

DocumentCabinetCategory.define({
  dsNganTuCon: DocumentCabinetCategories,
});

const DocumentCabinetCategoryArraySchema = {
  result: {
    items: DocumentCabinetCategories,
  },
};

export default DocumentCabinetCategoryArraySchema;
