import { Classes, Overlay } from '@blueprintjs/core';
import { intersection, isEqual } from 'lodash';
import moment from 'moment';
import React from 'react';
import Confetti from 'react-dom-confetti';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
// import './assets/header.scss';
import { Birthday } from '@/components/chat';

const TONG_CONG_TY_ID = '06914739-d280-e711-80cd-40167e7b9c6a';
const BIRTHDAY_CONFIG_TYPE = {
  TAT_CA: 0,
  DON_VI: 1,
  DANH_SACH: 2,
  TAT_THONG_BA0: 3,
};
const config = {
  spread: '91',
  startVelocity: '68',
  elementCount: '70',
  dragFriction: 0.1,
  duration: '7110',
  delay: '10',
  width: '10px',
  height: '10px',
  colors: ['#f00', '#0f0', '#00f'],
};
class BirthdayModal extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    confetti: false,
    isUserBirthDay: false,
    birthdayUserList: this.props.birthdayUserList || [],
    isAnimate: false,
  };

  componentDidMount() {
    this.handleConfigBirthday();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillMount() {
    window.addEventListener('checkReloadPage', this.checkReloadPage);
    this.checkReloadPage();
  }

  checkReloadPage = () => {
    window.onbeforeunload = (e) => {
      console.log(encodeURI);
      saveData && saveData('isReloadPage', true);
    };

    window.onload = (e) => {
      console.log(e);
      saveData && saveData('isReloadPage', false);
    };
  };

  closeDialog = () => {
    this.props.handleClose();
  };

  openDialog = () => {
    this.props.handleOpen();
    setTimeout(() => {
      this.setState({
        confetti: true,
      });
    }, 300);
  };

  handleClickOutside = (event) => {
    if (this.birthDayRef && !this.birthDayRef.contains(event.target)) {
      this.closeDialogWithAnimate();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleConfigBirthday = () => {
    const { config } = this.props.auth;
    switch (config?.loaiThongBaoSinhNhat) {
      case BIRTHDAY_CONFIG_TYPE.DON_VI:
      case null:
        let newBirthdayUserList = [];
        let donViChinh =
          this.props.auth &&
          this.props.auth.mainUnitId &&
          this.props.units[this.props.auth.mainUnitId]
            ? this.props.units[this.props.auth.mainUnitId]
            : null;
        if (donViChinh && this.props.birthdayUserList) {
          this.props.birthdayUserList.forEach((item) => {
            if (
              item.donViId === donViChinh.donViId ||
              (item.donViChaId === donViChinh.donViId && donViChinh.donViId !== TONG_CONG_TY_ID) ||
              (item.donViChaId === donViChinh.donViCha &&
                donViChinh.donViCha !== TONG_CONG_TY_ID) ||
              (item.donViId === donViChinh.donViCha && donViChinh.donViCha !== TONG_CONG_TY_ID)
            ) {
              newBirthdayUserList.push(item);
            }
          });
        }
        this.setState({ birthdayUserList: newBirthdayUserList });
        break;
      case BIRTHDAY_CONFIG_TYPE.DANH_SACH:
        let userEntity = [];
        this.props.birthdayUserList &&
          this.props.birthdayUserList.forEach((user) => (userEntity[user.nhanVienId] = user));
        let birthdayUserIdList = this.props.birthdayUserList
          ? this.props.birthdayUserList.map((user) => user.nhanVienId)
          : [];
        let configUserIdList =
          config.dsNguoiNhanThongBaoSinhNhat && config.dsNguoiNhanThongBaoSinhNhat.length > 0
            ? config.dsNguoiNhanThongBaoSinhNhat.map((user) => user.nhanVienId)
            : [];
        let newBirthdayUserIdList = intersection(birthdayUserIdList, configUserIdList);
        this.setState({
          birthdayUserList: newBirthdayUserIdList.map((nhanVienId) => userEntity[nhanVienId]),
        });
        break;
      case BIRTHDAY_CONFIG_TYPE.TAT_THONG_BA0:
        this.setState({
          birthdayUserList: [],
        });
        break;
      default:
        this.setState({
          birthdayUserList: this.props.birthdayUserList,
        });
        break;
    }
  };

  animteBirthday = () => {
    setTimeout(() => {
      this.setState({
        isAnimate: true,
      });
    }, 150);
  };

  closeDialogWithAnimate = async () => {
    await this.setState({
      isAnimate: false,
    });
    this.closeDialog();
  };

  getBirthdayRef = (el) => (this.birthDayRef = el);

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !isEqual(this.props.auth, prevProps.auth) ||
      !isEqual(prevProps.birthdayUserList, this.props.birthdayUserList) ||
      !isEqual(prevProps.units, this.props.units)
    ) {
      this.handleConfigBirthday();
    }
    if (
      this.props.birthdayUserList &&
      this.props.birthdayUserList.length > 0 &&
      this.props.auth &&
      this.props.auth.user &&
      !this.state.isUserBirthDay &&
      !this.state.isCanShowBirthDay
    ) {
      if (
        this.props.birthdayUserList.findIndex(
          (item) => item.nhanVienId === this.props.auth.user.nhanVienId
        ) !== -1
      ) {
        setTimeout(() => {
          this.setState({
            isUserBirthDay: true,
            isCanShowBirthDay: true,
          });
        }, 2000);
      } else {
        this.setState({
          isCanShowBirthDay: true,
        });
      }
    }
    if (
      !prevState.isUserBirthDay &&
      this.state.isUserBirthDay &&
      this.props.auth &&
      this.props.auth.user
    ) {
      let openedDialog = localStorage.getItem(
        `openedDialog-${this.props.auth.user.nhanVienId}-${moment().year()}`
      );
      if (!openedDialog) {
        this.openDialog();
        localStorage.setItem(
          `openedDialog-${this.props.auth.user.nhanVienId}-${moment().year()}`,
          true
        );
      }
    }
  };

  render() {
    return (
      <Overlay
        transitionDuration={300}
        className={Classes.OVERLAY_SCROLL_CONTAINER}
        isOpen={this.props.isOpen}
      >
        <div className="d-flex w-100 h-100 middle-xs">
          <Birthday
            history={this.props.history}
            getBirthdayRef={this.getBirthdayRef}
            animteBirthday={this.animteBirthday}
            birthdayUserList={this.state.birthdayUserList}
            unit={this.props.units}
            auth={this.props.auth}
            receivers={this.props.receivers}
            closeDialog={this.closeDialogWithAnimate}
            isUserBirthDay={this.state.isUserBirthDay}
            isAnimate={this.state.isAnimate}
          />
        </div>
        <div className="position-absolute w-100 h-100 overflow-hidden pointer-event-none">
          <div className="position-absolute left-0 z-index-1">
            <Confetti
              active={this.state.confetti && this.state.isUserBirthDay}
              config={{ ...config, angle: 345 }}
            />
          </div>
          <div className="position-absolute right-0 z-index-1">
            <Confetti
              active={this.state.confetti && this.state.isUserBirthDay}
              config={{ ...config, angle: 195 }}
            />
          </div>
        </div>
      </Overlay>
    );
  }
}

const mapStateToProps = (state) => ({
  birthdayUserList: state.chat.birthdayUserList,
  units: state.entities.units,
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

export default withRouter(connect(mapStateToProps)(BirthdayModal));
