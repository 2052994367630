import { IMAGE_URL, UPLOAD_API_URL } from '../constants/Api';
import defaultAvatar from '@/assets/images/icons/default_avatar.png';
import { variables } from '@/constants/variables';

export const getLinkImage = (image) => {
  if (!image) return defaultAvatar;

  if (image?.startsWith('http')) return image;

  return IMAGE_URL + image;
};

export const getLinkAvatar = ({ anhDaiDien, accessToken }) => {
  return `${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${anhDaiDien}?w=150&BearerToken=${accessToken}`;
};

export const getImageMeta = (url, callBack) => {
  const img = new Image();
  img.onload = () =>
    callBack(null, {
      width: img?.naturalWidth,
      height: img?.naturalHeight,
    });
  img.onerror = (err) => callBack(err);
  img.src = url;
};

export const getImageScale = (scaleHeight = 10, originWidth, originHeight) => {
  return Math.floor(scaleHeight * (originWidth / originHeight));
};

export const getImageScaleWidth = (scaleWidth = 10, originWidth, originHeight) => {
  return Math.floor(scaleWidth * (originHeight / originWidth));
};
