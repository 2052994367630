import React, { Component } from 'react';
import TreeSelect, { TreeNode } from 'rc-tree-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'rc-tree-select/assets/index.css';
import './select.scss';

class Select extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  filterTreeNode = (input, child) => {
    let res = input.toLowerCase();
    let titleProps = child.props.title.toLowerCase();
    return String(titleProps).indexOf(res) === 0;
  };

  /**
   * Load data list option
   * @param {array} data list value
   * @param {bool} isTree true/false
   * @returns list option value
   * @memberof Select
   */
  handleSelect(data, isTree) {
    let model = this.props.model;
    if (isTree) {
      return (
        data &&
        data.map((item) => (
          <TreeNode value={String(item[model[0]])} title={item[model[1]]} key={item[model[0]]}>
            {item[model[2]] && item[model[2]].length > 0 && this.handleSelect(item[model[2]], true)}
          </TreeNode>
        ))
      );
    } else {
      return (
        data &&
        data.map((item, i) => (
          <option className="option" key={i} value={item[model[0]]}>
            {item[model[1]]}
          </option>
        ))
      );
    }
  }

  /**
   * Get data select
   * @param {object} e name/value data
   * @memberof Select
   */
  handleChange = (id, name, value) => {
    let result = id;
    if (!id) {
      result = value?.triggerValue;
    }

    this.props.onChange && this.props.onChange(result);
  };

  render() {
    let data = this.props.data || [];
    const classNameWrapper = this.props.classNameWrapper;
    return (
      <div
        className={classNames('select-box', {
          [classNameWrapper]: classNameWrapper,
        })}
      >
        {this.props.tree ? (
          <TreeSelect
            className={`${this.props.className || ''}`}
            dropdownStyle={{ height: 470, minWidth: 100, overflow: 'auto' }}
            value={this.props.value}
            treeDefaultExpandAll={false}
            treeNodeFilterProp="title"
            filterTreeNode={this.filterTreeNode}
            showSearch={this.props.showSearch}
            onChange={this.handleChange}
            notFoundContent="Không có kết quả"
            disabled={this.props.disabled}
            allowClear={this.props.allowClear}
            multiple={this.props.multiple}
            name={this.props.name}
          >
            <TreeNode value="All" title={this.props?.firstName} key="All" />
            {this.handleSelect(data, true)}
          </TreeSelect>
        ) : (
          <select
            name={this.props.name}
            onChange={this.handleChange}
            value={this.props.value}
            disabled={this.props.disabled}
          >
            {this.handleSelect(data, false)}
          </select>
        )}
      </div>
    );
  }
}

Select.defaultProps = {
  allowClear: false,
  showSearch: false,
  disabled: false,
  model: ['value', 'name', 'children'],
  multiple: false,
  tree: false,
  name: '',
  firstName: 'Tất cả',
};

Select.propTypes = {
  data: PropTypes.array.isRequired,
  showSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  model: PropTypes.array,
  allowClear: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiple: PropTypes.bool,
  tree: PropTypes.bool,
  name: PropTypes.string,
  firstName: PropTypes.string,
  onChange: PropTypes.func,
};

export default Select;
