import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { typeFile, getFileSize } from '../../helpers/helper';
import { UPLOAD_API_URL } from '../../constants/Api';
import { variables } from '@/constants/variables';

class FileList extends Component {
  render() {
    let { auth, data, isShowSize, isShowIcon, className } = this.props;
    let { accessToken } = auth?.token;
    let extension = null;
    return (
      <ul className={`file-list ${className}`}>
        {data &&
          data.length !== 0 &&
          data.map((item, key) => {
            extension = item.tenFile
              .slice(((item.tenFile.lastIndexOf('.') - 1) >>> 0) + 2)
              .toLowerCase();
            return (
              <li key={key} className="file-list-item">
                <Link
                  target="_blank"
                  to={`${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${item.fileId}?fileName=${item.tenFile}&BearerToken=${accessToken}`}
                >
                  {isShowIcon && (
                    <span
                      className="file-list-icon"
                      style={{ backgroundImage: `url(${typeFile(extension)})` }}
                    />
                  )}
                  {
                    <span className="file-list-name">
                      {item.tenFile}
                      {isShowSize && <i>{getFileSize(item.kichThuoc)}</i>}
                    </span>
                  }
                </Link>
              </li>
            );
          })}
      </ul>
    );
  }
}

FileList.defaultProps = {
  className: '',
  data: [],
  isShowSize: true,
  isShowIcon: true,
};

FileList.propTypes = {
  data: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  isShowSize: PropTypes.bool,
  isShowIcon: PropTypes.bool,
  className: PropTypes.string,
};

export default FileList;
