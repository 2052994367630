import React from 'react';

class StatisticalContainer extends React.Component {
  render() {
    return (
      <div className="statistical-container" {...this.props}>
        <h2 className="statistical-title">{this.props.title}</h2>
        <div className="statistical-wrap-children">{this.props.children}</div>
      </div>
    );
  }
}

StatisticalContainer.defaultProps = {
  title: 'This is title',
};

export default StatisticalContainer;
