import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FavouriteItem from './FavouriteItem';
import { Dialog, Button, Intent, Position, Toaster } from '@blueprintjs/core';

export default class Favourite extends Component {
  static propTypes = {
    favourites: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    filterOnline: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleToggleDialogDeleteFavourite = this.handleToggleDialogDeleteFavourite.bind(this);
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  state = {
    isOpenDeleteFavourite: false,
    favouriteDelete: null,
  };

  handleToggleDialogDeleteFavourite = (favouriteDelete: null, isOpen: true) => {
    this.setState({
      error: '',
      favouriteDelete: favouriteDelete,
      isOpenDeleteFavourite: isOpen,
    });
  };

  handleSubmitDeleteFavourite = (e) => {
    e.preventDefault();

    if (!this.state.favouriteDelete) {
      return;
    }

    this.props.actions
      .contactDeleteFavouriteGroup(this.state.favouriteDelete.nhomLienLacId)
      .then((res) => {
        if (res.error) {
          this.toaster.show({ message: 'Có lỗi xảy ra, vui lòng thử lại', intent: Intent.DANGER });
          return;
        }

        this.toaster.show({ message: 'Xóa nhóm thành công', intent: Intent.SUCCESS });
        this.setState({ isOpenDeleteFavourite: false });

        this.props.actions.contactFetchFavouriteGroups();
      });
  };

  render() {
    return (
      <div>
        {this.props.favourites.map((favourite, i) => (
          <FavouriteItem
            key={i}
            auth={this.props.auth}
            favourite={favourite}
            actions={this.props.actions}
            onDelete={this.handleToggleDialogDeleteFavourite}
            filterOnline={this.props.filterOnline}
            receivers={this.props.receivers}
          />
        ))}
        <Dialog
          className="cpc-delete-favourite-dialog"
          isOpen={this.state.isOpenDeleteFavourite}
          onClose={() => {
            this.handleToggleDialogDeleteFavourite(null, false);
          }}
          title="Xóa nhóm"
        >
          <form onSubmit={this.handleSubmitDeleteFavourite}>
            <div className="pt-dialog-body">
              <div className="pt-form-group">Anh/chị có muốn xóa nhóm đã chọn không?</div>
              {this.state.error && (
                <div className="pt-input-helper-text error">{this.state.error}</div>
              )}
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                <Button
                  className="pt-large"
                  rightIconName="cross"
                  text="Đóng"
                  onClick={() => {
                    this.handleToggleDialogDeleteFavourite(null, false);
                  }}
                />
                <Button
                  className="pt-large"
                  rightIconName="trash"
                  type="submit"
                  intent={Intent.PRIMARY}
                  text="Xóa"
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}
