import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Avatar from './Avatar';
import { Button, Intent, Text } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/profile.scss';
import moment from 'moment';
export default class Profile extends Component {
  static propTypes = {
    me: PropTypes.object,
    user: PropTypes.object,
    excludeShowUnit: PropTypes.string,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    me: {},
    user: {},
    excludeShowUnit: '',
  };

  constructor(props) {
    super(props);
    let me = localStorage.getItem(`${props.me.nhanVienId}-me`)
      ? JSON.parse(localStorage.getItem(`${props.me.nhanVienId}-me`))
      : null;
    this.state = {
      me: me || props.me,
      statusChange: false,
      phoneChange: false,
      phoneOtherChange: false,
      birthDayChange: false,
    };

    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.isMe = this.isMe.bind(this);
    this.handleSaveInfo = this.handleSaveInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.actions.getUserInfo(this.props.user.nhanVienId);
  }

  componentWillReceiveProps(props) {
    if (this.props.user.nhanVienId !== props.user.nhanVienId) {
      this.props.actions.getUserInfo(props.user.nhanVienId);
    }
  }

  handleClickClose = (e) => {
    this.props.actions.contactShowProfile(null);
  };

  handleSendMessage = () => {
    if (!this.props.user.checked) {
      this.props.actions.chatAddReceiver(this.props.user.nhanVienId);
    }
  };

  unitName = () => {
    if (!this.props.user.dsChucDanh || !this.props.user.dsChucDanh.length) {
      return '';
    }

    const mainUnit = this.props.user.dsChucDanh.find((unit) => unit.phongBanChinh);

    if (!mainUnit || !mainUnit.donVi) {
      return '';
    }

    return mainUnit.donVi.tenDonVi;
  };

  departmentName = (path = false) => {
    if (!this.props.user.dsChucDanh || !this.props.user.dsChucDanh.length) {
      return '';
    }

    const mainUnit = this.props.user.dsChucDanh.find((unit) => unit.phongBanChinh);

    if (!mainUnit || !mainUnit.phongBan) {
      return '';
    }

    if (path && mainUnit.phongBanPath) {
      return _.trim(mainUnit.phongBanPath, '/');
    }

    return mainUnit.phongBan.tenPhongBan;
  };

  isMe() {
    return this.props.me.nhanVienId === this.props.user.nhanVienId;
  }

  handleSaveInfo() {
    this.props.onSubmit &&
      this.props.onSubmit(this.state.me).then((value) => {
        localStorage.setItem(`${this.props.me.nhanVienId}-me`, JSON.stringify(this.state.me));
        this.setState({
          statusChange: false,
          phoneChange: false,
          phoneOtherChange: false,
          birthDayChange: false,
        });
      });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name === 'tamTrang'
        ? 'statusChange'
        : name === 'soDienThoai'
        ? 'phoneChange'
        : 'phoneOtherChange']: true,
      me: {
        ...this.state.me,
        [name]: value,
      },
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Escape') {
      this.setState({
        me: this.props.me,
        statusChange: false,
        phoneChange: false,
      });
    }
  }

  handleDateChange = (e) => {
    if (!e) {
      return;
    }

    this.setState({
      birthDayChange: true,
      me: {
        ...this.state.me,
        ngaySinh: moment(e).format('YYYY-MM-DD'),
      },
    });
  };

  render() {
    return (
      this.props.user && (
        <div className="cpc-profile-section">
          <div className="cpc-profile-header">
            <div className="cpc-content">
              <Avatar className="avatar-img" user={this.props.user} actions={this.props.actions} />
              <h3>{this.props.user.tenNhanVien}</h3>
              <h4>{this.departmentName()}</h4>
              <Button
                className="pt-large"
                iconName="chat"
                intent={Intent.PRIMARY}
                text="Nhắn tin"
                onClick={this.handleSendMessage}
              />
            </div>
            <button className="cpc-close" onClick={this.handleClickClose}>
              <span className="pt-icon pt-icon-cross" />
            </button>
          </div>
          <div className="cpc-profile-info">
            <Scrollbars
              style={{ width: '100%', height: '100%' }}
              renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
            >
              {(this.props.user.ngaySinh || this.isMe()) && (
                <div className="cpc-info-group">
                  <div className="cpc-icon">
                    <span className="left-icon icon-gift icon-primary" />
                  </div>
                  <div className="cpc-info">
                    <h3 className="title">Ngày sinh</h3>
                    {/*
                    this.isMe() &&
                    <div className="main-info">
                      <div className="form-group datepicker-unstyled input-border-secondary p-5px-0px">
                        <DateInput
                          value={this.state.me.ngaySinh}
                          format="DD/MM/YYYY"
                          name="ngayHetHan"
                          onChange={this.handleDateChange}
                          popoverProps={{position: Position.BOTTOM}}
                          minDate={new Date(1920, 1, 1)}
                          reverseMonthAndYearMenus={true}
                          canClearSelection={true}
                        />
                        {
                          this.state.birthDayChange &&
                          <button className="btn-icon btn-submit-form" onClick={this.handleSaveInfo}>
                            <span className="icon-save"/>
                          </button>
                        }
                      </div>
                    </div>
                    */}
                    <div className="main-info">
                      {this.props.user.ngaySinh
                        ? moment(this.props.user.ngaySinh).format('DD/MM/YYYY')
                        : ''}
                    </div>
                  </div>
                </div>
              )}
              {(this.props.user.tamTrang || this.isMe()) && (
                <div className="cpc-info-group">
                  <div className="cpc-icon">
                    <span className="left-icon icon-right-quote icon-primary" />
                  </div>
                  <div className="cpc-info">
                    <h3 className="title">Trạng thái</h3>
                    {this.isMe() && (
                      <div className="main-info">
                        <div className="form-group">
                          <textarea
                            className="pt-fill textarea-form"
                            rows="1"
                            cols="10"
                            name="tamTrang"
                            value={this.state.me.tamTrang || ''}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyPress}
                          />
                          {this.state.statusChange && (
                            <button
                              className="btn-icon btn-submit-form"
                              onClick={this.handleSaveInfo}
                            >
                              <span className="icon-save" />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    {!this.isMe() && <div className="main-info">{this.props.user.tamTrang}</div>}
                  </div>
                </div>
              )}
              <div className="cpc-info-group">
                <div className="cpc-icon">
                  <span className="left-icon icon-call" />
                </div>
                <div className="cpc-info">
                  <h3 className="title">Số điện thoại</h3>
                  {this.isMe() && (
                    <div className="main-info">
                      <div className="form-group">
                        <input
                          className="pt-fill"
                          placeholder=""
                          name="soDienThoai"
                          value={this.state.me.soDienThoai || ''}
                          onChange={this.handleChange}
                          onKeyDown={this.handleKeyPress}
                        />
                        {this.state.phoneChange && (
                          <button
                            className="btn-icon btn-submit-form"
                            onClick={this.handleSaveInfo}
                          >
                            <span className="icon-save" />
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {!this.isMe() && <div className="main-info">{this.props.user.soDienThoai}</div>}
                  {(this.props.user.soDienThoaiKhac || this.isMe()) && (
                    <div>
                      <h3 className="title mt15">Số điện thoại khác</h3>
                      {this.isMe() && (
                        <div className="main-info">
                          <div className="form-group">
                            <input
                              className="pt-fill"
                              placeholder=""
                              name="soDienThoaiKhac"
                              value={this.state.me.soDienThoaiKhac || ''}
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyPress}
                            />
                            {this.state.phoneOtherChange && (
                              <button
                                className="btn-icon btn-submit-form"
                                onClick={this.handleSaveInfo}
                              >
                                <span className="icon-save" />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {!this.isMe() && (
                        <div className="main-info">{this.props.user.soDienThoaiKhac}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="cpc-info-group">
                <div className="cpc-icon">
                  <span className="left-icon icon-mail" />
                </div>
                <div className="cpc-info">
                  <h3 className="title">Email</h3>
                  <div className="main-info">
                    <a href={`mailto:${this.props.user.email}`} className="text-link">
                      {this.props.user.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="cpc-info-group">
                <div className="cpc-icon">
                  <span className="left-icon icon-building" />
                </div>
                <div className="cpc-info">
                  <h3 className="title">Đơn vị</h3>
                  <div className="main-info">
                    <Text ellipsize={true}>{this.unitName()}</Text>
                  </div>
                </div>
              </div>
              <div className="cpc-info-group">
                <div className="cpc-icon">
                  <span className="left-icon icon-table" />
                </div>
                <div className="cpc-info">
                  <h3 className="title">Phòng ban</h3>
                  <div className="main-info">{this.departmentName(true)}</div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      )
    );
  }
}
