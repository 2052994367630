import * as Actions from '../actions';
import axios from 'axios';
import querystring from 'query-string';
import {
  AUTH_API_URL,
  CHAT_API_URL,
  UPLOAD_API_URL,
  FE_API_URL,
  SURVEY_API_URL,
  MAINTENANCE_URL,
} from '../constants/Api';
import { camelizeKeys } from 'humps';
import { getActionTypes } from 'redux-axios-middleware';
import { normalize } from 'normalizr';

const isBoolean = (value) => {
  return typeof value === 'boolean' || value instanceof Boolean;
};

const handleMaintenance = () => {
  fetch(MAINTENANCE_URL)
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      if (isBoolean(myJson) && myJson) {
        window.location.replace('/');
      }
    });
};

export const apiClients = {
  default: {
    client: axios.create({
      baseURL: CHAT_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        },
      ],
    }),
  },
  eoffice: {
    client: axios.create({
      baseURL: FE_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        },
      ],
    }),
  },
  survey: {
    client: axios.create({
      baseURL: SURVEY_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        },
      ],
    }),
  },
  auth: {
    client: axios.create({
      baseURL: AUTH_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      transformRequest: [
        (data) => {
          return querystring.stringify(data);
        },
      ],
      transformResponse: [
        function (data) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        },
      ],
    }),
  },
  download: {
    client: axios.create({
      baseURL: UPLOAD_API_URL,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    }),
  },
  fileInfo: {
    client: axios.create({
      baseURL: UPLOAD_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      transformResponse: [
        function (data) {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        },
      ],
    }),
  },
  upload: {
    client: axios.create({
      baseURL: UPLOAD_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformRequest: [
        (data) => {
          let formData = new FormData();
          for (const id in data) {
            if (data[id] && data[id].name) {
              formData.append(id, data[id], data[id].name);
            }
          }
          return formData;
        },
      ],
      transformResponse: [
        (data) => {
          return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data);
        },
      ],
    }),
  },
  defaultV2: {
    client: axios.create({
      baseURL: CHAT_API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: -1,
      },
      transformResponse: function (data) {
        return data;
      },
    }),
  },
};

export const apiMiddlewareConfig = {
  onSuccess: ({ action, next, response }, options) => {
    if (action.schema) {
      response.data = normalize(
        typeof response.data !== 'object' ? JSON.parse(response.data) : response.data,
        action.schema
      );
    }
    const nextAction = {
      type: getActionTypes(action, options)[1],
      payload: response,
      meta: {
        previousAction: action,
      },
    };
    next(nextAction);
    return nextAction;
  },
  onError: ({ action, next, error, dispatch }, options) => {
    if (error.response && error.response.status === 401) {
      return dispatch(Actions.authLogout());
    }

    if (
      error.response &&
      error.response.status &&
      error.response.status.toString().indexOf(50) > -1
    ) {
      return handleMaintenance();
    }
    let errorObject;
    if (!error.response) {
      errorObject = {
        data: error.message,
        status: 0,
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log('HTTP Failure in Axios', error);
      }

      return handleMaintenance();
    } else {
      errorObject = error;
    }
    const nextAction = {
      type: getActionTypes(action, options)[2],
      error: errorObject,
      meta: {
        previousAction: action,
      },
    };

    next(nextAction);
    return nextAction;
  },
};
