import React, { Component } from 'react';
import { Checkbox, Collapse } from '@blueprintjs/core';
import classnames from 'classnames';
import { isEqual, difference } from 'lodash';
export default class DepartmentTree extends Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.handleAutoCheckDepartment();
  }

  handleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getAllChild = (phongBan) => {
    const { departmentData } = this.props;
    let idDepartmentArr = [];
    let userArr = phongBan.dsNhanVien || [];

    for (let i = 0; i < departmentData.length; i++) {
      const item = departmentData[i];
      if (item.phongBanCha === phongBan.phongBanId) {
        idDepartmentArr.push(item.phongBanId);
        let idArrChild = this.getAllChild(item);
        userArr = [...userArr, ...idArrChild.user];
        idDepartmentArr = [...idDepartmentArr, ...idArrChild.department];
      }
    }
    return {
      user: userArr,
      department: idDepartmentArr,
    };
  };

  handleAutoCheckDepartment = () => {
    const { data, departmentChecked } = this.props;
    let idUserCheckedArr = this.props.userChecked.map((item) => item.nhanVienId);
    let idArrChild = this.getAllChild(this.props.data);
    let idUserArr = idArrChild.user.map((item) => item.nhanVienId);
    let idDepartmentArr = idArrChild.department;
    if (!(idUserArr.length === 0 && idDepartmentArr.length === 0)) {
      let isAllUserChecked =
        (idUserArr.length > 0 && difference(idUserArr, idUserCheckedArr).length === 0) ||
        idUserArr.length === 0;
      let isAllDepartmentChecked =
        (idArrChild.department.length > 0 &&
          difference(idArrChild.department, this.props.departmentChecked).length === 0) ||
        idArrChild.department.length === 0;
      if (departmentChecked.findIndex((id) => id === data.phongBanId) === -1) {
        if (isAllUserChecked && isAllDepartmentChecked) {
          this.props.onChange({
            idDepartmentArr: [data.phongBanId],
            checked: true,
          });
        }
      } else {
        if (!isAllUserChecked || !isAllDepartmentChecked) {
          this.props.onChange({
            idDepartmentArr: [data.phongBanId],
            checked: false,
          });
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.userChecked, prevProps.userChecked) ||
      !isEqual(this.props.departmentChecked, prevProps.departmentChecked)
    ) {
      this.handleAutoCheckDepartment();
    }
  }

  handleDepartmentCheck = (e) => {
    const { data } = this.props;
    let idDepartmentArr = [data.phongBanId];
    let userArr = [];
    let idArrChild = this.getAllChild(data);
    userArr = [...userArr, ...idArrChild.user];
    idDepartmentArr = [...idDepartmentArr, ...idArrChild.department];
    this.props.onChange({
      userArr,
      idDepartmentArr,
      checked: e.target.checked,
    });
  };

  handleUserCheck = (user, e) => {
    this.props.onChange({
      userArr: [user],
      checked: e.target.checked,
    });
  };

  render() {
    const { data, departmentData } = this.props;

    return (
      <div
        className={classnames('department-tree', {
          'department-tree--no_border_top': this.props.noBorderTop,
        })}
      >
        <div className="department-tree__item" onClick={this.handleCollapse}>
          {this.state.isOpen ? (
            <span className="cpc department-tree__icon icon-minus-v2" />
          ) : (
            <span className="cpc department-tree__icon icon-plus-v2" />
          )}
          <Checkbox
            checked={this.props.checked}
            onChange={(e) => this.handleDepartmentCheck(e)}
            className="department-tree__checkbox-form department-tree__checkbox-form--department"
            label={data.tenPhongBan}
          />
        </div>
        <Collapse className="department-tree__child" isOpen={this.state.isOpen}>
          {data.dsNhanVien &&
            data.dsNhanVien.length > 0 &&
            data.dsNhanVien.map((item) => (
              <Checkbox
                onChange={(e) => this.handleUserCheck(item, e)}
                checked={
                  this.props.userChecked &&
                  this.props.userChecked.findIndex(
                    (user) => user.nhanVienId === item.nhanVienId
                  ) !== -1
                }
                key={item.nhanVienId}
                className="department-tree__checkbox-form"
                label={item.tenNhanVien}
              />
            ))}
          {departmentData.map((item, index) =>
            item.phongBanCha === data.phongBanId ? (
              <DepartmentTree
                onChange={this.props.onChange}
                departmentChecked={this.props.departmentChecked}
                checked={
                  this.props.departmentChecked.findIndex((id) => item.phongBanId === id) !== -1
                }
                userChecked={this.props.userChecked}
                noBorderTop={data.dsNhanVien && data.dsNhanVien.length > 0}
                key={item.phongBanId}
                data={item}
                departmentData={departmentData}
              />
            ) : null
          )}
        </Collapse>
      </div>
    );
  }
}
