import { hubConnection } from 'signalr-no-jquery';
import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import { Intent } from '@blueprintjs/core';

import { getRandomInt } from '@/helpers/helper';
import { store } from '../index';
import { Message } from '../schemas';
import { CHAT_API_URL } from '../constants/Api';
import { pick } from 'lodash';

const debug = false;
const DISCONNECT = false;
const countManualDefault = 5;
const totalReconnect = 7;
const invokeTimeOut = 20000;

export const SignalRHub = (auth, actions = {}) => {
  const { getState } = store;
  if (!auth || !auth.token || !auth.token.accessToken) {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('You must login to connect signalR.');
    }
    return;
  }

  const checkExitNhanVienHoiThoai = async (hoiThoaiId) => {
    const res = await actions.getConversationExist(hoiThoaiId);
    return res?.payload?.data?.result;
  };

  const reloadAPI = () => {
    setTimeout(() => {
      actions.setReloadData(true);
    }, 1000);
  };

  const connection = hubConnection(CHAT_API_URL, {
    logging: debug,
    qs: {
      BearerToken: auth.token.accessToken,
    },
  });

  connection.reconnectDelay = 5000;
  const proxy = connection.createHubProxy('chatHub');
  const invoke = (methodName, args) => {
    return proxy.invoke.apply(proxy, [methodName, ...args]);
  };

  const proxyOn = (methodName, callBack) => {
    proxy.on(methodName, (res, status) => {
      callBack(res, status);
    });
  };

  proxy.on('newMessage', (message) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal newMessage', message);
    }

    const payload = {
      data: normalize(camelizeKeys(message), Message),
    };
    actions.chatSignalRTriggerNewMessage(payload);
    // Xử lý hình ảnh phần thông tin hội thoại
    const messageId = payload?.data?.result;
    const newMessage = payload?.data?.entities?.messages?.[messageId];
    if (newMessage?.fileDinhKem?.length !== 0) {
      actions.setFilesNewMessage(newMessage);
      reloadAPI();
    }

    actions.setConversationMessageSeen(
      newMessage?.hoiThoai?.hoiThoaiId,
      newMessage?.tinNhanId,
      false
    );
  });

  proxy.on('requestRefreshMessage', (message) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('requestRefreshMessage', message);
    }

    let messageData = {};
    if (message) {
      messageData = {
        dsThongKeEmoticon: message.ds_thong_ke_emoticon,
        hoiThoaiId: message.hoi_thoai_id,
        tinNhanId: message.tin_nhan_id,
      };
    }
    actions.chatSignalRChangeEmoticon(camelizeKeys(messageData));
  });

  proxy.on(
    'updateCauHinhNhom',
    (hoiThoaiId, thayDoiThongTin, ghimTinNhan, pheDuyetThanhVien, phanHoiTinNhan) => {
      if (process.env.NODE_ENV !== 'production' || debug) {
        console.log('updateCauHinhNhom', hoiThoaiId);
      }

      actions.setConversationInfoUpdate({
        hoiThoaiId: hoiThoaiId,
        data: {
          thayDoiThongTin: thayDoiThongTin,
          ghimTinNhan: ghimTinNhan,
          pheDuyetThanhVien: pheDuyetThanhVien,
          phanHoiTinNhan: phanHoiTinNhan,
        },
      });
    }
  );

  proxy.on('updateVaiTroNhanVienHoiThoai', (hoiThoaiId, nhanVienId, vaiTro) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('updateVaiTroNhanVienHoiThoai', hoiThoaiId);
    }

    actions.updateRoleConversation(hoiThoaiId, nhanVienId, vaiTro);
  });

  proxy.on('updateMessage', (message) => {
    actions.setEditorLoading(false);
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('updateMessage', message);
    }
    actions.updateMessage(camelizeKeys(message));
  });

  proxy.on('messageDeleted', (messageId) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal messageDeleted', messageId);
    }

    actions.chatSignalRTriggerMessageDeleted(messageId);
  });

  proxy.on('messagesDeleted', (messages) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal messagesDeleted', messages);
    }

    actions.chatSignalRTriggerMessagesDeleted(messages);
  });

  proxy.on('messageConversationDeleted', (conversationId) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal messageConversationDeleted', conversationId);
    }

    actions.chatReset();
    actions.chatFetchMessages(null);
  });

  proxy.on('seenMessage', (hoiThoaiId, tinNhanId) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('seenMessage', hoiThoaiId, tinNhanId);
    }

    actions.setConversationMessageReceived(hoiThoaiId, tinNhanId, false);
    actions.setConversationMessageSeen(hoiThoaiId, tinNhanId, true);
  });

  proxy.on('newGroup', (conversationId) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal newGroup', conversationId);
    }

    invoke('AddMeToGroup', [conversationId]);
  });

  proxy.on('createGroupChat', (z) => {
    console.log('Signal createGroupChats', z);
  });

  proxy.on('createdConversation', async (conversation) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signal createdConversation', conversation);
    }

    const res = await checkExitNhanVienHoiThoai(conversation?.hoi_thoai_id);
    if (res) {
      actions.createNewConversation(conversation);
    }
  });

  proxy.on('updatedConversation', (conversation) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal updatedConversation', conversation);
    }

    actions.setUpdateConversation(camelizeKeys(conversation));
  });

  proxy.on('syncLastTimeRead', (data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signal syncLastTimeRead', camelizeKeys(data));
    }

    actions.removeUnreadMessage(camelizeKeys(data));
  });

  proxy.on('sendMessageResult', async (message) => {
    actions.setEditorLoading(false);
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('Signal sendMessageResult', message);
    }

    if (!message || !message.entity) {
      return;
    }

    message.entity.delivered = true;
    const payload = {
      data: normalize(camelizeKeys(message), { entity: Message }),
    };
    await actions.chatSignalRTriggerSendMessage(payload);

    // Xử lý hình ảnh phần thông tin hội thoại
    const messageId = payload?.data?.result?.entity;
    const newMessage = payload?.data?.entities?.messages?.[messageId];
    if (newMessage?.fileDinhKem?.length !== 0) {
      actions.setFilesNewMessage(newMessage);
      reloadAPI();
    }

    actions.setConversationMessageReceived(
      newMessage?.hoiThoai?.hoiThoaiId,
      newMessage?.tinNhanId,
      true
    );
    actions.setConversationMessageSeen(
      newMessage?.hoiThoai?.hoiThoaiId,
      newMessage?.tinNhanId,
      false
    );

    if (getState()?.chat?.currentConversation?.hoiThoaiId === newMessage?.hoiThoai?.hoiThoaiId) {
      invoke('lastTimeRead', [
        newMessage?.hoiThoai?.hoiThoaiId,
        new Date(),
        newMessage?.hoiThoai?.tongTinNhan,
        true,
      ]);
    }
  });

  proxy.on('stateChanged', (userState) => {
    if (process.env.NODE_ENV !== 'production') {
      if (userState.newState == 0) {
        // Attempting a new connection
      } else if (userState.newState == 1) {
        // Established a connection
      } else if (userState.newState == 2) {
        // Attempting reconnect
      } else if (userState.newState == 4) {
        // Connection lost
        console.log('Signalr stateChanged', userState);
      }
    }

    actions.chatSignalRTriggerStateChange(camelizeKeys(userState));
  });

  proxy.on('ghimHoiThoai', (hoiThoai, isGhim) => {
    actions.updateConversationPinTop({ hoiThoai: camelizeKeys(hoiThoai), isGhim });
    actions.commonAddToasterMessage({
      message: isGhim ? 'Ghim hội thoại thành công' : 'Bỏ ghim hội thoại thành công',
      intent: Intent.SUCCESS,
    });
  });

  proxy.on('roiHoiThoai', (hoiThoaiId, nhanVienId, hoiThoai) => {
    if (!hoiThoaiId && !nhanVienId) {
      // Trường hợp 2 null chỉ trả về cho chính user đang rời hội thoại
      actions.commonAddToasterMessage({
        message: 'Có lỗi xảy ra, vui lòng thử lại',
        intent: Intent.WARNING,
      });
      return;
    } else {
      if (auth?.user?.nhanVienId === nhanVienId) {
        actions.setConversationPersonalLeave({ hoiThoaiId, nhanVienId });
        actions.commonAddToasterMessage({
          message: 'Rời hội thoại thành công',
          intent: Intent.SUCCESS,
        });
      }

      if (hoiThoaiId) {
        actions.setConversationDeleteUser({
          hoiThoai: camelizeKeys(hoiThoai),
          isDeletedMe: nhanVienId === getState()?.auth?.user,
          nhanVienDeleteId: nhanVienId,
        });
        reloadAPI();
      }
      return;
    }
  });

  proxy.on('newMessageSystem', (tinnhan, isMsgSystem) => {
    // define message
    const mes = pick(tinnhan, ['noi_dung', 'hoi_thoai_id']);
    mes.tuy_chon = {
      sms: 0,
      email: 0,
    };
    mes.file_dinh_kem = [];
    mes.phieu_khao_sat = [];

    invoke('sendMessage', [{ ...mes, is_msg_system: isMsgSystem }]);
  });

  proxy.on('deleteGroupChat', (hoiThoaiId, status) => {
    if (status) {
      actions.setConversationDelete(hoiThoaiId);
    }
  });

  proxy.on('addNhanVienHoiThoai', (hoiThoaiId, hoiThoai, nhanVienHoiThoai) => {
    if (nhanVienHoiThoai && nhanVienHoiThoai?.length !== 0 && hoiThoaiId) {
      // Update conversation info in conversations
      actions.getConversationUsers(hoiThoaiId);

      // Add users into allUsersConversation
      actions.signalrAddUsers(camelizeKeys(nhanVienHoiThoai));
    } else {
      reloadAPI();
    }
  });

  proxy.on('deleteThanhVienHoiThoai', (hoiThoaiId, nhanVienDeleteId, hoiThoai) => {
    if (hoiThoai) {
      actions.setConversationDeleteUser({
        hoiThoai: camelizeKeys(hoiThoai),
        isDeletedMe: nhanVienDeleteId === getState()?.auth?.user,
        nhanVienDeleteId: nhanVienDeleteId,
      });
      reloadAPI();
    }
  });

  proxy.on('danhDauHoiThoai', (hoiThoai, isQuanTrong) => {
    if (hoiThoai) {
      const conversation = camelizeKeys(hoiThoai);
      actions.setImportantConversation(conversation, getState()?.auth?.user, isQuanTrong);
    }
  });

  proxy.on('taoGhimTinNhan', (hoiThoaiId, tinNhanGhim) => {
    if (hoiThoaiId && tinNhanGhim) {
      actions.setConversationPinTop(hoiThoaiId, camelizeKeys(tinNhanGhim), 'NEW');
      reloadAPI();
    }
  });

  proxy.on('boGhimTinNhan', (hoiThoaiId, tinNhanGhimId) => {
    if (hoiThoaiId && tinNhanGhimId) {
      actions.setConversationPinTop(hoiThoaiId, null, 'UNPIN', tinNhanGhimId);
      reloadAPI();
    }
  });

  proxy.on('capNhatGhimTinNhan', (hoiThoaiId, tinNhanGhim) => {
    if (hoiThoaiId && tinNhanGhim) {
      if (process.env.NODE_ENV !== 'production' || debug) {
        console.log(hoiThoaiId && tinNhanGhim);
      }
    }
  });

  proxy.on('deleteGhimTinNhan', (hoiThoaiId, tinNhanGhimId) => {
    if (hoiThoaiId && tinNhanGhimId) {
      actions.setConversationPinTop(hoiThoaiId, null, 'DELETE', tinNhanGhimId);
      reloadAPI();
    }
  });

  proxy.on('duyetNhanVienHoiThoai', (hoiThoaiId, isDuyet, userId, hoiThoai, nhanVienHoiThoai) => {
    if (process.env.NODE_ENV !== 'production' || debug) {
      console.log('duyetNhanVienHoiThoai', hoiThoaiId, isDuyet, userId, hoiThoai);
    }

    actions.setLoadingApproved(userId, null, true);
    if (nhanVienHoiThoai && nhanVienHoiThoai?.length !== 0 && hoiThoaiId && isDuyet) {
      // Add users into allUsersConversation
      actions.signalrAddUsers(camelizeKeys(nhanVienHoiThoai));

      actions.setConversationApprovedUser({
        hoiThoai: camelizeKeys(hoiThoai),
      });
    }

    reloadAPI();
  });

  proxy.on('tatThongBaoHoiThoai', (hoiThoaiId, userId) => {
    actions.handleNotificationOfConversation(hoiThoaiId, userId);
  });

  proxy.on('disableHoiThoai', (hoiThoaiId) => {
    if (hoiThoaiId) {
      actions.setConversationDelete(hoiThoaiId);
    }
  });

  proxy.on('totalConversationHasNewMessage', (total) => {
    actions.handleTotalConversationHasNewsMessage(total);
  });

  proxy.on('changePassword', (nhanVienId) => {
    if (auth?.user?.nhanVienId === nhanVienId) {
      actions.setIsLoginHasBeenChanged(true);
    }
  });

  let countManualFalse = 0;
  let status, timeoutReconnect;
  let manualStop = false;
  connection.stateChanged(function (res) {
    status = res.newState;
  });

  if (process.env.NODE_ENV !== 'production') {
    connection.received(function (arg) {
      // console.log('Signal received', arg);
    });
    connection.connectionSlow(function (arg) {
      console.log('Signal connectionSlow', arg);
    });
  }

  connection.starting(function (arg) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signal starting', arg);
    }

    actions.setReconnectingStatus(true);
  });

  connection.reconnecting(function (arg) {
    // Cố gắn kết nối lại vài lần
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signal reconnecting', arg);
    }

    actions.setReconnectingStatus(true);
  });

  connection.reconnected(function (arg) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signalr reconnected', arg);
    }

    setTimeout(() => {
      invoke('verifyConnection', []);
      if (process.env.NODE_ENV !== 'production') {
        const date = new Date();
        console.log('verifyConnection ' + date);
      }
    }, invokeTimeOut);

    actions.setReconnectingStatus(false);
  });

  proxy.on('connectFail', () => {
    // Khi action reconnected không thành công
    countManualFalse = countManualDefault;
    connection.stop();
    const date = new Date();
    console.log(`connectFail ${date}`);
    actions.setReconnectingStatus(false);
    return actions.commonAddDialog();
  });

  const manualReconnect = () => {
    if (manualStop) {
      return;
    }

    clearTimeout(timeoutReconnect);
    timeoutReconnect = setTimeout(() => {
      if (connection.state === 1 || manualStop) {
        actions.setReconnectingStatus(false);
        return;
      }

      if (process.env.NODE_ENV !== 'production' || debug) {
        console.log('Signal manual reconnect');
      }

      countManualFalse++;
      connection.stop();
      connection
        .start({ transport: ['webSockets'] })
        .done(() => {
          countManualFalse = 0;
          if (connection && connection.id) {
            // Khi kết nối lại thành công
            if (process.env.NODE_ENV !== 'production' || debug) {
              console.log('Reconnected successfully');
            }

            actions.setIsReconnect(true);
            actions.chatSetConnectionId(connection.id);
            actions.setReconnectingStatus(false);
          }
        })
        .fail(() => {
          // Khi kết nối lại không thành công
          if (countManualFalse > totalReconnect - 1) {
            actions.commonReconnectSignalR(DISCONNECT);
            actions.setReconnectingStatus(false);
            return actions.commonAddDialog();
          }
        });
    }, getRandomInt(5000, 10000));
  };

  connection.disconnected(function (arg) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Signal disconnected', arg);
    }

    if (!getState()?.surveys?.dialog) {
      // Connect đã stop và có hiển thị dialog disconnect thì không reconnect nữa
      actions.commonReconnectSignalR(DISCONNECT);
      if (countManualFalse < totalReconnect) {
        manualReconnect();
      }
    }
  });

  return {
    start: () => {
      manualStop = false;
      countManualFalse = 0;
      return connection.start({ transport: ['webSockets'] });
    },
    stop: () => {
      manualStop = true;
      return connection.stop();
    },
    connection: connection,
    status: status,
    invoke: invoke,
    proxy: proxyOn,
  };
};
