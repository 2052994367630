import React, { Component } from 'react';

export default class ImageAttachment extends Component {
  state = {
    isLoading: true,
  };
  componentDidMount = () => {
    let img = new Image();
    img.onload = () => {
      this.setState(
        {
          isLoading: false,
        },
        () => {
          const { clientHeight } = this.img;
          if (clientHeight > window.innerWidth * 0.8) {
            this.img.height = window.innerWidth * 0.8;
            this.img.width = ((window.innerWidth * 0.8) / this.img.height) * this.img.width;
          }
          this.props.initDimensionImg &&
            this.props.initDimensionImg(this.img.width, this.img.height);
        }
      );
    };
    img.src = this.props.imagePreview;
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.deg !== this.props.deg && this.img) {
      if (this.props.deg % 180 === 90) {
        this.props.initDimensionImg(this.img.height, this.img.width);
      } else {
        this.props.initDimensionImg(this.img.width, this.img.height);
      }
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <img
          className="cpc-image"
          alt="Loading Img"
          src={process.env.PUBLIC_URL + '/images/loading.gif'}
        />
      );
    } else
      return (
        <img
          ref={(el) => (this.img = el)}
          className="rotate-img"
          id="img-element"
          alt="Preview Img"
          onClick={this.props.togleDialog}
          src={this.props.imagePreview}
        />
      );
  }
}
