import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-router/Switch';
import Route from 'react-router/Route';
import withRouter from 'react-router/withRouter';
// import MobileDetect from 'mobile-detect';
import { isMobile } from 'react-device-detect';
import QueryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// const md = new MobileDetect(window.navigator.userAgent);

const RenderRoutes = ({ routes, auth, preload, history, location }) => {
  let query = QueryString.parse(location.search);
  let token = undefined;
  let hoiThoai = undefined;
  const isDownloadPage = location?.pathname === '/download';
  let fileId = query.fileId || '';
  let infoGetFileDinhKem = query.infoGetFileDinhKem || '';
  let infoGetFileDinhKemType = query.infoGetFileDinhKemType || '';
  if (query.accessToken) {
    token = {
      accessToken: query.accessToken,
      expiresIn: query.expiresIn,
      tokenType: query.tokenType,
      refreshToken: query.refreshToken,
    };
  }

  if (query?.tinNhanId) {
    hoiThoai = {
      hoiThoaiId: query?.hoiThoaiId,
      tinNhanId: query?.tinNhanId,
    };
  }

  if (!routes) {
    return null;
  }

  routes.forEach((route) => {
    if (!route.requireLogin || !route.routes) {
      return;
    }
    const requireLogin = route.requireLogin;
    route.routes.forEach((route) => (route.requireLogin = requireLogin));
  });

  if (isMobile && !isDownloadPage) {
    return <Redirect to="/download" />;
  }

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          history={history}
          render={(props) => (
            <div>
              {route.requireLogin &&
                !auth.token &&
                !token &&
                !isDownloadPage &&
                location.pathname !== route.requireLogin && (
                  <Redirect
                    to={{
                      pathname: route.requireLogin,
                      state: { from: route.path },
                    }}
                  />
                )}
              {route.preload &&
                !preload.success &&
                (auth.token || token) &&
                !isDownloadPage &&
                location.pathname !== route.preload && (
                  <Redirect
                    to={{
                      pathname: route.preload,
                      state: {
                        from: location.pathname,
                        token,
                        fileId,
                        infoGetFileDinhKem,
                        infoGetFileDinhKemType,
                        hoiThoai,
                      },
                    }}
                  />
                )}
              {(!route.requireLogin ||
                !route.preload ||
                isDownloadPage ||
                preload.success ||
                location.pathname === route.preload ||
                location.pathname === route.requireLogin) && (
                <route.component {...props} route={route} />
              )}
            </div>
          )}
        />
      ))}
    </Switch>
  );
};

RenderRoutes.propTypes = {
  routes: PropTypes.array.isRequired,
  parent: PropTypes.array,
  auth: PropTypes.object.isRequired,
  preload: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  preload: state.preload,
});

export default withRouter(connect(mapStateToProps, null)(RenderRoutes));
