import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import {
  getUserInfoV2,
  profileUpdate,
  commonAddToasterMessage,
  createAddTopChat,
  setActivateAllTab,
  setTopConversation,
} from '@/actions';
import variables from '@/constants/variables';
import CPCFlex from '@/components/atoms/flex';
import CPCInput from '@/components/atoms/input';
import CPCIcon from '@/components/atoms/icon';
import CPCAvatar from '@/components/atoms/avatar';
import CPCDialog from '@/components/atoms/dialog';
import CPCButtonV2 from '@/components/atoms/button-v2';
import './index.scss';

const InfoAccount = ({
  currentUserId,
  actions,
  history,
  typeConversation,
  data,
  open,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState([]);
  const [isAllowedToSave, setIsAllowedToSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [keyView, setKeyView] = useState(null);

  const onChat = () => {
    setIsLoading(true);
    const requiredData = {
      nhan_vien_hoi_thoai: [{ nguoi_nhan_id: data?.nhanVienId }],
      nguoi_tao_id: currentUserId,
    };
    actions.createAddTopChat(requiredData, 1).then((res) => {
      if (res?.payload?.data?.result) {
        if (history?.location?.pathname !== '/') {
          return history.push('/');
        } else {
          if (typeConversation !== 'ALL') {
            // Nếu đang active tab khác "tất cả" thì kích hoạt lại để xử lý tiếp
            actions.setActivateAllTab(true);
          } else {
            actions.setTopConversation(res.payload?.data?.result);
          }
        }
      }

      setIsLoading(false);
      return handleClose();
    });
  };

  const regexNumber = (e) => {
    const regex = /^[0-9 ]+$/;
    const str = regex.test(e?.key);
    if (!str) {
      return e.preventDefault();
    }

    return true;
  };

  const onChangefield = (name) => (value) => {
    setIsAllowedToSave(true);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    actions
      .profileUpdate({
        ngaySinh: formData?.ngaySinh,
        soDienThoai: formData?.soDienThoai?.trim(),
        soDienThoaiKhac: formData?.soDienThoaiKhac?.trim(),
        tamTrang: formData?.tamTrang?.trim(),
      })
      .then((res) => {
        setIsLoading(false);
        setIsAllowedToSave(false);
        setKeyView(null);
        if (!res) {
          return actions.commonAddToasterMessage({
            message: t('system.capnhatkhongthanhcong'),
            intent: Intent.WARNING,
          });
        }

        actions.getUserInfoV2(data?.nhanVienId);
        handleClose();
        return actions.commonAddToasterMessage({
          message: t('system.capnhatthanhcong'),
          intent: Intent.SUCCESS,
        });
      });
  }, [formData, data?.nhanVienId]);

  useEffect(() => {
    setFormData({
      ngaySinh: data?.ngaySinh,
      soDienThoai: data?.soDienThoai,
      soDienThoaiKhac: data?.soDienThoaiKhac,
      tamTrang: data?.tamTrang,
    });
    setInfos([
      {
        icon: 'gift',
        name: 'Ngày sinh',
        value: moment(data?.ngaySinh).isValid()
          ? moment(data?.ngaySinh).format(variables.DATE.FORMAT)
          : '',
        className: 'text-14-400',
      },
      {
        icon: 'status',
        name: 'Trạng thái',
        value: data?.tamTrang,
        className: 'text-14-400',
      },
      {
        icon: 'phone',
        name: 'Số điện thoại',
        value: data?.soDienThoai,
        className: 'text-14-400 info-account__phone',
      },
      {
        icon: 'phone',
        name: 'Số điện thoại khác',
        value: data?.soDienThoaiKhac,
        className: 'text-14-400 info-account__phone',
      },
      {
        icon: 'email-v2',
        name: 'Email',
        value: data?.email,
        href: `mailto:${data?.email}`,
        className: 'text-14-400 info-account__phone',
      },
      {
        icon: 'building',
        name: 'Đơn vị',
        value: data?.dsChucDanh?.find((e) => e?.phongBanChinh)?.donVi?.tenDonVi,
        className: 'text-14-400',
      },
      {
        icon: 'suitcase',
        name: 'Phòng ban',
        value: data?.dsChucDanh?.find((e) => e?.phongBanChinh)?.phongBan?.tenPhongBan,
        className: 'text-14-400',
      },
    ]);
  }, [data]);

  const renderLayout = useCallback(() => {
    if (keyView) {
      return (
        <div>
          <CPCFlex align={'center'}>
            <div style={{ minWidth: '155px' }}>
              <CPCIcon icon={'status'} color="#1552DC" size="12px" />
              <span style={{ marginLeft: '12px', color: '#7589A3' }}>Trạng thái:</span>
            </div>
            <CPCInput
              asyncControl={true}
              maxLength={100}
              placeholder="Nhập"
              theme="2"
              fill={true}
              value={formData?.tamTrang}
              onChange={(e) => onChangefield('tamTrang')(e?.target?.value)}
            />
          </CPCFlex>
          <CPCFlex align={'center'} className={'mt-10'}>
            <div style={{ minWidth: '155px' }}>
              <CPCIcon icon={'phone'} color="#1552DC" size="16px" />
              <span style={{ marginLeft: '12px', color: '#7589A3' }}>Số điện thoại:</span>
            </div>
            <CPCInput
              asyncControl={true}
              maxLength={100}
              placeholder="Nhập"
              theme="2"
              fill={true}
              value={formData?.soDienThoai}
              onKeyPress={regexNumber}
              onChange={(e) => onChangefield('soDienThoai')(e?.target?.value)}
            />
          </CPCFlex>
          <CPCFlex align={'center'} className={'mt-10'}>
            <div style={{ minWidth: '155px' }}>
              <CPCIcon icon={'phone'} color="#1552DC" size="16px" />
              <span style={{ marginLeft: '12px', color: '#7589A3' }}>Số điện thoại khác:</span>
            </div>
            <CPCInput
              asyncControl={true}
              maxLength={100}
              placeholder="Nhập"
              theme="2"
              fill={true}
              value={formData?.soDienThoaiKhac}
              // onKeyPress={regexNumber}
              onChange={(e) => onChangefield('soDienThoaiKhac')(e?.target?.value)}
            />
          </CPCFlex>
          <CPCFlex align={'center'}></CPCFlex>
        </div>
      );
    }

    return (
      <ul>
        {infos?.map((elm, index) => (
          <li key={index} className={elm?.className}>
            <CPCIcon
              icon={elm?.icon}
              color="#1552DC"
              size={elm.icon === 'status' ? '12px' : '16px'}
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <div>
              {elm?.name}:{' '}
              {elm?.href ? (
                <b>
                  <a href={elm?.href} target="_top">
                    {elm?.value}
                  </a>
                </b>
              ) : (
                <b>{elm?.value}</b>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  }, [infos, formData, keyView]);

  const renderAction = useCallback(() => {
    if (keyView) {
      return (
        <div>
          <CPCButtonV2
            text={'Hủy'}
            classNameText={'text-14-600'}
            color={'light-gray'}
            size={'xs-m'}
            min={'90'}
            disabled={isLoading || false}
            onClick={() => setKeyView(null)}
          />
          <CPCButtonV2
            text={'Cập nhật'}
            className={'ml-20'}
            classNameText={'text-14-600'}
            color={'blue'}
            size={'xs-m'}
            min={'90'}
            disabled={!isAllowedToSave || isLoading}
            loading={isLoading}
            onClick={onSubmit}
          />
        </div>
      );
    }

    if (currentUserId === data?.nhanVienId) {
      return (
        <CPCButtonV2
          text={'Chỉnh sửa thông tin'}
          classNameText={'text-14-600'}
          color={'blue'}
          size={'xs-m'}
          loading={false}
          onClick={() => setKeyView('FORM')}
        />
      );
    } else {
      return (
        <CPCButtonV2
          text={'Nhắn tin'}
          classNameText={'text-14-600'}
          color={'blue'}
          size={'xs-m'}
          min={'90'}
          loading={isLoading}
          disabled={isLoading}
          onClick={onChat}
        />
      );
    }
  }, [currentUserId, keyView, isAllowedToSave, formData, isLoading]);

  return (
    <CPCDialog
      className={'max-500'}
      title={keyView === 'FORM' ? 'Chỉnh sửa thông tin' : 'Thông tin'}
      isOpen={open}
      onClose={handleClose}
      footerElement={
        <CPCFlex align={'center'} justify={'center'}>
          {renderAction()}
        </CPCFlex>
      }
    >
      <CPCFlex
        align={'center'}
        justify={'center'}
        direction={'column'}
        className={'p-20 info-account'}
      >
        <CPCFlex
          align={'center'}
          justify={'center'}
          direction={'column'}
          className={'pl-20 pr-20 pb-20'}
        >
          <CPCAvatar src={data?.anhDaiDien} size="lg" trucTuyen={data?.trucTuyen} />
          {data?.tenNhanVien && <div className="text-16-700 mt-15">{data?.tenNhanVien}</div>}
          {data?.tamTrang && (
            <div className="text-14-400 mt-5" style={{ color: '#7589A3' }}>
              "{data?.tamTrang}"
            </div>
          )}
        </CPCFlex>
        <div className="border-top pt-20" style={{ width: '100%' }}>
          {renderLayout()}
        </div>
      </CPCFlex>
    </CPCDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUserId: state.auth?.user,
    typeConversation: state.chat?.typeConversation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getUserInfoV2,
      profileUpdate,
      commonAddToasterMessage,
      createAddTopChat,
      setActivateAllTab,
      setTopConversation,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoAccount);
