import Heading from '@/components/layout/heading';
import FormGroup from '@/components/molecules/form-group';
import LayoutMain from '@/components/organisms/layout-main';
import { Dialog } from '@blueprintjs/core';
import classnames from 'classnames';
import _ from 'lodash';
import { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import { DataTable } from '../../../components/common';
import { ListItemStatistic } from '../../../components/hopthugopy';
import { statisticsList } from '../../../components/hopthugopy/ListHeader';
import { FE_URL } from '../../../constants/Api';
import { variables } from '../../../constants/variables';
import { checkQuyenChucVu } from '../../../helpers/helper';
import './../assets/common.scss';
import './../assets/hopthugopy.scss';
import CPCIcon from '@/components/atoms/icon';

const getPreviousMonth = (currentDate) => {
  currentDate.setMonth(currentDate.getMonth() - 1);
  return currentDate;
};

const getNextDay = (currentDate) => {
  currentDate.setDate(currentDate.getDate());
  return currentDate;
};

class FeedbackStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: getPreviousMonth(new Date()),
      toDate: getNextDay(new Date()),
      isOpenPopup: false,
      thongKeModel: {},
      iconChiTiet: 'tot.svg',
      isLoadingTable: false,
      isEmptyTable: false,
      isErrorTable: false,
      isEmpty: false,
      isError: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getGetStatistic();
  }

  getGetStatistic = () => {
    let params = {
      fromDate: this.getDateCover(this.state.fromDate, 'FROM') || undefined,
      toDate: this.getDateCover(this.state.toDate, 'TO') || undefined,
    };
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.actions.feedbackGetStatistic(params).then((res) => {
          this.setState({
            isLoading: false,
          });
        });
      }
    );
  };

  handleChangeFromDate = (date) => {
    this.setState({ fromDate: date }, () => this.getGetStatistic());
  };

  handleChangeToDate = (date) => {
    this.setState({ toDate: date }, () => this.getGetStatistic());
  };

  OpenThongKe = (e, chatLuongId, maChatLuong, tenChatLuong) => {
    e.preventDefault();
    this.setState(
      {
        isOpenPopup: true,
        isLoadingTable: true,
      },
      () => {
        if (!chatLuongId) {
          return false;
        }

        let params = {
          fromDate: this.getDateCover(this.state.fromDate, 'FROM'),
          toDate: this.getDateCover(this.state.toDate, 'TO'),
        };
        this.props.actions.feedbackGetStatisticDetail(chatLuongId, params).then((res) => {
          let isEmptyTable = false;
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.request &&
            res.payload.request.status === 200
          ) {
            this.setState({
              thongKeModel: res.payload.data.result,
              iconChiTiet: this.iconShow(maChatLuong),
            });
          } else {
            isEmptyTable = true;
          }

          if (res && res.payload && res.payload.data && res.payload.data.result === null) {
            this.setState({
              thongKeModel: {
                tenChatLuong,
              },
              iconChiTiet: this.iconShow(maChatLuong),
            });
          }

          if (!res) {
            this.setState({
              isOpenPopup: false,
              isLoadingTable: false,
              isEmptyTable: true,
            });
          }

          this.setState({
            isLoadingTable: false,
            isEmptyTable,
          });
        });
      }
    );
  };

  getDateCover = (dateValue, timeline) => {
    if (!timeline || !dateValue) {
      return undefined;
    }

    let dataDate = new Date(dateValue);
    let dateResult = new Date();
    if (timeline.toUpperCase() === 'FROM') {
      dateResult = new Date(
        dataDate.getUTCFullYear(),
        dataDate.getUTCMonth(),
        dataDate.getUTCDate(),
        0,
        0,
        0
      );
    } else {
      dateResult = new Date(
        dataDate.getUTCFullYear(),
        dataDate.getUTCMonth(),
        dataDate.getUTCDate(),
        23,
        59,
        59
      );
    }

    return dateResult.toISOString();
  };

  handleClosePopup = () => {
    this.setState({
      isOpenPopup: false,
    });
  };

  tinhPhanTram = (soCanTinh) => {
    let tongSoLuotDanhGia = this.props.statistic.tongSoLuotDanhGia;
    let soPhanTram = Math.ceil((soCanTinh * 100) / tongSoLuotDanhGia);
    return `${soPhanTram}%`;
  };

  iconShow = (maChatLuong) => {
    switch (maChatLuong) {
      case variables.CHAT_LUONG.TOT:
        return 'tot.svg';
      case variables.CHAT_LUONG.BINH_THUONG:
        return 'binh-thuong.svg';
      case variables.CHAT_LUONG.CHUA_TOT:
        return 'chua-tot.svg';
      default:
        return 'binh-thuong.svg';
    }
  };

  handleGoBack = () => {
    return this.props.history.push('/hop-thu-gop-y');
  };

  handleOpenEoffice = () => {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  };

  render() {
    const { iconChiTiet, thongKeModel } = this.state;
    const { statistic } = this.props;
    const leftElement = (
      <div className="date-filter">
        <FormGroup
          kind="date"
          inputProps={{
            maxDate: this.state.toDate,
            selected: this.state.fromDate,
            dateFormat: 'dd/MM/yyyy',
            onChange: (date) => this.handleChangeFromDate(date),
            className: 'theme-2',
          }}
        />
        <FormGroup
          kind="date"
          inputProps={{
            minDate: this.state.fromDate,
            selected: this.state.toDate,
            dateFormat: 'dd/MM/yyyy',
            onChange: (date) => this.handleChangeToDate(date),
            className: 'theme-2',
          }}
        />
      </div>
    );
    return (
      <LayoutMain {...this.props.props} history={this.props.history}>
        <div className="section">
          <Heading
            title="Thống kê góp ý"
            isGoback={true}
            handleGoBack={this.handleGoBack}
            leftElement={leftElement}
          />
          <div className="cpc-container">
            <div className="cpc-content p-0">
              {/* <div className="cpc-content__label statistic">
                Thống kê góp ý:{' '}
                <span className="text-red">{statistic?.tongSoLuotDanhGia || '0'}</span>
              </div> */}
              <div className="cpc-content__body">
                <div>
                  <ul className="slider-status">
                    {statistic &&
                      statistic.dsDanhGia &&
                      statistic.dsDanhGia.length !== 0 &&
                      statistic.dsDanhGia.map((item, key) => {
                        let phanTram = '0%';
                        let icon = 'tot.svg';
                        phanTram = this.tinhPhanTram(item.soLuotDanhGia);
                        icon = this.iconShow(item.maChatLuong);
                        return (
                          <li key={key}>
                            <div className="status-smile">
                              <span
                                className="icon"
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/${icon}?cache=${icon})`,
                                }}
                              />
                              <h4>{item.tenChatLuong}</h4>
                            </div>
                            <div
                              className={classnames('slider-bar', {
                                'slider-bar-two': !checkQuyenChucVu(this.props.auth, [
                                  'Superadmin',
                                ]),
                              })}
                            >
                              <span>
                                <span
                                  className="active"
                                  style={{
                                    width: this.state.isLoading ? '0%' : phanTram,
                                  }}
                                ></span>
                              </span>
                              <div className="num-count">{item.soLuotDanhGia}</div>
                            </div>
                            {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
                              <div className="open-statistics">
                                <a
                                  onClick={(e) =>
                                    this.OpenThongKe(
                                      e,
                                      item.chatLuongId,
                                      item.maChatLuong,
                                      item.tenChatLuong,
                                      {
                                        fromDate: this.state.fromDate,
                                        toDate: this.state.toDate,
                                      }
                                    )
                                  }
                                >
                                  Thống kê người góp ý
                                  <CPCIcon icon="arrow-right2 pl-6" size="14px" color="#1552DC" />
                                </a>
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                  <Dialog
                    isOpen={this.state.isOpenPopup}
                    onClose={this.handleClosePopup}
                    className="dialog-common dialog-statistics"
                    title="Thống kê người góp ý"
                    style={{ minWidth: '750px', maxWidth: '750px' }}
                  >
                    <div className="statistics-popup-headding">
                      <div className="row">
                        <div className="col-50">
                          <span
                            className="icon"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/images/${iconChiTiet}?cache=${iconChiTiet})`,
                            }}
                          />
                          <span>{thongKeModel.tenChatLuong}</span>
                        </div>
                        <div className="col-50">
                          <span className="wrap-total">
                            <span className="total-feedback">Tổng số người đánh giá</span>
                            <i>{_.get(thongKeModel, 'soLuotDanhGia') || 0}</i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="statistics-popup-body">
                      <h5>Danh sách câu hỏi & đáp án</h5>
                      <Scrollbars
                        autoHeight={true}
                        autoHeightMax={500}
                        ref={(e) => (this.scroll = e)}
                      >
                        <DataTable
                          isLoading={this.state.isLoadingTable}
                          data={thongKeModel.dsCauHoi || []}
                          classNames={'table middle statistics-table'}
                          page={1}
                          headers={statisticsList}
                          itemShape={ListItemStatistic}
                          isEmpty={this.state.isEmptyTable}
                          isError={this.state.isErrorTable}
                          auth={this.props.auth}
                        />
                      </Scrollbars>
                    </div>
                  </Dialog>
                </div>
                <div className="statistics-total-block">
                  <div className="total-reviews-center">
                    <div className="total-reviews-center-box">
                      <div className="total-reviews">
                        <div className="img">
                          <span
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/images/group-img-v2.svg)`,
                            }}
                          ></span>
                        </div>
                        <div className="total">
                          <span>{statistic.tongSoLuotDanhGia || 0}</span>
                          <h4>Tổng số đánh giá</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    );
  }
}

const mapStateToProps = (state) => ({
  statistic: state.feedback.statistic,
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackStatistics);
