import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import _ from 'lodash';
import classnames from 'classnames';
import { decamelizeKeys } from 'humps';
import { Position, Toaster, Intent } from '@blueprintjs/core';
import { Header } from '../../components/layout';
import { Section as ContactSection, Profile, Config } from '../../components/contact';
import { Section as ChatSection, Search, DocumentCabinet } from '../../components/chat';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FE_URL } from '../../constants/Api';
import * as Actions from '../../actions';
import './assets/home.scss';
import moment from 'moment';
import { API_XAC_THUC_DANG_NHAP } from '../../constants/Api';
import CryptoAES from 'crypto-js/aes';
import { Cookies } from 'react-cookie';
import LimitConfirm from '../../components/chat/common/LimitConfirm';
import Sidebar from '../../components/organisms/sidebar';

const CABINET_UNIT = '1';
const CABINET_DEPARTMENT = '2';
const CABINET_PERSONAL = '3';
const HOT_LINE = '0975 909 779';
const CHECK_BIRTHDAY_TIME = 5000;

class Home extends React.Component {
  static propTypes = {
    contact: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    showContact: true,
    showSearchMessages: false,
    closeSearchMessages: true,
    showCabinet: false,
    showConfig: false,
    files: [],
    fileUpload: {},
    chatStyles: {},
    fileDinhKem: [],
  };

  constructor(props) {
    super(props);
    this.toggleContact = this.toggleContact.bind(this);
    this.toggleSearchMessage = this.toggleSearchMessage.bind(this);
    this.handleForwardMessage = this.handleForwardMessage.bind(this);
    this.handleResizeWidow = this.handleResizeWidow.bind(this);
    this.handleSubmitProfile = this.handleSubmitProfile.bind(this);
    this.handleOpenEoffice = this.handleOpenEoffice.bind(this);
    this.handleCopyAndForwardMessage = this.handleCopyAndForwardMessage.bind(this);
  }

  refHandlers = {
    chatSection: (ref: ChatSection) => (this.chatSection = ref),
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  handleGetBirthDayUserList = async () => {
    let birthDayUserListGetDate =
      sessionStorage.getItem('birthDayUserListGetDate') &&
      moment(sessionStorage.getItem('birthDayUserListGetDate'));
    let currentDate = moment();
    if (
      !birthDayUserListGetDate ||
      (birthDayUserListGetDate &&
        !birthDayUserListGetDate.isSame(currentDate, 'date') &&
        currentDate.hour() >= 3 &&
        currentDate.minute() <= 1 &&
        currentDate.second() <= 5)
    ) {
      await this.props.actions.getUserConfig();
      await this.props.actions.getBirthDayUserList();
      sessionStorage.setItem('birthDayUserListGetDate', currentDate.format());
      return window.location.reload(true);
    }
  };
  generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = ~~((d + Math.random() * 16) % 16);
      d = ~~(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  get_device() {
    var module = {
      options: [],
      header: [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera,
      ],
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],

      init: function () {
        var agent = this.header.join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser);
        return { os: os, browser: browser };
      },
      matchItem: function (string, data) {
        var i = 0,
          j = 0,
          regex,
          regexv,
          match,
          matches,
          version;
        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i');
          match = regex.test(string);
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) {
              if (matches[1]) {
                matches = matches[1];
              }
            }
            if (matches) {
              matches = matches.split(/[._]+/);
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.';
                } else {
                  version += matches[j];
                }
              }
            } else {
              version = '0';
            }
            return {
              name: data[i].name,
              version: parseFloat(version),
            };
          }
        }
        return { name: 'unknown', version: 0 };
      },
    };
    var e = module.init(),
      debug = '';
    debug += '' + e.os.name + ' ';
    debug += '' + e.os.version + ', ';
    debug += '' + e.browser.name + '';
    return debug;
  }

  checkFirstLogin() {
    //check login lan dau
    const cookies = new Cookies();
    const checkin_maxacnhan_cookies = cookies.get('checkin_maxacnhan_cookies')
      ? cookies.get('checkin_maxacnhan_cookies')
      : '';
    const isNewLogin = this.props.auth.user.username !== localStorage['user_login'];
    localStorage['user_login'] = this.props.auth.user.username;
    let MA_XACNHAN = localStorage['checkin_maxacnhan'];
    if (
      checkin_maxacnhan_cookies === undefined ||
      checkin_maxacnhan_cookies === '' ||
      checkin_maxacnhan_cookies === null ||
      checkin_maxacnhan_cookies === 'null'
    ) {
      cookies.set('checkin_maxacnhan_cookies', this.generateUUID(), { path: '/' });
    }

    if (
      MA_XACNHAN === undefined ||
      MA_XACNHAN === '' ||
      MA_XACNHAN === null ||
      MA_XACNHAN === 'null'
    ) {
      localStorage['checkin_maxacnhan'] = this.generateUUID();
      MA_XACNHAN = localStorage['checkin_maxacnhan'];
    }
    let login = {
      TAIKHOAN: encodeURIComponent(CryptoAES.encrypt(this.props.auth.user.username, 'hoa3004')),
      MA_UNGDUNG: 'EO_CHAT',
      MA_XACNHAN_STORAGE: MA_XACNHAN,
      MA_XACNHAN_COOKIE: cookies.get('checkin_maxacnhan_cookies'),
      TEN_THIETBI: this.get_device(),
      HOST_NAME: window.location.href,
      IP: '',
      LAT: '',
      LONG: '',
      KEY: isNewLogin ? '/VqC9jG0xNs=' : 'Iw87fGcomEZcl2AvIfdKhg==',
    };

    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          login.LAT = position.coords.latitude;
          login.LONG = position.coords.longitude;
        },
        (error) => {}
      );
    }

    fetch(
      API_XAC_THUC_DANG_NHAP +
        `?TAIKHOAN=${login.TAIKHOAN}&MA_UNGDUNG=${login.MA_UNGDUNG}&MA_XACNHAN_STORAGE=${login.MA_XACNHAN_STORAGE}&MA_XACNHAN_COOKIE=${login.MA_XACNHAN_COOKIE}&TEN_THIETBI=${login.TEN_THIETBI}&HOST_NAME=${login.HOST_NAME}&IP=${login.IP}&LAT=${login.LAT}&LONG=${login.LONG}&KEY=${login.KEY}`,
      {
        mode: 'no-cors',
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'length',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: -1,
        },
      }
    );
  }

  componentDidMount() {
    this.checkFirstLogin();
    this.props.actions.getListEmoticon();
    this.props.actions.getBirthDayUserList();
    let currentDate = moment().format();
    sessionStorage.setItem('birthDayUserListGetDate', currentDate);
    this.intervalGetBirthDayUserList = setInterval(
      this.handleGetBirthDayUserList,
      CHECK_BIRTHDAY_TIME
    );
    this.handleResizeWidow();
    window.addEventListener('resize', this.handleResizeWidow);
    // if (this.props.fileIdFromEoffice) {
    //   this.props.actions.getFileInformation(this.props.fileIdFromEoffice).then((res) => {
    //     if (res && res.payload && res.payload.data && res.payload.data.result !== null) {
    //       let file = res.payload.data.result;
    //       this.setState(
    //         {
    //           fileUpload: {
    //             id: file.fileId,
    //             name: 'eofficeFile-' + file.tenFile,
    //             info: {
    //               id: file.fileId,
    //               original_name: 'eofficeFile-' + file.tenFile,
    //               path: file.url,
    //               type: file.kieuFile,
    //               length: file.kichThuoc,
    //             },
    //           },
    //         },
    //         () => this.props.actions.setFileIdFromEoffice('')
    //       );
    //     }
    //   });
    // }

    // TODO: Danh sách file đính kèm nhận được từ eoffice
    // if (this.props.fileDinhKemFromEoffice && this.props.fileDinhKemFromEoffice.id) {
    //   this.processData().then((res) => {
    //     this.setState(
    //       {
    //         fileDinhKem: res,
    //       },
    //       () => {
    //         this.props.actions.setInfoGetFileDinhKemFromEoffice('', '');
    //       }
    //     );
    //   });
    // }
  }

  // processData = async () => {
  //   let result = [];
  //   await this.props.actions
  //     .getInfoFileDinhKemFromEoffice(
  //       this.props.fileDinhKemFromEoffice.id,
  //       this.props.fileDinhKemFromEoffice.type
  //     )
  //     .then((res) => {
  //       if (
  //         res &&
  //         res.payload &&
  //         res.payload.data &&
  //         res.payload.data.result &&
  //         res.payload.data.result.items
  //       ) {
  //         res.payload.data.result.items.forEach((item) => {
  //           return result.push({
  //             id: item.fileId,
  //             name: 'eofficeFile-' + item.tenFile,
  //             info: {
  //               id: item.fileId,
  //               original_name: 'eofficeFile-' + item.tenFile,
  //               path: item.url,
  //               type: item.kieuFile,
  //               length: item.kichThuoc,
  //             },
  //           });
  //         });
  //       }
  //     });
  //   result = await Promise.all(result);

  //   return result;
  // };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWidow);
    this.intervalGetBirthDayUserList && clearInterval(this.intervalGetBirthDayUserList);
  }

  handleResizeWidow = () => {
    this.setState({ chatStyles: this.chatStyles() });
  };

  handleForwardMessage = (message) => {
    this.chatSection.handleForwardMessage(message);
  };

  handleCopyAndForwardMessage = (message) => {
    this.chatSection.handleCopyAndForwardMessage(message);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.contact.showProfile && this.props.contact.showProfile) {
      this.setState({ showSearchMessages: false });
    }
    if (
      prevState.showContact !== this.state.showContact ||
      prevState.showSearchMessages !== this.state.showSearchMessages ||
      prevProps.contact.showProfile !== this.props.contact.showProfile
    ) {
      this.handleResizeWidow();
    }
  }

  toggleContact = () => {
    this.setState({ showContact: !this.state.showContact });
  };

  toggleSearchMessage = (close = true) => {
    this.setState(
      {
        closeSearchMessages: close ? this.state.showSearchMessages : false,
        showSearchMessages: !this.state.showSearchMessages,
        showContact: this.state.showSearchMessages,
        showConfig: false,
      },
      () => {
        if (this.state.closeSearchMessages) {
          this.props.actions.chatClearSearch();
        }

        if (this.state.showSearchMessages && this.props.contact.showProfile) {
          this.props.actions.contactShowProfile(null);
        }
      }
    );
  };

  hiddenSearchMessage = () => {
    this.toggleSearchMessage(false);
  };

  handleSubmitProfile(data) {
    return this.props.actions
      .profileUpdate(data)
      .then((res) => {
        if (res.error) {
          return this.toaster.show({
            message: 'Có lỗi xảy ra, vui lòng thử lại.',
            intent: Intent.DANGER,
          });
        }

        return this.toaster.show({
          message: 'Cập nhật thông tin thành công.',
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        return this.toaster.show({
          message: 'Có lỗi xảy ra, vui lòng thử lại.',
          intent: Intent.DANGER,
        });
      });
  }

  handleOpenEoffice() {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  }

  handleOpenSaveDocument = (files) => {
    files = files.filter((file) => {
      if (file.kieuFile.indexOf('image') === -1) {
        return true;
      }

      return false;
    });

    this.setState({
      files,
      showCabinet: true,
      showSearchMessages: false,
      showConfig: false,
    });
  };

  handleCloseSaveDocument = () => {
    this.setState({
      showCabinet: false,
    });
  };

  handleChangeCabinetType = (type) => {
    switch (type) {
      case CABINET_UNIT:
        this.props.actions.documentsCabinetGetListCategoriesUnit();
        break;
      case CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal();
        break;
      case CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment();
        break;
      default:
    }
  };

  handleSaveDocument = (type, form) => {
    let request;

    if (!form.tenTaiLieu || form.tenTaiLieu === '') {
      return this.toaster.show({ message: 'Nhập nội dung tin nhắn.', intent: Intent.WARNING });
    }

    if (!form.dsNganTu || form.dsNganTu.length === 0) {
      return this.toaster.show({ message: 'Chưa chọn ngăn tủ.', intent: Intent.WARNING });
    }

    form.fileNoiDung = this.state.files[0];

    let data = decamelizeKeys(form);

    switch (type) {
      case CABINET_UNIT:
        request = this.props.actions.documentsCabinetNewItemUnit(data);
        break;
      case CABINET_PERSONAL:
        request = this.props.actions.documentsCabinetNewItemPersonal(data);
        break;
      case CABINET_DEPARTMENT:
        request = this.props.actions.documentsCabineNewItemDepartment(data);
        break;
      default:
    }

    this.setState({ loadingSaveDocument: true });
    return request
      .then((res) => {
        if (!res.error && res.payload.data.result) {
          return this.toaster.show({
            message: 'Lưu tủ tài liệu thành công.',
            intent: Intent.SUCCESS,
          });
        }

        return this.toaster.show({ message: 'Lưu tủ tài liệu thất bại.', intent: Intent.DANGER });
      })
      .then(() => {
        this.setState({
          showCabinet: false,
          loadingSaveDocument: false,
        });
      });
  };

  handleToggleConfig = () => {
    this.setState(
      {
        showCabinet: this.state.showConfig ? false : this.state.showCabinet,
        showSearchMessages: this.state.showConfig ? false : this.state.showSearchMessages,
        showConfig: !this.state.showConfig,
      },
      () => {
        if (this.state.showConfig && this.props.contact.showProfile) {
          this.props.actions.contactShowProfile(null);
        }
      }
    );
  };

  handleSaveConfig = (data) => {
    return this.props.actions
      .configUpdate(data)
      .then((res) => {
        if (res.error) {
          return this.toaster.show({
            message: 'Có lỗi xảy ra, vui lòng thử lại.',
            intent: Intent.DANGER,
          });
        }

        return this.toaster.show({
          message: 'Cập nhật thông tin thành công.',
          intent: Intent.SUCCESS,
        });
      })
      .then(this.props.actions.getUserConfig)
      .catch((err) => {
        return this.toaster.show({
          message: 'Có lỗi xảy ra, vui lòng thử lại.',
          intent: Intent.DANGER,
        });
      });
  };

  render() {
    return (
      <div>
        <Header
          actions={this.props.actions}
          auth={this.props.auth}
          onClickSearch={this.toggleSearchMessage}
          onOpenEoffice={this.handleOpenEoffice}
          onOpenConfig={this.handleToggleConfig}
          receivers={this.props.chat.receivers}
        />
        <style>
          {`:root {
              --deg: ${this.props.deg}deg
            }`}
        </style>
        <div className="cpc-container-fluid">
          <Sidebar actions={this.props.actions} />
          {this.state.showContact && (
            <div className="cpc-contact-container" style={this.contactStyles()}>
              <ContactSection
                actions={this.props.actions}
                auth={this.props.auth}
                contact={this.props.contact}
                units={this.props.contact.units}
                receivers={this.props.chat.receivers}
                viewUnit={this.props.contact.viewUnit}
              />
              <div className="cpc-contact-toggle" onClick={this.toggleContact}>
                <span className="pt-icon-chevron-left" />
              </div>
              <div className="hot-line">
                <span className="icon-old-typical-phone"></span>
                {HOT_LINE}
              </div>
            </div>
          )}
          {!this.state.showContact && (
            <div className="cpc-contact-toggle-on" onClick={this.toggleContact}>
              <span className="pt-icon-chevron-right" />
            </div>
          )}
          {/* <div className="cpc-chat-container" style={this.state.chatStyles}> */}
          <div className="cpc-chat-container">
            <ChatSection
              ref={this.refHandlers.chatSection}
              delivered={this.props.chat.delivered}
              auth={this.props.auth}
              actions={this.props.actions}
              messages={this.props.chat.messages}
              receivers={this.props.chat.receivers}
              favourites={this.props.contact.favourites}
              conversationId={this.props.chat.conversationId}
              onLoadReceiverUsers={this.props.chat.loadReceiverUsers}
              onSaveDocument={this.handleOpenSaveDocument}
              newsMessages={this.props.chat.news}
              reconnectSignalR={this.props.reconnectSignalR}
              fileUpload={this.state.fileUpload}
              customText={this.props.customText}
              fileDinhKem={this.state.fileDinhKem}
            />
            <LimitConfirm />
          </div>
          {this.props.contact.showProfile && (
            // <div className="cpc-profile-container" style={this.profileStyles()}>
            <div className="cpc-profile-container">
              <Profile
                me={this.props.auth.user}
                user={this.props.contact.showProfile}
                actions={this.props.actions}
                excludeShowUnit={this.props.auth.mainUnitId}
                onSubmit={this.handleSubmitProfile}
              />
            </div>
          )}
          {!this.state.closeSearchMessages && (
            <div className="cpc-search-messages-container">
              {/* <div className="cpc-search-messages-container" style={this.searchStyles()}> */}
              <Search
                auth={this.props.auth}
                actions={this.props.actions}
                receivers={this.props.chat.receivers}
                units={this.props.contact.units}
                onClose={this.toggleSearchMessage}
                searchMessages={this.props.chat.searchMessages}
                suggestUsers={this.props.chat.suggestUsers}
                onForwardMessage={this.handleForwardMessage}
                onSaveDocument={this.handleOpenSaveDocument}
                onCopyAndForward={this.handleCopyAndForwardMessage}
              />
              <div className="cpc-search-toggle-on" onClick={this.hiddenSearchMessage}>
                <span
                  className={classnames({
                    'pt-icon-chevron-right': this.state.showSearchMessages,
                    'pt-icon-chevron-left': !this.state.showSearchMessages,
                  })}
                />
              </div>
            </div>
          )}
          {!this.props.contact.showProfile && this.state.showConfig && (
            // <div className="cpc-config-container" style={this.configStyle()}>
            <div className="cpc-config-container">
              <Config
                actions={this.props.actions}
                auth={this.props.auth}
                onClose={this.handleToggleConfig}
                config={this.props.auth.config}
                onSubmit={this.handleSaveConfig}
                units={this.props.contact.units}
              />
            </div>
          )}
          {!this.props.contact.showProfile && this.state.showCabinet && (
            // <div className="cpc-search-messages-container" style={this.cabinetStyle()}>
            <div className="cpc-search-messages-container">
              <DocumentCabinet
                files={this.state.files}
                permissions={this.props.auth.permissions}
                categories={this.props.cabinet.categories}
                onChangeType={this.handleChangeCabinetType}
                onSubmit={this.handleSaveDocument}
                onClose={this.handleCloseSaveDocument}
                isLoading={this.state.loadingSaveDocument}
              />
            </div>
          )}
        </div>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }

  chatStyles = () => {
    let width = this.bodyWidth();

    let left = this.contactWidth();
    width -= this.contactWidth();
    if (width < 610) {
      width += this.contactWidth();
      left = 0;
    }

    width -= this.searchWidth();
    if (width < 610) {
      width += this.searchWidth();
    }

    width -= this.profileWidth();
    if (width < 610) {
      width += this.profileWidth();
    }

    return {
      width: width,
      left: left,
    };
  };

  contactStyles = () => {
    return {
      width: this.contactWidth(),
    };
  };

  profileStyles = () => {
    return {
      width: this.profileWidth(),
    };
  };

  searchStyles = () => {
    return {
      width: this.searchWidth(),
    };
  };

  cabinetStyle = () => {
    return {
      width: this.cabinetWidth(),
    };
  };

  configStyle = () => {
    return {
      width: this.configWidth(),
    };
  };

  contactWidth = () => {
    return this.state.showContact ? 349 : 0;
  };

  searchWidth = () => {
    return this.state.showSearchMessages ? this.bodyWidth() / 2 : 0;
  };

  cabinetWidth = () => {
    return this.state.showCabinet ? 419 : 0;
  };

  configWidth = () => {
    return this.state.showConfig ? 419 : 0;
  };

  profileWidth = () => {
    return this.props.contact.showProfile ? 419 : 0;
  };

  bodyWidth = () => {
    return document.body.clientWidth;
  };
}

const messagerEmoticon = (emoticonUpdate, message) => {
  return emoticonUpdate.findIndex(
    (elm) => elm.hoiThoaiId === message.hoiThoaiId && elm.tinNhanId === message.tinNhanId
  );
};

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
      user: state.entities.users[state.auth.user],
    },
    contact: {
      ...state.contact,
      viewUnit: state.entities.units[state.contact.viewUnit]
        ? {
            ...state.entities.units[state.contact.viewUnit],
            dsPhongBan: state.entities.units[state.contact.viewUnit].dsPhongBan.map((id) => {
              const department = state.entities.departments[id];
              return {
                ...department,
                dsNhanVien: department.dsNhanVien
                  .map((id) => state.entities.users[id])
                  .filter((user) => {
                    if (state.contact.filterOnline) {
                      return user.trucTuyen === 1;
                    }

                    return true;
                  }),
              };
            }),
          }
        : {},
      units: (() => {
        let { units } = state.contact;

        if (
          state.entities.units[state.auth.mainUnitId] &&
          units.indexOf(state.auth.mainUnitId) === -1
        ) {
          units.push(state.auth.mainUnitId);
        }

        return units.map((id) => {
          const unit = state.entities.units[id];
          if (!unit.dsPhongBan) {
            unit.dsPhongBan = [];
          }

          const getUnit = (data) => {
            if (!data || !_.isArray(data.dsDonViCon)) {
              return [];
            }

            return data.dsDonViCon.map((id) => {
              const unit = state.entities.units[id];
              return {
                ...unit,
                dsDonViCon: getUnit(unit),
                dsPhongBan: unit.dsPhongBan
                  ? unit.dsPhongBan.map((id) => {
                      const department = state.entities.departments[id];
                      return {
                        ...department,
                        dsNhanVien: department.dsNhanVien
                          .map((id) => state.entities.users[id])
                          .filter((user) => {
                            if (state.contact.filterOnline) {
                              return user.trucTuyen === 1;
                            }

                            return true;
                          }),
                      };
                    })
                  : [],
              };
            });
          };

          return {
            ...unit,
            dsPhongBan: unit.dsPhongBan.map((id) => {
              const department = state.entities.departments[id];
              return {
                ...department,
                dsNhanVien: department.dsNhanVien
                  .map((id) => state.entities.users[id])
                  .filter((user) => {
                    if (state.contact.filterOnline) {
                      return user.trucTuyen === 1;
                    }

                    return true;
                  }),
              };
            }),
            dsDonViCon: getUnit(unit),
          };
        });
      })(),
      favourites: state.contact.favourites.map((id) => {
        const favourite = state.entities.favourites[id];
        if (!favourite.dsNhanVien) {
          favourite.dsNhanVien = [];
        }
        return {
          ...favourite,
          users: favourite.dsNhanVien.map((item) => state.entities.users[item.nhanVienId]),
        };
      }),
      showProfile: state.contact.showProfile
        ? state.entities.users[state.contact.showProfile]
        : null,
      searchUnit: {
        ...state.contact.searchUnit,
        items: state.contact.searchUnit.items
          .map((id) => state.entities.users[id])
          .filter((user) => {
            if (state.contact.filterOnline) {
              return user.trucTuyen === 1;
            }

            return true;
          }),
      },
      searchAll: {
        ...state.contact.searchAll,
        items: state.contact.searchAll.items
          .map((id) => state.entities.users[id])
          .filter((user) => {
            if (state.contact.filterOnline) {
              return user.trucTuyen === 1;
            }

            return true;
          }),
      },
      searchFavourite: {
        ...state.contact.searchFavourite,
        items: state.contact.searchFavourite.items
          .map((id) => state.entities.users[id])
          .filter((user) => {
            if (state.contact.filterOnline) {
              return user.trucTuyen === 1;
            }

            return true;
          }),
      },
    },
    chat: {
      ...state.chat,
      receivers: state.chat?.receivers.map((id) => {
        if (state.entities.conversations[id]) {
          return state.entities.conversations[id];
        }
        const user = state.chat.birthdayUserList
          ? state.chat.birthdayUserList.find((item) => item.nhanVienId === id)
          : {};
        if (!_.isEmpty(user)) {
          return {
            nhanVienId: user.nhanVienId,
            tenNhanVien: user.tenNhanVien,
          };
        }
        return state.entities.users[id];
      }),
      messages: state.chat?.messages?.map((group) => {
        return {
          ...group,
          items: group.items.map((id) => {
            let message = state.entities.messages[id];
            const emoticonUpdate = state.chat.emoticonUpdate;
            // Cập nhật emoticon
            if (!!_.size(emoticonUpdate)) {
              const emoticonIndex = messagerEmoticon(emoticonUpdate, message);
              if (emoticonIndex !== -1) {
                message = {
                  ...message,
                  dsThongKeEmoticon: emoticonUpdate[emoticonIndex].dsThongKeEmoticon,
                };
              }
            }

            return {
              ...message,
              sender: state.entities.users[message.nguoiGui || message.nguoiGuiId] || {},
              nhanVienHoiThoai: message.hoiThoai.nhanVienHoiThoai
                ? message.hoiThoai.nhanVienHoiThoai.map(
                    (item) => state.entities.conversations[item.nhanVien]
                  )
                : [],
            };
          }),
        };
      }),
      searchMessages: {
        ...state.chat.searchMessages,
        items: state.chat.searchMessages.items.map((id) => {
          const message = state.entities.messages[id];
          return {
            ...message,
            sender: state.entities.users[message.nguoiGui || message.nguoiGuiId] || {},
            nhanVienHoiThoai: message.hoiThoai.nhanVienHoiThoai
              ? message.hoiThoai.nhanVienHoiThoai.map(
                  (item) => state.entities.conversations[item.nhanVien]
                )
              : [],
          };
        }),
      },
      suggestUsers: {
        ...state.chat.suggestUsers,
        items: state.chat.suggestUsers.items.map((id) => state.entities.users[id]),
      },
    },
    cabinet: {
      categories: (() => {
        const getDsNganTu = (items) => {
          if (!items) {
            return null;
          }

          return items.map((id) => {
            const category = state.entities.documentCabinetCategories[id];

            if (!category) {
              return category;
            }

            return {
              ...category,
              dsNganTuCon: getDsNganTu(category.dsNganTuCon),
            };
          });
        };

        return state.cabinet.categories
          .map((id) => {
            let category = state.entities.documentCabinetCategories[id];

            if (!category) {
              return category;
            }

            if (category.dsNganTuCon) {
              category.dsNganTuCon = getDsNganTu(category.dsNganTuCon);
            }

            return category;
          })
          .filter((item) => !!item);
      })(),
    },

    reconnectSignalR: state.chat.reconnectSignalR,
    deg: state.chat.deg,
    fileIdFromEoffice: state.chat.fileIdFromEoffice,
    fileDinhKemFromEoffice: state.chat.fileDinhKemFromEoffice,
    customText: state.chat.customText,
    emoticons: state.emoticon.emoticons,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
