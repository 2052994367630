import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@blueprintjs/core';

import { REACT_EMOTICON } from '../../constants/Permissions';
import './assets/message-item.scss';

export default class MessageItemContaxtMenu extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    receivers: PropTypes.array.isRequired,
    message: PropTypes.object.isRequired,
    disableRemoveAll: PropTypes.bool,
    onForward: PropTypes.func,
    onCopyAndForward: PropTypes.func,
    onReply: PropTypes.func,
    onReplyAll: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onSaveDocument: PropTypes.func,
    onSaveMessage: PropTypes.func,
    classSmsId: PropTypes.string,
  };

  static defaultProps = {
    disableRemoveAll: false,
    onForward: () => {},
    onCopyAndForward: () => {},
    onReply: () => {},
    onReplyAll: () => {},
    onRemove: () => {},
    onRemoveAll: () => {},
    onSaveDocument: () => {},
  };

  constructor(props) {
    super(props);
    this.handleForward = this.handleForward.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleCopyAndForward = this.handleCopyAndForward.bind(this);
  }

  handleForward = () => {
    this.props.onForward(this.props.message.tinNhanId);
  };

  handleCopyAndForward = () => {
    this.props.onCopyAndForward(this.props.message.tinNhanId);
  };

  handleRemove = () => {
    this.props.onRemove(this.props.message);
  };

  handleOnCopy(e) {
    const aux = document.createElement('div');
    aux.setAttribute('contentEditable', true);
    let messageList = document.getElementsByClassName(this.props.classSmsId);
    let messageInnerHTML = '';
    for (let i = 0; i < messageList.length; i++) {
      const element = messageList[i];
      messageInnerHTML += element.innerHTML;
    }
    aux.innerHTML = messageInnerHTML;
    document.body.appendChild(aux);
    const selectElementContents = (el) => {
      var range = document.createRange();
      range.selectNodeContents(el);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    };
    selectElementContents(aux);
    document.execCommand('copy');
    document.body.removeChild(aux);
  }

  isContentAttached = () => {
    let { fileDinhKem } = this.props.message;

    if (fileDinhKem) {
      return fileDinhKem.find((file) => {
        if (
          file.kieuFile === 'application/pdf' ||
          file.kieuFile ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.kieuFile === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.kieuFile === 'application/octet-stream'
        ) {
          return true;
        }

        return false;
      });
    }

    return false;
  };

  handleSaveDocument = () => {
    this.props.onSaveDocument && this.props.onSaveDocument(this.props.message.fileDinhKem);
  };

  funcMarkEmoticon = () => {
    this.props && this.props.markEmoticon();
  };

  render() {
    const {
      message: { nguoiGui },
      auth: {
        user: { nhanVienId },
        permissions,
      },
    } = this.props;
    const isCheckShowReactEmoticon = permissions.indexOf(REACT_EMOTICON);
    return (
      <Menu className={this.props.moreWidth && 'more-width'}>
        {this.props.isImportantMessage !== undefined && (
          <MenuItem
            iconName={`cpc ${!this.props.isImportantMessage ? 'icon-star' : 'icon-star-no-fill'}`}
            text={this.props.isImportantMessage ? 'Hủy đánh dấu quan trọng' : 'Đánh dấu quan trọng'}
            onClick={this.props.markImportantMessage}
          />
        )}
        {/* {nguoiGui !== nhanVienId && isCheckShowReactEmoticon !== -1 && (
          <MenuItem
            iconName="cpc icon-happy"
            text="Đánh dấu cảm xúc"
            onClick={this.funcMarkEmoticon}
          />
        )} */}
        <MenuItem
          iconName="cpc icon-save"
          text="Lưu tin nhắn (lưu text)"
          onClick={this.props.onSaveMessage}
        />
        <MenuItem iconName="cpc icon-arrrow-1" text="Trả lời" onClick={this.props.onReply} />
        <MenuItem
          iconName="cpc icon-arrow-2"
          text="Trả lời tất cả"
          onClick={this.props.onReplyAll}
        />
        <MenuItem iconName="cpc icon-foward" text="Chuyển tiếp" onClick={this.handleForward} />
        <MenuItem
          iconName="cpc icon-copy"
          text="Sao chép tin nhắn"
          onClick={this.handleCopyAndForward}
        />
        {/* { this.props.message.noiDung ?
          <MenuItem iconName="cpc icon-copy" text="Sao chép" onClick={this.handleOnCopy.bind(this)}/>
          : null
        } */}
        {/* {this.isContentAttached() && (
          <MenuItem
            iconName="cpc icon-save-document"
            text="Lưu tủ tài liệu"
            onClick={this.handleSaveDocument}
          />
        )} */}
        <MenuItem iconName="cpc icon-delete" text="Xóa tin nhắn" onClick={this.handleRemove} />
        {!this.props.disableRemoveAll ? (
          <MenuItem
            iconName="cpc icon-delete"
            text="Xóa tất cả tin nhắn"
            onClick={this.props.onRemoveAll}
          />
        ) : null}
      </Menu>
    );
  }
}
