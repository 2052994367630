import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Filter from './Filter';
import classnames from 'classnames';
import { SelectTree } from '../chat';

export default class PanelSearch extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    timeClick: PropTypes.number,
    contact: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isOtherUnit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search && this.state.search.trim() === '') {
      this.props.onSearch();
    }

    if (prevProps.timeClick !== this.props.timeClick) {
      this.props.actions.contactClearViewUnit();
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => this.props.onChange('search', value));
  };

  state = {
    search: '',
    submitting: false,
    unitId: 'all',
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.onSearch();
  };

  handleClearSearchAll = () => {
    this.setState({ search: '' }, () => {
      this.props.onChange('search', '');
      this.props.onSearch();
    });
  };

  showSearch = () => {
    return this.state.search.trim() !== '';
  };

  handleChangeSelect = (e) => {
    if (e !== undefined) {
      this.setState(
        {
          unitId: e,
        },
        () => this.props.onChange('unitId', e)
      );
    }
  };

  render() {
    return (
      <div className="select-unit-search">
        <div className="container-fuild">
          <form onSubmit={this.handleSearch} className="cpc-search-form">
            <div className="search-group-form">
              <div
                className={classnames('cpc-input-search', {
                  'no-select-tree': !this.props.isOtherUnit,
                })}
              >
                <button type="submit" disabled={this.state.submitting} className="btn-search">
                  <span className="icon-search" />
                </button>
                <input
                  className="search-input-control"
                  type="text"
                  placeholder="Tìm kiếm danh bạ..."
                  name="search"
                  value={this.state.search}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                {this.showSearch() && (
                  <button
                    type="button"
                    onClick={this.handleClearSearchAll}
                    className="close-button"
                  >
                    <span className="icon-close" />
                  </button>
                )}
              </div>
              {this.props.isOtherUnit && (
                <div className="cpc-select">
                  <SelectTree
                    className="cpc-select-element"
                    data={this.props.units}
                    handleChange={this.handleChangeSelect}
                    value={this.state.unitId}
                    except={this.props.auth.mainUnitId}
                  />
                </div>
              )}
            </div>
            <div className="check-status-row">
              <div className="">
                <Filter actions={this.props.actions} {...this.props} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
