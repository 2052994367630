import {
  AUTH_SET_TOKEN,
  AUTH_DISCARD_TOKEN,
  AUTH_SET_USER,
  AUTH_GET_PERMISSION,
  AUTH_GET_CONFIG,
} from '../constants/ActionTypes';
import { Cookies } from 'react-cookie';
import ls from 'local-storage';

const cookies = new Cookies();
const initialState = cookies.get('authState') ? cookies.get('authState') : {};

export default function auth(state = initialState, action) {
  let authState = state;

  switch (action.type) {
    case AUTH_SET_TOKEN:
      authState = {
        ...state,
        token: action.payload.data,
      };
      break;

    case AUTH_DISCARD_TOKEN:
      let localStorageItem = { ...localStorage };
      authState = {};
      let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan');
      let user_login = localStorage.getItem('user_login');
      ls.clear();
      localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan);
      localStorage.setItem('user_login', user_login);
      // keep opened birthday dialog status in localstorage
      for (const key in localStorageItem) {
        if (localStorageItem.hasOwnProperty(key) && key.startsWith('openedDialog')) {
          ls.set(key, localStorageItem[key]);
        }
      }
      break;

    case AUTH_SET_USER:
      let donViChinh = null;
      const user = action.payload.data.entities.users[action.payload.data.result.result];
      if (user && user.dsChucDanh) {
        donViChinh = user.dsChucDanh.find((role) => role.phongBanChinh);
      }
      authState = {
        ...state,
        user: action.payload.data.result.result,
        mainUnitId: donViChinh && donViChinh.donViId ? donViChinh.donViId : null,
        mainDepartmentId: donViChinh && donViChinh.phongBanId ? donViChinh.phongBanId : null,
      };
      break;

    case AUTH_GET_PERMISSION:
      authState = {
        ...state,
        permissions: action.payload.data.result.items.map((item) => item.maQuyen),
      };
      break;

    case AUTH_GET_CONFIG:
      authState = {
        ...state,
        config: action.payload.data.result || {},
      };
      break;

    default:
      authState = state;
  }

  cookies.set('authState', authState, { path: '/' });

  return authState;
}
