export const PRELOAD_SUCCESS = 'PRELOAD_SUCCESS';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_DISCARD_TOKEN = 'AUTH_DISCARD_TOKEN';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_GET_PERMISSION = 'AUTH_GET_PERMISSION';
export const AUTH_GET_CONFIG = 'AUTH_GET_CONFIG';

export const REMOVE_ID_FILE_FROM_QUOTE_MESSAGE = 'REMOVE_ID_FILE_FROM_QUOTE_MESSAGE';
export const REMOVE_MESSAGE_FROM_QUOTE_MESSAGE = 'REMOVE_MESSAGE_FROM_QUOTE_MESSAGE';
export const REMOVE_SURVEY_FROM_QUOTE_MESSAGE = 'REMOVE_SURVEY_FROM_QUOTE_MESSAGE';
export const SET_ID_MESSAGE_FORWARD = 'SET_ID_MESSAGE_FORWARD';
export const SET_FILE_ID_FROM_EOFFICE = 'SET_FILE_ID_FROM_EOFFICE';
export const SET_INFO_GET_FILE_DINH_KEM_FROM_EOFFICE = 'SET_INFO_GET_FILE_DINH_KEM_FROM_EOFFICE';
export const CONTACT_FETCH_DEPARTMENTS = 'CONTACT_FETCH_DEPARTMENTS';
export const CONTACT_FETCH_DEPARTMENTS_NO_ENTITY = 'CONTACT_FETCH_DEPARTMENTS_NO_ENTITY';
export const CONTACT_FETCH_DEPARTMENTS_ERROR = 'CONTACT_FETCH_DEPARTMENTS_ERROR';
export const CONTACT_FETCH_UNITS = 'CONTACT_FETCH_UNITS';
export const CONTACT_FETCH_FAVOURITE_GROUPS = 'CONTACT_FETCH_FAVOURITE_GROUPS';
export const CONTACT_FETCH_FAVOURITES = 'CONTACT_FETCH_FAVOURITES';
export const CONTACT_SEARCH_BY_UNIT = 'CONTACT_SEARCH_BY_UNIT';
export const CONTACT_SEARCH_ALL = 'CONTACT_SEARCH_ALL';
export const CONTACT_SEARCH_FAVOURITE = 'CONTACT_SEARCH_FAVOURITE';
export const CONTACT_CLEAR_SEARCH_BY_UNIT = 'CONTACT_CLEAR_SEARCH_BY_UNIT';
export const CONTACT_CLEAR_SEARCH_ALL = 'CONTACT_CLEAR_SEARCH_ALL';
export const CONTACT_CLEAR_SEARCH_FAVOURITE = 'CONTACT_CLEAR_SEARCH_FAVOURITE';
export const CONTACT_SHOW_PROFILE = 'CONTACT_SHOW_PROFILE';
export const CONTACT_CHANGE_STATUS_FILTER = 'CONTACT_CHANGE_STATUS_FILTER';
export const CONTACT_VIEW_UNIT = 'CONTACT_VIEW_UNIT';
export const CONTACT_CLEAR_VIEW_UNIT = 'CONTACT_CLEAR_VIEW_UNIT';

export const CHAT_ADD_RECEIVER = 'CHAT_ADD_RECEIVER';
export const CHAT_ADD_MULTIPLE_RECEIVERS = 'CHAT_ADD_MULTIPLE_RECEIVERS';
export const CHAT_ADD_RECEIVER_SURVEY = 'CHAT_ADD_RECEIVER_SURVEY';
export const CHAT_REMOVE_RECEIVER_SURVEY = 'CHAT_REMOVE_RECEIVER_SURVEY';
export const CHAT_ADD_RECEIVERS_SURVEY = 'CHAT_ADD_RECEIVERS_SURVEY';
export const CHAT_REMOVE_RECEIVERS_SURVEY = 'CHAT_REMOVE_RECEIVERS_SURVEY';
export const CHAT_REMOVE_RECEIVER = 'CHAT_REMOVE_RECEIVER';
export const CHAT_REMOVE_MULTIPLE_RECEIVERS = 'CHAT_REMOVE_MULTIPLE_RECEIVERS';
export const CHAT_CLEAR_RECEIVER = 'CHAT_CLEAR_RECEIVER';
export const CHAT_FETCH_MESSAGES = 'CHAT_FETCH_MESSAGES';
export const CHAT_FETCH_MORE_MESSAGES = 'CHAT_FETCH_MORE_MESSAGES';
export const CHAT_SET_CONNECTION = 'CHAT_SET_CONNECTION';
export const CHAT_SET_CONVERSATION = 'CHAT_SET_CONVERSATION';
export const CHAT_SIGNALR_NEW_MESSAGE = 'CHAT_SIGNALR_NEW_MESSAGE';
export const CHAT_SIGNALR_PRE_SEND_MESSAGE = 'CHAT_SIGNALR_PRE_SEND_MESSAGE';
export const CHAT_SIGNALR_SEND_MESSAGE = 'CHAT_SIGNALR_SEND_MESSAGE';
export const CHAT_SIGNALR_STATE_CHANGE = 'CHAT_SIGNALR_STATE_CHANGE';
export const CHAT_SIGNALR_MESSAGE_DELETED = 'CHAT_SIGNALR_MESSAGE_DELETED';
export const CHAT_SIGNALR_MESSAGES_DELETED = 'CHAT_SIGNALR_MESSAGES_DELETED';
export const CHAT_REMOVE_MESSAGE = 'CHAT_REMOVE_MESSAGE';
export const CHAT_REMOVE_MULTIPLE_MESSAGE = 'CHAT_REMOVE_MULTIPLE_MESSAGE';
export const CHAT_SEARCH_MESSAGES = 'CHAT_SEARCH_MESSAGES';
export const CHAT_SEARCH_CLEAR = 'CHAT_SEARCH_CLEAR';
export const CHAT_SEARCH_SUGGEST_USER = 'CHAT_SEARCH_SUGGEST_USER';
export const CHAT_SEARCH_SUGGEST_CLEAR = 'CHAT_SEARCH_SUGGEST_CLEAR';
export const CHAT_RESET = 'CHAT_RESET';
export const CHAT_GET_RECEIVER_CONVERSATION = 'CHAT_GET_RECEIVER_CONVERSATION';
export const PRE_CHAT_GET_RECEIVER_CONVERSATION = 'PRE_CHAT_GET_RECEIVER_CONVERSATION';
export const CHAT_GET_NEW_MESSAGES = 'CHAT_GET_NEW_MESSAGES';
export const CHAT_READ_ALL_MESSAGES = 'CHAT_READ_ALL_MESSAGES';
export const CHAT_SET_IDLE_STATUS = 'CHAT_SET_IDLE_STATUS';
export const GET_FILE_INFORMATION = 'GET_FILE_INFORMATION';
export const CHAT_CLOSE_LIMIT_CONFIRM = 'CHAT_CLOSE_LIMIT_CONFIRM';

export const DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL = 'DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL';
export const DOCUMENTS_CABINET_UNIT_CATEGORIES = 'DOCUMENTS_CABINET_UNIT_CATEGORIES';
export const DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES = 'DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES';
export const DOCUMENTS_CABINET_PERSONAL_CATEGORIES = 'DOCUMENTS_CABINET_PERSONAL_CATEGORIES';

export const API_REQUEST_SEND = 'API_REQUEST_SEND';
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS';
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR';

export const GET_USER_INFOS = 'GET_USER_INFOS';

// danh sách phiếu khảo sát
export const LIST_SURVEYS = 'LIST_SURVEYS';
export const POST_SURVEY = 'POST_SURVEY';
export const COMMON_ADD_TOASTER_MESSAGE = 'COMMON_ADD_TOASTER_MESSAGE';
export const COMMON_REMOVE_TOASTER_MESSAGE = 'COMMON_REMOVE_TOASTER_MESSAGE';
export const GET_SURVEY_ITEM = 'GET_SURVEY_ITEM';
export const REMOVE_SURVEY_ITEM = 'REMOVE_SURVEY_ITEM';
export const DELETE_SURVEY_LIST_ITEM = 'DELETE_SURVEY_LIST_ITEM';
export const PUT_SURVEY = 'PUT_SURVEY';
export const API_THONGKE_SUCCESS = 'API_THONGKE_SUCCESS';
export const REMOVE_ENTITIES_SURVEY_ITEM = 'REMOVE_ENTITIES_SURVEY_ITEM';
export const GET_LIST_UERS_SURVEY = 'GET_LIST_UERS_SURVEY';
export const COMMON_ADD_DIALOG = 'COMMON_ADD_DIALOG';
export const COMMON_REMOVE_DIALOG = 'COMMON_REMOVE_DIALOG';
export const COMMON_RECONNECT_SIGNALR = 'COMMON_RECONNECT_SIGNALR';
export const GET_ANSWER_OTHER = 'GET_ANSWER_OTHER';
export const ADD_IMPORTANT_LIST = 'ADD_IMPORTANT_LIST';
export const CREAT_CUSTOM_TEXT_CHAT = 'CREAT_CUSTOM_TEXT_CHAT';
export const GET_BIRTHDAY_USER_LIST = 'GET_BIRTHDAY_USER_LIST';
export const HANDLE_ROTATE_IMG = 'HANDLE_ROTATE_IMG';
export const RESET_DEG_IMG = 'RESET_DEG_IMG';
export const API_GET_INFO_FILEDINHKEM = 'API_GET_INFO_FILEDINHKEM';
export const GET_FEEDBACK_LIST = 'GET_FEEDBACK_LIST';
export const GET_FEEDBACK_DETAIL = 'GET_FEEDBACK_DETAIL';
export const UPDATE_FEEDBACK_HANDLE = 'UPDATE_FEEDBACK_HANDLE';
export const GET_FEEDBACK_LIST_STATISTIC = 'GET_FEEDBACK_LIST_STATISTIC';
export const GET_FEEDBACK_STATISTIC_DETAIL = 'GET_FEEDBACK_STATISTIC_DETAIL';
export const GET_FEEDBACK_PHIENBAN = 'GET_FEEDBACK_PHIENBAN';
export const GET_FEEDBACK_CHATLUONG = 'GET_FEEDBACK_CHATLUONG';
export const GET_FEEDBACK_TRANGTHAI = 'GET_FEEDBACK_TRANGTHAI';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';

// Emoticon
export const API_REQUEST_GET_EMOTICON = 'API_REQUEST_GET_EMOTICON';
export const CHAT_SIGNALR_UPDATE_MESSAGE = 'CHAT_SIGNALR_UPDATE_MESSAGE';

// Conversation
export const API_REQUEST_GET_CONVERSATION = 'API_REQUEST_GET_CONVERSATION';
export const API_REQUEST_GET_CONVERSATION_PIN_TOP = 'API_REQUEST_GET_CONVERSATION_PIN_TOP';
export const API_REQUEST_GET_CONVERSATION_LOADING = 'API_REQUEST_GET_CONVERSATION_LOADING';
export const API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP =
  'API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP';
export const API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP_RESET =
  'API_REQUEST_GET_CONVERSATION_LOADING_PIN_TOP_RESET';
export const UPDATE_CONVERSATION_PIN_TOP = 'UPDATE_CONVERSATION_PIN_TOP';
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const SET_ACTIVATE_ALL_TAB = 'SET_ACTIVATE_ALL_TAB';
export const API_REQUEST_GET_MESSAGES = 'API_REQUEST_GET_MESSAGES';
export const SET_IS_SCROLL_BOTTOM = 'SET_IS_SCROLL_BOTTOM';
export const REMOVE_MESSAGE_OF_CONVERSATION = 'REMOVE_MESSAGE_OF_CONVERSATION';
export const SET_RECEIVE_NEW_MESSAGE = 'SET_RECEIVE_NEW_MESSAGE';
export const CREATE_NEW_CONVERSATION = 'CREATE_NEW_CONVERSATION';
export const REMOVE_UNREAD_MESSAGE = 'REMOVE_UNREAD_MESSAGE';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const LEAVE_CONVERSATION = 'LEAVE_CONVERSATION';
export const UPDATE_CONVERSATION_INFO = 'UPDATE_CONVERSATION_INFO';
export const RESET_TOP_PIN_MESSAGES = 'RESET_TOP_PIN_MESSAGES';
export const RECALL_MESSAGE = 'RECALL_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const ADD_USERS_CONVERSATION = 'ADD_USERS_CONVERSATION';
export const DELETE_USER_CONVERATION = 'DELETE_USER_CONVERATION';
export const GET_LIST_PIN_MESSAGES = 'GET_LIST_PIN_MESSAGES';
export const GET_LIST_IMPORTANT_MESSAGES = 'GET_LIST_IMPORTANT_MESSAGES';
export const GET_ATTACHED_FILES_CONVERATION = 'GET_ATTACHED_FILES_CONVERATION';
export const GET_CONVERATION_USERS_SEARCH = 'GET_CONVERATION_USERS_SEARCH';
export const GET_CONVERATION_USERS_NOT_APPROVED_SEARCH =
  'GET_CONVERATION_USERS_NOT_APPROVED_SEARCH';
export const GET_MESSAGES_IMPORTANT = 'GET_MESSAGES_IMPORTANT';
export const CONVERSATION_DETAIL = 'CONVERSATION_DETAIL';
export const CLEAN_CONTEXT_CONVERSATION = 'CLEAN_CONTEXT_CONVERSATION';
export const DELETE_CONVERSATION_GENERAL = 'DELETE_CONVERSATION_GENERAL';
export const GET_ATTACHED_IMAGES = 'GET_ATTACHED_IMAGES';
export const GET_ATTACHED_DOCUMENTS = 'GET_ATTACHED_DOCUMENTS';
export const SET_FILES_NEW_MESSAGE = 'SET_FILES_NEW_MESSAGE';
export const SET_RELOAD_DATA = 'SET_RELOAD_DATA';
export const SAVE_ADD_TOP_CHAT = 'SAVE_ADD_TOP_CHAT';
export const SET_CONVERSATION_LATEST_CONTACT_LIST = 'SET_CONVERSATION_LATEST_CONTACT_LIST';
export const REMOVE_CONVERSATION_LATEST_CONTACT_LIST = 'REMOVE_CONVERSATION_LATEST_CONTACT_LIST';
export const REMOVE_CONVERSATION_LIST = 'REMOVE_CONVERSATION_LIST';
export const IMPORTANT_CONVERSATION = 'IMPORTANT_CONVERSATION';
export const HANDLE_NOTIFICATION_OF_CONVERSATION = 'HANDLE_NOTIFICATION_OF_CONVERSATION';
export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const SET_TYPE_CONVERSATION = 'SET_TYPE_CONVERSATION';
export const SET_SEARCH_MESSAGE = 'SET_SEARCH_MESSAGE';
export const GET_MESSAGES_FORM_SEARCH_MESSAGE = 'GET_MESSAGES_FORM_SEARCH_MESSAGE';
export const SET_LOADING_SEARCH_MESSAGE = 'SET_LOADING_SEARCH_MESSAGE';
export const API_REQUEST_GET_AFTER_MESSAGES = 'API_REQUEST_GET_AFTER_MESSAGES';
export const SET_LOADING_AFTER_MESSAGE = 'SET_LOADING_AFTER_MESSAGE';
export const REFRESH_CONTEXT_MESSAGE = 'REFRESH_CONTEXT_MESSAGE';
export const UPDATE_ROLE_CONVERSATION = 'UPDATE_ROLE_CONVERSATION';
export const API_REQUEST_CONVERSATION_LIST = 'API_REQUEST_CONVERSATION_LIST';
export const SET_CONVERSATION_PIN_TOP = 'SET_CONVERSATION_PIN_TOP';
export const RESET_USER_LIST = 'RESET_USER_LIST';
export const GET_FAVOURITE_CONTACTS = 'GET_FAVOURITE_CONTACTS';
export const ACTIVE_CONVERSATION = 'ACTIVE_CONVERSATION';
export const SAVE_CURRENT_CONVERSATION_CHAT = 'SAVE_CURRENT_CONVERSATION_CHAT';
export const SAVE_CURRENT_CONVERSATION_DEFAULT = 'SAVE_CURRENT_CONVERSATION_DEFAULT';
export const SET_LAST_MESSAGE = 'SET_LAST_MESSAGE';
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const SET_TOP_CONVERSATION = 'SET_TOP_CONVERSATION';
export const SET_CONVERSATION_MESSAGE_SEEN = 'SET_CONVERSATION_MESSAGE_SEEN';
export const SET_CONVERSATION_MESSAGE_RECEIVED = 'SET_CONVERSATION_MESSAGE_RECEIVED';
export const GET_CONVERSATION_TOTAL_NEW_MESSAGES = 'GET_CONVERSATION_TOTAL_NEW_MESSAGES';
export const API_GET_GROUP_CONVERSATION_LIST = 'API_GET_GROUP_CONVERSATION_LIST';
export const SET_TOTAL_MESSAGE = 'SET_TOTAL_MESSAGE';
export const GET_LINKS_CONVERATION = 'GET_LINKS_CONVERATION';
export const SET_UNREAD_LIST = 'SET_UNREAD_LIST';
export const REMOVE_UNREAD_LIST = 'REMOVE_UNREAD_LIST';
export const SET_CONTENT_EDITOR = 'SET_CONTENT_EDITOR';

export const SET_USER_INTERFACE_KEY = 'SET_USER_INTERFACE_KEY';

export const SET_SIDEBAR = 'SET_SIDEBAR';

export const SET_SCROLL = 'SET_SCROLL';

export const SET_IS_RECONNECT = 'SET_IS_RECONNECT';
export const RECONNECTING_STATUS = 'RECONNECTING_STATUS';
export const SET_PAGE_VISIBILITY = 'SET_PAGE_VISIBILITY';
export const API_GET_CONVERSATIONS_RECONNECTED = 'API_GET_CONVERSATIONS_RECONNECTED';

export const IS_EDITOR_LOADING = 'IS_EDITOR_LOADING';

export const SET_FROM_OTHER_APP_DATA = 'SET_FROM_OTHER_APP_DATA';

export const SET_CONVERSATION_APPROVED_USER = 'SET_CONVERSATION_APPROVED_USER';
export const SET_LOADING_APPROVED = 'SET_LOADING_APPROVED';

export const SIGNALR_ADD_USERS = 'SIGNALR_ADD_USERS';
export const SET_AUDIO_PLAY_ID = 'SET_AUDIO_PLAY_ID';
export const SET_DIALOG_CREATE_WORK = 'SET_DIALOG_CREATE_WORK';

export const SET_IS_LOGIN_HAS_BEEN_CHANGED = 'SET_IS_LOGIN_HAS_BEEN_CHANGED';

// CHAT ACTION TYPE
export const SET_MENTIONED_IDS = 'SET_MENTIONED_IDS';
