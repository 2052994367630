import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import CryptoAES from 'crypto-js/aes';
import dayjs from 'dayjs';

const CHECK_BIRTHDAY_TIME = 5000;

const useSidebar = (props) => {
  const { actions = {}, auth = {} } = props;

  const generateUUID = () => {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = ~~((d + Math.random() * 16) % 16);
      d = ~~(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  };

  const get_device = () => {
    var module = {
      options: [],
      header: [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera,
      ],
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],

      init: function () {
        var agent = this.header.join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser);
        return { os: os, browser: browser };
      },
      matchItem: function (string, data) {
        var i = 0,
          j = 0,
          regex,
          regexv,
          match,
          matches,
          version;
        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i');
          match = regex.test(string);
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) {
              if (matches[1]) {
                matches = matches[1];
              }
            }
            if (matches) {
              matches = matches.split(/[._]+/);
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.';
                } else {
                  version += matches[j];
                }
              }
            } else {
              version = '0';
            }
            return {
              name: data[i].name,
              version: parseFloat(version),
            };
          }
        }
        return { name: 'unknown', version: 0 };
      },
    };
    var e = module.init(),
      debug = '';
    debug += '' + e.os.name + ' ';
    debug += '' + e.os.version + ', ';
    debug += '' + e.browser.name + '';
    return debug;
  };

  const checkFirstLogin = () => {
    //check login lan dau
    const cookies = new Cookies();
    const checkin_maxacnhan_cookies = cookies.get('checkin_maxacnhan_cookies')
      ? cookies.get('checkin_maxacnhan_cookies')
      : '';
    const isNewLogin = auth.user.username !== localStorage['user_login'];
    localStorage['user_login'] = auth.user.username;
    let MA_XACNHAN = localStorage['checkin_maxacnhan'];
    if (
      checkin_maxacnhan_cookies === undefined ||
      checkin_maxacnhan_cookies === '' ||
      checkin_maxacnhan_cookies === null ||
      checkin_maxacnhan_cookies === 'null'
    ) {
      cookies.set('checkin_maxacnhan_cookies', generateUUID(), { path: '/' });
    }

    if (
      MA_XACNHAN === undefined ||
      MA_XACNHAN === '' ||
      MA_XACNHAN === null ||
      MA_XACNHAN === 'null'
    ) {
      localStorage['checkin_maxacnhan'] = generateUUID();
      MA_XACNHAN = localStorage['checkin_maxacnhan'];
    }
    let login = {
      TAIKHOAN: encodeURIComponent(CryptoAES.encrypt(auth.user.username, 'hoa3004')),
      MA_UNGDUNG: 'EO_CHAT',
      MA_XACNHAN_STORAGE: MA_XACNHAN,
      MA_XACNHAN_COOKIE: cookies.get('checkin_maxacnhan_cookies'),
      TEN_THIETBI: get_device(),
      HOST_NAME: window.location.href,
      IP: '',
      LAT: '',
      LONG: '',
      KEY: isNewLogin ? '/VqC9jG0xNs=' : 'Iw87fGcomEZcl2AvIfdKhg==',
    };

    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          login.LAT = position.coords.latitude;
          login.LONG = position.coords.longitude;
        },
        (error) => {}
      );
    }
  };

  // const processData = async () => {
  //   let result = []
  //   await this.props.actions
  //     .getInfoFileDinhKemFromEoffice(this.props.fileDinhKemFromEoffice.id, this.props.fileDinhKemFromEoffice.type)
  //     .then((res) => {
  //       if (res && res.payload && res.payload.data && res.payload.data.result && res.payload.data.result.items) {
  //         res.payload.data.result.items.forEach((item) => {
  //           return result.push({
  //             id: item.fileId,
  //             name: "eofficeFile-" + item.tenFile,
  //             info: {
  //               id: item.fileId,
  //               original_name: "eofficeFile-" + item.tenFile,
  //               path: item.url,
  //               type: item.kieuFile,
  //               length: item.kichThuoc,
  //             },
  //           })
  //         })
  //       }
  //     })
  //   result = await Promise.all(result)

  //   return result
  // }

  const handleGetBirthDayUserList = async () => {
    let birthDayUserListGetDate =
      sessionStorage.getItem('birthDayUserListGetDate') &&
      dayjs(sessionStorage.getItem('birthDayUserListGetDate'));
    let currentDate = dayjs();
    if (
      !birthDayUserListGetDate ||
      (birthDayUserListGetDate &&
        !birthDayUserListGetDate.isSame(currentDate, 'date') &&
        currentDate.hour() >= 3 &&
        currentDate.minute() <= 1 &&
        currentDate.second() <= 5)
    ) {
      await actions.getUserConfig();
      await actions.getBirthDayUserList();
      sessionStorage.setItem('birthDayUserListGetDate', currentDate.format());
      return window.location.reload(true);
    }
  };

  useEffect(() => {
    checkFirstLogin();
    actions.getListEmoticon();
    actions.getBirthDayUserList();

    let currentDate = dayjs().format();
    sessionStorage.setItem('birthDayUserListGetDate', currentDate);
    const intervalGetBirthDayUserList = setInterval(handleGetBirthDayUserList, CHECK_BIRTHDAY_TIME);

    return () => {
      clearInterval(intervalGetBirthDayUserList);
    };
  }, []);

  return {};
};

export default useSidebar;
