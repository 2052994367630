import * as types from '../constants/ActionTypes';
import { variables } from '../constants/variables';
import {
  UserSchema,
  UserArraySchema,
  ConversationArraySchema,
  UnitArraySchema,
  DepartmentArraySchema,
  FavouriteArraySchema,
  MessageArraySchema,
  UnitRecursive,
  DocumentCabinetCategoryArraySchema,
  SurveysArraySchema,
  SurveyItemSchema,
  StatisticalSurveyArraySchema,
} from '../schemas';
import { linkObjectKey } from '../helpers/helper';
import _, { get } from 'lodash';
import { apiClients } from '@/middleware/api';

export * from './conversation.actions';
export * from './group.action';
export * from './message.action';
export * from './configuration.actions';
export * from './chat.actions';

export const preloadSuccess = () => ({ type: types.PRELOAD_SUCCESS });

export const authLogin = (login) => {
  login.grant_type = 'password';
  login.scope = 'eoffice offline_access';

  return {
    types: [types.API_REQUEST_SEND, types.AUTH_SET_TOKEN, types.AUTH_DISCARD_TOKEN],
    payload: {
      client: 'auth',
      request: {
        url: '/connect/token',
        method: 'POST',
        headers: {
          Authorization: 'Basic ZW9mZmljZS5yby5jbGllbnQ6dGN4LnNlY3JldA==',
        },
        data: login,
      },
    },
  };
};

export const setTokenWithQuery = (token) => ({
  type: types.AUTH_SET_TOKEN,
  payload: {
    data: token,
  },
});

export const setFileIdFromEoffice = (fileId) => ({
  type: types.SET_FILE_ID_FROM_EOFFICE,
  payload: {
    data: fileId,
  },
});

export const setInfoGetFileDinhKemFromEoffice = (infoGetFileDinhKem, infoGetFileDinhKemType) => ({
  type: types.SET_INFO_GET_FILE_DINH_KEM_FROM_EOFFICE,
  payload: {
    data: {
      id: infoGetFileDinhKem,
      type: infoGetFileDinhKemType,
    },
  },
});

export const setIdMessageForward = (id) => ({
  type: types.SET_ID_MESSAGE_FORWARD,
  payload: {
    data: id,
  },
});

export const removeFromQuoteMessage = ({ id, type }) => ({
  type:
    type === 'file'
      ? types.REMOVE_ID_FILE_FROM_QUOTE_MESSAGE
      : type === 'tinNhan'
      ? types.REMOVE_MESSAGE_FROM_QUOTE_MESSAGE
      : types.REMOVE_SURVEY_FROM_QUOTE_MESSAGE,
  payload: {
    data: id,
  },
});

export const authLogout = () => ({ type: types.AUTH_DISCARD_TOKEN });

export const userAuthenticated = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.AUTH_SET_USER, types.AUTH_DISCARD_TOKEN],
    schema: UserSchema,
    payload: {
      request: {
        url: '/api/me',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const getUserConfig = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.AUTH_GET_CONFIG, types.AUTH_DISCARD_TOKEN],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/nhanvien/${getState().auth.user}/caidat`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const userPermission = (id) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.AUTH_GET_PERMISSION, types.AUTH_DISCARD_TOKEN],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/quyen/nhanvien/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const contactShowProfile = (userId) => ({ type: types.CONTACT_SHOW_PROFILE, userId });

export const contactFetchDepartmentsByUnit = (unitId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CONTACT_FETCH_DEPARTMENTS,
      types.CONTACT_FETCH_DEPARTMENTS_ERROR,
    ],
    schema: DepartmentArraySchema,
    unitId: unitId,
    payload: {
      request: {
        url: `/api/donvi/${unitId}/phongban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });
export const contactFetchDepartmentsByUnitNoEntity = (unitId) => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.CONTACT_FETCH_DEPARTMENTS_NO_ENTITY,
      types.CONTACT_FETCH_DEPARTMENTS_ERROR,
    ],
    payload: {
      request: {
        url: `/api/donvi/${unitId}/phongban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const contactFetchUnits = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.CONTACT_FETCH_UNITS, types.API_REQUEST_ERROR],
    schema: UnitArraySchema,
    payload: {
      request: {
        url: '/api/donvi',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const contactFetchFavouriteGroups = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.CONTACT_FETCH_FAVOURITE_GROUPS, types.API_REQUEST_ERROR],
    schema: FavouriteArraySchema,
    payload: {
      request: {
        url: '/api/nhomlienlac',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const contactFetchFavouritesByGroup = (groupId) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.CONTACT_FETCH_FAVOURITES, types.API_REQUEST_ERROR],
    schema: UserArraySchema,
    groupId: groupId,
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}/nhanvien`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const contactAddUsersToFavouriteGroup = (groupId, users) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}/nhanvien`,
        method: 'POST',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: {
          nhom_lien_lac_id: groupId,
          nhan_viens: users.map((user) => {
            return {
              nhan_vien_id: user.nhanVienId,
            };
          }),
        },
      },
    },
  });

export const contactCreateFavouriteGroup = (name, users) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/nhomlienlac',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: {
          ten_nhom_lien_lac: name,
          nhan_viens: users.map((user) => {
            return {
              nhan_vien_id: user.nhanVienId,
            };
          }),
        },
      },
    },
  });

export const contactUpdateFavouriteGroup = (groupId, newName) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: {
          nhom_lien_lac_id: groupId,
          ten_nhom_lien_lac: newName,
        },
      },
    },
  });

export const contactDeleteFavouriteGroup = (groupId) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const contactRemoveUserFromFavouriteGroup = (groupId, userId) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhanviennhomlienlac/${groupId}/nhanVien/${userId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const createFavouriteGroup = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/nhomlienlac',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: data,
      },
    },
  });

export const contactSearchByUnit =
  (unitId, q, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [types.API_REQUEST_SEND, types.CONTACT_SEARCH_BY_UNIT, types.API_REQUEST_ERROR],
      schema: UserArraySchema,
      page: page,
      payload: {
        request: {
          url: `/api/donvi/${unitId}/nhanvien/timkiem`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            textSearch: q,
            page: page,
          },
        },
      },
    });
export const contactClearSearchByUnit = () => ({ type: types.CONTACT_CLEAR_SEARCH_BY_UNIT });

export const contactSearchAll =
  (mainUnitId, q, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [types.API_REQUEST_SEND, types.CONTACT_SEARCH_ALL, types.API_REQUEST_ERROR],
      schema: UserArraySchema,
      page: page,
      payload: {
        request: {
          url: '/api/nhanvien/timkiem',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            excludeDonViId: mainUnitId,
            textSearch: q,
            page: page,
          },
        },
      },
    });

export const contactClearSearchAll = () => ({ type: types.CONTACT_CLEAR_SEARCH_ALL });

export const contactSearchFavourite =
  (q, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [types.API_REQUEST_SEND, types.CONTACT_SEARCH_FAVOURITE, types.API_REQUEST_ERROR],
      schema: UserArraySchema,
      page: page,
      payload: {
        request: {
          url: '/api/nhomlienlac/nhanvien/timkiem',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            textSearch: q,
            page: page,
          },
        },
      },
    });
export const contactClearSearchFavourite = () => ({ type: types.CONTACT_CLEAR_SEARCH_FAVOURITE });

/**
 * Action update state filter
 */
export const contactChangeStatusFilter = () => ({ type: types.CONTACT_CHANGE_STATUS_FILTER });
export const contactViewUnit = (unitId) => ({ type: types.CONTACT_VIEW_UNIT, unitId });
export const contactClearViewUnit = () => ({ type: types.CONTACT_CLEAR_VIEW_UNIT });

export const chatAddReceiver = (userId) => ({ type: types.CHAT_ADD_RECEIVER, userId });
export const chatAddReceiverSurvey = (userId) => ({ type: types.CHAT_ADD_RECEIVER_SURVEY, userId });
export const chatAddMultipleReceivers = (users) => ({
  type: types.CHAT_ADD_MULTIPLE_RECEIVERS,
  users,
});
export const chatAddReceiversSurvey = (users) => ({ type: types.CHAT_ADD_RECEIVERS_SURVEY, users });
export const chatRemoveReceiver = (userId) => ({ type: types.CHAT_REMOVE_RECEIVER, userId });
export const chatRemoveReceiverSurvey = (userId) => ({
  type: types.CHAT_REMOVE_RECEIVER_SURVEY,
  userId,
});
export const chatRemoveMultipleReceivers = (users) => ({
  type: types.CHAT_REMOVE_MULTIPLE_RECEIVERS,
  users,
});
export const chatRemoveReceiversSurvey = (users) => ({
  type: types.CHAT_REMOVE_RECEIVERS_SURVEY,
  users,
});
export const chatClearReceiver = (users) => ({ type: types.CHAT_CLEAR_RECEIVER, users });
export const chatReadAllMessages = () => ({ type: types.CHAT_READ_ALL_MESSAGES });
export const chatSetIdle = (status) => ({ type: types.CHAT_SET_IDLE_STATUS, status });
export const chatFetchMessages =
  (lastId = null, limit = 20) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        lastId ? types.CHAT_FETCH_MORE_MESSAGES : types.CHAT_FETCH_MESSAGES,
        types.API_REQUEST_ERROR,
      ],
      schema: MessageArraySchema,
      payload: {
        request: {
          url: '/api/tinnhan',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            limit: limit,
            tinnhanId: lastId,
          },
        },
      },
    });
export const chatCreateConversation = (users, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/hoithoai',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params: {
          connectionId: getState().chat ? getState().chat.connectionId : null,
        },
        data: {
          nhan_vien_hoi_thoai: users.map((user) => ({ nguoi_nhan_id: user.nhanVienId })),
          ...data,
        },
      },
    },
  });
export const chatSetConnectionId = (connectionId) => ({
  type: types.CHAT_SET_CONNECTION,
  connectionId,
});
export const chatSetConversation = (conversationId) => ({
  type: types.CHAT_SET_CONVERSATION,
  conversationId,
});

export const chatSignalRTriggerNewMessage = (payload) => async (dispatch, getState) => {
  const { conversations, typeConversation } = getState().chat;

  const messageId = payload?.data?.result;
  const newMessage = payload?.data?.entities?.messages?.[messageId];
  const indexConversation = conversations.findIndex((o) => o.hoiThoaiId === newMessage.hoiThoaiId);

  if (
    indexConversation === -1 &&
    (newMessage?.hoiThoai?.isGroup === typeConversation || typeConversation === null)
  ) {
    const res = await apiClients.default.client({
      method: 'get',
      url: `/api/hoithoai/${newMessage?.hoiThoaiId}`,
      headers: {
        Authorization:
          getState().auth && getState().auth.token
            ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
            : '',
      },
    });

    payload.conversation = res?.data?.result;
  }

  return dispatch({
    type: types.CHAT_SIGNALR_NEW_MESSAGE,
    payload: {
      ...payload,
      auth: getState().auth,
      typeConversation,
    },
  });
};

export const chatSignalRPreSendMessage = () => ({ type: types.CHAT_SIGNALR_PRE_SEND_MESSAGE });
export const chatSignalRTriggerSendMessage = (payload) => async (dispatch, getState) => {
  const state = getState();
  const user = state.auth.user;
  const nguoiGui = state.entities.users[user];
  payload.data.entities.messages[payload?.data?.result?.entity].nguoiGui = nguoiGui;

  if (state.chat.loadMoreBeforeMessages?.hasMore) {
    try {
      const res = await apiClients.default.client({
        method: 'get',
        url: `/api/hoithoai/${state.chat?.currentConversation?.hoiThoaiId}/tinnhan?tinnhanId=${
          payload.data.entities.messages[payload?.data?.result?.entity]?.tinNhanId
        }&limit=${variables.LOAD_MORE.MESSAGE_LIMIT}`,
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      });

      payload.messages = res?.data?.result?.items || [];
    } catch (err) {
      // continue next step
    }
  }

  return dispatch({
    type: types.CHAT_SIGNALR_SEND_MESSAGE,
    payload: {
      ...payload,
      auth: getState().auth,
      typeConversation: state.chat?.typeConversation,
    },
  });
};

export const chatSignalRTriggerStateChange = (userState) => ({
  type: types.CHAT_SIGNALR_STATE_CHANGE,
  userState,
});
export const chatSignalRTriggerMessageDeleted = (messageId) => ({
  type: types.CHAT_SIGNALR_MESSAGE_DELETED,
  messageId,
});
export const chatSignalRTriggerMessagesDeleted = (messages) => ({
  type: types.CHAT_SIGNALR_MESSAGES_DELETED,
  messages,
});
export const chatReset = () => ({ type: types.CHAT_RESET });
export const chatRemoveMessage = (messageId) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.CHAT_REMOVE_MESSAGE, types.API_REQUEST_ERROR],
    messageId: messageId,
    payload: {
      request: {
        url: `/api/tinnhan/${messageId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });
export const chatRemoveMultipleMessages = (messages) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.CHAT_REMOVE_MULTIPLE_MESSAGE, types.API_REQUEST_ERROR],
    messages: messages,
    payload: {
      request: {
        url: '/api/tinnhan/xoanhieutin',
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: {
          items: messages.map((message) => message.tinNhanId),
        },
      },
    },
  });
export const chatSearchMessages =
  (
    q,
    userFrom = '',
    userTo = '',
    startDate = '',
    endDate = '',
    tn1vs1 = 0,
    fileDinhKem = false,
    guiKemEmail = false,
    guiKemSMS = false,
    tinNhanDanhDau = false,
    page = 1,
    limit = 20
  ) =>
  (dispatch, getState) =>
    dispatch({
      types: [types.API_REQUEST_SEND, types.CHAT_SEARCH_MESSAGES, types.API_REQUEST_ERROR],
      schema: MessageArraySchema,
      page: page,
      payload: {
        request: {
          url: '/api/tinnhan/timkiem',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            searchText: q,
            tuNgay: startDate,
            denNgay: endDate,
            nguoiGuiId: userFrom,
            nguoiNhanId: userTo,
            tongSoNhanVien: tn1vs1,
            fileDinhKem,
            guiKemEmail,
            guiKemSMS,
            tinNhanDanhDau,
            limit: limit,
            page: page,
          },
        },
      },
    });
export const chatClearSearch = () => ({ type: types.CHAT_SEARCH_CLEAR });
export const chatClearSuggestSearch = () => ({ type: types.CHAT_SEARCH_SUGGEST_CLEAR });

export const chatSearchSuggestUser =
  (q, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [types.API_REQUEST_SEND, types.CHAT_SEARCH_SUGGEST_USER, types.API_REQUEST_ERROR],
      schema: UserArraySchema,
      page: page,
      payload: {
        request: {
          url: '/api/nhanvien/timkiem',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            textSearch: q,
            page: page,
          },
        },
      },
    });

export const chatSearchSuggestUserAtUnit =
  (donviId, q, page = 1) =>
  (dispatch, getState) =>
    dispatch({
      types: [types.API_REQUEST_SEND, types.CHAT_SEARCH_SUGGEST_USER, types.API_REQUEST_ERROR],
      schema: UserArraySchema,
      page: page,
      payload: {
        request: {
          url: `/api/donvi/${donviId}/nhanvien/timkiem`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            textSearch: q,
            page: page,
          },
        },
      },
    });

export const getReceiverUserWithConversationId =
  (messageId, conversationId, limit = 10000, loading = true) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.PRE_CHAT_GET_RECEIVER_CONVERSATION,
        types.CHAT_GET_RECEIVER_CONVERSATION,
        types.API_REQUEST_ERROR,
      ],
      messageId,
      loading,
      schema: ConversationArraySchema,
      payload: {
        request: {
          url: `/api/hoithoai/${conversationId}/nhanvien`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            limit,
          },
        },
      },
    });

export const downloadFile = (file) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, 'DOWNLOAD_FILE', types.API_REQUEST_ERROR],
    payload: {
      client: 'download',
      request: {
        url: `/api/file/downloadfile?fileName=${file.fileName}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const downloadFileChat = (id) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.DOWNLOAD_FILE, types.API_REQUEST_ERROR],
    payload: {
      client: 'download',
      request: {
        url: `/api/filechat/get/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const getFileInformation = (id) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FILE_INFORMATION, types.API_REQUEST_ERROR],
    payload: {
      client: 'fileInfo',
      request: {
        url: `/api/file/${id}/info`,
        method: 'GET',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
      },
    },
  });

export const profileUpdate = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: '/api/nhanvien',
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: {
          so_dien_thoai: data.soDienThoai,
          so_dien_thoai_khac: data.soDienThoaiKhac,
          tam_trang: data.tamTrang,
          ngay_sinh: data.ngaySinh,
        },
      },
    },
  });

export const getNewMessages = (lastId) => (dispatch, getState) => {
  const newMessages = getState().chat.newMessages;
  let lastMessageId = '';
  if (lastId) {
    lastMessageId = lastId;
  } else if (!lastId && newMessages?.length > 0) {
    lastMessageId = newMessages[newMessages.length - 1]?.tinNhanId;
  }

  return dispatch({
    types: [types.API_REQUEST_SEND, types.CHAT_GET_NEW_MESSAGES, types.API_REQUEST_ERROR],
    schema: MessageArraySchema,
    payload: {
      request: {
        url: '/api/tinnhan/tinnhanmoi',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
          'api-version': '1.0',
        },
        params: {
          tinnhanId: lastMessageId,
          hoithoaiId: getState().chat.currentConversation?.hoiThoaiId || '',
        },
      },
    },
  });
};

export const getUserInfo = (id) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    schema: UserSchema,
    payload: {
      request: {
        url: `/api/nhanvien/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const getFullUnits = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.CONTACT_FETCH_UNITS, types.API_REQUEST_ERROR],
    schema: UnitRecursive,
    payload: {
      request: {
        url: `/api/donvi/multilevel`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const documentsCabinetGetListCategoriesUnit = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
      types.DOCUMENTS_CABINET_UNIT_CATEGORIES,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetCategoryArraySchema,
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/tutailieu/donvi`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const documentsCabinetGetListCategoriesDepartment =
  (id = null) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
        types.DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES,
        types.API_REQUEST_ERROR,
      ],
      schema: DocumentCabinetCategoryArraySchema,
      payload: {
        client: 'eoffice',
        request: {
          url: `/api/tutailieu/phongban`,
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params: {
            phong_ban_id: id,
          },
        },
      },
    });

export const documentsCabinetGetListCategoriesPersonal = () => (dispatch, getState) =>
  dispatch({
    types: [
      types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL,
      types.DOCUMENTS_CABINET_PERSONAL_CATEGORIES,
      types.API_REQUEST_ERROR,
    ],
    schema: DocumentCabinetCategoryArraySchema,
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/tutailieu/canhan`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const documentsCabinetNewItemPersonal = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/tailieucanhan`,
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const documentsCabineNewItemDepartment = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/tailieuphongban`,
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const documentsCabinetNewItemUnit = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/tailieudonvi`,
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const configUpdate = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/nhanvien/${getState().auth.user}/caidat`,
        method: 'PATCH',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data: {
          su_dung_chat_offline: data?.suDungChatOffline,
          phim_tat_web_send: data.phimTatWebSend,
          loai_thong_bao_sinh_nhat: data.loaiThongBaoSinhNhat,
          ds_nguoi_nhan_thong_bao_sinh_nhat: data.dsNguoiNhanThongBaoSinhNhat
            ? data.dsNguoiNhanThongBaoSinhNhat.map((item) => ({
                nhan_vien_id: item.nhanVienId,
                ten_nhan_vien: item.tenNhanVien,
              }))
            : undefined,
        },
      },
    },
  });

export const getListSurveys =
  (tuNgay, denNgay, tieuDe, page = 1) =>
  (dispatch, getState) => {
    let params = {
      page: page,
      limit: variables.PAGINATION.PAGINATION_LIMIT,
      tuNgay,
      denNgay,
      tieuDe,
    };

    return dispatch({
      types: [types.API_REQUEST_SEND, types.LIST_SURVEYS, types.API_REQUEST_ERROR],
      schema: SurveysArraySchema,
      page: page,
      payload: {
        client: 'survey',
        request: {
          url: `/api/phieukhaosat`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
            'api-version': '2.0',
          },
          params,
        },
      },
    });
  };

export const commonAddToasterMessage = (data) => ({
  type: types.COMMON_ADD_TOASTER_MESSAGE,
  data: data,
});

export const commonRemoveToasterMessage = () => ({
  type: types.COMMON_REMOVE_TOASTER_MESSAGE,
});

export const commonAddDialog = () => ({
  type: types.COMMON_ADD_DIALOG,
});

export const commonRemoveDialog = () => ({
  type: types.COMMON_REMOVE_DIALOG,
});

export const commonReconnectSignalR = (data) => ({
  type: types.COMMON_RECONNECT_SIGNALR,
  data: data,
});

export const postSurvey = (data) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.POST_SURVEY, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat`,
        method: 'POST',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
        data,
      },
    },
  });
};

export const putSurveyNgayHetHan = (data) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/ngayhethan`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
        data,
      },
    },
  });
};

export const getSurveyItem = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.REMOVE_ENTITIES_SURVEY_ITEM, types.GET_SURVEY_ITEM, types.API_REQUEST_ERROR],
    schema: SurveyItemSchema,
    phieuKhaoSatId: id,
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
      },
    },
  });
};

export const getSurveyItemV2 = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_SURVEY_ITEM, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
      },
    },
  });
};

export const removeSurveyItem = () => ({
  type: types.REMOVE_SURVEY_ITEM,
});

export const deleteSurveyListItem = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.DELETE_SURVEY_LIST_ITEM, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
      },
    },
  });
};

export const updateSurvey = (data) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.PUT_SURVEY, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
        data,
      },
    },
  });
};

export const surveySend = (surveyId, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/${surveyId}/gui`,
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const surveyAnswer = (surveyId, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/${surveyId}/traloi`,
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const ThongKe = (id) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_THONGKE_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/thongke/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const surveyCheckPermission = (surveyId) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/${surveyId}/quyen`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const getUsersSurvey =
  (nhom_tham_gia_khao_sat = 0, page = 1, id) =>
  (dispatch, getState) => {
    let params = {
      nhom_tham_gia_khao_sat,
      page: page,
      limit: 15,
    };

    return dispatch({
      types: [types.API_REQUEST_SEND, types.GET_LIST_UERS_SURVEY, types.API_REQUEST_ERROR],
      schema: StatisticalSurveyArraySchema,
      page: page,
      payload: {
        client: 'survey',
        request: {
          url: `/api/phieukhaosat/${id}/nguoinhan`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
          },
          params,
        },
      },
    });
  };

export const getAnswerOther = (id) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.GET_ANSWER_OTHER, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `api/phieukhaosat/dapankhac/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const markImportantMessage = (tinnhanId, hoithoaiId, checked) => (dispatch, getState) => {
  let params = {
    tinnhanId,
    hoithoaiId,
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ADD_IMPORTANT_LIST, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/danhdau`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params,
      },
    },
    message: {
      id: tinnhanId,
      checked,
    },
  });
};

export const getBirthDayUserList = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.GET_BIRTHDAY_USER_LIST, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/nhanvien/sinhnhat',
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const createCustomTextChatBox = (text) => ({
  type: types.CREAT_CUSTOM_TEXT_CHAT,
  data: text,
});
export const handleRotateImg = () => ({
  type: types.HANDLE_ROTATE_IMG,
});

export const resetDeg = () => ({
  type: types.RESET_DEG_IMG,
});

export const feedbackGetList =
  (filter = {}, page = 1, limit = variables.PAGINATION.PAGINATION_LIMIT) =>
  (dispatch, getState) => {
    let params = {
      page: page,
      limit: limit,
    };
    params = Object.assign(params, linkObjectKey(filter));
    return dispatch({
      types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_LIST, types.API_REQUEST_ERROR],
      payload: {
        client: 'survey',
        request: {
          url: '/api/gopy',
          method: 'GET',
          headers: {
            Authorization: getState().auth
              ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
              : '',
          },
          params,
        },
      },
    });
  };

export const feedbackGetDetail = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_DETAIL, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const feedbackXuLy = (data) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.UPDATE_FEEDBACK_HANDLE, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/${data.id}`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
        data,
      },
    },
  });
};

export const feedbackGetStatistic = (params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_LIST_STATISTIC, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: '/api/gopy/thongke',
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params,
      },
    },
  });
};

export const feedbackGetStatisticDetail = (chatLuongId, params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_STATISTIC_DETAIL, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/thongke/${chatLuongId}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params,
      },
    },
  });
};

export const feedbackGetPhienBan = () => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_PHIENBAN, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/phienban`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const feedbackGetChatLuong = () => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_CHATLUONG, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/chatluong`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const feedbackGetTrangThai = () => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.GET_FEEDBACK_TRANGTHAI, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/trangthai`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const getCauHinhGopY = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: '/api/gopy/cauhinh',
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const putCauHinhGopY = (cauHinhId, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/gopy/cauhinh/${cauHinhId}`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const postCauHinhGopY = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: '/api/gopy/cauhinh',
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const guiGopY = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: '/api/gopy',
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const uploadFile = (files) => (dispatch, getState) => {
  let data = {};
  if (Array.isArray(files)) {
    for (const index in files) {
      if (files[index]) {
        data[`images-${index}`] = files[index];
      }
    }
  } else {
    data.image = files;
  }
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'upload',
      request: {
        url: '/api/file/chat',
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });
};

export const getInfoFileDinhKemFromEoffice = (id, type) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_GET_INFO_FILEDINHKEM, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/congvan/${id}/filedinhkem`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params: {
          type,
        },
      },
    },
  });

export const getListEmoticon = () => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_GET_EMOTICON, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/emoticon',
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const putEmoticons = (tinNhanId, params) => (dispatch, getState) => {
  const newParams = { ...params };
  delete newParams.tinNhanId;
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/${tinNhanId}/tinnhan_nhanvien_emoticon`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params: newParams,
      },
    },
  });
};

export const chatSignalRChangeEmoticon = (payload) => (dispatch, getState) => {
  return dispatch({ type: types.CHAT_SIGNALR_UPDATE_MESSAGE, payload });
};

export const setCloseLimitConfirm = (payload) => (dispatch) => {
  return dispatch({ type: types.CHAT_CLOSE_LIMIT_CONFIRM, payload });
};

export const getMessageEmoticon = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/${id}/reaction`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const getUserInfoV2 =
  (id, saveRedux = true) =>
  (dispatch, getState) => {
    const typeRedux = saveRedux ? types.GET_USER_INFOS : types.API_REQUEST_SUCCESS;
    return dispatch({
      types: [types.API_REQUEST_SEND, typeRedux, types.API_REQUEST_ERROR],
      payload: {
        request: {
          url: `/api/nhanvien/${id}`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
        },
      },
    });
  };

export const surveySendV2 = (surveyId, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'survey',
      request: {
        url: `/api/phieukhaosat/v2/${surveyId}/gui`,
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const supportGetList = (params) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/remote/hotrocntt',
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params,
      },
    },
  });
};

export const supportGetById = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/remote/hotrocntt/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};

export const supportPost = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/remote/hotrocntt',
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        params: data,
      },
    },
  });

export const uploadFileSupport = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/remote/hotrocntt/${id}/upload`,
        method: 'POST',
        responseType: 'json',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: -1,
        },
        data,
      },
    },
  });

export const usersSearch =
  ({ organizationId, textSearch, page = 1 }) =>
  (dispatch, getState) => {
    return dispatch({
      types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
      page: page,
      payload: {
        request: {
          url: `/api/donvi/${organizationId}/nhanvien/timkiem`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            textSearch: textSearch,
            page: page,
          },
        },
      },
    });
  };

export const setEditorLoading = (bool) => ({
  type: types.IS_EDITOR_LOADING,
  payload: bool,
});

export const setIsReconnect = (bool) => ({
  type: types.SET_IS_RECONNECT,
  payload: bool,
});

export const setReconnectingStatus = (bool) => ({
  type: types.RECONNECTING_STATUS,
  payload: bool,
});

export const setAudioPlayId = (id) => ({
  type: types.SET_AUDIO_PLAY_ID,
  payload: id,
});

export const setDialogCreateWork = (bool, data) => ({
  type: types.SET_DIALOG_CREATE_WORK,
  payload: {
    isOpen: bool,
    ...data,
  },
});

export const setIsLoginHasBeenChanged = (bool) => ({
  type: types.SET_IS_LOGIN_HAS_BEEN_CHANGED,
  payload: bool,
});

export const goiTinHangLoat = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/external/tinnhan',
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
          'api-version': '2.0',
        },
        data,
      },
    },
  });

export const getListNhanTinNhacViec = () => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/nhantinnhacviec/getdanhsach`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });

export const nhacViecGetById = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/nhantinnhacviec/chitiet/${id}`,
        method: 'GET',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
      },
    },
  });
};
export const taoNhacViec = (data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: '/api/nhantinnhacviec/create',
        method: 'POST',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const suaNhacViec = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/nhantinnhacviec/chitiet/${id}`,
        method: 'PUT',
        headers: {
          Authorization: getState().auth
            ? getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken
            : '',
        },
        data,
      },
    },
  });

export const deleteNhacViec = (id) => (dispatch, getState) => {
  return dispatch({
    types: [types.API_REQUEST_SEND, types.DELETE_SURVEY_LIST_ITEM, types.API_REQUEST_ERROR],
    payload: {
      client: 'eoffice',
      request: {
        url: `/api/nhantinnhacviec/chitiet/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: getState().auth.token.tokenType + ' ' + getState().auth.token.accessToken,
        },
      },
    },
  });
};
