import { Link } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';
import classNames from 'classnames';

import CPCIcon from '@/components/atoms/icon';
import ItemChild from './item-child';
import './item.scss';

const SidebarItem = ({ data, pathname, counterBirthDay, loading, onChange = () => {} }) => {
  const { url, icon, name, lastName, keyName, children } = data;
  const checkActiveParentMenu = () => {
    if (!data?.children) {
      return false;
    }

    let newActive = [];
    data?.children.forEach((element) => {
      if (element?.childrenUrl) {
        newActive = [...newActive, ...element?.childrenUrl];
      }
    });
    let newPathname = pathname.split('/');
    if (newPathname?.length > 1 && !newActive?.some((e) => e === newPathname.join('/'))) {
      newPathname.pop();
    }

    return newActive?.some((e) => e === newPathname.join('/'));
  };

  if (!url) {
    return (
      <div
        className={classNames('nav-left-item', {
          active: children?.some((e) => e?.url === pathname) || checkActiveParentMenu(pathname),
        })}
        onClick={() => onChange(data)}
      >
        {keyName && loading?.[keyName] ? (
          <div
            style={{
              display: 'inline-flex',
              width: '25px',
              height: '25px',
              lineHeight: '25px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircleSpinner size={14} color={'#fff'} />
          </div>
        ) : (
          <CPCIcon className="nav-left-item-icon" icon={icon} size={25} />
        )}
        <div className="nav-left-item-name">
          <span>{name}</span>
          {lastName ? <span>{lastName}</span> : ''}
        </div>
        {counterBirthDay !== 0 && (keyName === 'SINH_NHAT' || keyName === 'TIEN_ICH') && (
          <span className="counter">{counterBirthDay}</span>
        )}
        {children && children?.length !== 0 && (
          <div className="nav-left-item__child">
            {children?.map((item, index) => (
              <ItemChild
                key={index}
                dataSource={item}
                onClick={(item) => onChange(item)}
                counter={{
                  SINH_NHAT: counterBirthDay,
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <Link
      to={url ?? '/'}
      className={`nav-left-item${
        (pathname?.indexOf(url) !== -1 && url !== '/') || pathname === url ? ' active' : ''
      }`}
      onClick={() => onChange(data)}
    >
      <CPCIcon className="nav-left-item-icon" icon={icon} size={25} />
      <div className="nav-left-item-name">
        <span>{name}</span>
      </div>
    </Link>
  );
};

export default SidebarItem;
