import React, { Component } from 'react';
import { Position, PopoverInteractionKind } from '@blueprintjs/core';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import classnames from 'classnames';

import CPCPopover from '@/components/atoms/popover';
import EmoticonItem from './EmoticonItem';
import IconWrapper from '@/components/molecules/icon-wrapper';
import CPCIcon from '@/components/atoms/icon';
import '../assets/SliderSlick.scss';
import '../assets/Emoticon.scss';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconWrapper rotate={180} onClick={onClick}>
      <CPCIcon icon={'arrow-left-v2'} size={14} color="#7589A3" />
    </IconWrapper>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconWrapper onClick={onClick}>
      <CPCIcon icon={'arrow-left-v2'} size={14} color="#7589A3" />
    </IconWrapper>
  );
}

class Emoticon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: undefined,
    };
  }

  changeEmoticon = (emoticon) => {
    this.props && this.props.changeEmoticon(emoticon);
  };

  componentWillReceiveProps(props) {
    if (props && props.isOpenEmoticon) {
      this.setState(
        {
          isOpen: true,
        },
        () => {
          this.props && this.props.onCallBackOpenEmotion();
        }
      );
    }
  }

  render() {
    const slidesToShow = 6;
    const settings = {
      dots: false,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 6,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    const { emoticons, selectedEmoticons, user } = this.props;
    const { isOpen } = this.state;
    // Kiểm tra nếu danh sách emoticons <= slidesToShow thì ẩn nút, giảm chiều rộng
    const emoticonsCouter = emoticons.length;
    let widthPanel = '270px';
    let classSlider = '';
    if (emoticonsCouter <= slidesToShow) {
      widthPanel = '230px';
      classSlider = 'emoticon-slider--no-nav';
    }

    return (
      <div className="ml10">
        <CPCPopover
          isOpen={isOpen || undefined}
          position={Position.TOP_RIGHT}
          interactionKind={PopoverInteractionKind.HOVER}
          content={
            <div
              className="emoticon-block"
              style={{
                width: widthPanel,
                position: 'relative',
              }}
            >
              <Slider {...settings} className={classnames('emoticon-slider', classSlider)}>
                {emoticons &&
                  emoticons.map((elm, index) => {
                    let isActive = false;
                    const indexNumber = selectedEmoticons.findIndex(
                      (i) =>
                        i.emoticonId === elm.emoticonId &&
                        i.count !== 0 &&
                        !!size(i.nhanVienReactInfoList) &&
                        i.nhanVienReactInfoList.some((u) => u.nhanVienId === user.nhanVienId)
                    );
                    if (indexNumber !== -1) {
                      isActive = true;
                    }

                    return (
                      <EmoticonItem
                        key={index}
                        {...elm}
                        changeEmoticon={this.changeEmoticon}
                        isActive={isActive}
                      />
                    );
                  })}
              </Slider>
            </div>
          }
        >
          <div
            className="emoticon-selection"
            onClick={() =>
              this.changeEmoticon(
                emoticons?.find((e) => e?.emoticonId === 'c782db56-6cce-4f1d-b593-4694508b1ba9')
              )
            }
          >
            <CPCIcon icon="like" size={13} color="#7589A3" />
          </div>
        </CPCPopover>
      </div>
    );
  }
}

Emoticon.defaultProps = {
  user: null,
  emoticons: [],
  selectedEmoticons: [],
  changeEmoticon: () => {},
  isOpenEmoticon: false,
};

Emoticon.propTypes = {
  user: PropTypes.object,
  emoticons: PropTypes.array,
  changeEmoticon: PropTypes.func,
  selectedEmoticons: PropTypes.array,
  isOpenEmoticon: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.entities.users[state.auth.user],
  emoticons: state.emoticon.emoticons,
});

export default connect(mapStateToProps)(Emoticon);
