import React, { useMemo } from 'react';
import iosImg from '../../../assets/images/icons/ios.svg';
import windowImg from '../../../assets/images/icons/window.svg';
import { UPDATER_API } from '../../../constants/Api';
import './index.scss';

function DownloadApp(props) {
  const { type } = props;

  const icon = useMemo(() => {
    if (type === 'ios') return iosImg;
    return windowImg;
  }, [type]);

  const link = () => {
    if (type === 'ios') {
      // return `${UPDATER_API}/download/latest/osx_64`;
      return 'https://drive.google.com/file/d/1GVQQRps0YxLItmHv9gzOvTe1v0zXjEcJ/view?usp=sharing';
    }

    // return `${UPDATER_API}/download/latest/windows_32`;
    return 'https://drive.google.com/file/d/16lqYgafyBADMF1ewu9jlkKmYUgsul6Qs/view?usp=sharing';
  };

  return (
    <a href={link()} target="_blank" className="download-app">
      <img src={icon} alt="" className="app-img" />
      <div className="download-body flex-column">
        <span className="download">Tải ứng dụng</span>
        <span className="notification">Thông báo</span>
      </div>
    </a>
  );
}

export default DownloadApp;
