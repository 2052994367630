import CPCIcon from '@/components/atoms/icon';
import { UPLOAD_API_URL } from '@/constants/Api';
import { variables } from '@/constants/variables';
import { checkFileType, getIconFile } from '@/helpers/helper';
import IconWrapper from '../../icon-wrapper';

const FileItem = ({ item, handleUploadCancel, auth }) => {
  const thumbWidth = item?.info?.type === 'image/gif' ? '' : '&w=250';

  return (
    <div className="file-item">
      {checkFileType(item?.info?.type) === 'IMAGE' ? null : (
        <span className="cpc-file-name">{item.name}</span>
      )}
      {/* <span className="cpc-file-size">{bytes(item?.info?.length, { unitSeparator: ' ' })}</span> */}

      {checkFileType(item?.info?.type) === 'IMAGE' ? (
        <img
          className="item-img"
          src={`${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${item?.info?.id}?fileName=${item?.info?.id}${thumbWidth}&BearerToken=${auth?.token?.accessToken}`}
        />
      ) : (
        <img className="item-img document" src={getIconFile(item?.info?.type)} alt="" />
      )}
      <div className="bg-close">
        <IconWrapper color="rgba(8, 28, 54, 0.8)" type="circle" size="s-1">
          <CPCIcon
            icon="xmark"
            onClick={() => {
              handleUploadCancel(item.id, item.name);
            }}
            size={9}
            color="white"
          />
        </IconWrapper>
      </div>
    </div>
  );
};

export default FileItem;
