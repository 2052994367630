import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { ClassicSpinner } from 'react-spinners-kit';

import { arrayToTree } from '@/helpers/helper';
import CPCIcon from '@/components/atoms/icon';
import CPCFlex from '@/components/atoms/flex';
import CPCAvatar from '@/components/atoms/avatar';
import CPCButtonV2 from '@/components/atoms/button-v2';
import InfoAccount from '@/components/molecules/info-account';
import CPCTooltip from '@/components/atoms/tooltip';
import CPCCheckbox from '@/components/atoms/checkbox';
import { createAddTopChat, getConversationCloud, createTopChat } from '@/actions';
import ItemChild from './item';
import './item.scss';

const OrganizationItem = ({
  history,
  actions,
  auth,
  userConfig = {
    isHiddenInfo: false,
    isHiddenAction: false,
    isHiddenOrganization: false,
    avatarSize: 'md',
  },
  hasCheckbox = false,
  data,
  loading,
  dataChecked = [],
  dataDisable = [],
  dataToggle,
  onToggle = () => {},
  onChecked = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUserInfo, setIsOpenUserInfo] = useState(false);
  const [dsPhongBanConvert, setDsPhongBanConvert] = useState([]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen, data);
  };

  const handleCheck = (isChecked) => {
    if (!isOpen && isChecked) {
      setIsOpen(!isOpen);
    }

    onChecked(!isOpen, isChecked, data);
  };

  const onUserAction = (type) => {
    if (type === 'CHAT') {
      setIsLoading(true);
      if (data?.nhanVienId === auth?.user) {
        actions.getConversationCloud().then((res) => {
          setIsLoading(false);
          if (res?.payload?.data?.result) {
            actions.createTopChat(res.payload.data.result);
            history.push('/');
          }
        });
      } else {
        try {
          const requiredData = {
            nhan_vien_hoi_thoai: [{ nguoi_nhan_id: data?.nhanVienId }],
            nguoi_tao_id: auth?.user,
          };
          actions.createAddTopChat(requiredData, 1).then((res) => {
            setIsLoading(false);
            if (res) {
              history.push('/');
            }
          });
        } catch (error) {
          setIsLoading(false);
          console.error(error);
        }
      }
    } else if (type === 'VIEW_INFOS') {
      setIsOpenUserInfo(true);
    }
  };

  const getUsers = (data, result = []) => {
    let newData = [...result];
    if (data?.dsNhanVien && data?.dsNhanVien?.length !== 0) {
      newData = [...newData, ...data?.dsNhanVien];
    }

    if (data?.children && data?.children?.length !== 0) {
      data?.children.forEach((element) => {
        newData = getUsers(element, newData);
      });
    }

    return newData;
  };

  const handleChecked = () => {
    if (data?.nhanVienId) {
      return dataChecked?.some((e) => e?.nhanVienId === data?.nhanVienId);
    } else if (data?.phongBanId && data?.maPhongBan) {
      const users = data?.dsNhanVien;
      const userDepartmentChecked = dataChecked?.filter((e) =>
        e?.dsChucDanh?.some((role) => role?.phongBan?.phongBanId === data?.phongBanId)
      );
      if (
        userDepartmentChecked &&
        users &&
        userDepartmentChecked?.length !== 0 &&
        users?.length !== 0 &&
        userDepartmentChecked?.length === users?.length
      ) {
        return true;
      }

      return false;
    } else if (data?.donViId && data?.maDonVi) {
      let newData = [];
      data?.dsPhongBan?.forEach((elm) => {
        newData = [...newData, ...getUsers(elm)];
      });
      data?.dsDonViCon?.forEach((elm) => {
        elm?.dsPhongBan?.forEach((department) => {
          const childUsers = getUsers(department);
          newData = [...newData, ...childUsers];
        });
      });
      const userOrganizationChecked = dataChecked?.filter((e) =>
        e?.dsChucDanh?.some((role) => role?.donVi?.donViId === data?.donViId)
      );
      if (
        userOrganizationChecked &&
        newData &&
        userOrganizationChecked?.length !== 0 &&
        newData?.length !== 0 &&
        userOrganizationChecked?.length === newData?.length
      ) {
        return true;
      }

      return false;
    }

    return undefined;
  };

  const handleIndeterminate = () => {
    if (data?.phongBanId && data?.maPhongBan) {
      const dsNhanVien = data?.dsNhanVien;
      const userInDepartment = dataChecked?.filter((e) =>
        e?.dsChucDanh?.some((role) => role?.phongBan?.phongBanId === data?.phongBanId)
      );

      if (
        userInDepartment &&
        userInDepartment?.length !== 0 &&
        userInDepartment?.some((user) =>
          dsNhanVien?.some((nv) => nv?.nhanVienId === user?.nhanVienId)
        )
      ) {
        return true;
      }

      return false;
    }

    if (data?.donViId && data?.maDonVi) {
      const dsNhanVien = data?.dsNhanVien ?? [];
      const userInOrganization =
        dataChecked?.filter((e) =>
          e?.dsChucDanh?.some((role) => role?.donVi?.donViId === data?.donViId)
        ) ?? [];

      let newData = dsNhanVien;
      data?.dsPhongBan?.forEach((elm) => {
        newData = [...newData, ...getUsers(elm)];
      });
      data?.dsDonViCon?.forEach((elm) => {
        elm?.dsPhongBan?.forEach((department) => {
          const childUsers = getUsers(department);
          newData = [...newData, ...childUsers];
        });
      });
      if (
        userInOrganization &&
        userInOrganization?.length !== 0 &&
        userInOrganization?.some((user) =>
          newData?.some((nv) => nv?.nhanVienId === user?.nhanVienId)
        )
      ) {
        return true;
      }

      return false;
    }

    return false;
  };

  const handleDisabled = () => {
    if (data?.phongBanId && data?.maPhongBan) {
      const dsNhanVien = data?.dsNhanVien;
      const a = dataDisable?.filter((userId) =>
        dsNhanVien?.some((nv) => nv?.nhanVienId === userId)
      );
      if (dsNhanVien?.length === a?.length) {
        return true;
      }
    }

    return dataDisable?.some((elmId) => elmId === data?.nhanVienId);
  };

  useEffect(() => {
    if (data?.dsPhongBan && data?.dsPhongBan?.length !== 0) {
      setDsPhongBanConvert(arrayToTree(data?.dsPhongBan, null, 'phongBanCha', 'phongBanId'));
    }
  }, [data?.dsPhongBan]);

  useEffect(() => {
    if (dataToggle && dataToggle?.length !== 0 && data?.phongBanId && data?.maPhongBan) {
      setIsOpen(dataToggle?.some((phongBanId) => phongBanId === data?.phongBanId) || false);
    }
  }, []);

  const viewItem = () => {
    if (data?.donViId && data?.maDonVi) {
      return (
        <div
          className={classNames('name cursor-pointer', {
            active: isOpen,
          })}
          onClick={handleToggle}
        >
          {data?.tenDonVi}{' '}
        </div>
      );
    } else if (data?.phongBanId && data?.maPhongBan) {
      const users = getUsers(data);
      return (
        <div
          className={classNames('name cursor-pointer', {
            active: isOpen,
          })}
          onClick={handleToggle}
        >
          {data?.tenPhongBan}
          <span style={{ color: '#7589A3', marginLeft: 8 }}>
            ({users?.filter((e) => e?.trucTuyen)?.length || 0} /{users?.length || 0})
          </span>
        </div>
      );
    }

    const chucDanhChinh = data?.dsChucDanh?.find((chucDanh) => chucDanh?.phongBanChinh);

    return (
      <CPCFlex
        align={'center'}
        justify={'space-between'}
        className={classNames('user', {
          active: isOpen,
        })}
      >
        <CPCFlex
          align={'center'}
          style={{ width: '100%' }}
          className={'cursor-pointer'}
          onClick={() => (!isLoading ? onUserAction('CHAT') : null)}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <CPCAvatar
              src={data?.anhDaiDien}
              size={userConfig?.avatarSize}
              trucTuyen={data?.trucTuyen}
              onClick={() => setIsOpenUserInfo(true)}
            />
          </div>
          <div className="ml-10">
            <div className="user-name">
              <span>{data?.tenNhanVien}</span>
              {!userConfig?.isHiddenAction && (
                <div className="organization-item-action">
                  <CPCTooltip content={'Nhắn tin'} theme="white" openOnTargetFocus={false}>
                    <CPCButtonV2
                      icon={<CPCIcon icon="chat" size="20px" />}
                      minimal
                      loading={isLoading}
                      disabled={isLoading}
                      // onClick={() => (!isLoading ? onUserAction('CHAT') : null)}
                    />
                  </CPCTooltip>
                </div>
              )}
            </div>
            {!userConfig?.isHiddenInfo && data?.soDienThoai && (
              <p className="user-phone mt-5">
                <CPCIcon icon="phone" size="14px" className="user-info-icon" />
                {data?.soDienThoai}
              </p>
            )}
            {!userConfig?.isHiddenInfo && data?.soDienThoaiKhac && (
              <p className="user-phone mt-5">
                <CPCIcon icon="phone" size="14px" className="user-info-icon" />
                {data?.soDienThoaiKhac}
              </p>
            )}
            {!userConfig?.isHiddenInfo && data?.tamTrang && (
              <p className="user-status mt-5">
                <CPCIcon icon="status" size="12px" className="user-info-icon" />
                {data?.tamTrang}
              </p>
            )}
            {!userConfig?.isHiddenOrganization && chucDanhChinh?.donVi?.tenVietTat ? (
              <p className="user-other-info mt-5">
                <CPCIcon icon="building" size="12px" className="user-info-icon" />
                {chucDanhChinh.donVi.tenVietTat}
              </p>
            ) : (
              ''
            )}
            {!userConfig?.isHiddenOrganization && chucDanhChinh?.phongBan?.maPhongBan ? (
              <p className="user-other-info mt-5">
                <CPCIcon icon="department" size="12px" className="user-info-icon" />
                {chucDanhChinh.phongBan.maPhongBan}
              </p>
            ) : (
              ''
            )}
          </div>
        </CPCFlex>
      </CPCFlex>
    );
  };

  return (
    <div className="organization-item">
      <CPCFlex align={'center'}>
        {!data?.tenNhanVien && (
          <div style={{ width: '20px', lineHeight: '100%' }}>
            {loading?.some((e) => e === data.donViId) ? (
              <ClassicSpinner color="#555" size={10} />
            ) : (
              <CPCIcon
                icon={isOpen ? 'minus-v2' : 'plus-v2'}
                style={{ color: '#1552DC', cursor: 'pointer' }}
                onClick={handleToggle}
              />
            )}
          </div>
        )}
        {hasCheckbox && (
          <CPCCheckbox
            size={'xs'}
            checked={handleChecked()}
            disabled={handleDisabled()}
            indeterminate={!handleChecked() && handleIndeterminate()}
            onChange={(event) =>
              handleCheck(handleIndeterminate() ? false : event?.target?.checked)
            }
          />
        )}
        {viewItem()}
      </CPCFlex>
      {isOpen && (
        <div
          className={classNames({
            'ml-35': hasCheckbox,
            'ml-25': !hasCheckbox,
          })}
        >
          {data?.dsNhanVien?.map((elm, index) => (
            <ItemChild
              key={index}
              userConfig={userConfig}
              hasCheckbox={hasCheckbox}
              data={elm}
              history={history}
              loading={loading}
              dataChecked={dataChecked}
              dataDisable={dataDisable}
              onToggle={onToggle}
              onChecked={onChecked}
            />
          ))}
          {data?.children?.map((elm, index) => (
            <ItemChild
              key={index}
              userConfig={userConfig}
              hasCheckbox={hasCheckbox}
              data={elm}
              history={history}
              loading={loading}
              dataChecked={dataChecked}
              dataDisable={dataDisable}
              onToggle={onToggle}
              onChecked={onChecked}
            />
          ))}
          {dsPhongBanConvert?.map((elm, index) => (
            <ItemChild
              key={index}
              userConfig={userConfig}
              hasCheckbox={hasCheckbox}
              data={elm}
              history={history}
              loading={loading}
              dataChecked={dataChecked}
              dataDisable={dataDisable}
              onToggle={onToggle}
              onChecked={onChecked}
            />
          ))}
          {data?.dsDonViCon?.map((elm, index) => (
            <ItemChild
              key={index}
              userConfig={userConfig}
              hasCheckbox={hasCheckbox}
              data={elm}
              history={history}
              loading={loading}
              dataChecked={dataChecked}
              dataDisable={dataDisable}
              onToggle={onToggle}
              onChecked={onChecked}
            />
          ))}
        </div>
      )}
      {isOpenUserInfo && (
        <InfoAccount
          history={history}
          open={isOpenUserInfo}
          data={data}
          handleClose={() => setIsOpenUserInfo(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const auth = state.auth;
  return {
    auth: auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createAddTopChat,
      getConversationCloud,
      createTopChat,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationItem);
