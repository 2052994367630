import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { size, get } from 'lodash';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions';
import CPCFlex from '@/components/atoms/flex';
import { IMAGE_URL } from '../../../constants/Api';
import { groupChildrensByOrigin } from '../../../helpers/helper';
import TagEmoticon from './TagEmoticon';
import { User } from '../../common';
import { LoadingRow } from '../../../components/common';
import { Popover2 } from '@blueprintjs/popover2';

// Popover Vị trí tự động hiển thị
const tetherConstraints = [
  {
    attachment: 'together',
    pin: true,
    to: 'window',
  },
];

class TagsEmoticon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      data: props.data,
      dataGroup: [],
      dataGroupFull: [],
      dataTabs: [],
      isOpen: false,
      isLoading: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      activeTab: null,
      data: props.data,
    });
  }

  onFilterEmoticon = (value, key) => {
    const { dataGroupFull } = this.state;
    let dataFilter = dataGroupFull;
    if (value && size(value.users) !== 0) {
      dataFilter = value.users.map((elm) => ({
        ...elm,
        // emoticon_array: [
        //   {
        //     emoticon: value.emoticon
        //   }
        // ]
      }));
    }

    this.setState({
      dataGroup: dataFilter,
      activeTab: key,
    });
  };

  renderThumb = (props) => {
    return <div {...props} />;
  };

  onInteraction = async () => {
    const { message } = this.props;

    this.setState({
      isOpen: !this.state.isOpen,
    });
    try {
      this.setState({ isLoading: true });
      const response = await this.props.actions.getMessageEmoticon(message.tinNhanId);
      if (response && get(response, 'payload.data.result.items')) {
        const newResponse = get(response, 'payload.data.result.items');
        let filterHasData = [];
        let dataGroup = [];
        if (size(newResponse) !== 0) {
          filterHasData = newResponse
            .filter((f) => f.nhanVienListCount !== 0 && size(f.dsNhanVien) !== 0)
            .map((elm) => {
              let newItem = {
                emoticon: elm.emoticonName,
                count: elm.nhanVienListCount,
                users: elm.dsNhanVien
                  ? elm.dsNhanVien.map((i) => ({
                      userId: i.nhanVienId,
                      name: i.tenNhanVien,
                      avatar: IMAGE_URL + i.anhDaiDien,
                      department: i.maPhongBan,
                      unit: i.tenDonViVietTat,
                    }))
                  : [],
              };
              return newItem;
            });
          if (size(filterHasData) !== 0) {
            dataGroup = groupChildrensByOrigin(filterHasData, 'userId', 'users', 'emoticon_array');
          }
        }
        this.setState({
          dataTabs: filterHasData || [],
          dataGroup: dataGroup || [],
          dataGroupFull: dataGroup || [],
        });
      }
    } catch (error) {
      this.setState({
        dataTabs: [],
        dataGroup: [],
        dataGroupFull: [],
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { totalNumber } = this.props;
    const { data, dataGroup, dataTabs, activeTab, isOpen, isLoading } = this.state;
    return (
      <Popover2
        isOpen={isOpen}
        portalClassName="popover-v2"
        disabled={totalNumber === 0}
        content={
          totalNumber !== 0 ? (
            <div className="tags-emoticon-history">
              <h3 className="title">Biểu cảm</h3>
              {isLoading ? (
                <div
                  style={{
                    background: '#fff',
                    padding: '20px',
                    minWidth: '250px',
                  }}
                >
                  <LoadingRow className="mb10" width={'80%'} />
                  <LoadingRow className="mb10" width={'50%'} />
                  <LoadingRow width={'100%'} />
                </div>
              ) : (
                <div className="tags-emoticon-history__content">
                  <div className="tags-emoticon-history__content__synthesis">
                    <div className="div-center">
                      <span
                        className={classNames('all-emoji', {
                          active: !activeTab,
                        })}
                        onClick={() => this.onFilterEmoticon(null, null)}
                      >
                        Tất cả <span className="number">({totalNumber})</span>
                      </span>
                    </div>
                    <div className="tags-emoticon-history__content__synthesis__emoticons">
                      <ul>
                        {size(dataTabs) !== 0 &&
                          dataTabs.map((elm, index) => (
                            <li
                              key={index}
                              className={classNames({
                                active: activeTab === index + 1,
                              })}
                              onClick={() => this.onFilterEmoticon(elm, index + 1)}
                            >
                              <TagEmoticon {...elm} isShowCount className="tag-emoticon" />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="tags-emoticon-history__content__grouping">
                    <Scrollbars
                      autoHide
                      autoHeight
                      renderView={this.renderView}
                      renderTrackHorizontal={this.renderThumb}
                      autoHeightMax="220px"
                    >
                      <ul className="tags-emoticon-history__content__grouping__box">
                        {size(dataGroup) !== 0 &&
                          dataGroup.map((elm, index) => (
                            <li key={index}>
                              <div className="tags-emoticon-history__content__grouping__users-emoticons">
                                <User {...elm} />
                                <CPCFlex align={'center'} className={'ml-20'}>
                                  {size(elm.emoticon_array) !== 0 && (
                                    <ul>
                                      {elm.emoticon_array.map((e, key) => (
                                        <li key={key}>
                                          <TagEmoticon {...e} className="tag-emoticon" />
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                  {!activeTab && (
                                    <span className="text-13-500 color-text-time ml-5">
                                      {size(elm.emoticon_array)}
                                    </span>
                                  )}
                                </CPCFlex>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Scrollbars>
                  </div>
                </div>
              )}
            </div>
          ) : null
        }
        tetherOptions={{ constraints: tetherConstraints }}
        onClose={this.handleClose}
      >
        <ul className="tags-emoticon tags-emoticon__small" onClick={this.onInteraction}>
          {size(data) !== 0
            ? data.slice(0, 3).map((elm, index) => {
                return (
                  <li key={index}>
                    <TagEmoticon {...elm} />
                  </li>
                );
              })
            : ''}
          {totalNumber > 0 ? <span className="total-number">{totalNumber}</span> : ''}
        </ul>
      </Popover2>
    );
  }
}

TagsEmoticon.defaultProps = {
  data: [],
};

TagsEmoticon.propTypes = {
  data: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(null, mapDispatchToProps)(TagsEmoticon);
