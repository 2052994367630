import React, { Component } from 'react';
import classnames from 'classnames';
import './assets/birthday.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';
import { Spinner } from '@blueprintjs/core';
import { IMAGE_URL } from '../../constants/Api';
import moment from 'moment';
import { isEqual, isEmpty } from 'lodash';
import birthdayBg from '@/assets/images/birthday.png';
import balloonBg from '@/assets/images/bg-balloon.svg';
import chucMungImg from '@/assets/images/birthday-text.png';
import { setMessageContents } from '@/helpers/cache';
const TONG_CONG_TY_ID = '06914739-d280-e711-80cd-40167e7b9c6a';
class Birthday extends Component {
  state = {
    isLoading: false,
    birthdayUserList: [],
    isLoadingBirthdayClick: false,
  };

  componentDidMount = () => {
    this.props.animteBirthday();
    this.handleUserBirthday();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isUserBirthDay !== prevProps.isUserBirthDay ||
      !isEqual(prevProps.auth, this.props.auth) ||
      !isEqual(prevProps.birthdayUserList, this.props.birthdayUserList)
    ) {
      this.handleUserBirthday();
    }
  }

  getAllUserByUnit = (type, birthdayUserList, unit) => {
    let idType = type === 'PC' ? 'donViId' : 'donViChaId';
    let isNotHaveAnyUser = false;
    let userList = [];
    while (!isNotHaveAnyUser) {
      let userIndex = birthdayUserList.findIndex((user) => user[idType] === unit.donViId);
      if (userIndex !== -1) {
        userList.push(birthdayUserList[userIndex]);
        birthdayUserList.splice(userIndex, 1);
      } else {
        isNotHaveAnyUser = true;
      }
    }
    return userList;
  };

  handleUserBirthday = () => {
    let birthdayUserList = [...this.props.birthdayUserList];
    if (
      this.props.isUserBirthDay &&
      this.props.birthdayUserList &&
      this.props.birthdayUserList.length > 0 &&
      this.props.auth &&
      this.props.auth.user
    ) {
      birthdayUserList = this.props.birthdayUserList.filter(
        (item) => item.nhanVienId !== this.props.auth.user.nhanVienId
      );
    }
    const { units } = this.props;
    let groupBirthdayUserList = [];
    let donViChinh =
      this.props.auth && this.props.auth.mainUnitId && this.props.unit[this.props.auth.mainUnitId]
        ? this.props.unit[this.props.auth.mainUnitId]
        : null;
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      let isUnitHaveCurrenUser = false;
      let userUnitGroupList = [];
      let userPCList = this.getAllUserByUnit('PC', birthdayUserList, unit);
      // i === 0 là tổng công ty, là donViChaId của các PC còn lại, nên group sẽ bị sai
      let userDLList = i !== 0 ? this.getAllUserByUnit('DL', birthdayUserList, unit) : [];
      userUnitGroupList = [...userPCList, ...userDLList];
      if (donViChinh) {
        let userCungDv = [];
        let userKhacDv = [];
        userUnitGroupList.forEach((item) => {
          if (
            item.donViId === donViChinh.donViId ||
            (item.donViChaId === donViChinh.donViId && donViChinh.donViId !== TONG_CONG_TY_ID) ||
            (item.donViChaId === donViChinh.donViCha && donViChinh.donViCha !== TONG_CONG_TY_ID) ||
            (item.donViId === donViChinh.donViCha && donViChinh.donViCha !== TONG_CONG_TY_ID)
          ) {
            isUnitHaveCurrenUser = true;
          }
          if (item.donViId === donViChinh.donViId) {
            userCungDv.push(item);
          } else {
            userKhacDv.push(item);
          }
        });
        userUnitGroupList = [...userCungDv, ...userKhacDv];
      }
      if (!isEmpty(userUnitGroupList)) {
        if (isUnitHaveCurrenUser) {
          groupBirthdayUserList = [...userUnitGroupList, ...groupBirthdayUserList];
        } else {
          groupBirthdayUserList.push(...userUnitGroupList);
        }
      }
    }
    this.setState({ birthdayUserList: groupBirthdayUserList });
  };

  sendBirthdayGreetings = (id) => {
    this.setState({ isLoadingBirthdayClick: true });
    const requiredData = {
      nhan_vien_hoi_thoai: [{ nguoi_nhan_id: id }],
      nguoi_tao_id: this.props.auth?.user?.nhanVienId,
    };
    this.props.actions.createAddTopChat(requiredData, 1).then((res) => {
      this.props.closeDialog();
      this.setState({ isLoadingBirthdayClick: false });
      if (res) {
        const hoiThoaiId = res?.payload?.data?.result?.hoiThoaiId;
        if (
          hoiThoaiId &&
          (this.props.history?.location?.pathname !== '/' ||
            (this.props.history?.location?.pathname === '/' &&
              this.props?.typeConversation !== 'ALL'))
        ) {
          setMessageContents(hoiThoaiId, 'Happy birthday! 🎁 🎉 🎂');
        }

        if (this.props.history?.location?.pathname !== '/') {
          return this.props.history.push('/');
        } else {
          if (this.props?.typeConversation !== 'ALL') {
            // Nếu đang active tab khác "tất cả" thì kích hoạt lại để xử lý tiếp
            this.props.actions.setActivateAllTab(true);
          } else {
            if (hoiThoaiId) {
              this.props.actions.setContentEditor('Happy birthday! 🎁 🎉 🎂');
            }

            this.props.actions.setTopConversation(res.payload?.data?.result);
          }
        }
      }
    });
  };

  render() {
    const { birthdayUserList } = this.props;

    return (
      <div
        ref={this.props.getBirthdayRef}
        className={classnames(
          'birthday-wrap m-auto bg-white d-flex border-radius-5 overflow-hidden flex-row max-width-1000 transition-300',
          {
            'fade-in-top': !this.props.isAnimate,
          }
        )}
      >
        <div className="birthday-left" style={{ backgroundImage: `url('${birthdayBg}')` }}>
          <img src={chucMungImg} className="text-congratulation" />
          <span className="date">{new Date().toLocaleDateString('en-GB')}</span>
        </div>
        <div className="birthday-right">
          {birthdayUserList && birthdayUserList.length > 0 && (
            <div
              className={classnames(
                'row m0 p-10 position-relative min-height-100px transition-300 overflow-y-auto',
                {
                  'max-height-515px': birthdayUserList && birthdayUserList.length > 0,
                }
              )}
            >
              {birthdayUserList.map((item, i) => (
                <div className="col-xs-6 p-10" key={item.nhanVienId}>
                  <ul
                    className={classnames(
                      'list-style-none text-align-center p0 py-10 border-gray shadow-black border-radius-5 line-height-30 bg-bottom hover-select',
                      {
                        'fade-in-top': this.state.isLoading,
                      }
                    )}
                    onClick={
                      !this.state?.isLoadingBirthdayClick
                        ? () => this.sendBirthdayGreetings(item.nhanVienId)
                        : null
                    }
                    style={{ backgroundImage: `url(${balloonBg})` }}
                  >
                    <li className="avatar">
                      <div
                        style={{ backgroundImage: `url(${IMAGE_URL + item.anhDaiDien})` }}
                        className="rounded w-49px h-49px m-auto bg-cover border-gray"
                      ></div>
                    </li>
                    <li className="name">{item.tenNhanVien}</li>
                    <li className="short-name">
                      {item.maPhongBan} - {item.tenVietTat}{' '}
                    </li>
                    <li className="year">{moment(item.ngaySinh).format('YYYY')}</li>
                  </ul>
                </div>
              ))}

              {this.state.isLoading && (
                <div className="position-absolute top-0 left-0 w-100 h-100 d-flex">
                  <Spinner className="m-auto" size={200} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
  units: (() => {
    let { units } = state.contact;
    return units.map((id) => state.entities.units[id]);
  })(),
  typeConversation: state.chat?.typeConversation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Birthday);
