import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from '@/actions';
import { FE_CHAT_URL } from '@/constants/Api';
import CPCFlex from '@/components/atoms/flex';
import CPCDialog from '@/components/atoms/dialog';
import CPCButtonV2 from '@/components/atoms/button-v2';
import './assets/preload.scss';

class Preload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      isPopup: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.location && this.props.location.state && this.props.location.state.token) {
      await this.props.actions.setTokenWithQuery(this.props.location.state.token);
    }

    if (this.props.location && this.props.location.state && this.props.location.state.fileId) {
      await this.props.actions.setFileIdFromEoffice(this.props.location.state.fileId);
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.infoGetFileDinhKem
    ) {
      await this.props.actions.setInfoGetFileDinhKemFromEoffice(
        this.props.location.state.infoGetFileDinhKem,
        this.props.location.state.infoGetFileDinhKemType
      );
    }

    if (
      this.props.location?.state?.hoiThoai?.tinNhanId ||
      this.props.location?.state?.hoiThoai?.hoiThoaiId
    ) {
      if (this.props.location?.state?.hoiThoai?.tinNhanId) {
        this.props.actions
          .getInfoMessage(this.props.location.state.hoiThoai.tinNhanId)
          .then((tinNhan) => {
            if (tinNhan?.payload?.data?.result?.hoiThoaiId) {
              this.props.actions
                .getConversationDetailV2(tinNhan.payload.data.result.hoiThoaiId)
                .then((hoiThoai) => {
                  this.props.actions.setFromOtherAppData({
                    tinNhan: tinNhan?.payload?.data?.result,
                    hoiThoai: hoiThoai?.payload?.data?.result,
                  });
                });
            }
          });
      }
    }

    if (!this.props?.auth || !this.props.auth?.token) {
      return;
    }

    try {
      await this.props.actions.userAuthenticated().then((res) => {
        const user = res.payload.data.entities.users[res.payload.data.result.result];
        const defaultApartmend = user.dsChucDanh.find((item) => item.phongBanChinh === true);
        if (defaultApartmend) {
          return this.props.actions.userPermission(defaultApartmend.id).then((res) => {
            const items = res?.payload?.data?.result?.items;
            this.props.actions.getUserConfig();
            this.props.actions.preloadSuccess();
            this.setState({ success: true, isPopup: false });
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  onClose = () => {
    this.setState({ success: false, isPopup: false });
    this.props.actions.authLogout();
  };

  goChatLive = () => {
    const { accessToken, expiresIn, tokenType, refreshToken } = this.props.auth?.token;
    if (accessToken) {
      this.props.actions.authLogout();
      const url =
        FE_CHAT_URL +
        `?accessToken=${accessToken}&expiresIn=${expiresIn}&tokenType=${tokenType}&refreshToken=${refreshToken}`;
      return window.open(url, '_blank');
    }

    return;
  };

  render() {
    const { isPopup } = this.state;
    let lastLink = this.props.location.state ? this.props.location.state.from || '/' : '/';
    if (lastLink === '/login') {
      lastLink = '/';
    }

    return (
      <div>
        {!isPopup && (
          <div className="cpc-preload">
            <div className="cpc-logo" />
            <div className="cpc-spinner" />
            <div className="cpc-spinner-2" />
            <div className="cpc-loading-text">Đang tải dữ liệu...</div>
            <div className="cpc-back-link">
              <Link to="/login">Bỏ qua</Link>
            </div>
          </div>
        )}
        {isPopup && (
          <CPCDialog isOpen={isPopup} title={'Thông báo'} onClose={this.onClose}>
            <div className="p-20">
              <p className="m-0">CPC Chat beta 7.1 chưa được triển khai ở đơn vị của Anh/Chị.</p>
              <p className="m-0">
                Bấm <b>Đồng ý</b> để qua lại ứng dụng chat hiện tại.
              </p>
            </div>
            <CPCFlex align={'center'} justify={'flex-end'} gap={20} className={'border-top p-20'}>
              <CPCButtonV2
                text={'Đồng ý'}
                classNameText={'text-14-600'}
                color={'light-gray'}
                size={'sm'}
                min={'90'}
                shape={'round-sm'}
                disabled={false}
                loading={false}
                onClick={this.goChatLive}
              />
            </CPCFlex>
          </CPCDialog>
        )}
        {this.state.success && !isPopup && <Redirect to={{ pathname: lastLink }} />}
      </div>
    );
  }
}

Preload.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preload);
