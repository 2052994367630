import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchUserSuggestionItem from './SearchUserSuggestionItem';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/search-user-suggestion.scss';

export default class SearchUserSuggestion extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    suggestUsers: PropTypes.object.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.handleChangeFilterUser = this.handleChangeFilterUser.bind(this);
    this.handleSuggestUsers = this.handleSuggestUsers.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.suggestUser = this.suggestUser.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);
    this.handleBlurInput = this.handleBlurInput.bind(this);
  }

  state = {
    loading: false,
    search: '',
    timeoutFilterUser: null,
    showUserSuggestion: false,
  };

  handleChangeFilterUser = (e) => {
    this.setState({ loading: true });
    this.props.onChange(null);
    clearTimeout(this.state.timeoutFilterUser);

    this.setState({
      [e.target.name]: e.target.value,
      timeoutFilterUser: setTimeout(this.handleSuggestUsers, 1000),
    });
  };

  handleScroll = (values) => {
    if (this.props.suggestUsers.items.length >= this.props.suggestUsers.total) {
      return;
    }

    if (values.top >= 1) {
      this.suggestUser(this.props.suggestUsers.page + 1);
    }
  };

  handleSelectUser = (user) => {
    this.setState({ search: user.tenNhanVien });
    this.props.onChange(user);
  };

  handleSuggestUsers = () => {
    this.suggestUser(1);
  };

  suggestUser = (page = 1) => {
    if (this.state.search.trim() === '') {
      return;
    }

    this.setState({ loading: true }, () => {
      if (this.props.other && this.props.other !== '') {
        return this.props.actions
          .chatSearchSuggestUserAtUnit(this.props.other, this.state.search, page)
          .then(() => {
            this.setState({ loading: false });
          });
      }

      return this.props.actions.chatSearchSuggestUser(this.state.search, page).then(() => {
        this.setState({ loading: false });
      });
    });
  };

  handleBlurInput = () => {
    setTimeout(() => {
      this.setState({ showUserSuggestion: false });
    }, 200);
  };

  render() {
    return (
      <div className="cpc-filter-user cpc-search-user-suggestion">
        <input
          className="pt-fill border-radius-right-0"
          type="text"
          placeholder="Chọn nhân viên..."
          name="search"
          value={this.state.search}
          onChange={this.handleChangeFilterUser}
          autoComplete="off"
          onFocus={() => this.setState({ showUserSuggestion: true })}
          onBlur={this.handleBlurInput}
        />
        {this.state.search.trim() !== '' && this.state.showUserSuggestion ? (
          <div className="cpc-user-suggestion">
            <Scrollbars
              style={{ width: '100%', height: '100%' }}
              onScrollFrame={this.handleScroll}
              renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
            >
              {this.props.suggestUsers.total === 0 ? (
                <div className="cpc-not-found">Không tìm thấy kết quả</div>
              ) : (
                ''
              )}
              {this.props.suggestUsers.items.map((user, i) => (
                <SearchUserSuggestionItem
                  key={i}
                  user={user}
                  actions={this.props.actions}
                  onClick={this.handleSelectUser}
                />
              ))}
              {this.props.suggestUsers.items.length < this.props.suggestUsers.total ||
              this.state.loading ? (
                <div className="spinner spinner-custom">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              ) : null}
            </Scrollbars>
          </div>
        ) : null}
      </div>
    );
  }
}
