import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, size, get } from 'lodash';
import shallowCompare from 'react-addons-shallow-compare';
import classnames from 'classnames';
import { Collapse, Text } from '@blueprintjs/core';

import { TINNHAN_SMS_365 } from '../../constants/Permissions';
import ContactItem from './ContactItem';
import Department from './Department';
import './assets/department-item.scss';
import CPCCheckbox from '../atoms/checkbox';

export default class DepartmentItem extends Component {
  static propTypes = {
    department: PropTypes.object.isRequired,
    parentIds: PropTypes.array,
    unit: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    filterOnline: PropTypes.bool,
    receivers: PropTypes.array,
  };

  static defaultProps = {
    auth: {},
    parentIds: [], // detect recursive infinite loop
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.getChecked = this.getChecked.bind(this);
  }

  state = {
    isOpen: this.props.auth.mainDepartmentId === this.props.department.phongBanId,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(this.props, nextProps) && isEqual(this.state, nextState)) {
      return false;
    }

    return shallowCompare(this, nextProps, nextState);
  }

  getUserOnline = () => {
    if (!this.props.department.dsNhanVien || !this.props.department.dsNhanVien.length) {
      return [];
    }

    let userOnline = this.props.department.dsNhanVien.filter((user) => {
      return user.trucTuyen === 1;
    });

    this.props.unit.dsPhongBan
      .filter((i) => {
        return (
          this.props.department.phongBanId === i.phongBanCha &&
          this.props.department.phongBanId !== i.phongBanId
        );
      })
      .map((i) => {
        return i.dsNhanVien.filter((user) => {
          return user.trucTuyen === 1 && userOnline.push(user);
        });
      });

    return userOnline;
  };

  getChecked = () => {
    if (!this.props.department.dsNhanVien || !this.props.department.dsNhanVien.length) {
      return false;
    }

    for (const user of this.props.department.dsNhanVien) {
      if (user && !user.checked) {
        return false;
      }
    }
    return true;
  };

  getUsers = () => {
    if (!this.props.department.dsNhanVien || !this.props.department.dsNhanVien.length) {
      return [];
    }

    if (this.props.filterOnline === true) {
      return this.getUserOnline();
    }

    return this.props.department.dsNhanVien;
  };

  handleCheckbox = () => {
    if (this.getChecked()) {
      this.props.children.map((department) => {
        return this.props.actions.chatRemoveMultipleReceivers(department.dsNhanVien);
      });

      return this.props.actions.chatRemoveMultipleReceivers(this.props.department.dsNhanVien);
    }

    let users = [];
    if (this.props.filterOnline === true) {
      users = [...users, ...this.getUserOnline()];
    }
    for (const department of this.props.children) {
      const dsNhanVien = department.dsNhanVien || [];
      users = [...users, ...dsNhanVien];
    }
    if (this.props.department && size(this.props.department.dsNhanVien) !== 0) {
      users = [...users, ...this.props.department.dsNhanVien];
    }

    let newUsers = [...users];
    if (this.props.receivers && size(this.props.receivers) !== 0) {
      newUsers = [...this.props.receivers, ...newUsers];
    }

    if (
      size(newUsers) > 1200 &&
      get(this.props, 'auth') &&
      this.props.auth.permissions.indexOf(TINNHAN_SMS_365) === -1
    ) {
      return this.props.actions.setCloseLimitConfirm(true);
    }

    this.props.actions.chatAddMultipleReceivers(users);
  };

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const users = this.getUsers();

    return (
      <div className="cpc-department-item">
        <div
          className={classnames('cpc-item', {
            active: this.state.isOpen,
          })}
        >
          {/* <button onClick={this.handleClick}>
            {
              this.state.isOpen ?
                <span className="cpc icon-round-delete-button" /> :
                <span className="cpc icon-rounded-add-button" />
            }
          </button> */}
          <label className="pt-control pt-checkbox">
            <input type="checkbox" checked={this.getChecked()} onChange={this.handleCheckbox} />
            {/* <span className="pt-control-indicator" /> */}
          </label>
          <div onClick={this.handleClick} className="user-info-content">
            <Text className="cpc-unit-info" title={this.props.department.tenPhongBan}>
              {this.props.department.tenPhongBan}
              <span className="cpc-item-number">
                ({this.getUserOnline().length}/{this.props.department.totalNhanVien})
              </span>
            </Text>
          </div>
        </div>
        {this.state.isOpen && (
          <Collapse className="cpc-department-contact-list" isOpen={this.state.isOpen}>
            {users.map(
              (user, i) =>
                user && (
                  <ContactItem
                    key={user.nhanVienId}
                    user={user}
                    auth={this.props.auth}
                    receivers={this.props.receivers}
                    actions={this.props.actions}
                  />
                )
            )}
            {this.props.parentIds.indexOf(this.props.department.phongBanId) >= 0 ? null : (
              <Department
                auth={this.props.auth}
                parentIds={[...this.props.parentIds, this.props.department.phongBanId]}
                actions={this.props.actions}
                unit={this.props.unit}
                filterOnline={this.props.filterOnline}
                receivers={this.props.receivers}
                parentId={this.props.department.phongBanId}
              />
            )}
          </Collapse>
        )}
      </div>
    );
  }
}
