import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import classNames from 'classnames';
import './index.scss';

function CPCCheckbox(props) {
  const { className, children, size, disabled = false, theme, ...rest } = props;
  return (
    <Checkbox
      className={classNames('cpc-checkbox', {
        [`${className}`]: className,
        [`size-${size}`]: size,
        [`theme-${theme}`]: theme,
      })}
      disabled={disabled}
      {...rest}
    />
  );
}

export default CPCCheckbox;
