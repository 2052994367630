import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CPCDialog from '@/components/atoms/dialog';
import { Tab } from '@blueprintjs/core';
import unionBy from 'lodash/unionBy';
import { filter as filterLodash } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';

import ForwardList from '@/components/templates/chat-body/information/share/forward/list';

import {
  contactFetchDepartmentsByUnit,
  contactSearchAll,
  contactSearchByUnit,
  getFullUnits,
  getConversationLatestContactList,
  removeConversationLatestContactList,
  commonAddToasterMessage,
  createAddTopChat,
  surveySend,
  createGroup,
  updateIsScroll,
  getSearchConversationList,
  chatCreateConversation,
  getListSurveys,
  getConversationAllUsers,
} from '@/actions';
import CPCTab from '@/components/atoms/tab';
import CPCIcon from '@/components/atoms/icon';
import useShare from './use-share';
import Organization from '@/components/organization';
import CPCFlex from '@/components/atoms/flex';
import CPCTooltip from '@/components/atoms/tooltip';
import CPCInputV2 from '@/components/atoms/input/v2';
import CPCButtonV2 from '@/components/atoms/button-v2';
import CPCTextArea from '@/components/atoms/textarea';
import { Select } from '@/components/common';
import ListChoose from './list-choose';
import ConversationList from './conversation-list';
import CPCFileThumbnail from '@/components/molecules/file-thumbnail';
import CPCEditor from '@/components/molecules/editor';
import { arrayToTree } from '@/helpers/helper';
import Survey from '@/components/molecules/messages/survey';
import './index.scss';
import { isEmpty } from 'lodash';

const Share = ({
  title = 'Chia sẻ',
  isOpen = true,
  isScroll,
  actions,
  auth,
  hoiThoaiId,
  dataFiles = [],
  dataUploadFiles = [],
  dataUnit,
  dataUnits,
  conversationLatestContact,
  allUsersConversation,
  signalRHub,
  limitMember,
  isEditorDisplay = false,
  actionProps = { type: 'SHARE', data: null },
  history,
  isEnter,
  onReset = () => {},
  onClose = () => {},
  searchParamKhaoSat,
}) => {
  const [fileList, setFileList] = useState([]);

  const handleChangeFiles = (files) => {
    setFileList(files);
  };

  const {
    tab,
    tabActive,
    filter,
    isSearch,
    dataDisable,
    dataChecked,
    dataSearch,
    dataSearchConversation,
    loading,
    isLoadingTree,
    isLoadingPagination,
    isLoading,
    isSubmit,
    dateError,
    dataForm,
    files,
    forwardMessage,
    isForwardDisplay,
    setDataChecked,
    setIsForwardDisplay,
    onRemoveForward,
    setFilter,
    setIsSearch,
    onScroll,
    onChangeSearch,
    onChangeTab,
    onSelectUnit,
    onUnChecked,
    onChangePermission,
    onChecked,
    onCheckedAll,
    onToggle,
    onClear,
    onConversationScroll,
    onConversationChecked,
    setDataForm,
    onFileRemove,
    onSubmit,
  } = useShare({
    actions,
    auth,
    hoiThoaiId,
    conversationLatestContact,
    allUsersConversation,
    dataFiles,
    actionProps,
    limitMember,
    signalRHub,
    history,
    fileList,
    onReset,
    onClose,
    searchParamKhaoSat,
  });

  const onKeySubmit = (eventString) => {
    if (!eventString) {
      return;
    }

    if ((isEnter && eventString === 'ENTER') || (!isEnter && eventString === 'CTRLENTER')) {
      onSubmit();
    }
  };

  const titleComponent = (keyName = 'MAIN') => {
    if (keyName === 'FORWARD_LIST' && isForwardDisplay) {
      return (
        <CPCFlex align={'center'} justify={'space-between'} className={'mb-10'}>
          <h3 className="text-14-500">Nội dung</h3>
          {forwardMessage?.length !== 0 && actionProps?.type === 'FORWARD' && (
            <span
              className="color-red text-14-400 cursor-pointer"
              onClick={() => setIsForwardDisplay(false)}
            >
              Đóng
            </span>
          )}
        </CPCFlex>
      );
    } else if (keyName === 'MAIN' && !isForwardDisplay) {
      return (
        <CPCFlex align={'center'} justify={'space-between'} className={'mb-10'}>
          <h3 className="text-14-500">Nội dung</h3>
          {forwardMessage?.length !== 0 && actionProps?.type === 'FORWARD' && (
            <span
              className="color-blue text-14-400 cursor-pointer"
              onClick={() => setIsForwardDisplay(!isForwardDisplay)}
            >
              ({forwardMessage?.length}){' '}
              {actionProps?.type === 'FORWARD' ? 'Nội dung chuyển tiếp' : 'Nội dung chia sẻ'}
            </span>
          )}
        </CPCFlex>
      );
    }
  };

  const filteredDataChecked = filterLodash(dataChecked, (item) => item.nhanVienId);
  const nhanVienTemp = filterLodash(dataChecked, (item) => item.nhanVienId);
  const hoiThoaiTemp = filterLodash(dataChecked, (item) => item.hoiThoaiId);
  const dataCommonCheck =
    actionProps.type === 'SURVEY'
      ? [...unionBy(filteredDataChecked, (item) => item.nhanVienId)]
      : [
          ...unionBy(nhanVienTemp, (item) => item.nhanVienId),
          ...unionBy(hoiThoaiTemp, (item) => item.hoiThoaiId),
        ];

  return (
    <CPCDialog className={'max-720'} isOpen={isOpen} title={title} theme={2} onClose={onClose}>
      <div className="ph-15 pv-20">
        <CPCTab selected={tabActive} theme="2" onChange={onChangeTab}>
          <Tab
            disabled={
              tabActive !== 'CONVERSATION' &&
              !isEmpty(dataChecked) &&
              actionProps?.type !== 'SURVEY'
            }
            id={tab.hoiThoai}
            title="Hội thoại"
          />
          <Tab
            disabled={
              tabActive === 'CONVERSATION' &&
              !isEmpty(dataChecked) &&
              actionProps?.type !== 'SURVEY'
            }
            id={tab.donVi}
            title="Đơn vị"
          />
          <Tab
            disabled={
              tabActive === 'CONVERSATION' &&
              !isEmpty(dataChecked) &&
              actionProps?.type !== 'SURVEY'
            }
            id={tab.donViKhac}
            title="Đơn vị khác"
          />
        </CPCTab>
        <CPCFlex align={'center'} gap="10" className="mt-15">
          {tabActive === tab.donViKhac && (
            <div style={{ flexGrow: 0, minWidth: '120px', maxWidth: '300px' }}>
              <Select
                classNameWrapper="select-box__theme1"
                firstName="Chọn đơn vị"
                data={dataUnits?.filter((elm) => elm?.donViId !== auth?.mainUnitId)}
                model={['donViId', 'tenVietTat', 'dsDonViCon']}
                value={filter?.unitId || 'All'}
                tree={true}
                onChange={onSelectUnit}
              />
            </div>
          )}
          <div style={{ flexGrow: 1 }}>
            <CPCInputV2
              asyncControl={true}
              maxLength={100}
              placeholder="Tìm kiếm"
              theme="2"
              fill={true}
              shape="round"
              leftIcon={<CPCIcon icon="search" color="#A9B6C7" />}
              showClose={true}
              value={filter?.textSearch || ''}
              onClose={onClear}
              onChange={(e) => {
                setFilter((prev) => ({ ...prev, textSearch: e?.target?.value }));
                if (!e?.target?.value) {
                  onClear();
                  setIsSearch(false);
                }
              }}
              onKeyDown={(e) => onChangeSearch(e)}
            />
          </div>
        </CPCFlex>
      </div>
      <CPCFlex
        align={'flex-start'}
        className={'ph-10 pv-20 border-top panel-organization'}
        style={{ minHeight: 336 }}
      >
        <div style={{ width: '55%', minWidth: '55%', flexGrow: 0 }}>
          {tabActive === tab.hoiThoai ? (
            <ConversationList
              idActive={hoiThoaiId}
              dataSource={
                isSearch ? dataSearchConversation?.items : conversationLatestContact?.items
              }
              isLoadingTree={isLoadingTree}
              isLoading={isLoadingPagination}
              dataChecked={unionBy(dataChecked?.filter((e) => e?.hoiThoaiId) || [], 'hoiThoaiId')}
              onScroll={onConversationScroll}
              onCheck={onConversationChecked}
            />
          ) : (
            <Scrollbars
              autoHeight
              autoHeightMin={290}
              autoHeightMax={290}
              renderView={({ style, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...style,
                    paddingLeft: '1px',
                    paddingRight: '10px',
                  }}
                />
              )}
              onScrollFrame={isSearch ? onScroll : undefined}
            >
              {tabActive === tab.donVi && (
                <Organization
                  key={1}
                  history={history}
                  hasCheckAll={true}
                  userConfig={{
                    isHiddenAction: true,
                    isHiddenOrganization: isSearch ? false : true,
                    avatarSize: 'sm-4',
                  }}
                  hasCheckbox={true}
                  isLoading={isLoadingTree}
                  loading={loading}
                  dataDisable={dataDisable}
                  dataSource={
                    isSearch
                      ? dataSearch?.items && dataSearch.items.length !== 0
                        ? dataSearch.items
                        : []
                      : [
                          ...arrayToTree(dataUnit),
                          ...dataUnit?.filter((e) => e?.donViCha === auth.mainUnitId),
                        ]
                  }
                  dataChecked={unionBy(
                    dataChecked?.filter((e) => e?.nhanVienId) || [],
                    'nhanVienId'
                  )}
                  dataToggle={[auth?.mainDepartmentId]}
                  onToggle={onToggle}
                  onChecked={onChecked}
                  onCheckedAll={onCheckedAll}
                />
              )}
              {tabActive === tab.donViKhac && (
                <Organization
                  key={2}
                  history={history}
                  userConfig={{
                    isHiddenAction: true,
                    isHiddenOrganization: isSearch ? false : true,
                    avatarSize: 'sm-4',
                  }}
                  hasCheckbox={true}
                  isLoading={isLoadingTree}
                  loading={loading}
                  dataDisable={dataDisable}
                  dataSource={
                    isSearch
                      ? dataSearch?.items && dataSearch.items.length !== 0
                        ? dataSearch.items
                        : []
                      : dataUnits?.filter((e) => e?.donViId !== auth.mainUnitId)
                  }
                  dataChecked={unionBy(
                    dataChecked?.filter((e) => e?.nhanVienId) || [],
                    'nhanVienId'
                  )}
                  dataToggle={[auth?.mainDepartmentId]}
                  onToggle={onToggle}
                  onChecked={onChecked}
                />
              )}
            </Scrollbars>
          )}
        </div>
        {/* Dữ liệu khi chọn trên 1000 người sẽ + thêm 1 là user hiện tại để đúng với UI default 0/1200 */}
        <div className="share-choose" style={{ maxWidth: '45%', flexGrow: 1, minHeight: 287 }}>
          <CPCFlex align={'center'} justify={'space-between'} className={'mb-5'}>
            <div>
              <span className="text-14-500">Đã chọn</span>
              <span
                className={classNames('count', {
                  beyond:
                    (dataCommonCheck?.length > 1000
                      ? dataCommonCheck?.length + 1
                      : dataCommonCheck?.length) > limitMember,
                })}
              >{`${
                (dataCommonCheck?.length > 1000
                  ? dataCommonCheck?.length + 1
                  : dataCommonCheck?.length) || 0
              } / ${limitMember}`}</span>
            </div>
            {dataChecked?.length !== 0 && (
              <div style={{ lineHeight: '100%', paddingRight: '8px' }}>
                <CPCTooltip
                  theme={'white'}
                  content={'Làm trống danh sách'}
                  openOnTargetFocus={false}
                  usePortal={false}
                  position={'left'}
                >
                  <CPCButtonV2
                    icon={<span className="icon-xmark user-choose-remove"></span>}
                    minimal
                    disabled={false}
                    onClick={() => setDataChecked([])}
                  />
                </CPCTooltip>
              </div>
            )}
          </CPCFlex>
          <ListChoose
            actions={actions}
            isScroll={isScroll}
            data={actionProps.type === 'SURVEY' ? dataCommonCheck : dataChecked}
            actionProps={actionProps}
            onChange={onChangePermission}
            onRemove={onUnChecked}
          />
          {dateError?.['error-check'] ? (
            <p className="pt-10 text-error">{dateError?.['error-check']}</p>
          ) : (
            ''
          )}
        </div>
        {forwardMessage?.length !== 0 && actionProps?.type === 'FORWARD' && (
          <div
            className={classNames('panel-forward', {
              display: isForwardDisplay,
            })}
          >
            <div className="panel-forward__over" style={{ minHeight: 300 }}>
              {titleComponent('FORWARD_LIST')}
              <ForwardList
                autoHeightMax={250}
                auth={auth}
                dataSource={forwardMessage}
                onRemove={onRemoveForward}
              />
            </div>
          </div>
        )}
      </CPCFlex>
      <div className="border-top pt-15 pv-20">
        {titleComponent('MAIN')}
        <div className="share-editor">
          {(files?.length !== 0 ||
            (actionProps?.data?.phieuKhaoSat && actionProps?.data?.phieuKhaoSat?.length !== 0)) && (
            <div className="current-content">
              <CPCFlex align={'center'} gap={10} className={'files'}>
                {files?.map((file, index) => (
                  <CPCFileThumbnail
                    key={index}
                    theme={1}
                    data={{
                      ...file,
                      id: file?.fileId,
                      name: file?.tenFile,
                      extension: file?.kieuFile,
                      size: file?.kichThuoc,
                    }}
                    auth={auth}
                    hasRemove={files?.length >= 2}
                    onRemove={onFileRemove}
                  />
                ))}
              </CPCFlex>
              {actionProps?.data?.phieuKhaoSat &&
                actionProps?.data?.phieuKhaoSat?.length !== 0 &&
                actionProps?.data?.phieuKhaoSat?.map((survey, index) => (
                  <Survey key={index} history={history} dataSource={survey} />
                ))}
            </div>
          )}
          {isEditorDisplay ? (
            <div>
              <CPCEditor
                className={actionProps.type === 'SURVEY' ? 'editor-survey' : ''}
                isHiddenUpload={actionProps.type === 'SURVEY'}
                emojiProps={{ portalClassName: 'emoji-in-popup' }}
                content={dataForm?.description}
                actions={actions}
                fileList={dataUploadFiles}
                onChange={(value) =>
                  setDataForm((prev) => ({
                    ...prev,
                    description: value,
                  }))
                }
                auth={auth}
                handleChangeFiles={handleChangeFiles}
                onKeySubmit={onKeySubmit}
              />
            </div>
          ) : (
            <div>
              <CPCTextArea
                placeholder={'Thêm mô tả...'}
                rows={2}
                theme={1}
                onChange={(e) =>
                  setDataForm((prev) => ({
                    ...prev,
                    description: e?.target?.value,
                  }))
                }
              />
            </div>
          )}
        </div>
        {dateError?.['error-content-check'] ? (
          <p className="m-0 pt-10 text-error">{dateError?.['error-content-check']}</p>
        ) : (
          ''
        )}
      </div>
      <CPCFlex align={'center'} justify={'flex-end'} gap={20} className={'ph-15 pv-20'}>
        <CPCButtonV2
          text={'Hủy'}
          classNameText={'text-14-600'}
          color={'light-gray'}
          size={'sm'}
          min={'90'}
          shape={'round-sm'}
          disabled={isLoading || false}
          onClick={onClose}
        />
        <CPCButtonV2
          text={'Gửi'}
          classNameText={'text-14-600'}
          color={'blue'}
          size={'sm'}
          min={'90'}
          shape={'round-sm'}
          disabled={!isSubmit || isLoading}
          loading={isLoading}
          onClick={onSubmit}
        />
      </CPCFlex>
    </CPCDialog>
  );
};

const mapStateToProps = (state) => {
  const auth = {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  };
  const contact = {
    ...state.contact,
    viewUnit: state.entities.units[state.contact.viewUnit]
      ? {
          ...state.entities.units[state.contact.viewUnit],
          dsPhongBan: state.entities.units[state.contact.viewUnit].dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
        }
      : {},
    units: (() => {
      let { units } = state.contact;
      if (
        state.entities.units[state.auth.mainUnitId] &&
        units.indexOf(state.auth.mainUnitId) === -1
      ) {
        units.push(state.auth.mainUnitId);
      }

      return units.map((id) => {
        const unit = state.entities.units[id];
        if (!unit.dsPhongBan) {
          unit.dsPhongBan = [];
        }

        const getUnit = (data) => {
          if (!data || !_.isArray(data.dsDonViCon)) {
            return [];
          }

          return data.dsDonViCon.map((id) => {
            const unit = state.entities.units[id];
            return {
              ...unit,
              dsDonViCon: getUnit(unit),
              dsPhongBan: unit.dsPhongBan
                ? unit.dsPhongBan.map((id) => {
                    const department = state.entities.departments[id];
                    return {
                      ...department,
                      dsNhanVien: department.dsNhanVien
                        .map((id) => state.entities.users[id])
                        .filter((user) => {
                          if (state.contact.filterOnline) {
                            return user.trucTuyen === 1;
                          }

                          return true;
                        }),
                    };
                  })
                : [],
            };
          });
        };

        return {
          ...unit,
          dsPhongBan: unit.dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
          dsDonViCon: getUnit(unit),
        };
      });
    })(),
  };
  return {
    auth,
    dataUnit: [
      ...(contact.units
        .find((unit) => unit.donViId === auth.mainUnitId)
        ?.dsPhongBan?.map((e) => ({
          id: e?.phongBanId,
          name: e?.tenPhongBan,
          parentId: auth.mainUnitId,
          ...e,
        })) ?? []),
      ...(contact.units
        .find((unit) => unit.donViId === auth.mainUnitId)
        ?.dsDonViCon?.map((e) => ({
          id: e?.donViId,
          name: e?.tenVietTat,
          parentId: auth.mainUnitId,
          ...e,
        })) ?? []),
    ],
    dataUnits: contact.units,
    contact,
    hoiThoaiId: state.conversation?.conversationDetail?.hoiThoaiId,
    conversationLatestContact: state.conversation?.conversationLatestContact,
    limitMember: state.configuration?.configurations?.limitMember,
    allUsersConversation: state.chat?.allUsersConversation,
    isScroll: state.conversation?.isScroll,
    isEnter: state?.auth?.config?.phimTatWebSend !== 'true',
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      contactFetchDepartmentsByUnit,
      contactSearchAll,
      contactSearchByUnit,
      getFullUnits,
      getConversationLatestContactList,
      removeConversationLatestContactList,
      commonAddToasterMessage,
      createAddTopChat,
      surveySend,
      createGroup,
      updateIsScroll,
      getSearchConversationList,
      chatCreateConversation,
      getListSurveys,
      getConversationAllUsers,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Share);
