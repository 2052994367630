import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import DepartmentItem from './DepartmentItem';

export default class Department extends Component {
  static propTypes = {
    parentId: PropTypes.string,
    parentIds: PropTypes.array,
    unit: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    filterOnline: PropTypes.bool,
    receivers: PropTypes.array,
  };

  static defaultProps = {
    parentId: null,
    parentIds: [], // detect recursive infinite loop
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  getDepartments = () => {
    if (!this.props.unit.dsPhongBan) {
      return [];
    }

    return this.props.unit.dsPhongBan.filter(
      (department) => department.phongBanCha === this.props.parentId
    );
  };

  getChildren = (id) => {
    return this.props.unit.dsPhongBan.filter((department) => department.phongBanCha === id);
  };

  render() {
    const departments = this.getDepartments().sort((a, b) => {
      if (a.stt < b.stt) {
        return -1;
      }
      if (a.stt > b.stt) {
        return 1;
      }
      return 0;
    });

    return departments.length ? (
      <div className="cpc-department-section">
        {departments.map((department, i) => (
          <DepartmentItem
            key={department.phongBanId}
            auth={this.props.auth}
            department={department}
            children={this.getChildren(department.phongBanId)}
            parentIds={this.props.parentIds}
            actions={this.props.actions}
            unit={this.props.unit}
            receivers={this.props.receivers}
            filterOnline={this.props.filterOnline}
          />
        ))}
      </div>
    ) : null;
  }
}
