import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { InputGroup, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';

import LoadingLoadMore from '@/components/molecules/loading-load-more';
import CPCLoadingWare from '@/components/atoms/loading/ware';
import useDebounce from '@/hooks/use-debounce';
import CPCIcon from '@/components/atoms/icon';
import './index.scss';

const DropdownSelect = ({
  className = '',
  isSearch = false,
  isRemove = false,
  theme,
  inputTheme,
  placeholder = 'Chọn',
  options = [],
  disabled = false,
  inputClassName = '',
  open = false,
  selected,
  popoverHeight = 'unset',
  popoverClassName,
  popoverPosition = Position.BOTTOM_LEFT,
  isMoreLoading = false,
  isLoading = false,
  onChangeSearch = () => {},
  onSearch = () => {},
  onScroll = () => {},
  onChange = () => {},
  onDropdownVisibleChange = () => {},
}) => {
  const inputRef = useRef();

  const [isOpen, setIsOpen] = useState(open);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const [searchText, setSearchText] = useState('');

  const debouncedValue = useDebounce(searchText, 2000);

  const openDropdown = useCallback(() => {
    if (disabled) return;
    if (inputRef.current && !popoverWidth) {
      const { width } = inputRef.current.getBoundingClientRect();
      setPopoverWidth(width);
    }
    setIsOpen((prev) => !prev);
    onDropdownVisibleChange(true);
  }, [onDropdownVisibleChange, inputRef, popoverWidth, disabled]);

  const closeDropdown = useCallback(
    (e) => {
      setSearchText('');
      setIsOpen(false);
      onDropdownVisibleChange(false);
    },
    [onDropdownVisibleChange]
  );

  useEffect(() => {
    if (isSearch) {
      onSearch(debouncedValue);
    }
  }, [isSearch, debouncedValue]);

  return (
    <div
      ref={inputRef}
      className={classNames('cpc-dropdown-select-wrapper', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
      })}
    >
      <Popover2
        minimal={true}
        usePortal={false}
        content={
          <div
            style={{
              minWidth: popoverWidth,
              maxHeight: popoverHeight,
              overflowY: 'auto',
            }}
          >
            {isSearch && (
              <div className="cpc-dropdown-select-search">
                <InputGroup
                  placeholder={'Nhập từ khóa...'}
                  rightElement={
                    searchText ? (
                      <span
                        className="icon-xmark close"
                        onClick={() => {
                          setSearchText('');
                          onChangeSearch('');
                        }}
                      />
                    ) : undefined
                  }
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e?.target?.value);
                    onChangeSearch(e?.target?.value);
                  }}
                />
              </div>
            )}
            <Scrollbars
              autoHeight
              autoHeightMax={250}
              onScrollFrame={isSearch ? onScroll : undefined}
            >
              {isLoading ? (
                <div className="p-10">
                  <CPCLoadingWare ware={3} />
                </div>
              ) : (
                <div>
                  {options?.length !== 0 ? (
                    <div>
                      <ul className="cpc-dropdown-select__list">
                        {options?.map((elm, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setIsOpen(false);
                              onChange(elm);
                            }}
                            className={classNames({
                              selected: elm?.value === selected?.value,
                            })}
                          >
                            {elm?.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div>{searchText ? <div className="not-data">Không có dữ liệu</div> : ''}</div>
                  )}
                </div>
              )}
              {isMoreLoading ? <LoadingLoadMore /> : null}
            </Scrollbars>
          </div>
        }
        isOpen={isOpen}
        onClose={closeDropdown}
        position={popoverPosition}
        popoverClassName={classNames('cpc-dropdown', popoverClassName)}
      >
        <InputGroup
          readOnly={true}
          placeholder={placeholder}
          rightElement={
            <div className="arrow">
              {isRemove && options?.find((e) => e.value === selected?.value)?.label && (
                <CPCIcon
                  icon="xmark"
                  size={9}
                  color="#707070"
                  className={'close-action'}
                  onClick={() => onChange(null)}
                />
              )}
              <CPCIcon
                icon="chevron-down"
                size={9}
                color="#B3B3B3"
                className={classNames({ open: isOpen })}
                onClick={openDropdown}
              />
            </div>
          }
          value={options?.find((e) => e.value === selected?.value)?.label ?? ''}
          className={classNames('cpc-dropdown-select', {
            [inputClassName]: inputClassName,
            [`theme-${inputTheme}`]: inputTheme,
          })}
          onClick={openDropdown}
        />
      </Popover2>
    </div>
  );
};

export default memo(DropdownSelect);
