import { connect } from 'react-redux';

import InfoAccount from '@/components/molecules/info-account';

const PopupAccount = ({ history, user, open, handleClose }) => {
  return <InfoAccount history={history} open={open} data={user} handleClose={handleClose} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.entities.users[state.auth.user],
  };
};

export default connect(mapStateToProps, null)(PopupAccount);
