import { useMemo } from 'react';
import './index.scss';

const CPCLoadingWare = ({ ware = 1, width = '100%' }) => {
  const renderWare = useMemo(() => {
    const arrayWidth = ['100%', '80%', '70%'];
    const func = (a, b) => {
      return 0.5 - Math.random();
    };

    return Array.from(Array(ware).keys())?.map((e, index) => {
      const arraySort = arrayWidth.sort(func);
      return (
        <div key={index} className={'ware'} style={{ width: ware === 1 ? width : arraySort[0] }} />
      );
    });
  }, [ware, width]);

  return <div className="cpc-loading-ware">{renderWare}</div>;
};

export default CPCLoadingWare;
