import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateInput } from '@blueprintjs/datetime';
import classnames from 'classnames';
import { checkQuyenChucVu } from '../../helpers/helper';
import { variables } from '../../constants/variables';
import '../../containers/pages/assets/datatable.scss';
function handleStyle(options = []) {
  let data = {};
  for (var i = 0; i <= options.length - 1; i++) {
    data[options[i]['type']] = options[i]['value'];
  }
  return data;
}

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.state = {
      sort: {},
      isLoading: props.isLoading !== undefined ? props.isLoading : true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data && !prevProps.isLoading) {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.isLoading !== this.props.isLoading) {
      this.setState({
        isLoading: props.isLoading,
      });
    }
  }

  handleClickSort(i) {
    let state = this.state;
    for (var v in state.sort) {
      if (v === i) {
        continue;
      }

      delete state.sort[v];
    }
    state.sort[i] = !state.sort[i] || state.sort[i] === 'ASC' ? 'DESC' : 'ASC';
    this.setState(state, () => {
      let sort;
      for (let sortField in this.state.sort) {
        sort = `${sortField} ${this.state.sort[sortField]}`;
      }
      this.props.onFilter && this.props.onFilter(sort);
    });
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.props.onSubmit && this.props.onSubmit();
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
      </div>
    );
    let imageUrl = process.env.PUBLIC_URL + '/images/search-result-not-found.png';
    if (this.props.isError) {
      imageUrl = process.env.PUBLIC_URL + '/images/Server.png';
    }

    return (
      <div className={'data-table table-scroll'}>
        <form
          className={classnames('auto', {
            'table-min-height': this.props.type === 'filter',
          })}
        >
          <table className={`${this.props.classNames || ''}`}>
            {this.props.headers.length > 0 && !this.props.isError && (
              <thead>
                <tr className="thead">
                  {this.props.headers.map((header, i) => {
                    let itemStyle = handleStyle(header.style);
                    return (
                      header.chucVu &&
                      checkQuyenChucVu(this.props.auth, header.chucVu) && (
                        <th key={i} className={header.classNames || ''} style={itemStyle}>
                          <span className="table-filter-sort-colum">
                            <span className="text-sort">{header.value}</span>
                            {header.sort && (
                              <span
                                className={`icon ${
                                  this.state.sort[header.sort] &&
                                  this.state.sort[header.sort] === 'DESC'
                                    ? 'icon-table-up'
                                    : 'icon-small-down'
                                }`}
                                onClick={this.handleClickSort.bind(this, header.sort)}
                              ></span>
                            )}
                          </span>
                        </th>
                      )
                    );
                  })}
                </tr>
                {this.props.type === 'filter' && (
                  <tr className="tfilter">
                    {this.props.headers.map((header, i) => {
                      let itemStyle = handleStyle(header.style);
                      if (header.typeGridView) {
                        if (header.typeGridView === 'input') {
                          return (
                            header.chucVu &&
                            checkQuyenChucVu(this.props.auth, header.chucVu) && (
                              <th
                                key={i}
                                className={`${header.classNames || ''}`}
                                style={itemStyle}
                              >
                                <div className="filter-column-control">
                                  <input
                                    name={header.nameGridView}
                                    maxLength={255}
                                    placeholder="Nhập"
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        this.onSubmitForm(e);
                                      }
                                    }}
                                    onChange={this.props.onChange}
                                    value={
                                      (this.props.filterColumn &&
                                        this.props.filterColumn[header.nameGridView]) ||
                                      ''
                                    }
                                  />
                                  {this.props.filterColumn &&
                                    this.props.filterColumn[header.nameGridView] && (
                                      <i
                                        className="icon-close-cicle-line"
                                        onClick={() => {
                                          this.props.onRemoveText([header.nameGridView]);
                                        }}
                                      ></i>
                                    )}
                                </div>
                              </th>
                            )
                          );
                        }
                        if (header.typeGridView === 'date') {
                          return (
                            header.chucVu &&
                            checkQuyenChucVu(this.props.auth, header.chucVu) && (
                              <th
                                key={i}
                                className={`${header.classNames || ''}`}
                                style={itemStyle}
                              >
                                <div className="filter-column-control filter-column-control-date">
                                  <DateInput
                                    formatDate={(date) => date.toLocaleString()}
                                    value={
                                      (this.props.filterColumn &&
                                        this.props.filterColumn[header.nameGridView]) ||
                                      null
                                    }
                                    format="DD/MM/YYYY"
                                    onChange={this.props.onChangeDate.bind(
                                      this,
                                      header.nameGridView
                                    )}
                                    highlightCurrentDay={true}
                                    closeOnSelection={true}
                                  />
                                </div>
                              </th>
                            )
                          );
                        }
                        if (header.typeGridView === 'select') {
                          return (
                            header.chucVu &&
                            checkQuyenChucVu(this.props.auth, header.chucVu) && (
                              <th
                                key={i}
                                className={`${header.classNames || ''}`}
                                style={{ ...itemStyle, position: 'relative' }}
                              >
                                <div className="filter-column-control">
                                  <select
                                    onChange={this.props.onChange}
                                    name={header.nameGridView}
                                    value={
                                      (this.props.filterColumn &&
                                        this.props.filterColumn[header.nameGridView]) ||
                                      ''
                                    }
                                  >
                                    {header.dataSelect &&
                                      header.dataSelect.map((item, i) => (
                                        <option key={i} value={item.value}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </th>
                            )
                          );
                        }
                      }
                      return (
                        header.chucVu &&
                        checkQuyenChucVu(this.props.auth, header.chucVu) && (
                          <th key={i} className={header.classNames || ''} style={itemStyle} />
                        )
                      );
                    })}
                  </tr>
                )}
              </thead>
            )}
            {this.state.isLoading ? (
              <tbody>
                <tr className="table-loading">
                  <td className="colum-loading" colSpan={this.props.headers.length}>
                    {ItemLoading}
                    {ItemLoading}
                    {ItemLoading}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {!this.props.isEmpty &&
                  !this.props.isError &&
                  this.props.data &&
                  this.props.data.length > 0 &&
                  this.props.data.map((item, i) => {
                    return (
                      <this.props.itemShape
                        type={this.props.type}
                        item={item}
                        number={(this.props.page - 1) * 10 + i + 1}
                        key={i}
                        onHandleSubmitRemoveItem={this.props.onHandleSubmitRemoveItem}
                        match={this.props.match}
                        checked={false}
                        onClickDetail={this.props.onClickDetail}
                        auth={this.props.auth}
                        history={this.props.history}
                        actions={this.props.actions}
                        filter={this.props.filter}
                        actionPermissions={this.props.actionPermissions}
                      />
                    );
                  })}
                {this.props.isEmpty === true ||
                this.props.isError === true ||
                (this.props.data && this.props.data.length === 0) ? (
                  <tr className="no-border-bt">
                    <td colSpan={this.props.headers.length} className="error-table-hover">
                      <div className="error-page-container">
                        <div className="error-content">
                          <img
                            src={imageUrl}
                            alt="search-result-not-found"
                            className="img-thumb-error"
                          />
                          <p className="description-note">
                            {this.props.isError
                              ? variables.MESSAGE_TABLE.MESSAGE_ERROR
                              : variables.MESSAGE_TABLE.MESSAGE_NOT_FOUND}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            )}
          </table>
        </form>
      </div>
    );
  }
}

DataTable.defaultProps = {
  classNames: '',
  type: '',
  isEmpty: false,
  isError: false,
  isLoading: false,
};

DataTable.propTypes = {
  classNames: PropTypes.string,
  headers: PropTypes.array.isRequired,
  itemShape: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  match: PropTypes.object,
  type: PropTypes.any,
  onCheckAll: PropTypes.func,
  onClickDetail: PropTypes.func,
  isEmpty: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  auth: PropTypes.object,
  history: PropTypes.object,
};

export default DataTable;
