import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchItem from './SearchItem';
import SearchUserSuggestion from './SearchUserSuggestion';
import SelectTree from './SelectTree';
import { Dialog, Button, Intent, Position, Toaster } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/search.scss';
import { connect } from 'react-redux';
import { camelizeKeys } from 'humps';
import { InputDate } from '../../components/common';

const getPreviousMonth = (currentDate) => {
  currentDate.setMonth(currentDate.getMonth() - 3);
  return currentDate;
};

const FROM = 'FROM';
const TO = 'TO';
const FILTER_1VS1_TRUE = 2;
const FILTER_1VS1_FALSE = 0;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      search: '',
      unitId: this.props.auth.mainUnitId,
      isOpenFilter: true,
      filter1Vs1: false,
      filterDateStart: getPreviousMonth(new Date()),
      filterDateEnd: new Date(),
      filterUserFrom: null,
      filterUserTo: null,
      fileDinhKem: false,
      guiKemEmail: false,
      guiKemSMS: false,
      filterTnQuanTrong: false,
      limit: 10,
      isOpenRemoveMessage: false,
    };
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleCloseDialogRemoveMessage = this.handleCloseDialogRemoveMessage.bind(this);
    this.handleRemoveMessage = this.handleRemoveMessage.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);
    this.handleTogleFilter = this.handleTogleFilter.bind(this);
  }

  refHandlers = {
    toaster: (ref) => (this.toaster = ref),
  };

  componentDidMount() {
    // this.endInput.addEventListener('keydown', (e) => {
    //   e.preventDefault()
    // })
    // this.startInput.addEventListener('keydown', (e) => {
    //   e.preventDefault()
    // })
    this.searchInput.addEventListener('keypress', (e) => {
      if (e.keyCode === 13) {
        this.handleSubmitSearch();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const hasMore = this.props.searchMessages.items.length < this.props.searchMessages.total;
    if (
      prevProps.searchMessages.items.length !== this.props.searchMessages.items.length &&
      this.props.searchMessages.items.length === 0 &&
      hasMore
    ) {
      this.search(1);
    }

    if (
      this.props.searchMessages &&
      this.props.searchMessages.page === 1 &&
      hasMore &&
      this.searchResult.offsetHeight < this.searchResultContainer.getClientHeight()
    ) {
      this.search(2);
    }

    if (
      prevState.search !== this.state.search &&
      this.state.search === '' &&
      this.props.searchMessages.total >= 0
    ) {
      this.props.actions.chatClearSearch();
    }
  }

  handleSubmitSearch = (e) => {
    e && e.preventDefault();
    this.props.actions.chatClearSearch();
    this.search(1).then(() => {
      this.setState({
        isOpenFilter: false,
      });
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'toOther') {
      this.props.actions.chatClearSuggestSearch();
    }

    this.setState({
      [name]: value,
    });
  };

  handleChangeDateRange = (selectedRange) => {
    this.setState({
      filterDateStart: selectedRange[0],
      filterDateEnd: selectedRange[1],
    });
  };

  handleChangeDate = (name, e) => {
    this.setState({
      [name]: e,
    });
  };

  handleClearSearch = () => {
    this.props.actions.chatClearSearch();
    this.setState({ search: '' });
  };

  handleToggleFilter = () => {
    this.setState({ isOpenFilter: !this.state.isOpenFilter });
  };

  handleSelectUser = (user) => {
    this.props.actions.chatClearSuggestSearch();
    this.setState({
      userSelected: user,
    });
  };

  handleScroll = (values) => {
    if (this.props.searchMessages.items.length >= this.props.searchMessages.total) {
      return;
    }

    if (values.top >= 0.98) {
      this.search(this.props.searchMessages.page + 1);
    }
  };

  search = async (page = 1) => {
    if (this.state.loading) {
      return;
    }

    const {
      search,
      userSelected,
      filter1Vs1,
      filterDateStart,
      filterDateEnd,
      fileDinhKem,
      guiKemEmail,
      guiKemSMS,
      filterTnQuanTrong,
      limit,
      fromTo,
    } = this.state;
    let filterUserFrom = '';
    let filterUserTo = '';
    switch (fromTo) {
      case FROM:
        filterUserFrom = userSelected;
        filterUserTo = null;
        break;
      case TO:
        filterUserFrom = null;
        filterUserTo = userSelected;
        break;
      default:
        filterUserFrom = userSelected;
        filterUserTo = userSelected;
    }

    await this.setState({ loading: true });
    await this.props.actions.chatSearchMessages(
      search,
      filterUserFrom ? filterUserFrom.nhanVienId : '',
      filterUserTo ? filterUserTo.nhanVienId : '',
      filterDateStart ? filterDateStart.toISOString() : '',
      filterDateEnd ? filterDateEnd.toISOString() : '',
      filter1Vs1 === true ? FILTER_1VS1_TRUE : FILTER_1VS1_FALSE,
      fileDinhKem,
      guiKemEmail,
      guiKemSMS,
      filterTnQuanTrong,
      page,
      limit
    );
    await this.setState({ loading: false });
  };

  handleRemoveMessage = (message) => {
    this.setState({
      messageRemove: message,
      isOpenRemoveMessage: true,
    });
  };
  handleCloseDialogRemoveMessage = () => {
    this.setState({
      error: '',
      isOpenRemoveMessage: false,
    });
  };
  handleSubmitRemoveMessage = (e) => {
    e.preventDefault();
    if (!this.state.messageRemove) {
      return;
    }

    this.props.actions.chatRemoveMessage(this.state.messageRemove.tinNhanId).then((res) => {
      if (res.error) {
        this.toaster.show({ message: 'Có lỗi xảy ra, vui lòng thử lại', intent: Intent.DANGER });
        return;
      }

      this.toaster.show({ message: 'Xóa tin nhắn thành công', intent: Intent.SUCCESS });
      this.setState({
        isOpenRemoveMessage: false,
        messageRemove: null,
      });
    });
  };

  handleTogleFilter(e) {
    this.setState({
      isOpenFilter: !this.state.isOpenFilter,
    });
  }

  handleChangeSelect = (e) => {
    if (e !== undefined) {
      this.setState({
        unitId: e,
      });
    }
  };

  getFordwardMessage = (str) => {
    if (str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[1];
    } else {
      return '';
    }
  };

  render() {
    return (
      <div className="cpc-search-messages-section">
        <a className="btn-close-search" onClick={this.props.onClose}>
          <span className="pt-icon pt-icon-cross" />
        </a>
        <div className="cpc-search-togle">
          <div className="cpc-form-group cpc-user-search" onClick={this.handleTogleFilter}>
            <p className="p-reset">
              Bộ lọc{' '}
              <span
                className={classnames('pt-icon', {
                  'pt-icon-chevron-down': !this.state.isOpenFilter,
                  'pt-icon-chevron-up': this.state.isOpenFilter,
                })}
              ></span>
            </p>
          </div>
        </div>
        <div
          className="search-form-container"
          style={{ display: this.state.isOpenFilter ? 'block' : 'none' }}
        >
          <div className="cpc-search-form">
            <div className="cpc-search-header">
              <div className="cpc-form-group cpc-user-search">
                <label className="label-content">Nhân viên</label>
                <div className="form-group-form-inline">
                  <div className="pt-select select-w1 pt-inline">
                    <select
                      className="select-custom pt-fill"
                      name="fromTo"
                      onChange={this.handleChange}
                      value={this.state.fromTo}
                    >
                      <option value="">Tất cả</option>
                      <option value={FROM}>From</option>
                      <option value={TO}>To</option>
                    </select>
                  </div>
                  <div className="pt-select select-w2 pt-inline">
                    <SelectTree
                      data={this.props.units}
                      handleChange={this.handleChangeSelect}
                      value={this.state.unitId}
                    />
                  </div>
                </div>
              </div>
              <div className="cpc-form-group cpc-input-search d-flex">
                <div className="input-search flex-auto">
                  <SearchUserSuggestion
                    auth={this.props.auth}
                    other={this.state.unitId}
                    actions={this.props.actions}
                    suggestUsers={this.props.suggestUsers}
                    onChange={this.handleSelectUser}
                  />
                </div>
                <div className="item form-border-last-item">
                  <label className="pt-control pt-checkbox mb0">
                    <input
                      name="filter1Vs1"
                      type="checkbox"
                      className="checkbox"
                      onChange={this.handleChange}
                      checked={this.state.filter1Vs1}
                    />
                    <span className="pt-control-indicator"></span>
                    <span className="text-content">Tin nhắn 1 vs 1</span>
                  </label>
                </div>
              </div>
              <div className="cpc-form-group cpc-input-search">
                <label className="label-content">Tìm kiếm</label>
                <input
                  ref={(el) => (this.searchInput = el)}
                  className="pt-fill"
                  type="text"
                  placeholder="Nhập nội dung"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                {this.state.search.trim() !== '' ? (
                  <button
                    className="btn-clear-search"
                    type="button"
                    onClick={this.handleClearSearch}
                  >
                    <span className="icon-close" />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="cpc-search-filter">
              <div className="cpc-form-group cpc-block-more cpc-datetime-group">
                <label className="label-content">Thời gian</label>
                <div className="date-range-common">
                  <div className="col">
                    <InputDate
                      name="filterDateStart"
                      placeholder={'Từ ngày'}
                      defaultValue={this.state.filterDateStart || undefined}
                      maxDate={this.state.filterDateEnd || undefined}
                      onChange={this.handleChangeDate}
                    />
                  </div>
                  <div className="col">
                    <InputDate
                      name="filterDateEnd"
                      placeholder={'Đến ngày'}
                      defaultValue={this.state.filterDateEnd || undefined}
                      minDate={this.state.filterDateStart || undefined}
                      onChange={this.handleChangeDate}
                    />
                  </div>
                </div>
                <ul className="cpc-form-group action-select-list">
                  <li className="item">
                    <label className="pt-control pt-checkbox">
                      <input
                        name="fileDinhKem"
                        type="checkbox"
                        className="checkbox"
                        onChange={this.handleChange}
                        checked={this.state.fileDinhKem}
                      />
                      <span className="pt-control-indicator"></span>
                      <span className="text-content">Đính kèm file</span>
                    </label>
                  </li>
                  <li className="item">
                    <label className="pt-control pt-checkbox">
                      <input
                        name="guiKemSMS"
                        type="checkbox"
                        className="checkbox"
                        onChange={this.handleChange}
                        checked={this.state.guiKemSMS}
                      />
                      <span className="pt-control-indicator"></span>
                      <span className="text-content">SMS</span>
                    </label>
                  </li>
                  <li className="item">
                    <label className="pt-control pt-checkbox">
                      <input
                        name="guiKemEmail"
                        type="checkbox"
                        className="checkbox"
                        onChange={this.handleChange}
                        checked={this.state.guiKemEmail}
                      />
                      <span className="pt-control-indicator"></span>
                      <span className="text-content">Email</span>
                    </label>
                  </li>
                  <li className="item">
                    <label className="pt-control pt-checkbox">
                      <input
                        name="filterTnQuanTrong"
                        type="checkbox"
                        className="checkbox"
                        onChange={this.handleChange}
                        checked={this.state.filterTnQuanTrong}
                      />
                      <span className="pt-control-indicator"></span>
                      <span className="text-content">Tin nhắn quan trọng</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cpc-submit-group">
              <button
                className="btn-green btn-submit-form"
                onClick={this.handleSubmitSearch}
                disabled={this.state.loading}
              >
                <span className="icon-search" />
                <span className="text-content">Tìm kiếm</span>
              </button>
            </div>
          </div>
        </div>
        <div className="cpc-search-results" style={this.styleResults()}>
          <Scrollbars
            ref={(el) => (this.searchResultContainer = el)}
            style={{ width: '100%', height: '100%', bottom: '0px', top: '0px' }}
            onScrollFrame={this.handleScroll}
            renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
          >
            <div className="cpc-search-results-section" ref={(el) => (this.searchResult = el)}>
              {this.props.searchMessages.total === 0 ? (
                <div className="cpc-not-found">Không tìm thấy kết quả</div>
              ) : (
                ''
              )}
              {this.props.searchMessages.items.map((message, i) => {
                let messageForward = this.getFordwardMessage(message.noiDung)
                  ? JSON.parse(this.getFordwardMessage(message.noiDung))
                  : null;
                messageForward = camelizeKeys(messageForward);
                return (
                  <SearchItem
                    key={i}
                    message={message}
                    forwardMessage={messageForward}
                    actions={this.props.actions}
                    auth={this.props.auth}
                    receivers={this.props.receivers}
                    onCopyAndForward={this.props.onCopyAndForward}
                    onForwardMessage={this.props.onForwardMessage}
                    onSaveDocument={this.props.onSaveDocument}
                    onRemoveMessage={this.handleRemoveMessage}
                    importantMessage={
                      this.props.importantMessageList &&
                      this.props.importantMessageList[message.tinNhanId] !== undefined
                        ? this.props.importantMessageList[message.tinNhanId]
                        : undefined
                    }
                  />
                );
              })}
              {(this.state.loading ||
                this.props.searchMessages.items.length < this.props.searchMessages.total) && (
                <div className="spinner">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
        <Dialog
          className="cpc-delete-message-dialog"
          isOpen={this.state.isOpenRemoveMessage}
          onClose={this.handleCloseDialogRemoveMessage}
          title="Xóa tin nhắn"
        >
          <form onSubmit={this.handleSubmitRemoveMessage}>
            <div className="pt-dialog-body">
              <div className="pt-form-group">Bạn muốn xóa tin nhắn?</div>
              {this.state.error && (
                <div className="pt-input-helper-text error">{this.state.error}</div>
              )}
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                <Button
                  className="pt-large"
                  rightIconName="cross"
                  text="Đóng"
                  onClick={this.handleCloseDialogRemoveMessage}
                />
                <Button
                  className="pt-large"
                  rightIconName="trash"
                  type="submit"
                  intent={Intent.PRIMARY}
                  text="Xóa"
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }

  styleResults = () => {
    return {
      height: `calc(100% - 42px - ${this.state.isOpenFilter ? 398 : 0}px)`,
      position: 'relative',
    };
  };
}

Search.defaultProps = {
  onForwardMessage: () => {},
  onClose: () => {},
  onSaveDocument: () => {},
  onCopyAndForward: () => {},
};

Search.propTypes = {
  units: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  receivers: PropTypes.array.isRequired,
  suggestUsers: PropTypes.object.isRequired,
  searchMessages: PropTypes.object.isRequired,
  onForwardMessage: PropTypes.func,
  onSaveDocument: PropTypes.func,
  onClose: PropTypes.func,
  onCopyAndForward: PropTypes.func,
};

const mapStateToProps = (state) => ({
  importantMessageList: state.chat.importantMessageList,
});

export default connect(mapStateToProps)(Search);
