import React, { Component } from 'react';
import { Header } from '../../components/layout';
// import { GroupRadio } from '../../components/survey';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../actions';
// import { Checkbox, RadioGroup, Radio, Button } from "@blueprintjs/core";
// import { DateInput } from "@blueprintjs/datetime";
import './assets/AddSurvey.scss';
import classnames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

class SurveyViewItemPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      title: '',
      isEnabled: false,
      mealType: 'one',
      buttonbackFixTop: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    //fixed button back
    let scrollTop = window.pageYOffset;
    if (scrollTop > 70) {
      this.setState({
        buttonbackFixTop: true,
      });
    } else {
      this.setState({
        buttonbackFixTop: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Header
          actions={this.props.actions}
          auth={this.props.auth}
          onClickSearch={this.toggleSearchMessage}
          onOpenEoffice={this.handleOpenEoffice}
          onOpenConfig={this.handleToggleConfig}
        />
        <div className="container-fluid wrapper-survey">
          <button
            type="button"
            className={classnames('pt-button cpc-button-back', {
              'button-back-fixed': this.state.buttonbackFixTop,
            })}
          >
            <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
            <span className="button-content">Quay lại Chat</span>
          </button>
          <div className="cpc-survey-container">
            <div className="survey-container item-survey">
              <Scrollbars className="wrapper-survey-form">
                <section className="form-group">
                  <h2 className="title-form-survey">This is title</h2>
                  <p className="survey-desc"> this is description</p>
                </section>
                <div className="border-line" />
                <div className="list-question form-group">
                  <section className="question-item">
                    <h3 className="title-question">This is title question1</h3>
                    <div className="list-answer">
                      <div className="item-checked">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                    </div>
                  </section>
                  <section className="question-item">
                    <h3 className="title-question">This is title question2</h3>
                    <div className="list-answer">
                      <div className="item-checked">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                    </div>
                  </section>
                  <section className="question-item">
                    <h3 className="title-question">This is title question1</h3>
                    <div className="list-answer">
                      <div className="item-checked">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                    </div>
                  </section>
                  <section className="question-item">
                    <h3 className="title-question">This is title question2</h3>
                    <div className="list-answer">
                      <div className="item-checked">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                    </div>
                  </section>
                  <section className="question-item">
                    <h3 className="title-question">This is title question1</h3>
                    <div className="list-answer">
                      <div className="item-checked">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-check-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                    </div>
                  </section>
                  <section className="question-item">
                    <h3 className="title-question">This is title question2</h3>
                    <div className="list-answer">
                      <div className="item-checked">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                      <div className="item-checked item-disable">
                        <span className="wrap-input-custom">
                          <span className="icon-riadio-custom"></span>
                          <span className="input-text-custom">text</span>
                        </span>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="border-line" />
                <div className="footer-action-block">
                  <div className="row">
                    <div className="col-xs-9 box-left box-left-custom">
                      <div className="input-datepicker">
                        <p className="label-datepicker">Người tạo: </p>
                      </div>
                      <div className="user-info">
                        <span className="avatar">
                          <img
                            src="http://api-chat-eoffice-dev.greenglobal.vn:9013/Content/images/avatar/hoand1.jpeg"
                            alt="{item.tenNhanVien}"
                          />
                        </span>
                        <div className="user-info">
                          <h4 className="username">{'item.tenNhanVien'}</h4>
                          <p className="intro">CNTT - EVNCPC</p>
                        </div>
                      </div>
                      <div className="input-datepicker">
                        <span className="label-datepicker">
                          Ngày hết hạn: <span className="blue-content">24/12/2017</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyViewItemPage);
