import * as types from '../constants/ActionTypes';

const initialState = {
  categories: [],
};

export default function cabinet(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL:
      return initialState;

    case types.DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data.result.result.items,
      };

    case types.DOCUMENTS_CABINET_PERSONAL_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data.result.result.items,
      };

    case types.DOCUMENTS_CABINET_UNIT_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data.result.result.items,
      };

    default:
      return state;
  }
}
