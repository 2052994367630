import FineUploaderTraditional from 'fine-uploader-wrappers';
import { UPLOAD_API_URL } from '../constants/Api';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const acceptMIMETypes = [
  '.xls',
  '.xlsx',
  '.doc',
  '.docx',
  '.rar',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/pdf',
  'application/x-pdf',
  'application/acrobat',
  'applications/vnd.pdf',
  'text/pdf',
  'text/x-pdf',
  'application/x-rar-compressed',
  'application/zip',
  'image/jpeg',
  'image/jpg',
  'application/jpg',
  'application/x-jpg',
  'image/pjpeg',
  'image/pipeg',
  'image/vnd.swiftview-jpeg',
  'image/x-xbitmap',
  'image/gif',
  'image/bmp',
  'image/x-windows-bmp',
  'image/png',
  'application/mspowerpoint',
  'application/powerpoint',
  'application/vnd.ms-powerpoint',
  'application/x-mspowerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'audio/3gpp',
  'video/3gpp',
  'video/mp4',
  'video/x-flv',
  'image/svg+xml',
  'application/visio',
  'application/x-visio',
  'application/vnd.visio',
  'application/visio.drawing',
  'application/vsd',
  'application/x-vsd',
  'image/x-vsd',
  'zz-application/zz-winassoc-vsd',
  'application/vnd.ms-project',
  'application/msproj',
  'application/msproject',
  'application/x-msproject',
  'application/x-ms-project',
  'application/x-dos_ms_project',
  'application/mpp',
  'zz-application/zz-winassoc-mpp',
  'application/rtf',
  'application/x-rtf',
  'text/rtf',
  'text/richtext',
  'application/msword',
  'application/doc',
  'application/x-soffice',
  'text/plain',
  'application/txt',
  'browser/internal',
  'text/anytext',
  'widetext/plain',
  'widetext/paragraph',
  'application/tar',
  'application/x-tar',
  'applicaton/x-gtar',
  'multipart/x-tar',
  'application/x-compress',
  'application/x-compressed',
  'audio/mpeg',
  'audio/x-mpeg',
  'audio/mp3',
  'audio/x-mp3',
  'audio/mpeg3',
  'audio/x-mpeg3',
  'audio/mpg',
  'audio/x-mpg',
  'audio/x-mpegaudio',
  'audio/wav',
  'audio/x-wav',
  'audio/wave',
  'audio/x-pn-wav',
].join(',');

function getToken() {
  const auth = cookies.get('authState') ? cookies.get('authState') : {};
  return auth.token ? `${auth.token.tokenType} ${auth.token.accessToken}` : null;
}

export const uploader = () => {
  return new FineUploaderTraditional({
    options: {
      request: {
        endpoint: `${UPLOAD_API_URL}/api/file/chat`,
        customHeaders: {
          Authorization: getToken(),
          'api-version': '1.0',
        },
      },
    },
  });
};

export const fileFromUrl = (url) => {
  return new File(`${UPLOAD_API_URL}${url}`);
};
