import { Button, ContextMenu, Dialog, Intent, Position, Toaster, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';
import emojione from 'emojione';
import { get, isEmpty, isEqual, size, trim } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import FileDownload from 'react-file-download';
import { FormattedTime } from 'react-intl';

import { getContent } from '@/helpers/chat';
import { REACT_EMOTICON, TINNHAN_SMS_365 } from '../../constants/Permissions';
import { Utils } from '../../helpers';
import Avatar from '../contact/AvatarOnline';
import DownloadAllButton from './DownloadAllButton';
import ForwardMessage from './ForwardMessage';
import MessageItemAttachment from './MessageItemAttachment';
import MessageItemContextMenu from './MessageItemContextMenu';
import MessageItemReceiver from './MessageItemReceiver';
import MessageItemSurvey from './MessageItemSurvey';
import './assets/message-item.scss';
import { Emoticon, TagsEmoticon } from './module';

const ANSWER = 1;
const FOLLOW = 2;
const READ = 3;

export default class MessageItem extends Component {
  static propTypes = {
    news: PropTypes.number,
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    receivers: PropTypes.array.isRequired,
    message: PropTypes.object.isRequired,
    onForward: PropTypes.func,
    onCopyAndForward: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onSaveDocument: PropTypes.func,
  };

  static defaultProps = {
    news: 0,
    onForward: () => {},
    onCopyAndForward: () => {},
    onRemove: () => {},
    onRemoveAll: () => {},
    onSaveDocument: () => {},
  };

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  constructor(props) {
    super(props);
    this.handleReply = this.handleReply.bind(this);
    this.handleReplyAll = this.handleReplyAll.bind(this);
    this.handleShowContextMenu = this.handleShowContextMenu.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.markImportantMessage = this.markImportantMessage.bind(this);
  }

  state = {
    isContextMenuOpen: false,
    isOpenPopup: false,
    fileDownloading: {},
    classSmsId: `sms-${this.props.message.tinNhanId}`,
    importantMessage: this.props.message.danhDau,
    isOpenEmoticon: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(this.props, nextProps) && isEqual(this.state, nextState)) {
      return false;
    }

    return shallowCompare(this, nextProps, nextState);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.importantMessage !== nextProps.message.danhDau) {
      this.setState({ importantMessage: nextProps.message.danhDau });
    }
  }

  handleReply = () => {
    if (!this.props.message.sender) {
      return;
    }

    if (this.isOwner()) {
      return this.handleReplyAll();
    }

    this.props.actions.chatClearReceiver(this.props.receivers);
    this.props.actions.chatAddReceiver(this.props.message.sender.nhanVienId);

    this.props.actions.chatSetConversation(null);
  };

  handleReplyAll = async () => {
    this.props.actions.chatClearReceiver(this.props.receivers);
    await this.props.actions.getReceiverUserWithConversationId(
      this.props.message.tinNhanId,
      this.props.message.hoiThoaiId,
      this.props.message.hoiThoai.tongSoNhanVien
    );

    if (!this.props.message.nhanVienHoiThoai || !this.props.message.nhanVienHoiThoai.length) {
      return;
    }

    const getUsers = (users) => {
      if (users.length === 1) {
        return users;
      }

      return users.filter(
        (user) => this.props.auth.user && this.props.auth.user.nhanVienId !== user.nhanVienId
      );
    };

    if (
      this.props.message &&
      this.props.message.hoiThoai &&
      this.props.message.hoiThoai.tongSoNhanVien > 1200 &&
      get(this.props, 'auth') &&
      this.props.auth.permissions.indexOf(TINNHAN_SMS_365) === -1
    ) {
      return this.props.actions.setCloseLimitConfirm(true);
    }

    this.props.actions.chatAddMultipleReceivers(getUsers(this.props.message.nhanVienHoiThoai));
    if (!this.props.message.hoiThoaiId) {
      this.props.actions.chatSetConversation(null);
    } else {
      this.props.actions.chatSetConversation(this.props.message.hoiThoaiId);
    }
  };

  departmentName = () => {
    if (this.isOwner()) {
      return;
    }

    if (!this.props.message.sender.dsChucDanh || !this.props.message.sender.dsChucDanh.length) {
      return '';
    }

    const mainUnit = this.props.message.sender.dsChucDanh.find((unit) => unit.phongBanChinh);

    if (!mainUnit || !mainUnit.phongBan) {
      return '';
    }

    let unitName = '';
    if (
      mainUnit.donVi &&
      mainUnit.donVi.donViId !== this.props.auth.mainUnitId &&
      mainUnit.donVi.tenVietTat
    ) {
      unitName = ' - ' + mainUnit.donVi.tenVietTat;
    }

    return mainUnit.phongBan.maPhongBan + unitName;
  };

  isOwner = () => {
    return (
      this.props.auth.user && this.props.message.nguoiGuiId === this.props.auth.user.nhanVienId
    );
  };

  handleShowContextMenu = (e) => {
    e.preventDefault();

    ContextMenu.show(
      <MessageItemContextMenu
        auth={this.props.auth}
        actions={this.props.actions}
        classSmsId={this.state.classSmsId}
        receivers={this.props.receivers}
        message={this.props.message}
        onForward={this.props.onForward}
        onCopyAndForward={this.props.onCopyAndForward}
        onReply={this.handleReply}
        onReplyAll={this.handleReplyAll}
        onRemove={this.handleRemove}
        onRemoveAll={this.props.onRemoveAll}
        onSaveDocument={this.props.onSaveDocument}
        onSaveMessage={this.handleSaveMessage}
        onSaveFile={this.handleSaveFile}
        isImportantMessage={
          this.props.importantMessage !== undefined
            ? this.props.importantMessage
            : this.props.message.danhDau
        }
        markImportantMessage={this.markImportantMessage}
        moreWidth
        markEmoticon={this.funcMarkEmoticon}
      />,
      { left: e.clientX, top: e.clientY },
      () => this.setState({ isContextMenuOpen: false })
    );

    this.setState({ isContextMenuOpen: true });
  };

  handleRemove(message) {
    this.props.onRemove && this.props.onRemove(message);
  }

  getContextMessage = (message) => {
    let receivers = message.nhanVienHoiThoai
      ? message.nhanVienHoiThoai.filter((i) => i.nhanVienId !== message.sender.nhanVienId)
      : null;
    let receiverText = '';
    if (receivers) {
      const maxReceiver = receivers.length <= 3 ? receivers.length : 3;
      for (var i = 0; i < maxReceiver; i++) {
        receiverText = trim(`${receiverText}, ${receivers[i].tenNhanVien}`, ', ');
      }
      if (receivers.length > 3) {
        receiverText = `${receiverText}...`;
      }
    }
    const content = message.noiDung?.startsWith('[messageForwardJSON]')
      ? getContent(message?.noiDung)
      : message.noiDung;
    const contentClean = content.replace(/<p>/g, '').replace(/<\/p>/g, '\n');
    const sender = message.sender || message.nguoiGui;
    const text = `FW: [From: ${sender.tenNhanVien || ''}${
      receiverText ? ` To: ${receiverText}` : ''
    } ${
      moment(message.ngayTao).isValid() ? moment(message.ngayTao).format('DD/MM/YYYY h:mm') : ''
    }] \r\n${contentClean}\r\n`;
    return text;
  };

  handleSaveMessage(e) {
    const { forwardMessage } = this.props;
    const { message } = this.props;
    let forwardMessageContent = '';
    forwardMessage &&
      forwardMessage.forEach((msg) => {
        const text = this.getContextMessage(msg);
        forwardMessageContent += text;
      });
    const text = forwardMessageContent + this.getContextMessage(message);
    var blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
    FileDownload(blob, 'tin-nhan.txt');
  }

  async handleDownload(file) {
    this.setState({
      fileDownloading: {
        ...this.state.fileDownloading,
        [file.fileId]: true,
      },
    });
    await this.props.actions.downloadFile(file).then((res) => {
      if (res.error) {
        this.toaster.show({
          message: 'Không tải được file.',
          intent: Intent.DANGER,
        });
      }

      FileDownload(res.payload.data, file.tenFile);
    });
    this.setState({
      fileDownloading: {
        ...this.state.fileDownloading,
        [file.fileId]: false,
      },
    });
  }

  urlify(text) {
    const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;
    text = text?.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });

    return emojione.toImage(text);
  }

  handleOpen() {
    this.setState({ onLoadReceiverUsers: true });
    this.props.actions
      .getReceiverUserWithConversationId(
        this.props.message.tinNhanId,
        this.props.message.hoiThoaiId,
        this.props.message.hoiThoai.tongSoNhanVien,
        false
      )
      .then(() => {
        this.setState({ onLoadReceiverUsers: false });
      });
  }

  handleDelete(e) {
    this.props.onCheckDelete && this.props.onCheckDelete(e, this.props.message);
  }

  handleClickSaveDocument = (file) => {
    this.props.onSaveDocument && this.props.onSaveDocument([file]);
  };

  handleSurveyClick = (survey) => {
    return this.props.actions.surveyCheckPermission(survey.phieuKhaoSatId).then((res) => {
      if (res.error) {
        return (
          this.toaster &&
          this.toaster.show({
            message: 'Kiểm tra phiếu khảo sát không thành công.',
            intent: Intent.WARNING,
          })
        );
      }

      const { result = {} } = res.payload.data;
      const role = result.dsQuyen;
      Utils.saveData(`RoleSurveys`, { role });
      if (result.dsQuyen && result.dsQuyen.indexOf(ANSWER) >= 0) {
        if (result.daHoanThanh) {
          return READ;
        }

        return ANSWER;
      }

      if (result.dsQuyen && result.dsQuyen.indexOf(FOLLOW) >= 0) {
        return FOLLOW;
      }

      return this.setState({ isOpenPopup: true });
    });
  };

  markImportantMessage = () => {
    this.props.actions.markImportantMessage(
      this.props.message.tinNhanId,
      this.props.message.hoiThoaiId,
      this.props.message.danhDau
    );
  };

  getContent = (str = '') => {
    if (!str) return '';

    if (str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[2];
    } else {
      return str;
    }
  };

  funcChangeEmoticon = (emoticon) => {
    if (isEmpty(emoticon)) {
      return;
    }

    const { message } = this.props;
    let data = {
      tinNhanId: message.tinNhanId,
      hoiThoaiId: message.hoiThoaiId,
      emoticonId: emoticon.emoticonId,
    };
    this.props && this.props.funcChangeEmoticon(data);
  };

  funcMarkEmoticon = () => {
    this.setState({ isOpenEmoticon: true });
  };

  onCallBackOpenEmotion = () => {
    this.setState({ isOpenEmoticon: false });
  };

  getTotalNumber = (data) => {
    let result = 0;
    data.map((elm) => (result += Number(elm.count)));
    return result;
  };

  render() {
    const { auth, forwardMessage, message } = this.props;
    const isCheckShowReactEmoticon = auth.permissions.indexOf(REACT_EMOTICON);
    const { isOpenEmoticon } = this.state;

    // Covert data emoticon history
    let filterHasData = [];
    let totalNumber = 0;
    if (size(message.dsThongKeEmoticon) !== 0) {
      filterHasData = message.dsThongKeEmoticon
        .filter((f) => f.count !== 0 && size(f.nhanVienReactInfoList) !== 0)
        .map((elm) => {
          let newItem = {
            emoticon: elm.emoticonName,
            count: elm.count,
            users: [],
          };
          return newItem;
        });
      if (size(filterHasData) !== 0) {
        totalNumber = this.getTotalNumber(filterHasData);
      }
    }
    // END Covert data emoticon history

    return (
      <div
        className={classnames('cpc-chat-message-item cpc-chat-message-item-flex', {
          'cpc-owner': this.isOwner(),
        })}
      >
        <div className="message-item-flex-one">
          {this.props.news ? (
            <div className="cpc-new-item">
              <div className="cpc-new-item-label">Tin nhắn mới ({this.props.news})</div>
              <div className="cpc-new-item-line" />
            </div>
          ) : null}
          {!this.isOwner() && (
            <Avatar
              user={this.props.message.sender}
              disableStatus
              actions={this.props.actions}
              className="cpc-item-avatar"
            />
          )}
          <div className="cpc-item-container">
            <div
              className={classnames('cpc-item-box', {
                'box-highlight': this.state.isContextMenuOpen,
              })}
              onContextMenu={this.handleShowContextMenu}
            >
              <div className="cpc-item-header">
                <Tooltip
                  className="float-left"
                  content="Tin nhắn quan trọng"
                  position={Position.TOP_LEFT}
                >
                  <span
                    onClick={this.markImportantMessage}
                    className={classnames('show', {
                      'icon-star':
                        this.props.importantMessage !== undefined
                          ? this.props.importantMessage
                          : this.props.message.danhDau,
                      'icon-star-no-fill': !(this.props.importantMessage !== undefined
                        ? this.props.importantMessage
                        : this.props.message.danhDau),
                    })}
                  ></span>
                </Tooltip>
                <div className="cpc-sender">
                  {!this.isOwner() ? (
                    <h3 className="user-send">
                      <span className="text-label">From: </span>
                      <span className="user-name" onClick={this.handleReply}>
                        {this.props.message.sender.tenNhanVien}
                      </span>
                    </h3>
                  ) : null}
                  {this.departmentName() ? (
                    <span className="cpc-role">{this.departmentName()}</span>
                  ) : null}
                </div>
                <div className="cpc-receivers">
                  <span>To:</span>
                  <MessageItemReceiver
                    actions={this.props.actions}
                    receivers={this.props.message.nhanVienHoiThoai}
                    exclude={this.props.message.sender}
                    onOpen={this.handleOpen}
                    // onClick={this.handleReplyAll}
                    onLoadReceiverUsers={this.state.onLoadReceiverUsers}
                  />
                  <Tooltip content="Trả lời" position={Position.TOP}>
                    <span
                      className="cpc-actions cpc-icon icon-arrrow-1"
                      onClick={this.handleReply}
                    />
                  </Tooltip>
                  <Tooltip content="Trả lời tất cả" position={Position.TOP}>
                    <span
                      className="cpc-actions cpc-icon icon-arrow-2"
                      onClick={this.handleReplyAll}
                    />
                  </Tooltip>
                </div>
                <div className="cpc-right-section">
                  <div className="cpc-time-delivery">
                    {this.isOwner() && (
                      <span className="cpc-delivery">
                        <span className="cpc-icon icon-sucess" />
                      </span>
                    )}
                    <span className="cpc-time">
                      <FormattedTime value={this.props.message.ngayTao} />
                    </span>
                  </div>
                  <div className="cpc-sms-email">
                    {this.props.message.guiKemSms ? <span className="cpc-sms">SMS</span> : null}
                    {this.props.message.guiKemEmail ? (
                      <span className="cpc-icon icon-mail" />
                    ) : null}
                  </div>
                </div>
              </div>

              {forwardMessage &&
                forwardMessage.map((message) => (
                  <ForwardMessage
                    key={message.tinNhanId}
                    onSaveDocument={this.props.onSaveDocumen}
                    actions={this.props.actions}
                    forwardMessage={message}
                    auth={this.props.auth}
                    isOwner={this.isOwner()}
                    classSmsId={this.state.classSmsId}
                  />
                ))}
              {!this.props.message.noiDung ? null : (
                <div>
                  <div
                    className={`cpc-item-body cpc-chat-content ${this.state.classSmsId}`}
                    id={this.state.classSmsId}
                    dangerouslySetInnerHTML={{
                      __html: this.urlify(this.getContent(this.props.message.noiDung)),
                    }}
                  />
                </div>
              )}
              {this.props.message.fileDinhKem && this.props.message.fileDinhKem.length ? (
                <MessageItemAttachment
                  auth={this.props.auth}
                  files={this.props.message.fileDinhKem}
                  onDownload={this.handleDownload}
                  fileDownloading={this.state.fileDownloading}
                  onSaveDocument={this.handleClickSaveDocument}
                />
              ) : null}

              {size(get(forwardMessage, '[0].fileDinhKem', [])) +
                size(get(this.props, 'message.fileDinhKem', [])) !==
                0 && (
                <DownloadAllButton
                  accessToken={get(this.props, 'auth.token.accessToken')}
                  files={[
                    ...get(forwardMessage, '[0].fileDinhKem', []),
                    ...get(this.props, 'message.fileDinhKem', []),
                  ]}
                />
              )}

              {this.props.message.phieuKhaoSat && this.props.message.phieuKhaoSat.length ? (
                <MessageItemSurvey
                  owner={this.isOwner()}
                  surveys={this.props.message.phieuKhaoSat}
                  onClick={this.handleSurveyClick}
                  actions={this.props.actions}
                />
              ) : null}
              {this.props.isDelete && (
                <div className="cpc-check-delete">
                  <label className="pt-control pt-checkbox">
                    <input
                      type="checkbox"
                      name={this.props.message.tinNhanId}
                      onChange={this.handleDelete}
                      checked={this.props.checked}
                    />
                    <span className="pt-control-indicator" />
                  </label>
                </div>
              )}
              {(size(message.dsThongKeEmoticon) !== 0 || !this.isOwner()) &&
                isCheckShowReactEmoticon !== -1 && (
                  <div
                    className={classnames('emoticon', {
                      'emoticon-and-check-delete': this.props.isDelete,
                    })}
                  >
                    {size(filterHasData) !== 0 && (
                      <TagsEmoticon
                        data={filterHasData}
                        totalNumber={totalNumber}
                        message={this.props.message}
                      />
                    )}
                    {!this.isOwner() && (
                      <Emoticon
                        changeEmoticon={this.funcChangeEmoticon}
                        selectedEmoticons={message.dsThongKeEmoticon}
                        isOpenEmoticon={isOpenEmoticon}
                        onCallBackOpenEmotion={this.onCallBackOpenEmotion}
                      />
                    )}
                  </div>
                )}
            </div>
          </div>
          <Dialog
            isOpen={this.state.isOpenPopup}
            onClose={(e) => this.setState({ isOpenPopup: false })}
            className="popup-cpc-confirm"
            title=""
          >
            <div className="pt-dialog-body">
              <span className="icon icon-interactive"></span>
              <p className="message-cofirm">Anh chị không được tương tác phiếu khảo sát này</p>
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                <Button
                  iconName="pt-icon-tick"
                  intent={Intent.NORMAL}
                  onClick={(e) => this.setState({ isOpenPopup: false })}
                  text="Đóng"
                  className="btn-confirm-popup"
                />
              </div>
            </div>
          </Dialog>
          <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
        </div>
      </div>
    );
  }
}
