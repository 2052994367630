import Conversation from './ConversationSchema';
// import User from './User';
import { schema } from 'normalizr';

const Message = new schema.Entity(
  'messages',
  {
    hoiThoai: {
      nhanVienHoiThoai: [Conversation],
    },
    // nguoiGui: User,
  },
  {
    idAttribute: (message) => message.tinNhanId,
  }
);

export default Message;
