import { checkFileType, getIconFile } from '@/helpers/helper';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const LoadingFile = ({ item, uploader }) => {
  return (
    <div className="file-loading">
      <div className="div-center">
        <CircularProgressbar
          value={Math.floor(item.progress)}
          strokeWidth={10}
          styles={buildStyles({
            // Colors
            pathColor: '#1552DC',
            trailColor: 'white',
          })}
        />
      </div>

      {checkFileType(item?.file?.type) === 'IMAGE' ? (
        <img src={item.src} alt="" />
      ) : (
        <img className="item-img" src={getIconFile(item?.file?.type)} alt="" />
      )}
    </div>
  );
};

export default LoadingFile;
