import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ANSWER = 1;
const FOLLOW = 2;
const READ = 3;

export default class MessageItemSurvey extends Component {
  static propTypes = {
    owner: PropTypes.bool,
    surveys: PropTypes.array.isRequired,
  };

  static defaultProps = {
    owner: false,
    surveys: [],
  };

  state = {
    isRedirect: false,
    isLoading: false,
    role: false,
  };

  handleClick = (survey) => {
    if (!this.props.onClick) {
      return;
    }

    if (!this.state.isLoading) {
      this.setState({ isLoading: true }, async () => {
        await this.props.actions.removeSurveyItem();
        await this.props.onClick(survey).then((res) => {
          switch (res) {
            case READ:
              this.setState({
                isLoading: false,
                isRedirect: `/phieu-khao-sat/chi-tiet/${survey.phieuKhaoSatId}`,
              });
              break;
            case ANSWER:
              this.setState({
                isLoading: false,
                isRedirect: `/phieu-khao-sat/tra-loi/${survey.phieuKhaoSatId}`,
              });
              break;
            case FOLLOW:
              this.setState({
                isLoading: false,
                isRedirect: `/phieu-khao-sat/thong-ke/${survey.phieuKhaoSatId}`,
              });
              break;
            default:
              this.setState({ isLoading: false });
          }
        });
      });
    }
  };

  render() {
    if (this.state.isRedirect) {
      return <Redirect push to={this.state.isRedirect} />;
    }
    return (
      <div className="mesage-item-sent">
        {this.props.surveys.map((item, k) => (
          <div className="cpc-survey-box-messenger" key={k}>
            <div
              className="cpc-survey-link cpc-survey-link--inline"
              onClick={this.handleClick.bind(this, item)}
            >
              <span
                className={classnames('loading-data', {
                  'icon-survey': !this.state.isLoading && !item.tieuDe?.startsWith('[PĐT]'),
                  'icon-fruit': !this.state.isLoading && item.tieuDe?.startsWith('[PĐT]'),
                })}
              />
              <div
                className={classnames('cpc-survey-inline', {
                  'text-blue': item.tieuDe?.startsWith('[PĐT]'),
                })}
              >
                {item.tieuDe?.startsWith('[PĐT]') ? 'Phiếu Điểm Tâm' : 'Phiếu Khảo Sát'}
              </div>
              <div className="cpc-survey-inline-name">
                {item.tieuDe?.startsWith('[PĐT]') ? item.tieuDe.replace('[PĐT]', '') : item.tieuDe}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
