import React, { Component } from 'react';
import moment from 'moment';
import emojione from 'emojione';
import classnames from 'classnames';
import MessageItemAttachment from './MessageItemAttachment';
import MessageItemSurvey from './MessageItemSurvey';
import Image from './Image';
import FileDownload from 'react-file-download';
import { Position, Intent, Toaster, Dialog, Button } from '@blueprintjs/core';
import { UPLOAD_API_URL } from '../../constants/Api';
import bytes from 'bytes';
import { variables } from '@/constants/variables';
import { Utils } from '../../helpers';
const ANSWER = 1;
const FOLLOW = 2;
const READ = 3;
export default class ForwardMessage extends Component {
  state = {
    fileDownloading: {},
    isOpenPopup: false,
  };

  refHandlers = {
    toaster: (ref) => (this.toaster = ref),
  };

  removeForwardMessage = () => {
    this.props.removeForwardMessage && this.props.removeForwardMessage();
  };

  departmentName = (message) => {
    if (message.sender) {
      if (!message.sender.dsChucDanh || !message.sender.dsChucDanh.length) {
        return '';
      }

      const mainUnit = message.sender.dsChucDanh.find((unit) => unit.phongBanChinh);

      if (!mainUnit || !mainUnit.phongBan) {
        return '';
      }

      let unitName = '';
      if (
        mainUnit.donVi &&
        mainUnit.donVi.donViId !== this.props.auth.mainUnitId &&
        mainUnit.donVi.tenVietTat
      ) {
        unitName = ' - ' + mainUnit.donVi.tenVietTat;
      }
      return mainUnit.phongBan.maPhongBan + unitName;
    } else {
      if (!message.nguoiGui.dsChucDanh || !message.nguoiGui.dsChucDanh.length) {
        return '';
      }

      const mainUnit = message.nguoiGui.dsChucDanh[0];

      if (!mainUnit || !mainUnit.phongBan) {
        return '';
      }

      let unitName = '';
      if (
        mainUnit.donVi &&
        mainUnit.donVi.donViId !== this.props.auth.mainUnitId &&
        mainUnit.donVi.tenVietTat
      ) {
        unitName = ' - ' + mainUnit.donVi.tenVietTat;
      }
      return mainUnit.phongBan.maPhongBan + unitName;
    }
  };

  isOwner = (message) => {
    return this.props.auth.user && message.nguoiGuiId === this.props.auth.user.nhanVienId;
  };

  urlify(text) {
    const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;
    text = text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });

    return emojione.toImage(text);
  }

  getContent = (str) => {
    if (str && str?.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[2];
    } else {
      return str;
    }
  };

  async handleDownload(file) {
    this.setState({
      fileDownloading: {
        ...this.state.fileDownloading,
        [file.fileId]: true,
      },
    });
    await this.props.actions.downloadFile(file).then((res) => {
      if (res.error) {
        this.toaster.show({ message: 'Không tải được file.', intent: Intent.DANGER });
      }

      FileDownload(res.payload.data, file.tenFile);
    });
    this.setState({
      fileDownloading: {
        ...this.state.fileDownloading,
        [file.fileId]: false,
      },
    });
  }

  handleClickSaveDocument = (file) => {
    this.props.onSaveDocument && this.props.onSaveDocument([file]);
  };

  handleSurveyClick = (survey) => {
    return this.props.actions.surveyCheckPermission(survey.phieuKhaoSatId).then((res) => {
      if (res.error) {
        return (
          this.toaster &&
          this.toaster.show({
            message: 'Kiểm tra phiếu khảo sát không thành công.',
            intent: Intent.WARNING,
          })
        );
      }

      const { result = {} } = res.payload.data;
      const role = result.dsQuyen;
      Utils.saveData(`RoleSurveys`, { role });
      if (result.dsQuyen && result.dsQuyen.indexOf(ANSWER) >= 0) {
        if (result.daHoanThanh) {
          return READ;
        }

        return ANSWER;
      }

      if (result.dsQuyen && result.dsQuyen.indexOf(FOLLOW) >= 0) {
        return FOLLOW;
      }

      return this.setState({ isOpenPopup: true });
    });
  };

  getURLTypeFileImg = (type) => {
    switch (type) {
      case 'application/pdf':
      case '.pdf':
        return process.env.PUBLIC_URL + '/images/pdf.png';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
      case '.doc':
      case '.docx':
        return process.env.PUBLIC_URL + '/images/doc.png';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
      case '.xls':
      case '.xlsx':
        return process.env.PUBLIC_URL + '/images/xls.png';
      default:
        return process.env.PUBLIC_URL + '/images/file.png';
    }
  };

  handleRemoveForwardMessage = (id) => () => {
    this.props.forwardMessageListLength === 1 && this.props.actions.setIdMessageForward('');
    this.props.actions.removeFromQuoteMessage({ id, type: 'tinNhan' });
    this.props.resetCheckCopyAndForward(false);
  };

  handleRemoveFileForwardMessage = (idFile, forwardMessage) => () => {
    !forwardMessage.noiDung &&
      forwardMessage.fileDinhKem.length === 1 &&
      this.handleRemoveForwardMessage(forwardMessage.tinNhanId)();
    this.props.actions.removeFromQuoteMessage({ id: idFile, type: 'file' });
    this.props.resetCheckCopyAndForward(false);
  };

  render() {
    let { forwardMessage, removeIdFileFromQuoteMessage } = this.props;
    if (removeIdFileFromQuoteMessage && removeIdFileFromQuoteMessage.length > 0) {
      removeIdFileFromQuoteMessage.forEach(
        (id) =>
          (forwardMessage.fileDinhKem = forwardMessage.fileDinhKem.filter(
            (item) => item.fileId !== id
          ))
      );
    }
    let { accessToken } = this.props.auth.token;
    return (
      <div
        className={classnames('position-relative p10 pr50', {
          'bg-white border-secondary border-bottom-width-0': this.props.isInEditor,
          'bg-transparent border-bottom-secondary':
            !this.props.isInEditor && !this.props.isOwner && !this.props.isInSearch,
          'bg-transparent border-bottom-secondary-owner':
            !this.props.isInEditor && this.props.isOwner && !this.props.isInSearch,
          'bg-transparent border-bottom-secondary-search': this.props.isInSearch,
        })}
      >
        <div
          className={classnames('quote-icon__wrapper', {
            'quote-icon__wrapper--in-editor': this.props.isInEditor,
          })}
        >
          <span className="icon-right-quote quote-icon__icon" />
        </div>
        {this.props.removeForwardMessage && (
          <span
            onClick={this.handleRemoveForwardMessage(forwardMessage.tinNhanId)}
            className="cursor-pointer cpc-icon icon-close-2 position-absolute top-10 right-10 font-size-13"
          />
        )}
        <div className={classnames({ pl30: this.props.isInEditor, pl20: !this.props.isInEditor })}>
          {forwardMessage.noiDung && (
            <div
              className={classnames('cpc-chat-content text-italic emoji-w-25px', {
                'white-space-nowrap break-word text-overflow-ellipsis height-28px':
                  this.props.isInEditor,
                [this.props.classSmsId]: !this.props.isInEditor,
              })}
              dangerouslySetInnerHTML={{
                __html: this.urlify(this.getContent(forwardMessage.noiDung)),
              }}
            />
          )}
          {forwardMessage.fileDinhKem &&
          forwardMessage.fileDinhKem.length &&
          !this.props.isInEditor ? (
            <MessageItemAttachment
              auth={this.props.auth}
              isInEditor={this.props.isInEditor}
              files={forwardMessage.fileDinhKem}
              onDownload={this.handleDownload}
              fileDownloading={this.state.fileDownloading}
              onSaveDocument={this.handleClickSaveDocument}
              isForwardMessage={true}
            />
          ) : null}
          {forwardMessage.fileDinhKem &&
          forwardMessage.fileDinhKem.length &&
          this.props.isInEditor ? (
            <div className="attachment-files">
              {forwardMessage.fileDinhKem.map((file) => {
                if (file.kieuFile.indexOf('image') >= 0) {
                  return (
                    <div key={file.fileId} className="attachment-files__image-wrapper">
                      <Image
                        className="attachment-files__image"
                        alt={file.tenFile}
                        title={file.tenFile}
                        src={`${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${file.fileId}?w=250&fileName=${file.fileName}&BearerToken=${accessToken}`}
                      />
                      <div
                        onClick={this.handleRemoveFileForwardMessage(file.fileId, forwardMessage)}
                        className="attachment-files__cancle-icon-wrapper"
                      >
                        <span className="icon-x attachment-files__cancle-icon"></span>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      title={
                        file.tenFile?.startsWith('eofficeFile')
                          ? file.tenFile.replace('eofficeFile-', '')
                          : file.tenFile
                      }
                      key={file.fileId}
                      className="attachment-files__file"
                    >
                      <img
                        className="attachment-files__file-type-image"
                        alt=""
                        src={this.getURLTypeFileImg(file.kieuFile)}
                      />
                      <div className="attachment-files__file-info">
                        <div className="attachment-files__file-name">
                          {file.tenFile?.startsWith('eofficeFile')
                            ? file.tenFile.replace('eofficeFile-', '')
                            : file.tenFile}
                        </div>
                        <div className="attachment-files__file-size">
                          {bytes(file.kichThuoc, { unitSeparator: ' ' })}
                        </div>
                      </div>
                      <div
                        onClick={this.handleRemoveFileForwardMessage(file.fileId, forwardMessage)}
                        className="attachment-files__cancle-icon-wrapper"
                      >
                        <span className="icon-x attachment-files__cancle-icon"></span>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : null}
          {forwardMessage.phieuKhaoSat &&
          forwardMessage.phieuKhaoSat.length &&
          !this.props.isInEditor ? (
            <MessageItemSurvey
              owner={this.isOwner(forwardMessage)}
              surveys={forwardMessage.phieuKhaoSat}
              onClick={this.handleSurveyClick}
              actions={this.props.actions}
            />
          ) : null}
          {forwardMessage.phieuKhaoSat &&
          forwardMessage.phieuKhaoSat.length &&
          this.props.isInEditor
            ? forwardMessage.phieuKhaoSat.map((item) => (
                <div key={item.phieuKhaoSatId} className="attachment-files">
                  <div
                    title={
                      item.tieuDe?.startsWith('[PĐT]')
                        ? item.tieuDe.replace('[PĐT]', '')
                        : item.tieuDe
                    }
                    className="attachment-files__file attachment-files__file--survey"
                  >
                    <span
                      className={classnames(
                        'attachment-files__file-type-image attachment-files__file-type-image--survey',
                        {
                          'icon-survey': !item.tieuDe?.startsWith('[PĐT]'),
                          'icon-fruit': item.tieuDe?.startsWith('[PĐT]'),
                        }
                      )}
                    />
                    <div className="attachment-files__file-info">
                      <div
                        className={classnames('attachment-files__file-name', {
                          'attachment-files__file-name--survey': !item.tieuDe?.startsWith('[PĐT]'),
                          'attachment-files__file-name--breakfast-survey':
                            item.tieuDe?.startsWith('[PĐT]'),
                        })}
                      >
                        {item.tieuDe?.startsWith('[PĐT]') ? 'Phiếu Điểm Tâm' : 'Phiếu Khảo Sát'}
                      </div>
                      <div className="attachment-files__file-size attachment-files__file-size--survey">
                        {item.tieuDe?.startsWith('[PĐT]')
                          ? item.tieuDe.replace('[PĐT]', '')
                          : item.tieuDe}
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        this.props.actions.removeFromQuoteMessage({
                          id: forwardMessage.tinNhanId,
                          type: 'phieuKhaoSat',
                        })
                      }
                      className="attachment-files__cancle-icon-wrapper"
                    >
                      <span className="icon-x attachment-files__cancle-icon"></span>
                    </div>
                  </div>
                </div>
              ))
            : null}
          <p className={classnames('font-size-13', { sender: !this.props.isInEditor })}>
            {forwardMessage.sender ? (
              <span className="font-weight-500">{forwardMessage.sender.tenNhanVien}</span>
            ) : forwardMessage.nguoiGui ? (
              <span className="font-weight-500">{forwardMessage.nguoiGui.tenNhanVien}</span>
            ) : (
              ''
            )}
            {this.departmentName(forwardMessage, this.props.isInEditor)
              ? ` ${this.departmentName(forwardMessage, this.props.isInEditor)}`
              : ''}
            {forwardMessage.ngayTao && moment(forwardMessage.ngayTao).isValid()
              ? `, ${moment(forwardMessage.ngayTao).format('HH:mm - DD/MM/YYYY')}`
              : ''}
          </p>
        </div>
        <Dialog
          isOpen={this.state.isOpenPopup}
          onClose={(e) => this.setState({ isOpenPopup: false })}
          className="popup-cpc-confirm"
          title=""
        >
          <div className="pt-dialog-body">
            <span className="icon icon-interactive"></span>
            <p className="message-cofirm">Anh chị không được tương tác phiếu khảo sát này</p>
          </div>
          <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions">
              <Button
                iconName="pt-icon-tick"
                intent={Intent.NORMAL}
                onClick={(e) => this.setState({ isOpenPopup: false })}
                text="Đóng"
                className="btn-confirm-popup"
              />
            </div>
          </div>
        </Dialog>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}
