const sidebar = [
  {
    icon: 'chat',
    name: 'Chat',
    url: '/',
    keyName: 'CHAT',
  },
  {
    icon: 'phone-book',
    name: 'Danh bạ',
    url: '/danh-ba',
    keyName: 'DANH_BA',
  },
  {
    icon: 'eoffice-71',
    name: 'eOffice',
    url: null,
    keyName: 'EOFFICE',
  },
  {
    icon: 'doffice',
    name: 'doffice',
    url: null,
    keyName: 'DOFFICE',
  },
  {
    icon: 'headset',
    name: 'Hỗ trợ CNTT',
    url: '/ho-tro-cntt',
  },
  {
    icon: 'application',
    name: 'Tiện ích',
    url: null,
    keyName: 'TIEN_ICH',
    children: [
      {
        icon: 'message',
        name: 'Hộp thư góp ý',
        url: '/hop-thu-gop-y',
        keyName: 'HOP_THU_GOP_Y',
        childrenUrl: [
          '/hop-thu-gop-y',
          '/hop-thu-gop-y/thong-ke',
          '/hop-thu-gop-y/gui-gop-y',
          '/hop-thu-gop-y/chi-tiet',
          '/hop-thu-gop-y/tao-cau-hoi',
        ],
      },
      {
        icon: 'survey',
        name: 'Phiếu khảo sát',
        url: '/phieu-khao-sat',
        keyName: 'PHIEU_KHAO_SAT',
        childrenUrl: [
          '/phieu-khao-sat',
          '/phieu-khao-sat/them-moi',
          '/phieu-khao-sat/tra-loi',
          '/phieu-khao-sat/theo-doi',
          '/phieu-khao-sat/thong-ke',
          '/phieu-khao-sat/chi-tiet',
          '/phieu-khao-sat/chinh-sua',
          '/phieu-khao-sat/gui-di',
          '/phieu-khao-sat/cau-hoi-khong-duoc-tra-loi',
        ],
      },
      {
        icon: 'gift',
        name: 'Sinh nhật',
        keyName: 'SINH_NHAT',
      },
      // {
      //   icon: 'survey',
      //   name: 'Nhắn tin hàng loạt',
      //   url: '/nhan-tin-hang-loat',
      //   keyName: 'NHAN_TIN_HANG_LOAT',
      //   childrenUrl: [
      //     '/nhan-tin-hang-loat',
      //   ],
      // },
      {
        icon: 'survey',
        name: 'Nhắn tin nhắc việc',
        url: '/nhan-tin-nhac-viec',
        keyName: 'NHAN_TIN_NHAC_VIEC',
        childrenUrl: [
          '/nhan-tin-nhac-viec/tao',
          '/nhan-tin-nhac-viec/danh-sach',
          '/nhan-tin-nhac-viec/thong-ke',
          '/nhan-tin-nhac-viec/chi-tiet',
        ],
      },
    ],
  },
  {
    icon: 'cloud',
    name: 'Cloud',
    lastName: 'của tôi',
    keyName: 'CLOUD',
    isBottom: true,
  },
];

export { sidebar };
