import './heading.scss';
import CPCButton from '@/components/atoms/button';
import CPCIcon from '@/components/atoms/icon';

const Heading = ({ isGoback = false, title, leftElement = null, handleGoBack = () => {} }) => {
  return (
    <div className="heading">
      <div className="d-flex align-center">
        {isGoback && (
          <CPCButton className="cpc-button__theme4" onClick={handleGoBack}>
            <CPCIcon icon="arrow-left-v2" className="arrow-left-icon" />
            <span>Trở lại</span>
          </CPCButton>
        )}
        {title && (
          <h2 className="heading-icon-20 main-heading-page">
            <span className="heading-text word-break-break-word">{title?.trim()}</span>
          </h2>
        )}
      </div>
      <div>{leftElement}</div>
    </div>
  );
};

export default Heading;
