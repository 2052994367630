import {
  API_REQUEST_SEND,
  API_REQUEST_ERROR,
  GET_CONFIGURATION,
  SET_SIDEBAR,
  SET_PAGE_VISIBILITY,
} from '@/constants/ActionTypes';

export const getConfigurationInfos = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, GET_CONFIGURATION, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/hoithoai/configuration',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const setSidebar = (sidebar) => ({
  type: SET_SIDEBAR,
  payload: sidebar,
});

export const setPageVisibility = (value) => ({
  type: SET_PAGE_VISIBILITY,
  payload: value,
});
