import { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';
import CPCUser from '@/components/molecules/user';
import CPCFlex from '@/components/atoms/flex';
import AvatarConversation from '@/components/molecules/avatar-conversation';
import NameConversation from '@/components/molecules/name-conversation';
import FormGroup from '@/components/molecules/form-group';

const ListChoose = ({
  actions,
  heightMin = 200,
  heightMax = 230,
  actionProps,
  classNameItem,
  isScroll = false,
  data = [],
  onChange = () => {},
  onRemove = () => {},
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    const scrollContainer = scrollRef?.current;
    if (scrollContainer && isScroll) {
      scrollContainer.scrollToBottom();
      actions.updateIsScroll(false);
    }
  }, [isScroll]);

  return (
    <Scrollbars
      ref={scrollRef}
      autoHeight
      autoHeightMin={heightMin}
      autoHeightMax={heightMax}
      renderView={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            paddingRight: '10px',
          }}
        />
      )}
    >
      {data?.map((item, index) => {
        if (actionProps.type === 'SURVEY' && item.isGroup === 1) {
          return null;
        }
        const infos = item?.dsChucDanh?.find((u) => u?.phongBanChinh);
        return (
          <div
            key={index}
            className={classNames('ph-10', {
              'border-top': index !== 0,
              [`${classNameItem}`]: classNameItem,
            })}
          >
            <CPCFlex align={'center'} justify={'space-between'}>
              {item?.nhanVienId ? (
                <CPCUser
                  anhDaiDien={item?.anhDaiDien}
                  tenNhanVien={item?.tenNhanVien}
                  infos={
                    <>
                      <span className="text-12-400-uppercase" style={{ color: '#7589A3' }}>
                        {infos?.donVi?.tenVietTat}
                      </span>
                      {infos?.phongBan?.maPhongBan ? ' - ' : ''}
                      {infos?.phongBan?.maPhongBan && (
                        <span className="text-12-400-uppercase" style={{ color: '#7589A3' }}>
                          {infos?.phongBan?.maPhongBan}
                        </span>
                      )}
                    </>
                  }
                />
              ) : (
                <CPCFlex key={index} gap={10} align={'center'}>
                  <AvatarConversation
                    anhDaiDien={item?.anhDaiDien}
                    tongSoNhanVien={item?.tongSoNhanVien}
                    nhanVienHoiThoai={item?.nhanVienHoiThoai}
                    isGroup={item?.isGroup}
                  />
                  <NameConversation data={item} />
                </CPCFlex>
              )}
              <span
                className="icon-xmark icon-class__close cursor-pointer ml-5"
                onClick={() => onRemove(item)}
              />
            </CPCFlex>
            {actionProps?.type === 'SURVEY' && (
              <div className="mt-10" style={{ padding: '0 1px' }}>
                <FormGroup
                  kind="select2"
                  classNameGroup="mb-0"
                  themeGroup="1"
                  inputProps={{
                    options: [
                      { value: 1, label: 'Bỏ phiếu' },
                      { value: 2, label: 'Xem thống kê' },
                      { value: 3, label: 'Bỏ phiếu và xem thống kê' },
                    ],
                    selected: { value: item?.surveyPermission || 1 },
                    inputTheme: '1',
                    onChange: (values) => onChange(item, values),
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </Scrollbars>
  );
};

export default ListChoose;
