import { variables } from '@/constants/variables';
import { UPLOAD_API_URL } from '@/constants/Api';
import { checkFileType, getFileSize } from '@/helpers/helper';
import { removeSystemDeleteName } from '@/helpers/chat';
import CPCFile from '@/components/atoms/file';
import './file.scss';

const ForwardFile = ({ dataSource, auth, onRemove = () => {} }) => {
  const { accessToken } = auth.token;
  const name = dataSource?.tenFile?.startsWith('eofficeFile')
    ? removeSystemDeleteName(dataSource.tenFile.replace('eofficeFile-', ''))
    : removeSystemDeleteName(dataSource.tenFile);
  const thumbWidth = dataSource?.kieuFile === 'image/gif' ? '' : '&w=250';
  const isSystemDeletedFile = dataSource?.tenFile && dataSource.tenFile.endsWith('system_deleted');
  const prefix =
    dataSource?.tenFile && dataSource.tenFile?.startsWith('eofficeFile') ? 'file' : 'filechat';
  const originUrl = `${UPLOAD_API_URL}/api/${prefix}/get/${dataSource.fileId}?app=eoffice&fileName=${dataSource.fileName}&BearerToken=${accessToken}`;
  const displayUrl = `${UPLOAD_API_URL}/${variables.API.FILE_CHAT_GET}/${dataSource.fileId}?fileName=${dataSource.fileName}${thumbWidth}&BearerToken=${accessToken}`;

  return (
    <div className="forward-file">
      {checkFileType(dataSource?.kieuFile) === 'IMAGE' ? (
        <div style={{ lineHeight: 0 }}>
          <img src={displayUrl} alt={dataSource?.tenFile} title={dataSource?.tenFile} />
        </div>
      ) : (
        <div>
          <CPCFile
            className={'forward-file-document'}
            name={name}
            kind={dataSource?.kieuFile}
            size={getFileSize(dataSource?.kichThuoc)}
            maxWidth={100}
            // onClick={() => this.handleFileClick(fileUrl)}
          />
        </div>
      )}
      <span className="icon-xmark cursor-pointer close" onClick={() => onRemove(dataSource)}></span>
    </div>
  );
};

export default ForwardFile;
