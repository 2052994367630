import React, { useEffect, useRef, useState, useCallback } from 'react';
import { getImageScaleWidth } from '@/helpers/image';
import CPCDialog from '@/components/atoms/dialog';
import CPCFlex from '@/components/atoms/flex';
import CPCIcon from '@/components/atoms/icon';
import IconWrapper from '@/components/molecules/icon-wrapper';
import './index.scss';

const CPCImagePreview = ({ src, isOpen = false, onClose = () => {} }) => {
  if (!isOpen || !src) {
    return;
  }

  const refImg = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [customWidthHeight, setCustomWidthHeight] = useState({
    width: '500px',
    height: '500px',
  });
  const [deg, setDeg] = useState(0);
  const [objImg, setObjImg] = useState({
    height: 500,
    width: 500,
  });

  const loadImg = () => {
    let img = new Image();
    img.onload = () => {
      setIsLoading(false);
      const { height, width } = img;
      if (height > window.innerHeight * 0.8) {
        setObjImg({
          height: window.innerHeight * 0.8,
          width: ((window.innerHeight * 0.8) / img.height) * img.width,
        });
      } else if (width > window.innerWidth * 0.8) {
        setObjImg({
          height: getImageScaleWidth(window.innerWidth * 0.8, img.width, img.height),
          width: window.innerWidth * 0.8,
        });
      } else {
        setObjImg({
          height: img.height,
          width: img.width,
        });
      }
    };
    img.src = src;
  };

  const initDimensionImg = (w, h) => {
    setCustomWidthHeight({
      height: h + 'px',
      width: w + 'px',
    });
  };

  const onChangeDeg = useCallback(() => {
    setDeg((prev) => prev + 90);
  }, [deg]);

  useEffect(() => {
    if (refImg.current) {
      if (deg % 180 === 90) {
        initDimensionImg(objImg.height, objImg.width);
      } else {
        initDimensionImg(objImg.width, objImg.height);
      }
    }
  }, [deg]);

  useEffect(() => {
    initDimensionImg(objImg.width, objImg.height);
  }, [objImg]);

  useEffect(() => {
    loadImg();
  }, []);

  const createTitle = (content) => {
    return (
      <CPCFlex align={'center'} justify={'space-between'}>
        {content}
        <IconWrapper
          colorHover={'rgba(143, 153, 168, 0.15)'}
          size="sm-1"
          className="mr-10"
          onClick={onChangeDeg}
        >
          <CPCIcon icon="reload" color="#9b9b9b" />
        </IconWrapper>
      </CPCFlex>
    );
  };

  return (
    <CPCDialog
      className={'min-auto'}
      title={createTitle('Preview')}
      isOpen={isOpen}
      minimal
      onClose={onClose}
    >
      <div
        className="cpc-image-preview"
        style={customWidthHeight}
        onContextMenu={(e) => e.stopPropagation()}
      >
        {isLoading ? (
          <img
            className="cpc-image-preview__loading"
            alt="Loading Img"
            src={process.env.PUBLIC_URL + '/images/loading.gif'}
          />
        ) : (
          <div className="cpc-image-preview__box">
            <img
              ref={refImg}
              style={{ ...objImg, transform: `rotate(${deg}deg)` }}
              className="cpc-image-preview__img"
              id="img-element"
              alt="Preview Img"
              src={src}
            />
          </div>
        )}
      </div>
    </CPCDialog>
  );
};

export default CPCImagePreview;
