import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { Header } from '../../../components/layout';
import { Button, Tabs, TextAreaAutoHeight } from '../../../components/common';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions';
import { connect } from 'react-redux';
import './../assets/common.scss';
import { get, isEmpty } from 'lodash';
import { decamelizeKeys } from 'humps';
import { Intent, Position } from '@blueprintjs/core';
import { variables } from '../../../constants/variables';
import { FE_URL } from '../../../constants/Api';
import LayoutMain from '@/components/organisms/layout-main';
import Heading from '@/components/layout/heading';
import IconWrapper from '@/components/molecules/icon-wrapper';
import CPCIcon from '@/components/atoms/icon';
import COLORS from '@/constants/colors';
import CPCTooltip from '@/components/atoms/tooltip';
import Scrollbars from 'react-custom-scrollbars';

const imageUrl = process.env.PUBLIC_URL + '/images/Server.png';

const DragHandle = sortableHandle(() => (
  <span className="sortable-handle">
    <CPCIcon icon="sort-v2" size={18} color="#7589A3" />
  </span>
));

const SortableItem = SortableElement((props) => (
  <li className="sortable-li cpc-content configuration">
    {!props.value.isDeleted && <DragHandle />}
    {!props.value.isDeleted && (
      <div className="question-box">
        <input
          className="question-add-title"
          type="text"
          placeholder="Nhập nội dung câu hỏi"
          value={props.value.cauHoi}
          onChange={(e) => {
            props.handleChangeQuestion(e.target.value, props.indexParent, props.i);
          }}
          maxLength={500}
        />
        <div>
          {props.value.dsDapAn &&
            props.value.dsDapAn.map(
              (item, key) =>
                !item.isDeleted && (
                  <div key={key} className="question-radio pb10">
                    <div className="question-radio__left">
                      <span className="icon-radio-custom"></span>
                      <TextAreaAutoHeight
                        className="input-text-custom"
                        type="text"
                        placeholder="Nhập đáp án tiếp theo"
                        value={item.noiDungDapAn}
                        onChange={(e) => {
                          props.handleChangeAnswer(e.target.value, props.indexParent, props.i, key);
                        }}
                        focus={item.autoFocus}
                        maxLength={500}
                      />
                    </div>
                    <CPCTooltip theme="white" position={Position.TOP} content="Xóa">
                      <IconWrapper size={18}>
                        <CPCIcon
                          icon="delete"
                          size={16}
                          onClick={() => {
                            props.handleRemoveAnswer(props.indexParent, props.i, key);
                          }}
                          color="#A9B6C7"
                        />
                      </IconWrapper>
                    </CPCTooltip>
                  </div>
                )
            )}
          <div className="question-add">
            <input
              type="text"
              placeholder="Nhập đáp án tiếp theo"
              onChange={(e) => {
                props.handleAddAnswer(
                  e.target.value,
                  props.indexParent,
                  props.i,
                  props.value.dsDapAn.length
                );
              }}
              value=""
              maxLength={500}
            />
          </div>
        </div>
      </div>
    )}
    <div className="question-footer">
      <div className="button-group">
        <div className="button-item add">
          <Button
            className="white"
            title="Thêm câu hỏi"
            icon="icon-plus-v2"
            clickButton={() => {
              props.handleAddQuestion(props.indexParent, props.i);
            }}
          />
        </div>
        <div className="button-item delete">
          <Button
            className="white"
            title="Xóa"
            icon="icon-delete"
            clickButton={() => {
              props.handleRemoveQuestion(props.indexParent, props.i);
            }}
          />
        </div>
      </div>
    </div>
  </li>
));

const SortableList = SortableContainer((props) => (
  <ul className="sortable">
    {props.items.dsCauHoi.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={value}
        i={index}
        handleChangeQuestion={props.handleChangeQuestion}
        handleChangeAnswer={props.handleChangeAnswer}
        indexParent={props.indexParent}
        handleRemoveQuestion={props.handleRemoveQuestion}
        handleRemoveAnswer={props.handleRemoveAnswer}
        handleAddQuestion={props.handleAddQuestion}
        handleAddAnswer={props.handleAddAnswer}
      />
    ))}
  </ul>
));

class FeedbackQuestionControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: null,
      tabIndex: 0,
      isSubmit: false,
      isLoading: false,
      isLoadingButton: false,
      isEmpty: false,
      isError: false,
      dsChatLuong: [],
      defaultValue: [],
      cauHinhId: null,
      ngayHieuLuc: null,
    };
  }

  componentDidMount() {
    this.getChiTietCauHinhGopY();
  }

  getChiTietCauHinhGopY = async () => {
    await this.setState({ isLoading: true });
    const response = await this.props.actions.getCauHinhGopY();
    if (!isEmpty(response.error)) {
      return this.setState({ isError: true, isLoading: false });
    }

    this.setState({
      defaultValue: JSON.parse(
        JSON.stringify(get(response, 'payload.data.result.dsChatLuong', []))
      ),
      dsChatLuong: get(response, 'payload.data.result.dsChatLuong', []),
      cauHinhId: get(response, 'payload.data.result.cauHinhId', null),
      ngayHieuLuc: get(response, 'payload.data.result.ngayHieuLuc', null),
      tabActive: get(response, 'payload.data.result.dsChatLuong[0].chatLuongId', null),
      isLoading: false,
    });
  };

  handleChooseTab = (key, index = 0) => {
    if (key === '') {
      return false;
    }

    this.setState({ tabActive: key, tabIndex: index });
  };

  showTabs = (index) => {
    if (isEmpty(this.state.dsChatLuong)) {
      return;
    }

    const newItems = this.state.dsChatLuong[index];

    if (isEmpty(newItems.dsCauHoi)) {
      this.handleAddQuestion(index, 0);
    }

    if (!isEmpty(newItems.dsCauHoi)) {
      return (
        <div>
          <SortableList
            items={newItems}
            onSortEnd={this.onSortEnd(index)}
            useDragHandle
            indexParent={index}
            handleChangeQuestion={this.handleChangeQuestion}
            handleChangeAnswer={this.handleChangeAnswer}
            handleRemoveQuestion={this.handleRemoveQuestion}
            handleRemoveAnswer={this.handleRemoveAnswer}
            handleAddQuestion={this.handleAddQuestion}
            handleAddAnswer={this.handleAddAnswer}
          />
        </div>
      );
    }
  };

  arrayMove = (array, oldIndex, newIndex) => {
    if (newIndex >= array.length) {
      newIndex = array.length - 1;
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  };

  onSortEnd =
    (index) =>
    ({ oldIndex, newIndex }) => {
      const newItems = this.state.dsChatLuong[index];

      const newData = this.arrayMove(newItems.dsCauHoi, oldIndex, newIndex);

      let { dsChatLuong } = this.state;

      dsChatLuong[index] = {
        ...dsChatLuong[index],
        dsCauHoi: newData,
      };

      this.setState({ dsChatLuong });
    };

  handleChangeQuestion = (value, indexParent, indexChildren) => {
    let { dsChatLuong } = this.state;
    dsChatLuong[indexParent].dsCauHoi[indexChildren].cauHoi = value;

    this.setState({ dsChatLuong });
  };

  handleChangeAnswer = (value, indexParent, indexChildren, key) => {
    let { dsChatLuong } = this.state;
    dsChatLuong[indexParent].dsCauHoi[indexChildren].dsDapAn[key].noiDungDapAn = value;
    delete dsChatLuong[indexParent].dsCauHoi[indexChildren].dsDapAn[key].autoFocus;

    this.setState({ dsChatLuong });
  };

  handleRemoveQuestion = (indexParent, indexChildren) => {
    let { dsChatLuong } = this.state;

    if (!dsChatLuong[indexParent].dsCauHoi[indexChildren].cauHoiId) {
      dsChatLuong[indexParent].dsCauHoi.splice(indexChildren, 1);
    } else {
      dsChatLuong[indexParent].dsCauHoi[indexChildren].isDeleted = true;
    }

    this.setState({ dsChatLuong });
  };

  handleRemoveAnswer = (indexParent, indexChildren, key) => {
    let { dsChatLuong } = this.state;

    if (!dsChatLuong[indexParent].dsCauHoi[indexChildren].dsDapAn[key].dapAnId) {
      dsChatLuong[indexParent].dsCauHoi[indexChildren].dsDapAn.splice(key, 1);
    } else {
      dsChatLuong[indexParent].dsCauHoi[indexChildren].dsDapAn[key].isDeleted = true;
    }

    this.setState({ dsChatLuong });
  };

  handleAddQuestion = (indexParent, indexChildren = 0) => {
    let { dsChatLuong } = this.state;
    if (!dsChatLuong[indexParent].dsCauHoi) {
      dsChatLuong[indexParent].dsCauHoi = [
        {
          cauHoiId: null,
          cauHoi: '',
          loaiCauHoi: 0,
          isDeleted: false,
          dsDapAn: [],
        },
      ];
      return this.setState({ dsChatLuong });
    }

    dsChatLuong[indexParent].dsCauHoi.splice(indexChildren + 1, 0, {
      cauHoiId: null,
      cauHoi: '',
      loaiCauHoi: 0,
      isDeleted: false,
      dsDapAn: [],
    });

    this.setState({ dsChatLuong });
  };

  handleAddAnswer = (value, indexParent, indexChildren, key) => {
    let { dsChatLuong } = this.state;

    dsChatLuong[indexParent].dsCauHoi[indexChildren].dsDapAn[key] = {
      dapAnId: null,
      noiDungDapAn: value,
      isDeleted: false,
      soNguoiTraLoi: 0,
      autoFocus: true,
    };

    this.setState({ dsChatLuong });
  };

  handleCancel = () => {
    this.setState({
      dsChatLuong: JSON.parse(JSON.stringify(this.state.defaultValue)),
    });
  };

  handleSave = async (e) => {
    e.preventDefault();

    await this.setState({ isSubmit: true, isLoadingButton: true });
    const { cauHinhId } = this.state;

    const newDataUpdate = {
      cauHinhId,
      ngayHieuLuc: this.state.ngayHieuLuc,
      dsChatLuong: this.state.dsChatLuong,
    };

    const newDataPost = {
      ngayHieuLuc: this.state.ngayHieuLuc,
      dsChatLuong: this.state.dsChatLuong,
    };

    let request = {};
    if (cauHinhId) {
      request = await this.props.actions.putCauHinhGopY(cauHinhId, decamelizeKeys(newDataUpdate));
    } else {
      request = await this.props.actions.postCauHinhGopY(decamelizeKeys(newDataPost));
    }

    if (request && !isEmpty(request.error)) {
      this.setState({ isSubmit: false, isLoadingButton: false });
      return this.props.actions.commonAddToasterMessage({
        message: variables.MESSAGE_STATUS.QUESTION_CONTROL_FAIL,
        intent: Intent.DANGER,
      });
    }

    if (!request) {
      this.resetState();
      return this.props.actions.commonAddToasterMessage({
        message: variables.MESSAGE_STATUS.QUESTION_CONTROL_FAIL,
        intent: Intent.DANGER,
      });
    }

    this.props.actions.commonAddToasterMessage({
      message: variables.MESSAGE_STATUS.QUESTION_CONTROL_SUCCESS,
      intent: Intent.SUCCESS,
    });
    this.handleGoBack();
  };

  resetState = async () => {
    await this.setState({
      isSubmit: false,
      isLoadingButton: false,
    });
  };

  handleGoBack = () => {
    return this.props.history.push('/hop-thu-gop-y');
  };

  handleOpenEoffice = () => {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  };

  render() {
    const { dsChatLuong, isError, isLoading, tabIndex, tabActive, isSubmit, isLoadingButton } =
      this.state;
    return (
      <LayoutMain {...this.props} history={this.props.history}>
        <Scrollbars className="section" style={{ height: '100vh' }}>
          <Heading title="Cấu hình góp ý" isGoback={true} handleGoBack={this.handleGoBack} />
          {isLoading && (
            <div className="cpc-preload">
              <div className="cpc-logo" />
              <div className="cpc-spinner" />
              <div className="cpc-spinner-2" />
              <div className="cpc-loading-text">Đang tải dữ liệu...</div>
            </div>
          )}
          {!isLoading && (
            <div className="cpc-container pb-65">
              {isError && (
                <div className="pt-table">
                  <div className="no-border-bt">
                    <div className="error-table-hover">
                      <div className="error-page-container">
                        <div className="error-content">
                          <img
                            src={imageUrl}
                            alt="search-result-not-found"
                            className="img-thumb-error"
                          />
                          <p className="description-note">
                            Có lỗi xảy ra trong quá trình tải dữ liệu
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isError && (
                <div>
                  <Tabs data={dsChatLuong} active={tabActive} onClick={this.handleChooseTab} />
                  {this.showTabs(tabIndex)}
                </div>
              )}
              <div className="cpc-form-footer">
                <div className="button-wrap">
                  <Button
                    className="btn-cancel"
                    title="Hủy"
                    disabled={isSubmit}
                    clickButton={this.handleGoBack}
                    style={{ minWidth: '90px', textTransform: 'uppercase' }}
                  />
                  <Button
                    loading={isLoadingButton}
                    className="btn-save"
                    title="Lưu"
                    disabled={isSubmit}
                    clickButton={this.handleSave}
                    style={{ minWidth: '90px', textTransform: 'uppercase' }}
                  />
                </div>
              </div>
            </div>
          )}
        </Scrollbars>
      </LayoutMain>
    );
  }
}

FeedbackQuestionControl.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackQuestionControl);
