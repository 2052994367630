export const getNameConversation = (conversation, auth) => {
  if (!conversation) {
    return '';
  }

  const { nhanVienHoiThoai = [], tenHoiThoai, isGroup } = conversation;

  if (isGroup === 2) {
    return 'Cloud của tôi';
  }

  if (!isGroup) {
    if (nhanVienHoiThoai?.length === 1) {
      return nhanVienHoiThoai[0]?.nhanVien?.tenNhanVien;
    }
    const newMembers = nhanVienHoiThoai?.filter(
      (item) => item?.nhanVien?.nhanVienId !== (auth.user?.nhanVienId || auth.user)
    );
    return newMembers?.[0]?.nhanVien?.tenNhanVien;
  }

  if (tenHoiThoai) return tenHoiThoai;

  if (nhanVienHoiThoai?.length === 1 && isGroup) {
    return `Nhóm: ${nhanVienHoiThoai?.[0]?.nhanVien?.tenNhanVien}`;
  }

  let members = nhanVienHoiThoai;
  if (nhanVienHoiThoai?.length > 3) {
    members = (nhanVienHoiThoai || [])?.filter(
      (item) => item?.nhanVien?.nhanVienId !== conversation?.nguoiTaoId
    );
  }

  if (members?.length === 1) {
    return members?.[0]?.nhanVien?.tenNhanVien;
  }

  const threeMembers = members.slice(0, 3);
  return (
    threeMembers.map((item) => item?.nhanVien?.tenNhanVien).join(', ') +
    (nhanVienHoiThoai?.length > 3 ? ' ...' : '')
  );
};

export const urlify = (text) => {
  const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi;
  text = text?.replace(urlRegex, function (url) {
    return `<a target="_blank" href="${url}">${url}</a>`;
  });

  return text;
};

export const decode = (str) => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");
};

export const getContent = (str = '') => {
  if (!str) return '';

  if (str.startsWith('[messageForwardJSON]')) {
    return str.split('[messageForwardJSON]')[str?.split('[messageForwardJSON]').length - 1];
  } else {
    return str;
  }
};

export const getForward = (str) => {
  if (str && str?.startsWith('[messageForwardJSON]')) {
    return str.split('[messageForwardJSON]')[1];
  } else {
    return '';
  }
};

export const htmlClear = (string) => {
  const regex = /(<([^>]+)>)/gi;
  const value = string.replace(regex, '');
  return value;
};

export const removeSystemDeleteName = (string) => {
  if (string && string.endsWith('_system_deleted')) {
    return string.replace('_system_deleted', '');
  }

  return string;
};

export const securityMessage = (content) => {
  if (!content) {
    return '';
  }

  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gm;
  let result = content?.trim();
  result = result.replace(regex, '');
  return result;
};

export const scrollInView = (id, isHightLight = true, classNameItem = 'message-item') => {
  const item = document.getElementById(id);
  const itemElm = document.getElementsByClassName(classNameItem);
  for (var i = 0; i < itemElm.length; i++) {
    if (itemElm?.[i] && itemElm[i]?.classList) {
      itemElm[i].classList.remove('hightLight');
    }
  }

  if (item && item.classList) {
    if (isHightLight) {
      item.classList.remove('hightLight');
      setTimeout(() => {
        item.classList.add('hightLight');
      }, 100);
    }

    item.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }
};

export const rgbToHex = (r, g, b) => {
  // Converting each component to hexadecimal and padding with zeros if necessary
  const hexR = ('0' + parseInt(r).toString(16)).slice(-2);
  const hexG = ('0' + parseInt(g).toString(16)).slice(-2);
  const hexB = ('0' + parseInt(b).toString(16)).slice(-2);

  const hexValue = `#${hexR}${hexG}${hexB}`;

  // Convert to uppercase for consistency
  return hexValue.toUpperCase();
};

export const rgbStyleToHex = (string) => {
  if (!string) {
    return '';
  }

  const rgbRegex = /rgb?\((\d+), (\d+)(?:, ([\d.]+))?\)/gm;
  return string?.replace(rgbRegex, function (value) {
    const [r, g, b] = value.match(/\d+/g);
    if (r && g && b) {
      return rgbToHex(parseInt(r, 10), parseInt(g, 10), parseInt(b, 10));
    } else {
      return value;
    }
  });
};

export const removeLastBlankLine = (content) => {
  if (!content) {
    return '';
  }

  const blankLinePatterns = [
    '<p><br></p>',
    '<p></p>',
    '<br>',
    '<div><br></div>',
    '<p><br></p><p><br></p>',
  ];
  let trimmedContent = content.trim();

  // Remove leading blank lines
  for (const pattern of blankLinePatterns) {
    if (trimmedContent.startsWith(pattern)) {
      trimmedContent = trimmedContent.slice(pattern.length).trim();
    } else {
      break; // Stop loop if no blank line is found at the beginning
    }
  }

  // Remove trailing blank lines
  for (const pattern of blankLinePatterns) {
    if (trimmedContent.endsWith(pattern)) {
      trimmedContent = trimmedContent.slice(0, trimmedContent.length - pattern.length).trim();
    } else {
      break; // Stop loop if no blank line is found at the end
    }
  }

  return trimmedContent;
};

export const quillDeltaToHtml = (delta) => {
  let result = '';
  delta.forEach((op) => {
    let valueTemp = '';
    if (op.insert) {
      if (op.insert.mention) {
        valueTemp = `<span class="mention" data-index="${op.insert.mention.index}" data-denotation-char="${op.insert.mention.denotationChar}" data-id="${op.insert.mention.id}" data-value="${op.insert.mention.value}"><span contenteditable="false">@${op.insert.mention.value}</span>.</span>`;
      } else if (typeof op.insert === 'string') {
        valueTemp = op.insert;
      }
    }

    let style = '';
    // Start with opening tags based on attributes
    if (op.attributes) {
      if (op.attributes?.color || op.attributes?.background) {
        let background = '';
        let color = '';
        if (op.attributes?.background) {
          background = `background-color: ${op.attributes?.background};`;
        }

        if (op.attributes?.color) {
          color += `color: ${op.attributes?.color};`;
        }

        style = ` style="${background}${color}"`;
      }

      if (op.attributes?.italic) {
        result += `<em${style}>`;
        style = '';
      }
      if (op.attributes?.bold) {
        result += `<b${style}>`;
        style = '';
      }
      if (op.attributes?.strike) {
        result += `<s${style}>`;
        style = '';
      }
      if (op.attributes?.underline) {
        result += `<u${style}>`;
        style = '';
      }

      if (style) {
        result += `<span${style}>${valueTemp}</span>`;
      } else {
        result += valueTemp;
      }

      if (op.attributes?.underline) {
        result += '</u>';
      }
      if (op.attributes?.strike) {
        result += '</s>';
      }
      if (op.attributes?.bold) {
        result += '</b>';
      }
      if (op.attributes?.italic) {
        result += '</em>';
      }
    } else {
      result += valueTemp;
    }
  });

  return result;
};

export const stringMentionRequest = (string) => {
  const updatedHTML = string.replace(
    /<span class="mention"(.*?)<\/span>.<\/span>/g,
    function (match, group) {
      // 'match' contains the entire matched string
      // 'group' contains the content captured by the parentheses in the regex
      var parser = new DOMParser();
      var doc = parser.parseFromString(match, 'text/html');

      // Get the span element
      var spanElement = doc.querySelector('span.mention');
      var dataId = spanElement.getAttribute('data-id');
      var dataValue = spanElement.getAttribute('data-value');
      const formattedValue = `<mention>[${dataValue}](${dataId})</mention>`;
      // Return the formatted replacement value
      return formattedValue;
    }
  );
  return updatedHTML;
};

export const stringMentionSetEditor = (string, hasClick = true) => {
  const updatedHTML = string.replace(
    /<mention>\[(.*?)\]\((.*?)\)<\/mention>/g,
    function (match, group) {
      const value = match.match(/<mention>\[(.*?)\]\((.*?)\)<\/mention>/);
      return `<span class="mention" data-index="1" data-denotation-char="@" data-id="${
        value[2]
      }" data-value="${value[1]}" ${
        hasClick ? `onclick="window.handleMentionClick('${value[2]}', '${value[1]}')"` : ''
      }><span contenteditable="false">@${value[1]}</span></span>`;
    }
  );
  return updatedHTML;
};

export const getMentionedIds = (string) => {
  const regexp = /<span class="mention"(.*?)<\/span>.<\/span>/g;
  const idArray = [];
  let match;
  while ((match = regexp.exec(string)) !== null) {
    const regexDataId = /data-id="([^"]+)"/g;
    match = regexDataId.exec(match?.[1]);
    idArray.push(match?.[1] || '');
  }

  return idArray;
};

export const stringMentionSetText = (string) => {
  const updatedHTML = string.replace(
    /<mention>\[(.*?)\]\((.*?)\)<\/mention>/g,
    function (match, group) {
      const value = match.match(/<mention>\[(.*?)\]\((.*?)\)<\/mention>/);
      return `@${value[1]}`;
    }
  );
  return updatedHTML;
};
