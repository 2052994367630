import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Actions from '../../../actions';
import { feedbackList } from '../../../components/hopthugopy/ListHeader';
import { Header } from '../../../components/layout';
import { Button, DataTable } from '../../../components/common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { variables } from '../../../constants/variables';
import _ from 'lodash';
import { ListItem, ListFilter, ListFooter } from '../../../components/hopthugopy';
import './../assets/common.scss';
import { saveData, getData, checkQuyenChucVu } from '../../../helpers/helper';
import { FE_URL } from '../../../constants/Api';

const keyButton = {
  GUI_GOP_Y: 'GUI_GOP_Y',
  CAU_HINH: 'CAU_HINH',
  THONG_KE: 'THONG_KE',
};

const getPreviousMonth = (currentDate) => {
  currentDate.setMonth(currentDate.getMonth() - 1);
  return currentDate;
};

const getNextDay = (currentDate) => {
  currentDate.setDate(currentDate.getDate());
  return currentDate;
};

class FeedbackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEmpty: false,
      isError: false,
      filter: {
        fromDate: getPreviousMonth(new Date()),
        toDate: getNextDay(new Date()),
      },
      page: 1,
    };
  }

  componentDidMount() {
    let state = this.state;
    let preStore = getData('FeedbackList');
    if (preStore) {
      state.filter = preStore.filter;
      state.page = preStore.page;
    } else {
      state.filter = {
        fromDate: getPreviousMonth(new Date()),
        toDate: getNextDay(new Date()),
      };
    }

    this.setState(state, () => {
      this.clickPagination({ selected: this.state.page - 1 });
    });
    this.props.actions.getFullUnits().then(async (res) => {
      await this.props.actions.contactFetchDepartmentsByUnit(this.props.auth.mainUnitId);
    });
    this.props.actions.feedbackGetPhienBan();
    this.props.actions.feedbackGetChatLuong();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props &&
      this.props.list &&
      this.props.list.total &&
      this.state.page > Math.ceil(this.props.list.total / variables.PAGINATION.PAGINATION_LIMIT)
    ) {
      this.setState(
        {
          page: Math.ceil(this.props.list.total / variables.PAGINATION.PAGINATION_LIMIT),
        },
        () => {
          this.clickPagination({ selected: this.state.page - 1 });
        }
      );
    }
  }

  clickPagination = (e) => {
    this.setState(
      {
        page: e.selected + 1,
      },
      () => {
        this.submitFilter(this.state.filter, this.state.page);
      }
    );
  };

  /**
   * Xóa text input filter trong data table
   * @param {object} e form attribute
   * @memberof FeedbackList
   */
  onRemoveText = () => {};

  handleFilter = (filter) => {
    if (!filter) {
      return false;
    }

    this.submitFilter(filter, 1);
  };

  submitFilter = (e = {}, page = 1) => {
    this.setState(
      {
        filter: e,
        page,
        isLoading: true,
      },
      () => {
        let { filter } = this.state;
        saveData('FeedbackList', { filter, page });
        filter = {
          chat_luong_id:
            _.get(filter, 'chatLuongId') && _.get(filter, 'chatLuongId') !== '0'
              ? filter.chatLuongId
              : undefined,
          phien_ban_id:
            _.get(filter, 'phienBanId') && _.get(filter, 'phienBanId') !== '0'
              ? filter.phienBanId
              : undefined,
          noi_dung_tim_kiem: _.get(filter, 'textSearch') || undefined,
          nguoi_gui: _.get(filter, 'nguoiGui') || undefined,
          from_date: _.get(filter, 'fromDate')
            ? this.getDateCover(filter.fromDate, 'FROM')
            : undefined,
          to_date: _.get(filter, 'toDate') ? this.getDateCover(filter.toDate, 'TO') : undefined,
          don_vi_id:
            _.get(filter, 'donViId') && _.get(filter, 'donViId') !== 'All'
              ? _.get(filter, 'donViId')
              : undefined,
        };
        this.props.actions
          .feedbackGetList({ filter }, page)
          .then((res) => {
            let isEmpty = false;
            let isError = false;
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true;
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true;
            }

            this.setState({ isEmpty, isError });
          })
          .then(() => {
            this.setState({ isLoading: false });
          });
      }
    );
  };

  getDateCover = (dateValue, timeline) => {
    if (!timeline || !dateValue) {
      return undefined;
    }

    let dataDate = new Date(dateValue);
    let dateResult = new Date();
    if (timeline.toUpperCase() === 'FROM') {
      dateResult = new Date(
        dataDate.getUTCFullYear(),
        dataDate.getUTCMonth(),
        dataDate.getUTCDate(),
        0,
        0,
        0
      );
    } else {
      dateResult = new Date(
        dataDate.getUTCFullYear(),
        dataDate.getUTCMonth(),
        dataDate.getUTCDate(),
        23,
        59,
        59
      );
    }

    return dateResult.toISOString();
  };

  handleConfirm = () => {
    return this.props.history.push('/');
  };

  handleButton = (key) => {
    switch (key) {
      case keyButton.GUI_GOP_Y:
        return this.props.history.push('/hop-thu-gop-y/gui-gop-y');
      case keyButton.CAU_HINH:
        return this.props.history.push('/hop-thu-gop-y/tao-cau-hoi');
      case keyButton.THONG_KE:
        return this.props.history.push('/hop-thu-gop-y/thong-ke');
      default:
        return false;
    }
  };

  handleOpenEoffice = () => {
    const { auth } = this.props;
    const url =
      FE_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`;
    window.open(url, 'eoffice');
  };

  render() {
    const { list, phienBan, chatLuong } = this.props;
    const { isLoading, isEmpty, isError, filter } = this.state;
    return (
      <div className="cpc-container-wrapper">
        <Header
          actions={this.props.actions}
          auth={this.props.auth}
          onClickSearch={this.toggleSearchMessage}
          onOpenEoffice={this.handleOpenEoffice}
          onOpenConfig={this.handleToggleConfig}
          hideSearch={true}
        />
        <div className="cpc-container">
          <div className="cpc-heading-page">
            <div className="cpc-heading-page-col">
              <Button
                title="Quay lại"
                className="go-back"
                icon="icon-arrow"
                disabled={false}
                clickButton={this.handleConfirm}
              />
              <div className="title-icon">
                <span className="icon-mailbox1601" />
                <h3 className="title">Danh sách góp ý</h3>
              </div>
            </div>
            <div className="cpc-heading-page-col">
              <div className="heading-extra">
                <div className="heading-extra-col">
                  <Button
                    title="Thống kê"
                    className="extra-button green"
                    icon="icon-bieu-do-tang1601"
                    disabled={false}
                    clickButton={() => this.handleButton(keyButton.THONG_KE)}
                  />
                </div>
                {checkQuyenChucVu(this.props.auth, ['Superadmin']) && (
                  <div className="heading-extra-col">
                    <Button
                      title="Cấu hình"
                      className="extra-button green"
                      icon="icon-bieu-do-bien-dong1601"
                      disabled={false}
                      clickButton={() => this.handleButton(keyButton.CAU_HINH)}
                    />
                  </div>
                )}
                <div className="heading-extra-col">
                  <Button
                    title="Gửi góp ý"
                    className="extra-button"
                    icon="icon-plus"
                    disabled={false}
                    clickButton={() => this.handleButton(keyButton.GUI_GOP_Y)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-wrapper">
            <ListFilter
              filter={filter}
              handleFilter={this.handleFilter}
              unit={this.props.contact.units}
              phienBan={phienBan}
              chatLuong={chatLuong}
              auth={this.props.auth}
            />
          </div>
          <DataTable
            data={list.items}
            isLoading={isLoading}
            classNames={'table middle'}
            page={list.page}
            headers={feedbackList}
            history={this.props.history}
            itemShape={ListItem}
            onFilter={this.handleSort}
            isEmpty={isEmpty}
            isError={isError}
            readOnly={this.props.readOnly}
            onChange={this.onChangeDataTable}
            onChangeDate={this.onChangeDateDataTable}
            onChangeSelect={this.onChangeSelectDataTable}
            onRemoveText={this.onRemoveText}
            onHandleSubmitUpdateItem={this.handleSubmitUpdateItem}
            filterColumn={this.state.filterColumn}
            auth={this.props.auth}
            onSubmit={this.submitFilter}
            pageCount={Math.ceil(list.total / variables.PAGINATION.PAGINATION_LIMIT)}
          />
          {!isEmpty && !isError && (
            <ListFooter
              forcePage={this.state.page - 1}
              pageCount={Math.ceil(this.props.list.total / variables.PAGINATION.PAGINATION_LIMIT)}
              onPageClick={this.clickPagination}
            />
          )}
        </div>
      </div>
    );
  }
}

FeedbackList.defaultProps = {
  auth: {},
};

FeedbackList.propTypes = {
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  contact: {
    ...state.contact,
    units: (() => {
      let { units } = state.contact;

      if (
        state.entities.units[state.auth.mainUnitId] &&
        units.indexOf(state.auth.mainUnitId) === -1
      ) {
        units.push(state.auth.mainUnitId);
      }

      return units.map((id) => {
        const unit = state.entities.units[id];
        if (!unit.dsPhongBan) {
          unit.dsPhongBan = [];
        }

        const getUnit = (data) => {
          if (!data || !_.isArray(data.dsDonViCon)) {
            return [];
          }

          return data.dsDonViCon.map((id) => {
            const unit = state.entities.units[id];
            return {
              ...unit,
              dsDonViCon: getUnit(unit),
              dsPhongBan: unit.dsPhongBan
                ? unit.dsPhongBan.map((id) => {
                    const department = state.entities.departments[id];
                    return {
                      ...department,
                      dsNhanVien: department.dsNhanVien
                        .map((id) => state.entities.users[id])
                        .filter((user) => {
                          if (state.contact.filterOnline) {
                            return user.trucTuyen === 1;
                          }

                          return true;
                        }),
                    };
                  })
                : [],
            };
          });
        };

        return {
          ...unit,
          dsPhongBan: unit.dsPhongBan.map((id) => {
            const department = state.entities.departments[id];
            return {
              ...department,
              dsNhanVien: department.dsNhanVien
                .map((id) => state.entities.users[id])
                .filter((user) => {
                  if (state.contact.filterOnline) {
                    return user.trucTuyen === 1;
                  }

                  return true;
                }),
            };
          }),
          dsDonViCon: getUnit(unit),
        };
      });
    })(),
  },
  auth: {
    ...state.auth,
    user: state.entities.users[state.auth.user],
  },
  list: state.feedback.list,
  phienBan: state.feedback.phienBan,
  chatLuong: state.feedback.chatLuong,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackList);
