import React from 'react';

const NotFoundQuestion = ({ history, message }) => (
  <div className="container-fluid wrapper-survey wrapper-page-notdata">
    <button type="button" className="pt-button cpc-button-back" onClick={(e) => history.goBack()}>
      <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
      <span className="button-content">Quay lại</span>
    </button>
    <div className="cpc-survey-container container-page-notdata">
      <div className="content-padding">
        <span className="icon-not-data"></span>
        <h2 className="message-notdata">{message || 'Hiện tại không có nội dung này'}</h2>
      </div>
    </div>
  </div>
);

export default NotFoundQuestion;
