import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import CPCLoadingWare from '@/components/atoms/loading/ware';
import NameConversation from '@/components/molecules/name-conversation';
import AvatarConversation from '@/components/molecules/avatar-conversation';
import CPCFlex from '@/components/atoms/flex';
import CPCCheckbox from '@/components/atoms/checkbox';
import CPCNotFound from '@/components/atoms/not-found';
import './conversation-list.scss';

const ConversationList = ({
  dataSource = [],
  dataChecked = [],
  isLoadingTree = false,
  isLoading = false,
  onScroll = () => {},
  onCheck = () => {},
}) => {
  if (isLoadingTree) {
    return <CPCLoadingWare ware={3} />;
  }

  if (dataSource?.length === 0) {
    return <CPCNotFound theme="1" />;
  }

  const handleChecked = (conversation) => {
    if (!dataChecked || dataChecked?.length === 0) {
      return false;
    }

    return dataChecked?.some((e) => e.hoiThoaiId === conversation?.hoiThoaiId);
  };

  const handleCheck = (isChecked, conversation) => {
    onCheck(isChecked, conversation);
  };

  const handleScroll = (scroll) => {
    onScroll(scroll);
  };

  return (
    <Scrollbars
      autoHeight
      autoHeightMin={290}
      autoHeightMax={290}
      renderView={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            paddingLeft: '1px',
            paddingRight: '10px',
          }}
        />
      )}
      onScrollFrame={handleScroll}
    >
      {dataSource?.map((conversation, index) => (
        <CPCFlex
          key={index}
          align={'center'}
          className={classNames('ph-5 conversation-list-item', {
            'border-top': index !== 0,
          })}
        >
          <CPCCheckbox
            size={'xs'}
            checked={handleChecked(conversation)}
            onChange={(e) => handleCheck(e?.target?.checked, conversation)}
          />
          <CPCFlex key={index} gap={10} align={'center'}>
            <AvatarConversation
              anhDaiDien={conversation?.anhDaiDien}
              tongSoNhanVien={conversation?.tongSoNhanVien}
              nhanVienHoiThoai={conversation?.nhanVienHoiThoai}
              isGroup={conversation?.isGroup}
            />
            <NameConversation data={conversation} />
          </CPCFlex>
        </CPCFlex>
      ))}
      {isLoading && <CPCLoadingWare ware={3} />}
    </Scrollbars>
  );
};

export default ConversationList;
