import React from 'react';
import { Button } from '@blueprintjs/core';
import { CircleSpinner } from 'react-spinners-kit';
import './index.scss';

function CPCButton(props) {
  const { className, children, loading, loadingProps = {}, ...rest } = props;
  return (
    <Button className={`cpc-button ${className ?? ''}`} {...rest}>
      {loading ? (
        <CircleSpinner
          size={loadingProps.size || 14}
          className={`cpc-button-loading ${loadingProps.className || ''}`}
          color={loadingProps.color || '#959595'}
        />
      ) : null}
      {children}
    </Button>
  );
}

export default CPCButton;
