import React, { Component } from 'react';

export default class Image extends Component {
  state = {};
  componentDidMount() {
    // var tester=new Image();
    // tester.onload = () => this.setState({isError: false});
    // tester.onerror = () => this.setState({isError: true});;
    // tester.src = this.props.src;
  }
  render() {
    return (
      <div>
        {this.state.isError === undefined && (
          <img
            className={this.props.className}
            src={`${process.env.PUBLIC_URL}/images/loading.gif`}
            alt="Loading..."
          />
        )}
        <img
          {...this.props}
          style={this.state.isError === undefined ? { display: 'none' } : {}}
          alt=""
          onLoad={() => this.state.isError === undefined && this.setState({ isError: false })}
          onError={() => this.state.isError === undefined && this.setState({ isError: true })}
          src={
            this.state.isError === true
              ? `${process.env.PUBLIC_URL}/images/not-found-image.svg`
              : this.props.src
          }
        />
      </div>
    );
  }
}
