import { AUTH_DISCARD_TOKEN, PRELOAD_SUCCESS } from '../constants/ActionTypes';

const initialState = {
  success: false,
};

export default function preload(state = initialState, action) {
  switch (action.type) {
    case AUTH_DISCARD_TOKEN:
      return {
        success: false,
      };

    case PRELOAD_SUCCESS:
      return {
        ...state,
        success: true,
      };

    default:
      return state;
  }
}
