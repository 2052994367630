export const variables = {
  DATE: {
    FORMAT: 'DD/MM/YYYY',
    YEAR_MONTH_DAY: 'YYYY/MM/DD',
    TIME_DATE: 'HH:mm DD/MM/YYYY',
  },
  LOAD_MORE: {
    CONVERSATION_LIMIT: 20,
    MESSAGE_LIMIT: 10,
  },
  FEEDBACK_QUALITY: {
    GOOD: 'GOOD',
    NORMAL: 'NORMAL',
    NOTGOOD: 'NOTGOOD',
  },
  MESSAGE_TABLE: {
    MESSAGE_NOT_FOUND: 'Không có dữ liệu trong hệ thống',
    MESSAGE_ERROR: 'Có lỗi xảy ra trong quá trình tải dữ liệu',
    MESSAGE_REQUIRED_STATISTICS: 'Anh/Chị chưa chọn khoảng thời gian thống kê',
  },
  // Default pagination
  PAGINATION: {
    PAGINATION_LIMIT: 10,
    PAGE: 1,
    LIMIT_ZERO: 0,
    PAGINATION_LIMIT_MORE: 20,
    PAGINATION_LIMIT_MAX: 30,
  },
  // Form
  FORM: {
    MAX_SIZE_FILE_5MB: 1024 * 1024 * 5,
    MAX_SIZE_FILE_10MB: 1024 * 1024 * 10,
    MAX_SIZE_FILE: 1024 * 1024 * 25,
  },
  MESSAGE_STATUS: {
    MESSAGE_NOT_FOUND: 'Không có dữ liệu trong hệ thống',
    TOATS_MESSAGE_SUCCESS: 'Lưu thành công',
    TOATS_MESSAGE_FAIL: 'Lưu không thành công',
    TOATS_MESSAGE_DELETE_SUCCESS: 'Xóa thành công',
    TOATS_MESSAGE_DELETE_FAIL: 'Xóa thất bại',
    QUESTION_CONTROL_FAIL: 'Cập nhật cấu hình không thành công!',
    QUESTION_CONTROL_SUCCESS: 'Cập nhật cấu hình thành công!',
    TOATS_MESSAGE_CONNECT: 'Kết nối thành công',
    TOATS_MESSAGE_ERROR: 'Có lỗi xảy ra, vui lòng thử lại',
  },
  TRANGTHAIXULY: {
    CHO_TIEP_NHAN: 'CHO_TIEP_NHAN',
    DA_TIEP_NHAN: 'DA_TIEP_NHAN',
    SE_HIEU_CHINH: 'SE_HIEU_CHINH',
    KHONG_HIEU_CHINH: 'KHONG_HIEU_CHINH',
  },
  CHAT_LUONG: {
    TOT: 'TOT',
    BINH_THUONG: 'BINH_THUONG',
    CHUA_TOT: 'CHUA_TOT',
  },
  VALIDATE: {
    FILE_TYPE_ALLOW: (allowTypeArray) =>
      `Hệ thống hỗ trợ định dạng file ${allowTypeArray.join(', ')}`,
    FILE_TYPE_NOT_ALLOW: (notAllowTypeArray) =>
      `Hệ thống không hỗ trợ định dạng file ${notAllowTypeArray.join(', ')}`,
    FILE_SIZE_ALLOW: (sizeAllow) => `Dung lượng upload file vượt quá ${sizeAllow}MB`,
    LIMIT_USERS_CONVERSATION: (limit) => `Không vượt quá ${limit} thành viên trong 1 hội thoại`,
    INPUT_REQUIRE: 'Hãy nhập các trường bắt buộc!',
    GROUP_NAME_REQUIRED: 'Bạn không được bỏ trống tên nhóm',
  },
  ACTION_STATUS: {
    GOP_Y_SUCCESS: 'Góp ý thành công',
  },
  ERROR: {
    INPUT_REQUIRE: 'Bạn không được bỏ trống trường này.',
  },
  TOAST: {
    SUCCESS: (action) => `${action} thành công`,
    FAIL: (action) => `${action} thất bại`,
    INCOMPLETE: 'Hãy hoàn tất biểu mẫu',
    NOT_FOUND: (info) => `Không tìm thấy ${info}`,
  },
  MIN_SCROLL_BAR_DISTANCE: 250,
  CONVERSATION_TYPE: {
    ALL: null,
    GROUP: 1,
    PERSONAL: 0,
    CLOUD: 2,
    IMPORTANT: 3,
  },
  EDITOR: {
    PLACEHOLDER: 'Nhập nội dung tin nhắn...',
    PLACEHOLDER_ENTER: 'Nhập nội dung... (Ctrl+Enter: gửi tin, Enter: xuống dòng)',
    CONTENT: 'Nhập nội dung...',
  },
  SURVEY_PERMISSION: {
    ANSWER: 1,
    FOLLOW: 2,
    READ: 3,
  },
  API: {
    FILE_CHAT_GET: 'api/filechat/get',
  },
  BIRTHDAY_CONFIG_TYPE: {
    TAT_CA: 0,
    DON_VI: 1,
    DANH_SACH: 2,
    TAT_THONG_BA0: 3,
  },
  ONE_MB: 1048576,
  CONVERSATION_MAIN_TAB: {
    ALL: 'ALL',
    UNREAD: 'UNREAD',
  },
};

export default variables;
