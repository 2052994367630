import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Actions from '@/actions';
import Sidebar from '../sidebar';
import './index.scss';

function LayoutMain({ children, history, ...props }) {
  return (
    <section className="cpc-main">
      <Sidebar {...props} history={history} />
      {children}
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(null, mapDispatchToProps)(LayoutMain);
