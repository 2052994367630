import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Icon extends Component {
  click = () => {
    this.props && this.props.click();
  };

  render() {
    const { classIcon, className } = this.props;
    return (
      <span
        className={classnames({
          [classIcon]: classIcon,
          [className]: className,
        })}
        onClick={this.click}
      />
    );
  }
}

Icon.defaultProps = {
  classIcon: '',
  className: '',
  click: () => {},
};

Icon.propTypes = {
  classIcon: PropTypes.string,
  className: PropTypes.string,
  click: PropTypes.func,
};

export default Icon;
