import CPCButton from '@/components/atoms/button';
import { Config } from '@/components/contact';
import { Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';

function PopupSetting(props) {
  const { open, handleClose, actions, auth, handleSaveConfig, units } = props;

  return (
    <Dialog isOpen={open} onClose={handleClose} className="popup-config" title="Cài đặt">
      <DialogBody>
        <Config
          actions={actions}
          auth={auth}
          config={auth.config}
          onSubmit={handleSaveConfig}
          units={units}
        />
      </DialogBody>
    </Dialog>
  );
}

export default PopupSetting;
