import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Emojione } from 'react-emoji-render';
class TagEmoticon extends Component {
  renderView = ({ style, ...props }) => {
    const viewStyle = {
      padding: 15,
      color: `#000`,
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  renderThumb = ({ style }) => {
    const thumbStyle = {
      backgroundColor: `rgba(0,0,0,0.02)`,
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  };

  render() {
    const { emoticon, count, isShowCount, className } = this.props;
    return (
      <div className={className}>
        <span className={`${className}__image`}>
          {/* <Emojione text={emoticon} /> */}
          {emoticon && <Emojione>{emoticon}</Emojione>}
        </span>
        {isShowCount && <span className={`${className}__count`}>{count}</span>}
      </div>
    );
  }
}

TagEmoticon.defaultProps = {
  emoticon: null,
  count: 0,
  isShowCount: false,
  className: 'tag-emoticon-small',
};

TagEmoticon.propTypes = {
  emoticon: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isShowCount: PropTypes.bool,
  className: PropTypes.string,
};

export default TagEmoticon;
