import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import _ from 'lodash';
import { Button, Radio, RadioGroup } from '@blueprintjs/core';
import './assets/document-cabinet.scss';

export default class DocumentCabinet extends Component {
  static propTypes = {
    onChangeType: PropTypes.func,
    permissions: PropTypes.array,
  };

  static defaultProps = {
    permissions: [],
  };

  state = {
    toggle: {},
    type: '3',
    form: {
      dsNganTu: [],
      tenTaiLieu: '',
      ghiChu: '',
    },
  };

  componentDidMount() {
    this.props.onChangeType && this.props.onChangeType(this.state.type);
  }

  handleCabinetType = (e) => {
    this.setState(
      {
        dsNganTu: [],
        type: e.target.value,
      },
      () => {
        this.props.onChangeType && this.props.onChangeType(this.state.type);
      }
    );
  };

  toggleCategory = (id, e) => {
    e.preventDefault();

    this.setState({
      toggle: {
        ...this.state.toggle,
        [id]: !this.state.toggle[id],
      },
    });
  };

  handleCheck = (e) => {
    let { dsNganTu } = this.state.form;

    if (e.target.checked) {
      dsNganTu.push(e.target.name);
    } else {
      if (dsNganTu.indexOf(e.target.name) >= 0) {
        delete dsNganTu[dsNganTu.indexOf(e.target.name)];
      }
    }

    this.setState({
      form: {
        ...this.state.form,
        dsNganTu: _.compact(dsNganTu),
      },
    });
  };

  handleInputChange = (e) => {
    const { value, name } = e.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  sendData = () => {
    let { type, form } = this.state;

    this.props.onSubmit && this.props.onSubmit(type, form);
  };

  renderSubs = (data) => {
    return (
      <ul className="cpc-department-contact-list">
        {data.map((item, k) => (
          <li className="cpc-department-item" key={k}>
            <div className="cpc-item">
              <label className="pt-control pt-checkbox">
                <input type="checkbox" name={item.id} onChange={this.handleCheck} />
                <span className="pt-control-indicator" />
              </label>
              <div className="m-content">
                <p className="cpc-unit-info">{item.tenNganTu}</p>
              </div>
              {item.dsNganTuCon && (
                <button
                  className="btn-collapse-control"
                  onClick={this.toggleCategory.bind(this, item.id)}
                >
                  {this.state.toggle[item.id] ? (
                    <span className="pt-icon pt-icon-chevron-up" />
                  ) : (
                    <span className="pt-icon pt-icon-chevron-down" />
                  )}
                </button>
              )}
            </div>
            {this.state.toggle[item.id] && item.dsNganTuCon && this.renderSubs(item.dsNganTuCon)}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    let { categories, files } = this.props;

    if (files.length > 0) {
      files = [files[0]];
    }

    return (
      <div className="cpc-save-document">
        <div className="heading-content">
          <h2 className="title-heading">
            <span className="left-icon icon-save-document"></span>
            <span className="text">Lưu tủ tài liệu</span>
            <button className="cpc-close" onClick={this.props.onClose}>
              <span className="pt-icon pt-icon-cross" />
            </button>
          </h2>
        </div>
        <div className="save-document-form-container">
          <form className="cpc-save-document-form">
            <ul className="cpc-department-section">
              {categories.map((item, k) => (
                <li className="cpc-department-item" key={k}>
                  <div className="cpc-item">
                    <label className="pt-control pt-checkbox">
                      <input type="checkbox" name={item.id} onChange={this.handleCheck} />
                      <span className="pt-control-indicator" />
                    </label>
                    <div className="m-content">
                      <p className="cpc-unit-info">{item.tenNganTu}</p>
                    </div>
                    {item.dsNganTuCon && (
                      <button
                        className="btn-collapse-control"
                        onClick={this.toggleCategory.bind(this, item.id)}
                      >
                        {this.state.toggle[item.id] ? (
                          <span className="pt-icon pt-icon-chevron-up" />
                        ) : (
                          <span className="pt-icon pt-icon-chevron-down" />
                        )}
                      </button>
                    )}
                  </div>
                  {this.state.toggle[item.id] &&
                    item.dsNganTuCon &&
                    this.renderSubs(item.dsNganTuCon)}
                </li>
              ))}
            </ul>
            <div className="main-form-content">
              <div className="cpc-form-group">
                <label className="label-content">Nội dung tin nhắn</label>
                <textarea
                  className="pt-fill
                  pt-textarea"
                  name="tenTaiLieu"
                  rows="3"
                  placeholder="Nội dung…"
                  dir="auto"
                  value={this.state.form.tenTaiLieu}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="cpc-form-group">
                <label className="label-content">Ghi chú</label>
                <input
                  className="pt-fill"
                  type="text"
                  placeholder="Nhập nội dung"
                  name="ghiChu"
                  value={this.state.form.ghiChu}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="group-col-content">
                <div className="cpc-cols-50 cols-left-content">
                  <label className="label-content">File đính kèm</label>
                  {files.map((file, k) => (
                    <div className="file-show" title={file.tenFile} key={k}>
                      <span className="image-content">
                        {file.kieuFile === 'application/pdf' ? (
                          <img alt="" src={process.env.PUBLIC_URL + '/images/pdf.png'} />
                        ) : file.kieuFile ===
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          file.kieuFile === 'application/msword' ? (
                          <img alt="" src={process.env.PUBLIC_URL + '/images/doc.png'} />
                        ) : file.kieuFile ===
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                          file.kieuFile === 'application/vnd.ms-excel' ? (
                          <img alt="" src={process.env.PUBLIC_URL + '/images/xls.png'} />
                        ) : (
                          <img alt="" src={process.env.PUBLIC_URL + '/images/file.png'} />
                        )}
                      </span>
                      <p className="file-info">
                        <span className="text">{file.tenFile}</span>
                        <span className="note">
                          {bytes(file.kichThuoc, { unitSeparator: ' ' })}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
                <div className="cpc-cols-50 cols-right-content">
                  <label className="label-content">Lưu vào</label>
                  <div className="pt-radio-content">
                    <RadioGroup
                      className="radio-group"
                      name="loaiNganTu"
                      onChange={this.handleCabinetType}
                      selectedValue={this.state.type}
                    >
                      {/* <Radio
                        className="item-radio"
                        label="Tủ đơn vị"
                        disabled={permissions.indexOf("TUTAILIEUDONVI_QUANLY") === -1}
                        value="1"
                      />
                      <Radio
                        className="item-radio"
                        label="Tủ phòng ban"
                        disabled={permissions.indexOf("TUTAILIEUPHONGBAN") === -1}
                        value="2"
                      /> */}
                      <Radio className="item-radio" label="Tủ cá nhân" value="3" />
                    </RadioGroup>
                  </div>
                </div>
              </div>
              <div className="cpc-form-group">
                <Button
                  className="pt-button btn-save"
                  onClick={this.sendData}
                  loading={this.props.isLoading}
                >
                  Lưu tủ tài liệu
                  <span className="right-icon icon-save-document"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
