import { connect } from 'react-redux';
import { Position } from '@blueprintjs/core';

import CPCTooltip from '@/components/atoms/tooltip';
import { getNameConversation } from '@/helpers/chat';
import './index.scss';

const NameConversation = ({ auth, data }) => {
  return (
    <CPCTooltip
      className={'name-conversation'}
      theme="white"
      position={Position.BOTTOM}
      content={<div>{getNameConversation(data, auth)}</div>}
    >
      <p className="text-name m-0">{getNameConversation(data, auth)}</p>
    </CPCTooltip>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: { ...state.auth, user: state.entities.users[state.auth.user] },
  };
};

export default connect(mapStateToProps, null)(NameConversation);
