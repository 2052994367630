import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import preload from './preload';
import auth from './auth';
import contact from './contact';
import chat from './chat';
import entities from './entities';
import cabinet from './cabinet';
import surveys from './surveys';
import feedback from './feedback';
import emoticon from './emoticon';
import conversation from './conversation';
import configuration from './configuration';
import work from './work';
import other from './other';

const rootReducer = combineReducers({
  preload,
  entities,
  auth,
  contact,
  chat,
  routing,
  cabinet,
  surveys,
  feedback,
  emoticon,
  conversation,
  configuration,
  work,
  other,
});

export default rootReducer;
