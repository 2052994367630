import {
  API_REQUEST_SEND,
  API_REQUEST_ERROR,
  UPDATE_CONVERSATION,
  LEAVE_CONVERSATION,
  UPDATE_CONVERSATION_INFO,
  ADD_USERS_CONVERSATION,
  DELETE_USER_CONVERATION,
  GET_LIST_PIN_MESSAGES,
  GET_LIST_IMPORTANT_MESSAGES,
  GET_ATTACHED_FILES_CONVERATION,
  GET_CONVERATION_USERS_SEARCH,
  GET_CONVERATION_USERS_NOT_APPROVED_SEARCH,
  DELETE_CONVERSATION_GENERAL,
  GET_ATTACHED_IMAGES,
  GET_ATTACHED_DOCUMENTS,
  SAVE_ADD_TOP_CHAT,
  GET_FAVOURITE_CONTACTS,
  API_REQUEST_SUCCESS,
  SAVE_CURRENT_CONVERSATION_CHAT,
  SAVE_CURRENT_CONVERSATION_DEFAULT,
} from '@/constants/ActionTypes';

export const createGroup = (data) => (dispatch, getState) => {
  if (!getState().chat?.connectionId) {
    return;
  }

  return dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/hoithoai',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params: {
          connectionId: getState().chat ? getState().chat.connectionId : null,
        },
        data,
      },
    },
  });
};

export const createAddTopChat =
  (data, reduxSave = 0) =>
  (dispatch, getState) => {
    if (!getState().chat?.connectionId) {
      return;
    }

    let type = API_REQUEST_SUCCESS;
    if (reduxSave === 1) {
      type = SAVE_ADD_TOP_CHAT;
    }

    return dispatch({
      types: [API_REQUEST_SEND, type, API_REQUEST_ERROR],
      payload: {
        request: {
          url: '/api/hoithoaicanhan',
          method: 'POST',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            connectionId: getState().chat ? getState().chat.connectionId : null,
          },
          data,
        },
      },
    });
  };

export const createTopChat = (data) => (dispatch) => {
  return dispatch({
    type: SAVE_ADD_TOP_CHAT,
    payload: {
      data: {
        result: data,
      },
    },
  });
};

export const setCurrentConversationChat = (hoiThoai) => (dispatch, getState) =>
  dispatch({
    type: SAVE_CURRENT_CONVERSATION_CHAT,
    payload: {
      hoiThoai,
      typeConversation: getState().chat?.typeConversation,
    },
  });

export const setCurrentConversationDefault = () => (dispatch, getState) =>
  dispatch({
    type: SAVE_CURRENT_CONVERSATION_DEFAULT,
    payload: {
      auth: getState().auth,
    },
  });

export const removeAddTopChat = () => (dispatch) => {
  return dispatch({ type: SAVE_ADD_TOP_CHAT, payload: null });
};

export const getConversationUsers = (hoiThoaiId) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, ADD_USERS_CONVERSATION, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const updateGroup = (data) => (dispatch, getState) => {
  if (!getState().chat?.connectionId) {
    return;
  }

  return dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/hoithoai',
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params: {
          connectionId: getState().chat ? getState().chat.connectionId : null,
        },
        data,
      },
    },
  });
};

export const setUpdateConversation = (payload) => (dispatch) => {
  return dispatch({ type: UPDATE_CONVERSATION, payload });
};

export const setConversationPersonalLeave = (payload) => (dispatch) => {
  return dispatch({ type: LEAVE_CONVERSATION, payload });
};

export const setConversationDelete = (payload) => (dispatch) => {
  return dispatch({ type: DELETE_CONVERSATION_GENERAL, payload });
};

export const setConversationInfoUpdate = (payload) => (dispatch) => {
  return dispatch({ type: UPDATE_CONVERSATION_INFO, payload });
};

export const setConversationDeleteUser = (payload) => (dispatch) => {
  return dispatch({ type: DELETE_USER_CONVERATION, payload });
};

export const getPinMessage = (params) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, GET_LIST_PIN_MESSAGES, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/tinnhan/ghim',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params,
      },
    },
  });

export const getImportantMessage = (params) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, GET_LIST_IMPORTANT_MESSAGES, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params,
      },
    },
  });

export const getConversationAttachedFilesLimit = (hoiThoaiId, params) => (dispatch, getState) => {
  const types = params?.type === 1 ? GET_ATTACHED_DOCUMENTS : GET_ATTACHED_IMAGES;
  return dispatch({
    types: [API_REQUEST_SEND, types, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}/filedinhkem`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params,
      },
    },
  });
};

export const getConversationAttachedFiles = (hoiThoaiId, params) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, GET_ATTACHED_FILES_CONVERATION, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}/filedinhkem`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params,
      },
    },
  });

export const getConversationUsersSearch = (hoiThoaiId, params) => (dispatch, getState) => {
  let newParams = { ...params };
  let typeRedux = GET_CONVERATION_USERS_NOT_APPROVED_SEARCH;
  if (params?.isDaDuyet) {
    typeRedux = GET_CONVERATION_USERS_SEARCH;
  }

  if (params?.notRedux) {
    typeRedux = API_REQUEST_SUCCESS;
    delete newParams.notRedux;
  }

  return dispatch({
    types: [API_REQUEST_SEND, typeRedux, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}/nhanvien/timkiem`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params: newParams,
      },
    },
  });
};

export const getFavouriteContacts =
  (searchText = null, reduxSave = true) =>
  (dispatch, getState) =>
    dispatch({
      types: [
        API_REQUEST_SEND,
        ...(reduxSave ? [GET_FAVOURITE_CONTACTS] : [API_REQUEST_SUCCESS]),
        API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          url: '/api/nhomlienlac',
          method: 'GET',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            searchText,
          },
        },
      },
    });

export const getConversationOfFavouriteContact =
  ({ nhomLienLacId }) =>
  (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          url: '/api/hoithoai/nhomlienlac',
          method: 'POST',
          headers: {
            Authorization:
              getState().auth && getState().auth.token
                ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
                : '',
          },
          params: {
            nhomLienLacId,
          },
        },
      },
    });

export const deleteUserFavouriteContacts = (groupId, userId) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}/nhanVien/${userId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const updateNameFavouriteContacts = (groupId, newName) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}`,
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data: {
          nhom_lien_lac_id: groupId,
          ten_nhom_lien_lac: newName,
        },
      },
    },
  });

export const deleteFavouriteContacts = (groupId) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/nhomlienlac/${groupId}`,
        method: 'DELETE',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const getConversationAllUsers = (hoiThoaiId, params) => (dispatch, getState) => {
  return dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/hoithoai/${hoiThoaiId}/nhanvien/timkiem`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params,
      },
    },
  });
};
