import { useEffect, useState } from 'react';
import size from 'lodash/size';
import CPCLoadingWare from '@/components/atoms/loading/ware';
import CPCNotFound from '@/components/atoms/not-found';
import OrganizationItem from './item';
import CPCCheckbox from '@/components/atoms/checkbox';
import './index.scss';

const Organization = ({
  history,
  hasCheckAll = false,
  dataSource,
  isLoading = false,
  loading,
  userConfig = null,
  hasCheckbox = false,
  dataChecked = [],
  dataDisable = [],
  dataToggle,
  onToggle,
  onChecked,
  onCheckedAll = () => {},
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isIndeterminate, setIndeterminate] = useState(false);

  const getUsers = (data, result = []) => {
    let newData = [...result];
    if (data?.dsNhanVien && data?.dsNhanVien?.length !== 0) {
      newData = [...newData, ...data?.dsNhanVien];
    }

    if (data?.children && data?.children?.length !== 0) {
      data?.children.forEach((element) => {
        newData = getUsers(element, newData);
      });
    }

    return newData;
  };

  useEffect(() => {
    if (
      hasCheckAll &&
      dataChecked &&
      dataSource &&
      size(dataChecked) !== 0 &&
      size(dataSource) !== 0
    ) {
      let result = [];
      if (dataSource?.[0]?.phongBanId && dataSource?.[0]?.maPhongBan) {
        dataSource?.forEach((data) => {
          const childUsers = getUsers(data);
          result = [...result, ...childUsers];
        });
      } else {
        result = dataSource;
      }
      const checkCurrent = result?.filter((e) =>
        dataChecked?.some((check) => check.nhanVienId === e.nhanVienId)
      );
      setIndeterminate(size(checkCurrent) !== 0);
      setIsCheckAll(size(checkCurrent) === size(result) ? true : false);
    } else {
      setIndeterminate(false);
      setIsCheckAll(false);
    }
  }, [hasCheckAll, dataChecked, dataSource, getUsers]);

  if (!isLoading && dataSource?.length === 0) {
    return <CPCNotFound theme="1" />;
  }

  return (
    <div>
      {hasCheckAll && (
        <div className="border-bottom pt-5 pb-10">
          <CPCCheckbox
            label="Chọn/hủy tất cả"
            size={'xs'}
            className="check-all"
            checked={isCheckAll}
            disabled={false}
            indeterminate={!isCheckAll && isIndeterminate}
            onChange={(e) => onCheckedAll(isIndeterminate ? false : e?.target?.checked, dataSource)}
          />
        </div>
      )}
      {dataSource?.map((elm, index) => (
        <OrganizationItem
          key={index}
          userConfig={{
            isHiddenInfo: false,
            isHiddenAction: false,
            isHiddenOrganization: false,
            avatarSize: 'md',
            ...(userConfig ? userConfig : {}),
          }}
          hasCheckbox={hasCheckbox}
          history={history}
          data={elm}
          loading={loading}
          dataChecked={dataChecked}
          dataDisable={dataDisable}
          dataToggle={dataToggle}
          onToggle={onToggle}
          onChecked={onChecked}
        />
      ))}
      {isLoading ? <CPCLoadingWare ware={3} /> : null}
    </div>
  );
};

export default Organization;
