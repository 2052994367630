import React from 'react';
import { InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';

import './index.scss';

function CPCInput(props) {
  const { className, theme, full, ...rest } = props;

  return (
    <InputGroup
      className={classNames('cpc-input', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
        full: full,
      })}
      {...rest}
      autoComplete="off"
    />
  );
}

export default CPCInput;
