import { SET_IS_LOGIN_HAS_BEEN_CHANGED } from '@/constants/ActionTypes';

const initialState = {
  isLoginHasBeenChanged: false,
};

export default function other(state = initialState, action) {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_IS_LOGIN_HAS_BEEN_CHANGED:
      return {
        ...state,
        isLoginHasBeenChanged: payload,
      };
    default:
      return state;
  }
}
