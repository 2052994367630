import CPCIcon from '@/components/atoms/icon';
import FormGroup from '@/components/molecules/form-group';
import IconWrapper from '@/components/molecules/icon-wrapper';
import COLORS from '@/constants/colors';
import './index.scss';
import CPCTooltip from '@/components/atoms/tooltip';
import { Position } from '@blueprintjs/core';

function Search({ handleChangeFilter, handleResetFilter, onSearch, state }) {
  const handleSearchEnter = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };
  return (
    <section className="survey-search">
      <div className="search-body">
        <FormGroup
          label="Từ ngày"
          labelFor="=Từ ngày"
          kind="date"
          inputProps={{
            selected: state.tuNgay,
            dateFormat: 'dd/MM/yyyy',
            onChange: (date) => handleChangeFilter('tuNgay', date),
            name: 'tuNgay',
            maxDate: state.denNgay || undefined,
            className: 'theme-3',
          }}
        />
        <FormGroup
          label="Đến ngày"
          labelFor="Đến ngày"
          kind="date"
          inputProps={{
            selected: state.denNgay,
            dateFormat: 'dd/MM/yyyy',
            onChange: (date) => handleChangeFilter('denNgay', date),
            name: 'denNgay',
            minDate: state.tuNgay || undefined,
            className: 'theme-3',
          }}
        />
        <FormGroup
          label="Từ khóa"
          className="search"
          inputProps={{
            value: state.tieuDe,
            name: 'tieuDe',
            placeholder: 'Từ khóa tìm kiếm',
            onChange: (e) => handleChangeFilter('tieuDe', e.target.value),
            className: 'theme-5',
            onKeyDown: handleSearchEnter,
          }}
        />
        <CPCTooltip className={'mt-10'} theme="white" position={Position.TOP} content="Tìm kiếm">
          <IconWrapper className="icon" color={COLORS.blue} onClick={() => onSearch()}>
            <CPCIcon icon="search" color="#fff" size={15} />
          </IconWrapper>
        </CPCTooltip>
        <CPCTooltip className={'mt-10'} theme="white" content="Làm mới" position={Position.TOP}>
          <IconWrapper
            className="icon"
            color={COLORS.border}
            onClick={async () => {
              await handleResetFilter();
              onSearch();
            }}
          >
            <CPCIcon icon="load" size={15} />
          </IconWrapper>
        </CPCTooltip>
      </div>
    </section>
  );
}

export default Search;
