import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Image from './Image';
import classnames from 'classnames';
import { IMAGE_URL } from '../../constants/Api';

export default class AvatarOnline extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    className: PropTypes.string,
    disableStatus: PropTypes.bool,
  };

  static defaultProps = {
    disableStatus: false,
    className: '',
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
      return false;
    }

    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    return (
      <div
        className={classnames(this.props.className, {
          online: this.props.user.trucTuyen === 1,
        })}
        style={{ backgroundImage: `url(${IMAGE_URL}${this.props.user.anhDaiDien})` }}
      >
        <Image actions={this.props.actions} auth={this.props.auth} user={this.props.user} />
        {!this.props.disableStatus && (
          <div className="cpc-icon-online">
            <span className="pt-icon-full-circle" />
          </div>
        )}
      </div>
    );
  }
}
