import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import shallowCompare from 'react-addons-shallow-compare';
import { getLinkImage } from '../../helpers/image';

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
      return false;
    }

    return shallowCompare(this, nextProps, nextState);
  }

  handleClick = () => {
    this.props.actions.contactShowProfile(this.props.user.nhanVienId);
  };

  render() {
    return (
      <img
        alt="avatar"
        onClick={this.handleClick}
        className="cpc-avatar"
        src={getLinkImage(this.props.user.anhDaiDien)}
      />
    );
  }
}

Image.propTypes = {
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};
