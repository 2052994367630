import classNames from 'classnames';
import './index.scss';

const CPCNotFound = ({
  image = process.env.PUBLIC_URL + '/images/search-result-not-found.png',
  text = 'Không có dữ liệu trong hệ thống',
  theme,
  className,
}) => {
  return (
    <div
      className={classNames('cpc-not-found', {
        [`theme-${theme}`]: theme,
        [`${className}`]: className,
      })}
    >
      <img src={image} alt={text} className="image" />
      {text ? <p className="text">{text}</p> : ''}
    </div>
  );
};

export default CPCNotFound;
