import React, { useMemo, useState } from 'react';
import { getLinkImage } from '@/helpers/image';
import InfoAccount from '@/components/molecules/info-account';

import './index.scss';

function CPCAvatar(props) {
  const {
    className,
    src,
    size = 'sm',
    trucTuyen = 0,
    userInfo,
    actions,
    history,
    onClick,
    ...rest
  } = props;
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [dataProfile, setDataProfile] = useState(null);

  const onInfoDisplay = () => {
    if (onClick) {
      return onClick();
    }

    if (!actions || !userInfo?.nhanVienId) {
      return;
    }

    if (dataProfile?.nhanVienId !== userInfo?.nhanVienId) {
      actions.getUserInfoV2(userInfo?.nhanVienId, false).then((res) => {
        if (res?.payload?.data?.result) {
          setDataProfile(res?.payload?.data?.result);
        }
      });
    }

    setIsOpenProfile(true);
  };

  const getClassName = useMemo(() => {
    return `cpc-avatar ${size || ''} ${className || ''} ${trucTuyen ? 'online' : ''}`;
  }, [trucTuyen, size, className]);

  return (
    <>
      <div
        className={`${getClassName}`}
        style={{ backgroundImage: `url(${getLinkImage(src)})` }}
        {...rest}
        onClick={onInfoDisplay}
      />
      {isOpenProfile && (
        <InfoAccount
          history={history}
          open={isOpenProfile}
          data={dataProfile}
          handleClose={() => setIsOpenProfile(false)}
        />
      )}
    </>
  );
}

export default CPCAvatar;
