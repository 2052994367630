import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import Search from './Search';
import Department from './Department';
import PanelSearch from './PanelSearch';
import Unit from './Unit';
import { Scrollbars } from 'react-custom-scrollbars';
import { Intent, Position, Toaster } from '@blueprintjs/core';

export default class PanelUnit extends Component {
  static propTypes = {
    contact: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getMainUnit = this.getMainUnit.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentWillMount() {
    this.handleClearSearch();
    if (!this.state.loaded) {
      this.props.actions.getFullUnits().then(async (res) => {
        await this.props.actions.contactFetchDepartmentsByUnit(this.props.auth.mainUnitId);
        this.setState({ loaded: true });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search && this.state.search.trim() === '') {
      this.search();
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  state = {
    search: '',
    submitting: false,
    notFound: false,
    loaded: this.getMainUnit() || false,
  };

  handleSearch = () => {
    this.search();
  };
  handleClearSearch = () => {
    this.setState({ search: '' }, () => {
      this.search();
    });
  };

  search = async (page = 1) => {
    if (this.state.submitting) {
      return;
    }

    await this.setState({ submitting: true, notFound: false });
    if (this.state.search.trim()) {
      return this.props.actions
        .contactSearchByUnit(this.props.auth.mainUnitId, this.state.search.trim(), page)
        .then((res) => {
          this.setState({ submitting: false });

          if (res.error) {
            this.toaster.show({
              message: 'Có lỗi xảy ra, vui lòng thử lại',
              intent: Intent.DANGER,
            });
            return;
          }

          if (!res.payload.data.result.result.items.length) {
            this.setState({ notFound: true });
          }
        });
    } else {
      this.setState({ submitting: false });
      return this.props.actions.contactClearSearchByUnit();
    }
  };

  showSearch = () => {
    return this.state.search.trim() !== '';
  };

  getMainUnit() {
    return this.props.contact.units.find((unit) => unit.donViId === this.props.auth.mainUnitId);
  }

  render() {
    const mainUnit = this.getMainUnit();
    return (
      <div>
        <PanelSearch
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          actions={this.props.actions}
          auth={this.props.auth}
          units={this.props.units}
          contact={this.props.contact}
          receivers={this.props.receivers}
        />
        <div
          className="cpc-contact-list-container"
          style={{ top: this.props.isSurvey ? '124px' : null }}
        >
          <Scrollbars
            style={{ width: '100%', height: '100%' }}
            renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
          >
            {!this.state.loaded || this.state.submitting ? (
              <div className="spinner">
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
            ) : null}
            <div
              className="cpc-search-result"
              style={{ display: this.state.loaded && this.showSearch() ? 'block' : 'none' }}
            >
              {this.showSearch() &&
              (this.state.notFound ||
                (!this.state.submitting && this.props.contact.searchUnit.items.length === 0)) ? (
                <div className="cpc-not-found">Không tìm thấy kết quả</div>
              ) : null}

              {this.showSearch() && !this.state.notFound ? (
                <Search
                  result={this.props.contact.searchUnit}
                  auth={this.props.auth}
                  receivers={this.props.receivers}
                  actions={this.props.actions}
                  onLoadMore={this.search}
                />
              ) : null}
            </div>
            <div style={{ display: this.state.loaded && !this.showSearch() ? 'block' : 'none' }}>
              {mainUnit ? (
                <Department
                  auth={this.props.auth}
                  actions={this.props.actions}
                  unit={mainUnit}
                  filterOnline={this.props.contact.filterOnline}
                  receivers={this.props.receivers}
                />
              ) : null}

              {mainUnit && mainUnit.dsDonViCon && (
                <Unit
                  auth={this.props.auth}
                  actions={this.props.actions}
                  units={mainUnit.dsDonViCon}
                  exclude={this.props.auth.mainUnitId}
                  filterOnline={this.props.contact.filterOnline}
                  receivers={this.props.receivers}
                  viewUnit={this.props.viewUnit}
                  onClick={this.props.onClickUnit}
                />
              )}
            </div>
          </Scrollbars>
        </div>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}
