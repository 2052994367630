import { useState, useRef, useEffect } from 'react';

import Editor from '@/components/molecules/chat-input/editor';
import IconWrapper from '@/components/molecules/icon-wrapper';
import CPCIcon from '@/components/atoms/icon';
import Emoji from '@/components/molecules/chat-input/emoji';
import COLORS from '@/constants/colors';
import variables from '@/constants/variables';
import CPCFlex from '@/components/atoms/flex';
import CPCTooltip from '@/components/atoms/tooltip';
import './index.scss';
import Upload from '../chat-input/file-actions/upload';
import { connect } from 'react-redux';
import * as Actions from '@/actions';
import { bindActionCreators } from 'redux';
import Files from '../chat-input/files';
import useUpload from '@/containers/pages/home/components/hooks/use-upload';

const CPCEditor = ({
  readOnly = false,
  placeholder = variables.EDITOR.PLACEHOLDER,
  content,
  emojiProps,
  fileList = [],
  actions,
  auth,
  handleChangeFiles,
  className,
  isHiddenUpload = false,
  onChange = () => {},
  onKeySubmit = () => {},
}) => {
  const refEditor = useRef(null);
  const [indexSelection, setIndexSelection] = useState(0);
  const [zoom, setZoom] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(false);
  const [firstTimeSetZoom, setFirstTimeSetZoom] = useState(false);

  const onSetHeightMessage = () => {
    // force re-render to set height of messages
    setOffsetHeight(!offsetHeight);
  };

  const { uploader, uploadedFiles, handleUploadCancel, uploadingProgress, setUploadedFiles } =
    useUpload({
      onSetHeightMessage,
      actions,
    });

  useEffect(() => {
    if (refEditor?.current?.editor?.getLines()?.length >= 3 && !zoom && !firstTimeSetZoom) {
      setZoom(true);
      setFirstTimeSetZoom(true);
    }
  }, [refEditor?.current?.editor?.getLines()]);

  useEffect(() => {
    if (fileList?.length !== 0) {
      setUploadedFiles(
        fileList?.map((file) => ({
          id: file?.fileId,
          name: file?.tenFile,
          info: {
            ...file,
            id: file?.fileId,
            name: file?.tenFile,
            size: file?.kichThuoc,
            mime: file?.kieuFile,
            type: file?.kieuFile,
          },
        }))
      );
    }
  }, [fileList]);

  useEffect(() => {
    handleChangeFiles?.(uploadedFiles);
  }, [uploadedFiles]);

  const getSelection = (range) => {
    if (range) {
      if (range.length == 0) {
        // User cursor is at index
        return range.index;
      } else {
        var text = refEditor.current.editor.getText(range.index, range.length);
        // User has highlighted
        return text.length;
      }
    }
  };

  const onEditorChangeSelection = (range) => {
    if (range) {
      setIndexSelection(getSelection(range));
    }
  };

  const handleEditorChooseEmo = (icon) => {
    refEditor.current.editor.insertEmbed(indexSelection, 'text', icon + ' ');
    setIndexSelection(indexSelection + (icon + ' ').length);
  };

  return (
    <div className={`cpc-editor chat-input-custom ${className}`}>
      <CPCFlex align={'center'} justify={'space-between'} gap="10" className={'chat-input__top'}>
        <div className={'chat-input-top'}>
          {!isHiddenUpload && <Upload uploader={uploader} />}
          <IconWrapper colorHover={COLORS.hover} size="sm-1">
            <Emoji
              portalClassName={emojiProps?.portalClassName}
              handleChooseEmo={handleEditorChooseEmo}
            />
          </IconWrapper>
        </div>
        <div>
          <CPCTooltip
            content={zoom ? 'Thu gọn' : 'Mở rộng'}
            theme="white"
            openOnTargetFocus={false}
          >
            <IconWrapper size="xs-1" onClick={() => setZoom((prev) => !prev)}>
              <CPCIcon icon={zoom ? 'zoom-out' : 'extend'} color="#7589A3" />
            </IconWrapper>
          </CPCTooltip>
        </div>
      </CPCFlex>
      <div className="cpc-editor__editor editor-without-action">
        <Editor
          isZoom={zoom}
          isShowControl={true}
          refEditor={refEditor}
          onKeySubmit={onKeySubmit}
          content={content}
          onChange={(value) => onChange(value)}
          readOnly={readOnly || false}
          placeholder={variables.EDITOR.PLACEHOLDER}
          onChangeSelection={onEditorChangeSelection}
        />
        {(uploadedFiles?.length > 0 || uploadingProgress?.length > 0) && (
          <div className="p-5" style={{ borderTop: '1px solid #e5e5e5' }}>
            <Files {...{ uploadedFiles, handleUploadCancel, auth, uploadingProgress, uploader }} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const isEnter = state?.auth?.config?.phimTatWebSend !== 'true';
  return {
    isEnter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CPCEditor);
