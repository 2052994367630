import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactItem from './ContactItem';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/search.scss';

export default class Search extends Component {
  static propTypes = {
    onLoadMore: PropTypes.func,
    auth: PropTypes.object,
    result: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onLoadMore: () => {},
    auth: null,
  };

  constructor(props) {
    super(props);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    loading: false,
  };

  handleLoadMore = (e) => {
    this.props.onLoadMore(this.props.result.page + 1);
  };

  handleScroll = async (values) => {
    if (
      !this.props.result ||
      this.props.result.itemts ||
      this.props.result.items.length >= this.props.result.total
    ) {
      return;
    }

    if (values.top >= 1 && !this.state.loading) {
      await this.setState({ loading: true });
      await this.props.onLoadMore(this.props.result.page + 1);
      await this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        onScrollFrame={this.handleScroll}
        renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
      >
        {this.props.result.items && (
          <div className="cpc-search-section">
            {this.props.result.items.map((user, i) => (
              <ContactItem
                key={i}
                user={user}
                actions={this.props.actions}
                receivers={this.props.receivers}
                showFullInfo={true}
                auth={this.props.auth}
              />
            ))}
            {(this.state.loading || this.props.result.items.length < this.props.result.total) &&
            this.props.result.items.length >= 10 ? (
              <div className="spinner">
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
            ) : null}
          </div>
        )}
      </Scrollbars>
    );
  }
}
