import { useEffect, useState } from 'react';
import CPCIcon from '@/components/atoms/icon';
import FullLoading from '@/components/molecules/full-loading';
import COLORS from '@/constants/colors';
import variables from '@/constants/variables';
import { Intent } from '@blueprintjs/core';
import classnames from 'classnames';
import moment from 'moment';
import Footer from '../footer';
import Actions from './actions';
import './index.scss';
import Remove from './remove';
import useOpen from '@/hooks/useOpen';
import { SignalRHub } from '@/middleware/signalr';
import Share from '@/components/templates/chat-body/information/share';

const Table = (props) => {
  const [state, setState] = useState({
    isOpenPopup: false,
    isLoading: false,
    phieuKhaoSatId: null,
    surveyData: undefined,
  });
  const { open, handleClose, handleOpen } = useOpen();
  const [signalRHub, setSignalR] = useState();

  const handleClickPopup = (id) => {
    setState({
      ...state,
      isOpenPopup: !state.isOpenPopup,
      phieuKhaoSatId: id,
    });
  };

  const handleClickRemove = () => {
    let total = props.total;
    let page = props.page;

    setState({
      ...state,
      isLoading: true,
    });
    if (state.phieuKhaoSatId) {
      props.actions.deleteSurveyListItem(state.phieuKhaoSatId).then((res) => {
        if (res.error) {
          setState({
            ...state,
            isOpenPopup: !state.isOpenPopup,
            isLoading: false,
          });
          return props.actions.commonAddToasterMessage({
            message: 'Xóa không thành công',
            intent: Intent.DANGER,
          });
        }

        if (total > 0) {
          total -= 1;
        }
        if (page > Math.ceil(total / 10)) {
          page = Math.ceil(total / 10);
        }

        if (page === 0) {
          page = 1;
        }

        setState({
          ...state,
          isOpenPopup: !state.isOpenPopup,
          isLoading: false,
        });
        props.actions.commonAddToasterMessage({
          message: 'Xóa thành công',
          intent: Intent.SUCCESS,
        });
        props.onSubmit(page);
      });
    }
  };

  const handleClickClosePopup = () => {
    setState({
      ...state,
      isOpenPopup: !state.isOpenPopup,
    });
  };

  const handleClickEdit = (id) => {
    if (id) {
      return props.history.push(`/phieu-khao-sat/chinh-sua/${id}`);
    }
  };

  const handleStatistical = (id) => {
    return props.history.push(`/phieu-khao-sat/thong-ke/${id}`);
  };

  const handleClickAdd = (id) => {
    props.actions.getSurveyItemV2(id)?.then((value) => {
      const result = value?.payload?.data?.result;
      if (result) {
        setState({ surveyData: result });
      }
    });
    handleOpen();
  };

  const handleCopyPKS = (id) => {
    return props.history.push({
      pathname: '/phieu-khao-sat/them-moi',
      state: { pksId: id },
    });
  };

  const connectSignalRHub = () => {
    try {
      signalRHub
        .start()
        .done(() => {
          props.actions.chatSetConnectionId(signalRHub?.connection?.id);
          props.actions.commonRemoveToasterMessage();
          if (process.env.NODE_ENV !== 'production') {
            console.log('Now connected, connection ID=' + signalRHub.connection.id);
          }

          props.actions.commonReconnectSignalR(false);
          props.actions.setReconnectingStatus(false);
        })
        .fail(() => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Could not connect');
          }
        });
    } catch (err) {
      console.log('Error connect SignalR', err);
    }
  };

  useEffect(() => {
    if (signalRHub) {
      connectSignalRHub();
    }

    return () => {
      try {
        signalRHub && signalRHub.stop();
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(error);
        }
      }
    };
  }, [signalRHub]);

  useEffect(() => {
    setSignalR(SignalRHub(props.auth, props.actions));
  }, []);

  let imageUrl = process.env.PUBLIC_URL + '/images/search-result-not-found.png';
  if (props.isError) {
    imageUrl = process.env.PUBLIC_URL + '/images/Server.png';
  }

  return (
    <div className="table-container">
      {!props.isEmpty && !props.isError && (
        <table className="pt-table">
          <thead className="heading-table">
            <tr>
              <th className="col-stt">STT</th>
              <th className="col-stt"></th>
              <th className="col-title">Tiêu đề</th>
              <th className="col-datetime">Ngày tạo</th>
              <th className="col-datetime">Ngày hết hạn</th>
              <th className="col-action"></th>
            </tr>
          </thead>
          <tbody className="body-table">
            {!props.isEmpty &&
              !props.isError &&
              !props.isLoading &&
              props.items.map((item = {}, i) => {
                return (
                  <tr
                    className={classnames({
                      'status-expired': item?.daQuaHan,
                    })}
                    key={i}
                  >
                    <td>
                      {(props.forcePage * variables.PAGINATION.PAGINATION_LIMIT + i + 1)
                        .toString()
                        ?.padStart(2, '0')}
                    </td>
                    <td>
                      <div className="div-center sended">
                        {item?.daGui && (
                          <CPCIcon icon="email" color={COLORS.blue} size={25} cursor="default" />
                        )}
                      </div>
                    </td>
                    <td>
                      <span
                        className="text-custom-cursor word-break-break-word"
                        onClick={handleStatistical.bind(this, item.phieuKhaoSatId)}
                      >
                        {item.tieuDe?.startsWith('[PĐT]')
                          ? item.tieuDe?.replace('[PĐT]', '')
                          : item.tieuDe}
                      </span>
                    </td>
                    <td>{item.ngayTao && moment(item.ngayTao).format('DD/MM/YYYY - H:mm')}</td>
                    <td
                      className={classnames({
                        expired: moment().diff(moment(item.ngayHetHan)) > 0,
                      })}
                    >
                      {item.ngayHetHan && moment(item.ngayHetHan).format('DD/MM/YYYY - H:mm')}
                    </td>
                    <td>
                      <Actions
                        {...{
                          ...item,
                          handleClickEdit,
                          handleClickAdd,
                          handleCopyPKS,
                          handleClickPopup,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {(props.isError || props.isEmpty) && (
        <table className="pt-table">
          <thead className="heading-table">
            <tr>
              <th className="col-stt">STT</th>
              <th className="col-stt"></th>
              <th className="col-title">Tiêu đề</th>
              <th className="col-datetime">Ngày tạo</th>
              <th className="col-datetime">Ngày hết hạn</th>
              <th className="col-action"></th>
            </tr>
          </thead>
          <tbody className="body-table">
            <tr className="no-border-bt">
              <td colSpan={6} className="error-table-hover">
                <div className="error-page-container div-center">
                  <div className="error-content div-center flex-column">
                    <img src={imageUrl} alt="search-result-not-found" className="img-thumb-error" />
                    <p className="description-note">
                      {props.isError === true
                        ? 'Có lỗi xảy ra trong quá trình tải dữ liệu'
                        : 'Không có dữ liệu trong hệ thống'}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {props.isLoading && (
        <div className="mt-30">
          <FullLoading />
        </div>
      )}

      <Remove
        {...{
          isOpenPopup: state.isOpenPopup,
          handleClickPopup,
          handleClickClosePopup,
          handleClickRemove,
          isLoading: state.isLoading,
        }}
      />

      {!props.isLoading && props.pageCount > 1 && (
        <Footer
          forcePage={props.forcePage}
          pageCount={props.pageCount}
          onPageClick={props.onPageClick}
          isEmpty={props.isEmpty || props.isError}
        />
      )}

      {open && (
        <Share
          title="Gửi phiếu khảo sát"
          isOpen={open}
          isEditorDisplay={true}
          signalRHub={signalRHub}
          actionProps={{
            type: 'SURVEY',
            data: {
              phieuKhaoSat: [state.surveyData],
              conversations: props?.conversationId ? [props?.conversationId] : [],
            },
          }}
          dataFiles={[]}
          history={props.history}
          onClose={handleClose}
          searchParamKhaoSat={props.searchParamKhaoSat}
        />
      )}
    </div>
  );
};

export default Table;
