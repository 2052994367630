import {
  API_REQUEST_SEND,
  API_REQUEST_ERROR,
  GET_MESSAGES_IMPORTANT,
  SET_LAST_MESSAGE,
  SET_NEW_MESSAGE,
  API_REQUEST_SUCCESS,
} from '@/constants/ActionTypes';

export const getMessageImportant = (params) => (dispatch, getState) => {
  const newParams = { ...params };
  delete newParams.hoiThoaiId;

  return dispatch({
    types: [API_REQUEST_SEND, GET_MESSAGES_IMPORTANT, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/quantrong/hoithoai/${params?.hoiThoaiId}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
          'api-version': '1.0',
        },
        params: newParams,
      },
    },
  });
};

export const setIsMessageLatest = (status) => ({
  type: SET_LAST_MESSAGE,
  payload: status,
});

export const setIsMessageNewest = (status) => ({
  type: SET_NEW_MESSAGE,
  payload: status,
});

export const getInfoMessage = (tinNhanId) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/${tinNhanId}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
      },
    },
  });

export const putMessage = (data) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: '/api/tinnhan',
        method: 'PUT',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        data,
      },
    },
  });

export const getCheckStatusMessage = (tinNhanId, type) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/tinnhan/${tinNhanId}/exits`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth && getState().auth.token
              ? `${getState().auth.token.tokenType} ${getState().auth.token.accessToken}`
              : '',
        },
        params: {
          type,
        },
      },
    },
  });
