import { getDatabase, ref, onValue, off } from 'firebase/database';
import firebaseApp from './firebase';

let dataRef;

export const setupDataListener = (path = '/', callback) => {
  const db = getDatabase(firebaseApp);
  dataRef = ref(db, path);

  onValue(
    dataRef,
    (snapshot) => {
      const data = snapshot.val();
      callback(data);
    },
    (error) => {
      console.error('Error fetching data:', error);
    }
  );
};

export const stopDataListener = () => {
  if (dataRef) {
    off(dataRef);
  }
};
