import React, { Component } from 'react';
import classNames from 'classnames';

class LoadingRow extends Component {
  render() {
    const { width, className } = this.props;
    return (
      <div
        className={classNames('loading-row', className)}
        style={{ width: width ? width : '100%' }}
      />
    );
  }
}

export default LoadingRow;
