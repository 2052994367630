import React, { useMemo } from 'react';
import classNames from 'classnames';

import pdf from '@/assets/images/icons/pdf.svg';
import ppt from '@/assets/images/icons/ppt.svg';
import doc from '@/assets/images/icons/doc.svg';
import xls from '@/assets/images/icons/xls.svg';
import video from '@/assets/images/icons/video.svg';
import zip from '@/assets/images/icons/zip.svg';
import image from '@/assets/images/icons/image.svg';
import sound from '@/assets/images/icons/sound.svg';
import imageV2 from '@/assets/images/icons/image-v2.svg';
import './index.scss';

const CPCFile = ({
  className,
  name,
  size,
  kind,
  url,
  imageTheme = null,
  maxWidth = null,
  onClick = () => {},
}) => {
  const onOpenLink = () => {
    window.open(url, '_blank', 'noreferrer');
  };
  const icon = useMemo(() => {
    switch (kind?.toUpperCase()) {
      case 'PDF':
      case 'APPLICATION/PDF':
      case '.PDF':
        return <img src={pdf} />;
      case '.PPT':
      case '.PPTX':
      case 'PPT':
      case 'PPTX':
      case 'APPLICATION/VND.MS-POWERPOINT':
      case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION':
        return <img src={ppt} />;
      case 'XLS':
      case 'XLSX':
      case 'APPLICATION/VND.MS-EXCEL':
      case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
      case '.XLSX':
        return <img src={xls} />;
      case 'DOC':
      case 'DOCX':
      case 'APPLICATION/MSWORD':
      case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT':
        return <img src={doc} />;
      case 'VIDEO/MP4':
      case 'VIDEO/QUICKTIME':
        return <img src={video} />;
      case 'APPLICATION/X-ZIP-COMPRESSED':
      case 'APPLICATION/OCTET-STREAM':
        return <img src={zip} />;
      case 'AUDIO/WAV':
      case 'AUDIO/MPEG':
      case 'AUDIO/MP4':
        return <img src={sound} />;
      case 'IMAGE/PNG':
      case 'IMAGE/JPEG':
      case 'IMAGE/JPG':
      case 'IMAGE/PJPEG':
      case 'IMAGE/GIF':
      case 'IMAGE/BMP':
      case 'IMAGE/X-WINDOWS-BMP':
        if (imageTheme === 1) return <img src={image} />;
        return <img src={imageV2} />;
      default:
        return false;
    }
  }, [kind, imageTheme]);

  return (
    <div
      className={classNames('cpc-file', {
        [`${className}`]: className,
        pointer: url,
      })}
      onClick={url ? onOpenLink : onClick}
    >
      {icon ? <div className="cpc-file__icon">{icon}</div> : ''}
      <div className="cpc-file__info">
        <span
          className={classNames('name', {
            nowrap: maxWidth,
          })}
          style={{ maxWidth }}
          title={name}
        >
          {name}
        </span>
        {size ? <span className="size">{size}</span> : ''}
      </div>
    </div>
  );
};

export default CPCFile;
