import {
  AUTH_DISCARD_TOKEN,
  CONTACT_FETCH_UNITS,
  CONTACT_FETCH_FAVOURITE_GROUPS,
  CONTACT_SEARCH_BY_UNIT,
  CONTACT_SEARCH_ALL,
  CONTACT_SEARCH_FAVOURITE,
  CONTACT_CLEAR_SEARCH_BY_UNIT,
  CONTACT_CLEAR_SEARCH_ALL,
  CONTACT_CLEAR_SEARCH_FAVOURITE,
  CONTACT_SHOW_PROFILE,
  CONTACT_CHANGE_STATUS_FILTER,
  CONTACT_VIEW_UNIT,
  CONTACT_CLEAR_VIEW_UNIT,
  GET_FAVOURITE_CONTACTS,
} from '../constants/ActionTypes';

const initialState = {
  units: [],
  favourites: [],
  searchUnit: {
    items: [],
  },
  searchAll: {
    items: [],
  },
  searchFavourite: {
    items: [],
  },
  showProfile: null,
  filterOnline: false,
  viewUnit: null,
  favouriteContacts: [],
};

export default function contact(state = initialState, action) {
  switch (action.type) {
    case AUTH_DISCARD_TOKEN:
      return {
        units: [],
        favourites: [],
        searchUnit: {
          items: [],
        },
        searchAll: {
          items: [],
        },
        searchFavourite: {
          items: [],
        },
        showProfile: null,
        filterOnline: false,
      };

    case CONTACT_FETCH_UNITS:
      return {
        ...state,
        units: action.payload.data.result.result.items,
      };

    case CONTACT_FETCH_FAVOURITE_GROUPS:
      return {
        ...state,
        favourites: action.payload.data.result.result.items,
      };

    case CONTACT_SEARCH_BY_UNIT:
      let searchUnit = state.searchUnit;
      if (action.meta.previousAction.page === 1) {
        searchUnit = action.payload.data.result.result;
        if (!searchUnit.items) {
          searchUnit.items = [];
        }
      } else {
        searchUnit.page = action.meta.previousAction.page;
        if (
          action.payload.data.result.result.items &&
          action.payload.data.result.result.items.length
        ) {
          searchUnit.items = [...searchUnit.items, ...action.payload.data.result.result.items];
        }
      }
      return {
        ...state,
        searchUnit,
        searchAll: searchUnit,
      };

    case CONTACT_SEARCH_ALL:
      let searchAll = state.searchAll;
      if (action.meta.previousAction.page === 1) {
        searchAll = action.payload.data.result.result;
        if (searchAll) {
          searchAll.items = searchAll?.items || [];
        }
      } else {
        searchAll.page = action.meta.previousAction.page;
        if (
          action.payload.data.result.result.items &&
          action.payload.data.result.result.items.length
        ) {
          searchAll.items = [...searchAll.items, ...action.payload.data.result.result.items];
        }
      }

      return {
        ...state,
        searchAll,
      };

    case CONTACT_SEARCH_FAVOURITE:
      let searchFavourite = state.searchFavourite;
      if (action.meta.previousAction.page === 1) {
        searchFavourite = action.payload.data.result.result;
        if (!searchFavourite.items) {
          searchFavourite.items = [];
        }
      } else {
        searchFavourite.page = action.meta.previousAction.page;
        if (
          action.payload.data.result.result.items &&
          action.payload.data.result.result.items.length
        ) {
          searchFavourite.items = [
            ...searchFavourite.items,
            ...action.payload.data.result.result.items,
          ];
        }
      }
      return {
        ...state,
        searchFavourite: searchFavourite,
      };

    case CONTACT_CLEAR_SEARCH_BY_UNIT:
      return {
        ...state,
        searchUnit: {
          items: [],
        },
      };

    case CONTACT_CLEAR_SEARCH_ALL:
      return {
        ...state,
        searchAll: {
          items: [],
        },
      };

    case CONTACT_CLEAR_SEARCH_FAVOURITE:
      return {
        ...state,
        searchFavourite: {
          items: [],
        },
      };

    case CONTACT_SHOW_PROFILE:
      return {
        ...state,
        showProfile: action.userId,
      };

    case CONTACT_CHANGE_STATUS_FILTER:
      return {
        ...state,
        filterOnline: !state.filterOnline,
      };

    case CONTACT_VIEW_UNIT:
      return {
        ...state,
        viewUnit: action.unitId,
      };

    case CONTACT_CLEAR_VIEW_UNIT:
      return {
        ...state,
        viewUnit: null,
        searchAll: initialState.searchAll,
      };
    case GET_FAVOURITE_CONTACTS:
      return {
        ...state,
        favouriteContacts: action?.payload?.data?.result?.items ?? [],
      };
    default:
      return state;
  }
}
