import _ from 'lodash';
import { Cookies } from 'react-cookie';
import { decamelize } from 'humps';
import store from 'local-storage';
import pdf from '@/assets/images/icons/pdf.svg';
import doc from '@/assets/images/icons/doc.svg';
import xls from '@/assets/images/icons/xls.svg';
import video from '@/assets/images/icons/video.svg';
import zip from '@/assets/images/icons/zip.svg';
import sound from '@/assets/images/icons/sound.svg';

export const typeFile = (extension) => {
  let image = null;
  switch (extension) {
    case 'doc':
    case 'docx':
      image = process.env.PUBLIC_URL + '/images/doc.png';
      break;
    case 'pdf':
      image = process.env.PUBLIC_URL + '/images/pdf.png';
      break;
    case 'xlsx':
    case 'xls':
      image = process.env.PUBLIC_URL + '/images/xls.png';
      break;
    default:
      image = process.env.PUBLIC_URL + '/images/file.png';
      break;
  }

  return image;
};

export const getIconFile = (extension) => {
  let result = null;
  switch (extension?.toUpperCase()) {
    case 'PDF':
    case 'APPLICATION/PDF':
      result = pdf;
      break;
    case 'XLS':
    case 'XLSX':
    case 'APPLICATION/VND.MS-EXCEL':
    case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
      result = xls;
      break;
    case 'DOC':
    case 'DOCX':
    case 'APPLICATION/MSWORD':
    case 'APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT':
      result = doc;
      break;
    case 'VIDEO/MP4':
    case 'VIDEO/QUICKTIME':
      result = video;
      break;
    case 'AUDIO/WAV':
    case 'AUDIO/MP3':
    case 'AUDIO/MP4':
      result = sound;
      break;
    case 'APPLICATION/X-ZIP-COMPRESSED':
    case 'APPLICATION/OCTET-STREAM':
      result = zip;
      break;
    default:
      return result;
  }

  return result;
};

export const getFileSize = (size) => {
  if (isNaN(size)) {
    return;
  }

  let fileLength = '';
  if (size < 1000 * 1024) {
    fileLength = (size / 1024).toFixed(2) + ' kB';
  } else if (1000 * 1024 <= size && size <= 1024 * 1024) {
    fileLength = '1 MB';
  } else {
    fileLength = (size / 1024 / 1024).toFixed(2) + ' MB';
  }

  return fileLength;
};

export const linkObjectKey = (obj) => {
  let params = {};

  for (var v in obj) {
    if (typeof obj[v] !== 'object' || Array.isArray(obj[v])) {
      params[decamelize(v)] = obj[v];
      continue;
    }

    for (var v1 in obj[v]) {
      if (typeof obj[v][v1] !== 'object' || Array.isArray(obj[v][v1])) {
        params[`${decamelize(v)}.${decamelize(v1)}`] = obj[v][v1];
        continue;
      }

      for (var v2 in obj[v][v1]) {
        params[`${decamelize(v)}.${decamelize(v1)}.${decamelize(v2)}`] = obj[v][v1][v2];
      }
    }
  }

  return params;
};

export const checkQuyenChucVu = (auth, chucVu = []) => {
  if (!auth || chucVu.length === 0) {
    return true;
  }

  let chucVuSoSanh = [];
  chucVu.forEach((item) => {
    chucVuSoSanh.push({
      chucVu: {
        maChucVu: item,
      },
    });
  });
  if (auth && auth.user && auth.user.dsChucDanh) {
    // let dsChucDanh = auth.user.dsChucDanh.filter(
    //   item => item.chucVu.maChucVu.toLowerCase() === chucVu.toLowerCase()
    // );
    let dsChucDanh = _.intersectionBy(auth.user.dsChucDanh, chucVuSoSanh, 'chucVu.maChucVu');
    if (dsChucDanh.length !== 0) {
      return true;
    }

    return false;
  }
};

export const permissionsCheck = (permissions = [], condition = 'and') => {
  let cookies = new Cookies();
  let authPermissions =
    cookies.get('authState') && cookies.get('authState').permissions
      ? cookies.get('authState').permissions
      : [];
  if (
    (authPermissions && authPermissions.length === 0) ||
    (permissions && permissions.length === 0)
  ) {
    return true;
  }

  switch (condition) {
    case 'and':
      if (_.intersection(authPermissions, permissions).length === permissions.length) {
        return true;
      }

      return false;
    case 'or':
      if (_.intersection(authPermissions, permissions).length !== 0) {
        return true;
      }

      return false;
    default:
      return true;
  }
};

export const saveData = (name, data) => {
  return store.set(name, data);
};

export const getData = (name, data = null) => {
  let lastData;
  try {
    lastData = store.get(name);
  } catch (e) {
    lastData = undefined;
  }

  if (!lastData || lastData === 'undefined') {
    return data;
  }

  return lastData;
};

export const preData = (
  name,
  data,
  findNames = ['chi-tiet', 'thong-ke', 'tao-cau-hoi', 'gui-gop-y']
) => {
  let resData = data;
  if (typeof findNames === 'string') {
    findNames = [findNames];
  }

  if (Array.isArray(findNames)) {
    for (let i = 0; i <= findNames.length; i++) {
      if (
        window.previousLocation &&
        window.previousLocation.pathname.indexOf(findNames[i]) !== -1
      ) {
        resData = getData(name, data);
        break;
      }
    }
  }

  const isReloadPage = getData('isReloadPage', false);
  if (isReloadPage) {
    resData = getData(name, data);
  }

  saveData(name, data);
  return resData;
};

// Get file type
export const getExtension = (fileName) => {
  var parts = fileName.split('.');
  return parts[parts.length - 1];
};

// Check file type EX: checkExtension(["jpg", "jpeg", "png"], file.name)
export const checkExtension = (fileType = [], fileName) => {
  if ((fileType && fileType.length === 0) || !fileName) {
    return false;
  }

  let type = getExtension(fileName);
  if (fileType.findIndex((i) => i.toLowerCase() === type.toLowerCase()) !== -1) {
    return true;
  }

  return false;
};

// Nhóm các đối tượng bên trong array children đối với array origin
export const groupChildrensByOrigin = (arr, key, childrenKey, childrenName) => {
  const childrenGroupedByOrigin = arr.reduce((origin, childrenItem) => {
    // Chèn children item hiện tại vào từng mục mà nó chứa
    childrenItem[childrenKey].forEach((children) => {
      // Nếu mục tồn tại trong mảng, thì chỉ cần thêm children item hiện tại
      if (children[key] in origin) origin[children[key]][childrenName].push(childrenItem);
      // Nếu không, hãy tạo một đối tượng mục mới với children item hiện tại
      else origin[children[key]] = { ...children, [childrenName]: [childrenItem] };
    });
    return origin;
  }, {});

  // Chuyển đổi đối tượng thành một mảng đối tượng
  return Object.values(childrenGroupedByOrigin);
};

export const arrayToTree = (items, id = null, parentName = 'phongBanCha', parentIdName = 'id') => {
  return items
    .filter((item) => item?.[parentName] === id)
    .map((item) => ({
      ...item,
      children: arrayToTree(items, item?.[parentIdName], parentName, parentIdName),
    }));
};

export const compareArrays = (originArray, currentArray) => {
  return JSON.stringify(originArray) === JSON.stringify(currentArray);
};

export const arrayToCompare = (originArray = [], currentArray = [], fieldName = 'id') => {
  if (originArray?.length === 0 && currentArray?.length === 0) {
    return false;
  }

  const newResult = originArray?.filter((elm) =>
    currentArray?.some((e) => e?.[fieldName] === elm?.[fieldName])
  );
  if (newResult?.length === currentArray?.length && newResult?.length === originArray?.length) {
    return true;
  }

  return false;
};

export const camelToUnderscore = (key) => {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const convertSnakeCase = (object, exceptKeys = []) => {
  let newObject = object;
  if (_.isPlainObject(object)) {
    newObject = {};
    _.toPairs(object).forEach(([key, value]) => {
      let newValue = value;
      if (_.isPlainObject(value)) {
        newValue = convertSnakeCase(value);
      }
      if (_.isArray(value)) {
        newValue = value.map((item) => convertSnakeCase(item));
      }
      if (!exceptKeys.includes(key)) {
        newObject[camelToUnderscore(key)] = newValue;
      } else {
        newObject[key] = newValue;
      }
    });
  }
  if (_.isArray(object)) {
    newObject = [];
    newObject = object.map((item) => convertSnakeCase(item));
  }
  return newObject;
};

// Function to convert snake_case to camelCase
export const toCamelCase = (snakeStr) => {
  return snakeStr.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
};

// Recursive function to convert keys from snake_case to camelCase
export const convertKeysToCamelCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => convertKeysToCamelCase(item));
  } else if (typeof data === 'object' && data !== null) {
    const newObj = {};
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        const camelCaseKey = toCamelCase(key);
        newObj[camelCaseKey] = convertKeysToCamelCase(data[key]);
      }
    }
    return newObj;
  } else {
    return data;
  }
};

export const fileAccept = (type) => {
  const AVATARS = ['.JPG', '.PNG', '.JPEG'];
  const IMAGES = ['.JPG', '.PNG', '.GIF', '.JPEG'];
  const DOCUMENT = ['.DOC', '.DOCX', '.EXCEL', '.PDF'];
  const COMPRESSEDTYPE = ['.ZIP', '.RAR'];
  switch (type) {
    case 'IMAGE':
      return IMAGES;
    case 'DOCUMENT':
      return DOCUMENT;
    case 'AVATAR':
      return AVATARS;
    case 'COMPRESSED':
      return COMPRESSEDTYPE;
    default:
      break;
  }
};

export const checkAvatarType = (type) => {
  const imagesType = [
    'image/jpeg',
    'image/jpg',
    'application/jpg',
    'image/pjpeg',
    'image/pipeg',
    'image/bmp',
    'image/png',
    'image/x-windows-bmp',
  ];
  if (imagesType?.some((e) => e === type)) {
    return true;
  }

  return false;
};

export const imagesType = () => {
  return [
    'image/jpeg',
    'image/jpg',
    'application/jpg',
    'image/pjpeg',
    'image/pipeg',
    'image/gif',
    'image/bmp',
    'image/png',
    'image/x-windows-bmp',
  ];
};

export const ducumentsType = () => {
  return [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xlsx',
    'text/comma-separated-values',
    'text/csv',
    'application/csv',
    'application/excel',
    'application/vnd.msexcel',
    'application/pdf',
    'application/x-pdf',
    '.pdf',
    'application/zip',
    'application/mspowerpoint',
    'application/powerpoint',
    '.pps',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'ppt',
    'pptx',
    '.ppt',
    '.pptx',
    'application/msword',
    'application/doc',
  ];
};

export const videosType = () => {
  return ['video/mp4', 'video/quicktime'];
};

export const compressedsType = () => {
  return ['application/x-zip-compressed', 'application/octet-stream'];
};

export const audioType = () => {
  return ['audio/mpeg', 'audio/mp4'];
};

export const settupType = () => {
  return ['application/x-msdownload', '.exe'];
};

export const checkFileType = (type) => {
  if (imagesType()?.some((e) => e === type)) {
    return 'IMAGE';
  } else if (ducumentsType()?.some((e) => e === type)) {
    return 'DOCUMENT';
  } else if (videosType()?.some((e) => e === type)) {
    return 'VIDEO';
  } else if (compressedsType()?.some((e) => e === type)) {
    return 'COMPRESSED';
  } else if (audioType()?.some((e) => e === type)) {
    return 'AUDIO';
  }

  return false;
};

export const copyToClipboard = (value) => {
  const regex = /(<([^>]+)>)/gi;
  const valueText = value.replace(regex, '');
  if ('clipboard' in navigator) {
    try {
      navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([valueText], { type: 'text/plain' }),
          'text/html': new Blob([value], { type: 'text/html' }),
        }),
      ]);
    } catch (error) {
      document.execCommand('copy', true, value);
    }
  } else {
    document.execCommand('copy', true, value);
  }
};

export const copyIdMessToClipboard = (value) => {
  const keyPrefix = `@[CHAT_CPC_ID]`;
  const valueText = `${keyPrefix}${value}`;
  if ('clipboard' in navigator) {
    try {
      navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([valueText], { type: 'text/plain' }),
        }),
      ]);
    } catch (error) {
      const textArea = document.createElement('textarea');
      textArea.value = valueText;
      textArea.style.position = 'fixed';
      textArea.style.left = '-9999px';
      textArea.style.top = '-9999px';
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (error) {
        console.error('Error copying text to clipboard:', error);
      }
    }
  } else {
    document.execCommand('copy', true, value);
  }
};

export const checkScroll = (scroll, type = 'BOTTOM') => {
  const scrollBottom =
    +scroll?.scrollTop.toFixed(0) + 1 >= +(scroll?.scrollHeight - scroll?.clientHeight).toFixed(0);
  const scrollTop = scroll.top === 0;
  if (type === 'BOTTOM' && scrollBottom) {
    return true;
  } else if (type !== 'BOTTOM' && scrollTop) {
    return true;
  }

  return false;
};

export const removeVietnameseTones = (str, isUpperCase = false) => {
  if (!str) {
    return '';
  }

  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  /* eslint-disable no-useless-escape */
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\\=|\<|>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );

  if (isUpperCase) {
    return str.toUpperCase();
  }

  return str;
};

export const getRandomInt = (min, max) => {
  // The Math.floor() function rounds down to the nearest integer
  // The formula below generates a random integer in the range [min, max)
  return Math.floor(Math.random() * (max - min)) + min;
};

export const replaceHtmlBreakLine = (string) => {
  if (!string) {
    return '';
  }

  return string.replace(/\n/g, '<br />');
};
