import React, { useRef, useEffect } from 'react';
import { InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import './v2.scss';

const CPCInputV2 = ({
  className,
  theme,
  autoFocus = false,
  fill = false,
  leftIcon = null,
  showClose = false,
  rightElement = null,
  shape,
  value,
  refFunc = () => {},
  onClose = () => {},
  ...rest
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    refFunc(inputRef);
  }, [inputRef]);

  return (
    <InputGroup
      ref={inputRef}
      className={classNames('cpc-input-v2', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
        [`shape-${shape}`]: shape,
      })}
      fill={fill}
      leftIcon={leftIcon ? <div className="left-icon">{leftIcon}</div> : null}
      rightElement={
        <div className="right-element">
          {showClose && value !== '' && <span className="icon-xmark" onClick={onClose}></span>}
          {rightElement && rightElement}
        </div>
      }
      autoComplete="off"
      value={value}
      autoFocus={autoFocus ? autoFocus : null}
      {...rest}
    />
  );
};

export default CPCInputV2;
