export default {
  'conversation.thongtinhoithoai': 'Thông tin hội thoại',
  'conversation.cauhinhnhom': 'Cấu hình nhóm',
  'conversation.thanhvien': 'Thành viên',
  'conversation.admin-bancochacmuonroinhom':
    'Rời nhóm sẽ xoá hội thoại và toàn bộ tin nhắn của nhóm. Quyền quản lý sẽ được giao cho thành viên khác.<br>Anh/Chị có muốn rời nhóm không?',
  'conversation.bancochacmuonroinhom':
    'Rời nhóm sẽ xoá hội thoại và toàn bộ tin nhắn của nhóm.<br>Anh/Chị có muốn rời nhóm không?',
  'conversation.roinhom': 'Rời nhóm',
  'conversation.roinhomvaxoatrochuyen': 'Rời nhóm và xoá trò chuyện',
  'conversation.giaitannhom': 'Giải tán nhóm',
  'conversation.tatcathanhvienvatinnhansebixoa':
    'Tất cả thành viên sẽ bị mời ra khỏi nhóm và xoá tin nhắn. Nhóm đã giải tán sẽ KHÔNG THỂ khôi phục.',
  'conversation.tinhnangchidanhchoquantrivien': 'Tính năng chỉ dành cho quản trị viên',
  'conversation.chopheproinhom': 'Cho phép thành viên trong nhóm',
  'conversation.thayhoitenvaanhdaidien': 'Thay đổi tên và ảnh đại diện',
  'conversation.ghimtinnhan': 'Ghim tin nhắn',
  'conversation.chedopheduyetthanhvienmoi': 'Chế độ phê duyệt thành viên mới',
  'conversation.chophepthanhvienguitin': 'Cho phép thành viên gửi tin',
  'conversation.themthanhvien': 'Thêm thành viên',
  'conversation.giaitannhomthanhcong': 'Giải tán nhóm thành công',
  'conversation.chonphonhomthanhcong': 'Bổ nhiệm phó nhóm thành công',
  'conversation.chonphonhomkhongthanhcong': 'Bổ nhiệm phó nhóm không thành công',
  'conversation.xoathanhvienthanhcong': 'Xóa thành viên thành công',
  'conversation.nhanbanhoithoaithanhcong': 'Nhân bản hội thoại thành công',
  'conversation.nhanbanhoithoaithatbai': 'Nhân bản hội thoại thất bại',
  'conversation.themthanhvienthanhcong': 'Thêm thành viên thành công',
  'conversation.tinnhanghim': 'Tin nhắn ghim',
  'conversation.boghimthanhcong': 'Bỏ ghim nội dung tin nhắn thành công',
  'conversation.boghimthatbai': 'Bỏ ghim nội dung tin nhắn không thành công',
  'conversation.duyetthanhvienthanhcong': 'Duyệt thành viên thành công',
  'conversation.hinhanh': 'Hình ảnh',
  'conversation.taptin': 'Tập tin đính kèm',
  'conversation.chuacohinhanhduocchiase': 'Chưa có hình ảnh được chia sẻ trong hội thoại này',
  'conversation.chuacotailieuduocchiase': 'Chưa có tập tin được chia sẻ trong hội thoại này',
  'conversation.xoatrochuyen': 'Xoá trò chuyện',
  'conversation.toanbonoidungtrochuyensebixoa':
    'Toàn bộ nội dung trò chuyện sẽ bị xoá vĩnh viễn. Anh/ Chị có chắc chắn muốn xoá?',
  'conversation.xoacuoctrochuyen': 'Xoá cuộc trò chuyện',
  'conversation.tatthongbao': 'Tắt thông báo',
  'conversation.comuontatthongbao': 'Bạn có chắc muốn tắt thông báo',
  'conversation.tinnhanquantrong': 'Tin nhắn quan trọng',
  'conversation.batthongbao': 'Bật thông báo',
  'conversation.xoa': 'Xóa',
  'conversation.link': 'Link',
  'conversation.chuacolinkduocchiase': 'Chưa có link được chia sẻ trong hội thoại này',
  'conversation.kholuutru': 'Kho lưu trữ',
};
