import system from './vi/system';
import conversation from './vi/conversation';
import notification from './vi/notification';

const vi = {
  translation: {
    ...system,
    ...conversation,
    ...notification,
  },
};

export default vi;
