import React from 'react';
import classNames from 'classnames';
import './index.scss';

function CPCIcon(props) {
  const { className, icon, color, size, theme, cursor, style, ...rest } = props;
  return (
    <i
      style={{ color: color || 'inherit', fontSize: size || '13px', ...style }}
      className={classNames('cpc-icon', {
        [`icon-${icon}`]: icon,
        [`cursor-${cursor}`]: cursor,
        [`${className}`]: className,
      })}
      {...rest}
    />
  );
}

export default CPCIcon;
