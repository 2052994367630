import { useEffect, useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import classNames from 'classnames';
import 'quill-mention';

import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import { IMAGE_URL } from '@/constants/Api';
import { toolbarOptions, formats } from './config';
import './index.scss';
import { isEmpty } from 'lodash';
import { getContent, getForward, stringMentionSetEditor, getMentionedIds } from '@/helpers/chat';
import { removeVietnameseTones, replaceHtmlBreakLine } from '@/helpers/helper';
import defaultAvatar from '@/assets/images/icons/default_avatar.png';

const Editor = ({
  indexSelection,
  readOnly = false,
  refEditor,
  placeholder,
  isEnter,
  onChange,
  content,
  mentionedIds = [],
  usersConversation = [],
  isZoom,
  conversationDetail,
  handleFile,
  onChangeSelection,
  setSurveyListMessage,
  actions,
  handleForwardCopy = () => {},
  onKeySubmit = () => {},
}) => {
  const actionType = {
    enter: 'ENTER',
    shiftEnter: 'SHIFTENTER',
    ctrlEnter: 'CTRLENTER',
  };

  const setLastPositionPointer = (cursorIndex = null) => {
    if (!refEditor?.current) {
      return;
    }

    // Move the mouse pointer to the last position
    setTimeout(() => {
      const quill = refEditor.current?.getEditor();
      quill?.setSelection(cursorIndex ? cursorIndex : quill.getLength());
    }, 100);
  };

  const onQuillChange = (content) => {
    onChange(content);
  };

  const onKeyDown = () => {};

  const onKeyUp = (event) => {
    if (!event) {
      return;
    }

    if (!event?.altKey && !event?.ctrlKey && !event?.shiftKey && event?.keyCode === 13) {
      return onKeySubmit(actionType.enter);
    }

    if (!event?.altKey && event?.shiftKey && event?.keyCode === 13) {
      return onKeySubmit(actionType.shiftEnter);
    }

    if (!event?.altKey && event?.ctrlKey && event?.keyCode === 13) {
      return onKeySubmit(actionType.ctrlEnter);
    }
  };

  const mention = useMemo(() => {
    return {
      allowedChars: /^[A-Za-z\sÅÄÖåäöÀ-ỳýỷỹỵ.1234567890]*$/,
      onSelect(item, insertItem) {
        insertItem(item);
        // Thêm khoảng trắng sau mention
        const quill = refEditor.current.getEditor();
        const selection = quill.getSelection(true);
        quill.insertText(selection?.index, '\u200B');
        quill.blur();
        quill.focus();
      },
      mentionDenotationChars: ['@', '#'],
      spaceAfterInsert: true,
      onOpen: () => {},
      onClose: () => {},
      source: function (searchTerm, renderList, mentionChar) {
        // const quill = refEditor.current.getEditor();
        // const selection = quill.getSelection(true);
        // const text = quill.getText();
        // let isRemoveList = false;
        // let firstIndex = 0;
        // let lastIndex = 0;
        // if (text?.length <= selection.index) {
        //   firstIndex = text.length - 3;
        //   lastIndex = text.length - 1;
        // } else {
        //   firstIndex = selection.index - 2;
        //   lastIndex = selection.index;
        // }

        // const tempText = text;
        // const tempTextLimit = tempText.slice(firstIndex, lastIndex);
        // if (
        //   text?.length > 2 &&
        //   (tempTextLimit === '@@' ||
        //     tempTextLimit === '@ ' ||
        //     !tempTextLimit.split('').includes('@'))
        // ) {
        //   isRemoveList = true;
        // }

        // if (isRemoveList) {
        //   renderList([], searchTerm);
        //   return;
        // }

        let values;
        if (mentionChar === '@') {
          values =
            conversationDetail?.isGroup === 1
              ? [{ id: '', value: 'Tất cả' }, ...usersConversation]
              : [];
        } else {
          values = [];
        }

        const tempValues = values?.filter(
          (e) =>
            mentionedIds?.length === 0 ||
            (mentionedIds?.length !== 0 && !mentionedIds.some((s) => s === e?.id))
        );
        if (searchTerm.length === 0) {
          renderList(tempValues, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < tempValues.length; i++) {
            const temp = removeVietnameseTones(searchTerm)?.toLowerCase();
            const value = removeVietnameseTones(tempValues?.[i]?.value)?.toLowerCase();
            if (value && value.includes(temp)) {
              matches.push(tempValues[i]);
            }
          }

          renderList(matches, matches);
        }
      },
      renderItem: (mention) => {
        const chucDanhChinh = mention?.dsChucDanh?.filter((f) => f.phongBanChinh);

        let avatar = defaultAvatar;
        if (mention?.anhDaiDien) {
          avatar = `${IMAGE_URL}/${mention?.anhDaiDien}`;
        }

        let organization = '<div class="name">';
        organization += `<p>${mention?.value}</p>`;
        if (chucDanhChinh && chucDanhChinh?.length !== 0) {
          const phongBan = chucDanhChinh?.[0]?.phongBan;
          const donVi = chucDanhChinh?.[0]?.donVi;
          if (donVi) {
            organization += `<span>${donVi?.tenVietTat}</span>`;
          }

          if (phongBan) {
            organization += `<span>- ${phongBan?.maPhongBan}</span>`;
          }
        }
        organization += '</div>';

        // Create a element mention
        const mentionName = document.createElement('div');
        mentionName.classList.add('mention-name');
        mentionName.innerHTML = `<span class="avatar icon-uniE916"></span><div class="name"><p>Báo cho cả nhóm<span class="all">@Tất cả</span></p></div>`;
        if (mention?.id) {
          mentionName.innerHTML = `<img src="${avatar}" alt="${mention?.value}" />` + organization;
        }

        const mentionItem = document.createElement('div');
        mentionItem.classList.add('mention-item');
        mentionItem.appendChild(mentionName);

        return mentionItem;
      },
    };
  }, [usersConversation?.length, mentionedIds?.length]);

  const renderKey = useMemo(() => {
    const currentTime = new Date();
    if (mentionedIds?.length || usersConversation?.length) {
      return currentTime.getTime();
    }

    return usersConversation?.length || 1;
  }, [usersConversation?.length, mentionedIds?.length]);

  useEffect(() => {
    if (document && document.querySelector('.ql-editor') && actions) {
      document.querySelector('.ql-editor').addEventListener('paste', (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const copyMessage = clipboardData.getData('Text');
        if (copyMessage && copyMessage.includes('@[CHAT_CPC_ID]')) {
          if (actions) {
            const idMess = copyMessage.replace('@[CHAT_CPC_ID]', '');
            actions.getInfoMessage(idMess).then((res) => {
              const result = res?.payload?.data?.result;
              if (result) {
                const { noiDung, fileDinhKem, phieuKhaoSat } = result;
                if (noiDung) {
                  document.execCommand(
                    'insertHTML',
                    false,
                    stringMentionSetEditor(getContent(noiDung))
                  );
                  const forwardContent = getForward(noiDung);
                  if (forwardContent) {
                    try {
                      const parseData = JSON.parse(forwardContent);
                      handleForwardCopy(parseData);
                    } catch (error) {
                      console.error('Error parsing JSON:', error.message);
                    }
                  }
                }

                if (!isEmpty(fileDinhKem)) {
                  handleFile(fileDinhKem, true);
                }

                if (phieuKhaoSat && phieuKhaoSat?.length !== 0 && setSurveyListMessage) {
                  setSurveyListMessage(phieuKhaoSat);
                }
              }
            });
          }
          return;
        }

        const contentCtrlCopyEvent = clipboardData.getData('text/html');
        if (contentCtrlCopyEvent) {
          document.execCommand('insertHTML', false, getContent(contentCtrlCopyEvent));
        } else if (copyMessage && !copyMessage.includes('@[CHAT_CPC_ID]')) {
          document.execCommand('insertHTML', false, replaceHtmlBreakLine(getContent(copyMessage)));
        }

        const isImage =
          clipboardData.types.length && clipboardData.types.join('').includes('Files');
        if (!isImage) {
          return;
        }

        e.preventDefault();
        // Only support single image paste
        handleFile(clipboardData.files);
      });
    }
  }, [actions, renderKey]);

  useEffect(() => {
    if (actions && content) {
      if (actions.setMentionedIds) {
        // Filter out the list of people who mentioned
        actions.setMentionedIds(getMentionedIds(content));
      }
    }
  }, [actions, content]);

  useEffect(() => {
    if (refEditor?.current) {
      setLastPositionPointer(indexSelection > 0 ? indexSelection : null);
    }
  }, [mentionedIds?.length]);

  useEffect(() => {
    // Truyền dataset và thư viện editor để xử lý vấn đề ENTER action
    const quill = refEditor?.current?.getEditor();
    const editorElement = quill?.root;
    if (editorElement) {
      if (isEnter) {
        editorElement.dataset.keyAccess = 'ENTER';
      } else {
        editorElement.dataset.keyAccess = undefined;
      }
    }
  }, [isEnter, refEditor?.current?.getEditor(), renderKey]);

  useEffect(() => {
    refEditor?.current?.editor.root.setAttribute('spellcheck', 'false');
  }, []);

  return (
    <div
      className={classNames('editor-quill full-width', {
        'zoom-out': isZoom,
        zoom: !isZoom,
      })}
    >
      <ReactQuill
        key={renderKey}
        ref={refEditor}
        theme={'snow'}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        value={content}
        onChange={onQuillChange}
        modules={{
          toolbar: toolbarOptions,
          keyboard: keyboard,
          mention: mention,
        }}
        readOnly={readOnly}
        formats={formats}
        onChangeSelection={onChangeSelection}
      />
    </div>
  );
};

const keyboard = {
  bindings: {
    handleEnter: {
      key: 13,
      handler: function (range, context) {
        const keyAccess = this.quill?.root?.dataset?.keyAccess;
        if (!keyAccess || keyAccess !== 'ENTER') {
          // If there's a selection, just insert a newline
          if (range.length > 0 || this.quill.getSelection().index > 0) {
            this.quill.insertText(range.index, '\n', Quill.sources.USER);
            this.quill.setSelection(range.index + 1, 'silent');
          } else {
            // Otherwise, insert a newline and clear the selection
            this.quill.insertText(range.index, '\n', Quill.sources.USER);
            this.quill.setSelection(range.index + 1, 'silent');
            this.quill.selection.update('silent');
          }
        }
      },
    },
    handleCtrlEnter: {
      key: 13,
      ctrlKey: true,
      handler: function (range, context) {
        const keyAccess = this.quill?.root?.dataset?.keyAccess;
        if (keyAccess === 'ENTER') {
          // If there's a selection, just insert a newline
          if (range.length > 0 || this.quill.getSelection().index > 0) {
            this.quill.insertText(range.index, '\n', Quill.sources.USER);
            this.quill.setSelection(range.index + 1, 'silent');
          } else {
            // Otherwise, insert a newline and clear the selection
            this.quill.insertText(range.index, '\n', Quill.sources.USER);
            this.quill.setSelection(range.index + 1, 'silent');
            this.quill.selection.update('silent');
          }
        }
      },
    },
  },
};

export default Editor;
