import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Statistic extends Component {
  render() {
    const { item, number } = this.props;
    if (!item || !number) {
      return;
    }
    return (
      <tr>
        <td className="center">{number > 9 ? number : '0' + number}</td>
        <td className="border-left">{item.cauHoi}</td>
        <td className="p0 border-left" style={{ overflow: 'hidden' }} colSpan={2}>
          <table className="table table-data-answer">
            {item.dsDapAn &&
              item.dsDapAn.length !== 0 &&
              item.dsDapAn.map((dapAn, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <div className="pl10">
                        {`${key + 1}. `}
                        {dapAn.noiDungDapAn}
                      </div>
                    </td>
                    <td style={{ width: '80px', textAlign: 'center' }}>
                      {dapAn.soNguoiTraLoi ? dapAn.soNguoiTraLoi : ''}
                    </td>
                  </tr>
                );
              })}
          </table>
        </td>
      </tr>
    );
  }
}

Statistic.propTypes = {
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
};

export default Statistic;
