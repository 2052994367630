import store from 'local-storage';

export default class Utils {
  static saveData(name, data) {
    return store.set(name, data);
  }

  static getData(name, data = null) {
    let lastData;

    try {
      lastData = store.get(name);
    } catch (e) {
      lastData = undefined;
    }

    if (!lastData || lastData === 'undefined') {
      return data;
    }

    return lastData;
  }

  static preData(name, data, findNames = ['thong-ke', 'them-moi', 'chinh-sua']) {
    let resData = data;
    if (typeof findNames === 'string') {
      findNames = [findNames];
    }

    if (Array.isArray(findNames)) {
      for (let i = 0; i <= findNames.length; i++) {
        if (
          window?.previousLocation &&
          window?.previousLocation?.pathname?.indexOf(findNames[i]) !== -1
        ) {
          resData = Utils.getData(name, data);
          break;
        }
      }
    }

    Utils.saveData(name, data);

    return resData;
  }
}
