import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import classNames from 'classnames';
import './index.scss';

const CPCButtonV2 = ({
  className,
  classNameText,
  icon,
  text,
  size = 'xs',
  loading = false,
  disabled = false,
  minimal = false,
  fill = false,
  shape, // circle | round | round-sm,
  color, // light-red,
  style,
  min,
  hover,
  onClick = () => {},
}) => {
  return (
    <button
      style={style}
      className={classNames('cpc-button-v2', {
        [`${className}`]: className,
        [`size-${size}`]: size,
        [`fill`]: fill,
        [`minimal`]: minimal,
        [`shape-${shape}`]: shape,
        [`color-${color}`]: color,
        [`min-${min}`]: min,
        [`hover-${hover}`]: hover,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && !loading ? icon : ''}
      {loading && <CircleSpinner size={14} color={'#959595'} />}
      {text ? <div className={classNames('text-content', classNameText)}>{text}</div> : ''}
    </button>
  );
};

export default CPCButtonV2;
