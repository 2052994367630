import CPCIcon from '@/components/atoms/icon';
import { acceptMIMETypes } from '@/middleware/uploader';
import FileInput from 'react-fine-uploader/file-input';
import CPCTooltip from '@/components/atoms/tooltip';
import './index.scss';

function Upload(props) {
  return (
    <CPCTooltip content={'Đính kèm'} theme="white" openOnTargetFocus={true}>
      <div className="file-actions-upload-icon" onClick={(e) => e.stopPropagation()}>
        <FileInput
          className="chat-upload"
          title=""
          multiple
          accept={acceptMIMETypes}
          uploader={props.uploader}
        >
          <CPCIcon icon="upload-file" size={16} color="#A9B6C7" />
        </FileInput>
      </div>
    </CPCTooltip>
  );
}

export default Upload;
