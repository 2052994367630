import {
  Home,
  // Login,
  Preload,
  NoMatch,
  // Maintance,
  Download,
  // ListSurveyPage,
  // AddSurveyPage,
  SurveyItemPage,
  SendSurveyPage,
  SurveyViewItemPage,
  // StatisticalSurveyItemPage,
  // SurveyDetail,
  NotFoundQuestion,
  // Hop thu gop y
  FeedbackSend,
  FeedbackDetail,
  FeedbackQuestionControl,
  FeedbackStatistics,
} from '../containers/pages';

import React from 'react';
const Login = React.lazy(() => import('../containers/pages/login/Login'));
const HomePage = React.lazy(() => import('../containers/pages/home/containers/home'));
const ContactPage = React.lazy(() => import('../containers/pages/contact/containers/contact'));
const SurveyPage = React.lazy(() => import('../containers/pages/survey'));
const SurveyDetail = React.lazy(() => import('../containers/pages/survey-detail'));
const SurveyStatistic = React.lazy(() => import('../containers/pages/survey-statistic'));
const AddSurveyPage = React.lazy(() => import('../containers/pages/survey-new'));
const FeedbackList = React.lazy(() => import('../containers/pages/feedback'));
const SupportInformationTechnolory = React.lazy(() => import('../containers/pages/support'));
const CreateRequestSupport = React.lazy(() => import('../containers/pages/support/create'));
const CreateNhanTinHangLoat = React.lazy(() =>
  import('../containers/pages/nhantinnhacviec/create')
);
const ListNhanTinNhacViec = React.lazy(() => import('../containers/pages/nhantinnhacviec/index'));
const CreateNhanTinNhacViec = React.lazy(() =>
  import('../containers/pages/nhantinnhacviec/createnhacviec')
);
// const FeedbackSend = React.lazy(() => import('../containers/pages/feedback/answer'));
import App from '../containers/App';

const routes = [
  {
    component: App,
    requireLogin: '/login',
    preload: '/preload',
    routes: [
      {
        path: '/',
        exact: true,
        component: HomePage,
      },
      {
        path: '/test',
        exact: true,
        component: Home,
      },
      {
        path: '/danh-ba',
        exact: true,
        component: ContactPage,
      },
      {
        path: '/login',
        exact: true,
        component: Login,
      },
      {
        path: '/phieu-khao-sat/them-moi',
        exact: true,
        component: AddSurveyPage,
      },
      {
        path: '/phieu-khao-sat/tra-loi/:id',
        exact: true,
        component: SurveyItemPage,
      },
      {
        path: '/phieu-khao-sat/theo-doi/:id',
        exact: true,
        component: SurveyViewItemPage,
      },
      {
        path: '/phieu-khao-sat/thong-ke/:id',
        exact: true,
        component: SurveyStatistic,
      },
      {
        path: '/phieu-khao-sat/chi-tiet/:id',
        exact: true,
        component: SurveyDetail,
      },
      {
        path: '/phieu-khao-sat/chinh-sua/:id',
        exact: true,
        component: AddSurveyPage,
      },
      {
        path: '/phieu-khao-sat/gui-di/:id',
        exact: true,
        component: SendSurveyPage,
      },
      {
        path: '/phieu-khao-sat/cau-hoi-khong-duoc-tra-loi',
        exact: true,
        component: NotFoundQuestion,
      },
      {
        path: '/phieu-khao-sat/:conversationId?',
        exact: true,
        component: SurveyPage,
      },
      {
        path: '/hop-thu-gop-y',
        exact: true,
        component: FeedbackList,
      },
      {
        path: '/hop-thu-gop-y/gui-gop-y',
        exact: true,
        component: FeedbackSend,
      },
      {
        path: '/hop-thu-gop-y/chi-tiet/:id',
        exact: true,
        component: FeedbackDetail,
      },
      {
        path: '/hop-thu-gop-y/tao-cau-hoi',
        exact: true,
        component: FeedbackQuestionControl,
      },
      {
        path: '/hop-thu-gop-y/thong-ke',
        exact: true,
        component: FeedbackStatistics,
      },
      {
        path: '/ho-tro-cntt',
        exact: true,
        component: SupportInformationTechnolory,
      },
      {
        path: '/ho-tro-cntt/them-moi',
        exact: true,
        component: CreateRequestSupport,
      },
      {
        path: '/ho-tro-cntt/chi-tiet/:id',
        exact: true,
        component: CreateRequestSupport,
      },
      {
        path: '/nhan-tin-hang-loat',
        exact: true,
        component: CreateNhanTinHangLoat,
      },
      {
        path: '/nhan-tin-nhac-viec',
        exact: true,
        component: ListNhanTinNhacViec,
      },
      {
        path: '/nhan-tin-nhac-viec/them-moi',
        exact: true,
        component: CreateNhanTinNhacViec,
      },
      {
        path: '/nhan-tin-nhac-viec/chinh-sua/:id',
        exact: true,
        component: CreateNhanTinNhacViec,
      },
      {
        path: '/preload',
        exact: true,
        component: Preload,
        // component: Maintance
      },
      {
        path: '/download',
        static: true,
        component: Download,
      },
      {
        component: NoMatch,
      },
    ],
  },
];

export default routes;
