import CPCIcon from '@/components/atoms/icon';
import ReactPaginate from 'react-paginate';

function Pagination(props) {
  return (
    <div className="paginate-list">
      <ReactPaginate
        {...props}
        previousLabel={<CPCIcon icon="arrow-left" />}
        nextLabel={<CPCIcon icon="arrow-right" />}
        breakLabel={<button className="footer-three-dot">...</button>}
        breakClassName={'break-me'}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        disableInitialCallback
      />
    </div>
  );
}

export default Pagination;
