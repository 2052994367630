import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UnitItem from './UnitItem';
import Department from './Department';
import { Text } from '@blueprintjs/core';
import './assets/unit.scss';

export default class Unit extends Component {
  static propTypes = {
    receivers: PropTypes.array,
    units: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    exclude: PropTypes.string,
    filterOnline: PropTypes.bool,
  };

  static defaultProps = {
    exclude: '',
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  state = {
    selectUnit: null,
  };

  handleClick = (unit) => {
    return this.props.onClick && this.props.onClick(unit);
  };

  handleBack = () => {
    this.props.actions.contactClearViewUnit();
  };

  render() {
    const selectUnit = this.props.viewUnit;

    return (
      <div className="cpc-unit-section">
        {_.isEmpty(selectUnit) &&
          this.props.units.map((unit, i) => (
            <UnitItem
              key={unit.donViId}
              auth={this.props.auth}
              unit={unit}
              actions={this.props.actions}
              onClick={this.handleClick}
              receivers={this.props.receivers}
              exclude={this.props.exclude}
              filterOnline={this.props.filterOnline}
            />
          ))}
        {!_.isEmpty(selectUnit) && (
          <div>
            <div className="cpc-unit-item-title">
              <button onClick={this.handleBack}>
                <span className="pt-icon pt-icon-chevron-left" />
              </button>
              <Text className="cpc-unit-info" title={selectUnit.tenDonVi}>
                {selectUnit.tenDonVi}
              </Text>
            </div>
            <Department
              auth={this.props.auth}
              actions={this.props.actions}
              unit={selectUnit}
              receivers={this.props.receivers}
              filterOnline={this.props.filterOnline}
            />
          </div>
        )}
      </div>
    );
  }
}

// { this.props.units.map((unit, i) =>
//     <div key={i} style={{display: this.state.selectUnit && this.state.selectUnit.donViId === unit.donViId ? 'block' : 'none'}}>
//       <div className="cpc-unit-item-title">
//         <button onClick={this.handleBack}>
//           <span className="pt-icon pt-icon-chevron-left"/>
//         </button>
//         <Text className="cpc-unit-info" title={unit.tenDonVi}>
//           {unit.tenDonVi}
//         </Text>
//       </div>
//       {
//         this.state.selectUnit &&
//         this.state.selectUnit.donViId === unit.donViId &&
//         <Department actions={this.props.actions} unit={unit} filterOnline={this.props.filterOnline}/>
//       }
//     </div>
//   )
// }
