import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import Pagination from '../molecules/pagination';

class ListFooter extends Component {
  static propTypes = {
    initialPage: PropTypes.number,
    pageCount: PropTypes.number,
    pageRange: PropTypes.number,
    marginPage: PropTypes.number,
    onPageClick: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      initialPage: props.initialPage || 0,
      forcePage: props.forcePage || 0,
      pageCount: props.pageCount || 1,
      pageRange: props.pageRange || 2,
      marginPage: props.marginPage || 1,
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      initialPage: props.initialPage || 0,
      forcePage: props.forcePage || 0,
      pageCount: props.pageCount || 1,
      pageRange: props.pageRange || 2,
      marginPage: props.marginPage || 1,
    });
  }
  handlePageClick(e) {
    this.props.onPageClick && this.props.onPageClick(e);
  }
  render() {
    return (
      <div>
        {this.state.pageCount > 1 && (
          <Pagination
            forcePage={this.state.forcePage}
            initialPage={this.state.initialPage}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={this.state.marginPage}
            pageRangeDisplayed={this.state.pageRange}
            onPageChange={this.handlePageClick.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default ListFooter;
