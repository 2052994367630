import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import './assets/message-item-receiver.scss';

export default class MessageItemReceiver extends Component {
  items = [];

  static propTypes = {
    exclude: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    receivers: PropTypes.array.isRequired,
    allowExpand: PropTypes.bool,
    totalUsers: PropTypes.number,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    totalUsers: 0,
    exclude: {},
    allowExpand: true,
    onClick: () => {},
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  getReceiverElement() {
    const items = [];
    const allItems = [];

    for (const user of this.props.receivers) {
      if (
        !user ||
        (this.props.exclude &&
          this.props.exclude.nhanVienId === user.nhanVienId &&
          this.props.receivers.length > 1)
      ) {
        continue;
      }

      if (!this.items.length && items.length < 3) {
        items.push(
          <span key={items.length} className="cpc-name">
            {user.tenNhanVien}
          </span>
        );
      }
      allItems.push(
        <div key={allItems.length} className="cpc-name">
          {user.tenNhanVien}
        </div>
      );
    }

    if (items.length) {
      this.items = items;
    }

    return {
      items: this.items,
      allItems,
    };
  }

  render() {
    const { items, allItems } = this.getReceiverElement();

    return (
      <div className="cpc-receivers-list">
        <span onClick={this.props.onClick}>{items}</span>
        <span className="ml5">
          {this.props.allowExpand && allItems.length > 3 ? (
            <Popover
              portalClassName="cpc-receivers-list-more"
              popoverClassName="pt-minimal"
              interactionKind={PopoverInteractionKind.HOVER}
              popoverWillOpen={this.props.onOpen}
              content={
                this.props.onLoadReceiverUsers ? (
                  <img
                    style={{ width: '150px' }}
                    alt=""
                    src={process.env.PUBLIC_URL + '/images/loading.gif'}
                  />
                ) : allItems.length > 9 ? (
                  <Scrollbars
                    style={{ width: '150px', height: '190px' }}
                    renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
                  >
                    {allItems}
                  </Scrollbars>
                ) : (
                  <div>{allItems}</div>
                )
              }
              position={Position.BOTTOM}
              tetherOptions={{ constraints: [{ attachment: 'together', to: 'scrollParent' }] }}
            >
              <span className="cpc-icon icon-arrow-circle" />
            </Popover>
          ) : null}
        </span>
        {!this.props.allowExpand && allItems.length > 3 ? '...' : ''}
      </div>
    );
  }
}
