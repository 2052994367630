import * as React from 'react';
import { Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import classNames from 'classnames';

import './index.scss';

const CPCDialog = ({
  className,
  minimal = false,
  isOpen = false,
  footerElement = null,
  children,
  theme,
  isCloseButtonShown = true,
  ...props
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      className={classNames('cpc-dialog', {
        [`${className}`]: className,
        [`theme-${theme}`]: theme,
      })}
      {...props}
      isCloseButtonShown={isCloseButtonShown}
    >
      <DialogBody useOverflowScrollContainer={minimal ? false : undefined}>{children}</DialogBody>

      {footerElement && (
        <DialogFooter minimal={minimal} actions={props?.footerActions}>
          {footerElement}
        </DialogFooter>
      )}
    </Dialog>
  );
};

export default CPCDialog;
