import { schema } from 'normalizr';
import Department from './Department';

const Unit = new schema.Entity(
  'units',
  {
    dsPhongBan: [Department],
  },
  {
    idAttribute: (unit) => unit.donViId,
  }
);

export default Unit;
