import CPCAvatar from '@/components/atoms/avatar';
import CPCTooltip from '@/components/atoms/tooltip';
import classNames from 'classnames';
import './index.scss';

function CPCUser(props) {
  const {
    anhDaiDien,
    tenNhanVien,
    size = 'sm',
    sizeName,
    classOfName,
    styleOfName,
    infos,
    isOnline = false,
    hasClick,
    onClick,
  } = props;
  return (
    <div
      className={classNames('cpc-user', {
        pointer: hasClick,
      })}
      onClick={hasClick ? onClick : undefined}
    >
      <CPCAvatar src={anhDaiDien} size={size} trucTuyen={isOnline} />
      <div style={{ lineHeight: '100%' }}>
        <CPCTooltip theme={'white'} content={tenNhanVien || ''} openOnTargetFocus={false}>
          <p
            style={styleOfName}
            className={classNames('user-name m-0', {
              [sizeName]: sizeName,
              [classOfName]: !!classOfName,
            })}
          >
            {tenNhanVien || ''}
          </p>
        </CPCTooltip>

        {infos && <div className="user-infos">{infos}</div>}
      </div>
    </div>
  );
}

export default CPCUser;
