import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Intent, Dialog, Button } from '@blueprintjs/core';
import { setCloseLimitConfirm } from '../../../actions';

class LimitConfirm extends Component {
  onClosePopup = () => {
    this.props && this.props.setCloseLimitConfirm(false);
  };

  render() {
    const { closeLimitConfirm } = this.props;
    return (
      <Dialog
        isOpen={closeLimitConfirm || false}
        onClose={this.onClosePopup}
        className="modal-auto hide-header"
        title=""
      >
        <div className="pt-dialog-body">
          <p>{`Anh/chị không được gửi quá số người theo chính sách (<= 1.200)`}</p>
        </div>
        <div className="pt-dialog-footer">
          <div className="pt-dialog-footer-actions">
            <Button
              iconName="pt-icon-tick"
              intent={Intent.NORMAL}
              onClick={this.onClosePopup}
              text="Đóng"
              className="btn-confirm-popup"
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  closeLimitConfirm: state.chat.closeLimitConfirm,
});

const mapDispatchToProps = (dispatch) => ({
  setCloseLimitConfirm: bindActionCreators(setCloseLimitConfirm, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitConfirm);
