import {
  AUTH_DISCARD_TOKEN,
  CONTACT_FETCH_DEPARTMENTS,
  CONTACT_FETCH_FAVOURITES,
  CHAT_ADD_RECEIVER,
  CHAT_ADD_MULTIPLE_RECEIVERS,
  CHAT_ADD_RECEIVER_SURVEY,
  CHAT_ADD_RECEIVERS_SURVEY,
  CHAT_REMOVE_RECEIVER_SURVEY,
  CHAT_REMOVE_RECEIVERS_SURVEY,
  CHAT_REMOVE_RECEIVER,
  CHAT_REMOVE_MULTIPLE_RECEIVERS,
  CHAT_CLEAR_RECEIVER,
  CHAT_SIGNALR_STATE_CHANGE,
  CHAT_GET_RECEIVER_CONVERSATION,
  REMOVE_ENTITIES_SURVEY_ITEM,
  CONTACT_FETCH_FAVOURITE_GROUPS,
  GET_USER_INFOS,
} from '../constants/ActionTypes';
import merge from 'lodash/merge';

const initialState = {
  users: {},
  units: {},
  departments: {},
  favourites: {},
  messages: {},
  conversations: {},
  documentCabinetCategories: {},
  surveys: {},
};

export default function entities(state = initialState, action) {
  let users;
  switch (action.type) {
    case AUTH_DISCARD_TOKEN:
      return initialState;

    case CONTACT_FETCH_FAVOURITE_GROUPS:
      users = { ...state.users };
      let data = action.payload.data.entities.favourites;
      action.payload.data.result.result.items.forEach((id) => {
        let item = data[id];
        let dsNhanVien = item.dsNhanVien;
        if (item.dsNhanVien && item.dsNhanVien.length > 0) {
          dsNhanVien.forEach((user) => (users[user.nhanVienId] = user));
        }
      });
      return {
        ...state,
        users,
        favourites: action.payload.data.entities.favourites,
      };

    case REMOVE_ENTITIES_SURVEY_ITEM:
      if (state.surveys[action.phieuKhaoSatId]) {
        delete state.surveys[action.phieuKhaoSatId];
      }
      break;

    case CONTACT_FETCH_DEPARTMENTS:
      state = merge({}, state, {
        units: {
          [action.meta.previousAction.unitId]: {
            donViId: action.meta.previousAction.unitId,
            dsPhongBan: action.payload.data.result.result.items,
          },
        },
      });
      break;

    case CONTACT_FETCH_FAVOURITES:
      if (state.favourites[action.meta.previousAction.groupId]) {
        state.favourites[action.meta.previousAction.groupId] = {
          ...state.favourites[action.meta.previousAction.groupId],
          dsNhanVien: action.payload.data.result.result.items.map(
            (id) => action.payload.data.entities.users[id]
          ),
        };
      }
      break;

    case CHAT_ADD_RECEIVER:
      if (state.users[action.userId]) {
        state.users[action.userId] = {
          ...state.users[action.userId],
          checked: true,
        };
      }
      break;

    case CHAT_ADD_RECEIVER_SURVEY:
      if (state.users[action.userId]) {
        state.users[action.userId] = {
          ...state.users[action.userId],
          surveyChecked: true,
        };
      }
      break;

    case CHAT_ADD_MULTIPLE_RECEIVERS:
      users = {};
      for (const user of action.users) {
        if (!state.users[user.nhanVienId]) {
          continue;
        }

        users[user.nhanVienId] = {
          ...state.users[user.nhanVienId],
          checked: true,
        };
      }

      return {
        ...state,
        users: {
          ...state.users,
          ...users,
        },
      };

    case CHAT_ADD_RECEIVERS_SURVEY:
      users = {};
      for (const user of action.users) {
        if (!state.users[user.nhanVienId]) {
          continue;
        }

        users[user.nhanVienId] = {
          ...state.users[user.nhanVienId],
          surveyChecked: true,
        };
      }

      return {
        ...state,
        users: {
          ...state.users,
          ...users,
        },
      };

    case CHAT_REMOVE_RECEIVER:
      if (state.users[action.userId]) {
        state.users[action.userId] = {
          ...state.users[action.userId],
          checked: false,
        };
      }

      break;

    case CHAT_REMOVE_RECEIVER_SURVEY:
      if (state.users[action.userId]) {
        state.users[action.userId] = {
          ...state.users[action.userId],
          surveyChecked: false,
        };
      }

      break;

    case CHAT_REMOVE_MULTIPLE_RECEIVERS:
      users = {};
      for (const user of action.users) {
        if (!state.users[user.nhanVienId]) {
          continue;
        }

        users[user.nhanVienId] = {
          ...state.users[user.nhanVienId],
          checked: false,
        };
      }

      return {
        ...state,
        users: {
          ...state.users,
          ...users,
        },
      };

    case CHAT_REMOVE_RECEIVERS_SURVEY:
      users = {};
      for (const user of action.users) {
        if (!state.users[user.nhanVienId]) {
          continue;
        }

        users[user.nhanVienId] = {
          ...state.users[user.nhanVienId],
          surveyChecked: false,
        };
      }

      return {
        ...state,
        users: {
          ...state.users,
          ...users,
        },
      };

    case CHAT_CLEAR_RECEIVER:
      users = state.users;

      for (const user of action.users) {
        users[user.nhanVienId] = {
          ...state.users[user.nhanVienId],
          checked: false,
        };
      }

      state = {
        ...state,
        users,
      };
      break;

    case CHAT_SIGNALR_STATE_CHANGE:
      if (state.users && state.users[action.userState.userId]) {
        state = merge({}, state, {
          users: {
            [action.userState.userId]: {
              ...state.users[action.userState.userId],
              trucTuyen: action.userState.state,
            },
          },
        });
      }
      break;
    case CHAT_GET_RECEIVER_CONVERSATION:
      if (action.payload.data) {
        let { messageId } = action.meta.previousAction;
        state = {
          ...state,
          messages: {
            ...state.messages,
            [messageId]: {
              ...state.messages[messageId],
              hoiThoai: {
                ...state.messages[messageId].hoiThoai,
                nhanVienHoiThoai: action.payload.data.result.result.items.map((i) => {
                  return {
                    nhanVien: i,
                    soTinDaDoc: 0,
                  };
                }),
              },
            },
          },
        };
      }
    case GET_USER_INFOS:
      const newData = action?.payload?.data?.result;
      state = {
        ...state,
        users: {
          ...state?.users,
          ...(newData
            ? {
                [newData.nhanVienId]: {
                  ...state?.users[newData?.nhanVienId],
                  tamTrang: newData?.tamTrang,
                  soDienThoai: newData?.soDienThoai,
                  soDienThoaiKhac: newData?.soDienThoaiKhac,
                },
              }
            : {}),
        },
      };
      break;
    default:
  }

  if (action.payload && action.payload.data && action.payload.data.entities) {
    return merge({}, state, action.payload.data.entities);
  }

  return state;
}
