import PropTypes from 'prop-types';
import './index.scss';

const CPCSelect = (props) => {
  const { className, options, ...rest } = props;
  return (
    <div className={`${className || ''} cpc-select bp4-html-select`}>
      <select {...rest}>
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

CPCSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.string,
};

CPCSelect.defaultProps = {
  options: [],
};

export default CPCSelect;
