import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Collapse,
  Text,
  Dialog,
  Button,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
  Toaster,
} from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { size, get } from 'lodash';

import { TINNHAN_SMS_365 } from '../../constants/Permissions';
import ContactItem from './ContactItem';
import Avatar from './AvatarOnline';
import groupIcon from './assets/group.png';
import './assets/favourite-item.scss';

export default class FavouriteItem extends Component {
  static propTypes = {
    favourite: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    filterOnline: PropTypes.bool,
  };

  static defaultProps = {
    onDelete: () => {},
  };

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmitRename = this.handleSubmitRename.bind(this);
    this.handleSubmitRemoveUser = this.handleSubmitRemoveUser.bind(this);
    this.handleToggleDialogModifyFavourite = this.handleToggleDialogModifyFavourite.bind(this);
    this.handleToggleDialogRenameFavourite = this.handleToggleDialogRenameFavourite.bind(this);
    this.toggleDialogRemoveUserFavourite = this.toggleDialogRemoveUserFavourite.bind(this);
    this.handleRemoveUserFromGroup = this.handleRemoveUserFromGroup.bind(this);
    this.handleDeleteFavourite = this.handleDeleteFavourite.bind(this);
  }

  state = {
    isOpen: false,
    isOpenModifyFavourite: false,
    isOpenRenameFavourite: false,
    isOpenRemoveUserFavourite: false,
    userRemove: null,
    groupName: this.props.favourite.tenNhomLienLac,
  };

  getUserOnline = () => {
    if (!this.props.favourite.users || !this.props.favourite.users.length) {
      return [];
    }

    const userOnline = this.props.favourite.users.filter((user) => {
      return user.trucTuyen === 1;
    });

    return userOnline;
  };

  getUsers = () => {
    if (!this.props.favourite.users || !this.props.favourite.users.length) {
      return [];
    }

    if (!this.props.filterOnline) {
      return this.props.favourite.users;
    } else {
      return this.getUserOnline();
    }
  };

  getChecked = () => {
    if (!this.getUsers() || !this.getUsers().length) {
      return false;
    }

    for (const user of this.getUsers()) {
      if (!user.checked) {
        return false;
      }
    }
    return true;
  };

  handleChange = (e) => {
    this.setState({ error: '' });
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCollapse = () => {
    if (this.getUsers().length === 0) {
      return;
    }

    this.setState({ isOpen: !this.state.isOpen });
  };

  handleCheckbox = () => {
    if (!this.getChecked()) {
      let newUsers = [...this.getUsers()];
      if (this.props.receivers && size(this.props.receivers) !== 0) {
        newUsers = [...this.props.receivers, ...newUsers];
      }

      if (
        size(newUsers) > 1200 &&
        get(this.props, 'auth') &&
        this.props.auth.permissions.indexOf(TINNHAN_SMS_365) === -1
      ) {
        return this.props.actions.setCloseLimitConfirm(true);
      }

      this.props.actions.chatAddMultipleReceivers(this.getUsers());
    } else {
      this.props.actions.chatRemoveMultipleReceivers(this.getUsers());
    }
  };

  handleDeleteFavourite = () => {
    this.props.onDelete(this.props.favourite, true);
  };

  handleSubmitRename = (e) => {
    e.preventDefault();

    if (this.state.groupName.trim() === '') {
      this.setState({ error: 'Vui lòng nhập tên nhóm' });
      return;
    }

    if (this.state.groupName.trim().length > 50) {
      this.setState({ error: 'Vui lòng đặt tên nhóm dưới 50 ký tự' });
      return;
    }

    const success = () => {
      this.toaster.show({ message: 'Đổi tên nhóm thành công', intent: Intent.SUCCESS });
      this.setState({ isOpenRenameFavourite: false });
    };

    this.props.actions
      .contactUpdateFavouriteGroup(this.props.favourite.nhomLienLacId, this.state.groupName.trim())
      .then((res) => {
        if (res.error) {
          this.toaster.show({ message: 'Có lỗi xảy ra, vui lòng thử lại', intent: Intent.DANGER });
          this.setState({ error: 'Có lỗi xảy ra, vui lòng thử lại' });
          return;
        }

        this.props.actions.contactFetchFavouriteGroups().then(success);
      });
  };

  handleSubmitRemoveUser = (e) => {
    const success = () => {
      this.setState({ isOpenRemoveUserFavourite: false });
      this.toaster.show({ message: 'Chỉnh sửa nhóm thành công', intent: Intent.SUCCESS });
    };

    this.props.actions
      .contactRemoveUserFromFavouriteGroup(
        this.props.favourite.nhomLienLacId,
        this.state.userRemove.nhanVienId
      )
      .then((res) => {
        if (res.error) {
          this.toaster.show({ message: 'Có lỗi xảy ra, vui lòng thử lại', intent: Intent.DANGER });
          return;
        }

        this.props.actions
          .contactFetchFavouritesByGroup(this.props.favourite.nhomLienLacId)
          .then(success);
      });
  };

  handleRemoveUserFromGroup = (user) => {
    this.setState(
      {
        userRemove: user,
      },
      () => {
        this.handleSubmitRemoveUser();
      }
    );
  };

  handleToggleDialogModifyFavourite(isOpen = null) {
    this.setState({
      error: '',
      isOpenModifyFavourite: isOpen !== null ? isOpen : !this.state.isOpenModifyFavourite,
    });
  }

  handleToggleDialogRenameFavourite(isOpen = null) {
    this.setState({
      error: '',
      groupName: this.props.favourite.tenNhomLienLac,
      isOpenRenameFavourite: isOpen !== null ? isOpen : !this.state.isOpenRenameFavourite,
    });
  }

  toggleDialogRemoveUserFavourite() {
    this.setState({
      error: '',
      isOpenRemoveUserFavourite: !this.state.isOpenRemoveUserFavourite,
    });
  }

  render() {
    return (
      <div className="cpc-favourite-item">
        <div
          className={classnames('cpc-item', {
            active: this.state.isOpen,
          })}
        >
          {this.getUsers().length > 0 && (
            <button onClick={this.handleCollapse}>
              {this.state.isOpen ? (
                <span className="cpc icon-round-delete-button" />
              ) : (
                <span className="cpc icon-rounded-add-button" />
              )}
            </button>
          )}
          <label className="pt-control pt-checkbox">
            <input type="checkbox" checked={this.getChecked()} onChange={this.handleCheckbox} />
            {/* <span className="pt-control-indicator"/> */}
          </label>
          <div className="cpc-fav-name" onClick={this.handleCollapse}>
            <Text className="cpc-unit-info" title={this.props.favourite.tenNhomLienLac}>
              <img alt="avatar" className="cpc-avatar pull-left" src={groupIcon} />
              <span className="cpc-name-content">
                <span className="cpc-name">{this.props.favourite.tenNhomLienLac}</span>
                <span className="cpc-item-number">
                  ({this.getUserOnline().length}/{this.getUsers().length})
                </span>
              </span>
            </Text>
          </div>
          <Popover
            className="cpc-menu"
            content={
              <Menu>
                <MenuItem
                  iconName="cpc icon-community"
                  text="Chỉnh sửa nhóm"
                  onClick={() => {
                    this.handleToggleDialogModifyFavourite(true);
                  }}
                />
                <MenuItem
                  iconName="cpc icon-edit"
                  text="Đổi tên nhóm"
                  onClick={() => {
                    this.handleToggleDialogRenameFavourite(true);
                  }}
                />
                <MenuItem
                  iconName="cpc icon-delete"
                  text="Xóa nhóm"
                  onClick={this.handleDeleteFavourite}
                />
              </Menu>
            }
            position={Position.BOTTOM}
            tetherOptions={{ constraints: [{ attachment: 'together', to: 'scrollParent' }] }}
          >
            <button>
              <span className="pt-icon icon-dot3-menu" />
            </button>
          </Popover>
        </div>
        <Collapse className="cpc-favourite-contact-list" isOpen={this.state.isOpen}>
          {this.state.isOpen &&
            this.getUsers().map(
              (user, i) =>
                user && (
                  <ContactItem
                    key={i}
                    user={user}
                    actions={this.props.actions}
                    auth={this.props.auth}
                    receivers={this.props.receivers}
                  />
                )
            )}
        </Collapse>
        <Dialog
          className="cpc-modify-favourite-dialog"
          isOpen={this.state.isOpenModifyFavourite}
          onClose={() => {
            this.handleToggleDialogModifyFavourite(false);
          }}
          title={`${this.props.favourite.tenNhomLienLac} (${this.props.favourite.users.length})`}
        >
          <div className="pt-dialog-body">
            <Scrollbars
              style={{ width: '100%', height: 250 }}
              renderThumbVertical={(props) => <div {...props} className="thumb-horizontal" />}
            >
              <div className="cpc-list-container">
                {this.props.favourite.users.map((user, i) => (
                  <div key={i} className="cpc-item">
                    <Avatar
                      auth={this.props.auth}
                      actions={this.props.actions}
                      className="cpc-avatar-container"
                      user={user}
                    />
                    <span className="cpc-text">{user.tenNhanVien}</span>
                    <button onClick={this.handleRemoveUserFromGroup.bind(this, user)}>
                      <span className="pt-icon pt-icon-cross" />
                    </button>
                  </div>
                ))}
              </div>
            </Scrollbars>
          </div>
        </Dialog>
        <Dialog
          className="cpc-rename-favourite-dialog"
          isOpen={this.state.isOpenRenameFavourite}
          onClose={() => {
            this.handleToggleDialogRenameFavourite(false);
          }}
          title="Đổi tên nhóm"
        >
          <form onSubmit={this.handleSubmitRename}>
            <div className="pt-dialog-body">
              <div className="pt-form-group">
                <input
                  className="pt-input pt-fill pt-large"
                  type="text"
                  name="groupName"
                  value={this.state.groupName}
                  onChange={this.handleChange}
                />
              </div>
              {this.state.error && (
                <div className="pt-input-helper-text error">{this.state.error}</div>
              )}
            </div>
            <div className="pt-dialog-footer">
              <div className="pt-dialog-footer-actions">
                <Button
                  className="pt-large"
                  rightIconName="cross"
                  text="Đóng"
                  onClick={() => {
                    this.handleToggleDialogRenameFavourite(false);
                  }}
                />
                <Button
                  className="pt-large"
                  rightIconName="bookmark"
                  type="submit"
                  disabled={this.state.groupName.trim() === ''}
                  intent={Intent.PRIMARY}
                  text="Lưu tên nhóm"
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} />
      </div>
    );
  }
}
