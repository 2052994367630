import CPCScrollbar from '@/components/atoms/scrollbar';
import ForwardItem from './item';
import './list.scss';

const ForwardList = ({ autoHeightMax = 200, dataSource, auth, onRemove }) => {
  return (
    <CPCScrollbar autoHeightMax={autoHeightMax}>
      <ul className="forward-list">
        {dataSource?.map((elm, index) => {
          return (
            <li key={index}>
              <ForwardItem dataSource={elm} auth={auth} onRemove={onRemove} />
            </li>
          );
        })}
      </ul>
    </CPCScrollbar>
  );
};

export default ForwardList;
